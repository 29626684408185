import React, { useRef } from 'react'

import ReactTooltip from 'react-tooltip'
import { generateTimestampBasedId } from '../../helpers/TypeHelpers'

interface InfoTooltipProps {
    text: string,
    style?: React.CSSProperties,
    className?: string,
    place?: "top" | "right" | "bottom" | "left" | undefined,
}

const InfoTooltip = ({text, style = {}, className = "", place = "top"}: InfoTooltipProps) => {
    // Used to make unique IDs, otherwise a tooltip might show the wrong tooltip
    const id = useRef(generateTimestampBasedId())

    return <i data-for={`info-circle-tooltip-${id.current}`} data-tip className={`fa fa-info-circle ${className}`} style={style}>
        <ReactTooltip id={`info-circle-tooltip-${id.current}`} effect="solid" place={place} >
            <div style={{maxWidth: 200, zIndex: 999, position: "relative"}}>
                <span className="tooltip-font" style={{whiteSpace: "pre-wrap"}}>
                {text}
                </span>
            </div>
        </ReactTooltip>
        </i>
}

export default InfoTooltip
