import React, { createRef } from "react"
import ReactDOM from "react-dom"
import SaveDialog from "./components/Dialogs/SaveDialog"

const userConfirmation = (message, callback) => {
   const container = document.createElement("div")
   container.setAttribute("custom-confirmation-navigation", "")
   document.body.appendChild(container)

   const closeModal = (callbackState) => {
      ReactDOM.unmountComponentAtNode(container)
      callback(callbackState)
   }

   ReactDOM.render(
      <Dialog closeHandler={() => closeModal(true)} saveHandler={() => closeModal(false)} message={message}/>,
      container
   )
}

class Dialog extends React.Component {

   dialogRef = createRef(); 

   componentDidMount() {
      this.dialogRef.current.show()
   }

   render() {
      return (
         <SaveDialog 
            ref={this.dialogRef} 
            saveText="Stay on this page" 
            closeText="Leave this page" 
            title="Confirm navigation" 
            closeHandler={this.props.closeHandler} 
            saveHandler={this.props.saveHandler}
         >
            {this.props.message}
         </SaveDialog>
      )
   }
}

export default userConfirmation