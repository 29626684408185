import * as ActionTypes from '../../actions/ActionTypes'
import request from 'superagent'
import { saveToken, clearToken, apiGet, apiPut, getAddress, apiPost } from '../../helpers/ApiHelpers'
import { loggedIn, loggedOut, gotUserInfo, initialize2FADone, setup2FADone, is2FAEnabledDone, is2FAEnabled, googleLoginDone, gotEmailSettings, is2FAForcedDone, gotPasswordPolicy, setUserNPS, getFollowings, gotFollowings, gotUpdatedUser, updatePermissionsDone, updateMultiUsersPermissionsDone, disableMultipleUsersDone, createUserDone, sendWelcomeMailDone } from '../../actions/UserActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { notifySuccess, notifyFailure } from '../../helpers/NotificationManager'

const UserApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.LOGIN:
            url = 'login'
            request.post(getAddress(url))
                .send(action.payload)
                .end((err, res) => {
                    if (err !== null) {
                        let e = Error(err.toString())
                        if (err.status === 403) {
                            e = Error('wrong email and/or password')
                        }
                        cb(action, e)
                        return
                    }

                    let loginPackage = res.body
                    if (!loginPackage["2fa"]) {
                        cb(action, null, loginPackage)
                        saveToken(loginPackage.token)
                        setTimeout(() => {
                            dispatch(loggedIn(loginPackage.token))
                        }, 200)
                    } else if(loginPackage["2fa"]) {
                        cb(action, null, loginPackage)
                    }
                }, e => notifyApiFailure("logging in", 'POST', url, data, e.status))
            break
        case ActionTypes.LOGIN2FA:
            url = "2falogin"
            request.post(getAddress(url))
                .send(action.payload)
                .end((err, res) => {
                    if (err !== null) {
                        let e = Error(err.toString())
                        if (err.status === 403) {
                            e = Error("Wrong passcode")
                        }
                        cb(action, e)
                        return
                    }

                    let loginPackage = res.body
                    cb(action, null, loginPackage)
                    saveToken(loginPackage.token)
                    setTimeout(() => {
                        dispatch(loggedIn(loginPackage.token))
                    }, 200)
                }, e => notifyApiFailure("logging in", 'POST', url, data, e.status))
            break
        case ActionTypes.LOGOUT:
            url = 'logout'
            apiGet(url, () => {
                clearToken()
                dispatch(loggedOut())
                if (action.cb) action.cb()
            }, e => notifyApiFailure("logging out", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_USER_INFO:
            url = 'me'
            apiGet(url, info => {dispatch(gotUserInfo(info))}, e => notifyApiFailure("getting user info", 'GET', url, data, e.status))
            break
        case ActionTypes.UPDATE_USER_INFO:
            url = 'me'
            data = action.payload
            apiPut(url, data, result => {
                dispatch(gotUserInfo(result))
                cb(action, null, result)
            },
            e => {
                cb(action, e)
            }, e => notifyApiFailure("updating user info", 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_USER:
            url = `users/${action.payload.id}`
            data = action.payload
            apiPut(url, data, result => {
                dispatch(gotUpdatedUser(result))
            },
            e => {
                cb(action, e)
                notifyApiFailure("updating user information", 'PUT', url, data, e.status)
              })
            break
        case ActionTypes.GET_EMAIL_SETTINGS:
            url = 'me/email_settings'
            apiGet(url, res => {
                dispatch(gotEmailSettings(res))
            }, e => notifyApiFailure("getting email settings", 'GET', url, data, e.status))
            break
        case ActionTypes.SAVE_EMAIL_SETTINGS:
            url = 'me/email_settings'
            data = action.payload
            apiPut(url, data, () => {
                dispatch(gotEmailSettings(action.payload.settings))
            }, e => notifyApiFailure("saving email settings", 'PUT', url, data, e.status))
            break
        case ActionTypes.INITIALIZE_2FA:
            apiPost(`password/2factor`, action.payload, res => {
                dispatch(initialize2FADone(res))
            })
            break
        case ActionTypes.SETUP_2FA: 
            apiPost(`password/setup2FA`, action.payload.passcode, res => {
                notifySuccess("Success enabling 2FA on account.")
                dispatch(setup2FADone(res))
            }, err => {
                console.log(err)
                notifyFailure("Error when trying to enable 2FA. Make sure you wrote the correct code.")
            })
            break
        case ActionTypes.IS_2FA_ENABLED:
            apiGet(`2fa/enabled`, res => dispatch(is2FAEnabledDone(res)))
            break
        case ActionTypes.DISABLE_2FA_USER:
            apiPost(`2fa/disable/`+ action.payload.id, {}, res => {
                notifySuccess("Success resetting 2FA on account.")
            }, err => {
                console.log(err)
            })
            break
        case ActionTypes.DISABLE_2FA:
            apiPost(`2fa/disable`, {}, res => {
                notifySuccess("Success disabling 2FA from account.")
                dispatch(is2FAEnabled())
            }, err => {
                console.log(err)
            })
            break
        case ActionTypes.IS_2FA_FORCED:
            apiGet(`2fa/forced`, res => {
                dispatch(is2FAForcedDone(res))
            }, err => {
                console.log(err)
            })
            break
        case ActionTypes.GOOGLE_LOGIN:
            apiGet(`google`, res => dispatch(googleLoginDone(res))
            , err => {
                console.log(err)
            })
            break
        case ActionTypes.CREATE_USER:
            apiPost("users/create", action.payload, res => {
                dispatch(createUserDone(res))
                notifySuccess(`${res.firstname} is created`)
            }, 
             err => {
                if (err.status === 409) {
                    action.cb()
                } else {
                    notifyFailure("Error creating new user.")
                }
            })
            break
        case ActionTypes.CREATE_PENDING_USER:
            apiPost(`organization/newuser`, action.payload, res => {
                notifySuccess("New user requested.")
            },
            err => {
                console.log(err)
                notifyFailure("Error requesting new user.")
            })
            break
        case ActionTypes.GET_PASSWORD_POLICY:
            apiGet(`organization/password_policy`, policy => {
                dispatch(gotPasswordPolicy(policy))
            },
            err => {
                notifyFailure("Error getting password policy")
            })
            break
        case ActionTypes.GET_PASSWORD_POLICY_FROM_TOKEN:
            apiPost(`password_policy/${action.payload.type}`, {token: action.payload.token}, policy => {
                dispatch(gotPasswordPolicy(policy))
            },
            err => {
                notifyFailure("Error getting password policy")
            })
            break
        case ActionTypes.GET_NPS_ANSWER:
            apiGet(`nps`, res => {
                dispatch(setUserNPS(res))
            },
            e => notifyApiFailure("getting last NPS answer", 'GET', url, data, e.status))
            break
        case ActionTypes.POSTPONE_NPS_ANSWER:
            apiPost(`nps/postpone`, action.payload, res => {},
            e => notifyApiFailure("postponing NPS answer", 'POST', url, data, e.status))
            break
        case ActionTypes.ANSWER_NPS:
            apiPost(`nps/answer`, action.payload, res => {},
            e => notifyApiFailure("answering NPS", 'POST', url, data, e.status))
            break
        case ActionTypes.GET_FOLLOWINGS:
            apiGet(`followings`, followings => {
                dispatch(gotFollowings(followings))
            },
            err => {
                notifyFailure("Error getting followed items")
            })
            break
        case ActionTypes.FOLLOW_ITEM:
            apiPost(`follow`, action.payload, () => {
                dispatch(getFollowings())
                notifySuccess(action.payload.follow ? `You are now following this ${action.payload.item_type}.` : `You are no longer following this ${action.payload.item_type}.`)
            },
            err => {
                notifyFailure("Error following item")
            })
            break
        case ActionTypes.UPDATE_PERMISSIONS:
            url = `users/permissions/${action.payload.id}`
            data = action.payload
            apiPut(url, data, (result) => {
                dispatch(updatePermissionsDone(action.payload.id, result))
            },
            err => {
                notifyFailure("Error updating permissions")
            })
            break
        case ActionTypes.UPDATE_MULTIPLE_USERS_PERMISSIONS:
            url = `users/permissions`
            data = action.payload
            apiPut(url, data, (result) => {
                if(result) action.cb(result)
                dispatch(updateMultiUsersPermissionsDone(result))
                notifySuccess("Permissions updated")
            },
            err => {
                notifyFailure("Error updating permissions")
            })
            break
        case ActionTypes.DISABLE_MULTIPLE_USERS:
            url = `users/disable`
            data = action.payload
            apiPut(url, data, (result) => {
                if(result) action.cb(result)
                dispatch(disableMultipleUsersDone(result))
            },
            err => {
                notifyFailure("disabling users", "PUT", data, err?.status)
            })
            break
        case ActionTypes.SEND_WELCOME_MAIL:
            url =`users/${action.payload.id}/mail/welcome`
            apiPost(url, null, (result) => {
                dispatch(sendWelcomeMailDone(result))
                notifySuccess("Mail sent to " + action.payload.email)    
            }, err => {
                notifyFailure("sending mail to " + action.payload.email, "POST", null, err?.status)
            })
            break
        default:
            break
    }
}

const cb = (action, e, data) => {
    if (action.cb) {
        action.cb(e, data)
    }
}

export default UserApiMiddleware
