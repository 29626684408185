import React from 'react'

import WarningAcceptDialog from './WarningAcceptDialog'

class ReportUnselectColumnsDialog extends React.Component {
	state = { issues: undefined }

	show(issues, data) {
		this.refs.dialog.show()
		this.setState({issues: issues, data: data})
	}

	hide() {
		this.refs.dialog.hide()
		this.setState({issues: undefined, data: undefined})
	}

	render() {
		let issues = this.state.issues
        let title="You are removing columns from this Insight."
        let columnText = "columns";

        if(issues?.columns?.length == 1){
            title = title.replace("columns", "a column")
            columnText = "column"
        }

		return (
			<WarningAcceptDialog ref="dialog" acceptText="Proceed" title={title} accept={() => {if(this.props.accept) this.props.accept(this.state.data);}} closeHandler={this.props.closeHandler} >
				{ !issues ? null :
					<div>
						<p>The {columnText} will be removed from views and filters in this Insight.</p>
                        <p><b>Do you want to proceed?</b></p>
					</div>
				}
			</WarningAcceptDialog>
		)
	}
}

export default ReportUnselectColumnsDialog
