import React from 'react'
import Notification from './Notification'
import '../../css/notifications.css'

class Notifier extends React.Component {
    state = {
        notifications: [],
        observers: []
    }
    
    idCounter = 0
    
    addNotification(n) {
        let notifications = this.state.notifications
        n.id = this.idCounter++
        notifications.push(n)
        this.setState({notifications: notifications})
        return n.id
    }
    
    removeNotification(id) {
        let notifications = this.state.notifications.filter(n => n.id !== id)
        this.removeObserver(id)
        this.setState({notifications: notifications})
    }

    dismissAll = () => {
        this.state.observers.forEach(e => {
            e.dismiss()
        });
    }

    addObserver = (obs) => {
        let tmpObservers = this.state.observers
        tmpObservers.push(obs)
        this.setState({observers: tmpObservers})
    }

    removeObserver = (id) => {
        let tmpObservers = this.state.observers.filter(n => n.id !== id)
        this.setState({observers: tmpObservers})
    }
    
    render() {
        const offset = 10
        let total = this.state.notifications.length * offset
        let errors = this.state.notifications.filter(n => n.type === "failure")
        return (
            <div className="abc-notification-bar">
                {total > 0 && errors.length > 0 && <span className='notification-dismiss-all' style={{position: "absolute", bottom: total + 120, left: 20}}>
                    {errors.length} errors (<span className='notification-dismiss-all-link' onClick={this.dismissAll}>Dismiss all</span>)
                </span>}
                {this.state.notifications.map((n, i) => <Notification addObserver={this.addObserver} removeObserver={this.removeObserver} style={{position: "absolute", bottom: i * offset, left: (total - (i * offset + offset))}} key={n.id} id={n.id} onDismiss={() => this.removeNotification(n.id)} message={n.message} type={n.type} info={n.info} sendErrorReport={n.sendErrorReport}/>) }
            </div>
        )
    }
    
}

export default Notifier