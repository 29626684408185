import React from 'react'

class SortArrow extends React.PureComponent {
    render() {
        if(this.props.mySymbol !== this.props.currentSymbol) return null
        let arrowClass = this.props.sortDirection ? ' fa-long-arrow-down' : 'fa-long-arrow-up'
        return (
            <i className={"fa "+arrowClass}></i>
        )
        }
}

export default SortArrow