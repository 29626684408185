import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet, apiPost, apiPut } from '../../helpers/ApiHelpers'
import { gotNotifications, gotUnreadNotifications, didReadNotification, didReadAllNotifications, didUnreadNotification } from '../../actions/NotificationActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'

const NotificationApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_NOTIFICATIONS:
            url = `notifications/all`
            data = action.payload
            apiPost(url, data, res => dispatch(gotNotifications(res, action.payload.limit, action.payload.offset)), e => notifyApiFailure("getting notifications", 'POST', url, data, e.status))
            break
        case ActionTypes.GET_UNREAD_NOTIFICATIONS:
            url = 'notifications/unread'
            apiGet(url, res => dispatch(gotUnreadNotifications(res, action.payload)), e => notifyApiFailure("getting unread notifications", 'GET', url, data, e.status))
            break
        case ActionTypes.READ_NOTIFICATION:
            url = 'notifications/read/'+action.payload
            apiPut(url, null, () => dispatch(didReadNotification(action.payload)), e => notifyApiFailure("marking notification as read", 'PUT', url, data, e.status))
            break
        case ActionTypes.UNREAD_NOTIFICATION:
            url = 'notifications/unread/'+action.payload
            apiPut(url, null, () => dispatch(didUnreadNotification(action.payload)), e => notifyApiFailure("marking notification as unread", 'PUT', url, data, e.status))
            break
        case ActionTypes.READ_ALL_NOTIFICATIONS:
            url = 'notifications/read/all'
            apiPut(url, null, () => dispatch(didReadAllNotifications()), e => notifyApiFailure("marking all notifications as read", 'PUT', url, data, e.status))
            break
        default:
            break
    }
}

export default NotificationApiMiddleware