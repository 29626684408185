/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { convertColumns, getFilter, shouldReportInfoChangeUpdateData, sortDataRows, getKpiValue } from '../../../helpers/ReportHelpers'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import { getSumViewData } from '../../../actions/ReportActions'
import { copyToClipboard, deepClone, hashNValues } from '../../../helpers/GeneralHelpers'
import ShowIf from '../../Generic/ShowIf'
import DataGrid from '../../DataGrid'
import { notifySuccess } from '../../../helpers/NotificationManager'

const mapStateToProps = (state, ownProps) => {
    return {
        sumData: state.Report.sumData,
        aggregationdata: state.Report.aggregationdata,
    }
}

class SumView extends React.Component {
    state = {
        initialLoad: true,
        selectedGroupColumn: null,
        sortColumn: null,
        sortColumnIndex: -1,
        sortDirection: true,
        autoFitArr: [],
        showPercent: []
    }

    dataGrid = React.createRef()
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { fixedSlicerColumns, reportdata, slicers, show } = this.props

        if (fixedSlicerColumns && fixedSlicerColumns.length > 0 && (!this.state.selectedGroupColumn || !fixedSlicerColumns.includes(this.state.selectedGroupColumn))) {
            this.setState({ selectedGroupColumn: fixedSlicerColumns[0] })
        }

        if (this.state.selectedGroupColumn && (((shouldReportInfoChangeUpdateData(prevProps.reportdata, reportdata) || JSON.stringify(slicers) !== JSON.stringify(prevProps.slicers)) && show) || (show && !prevProps.show) || this.state.initialLoad || prevState.selectedGroupColumn !== this.state.selectedGroupColumn)) {
            this.setState({ initialLoad: false })
            this.getSumAggregation()

            const viewSettings = JSON.parse(reportdata.report.view_settings)
            if(viewSettings.actual && viewSettings.actual.sum) {
                if (viewSettings.actual.sum.autoFitArr && viewSettings.actual.sum.autoFitArr.length > 0 && this.state.autoFitArr.length === 0) {
                    this.setState({ autoFitArr: viewSettings.actual.sum.autoFitArr })
                }

                if(viewSettings.actual.sum.show_percent !== undefined && viewSettings.actual.sum.show_percent.length > 0 && this.state.showPercent.length === 0) {
                    this.setState({ showPercent: viewSettings.actual.sum.show_percent })
                }
            }
        }
    }

    setAutoFitArr(arr) {
        this.setState({ autoFitArr: arr }, this.saveViewSettings)
    }

    getKPIKey() {
        const reportdata = this.props.reportdata
        const limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        const filter = getFilter(reportdata.report, this.props.slicers)
        return hashNValues(limit, filter)
    }

    getSumAggregation() {
        const { dispatch } = this.props
        const limit = this.props.limit !== -1 ? this.props.limit : this.props.reportdata.report.limit
        const kpis = JSON.parse(this.props.reportdata.report.kpis)

        if(kpis.length === 0 || !this.state.selectedGroupColumn) return

        const data = {
            group_column: this.state.selectedGroupColumn,
            aggregation_columns: kpis.map(kpi => kpi.column),
            aggregation_methods: kpis.map(kpi => kpi.aggregation),
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction,
            limit: limit,
        }

        if(this.props.enabled && !this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            dispatch(getSumViewData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, data, this.getKPIKey()))
        }
    }

    getSortColumn(report) {
        // The priority of the settings goes as follows when the view is loaded the first time: 
        // - EditMode settings
        // - Session settings
        // - Find first column
        // When the view is first loaded 'this.state.sortColumn' will be null and we will enter the first else-statement. If the user selects their own column the session settings will be used.
        let sortColumn = ""
        const columns = JSON.parse(report.kpis).map(k => k.column)
        columns.push(this.state.selectedGroupColumn)
        if (this.state.sortColumn !== null) {
            sortColumn = this.state.sortColumn
        } else {
            const viewSettings = JSON.parse(report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            const editModeColumn = viewSettings.actual?.sum?.sort_column ?? ""
            if (editModeColumn && editModeColumn !== "") {
                sortColumn = editModeColumn
            } else {
                sortColumn = this.state.selectedGroupColumn
            }
        }
        const index = columns.indexOf(sortColumn)
        if (index === -1) { // if we have a column that is no longer in the report because of bucket change
            sortColumn = this.state.selectedGroupColumn
        }
        if(this.props.forPrint){ // we use the sortColumn set in secret component instead, when used for generating pdf
            sortColumn = this.props.selectedSortColumn
        } 
        return sortColumn
    }

    getSortDirection() {
        let sortDirection = true
        if (this.state.sortDirection !== null) {
            sortDirection = this.state.sortDirection
        } else {
            const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            if (viewSettings.actual.sum) {
                sortDirection = viewSettings.actual.sum.sort_direction
            }
        }
        if(this.props.forPrint){ // we use the sortDirection set in secret component instead, when used for generating pdf
            sortDirection = this.props.sortDirection
        } 
        return sortDirection
    }

    setSortColumn(column, direction) {
        this.setState({ 
            sortColumn: column.name, 
            sortColumnIndex: column.index, 
            sortDirection: direction
        }, this.saveViewSettings)
    }

    getSumViewSettings() {
        return {
            autoFitArr: this.state.autoFitArr,
            sort_column: this.state.sortColumn,
            sort_column_index: this.state.sortColumnIndex,
            sort_direction: this.state.sortDirection,
            show_percent: this.state.showPercent
        }
    }

    getKpiValueAdapter = (kpi, KPIAggregationData) => {
        const copiedKPI = deepClone(kpi)
        if(kpi.aggregation === "min" || kpi.aggregation === "max" || kpi.aggregation === "avg") {
            copiedKPI.aggregation = "sum"
        }
        const value = getKpiValue(copiedKPI, KPIAggregationData)
        if(value === "-") return value

    }

    getKPIValue = (kpi, aggregationData) => {
        const kpiAggregation = aggregationData.get(kpi?.column, null)?.total
        if(!kpiAggregation) return '-'
        return kpiAggregation[kpi.aggregation] ?? '-'
    } 

    onClickPercent = showPercent => {
        this.setState({ showPercent }, this.saveViewSettings)
    }

    saveViewSettings = () => {
        if (this.props.editMode) {
            let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            viewSettings.actual.sum = this.getSumViewSettings()
            this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
        }
    }

    copyDataToClipboard = rows => {
        copyToClipboard(this.dataGrid.current.getHTML(false, true))
        notifySuccess(rows + ' rows has been copied to the clipboard')
    }

    render() {
        const { show, reportdata, sumData, fixedSlicerColumns } = this.props

        if(!show) return null

        const kpis = JSON.parse(reportdata.report.kpis)
        let groupColumn = this.state.selectedGroupColumn ?? ""
        if(this.props.forPrint){ // we use the selectedcolumn from set in secret component when used for generating pdf
            groupColumn = this.props.selectedGroupColumn ?? ""
        }
        const aggregations = ["", ...kpis.map(k => k.aggregation)]
        
        const columns = convertColumns([groupColumn, ...kpis.map(k => k.column)], reportdata).map((c,i) => {
            c.index = i
            if(i === 0) {
                c.disablePercent = true
            } else {
                c.showDecimals = !!this.state.showPercent[i] || kpis.find(kpi => kpi.column === c.name)?.showKPIDecimals
            }
            return c
        })
        
        let kpiAggregationData = this.props.aggregationdata.get2(this.getKPIKey(), reportdata.report.report_id)
        if(this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            kpiAggregationData = this.props.secretAggregationData.get2(this.getKPIKey(), reportdata.report.report_id)
        }
        
        const totalRow = ["Total", ...kpis.map(k => this.getKPIValue(k, kpiAggregationData))]
        
        let data = (sumData.get(this.getKPIKey(), reportdata.report.report_id, groupColumn, {aggregation_values:[]}).aggregation_values||[]).slice()
        if(this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            data = (this.props.secretSumData.get(this.getKPIKey(), reportdata.report.report_id, groupColumn, {aggregation_values:[]}).aggregation_values||[]).slice()
        }
        const sortColumn = this.getSortColumn(reportdata.report)
        const sortIndex = columns.findIndex(c => c.name === sortColumn)
        const sortDirection = this.getSortDirection()
        
        sortDataRows(sortColumn, sortIndex, sortDirection, data, reportdata)
        
        data.unshift(totalRow)
        data = data.map(d => {
            //If percent is activated for column, change value to percent
            const data = d.map((v,i) => i > 0 && this.state.showPercent[i] ? v*100/this.getKPIValue(kpis[i-1], kpiAggregationData) : v)
            return {data, key: d[0]}
        })

        const limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit

        return (
            <div>
                <div className="col-md-12 no-padding margin-top-20px text-align-center">
                    <ShowIf if={fixedSlicerColumns.length === 0 || kpis.length === 0}>
                        <div className="alert alert-warning report-view-alert"><i className="fa fa-exclamation-triangle"/> You need at least one fixed slicer and one KPI to use this view!</div>
                    </ShowIf>
                    <ShowIf if={fixedSlicerColumns.length > 0 && kpis.length > 0}>
                        {/* Dont show when used for printing pdf */}
                        <ShowIf if={!this.props.forPrint}> 
                            <button onClick={() => this.copyDataToClipboard(data.length)} className="btn btn-default btn-sm margin-top-15px float-right position-relative z-index-1000">
                                <span className="fa fa-copy"/>
                            </button>
                        </ShowIf>
                        <div className="compose-navigation sum-sheet-navigation" style={{position: "sticky", top: 50}}>
                            {
                                fixedSlicerColumns.map(column => (
                                    <div key={column} className={`compose-tab ${groupColumn === column ? "active-compose-tab" : ""}`} onClick={() => this.setState({ selectedGroupColumn: column })}><span>{ column }</span></div>
                                ))
                            }
                        </div>
                        <DataGrid
                            className="sum-sheet-table auto-width"
                            ref={this.dataGrid}
                            columns={columns}
                            rows={data}
                            defaultSortColumn={sortColumn}
                            defaultSortDirection={sortDirection}
                            sticky={104}
                            onSort={(col, direction) => this.setSortColumn(col, direction)}
                            autoFitArr={this.state.autoFitArr}
                            setAutoFitArr={(arr) => this.setAutoFitArr(arr)}
                            onClickPercent={this.onClickPercent}
                            enablePercent
                            disableCopyID
                            isSum={true}
                            clickReport={val => this.props.clickReport(groupColumn, val, limit)}
                            activeCategoryColumn={this.props.reportdata.info.model.categorization_name}
                            aggregations={aggregations}
                        />
                    </ShowIf>
                </div>
            </div>

        )
    }
}


const shouldComponentUpdateSettings = (prevState, curState) => {
    return (prevState.sortDirection !== curState.sortDirection ||
        prevState.sortColumn !== curState.sortColumn ||
        prevState.sortColumnIndex !== curState.sortColumnIndex ||
        prevState.showPercent !== curState.showPercent ||
        prevState.selectedGroupColumn !== curState.selectedGroupColumn ||
        prevState.autoFitArr !== curState.autoFitArr)
}

const getSettingsKeys = (state) => {
    let { initialLoad, ...settings } = state
    return Object.keys(settings)
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => {
    if (_this.dataGrid.current) {
        _this.dataGrid.current.setSorting(_this.state.sortDirection, _this.state.sortColumn)
        _this.dataGrid.current.setAutoArr(_this.state.autoFitArr)
        _this.dataGrid.current.setShowPercent(_this.state.showPercent ?? [])
    }
}

SumView = withSettingsPropagation(SumView, getSettingsKeys, shouldComponentUpdateSettings, null, settingsDidApply, "sum")

SumView = connect(mapStateToProps)(SumView)

export default SumView