import { matchPath, match } from 'react-router'
import queryString from 'query-string'

export const reportMatchPath = "/report/:repId/:section"
export const reportHashMatchPath = reportMatchPath + "/:sHash"
export const checklistMatchPath = "/checklist/:checklistID"
export const itemActionPath = "item/:bucketId/:itemId"

export const getCurrentPathMatch = (match:string): match<{[key:string]:string}>|null => {
    return matchPath(window.location.hash.substring(1), match)
}

export const getPathMatchFromContext = (context:string) => {
    let match: match<{[key:string]:string}>|null
    switch(context) {
        case "report":
            match = getCurrentPathMatch(reportMatchPath)
            if(match === null) match = getCurrentPathMatch(reportHashMatchPath)
            break
        case "checklist":
            match = getCurrentPathMatch(checklistMatchPath)
            break
        default:
            match = null
            break
    }
    return match
}

export const getContextFromURL = () => {
    if(window.location.hash.includes("report")) return "report"
    if(window.location.hash.includes("checklist")) return "checklist"
    return "unknown"
}

export const getContextId = (match: match<{[key:string]:string}>|null, context: string): string|null => {
    if(!match) return null
    switch (context) {
        case "report":
            return match.params.repId ?? null
        case "checklist":
            return match.params.checklistID ?? null
        default:
            return null
    }
}

export const getSearchContext = () => {
    let context = getContextFromURL()
    let match = getPathMatchFromContext(context)
    if(match === null) {
        return {
            context: 'unknown',
            contextId: "-1"
        }
    }
    return {
        context,
        contextId: getContextId(match, context)
    }
}

export const getURLQueryParameter = (q:string, parameter:string, defaultValue:any, options: queryString.ParseOptions) => {
    let query = queryString.parse(q, options)
    if(query[parameter] === undefined) query[parameter] = defaultValue
    return query[parameter]
}