import React from 'react'
import LoadingIcon from './LoadingIcon'
import ShowIf from './Generic/ShowIf'
import WordWrap from './Generic/WordWrap'
import { showMasterDataDialog } from '../helpers/MasterDataManager'

import '../css/search.css'

const RESULTS_PER_PAGE = 100

class NavigationBarSearch extends React.Component {
    state = {
        selected: -1
    }

    getResultItemJSX(id, data, bucketResult, selected = false, context = "unknown", context_name = "") {
        let description = ""
        if (bucketResult.id_description_column) {
            let index = bucketResult.columns.indexOf(bucketResult.id_description_column)
            description = data[index]
        }
        return (
            <div onClick={_ => {showMasterDataDialog(bucketResult.bucket_id, id, true); this.props.onClose();}} key={id}>
                <div className={`search-result-item ${selected ? 'selected-search-item' : ""}`}>
                    <div className="search-result-item-title">
                        <b>
                            {bucketResult.id_column}: {id}
                        </b>
                    </div>
                    <ShowIf if={description && bucketResult.id_description_column}>
                        <div className="search-result-item-description">
                            <WordWrap>
                                <i>
                                    {description}
                                </i>
                            </WordWrap>
                        </div>
                    </ShowIf>
                    <ShowIf if={context !== "unknown"}>
                        <div className="search-result-item-info">
                            <WordWrap>
                                <b>
                                    <ShowIf if={context === "report"}>Insight: </ShowIf>
                                    <ShowIf if={context === "checklist"}>Action list: </ShowIf>
                                </b>
                                {context_name}
                            </WordWrap>
                        </div>
                    </ShowIf>
                    <div className="search-result-item-info">
                        <WordWrap>
                            <b>Data source:</b> {bucketResult.bucket_name}
                        </WordWrap>
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchText === "" && this.props.searchText !== "") {
            this.setState({ selected: -1 })
        }
    }

    setSelectedIndex(_s) {
        let results = this.getNumberOfResults()
        let max = Math.min(results, RESULTS_PER_PAGE) - 1
        let s = _s < 0 ? 0 : _s >= max ? max : _s
        this.setState({ selected: s })
    }

    getSelectedIndex() {
        return this.state.selected
    }

    getSelected() {
        let searchResult = this.props.searchResult
        if (searchResult) {
            let contextResult = searchResult.context_result
            let bucketResults = searchResult.bucket_results
            let totalIndex = 0

            if (contextResult.items) {
                let contextResultEntries = Object.entries(contextResult.items)
                for (let i = 0; i < contextResult.count; i++) {
                    if (totalIndex === this.state.selected) return { itemId: contextResultEntries[i][0], bucketId: contextResult.bucket_id }
                    totalIndex++
                }
            }

            for (let j = 0; j < bucketResults.length; j++) {
                let br = bucketResults[j]
                let bucketEntries = Object.entries(br.items)
                for (let i = 0; i < br.count; i++) {
                    if (totalIndex === this.state.selected) return { itemId: bucketEntries[i][0], bucketId: br.bucket_id }
                    totalIndex++
                }
            }
        }

        return null
    }

    numberOfItemsToShowForBucketIndex(total, index) {
        let searchResult = this.props.searchResult
        if (!searchResult) return 0
        let buckets = searchResult.bucket_results
        let n = total
        for (let i = 0; i < index; i++) {
            n -= buckets[i].count
        }
        if (n < 0) return 0
        return Math.min(n, buckets[index].count)
    }

    getNumberOfResults() {
        let searchResult = this.props.searchResult
        if (searchResult) {
            return searchResult.context_result.count + searchResult.bucket_results.reduce((acc, br) => acc + br.count, 0)
        }
        return 0
    }

    getTotalItemIndex(bucketIndex, itemIndex) {
        //bucketIndex === null for context result
        let searchResult = this.props.searchResult
        if (!searchResult) return 0
        if (bucketIndex === null) return itemIndex
        else return searchResult.context_result.count + searchResult.bucket_results.reduce((acc, br, i) => i < bucketIndex ? acc + br.count : acc, 0) + itemIndex
    }

    render() {
        let searchResult = this.props.searchResult
        let contextResult = searchResult ? searchResult.context_result : null
        let contextResultEntries = contextResult && contextResult.count > 0 ? Object.entries(contextResult.items) : []
        let bucketResults = searchResult ? searchResult.bucket_results : []
        let bucketResultsToShow = contextResult ? RESULTS_PER_PAGE - contextResult.count : 0
        return (
            <div className={`dropdown-menu search-dropdown-menu cancel-a-style ${this.props.searchOpen ? "show" : ""}`} role="menu">
                <ShowIf if={!searchResult && this.props.searchText}>
                    <LoadingIcon className="margin-10px" size="small" center={true} color="white" loading={true} />
                </ShowIf>
                <ShowIf if={contextResult}>
                    {
                        contextResultEntries.map((e, i) => i < RESULTS_PER_PAGE ? this.getResultItemJSX(e[0], e[1], contextResult, this.getTotalItemIndex(null, i) === this.state.selected, searchResult.context, searchResult.context_name) : null)
                    }
                </ShowIf>
                <ShowIf if={contextResult && contextResult.count > 0 && bucketResultsToShow > 0}>
                    <hr className="no-margin" />
                </ShowIf>
                {
                    bucketResults.map((br, i) => {
                        let numberToShow = this.numberOfItemsToShowForBucketIndex(bucketResultsToShow, i)
                        if (numberToShow > 0) {
                            let bucketItemEntries = Object.entries(br.items)
                            return bucketItemEntries.map((e, j) => numberToShow > j ? this.getResultItemJSX(e[0], e[1], br, this.getTotalItemIndex(i, j) === this.state.selected) : null)
                        }
                        return null
                    })
                }
                <ShowIf if={searchResult && this.getNumberOfResults() === 0}>
                    <div className="no-search-results">
                        No search results for: <b>{this.props.searchText}</b>
                    </div>
                </ShowIf>
            </div>
        )
    }
}

export default NavigationBarSearch