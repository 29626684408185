import React, { useMemo } from 'react'
import { DashboardFull, DashboardKPI, DashboardQuery, DashboardReportRow, ParsedDashboardData, User, RowType, DashboardRow, LibraryReport } from '../../types/transfertypes'

import '../../css/DashboardAdmin.css'
import ShowIf from '../Generic/ShowIf'
import { getKpiTotalsNew } from '../../helpers/DashboardHelpers'
import { formatValue } from '../../helpers/ReportHelpers'

interface ClickableDashboardTableProps {
    dashboard: DashboardFull,
    parsedDashboard: ParsedDashboardData,
    user: User,
    selectedRow?: string,
    selectedKpi?: string,
    onCellClick: (row: string, kpi: string) => void,
    reports: LibraryReport[],
    disabled?: boolean,
}

const ClickableDashboardTable = (props: ClickableDashboardTableProps) => {

    const total = useMemo(() => getKpiTotalsNew(props.dashboard.table_data, props.parsedDashboard.kpis, props.parsedDashboard.main_row), [props.dashboard, props.parsedDashboard])
    const shownRows = useMemo(() => props.parsedDashboard?.rows.filter(v => !v.hidden) ?? [], [props.parsedDashboard])
    const hiddenRows = useMemo(() => props.parsedDashboard?.rows.filter(v => v.hidden) ?? [], [props.parsedDashboard])

    const getDisplayValue = (value: number, kpi: DashboardKPI, kpiNumber: number) => {
        let tmpValue = kpi.percent ? value * 100 / total[kpiNumber] : value
        let tmp = formatValue(true, tmpValue, kpi.decimal)
        if (kpi.percent) {
            return `${tmp}%`
        }
        return tmp
    }

    const getTableRowReport = (report: DashboardReportRow, key: any, classes: string = '') => {
        let r = props.reports.find(v => v.report.report_id == report.report_id)
        if (r == undefined) return <></>

        return <tr key={key} className={`kpi-tr kpi-tr-click ${classes ?? ''}`}>
            <td className='kpi-td'>
                <ShowIf if={r.report.is_public}>
                    <i className="fa fa-file fa-fw report-icon" aria-hidden="true" title='Public Insight'></i>
                </ShowIf>
                <ShowIf if={!r.report.is_public}>
                    {
                        r.report.user_id === props.user.user_id ?
                            <i className="fa fa-unlock-alt fa-fw report-icon" aria-hidden="true" title='Your private Insight'></i>
                            :
                            <i className="fa fa-lock fa-fw report-icon" aria-hidden="true" title='Other persons private Insight'></i>
                    }
                </ShowIf>
                {report.label != "" ? report.label : r.report.name}
            </td>
            {
                props.parsedDashboard.kpis.map((kpi, i) => {
                    if (r == undefined) return null
                    let data = props.dashboard.table_data[kpi.kpi_key]?.[r.report.report_id]
                    let selected = props.selectedRow == `${r.report.report_id}` && props.selectedKpi == kpi.kpi_key
                    return (
                        <td className={`kpi-td text-align-right abc-click ${selected ? 'selected-cell' : ''}`} onClick={() => props.onCellClick(`${report.report_id}`, kpi.kpi_key)} key={`td${i}`}>
                            {
                                data != null ?
                                    getDisplayValue(data.last, kpi, i)
                                    :
                                    "N/A"
                            }

                        </td>
                    )
                })
            }

        </tr>
    }

    const getTableRowFilter = (q: DashboardQuery, key: any, classes: string = '') => {
        return <tr key={key} className={`kpi-tr kpi-tr-click ${classes ?? ''}`}>
            <td className='kpi-td'>
    <i className="fa fa-filter fa-fw report-icon" aria-hidden="true" title='Filter'></i>
                {q.label}
            </td>
            {
                props.parsedDashboard.kpis.map((kpi, i) => {
                    //@ts-ignore
                    let data = props.dashboard.table_data[kpi.kpi_key]?.[q.id]
                    let selected = props.selectedRow == q.id && props.selectedKpi == kpi.kpi_key
                    return (
                        <td className={`kpi-td text-align-right abc-click ${selected ? 'selected-cell' : ''}`} onClick={() => props.onCellClick(q.id, kpi.kpi_key)} key={`td${i}`}>
                            {
                                data != null ?
                                    getDisplayValue(data.last, kpi, i)
                                    :
                                    "N/A"
                            }
                        </td>
                    )
                })
            }
        </tr>
    }

    return (
        <table className='kpi-table' style={props.disabled ? {opacity: 0.5, pointerEvents: "none"} : {}}>
            <thead className='kpi-thead'>
                <tr className='kpi-tr'>
                    <th className='kpi-th'></th>
                    {props.parsedDashboard.kpis.map((kpi, i) => {
                        return <th className='kpi-th text-align-right' key={i}>
                            {kpi.label != "" ? kpi.label : kpi.column}
                        </th>
                    })}

                </tr>
            </thead>
            <tbody className='kpi-tbody'>
                {!(props.parsedDashboard.main_row as DashboardRow).hidden ? getTableRowReport(props.parsedDashboard.main_row.row as DashboardReportRow, "main", "bold") : null}
                {shownRows.map((v, i) => {
                    switch (v.type) {
                        case RowType.Report:
                            return getTableRowReport(v.row as DashboardReportRow, i)
                        case RowType.Filter:
                            return getTableRowFilter(v.row as DashboardQuery, i)
                        default:
                            return null
                    }
                })}
                {
                    hiddenRows.length > 0 || (props.parsedDashboard.main_row as DashboardRow).hidden ?
                    <>
                        <tr className="kpi-tr kpi-tr-label kpi-tr-click"><td className="kpi-td">Hidden rows:</td></tr>
                        {(props.parsedDashboard.main_row as DashboardRow).hidden ? getTableRowReport(props.parsedDashboard.main_row.row as DashboardReportRow, "main", "bold") : null}
                        {hiddenRows.map((v, i) => {
                            switch (v.type) {
                                case RowType.Report:
                                    return getTableRowReport(v.row as DashboardReportRow, i)
                                case RowType.Filter:
                                    return getTableRowFilter(v.row as DashboardQuery, i)
                                default:
                                    return null
                            }
                        })}
                    </>
                    : null
                }
            </tbody>

        </table>
    )
}

export default ClickableDashboardTable
