import React, { useEffect, useState } from 'react'
import { formatValue } from '../../helpers/ReportHelpers'
import SingleItemColumnBoxEdit from './SingleItemColumnBoxEdit'
import LoadingIcon from '../LoadingIcon'


export default function SingleItemColumnBoxSingle(props) {

    const [editing, setEditing] = useState(false);
    const [columnDeleted, setColumnDeleted] = useState(false)

    function editBoxHandler(e) {
        setEditing(true)
    }

    function cancelEditHandler(e) {
        setEditing(false)
    }

    function columnBoxChangedHandler(e, data) {
        props.columnBoxChanged(e, data)
        if(e.columnName === "") {
            setColumnDeleted(true)
            setEditing(false)
        }
    }

    useEffect(()=> {
        const interval = setInterval(() => {
            if(props.val === null ) {
                if (props.column != "") {
                    props.loadColumn({ type: "decimal", name: props.column })
                }
            } 
          }, 1000);

          if (props.val !== null) {
            clearInterval(interval)
          }
          return () => clearInterval(interval);
        
    }, [props.column, props.val, props.val2, props.bucket])

    return(
        (!editing) && !props.editMode && (!props.column || columnDeleted) ? <></> :
      
        <div className={`col-md-6 ${props.index % 2 === 0 ? "padding-right-4px" : "padding-left-4px"} padding-top-4px padding-bottom-4px`}>
          
            <div className={`${props.column !== "" || (props.editMode && editing) ? "dashboard-background-small abc-click" : ""} ${props.column === props.decimalColumn.name ? "dashboard-background-small-selected" : ""} col-md-12 no-padding blue-info-box`} onClick={_ => props.column != "" ? props.setColumn({ type: "decimal", name: props.column }) : ""}>
            {props.editMode ? 
                
                (editing) || (!!props.column && !columnDeleted) ? 
                    <>
                        <SingleItemColumnBoxEdit columnBoxChanged={(data) => columnBoxChangedHandler(data)} cancelEdit={cancelEditHandler} bucket={props.bucket} column={props.column} index={props.index}/>
                    </>                  
                     : 
                    <div className="single-item-column-box-add-column">
                        <button onClick={editBoxHandler} className="single-item-column-box-add-column-btn"><i className="fa fa-plus"></i></button>
                    </div> 
            : 
         
               ( <> 
                {
                    props.val === null ? 
                    <div className="single-item-column-box-loading">

                        <LoadingIcon size="small" loading={true} className="block" />
                    </div>
                    :
    
                <div className="cn height-100-p">
                    <div className="">
                        {
                            props.val &&
                                <span className="h1-fix-small">
                                    {
                                        props.val2 &&
                                        <span className={`margin-right-5px font-size-18px top-0 ${props.val.value > props.val2.value ? 'fa fa-arrow-up' : props.val.value < props.val2.value ? 'fa fa-arrow-down' : "" } `}></span>
                                    }
                                    {formatValue(true, props.val.value, (Math.abs(props.val.value) < 100 && props.val.value % 1 !== 0 ))}
                                </span>
                        }
                    </div>
                    <div className="">
                        {
                            !!props.val &&
                                <span>
                                    <h4 className="h4-fix pre-wrapper text-align-center">{props.column}</h4>
                                </span>
                               
                        }
                    </div>
                </div>
             }
                    </>
               )}
                    
            </div>
        </div>
    )
}
