import React from 'react'
import { findDOMNode } from 'react-dom'
import '../../css/notifications.css'
import { connect } from 'react-redux'
import { reportError, commentReportError } from '../../actions/SystemActions'
import ShowIf from '../Generic/ShowIf'
import { getSettingsFromPath } from '../../helpers/SettingsService'

const NOTIFICATION_TYPES = [{name: "success", displayTime: 5000}, {name: "failure", displayTime: 7000}]

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.info,
        errors: state.System.errors
    }
}
class Notification extends React.Component {
    timeout

    state = {
        comment: "",
        showComment: false
    }

    componentDidMount() {
        let displayTime = NOTIFICATION_TYPES.find(n => n.name === this.props.type).displayTime
        this._isMounted = true
        if(this.props.addObserver && this.props.removeObserver){
            this.props.addObserver({id: this.props.id, dismiss: this.dismiss})
        }
        this.initAnimation(() => this.timeout = setTimeout(() => this.dismissAnimation(() => this.props.onDismiss()), displayTime))
        if(this.props.sendErrorReport && this.props.type === "failure" && this.props.user.user_id) this.report()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    initAnimation(fn) {
        if(this._isMounted) window.$(findDOMNode(this)).animate({opacity: 1.0}, 750, 'swing', fn)
    }

    dismissAnimation(fn) {
        if(this._isMounted) window.$(findDOMNode(this)).animate({opacity: 0.0}, 750, 'swing', fn)
    }

    dismiss = () => {
        clearTimeout(this.timeout)
        if(this.props.addObserver && this.props.removeObserver){
            this.props.removeObserver(this.props.id)
        }
        this.dismissAnimation(() => this.props.onDismiss())
    }

    report(){
        //if the error happened on '#/report/...', 
        //get the current settings if they exist and add them to the error info
        if(window.location.hash.includes("report")) {
            getSettingsFromPath(settings => {
                this.props.info.report_settings = settings
            }, () => {})
        }
        this.props.dispatch(reportError(this.props.info, 1, this.props.id))
    }

    toggleComment() {
        if(!this.state.showComment) {
            clearTimeout(this.timeout)
        } else {
            let displayTime = NOTIFICATION_TYPES.find(n => n.name === this.props.type).displayTime
            this.timeout = setTimeout(() => this.dismissAnimation(() => this.props.onDismiss()), displayTime)
        }
        this.setState({showComment: !this.state.showComment, comment: ""})
    }

    commentTimeout
    sendComment() {
        clearTimeout(this.commentTimeout)

        let send = (eId) => {
            this.props.dispatch(commentReportError(eId, this.state.comment))
            this.setState({showComment: false, comment: ""}, () => {
                this.dismiss()
            })
        }

        let errorReportId = this.props.errors.get(this.props.id, {id: -1}).id
        
        if(errorReportId === -1) {
            //Try again in a moment if couldn't find the error report
            this.commentTimeout = setTimeout(() => {
                errorReportId = this.props.errors.get(this.props.id, {id: -1}).id
                if(errorReportId !== -1) {
                    send(errorReportId)
                } else {
                    //Give up sending the comment
                    this.setState({showComment: false})
                }
            }, 1000)
        } else {
            send(errorReportId)
        }
    }
    
    render() {
        let message = this.props.type === "failure" ? <span><span className="abc-notification-error-text" >Error. &nbsp;</span> {this.props.message}</span> : this.props.message
        return (
            <div className={`abc-notification abc-notification-${this.props.type}`} style={Object.assign({opacity: 0.0}, this.props.style ?? {})}>
                <div className="abc-notification-left-accent"></div>
                <div className="abc-notification-content">
                    <div className="abc-notification-text">
                        {message}&nbsp;
                        <ShowIf if={this.props.sendErrorReport && this.props.type === "failure" && this.props.user.user_id}>
                            <span className="btn btn-xs btn-default" onClick={() => this.toggleComment()}>Comment error <span className="fa fa-bullhorn"/></span>
                        </ShowIf>
                    </div>
                    <ShowIf if={this.state.showComment}>
                        <textarea className="abc-notification-comment form-control" value={this.state.comment} onChange={e => this.setState({comment: e.target.value})}/>
                        <div className="btn btn-xs btn-default abc-notification-action-button" onClick={() => this.sendComment()}>Send</div>
                        <div className="btn btn-xs btn-default abc-notification-action-button" onClick={() => this.toggleComment()}>Cancel</div>
                    </ShowIf>
                </div>
                <div className="abc-notification-dismiss"><span className="fa fa-times-sign" onClick={() => this.dismiss()}/></div>
            </div>
        ) 
    }
}

export default connect(mapStateToProps)(Notification)