import React from 'react'
import posed from 'react-pose'

class Drawer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            overflow: props.expand,
            initialLoad: true
        }
    }
    
    componentDidUpdate() {
        this.setState({initialLoad: false})
    }

    defaultDuration = 500

    expanded = {
        height: this.props.height ? this.props.height : 'auto',
        transition: () => this.props.skipFirstUpdate && this.state.initialLoad ? false : {
            duration:  this.props.duration ? this.props.duration : this.defaultDuration 
        }
    }

    closed = {
        height: 0,
        transition: () => this.props.skipFirstUpdate && this.state.initialLoad ? false : {
            duration:  this.props.duration ? this.props.duration : this.defaultDuration 
        }
    }

    Div = posed.div({
        expanded: this.expanded,
        closed: this.closed
    })

    onAnimationComplete = (currentPose) => {
        if(!this.props.expand) this.setState({overflow: false})
        else this.setState({overflow: true})

        if(this.props.onPoseComplete) this.props.onPoseComplete(currentPose)
    }

    render() {
        return (
            <this.Div 
                pose={this.props.expand ? 'expanded' : 'closed'}
                id={this.props.id} 
                className={(this.state.overflow && this.props.expand ? "overflow-visible " : "overflow-hidden ") + this.props.className + " w-100"} 
                onPoseComplete={this.onAnimationComplete}
                withParent={false}
            >
                {this.props.children}
            </this.Div>
        )
        
    }
}

export default Drawer