import React from 'react'
import UserOptionDialog from './UserOptionDialog'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class AssignUserDialog extends React.Component {
    state = {
        message: "",
        useDeadline: false
    }

    showEdit(defaultId, description, defaultDate, defaultUseDeadline){
        this.setState({message: description, date:defaultDate, useDeadline: defaultUseDeadline})

        this.refs.userDialog.show(defaultId)
    }

    show(defaultId, deaultMessage) {
        if(deaultMessage) this.setState({message: deaultMessage}) 
        else this.setState({message: this.props.description, date: this.props.defaultDate, useDeadline: this.props.defaultUseDeadline})
        this.refs.userDialog.show(defaultId)
    }

    hide() {
        this.refs.userDialog.hide()
    }

    updateDate(d) {
        this.setState({date: d})
    }

    render() {
        return (
            <UserOptionDialog ref="userDialog" showUserPredicate={this.props.showUserPredicate} title={this.props.title} saveHandler={(id) => this.props.saveHandler(id, this.state.message, this.state.useDeadline, this.state.date)} closeHandler={this.props.closeHandler} users={this.props.users} sort={this.props.sort}>
                <p className="margin-top-10"><b>Description (Optional):</b></p>
                <textarea  value={this.state.message} onChange={e => this.setState({message: e.target.value})} className="form-control"/>
                <p className="margin-top-10"><b>Deadline (Optional):</b></p>

                <DatePicker className="form-control" selected={this.state.date} dateFormat="DD-MM-YYYY" onChange={(m)=> this.updateDate(m)} disabled={!this.state.useDeadline} />               
                <button className={`btn btn-default btn-xs margin-left-5px fa margin-top-6px vertical-align-top`} onClick={() => this.setState({useDeadline: !this.state.useDeadline, date: this.state.useDeadline ? null : moment()})}>
                    <span className={`fas ${this.state.useDeadline ? "fa-check" : "fa-minus"}`}/>
                </button>
            </UserOptionDialog>
        )
    }
}

export default AssignUserDialog