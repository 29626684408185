import React, { ReactNode } from 'react'

export interface ShowIfProps {
    if: boolean,
    children: ReactNode
}

const ShowIf  = (props: ShowIfProps) => {
    return <>
        {
            props.if ?
                props.children
                : null
        }
    </>
    
}

export default ShowIf
