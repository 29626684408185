import React, { useState } from "react"
import { updateMultiUsersPermissions } from "../../actions/UserActions"
import OrganizationAdministrationEditPermissions from "./OrganizationAdministrationEditPermissions"
import ReactTooltip from "react-tooltip"

export default function OrganizationAdministrationMultiDialog(props) {
    const [showExitPrompt, setShowExitPrompt] = useState(false)
    const [permissions, setPermissions] = useState([])

    function permissionsChangedHandler(perms) {
        setPermissions(perms)
    }

    function getPermissions(){
        let allPerms = props.selected.map((s) => s.permissions)

        let allEqual = true
        if(allPerms.length > 1){
            for(let i = 0; i < allPerms.length - 1  && allEqual; i++){
                let first = allPerms[i] ? allPerms[i].sort() : []
                let second = allPerms[i+1] ? allPerms[i+1].sort() : []

                if(first.length !== second.length){
                    allEqual = false
                }
                else{
                    for(let j = 0; j < first.length && allEqual; j++){
                        if(first[j] !== second[j]){
                            allEqual = false
                        }
                    }
                }
            }
        }

        if(allPerms.length > 0 && allEqual){
           return allPerms[0]
        }
        else {
            return []
        }
    }

    function permissionsSaved(perms) {
        if (!!perms) props.closeModalHandler()
    }
    function saveChangesHandler(e) {
        props.dispatch(
            updateMultiUsersPermissions(
                props.selected.map((s) => s.id),
                permissions,
                permissionsSaved
            )
        )
    }

    function closeEditModalHandler() {
        exitWithoutSavingHandler()
    }

    function cancelExitPrompt() {
        // setUnsavedChanges(false) // Not defined
        setShowExitPrompt(false)
    }

    function exitWithoutSavingHandler() {
        setShowExitPrompt(false)
        props.closeModalHandler()
    }

    return (
        <div className="">
            <div id="edit-user-modal" className={"org-admin-modal modal " + (props.shown ? "display-block show" : "")} tabIndex="-1" role="dialog">
                {!!props.shown && (
                    <div className="modal-dialog mt-5 modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content ">
                            <div className="modal-header edit-user-modal-header">
                                <h4 className="modal-title">
                                    Edit {props.type} for{" "}
                                    <span className="text-primary btn-link" data-tip data-for={"tooltip-multi-select-dialog"}>
                                        {props.selected.length} {props.selected.length === 1 ? "user" : "users"}
                                    </span>
                                    <ReactTooltip id={`tooltip-multi-select-dialog`} type="dark" effect="solid" place="right">
                                        {
                                            <ul className="nav flex-column">
                                                {props.selected.map((s) => (
                                                    <li key={s.id}>
                                                        {s.firstname} {s.lastname}
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    </ReactTooltip>
                                </h4>
                            </div>

                            <div className="modal-body edit-user-modal-body ">
                                <section className="edit-user-tab-content">
                                    <OrganizationAdministrationEditPermissions multiSelect={true} getPermissionsHandler={getPermissions} permissionsChangedHandler={permissionsChangedHandler} />
                                </section>
                            </div>
                            <div className="modal-footer ">
                                {showExitPrompt ? (
                                    <div className="alert alert-warning d-flex align-items-center justify-content-between w-100" role="alert">
                                        <div>
                                            <strong className="mr-2">Unsaved changes</strong>
                                            Are you sure you want to exit?
                                        </div>

                                        <section className="d-flex justify-content-end ml-2 ">
                                            <button className="btn btn-outline-secondary mr-2" onClick={() => cancelExitPrompt()}>
                                                Cancel
                                            </button>
                                            <button onClick={() => saveChangesHandler()} className="btn btn-primary mr-2">
                                                Save
                                            </button>
                                            <button onClick={() => exitWithoutSavingHandler()} className="btn btn-danger">
                                                Close anyway
                                            </button>
                                        </section>
                                    </div>
                                ) : (
                                    <div className={""}>
                                        <section className="d-flex justify-content-end">
                                            <button className="btn btn-outline-primary mr-2" onClick={() => closeEditModalHandler()}>
                                                Close
                                            </button>
                                            <button onClick={() => saveChangesHandler()} className="btn btn-primary">
                                                Save
                                            </button>
                                        </section>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!!props.shown && <div className="fade modal-backdrop show"></div>}
        </div>
    )
}
