import React, { useState } from "react";
import { MailBundleWithRelations, Plan } from "../../types/reportingtypes";
import GenericDialog from "../Dialogs/GenericDialog";
import AlertBanner from "../Alert/AlertBanner";
import { DashboardFull } from "../../types/transfertypes";
import { RiDashboard2Fill } from "react-icons/ri";

interface DashboardMailSignupModalProps {
    show: boolean;
    onClose: () => void;
    onSubscribe: (plan: Plan, include_table: boolean) => void;
    onUnsubscribe: () => void;
    plans: Plan[]
    mailbundle: MailBundleWithRelations | undefined;
    subscription: MailBundleWithRelations | undefined
    subscribed: boolean
    dashboard: DashboardFull
    tableShown: boolean
}

function DashboardMailSignupModal(props:DashboardMailSignupModalProps) {
    const plans = props.plans.slice().sort((a,b) => a.description.localeCompare(b.description))
    const [selectedPlanId, setSelectedPlanId] = useState<number>(plans[0].id)
    const [includeTable, setIncludeTable] = useState<boolean>(props.tableShown)
    
    const getPlan = (plan_id: number): string => {
        var plan = props.plans.find(x => x.id === plan_id);
        if(plan !== undefined){
            let description = plan.description.substring(4).replace("on the", "")
            description = description[0].toLowerCase() + description.slice(1)
            return description
        }
        else return "??"
    }

    const submitHandler = () => {
        if(props.subscribed) {
            props.onUnsubscribe()
        } else {
            props.onSubscribe(props.plans.find(p => p.id === selectedPlanId) ?? props.plans[0], includeTable)
            props.onClose()
        }
    }

    return (
        <GenericDialog
            show={props.show}
            onClose={props.onClose}
            title={props.subscribed 
                ? <span className="d-flex flex-column">Unsubscribe from Dashboard <span className="font-weight-bold h6"><RiDashboard2Fill className="mb-1 mr-1"/> {props.dashboard.dashboard_data.title}</span></span> 
                : <span className="d-flex flex-column">Subscribe to Dashboard <span className="font-weight-bold h6"><RiDashboard2Fill className="mb-1 mr-1"/> {props.dashboard.dashboard_data.title}</span></span>
            }
            getButtons={() => (
                <div className='d-flex'>
                    <button 
                        onClick={props.onClose} 
                        type="button" 
                        className="btn btn-default margin-right-10px" 
                    >
                        Close
                    </button>
                    <button 
                        onClick={submitHandler} 
                        type="button" 
                        className={`${props.subscribed ? "btn-danger" : "btn-primary"} btn margin-right-10px` }
                    >
                        {props.subscribed ? "Unsubscribe" : "Subscribe"}
                    </button>
                </div>
            )}
            style={{maxWidth: 510}}
        >
            {props.mailbundle && 
                <AlertBanner
                    config={{
                        text: <span>
                                You already recieve this Dashboard {getPlan(props.mailbundle.plan_id ?? -1)}.<br/>To <strong>unsubscribe</strong> click the link in the bottom of the mail you recieved, 
                                or contact <a className="" href="mailto:support@inact.io">support@inact.io</a>.
                            </span>,
                        title: "",
                        type: "warning",
                    }}

                />
            }
            {props.subscribed && props.subscription &&
                <AlertBanner
                    config={{
                        text: `You have already subscribed to recieve this Dashboard ${getPlan(props.subscription.plan_id ?? -1)}`,
                        title: "",
                        type: "warning",
                    }}
                />
            }
            {!props.subscribed && <div className='d-flex flex-column px-2 py-4'>
                <span className='font-weight-bold'>
                    Select schedule
                </span>
                <select onChange={(e) => setSelectedPlanId(Number(e.target.value))} className="p-1">
                    {plans.map((p, i) => (
                        <option key={i} value={p.id}>{p.description}</option>
                    ))}
                </select>
                <span className='font-weight-bold mt-4'>
                    Include table
                </span>
                <div className="custom-control custom-switch mb-4">
                    <input 
                        type="checkbox" 
                        className="custom-control-input" 
                        checked={includeTable}  
                        id="include-table-switch" 
                        onChange={(e) => setIncludeTable(e.target.checked)} 
                    />
                    <label className="custom-control-label" htmlFor="include-table-switch"></label>
                </div>
            </div>}
        </GenericDialog>
    )
}

export default DashboardMailSignupModal