import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BgColor from '../Generic/BgColor'
import { login, login2FA, is2FAForced } from '../../actions/UserActions'
import ShowIf from '../Generic/ShowIf'
import Cleave from 'cleave.js/react'
import { getAddress, isLocalhost } from '../../helpers/ApiHelpers'
import { getUsedLocalStorageSpace, getUsedSessionStorageSpace } from '../../helpers/GeneralHelpers'

require('../../css/login.css')

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.User.token,
    }
}

class Login extends React.Component {
    state = {
        error: '',
        loading: false,
        isLoggedIn: false,
        resetClickCount: 0,
        resetMessage: null,
        twoFAEnabled: false,
        passcode: ""
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    doLogin(email, password) {
        let { dispatch } = this.props
        this.setState({
            email: email,
            password: password,
            error: '',
            loading: true,
        })
        let loginAction = login(email, password)
        loginAction.cb = (err, token) => {
            if (err !== null) {
                this.refs.passwordInput.value = ''
                this.refs.passwordInput.focus()

                this.setState({
                    error: err.toString(),
                    password: '',
                })
            } else {
                if (!token["2fa"]) {
                    this.setState({
                        isLoggedIn: true,
                    })
                } else if (token["2fa"]) {
                    this.setState({
                        twoFAEnabled: true
                    }, () => {
                        this.passcodeInput.focus()
                    })
                }
            }

            this.setState({
                loading: false,
            })

            this.props.dispatch(is2FAForced())
        }

        dispatch(loginAction)
    }

    do2FALogin() {
        const { dispatch } = this.props
        this.setState({ loading: true, error: '' })
        let loginAction = login2FA(this.state.email, this.state.password, this.state.passcode)
        loginAction.cb = (err, token) => {
            if (err !== null) {
                this.setState({ error: err.toString(), passcode: '' })
            } else {
                this.setState({ isLoggedIn: true })
            }

            this.setState({ loading: false })
        }
        dispatch(loginAction)
    }

    charCountToKiloByte(chars) {
        return Math.trunc(chars * 2 / 1000)
    }

    handleResetClick() {
        if (this.state.resetClickCount < 4) {
            this.setState({
                resetClickCount: this.state.resetClickCount + 1,
                resetMessage: null,
            })
        } else {

            this.setState({
                resetMessage: `Settings has been cleared. ${this.charCountToKiloByte(getUsedLocalStorageSpace())}kB from local storage and ${this.charCountToKiloByte(getUsedSessionStorageSpace())}kB from session storage was freed up!`
            })

            window.localStorage.clear()
            window.sessionStorage.clear()

            setTimeout(() => {
                if (this.mounted) {
                    this.setState({
                        resetClickCount: 0,
                        resetMessage: null
                    })
                }
            }, 5000)
        }
    }

    // googleSignIn() {
    //     const url = this.props.token ? this.props.token.url : ''
    //     const win = window.open(url, "googleWindow", 'width=800, height=600')
    //     let pollTimer = window.setInterval(() => {
    //         try {
    //             console.log(win.document.URL)
    //             if (win.document.URL.indexOf("127.0.0.1:8000/api/googleAuth") !== -1) {
    //                 let url = win.document.URL
    //             }
    //         } catch(e){}
    //     }, 100)
    // }

    render() {
        let email = this.state.email ? this.state.email : ''
        let password = this.state.password ? this.state.password : ''

        let errorDescription = <></>
        if (this.state.error !== '') {
            errorDescription = (
                <div className="alert alert-danger margin-top-10px">
                    <p><strong>Error logging in</strong>: <span>{this.state.error}</span></p>
                </div>
            )
        }
        let containerClass = 'login-box loginAnimation'
        if (this.state.isLoggedIn) {
            containerClass = `${containerClass} login-done`
        }
        // const url = this.props.token ? this.props.token.url : ''
        return (
            <BgColor bgClass="loginBody">
                <div className='row centered-row'>
                <div className='col-md-7 login-left-side'>
                    <img onClick={() => this.handleResetClick()} src="./img/inact_now_logo.svg" alt="" />
                   
                    <div className='previously'>
                        <p className="login-white-text">Previously ABC Cloud by <a className="white-text" target="_blank" href="https://inact.io" rel="noopener noreferrer">ABC Softwork</a></p>
                    </div>

                </div>
                <div className='col-md-5'>
                <div className={containerClass}>

                    <p className="margin-bottom-20px dark-forest" style={{fontSize:35}}>Log in to <span style={{fontWeight:700}}>Inact Now</span></p>

                    <ShowIf if={!this.state.twoFAEnabled}>
                        <div>

                            <div className="form-signin">
                                <input autoFocus onKeyPress={e => { if (e.charCode === 13) { this.refs.passwordInput.focus() }; }} onChange={(e) => this.setState({ email: e.target.value })} type="email" name="email" className="form-control margin-bottom-10px" placeholder="Email address" required ref="emailInput" />
                                <input ref="passwordInput" onKeyPress={e => { if (e.charCode === 13 && !this.state.loading) { this.doLogin(email, password) }; }} onChange={(e) => this.setState({ password: e.target.value })} type="password" name="password" className="form-control" placeholder="Password" required />

                                <button disabled={this.state.loading} onClick={() => this.doLogin(email, password)} className="btn-tangerine btn margin-top-20px">{this.state.loading ? 'Signing in...' : 'Sign in'}</button>
                                <div className="inline-block float-right forgot"><span className="font-size-12px font-weight-normal"><Link className="tangerine" tabIndex="-1" to={'/forgot'}>Forgot password?</Link></span></div>
                            </div>

                            {
                                isLocalhost() ?
                                    <div>
                                     
                                        <div className="row">
                                            <div className="col-md-5"><hr className="login-hr-2" /></div>
                                            <div className="col-md-2 text-align-center margin-top-10">or</div>
                                            <div className="col-md-5"><hr className="login-hr-2" /></div>
                                        </div>
                                        <div className="text-align-center margin-bottom-20px">continue with</div>
                                        <div className="text-align-center">
                                            <div className="col-md-12 row margin-auto">
                                                <div className="col-md-6 padding-left-0 padding-right-7px">
                                                    <a href={getAddress(`microsoft`)} role="button" className="cancel-a-style">
                                                        <div className="col-md-12 no-padding height-36px login-button-color border-radius-4px row margin-auto">
                                                            <div className="col-md-3 button-border-login-left logo-color padding-left-7px padding-right-7px height-36px button-border-left-radius">
                                                                <i className="fa fa-windows padding-top-11px"></i>
                                                            </div>
                                                            <div className="col-md-9 button-border-login height-36px padding-top-7px button-border-right-radius">
                                                                <div className="remove-a-color">Microsoft</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-6 padding-right-0px padding-left-7px">
                                                    <a href={getAddress(`google`)} role="button" className="cancel-a-style">
                                                        <div className="col-md-12 no-padding height-36px login-button-color border-radius-4px row margin-auto">
                                                            <div className="col-md-3 button-border-login-left logo-color padding-left-7px padding-right-7px height-36px button-border-left-radius">
                                                                <i className="fa fa-google padding-top-11px"></i>
                                                            </div>
                                                            <div className="col-md-9 button-border-login height-36px padding-top-7px button-border-right-radius">
                                                                <div className="remove-a-color">Google</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <p className="margin-top-10px margin-bottom-0 font-size-12px login-gray">(Requires an account on Inact Now)</p>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </ShowIf>

                    <ShowIf if={this.state.twoFAEnabled}>
                        <div>
                            <div className="form-signin">
                                <Cleave
                                    options={{ blocks: [3, 3], numericOnly: true }}
                                    onChange={(e) => this.setState({ passcode: e.target.rawValue })}
                                    className="form-control"
                                    placeholder="2FA passcode"
                                    value={this.state.passcode}
                                    onKeyPress={e => { if (e.charCode === 13 && !this.state.loading) { this.do2FALogin() }; }}
                                    htmlRef={(ref) => this.passcodeInput = ref}
                                />
                            </div>
                            <button disabled={this.state.passcode.length !== 6} onClick={() => this.do2FALogin()} className="btn-tangerine btn margin-top-20px">{this.state.loading ? 'Signing in...' : 'Sign in'}</button>
                        </div>
                    </ShowIf>
                    {errorDescription}
                    <div className="help-text">
                         <p className="font-size-12px"><a className="fix-helper-a tangerine" target="_blank" href="https://help.inact.io" rel="noopener noreferrer">Do you need help?</a></p>
                    </div>

                </div>
               
                </div>
                {this.state.resetMessage ? <p className="login-white-text" style={{ bottom: 0, position: "absolute"}}>{this.state.resetMessage}</p> : null}
                </div>
            </BgColor>
        )
    }
}

Login = connect(mapStateToProps)(Login)

export default Login