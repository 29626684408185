import React from 'react'
import { connect } from 'react-redux'
import { HashRouter, Switch, Route } from 'react-router-dom'
import Login from './Login/Login'
import User from '../views/User'
import ForgotPassword from './Login/ForgotPassword'
import ResetPassword from './Login/ResetPassword'
import NoUser from './Login/NoUser'
import { is2FAForced } from '../actions/UserActions'
import userConfirmation from '../UserConfirmation'
import DashboardSecret from '../views/DashboardSecret'
import DashboardTableSecret from '../views/DashboardTableSecret'
import TrendViewSecret from '../views/reporting/TrendViewSecret'
import MatrixViewSecret from '../views/reporting/MatrixViewSecret'
import KPIViewSecret from '../views/reporting/KPIViewSecret'
import ProfitViewSecret from '../views/reporting/ProfitViewSecret'
import SumViewSecret from '../views/reporting/SumViewSecret'


const mapStateToProps = (state, ownProps) => {
    return {
        loggedIn: state.User.loggedIn,
        force2FA: state.User.force2FA
    }
}

class Protector extends React.Component {
    componentDidMount() {
        this.props.dispatch(is2FAForced())
    }

    render() {
        let innerComponent = null

        if (this.props.loggedIn) {
            innerComponent = this.props.children
        } else {
            innerComponent = (
                <Switch>
                    <Route path="/reset/:secret" component={(a) => <ResetPassword type="reset" match={a.match}/>}/>
                    <Route path="/welcome/:secret" component={(a) => <ResetPassword type="welcome" match={a.match}/>}/>
                    <Route path="/nouser/:email" component={NoUser} />
                    <Route path="/forgot" component={ForgotPassword}/>
                    <Route path="/singledashboard/:dId/:secret/:include_table" component={DashboardSecret}/>
                    <Route path="/singledashboard/:dId/:secret" component={DashboardSecret}/>
                    <Route path="/singledashboardtable/:dId/:secret/" component={DashboardTableSecret}/>
                    <Route path="/singletrendview/:rId/:orgId/:secret/:sHash" component={TrendViewSecret} />
                    <Route path="/singlematrixview/:rId/:orgId/:secret/:sHash" component={MatrixViewSecret} />
                    <Route path="/singlekpiview/:rId/:orgId/:secret/:sHash" component={KPIViewSecret} />
                    <Route path="/singleprofitview/:rId/:orgId/:secret/:sHash" component={ProfitViewSecret} />
                    <Route path="/singlesumview/:rId/:orgId/:secret/:sHash" component={SumViewSecret} />
                    <Route path="/" component={Login}/>
                </Switch>
            )
        }

        let innerStuff = (
            <div>
                {innerComponent}
            </div>
        )

        if (this.props.force2FA) {
            innerStuff = (
                <User />
            )
        }

        return (
            <HashRouter getUserConfirmation={userConfirmation}>
                {innerStuff}
            </HashRouter>
        )
    }
}

Protector = connect(mapStateToProps)(Protector)

export default Protector