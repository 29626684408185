import React from 'react'

class Scrollbar extends React.Component {
    componentDidMount() {
        document.body.classList.add('scrollbar-always')
    }

    componentWillUnmount() {
        document.body.classList.remove('scrollbar-always')
    }

    render() {
        return this.props.children
    }
}

// Scrollbar = connect(mapStateToProps)(Scrollbar)

export default Scrollbar