import * as ActionTypes from '../actions/ActionTypes'

const defaultState = {
    info: null
}

const Download = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_DOWNLOAD_INFO_DONE:
            return Object.assign({}, state, { info: action.payload })
        default:
            return state
    }
}

export default Download