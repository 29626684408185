import { packAction } from '../../actions/ActionTypes'
import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet, apiPost, apiDelete, apiPut, apiCall } from '../../helpers/ApiHelpers'
import { gotItemComments, gotItemData, gotLinks, gotItemCategoryHistory, gotItemCategoryHistoryGraph, getItemDataNotFound, gotItemDrilldown, gotItemCategoryHistoryGraphAll } from '../../actions/ItemActions'
import { gotItemsComments } from '../../actions/ItemActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'

const ItemApiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    let p = action.payload
    switch (action.type) {
        case ActionTypes.GET_ITEM_COMMENTS:
            url = `item/${action.payload.bucket_id}/comments?itemId=${action.payload.item_id}`
            apiGet(url,
                result => dispatch(gotItemComments(action.payload.bucket_id, action.payload.item_id, result)),
                e => notifyApiFailure(`getting comments for item with id '${action.payload.item_id}'`, 'GET', url, data, e.status)
            )
            break
        case ActionTypes.GET_ITEM_DATA:
            url = `item/${action.payload.bucket_id}/data?itemId=${encodeURIComponent(action.payload.item_id)}`
            apiGet(url, 
                result =>  dispatch(gotItemData(action.payload.bucket_id, action.payload.item_id, result)), 
                e => {
                    if(e.status === 404) dispatch(getItemDataNotFound())
                    else notifyApiFailure(`getting data for item with id '${action.payload.item_id}'`, 'GET', url, data, e.status)
                }
            )
            break
        case ActionTypes.GET_ITEM_DRILLDOWN:
            url = `item/${action.payload.bucket_id}/drilldown?itemId=${encodeURIComponent(action.payload.item_id)}`
            data = { aggregated_columns: action.payload.aggregated_columns, drilldown_slicers: action.payload.drilldown_slicers}
            apiPost(url, 
                data,
                result => dispatch(gotItemDrilldown(action.payload.bucket_id, action.payload.item_id, action.payload.aggregated_columns, result)), 
                e => {
                    if(e.status !== 404) {
                        notifyApiFailure(`getting drilldown for item with id '${action.payload.item_id}'`, 'POST', url, data, e.status)
                    } 
                } 
            )
            break
        case ActionTypes.DOWNLOAD_TRANSACTION_DATA:
            url = `item/${action.payload.bucket_id}/drilldownload?itemId=${encodeURIComponent(action.payload.item_id)}`
            data = action.payload.data
            apiPost(url, 
                data,
                result => {/* NOP */},
                e => {
                    if(e.status !== 404) {
                        notifyApiFailure(`getting download drilldown for item with id '${action.payload.item_id}'`, 'POST', url, data, e.status)
                    } 
                } 
            )
            break

        case ActionTypes.ADD_ITEM_COMMENT:
            //payload is expected to be {item_id:.., context_type:.., comment:.., context:.., user_id:.., bucket_id:..}
            url = `item/${action.payload.bucket_id}/comment`
            data = { item_id: p.item_id, context_type: p.context_type, comment: p.comment, context: p.context, user_id: p.user_id, context_path: p.context_path, disable_tagging: p.disable_tagging }
            apiPost(url, data,
                result => {
                    dispatch(packAction(ActionTypes.GET_ITEM_COMMENTS, { bucket_id: p.bucket_id, item_id: p.item_id }))
                }, e => notifyApiFailure(`adding comment on item with id '${action.payload.item_id}'`, 'POST', url, data, e.status))
            break
        case ActionTypes.DELETE_ITEM_COMMENT:
            url = `item/${action.payload.bucket_id}/comment/${action.payload.id}`
            apiDelete(url, result => {
                dispatch(packAction(ActionTypes.GET_ITEM_COMMENTS, { bucket_id: p.bucket_id, item_id: p.item_id }))
            }, e => notifyApiFailure(`deleting comment from item with id '${action.payload.item_id}'`, 'DELETE', url, data, e.status))
            break
        case ActionTypes.UPDATE_ITEM_COMMENT:
            url = `item/${action.payload.bucket_id}/comment/${action.payload.id}`
            data = { comment: p.comment, context_path: p.context_path, disable_tagging: p.disable_tagging }
            apiPut(url, data, result => {
                dispatch(packAction(ActionTypes.GET_ITEM_COMMENTS, { bucket_id: p.bucket_id, item_id: p.item_id }))
            }, e => notifyApiFailure(`saving comment on item with id '${action.payload.item_id}'`, 'PUT', url, data, e.status))
            break
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY:
            url = `item/${action.payload.bucketID}/history`
            data = { item_id: action.payload.itemID, column: action.payload.categoryColumn }
            apiPost(url, data,
                history => dispatch(gotItemCategoryHistory(action.payload.bucketID, action.payload.itemID, history)),
                e => notifyApiFailure(`getting category history for item with id '${action.payload.itemID}'`, 'POST', url, data, e.status)
            )
            break
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH:
            url = `item/${action.payload.bucketID}/history`
            data = { item_id: action.payload.itemID, column: action.payload.categoryColumn }
            apiPost(url, data,
                history => dispatch(gotItemCategoryHistoryGraph(action.payload.bucketID, action.payload.itemID, history, action.payload.categoryColumn)),
                e => notifyApiFailure(`getting history graph for item with id '${action.payload.item_id}'`, 'POST', url, data, e.status)
            )
            break
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL:
            url = `item/${action.payload.bucketID}/history`
            Promise.all(action.payload.categoryColumns.map(c => {
                data = { item_id: action.payload.itemID, column: c }
                return apiCall(url, "POST", data).then(res => Promise.resolve(res), e => Promise.reject(e))
            })).then(res => dispatch(gotItemCategoryHistoryGraphAll(action.payload.bucketID, res)))
            .catch(e => {
                notifyApiFailure(`getting history graph for item with id '${action.payload.item_id}'`, 'POST', url, data, e.status)
            })
            break
        case ActionTypes.GET_ITEM_LINKS:
            url = `item/${action.payload.bucket}/elements?itemId=${action.payload.item}`
            apiGet(url, result => {
                dispatch(gotLinks(action.payload.bucket, action.payload.item, result))
            }, err => {
                if (err.status === 504) { //try again, temporary
                    console.warn('Backend responded with 504, waiting 5 seconds before trying again')
                    setTimeout(() => {
                        apiGet(url, result => {
                            dispatch(gotLinks(action.payload.bucket, action.payload.item, result))
                        }, e => {
                            console.warn('Backend responded with 504 two times in a row, request not repeated')
                            notifyApiFailure(`getting item references for item id '${action.payload.item}'`, 'GET', url, data, e.status)
                        })
                    }, 5000)
                }
            })
            break
        case ActionTypes.GET_ITEMS_COMMENTS:
            url = `bucket/${action.payload.bId}/comments`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotItemsComments(result, action.payload.bId))
            }, e => notifyApiFailure(`getting comments`, 'GET', url, data, e.status))
            break
        default:
            break
    }
}

export default ItemApiMiddleware