import React from 'react'
import BaseDialog from './BaseDialog'

class SaveReportDialog extends BaseDialog {

    closeDialog = () => {
		if(this.props.closeHandler)
			this.props.closeHandler();
		this.hide(); //base class
	}

	saveDialog = () => {
		if(this.props.saveHandler)
			this.props.saveHandler();
		this.hide(); //base class
	}


    getContent() {
        return (
            <div className="form-group margin-top-10px">
				<span>Name: </span><br/>
                <input type="text" placeholder="New Insight name" className="form-control form-control-sm inline-block width-370px" value={this.props.newReportName} onChange={(e) => this.props.updateNewReportName( e.target.value)} />
                <br /><br />
				<span>Description: </span>
				<textarea placeholder="Add description" className="width-100-p height-220px textarea-padding no-resize margin-top-10px textarea-border" value={this.props.newReportDescription} onChange={(e) => this.props.updateNewReportDescription( e.target.value)} />
				<br /><br />
                <input type="checkbox" className="vertical-align-middle" onChange={(e) => this.props.updateNewReportAddToFavorites(e.target.checked)} />
                &nbsp; <label> Add to Home page </label>
             </div>
        )
    }


	getButtons() {
		return (
			<div>
                <button onClick={this.saveDialog} type="button" className="btn btn-primary margin-right-10px" disabled={this.props.newReportName === ''}>Save</button>
				<button onClick={this.closeDialog} type="button" className="btn btn-default">Cancel</button>
			</div>
		)
	}
}

export default SaveReportDialog