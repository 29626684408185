import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet, apiPost } from '../../helpers/ApiHelpers'
import { getColumnsForProfit, getFilter } from '../../helpers/ReportHelpers'
import ProfitReportViewContainer from '../../components/Report/ReportComponents/ProfitReportViewContainer'

function ProfitViewSecret(){
    const { secret, rId, orgId, sHash} = useParams()
    const [data, setData] = useState()
    const [profitData, setProfitData] = useState()
    const [historyData, setHistoryData] = useState()
    
    const viewSettings = useMemo(() => {
        if(!data) return
        return JSON.parse(data.view_settings.settings)
    }, [data])

    let getWidthForPlaywright = () => {
        return 1600 //fixed @ 1600
    };
    
    useEffect(() => {
        apiGet(`singletrendview/${rId}/${orgId}/${secret}/${sHash}`, data => {
            setData(data)
        }, error => console.log(error))
    }, [orgId, rId, sHash, secret])

    useEffect(() => {
        if(!data || !viewSettings) return
        apiGet(`singlereport/profitability/${orgId}/${data.report_data.info.id}/${secret}`, res => {
            setProfitData(res)
        }, error => console.log(error))
    }, [data])

    useEffect(() => {
        if(!data || !profitData || !viewSettings) return
        const q = {
            aggregate_column: '',
            group_column: data.report_data.info.model.categorization_name,
            filter: getFilter(data.report_data.report, viewSettings.report.slicers ?? []),
            sort_column: data.report_data.report.sort_column === '-' ? null : data.report_data.report.sort_column,
            sort_direction: data.report_data.report.sort_direction === '-' ? null : data.report_data.report.sort_direction,
            limit: data.report_data.report.limit,
            kpis: JSON.stringify(getColumnsForProfit(profitData)),
        }

        apiPost(`singlereport/profit/history/${orgId}/${data.report_data.info.id}/${secret}`, q, res => {
            setHistoryData(res)
        }, error => console.log(error))
    }, [profitData])
    if (!secret || !rId || !sHash) return null;
    if (!data || !profitData || !historyData || !viewSettings) return null;
    
    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')

    return <div className="mt-2">
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
                
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                {/* reporting service waits for #dashboardTitle to load before it prints pdf, therefore this is needed */}
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.report_data.report.name}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>
        
        <div className='mb-4' style={{ borderTop: "1px solid rgb(229 231 235)", marginLeft: 0, marginRight:50, maxWidth: 1600 }}>
            <ProfitReportViewContainer
                show={true}
                enabled={true}
                settingsParent={this}
                editMode={null}
                limit={data.report_data.report.limit}
                profit={profitData}
                reportdata={data.report_data}
                slicers={viewSettings.report.slicers ?? []}
                changeView={null}
                forPrint={true}
                secretHistoryData={historyData}
                profitViewSettings={viewSettings.report.children.content.children.profit}
            />
        </div>

    </div>
}

export default ProfitViewSecret