import React from 'react'
import { reportError, commentReportError } from '../../actions/SystemActions'
import { store } from '../../index'
import ShowIf from './ShowIf'
import { getSettingsFromPath } from '../../helpers/SettingsService'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, comment: "", showComment: false, defaultComment: "" }
    }
    
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        let errorReport = {name: error.name, message: error.message, stack_trace: error.stack}

        //if the error happened on '#/report/...', 
        //get the current settings if they exist and add them to the error info
        if(window.location.hash.includes("report")) {
            getSettingsFromPath(settings => {
                errorReport.report_settings = settings
            }, () => {})
        }

        store.dispatch(reportError(errorReport, 2, -1))
    }

    toggleComment() {
        this.setState({showComment: !this.state.showComment, comment: this.state.defaultComment})
    }

    commentTimeout
    sendComment() {
        clearTimeout(this.commentTimeout)

        let send = (eId) => {
            store.dispatch(commentReportError(eId, this.state.comment))
            this.setState({showComment: false, defaultComment: this.state.comment})
        }

        let errorReportId = this.props.errors.get(-1, {id: -1}).id
        
        if(errorReportId === -1) {
            //Try again in a moment if couldn't find the error report
            this.commentTimeout = setTimeout(() => {
                errorReportId = this.props.errors.get(-1, {id: -1}).id
                if(errorReportId !== -1) {
                    send(errorReportId)
                } else {
                    //Give up sending the comment
                    this.setState({showComment: false})
                }
            }, 1000)
        } else {
            send(errorReportId)
        }
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="container">
                    <h2>Something went wrong.</h2>
                    <p>If the problem persists, please contact the administrator.</p>
                    <button onClick={() => window.location.reload()} className="error-boundary-action-button btn btn-default"><span className="fa fa-redo-alt"/>&nbsp;Reload page</button>
                    <ShowIf if={this.props.errors.get(-1, null)}>
                        <button onClick={() => this.toggleComment()} className="error-boundary-action-button btn btn-default"><span className="fa fa-comment"/>&nbsp;Comment error</button>
                    </ShowIf>
                    <ShowIf if={this.props.errors.get(-1, null) && this.state.showComment}>
                        <textarea className="error-boundary-comment form-control" value={this.state.comment} onChange={e => this.setState({comment: e.target.value})} placeholder="Your comment"/>
                        <div className="error-boundary-action-button btn btn-default" onClick={() => this.sendComment()}>Send</div>
                        <div className="error-boundary-action-button btn btn-default" onClick={() => this.toggleComment()}>Cancel</div>
                    </ShowIf>
                </div>
            )
        }
        return this.props.children
    }  
}

export default ErrorBoundary