/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import Compose from '../components/Report/Compose'
import * as ActionTypes from '../actions/ActionTypes'
import { packAction } from '../actions/ActionTypes'
import { getUserInfo } from '../actions/UserActions'
import { getUsers } from '../actions/SystemActions'
import { copyReport } from '../actions/ReportActions'
import { notifyFailure } from '../helpers/NotificationManager'
import { getIdColumn, findNumberColumns } from '../helpers/ReportHelpers'
import ReportDataIssueDialog from '../components/Dialogs/ReportDataIssueDialog'

const mapStateToProps = (state, ownProps) => {
    return {
        users: state.System.users,
        user: state.User.info,
        buckets: state.Report.buckets,
        bucketDetails: state.Bucket.buckets,
        bucket: state.Bucket.selectedBucket,
    }
}

class NewReport extends React.Component {
    constructor(props) {
        super(props)
        let viewSettings = {
            settings: {
                trend: false, graph: false, grid: false, kpis: false, sum: false, profit: false, overview: true
            }
        }
        this.state = {
            report_dummy: {
                report: 
                {
                    name: "Unnamed Insight", 
                    bucket: "", 
                    columns: "[]", 
                    filter: "[]", 
                    sort_column: "",
                    sort_direction: "", 
                    limit: -1, 
                    kpis: "[]", 
                    fixed_slicer: "", 
                    view_settings: JSON.stringify(viewSettings), 
                    kpi_config: "[{\"shown\":false},{\"shown\":false},{\"shown\":false}]",
                    user_id: -1, //SET THIS TO LOGGED IN USEr
                    group_id: 0, 
                    is_public: false, 
                    description: ""
                }, 
                info: {
                }
            },
            expand: false
        }
    }

    componentDidMount() {
        let { dispatch } = this.props
        if(!this.props.users)
            dispatch(getUsers())
        dispatch(getUserInfo())
        dispatch(packAction(ActionTypes.GET_REPORT_INFO_DONE, this.state.report_dummy))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.props.user) {
            const r_d = Object.assign({}, this.state.report_dummy)
            r_d.report.user_id = this.props.user.user_id
            this.setState({report_dummy: r_d})
        }
    }

    setExpand(bool) {
        this.setState({ expand: bool })
    }

    getCompose = (sectionKey, valueKey, parse = true) => {
        if(!this.state.report_dummy[sectionKey]) return {} 
        let value = this.state.report_dummy[sectionKey][valueKey]
        if(sectionKey === "report" && parse) {
            switch(valueKey) {
                case "kpis":
                case "columns":
                case "filter":
                case "fixed_slicer":
                case "view_settings":
                case "kpi_config":
                    return JSON.parse(value)
                default:
                    break
            }
        } 
        return typeof(value) === "object" ? (Array.isArray(value) ? value.slice() : Object.assign({}, value)) : value
    }

    setCompose = (sectionKey, valueKey, value, cb) => {
        let section = Object.assign({}, this.state.report_dummy[sectionKey])
        section[valueKey] = value

        let report_dummy = Object.assign({}, this.state.report_dummy, {[sectionKey]: section})
        this.setState({report_dummy}, cb)
    }

    setReport = (report, cb) => {
        let report_dummy = Object.assign({}, this.state.report_dummy)
        report_dummy.report = report
        this.setState({report_dummy}, cb)
    }

    setReportInfo = (info, cb) => {
        let report_dummy = Object.assign({}, this.state.report_dummy)
        report_dummy.info = info
        this.setState({report_dummy}, cb)
    }

    save = (fromReport, newName, section) => { // these parameters are only used from Report.js line 447 - when the user uses the "Save as"-function while editmode is open - section is used to avoid "not access"-error.
        const { dispatch } = this.props

        let data = Object.assign({}, this.state.report_dummy.report)

        if (!this.checkReportColumns()) {
            notifyFailure("You must include at least one number column!")
            return false
        }

        if (!this.checkReportIdColumn()) {
            let idColumn = getIdColumn(this.getCompose("info", "model").columns)
            notifyFailure("The Insight must include the id column! ('" + idColumn + "')")
            return false
        }

        data.columns = this.getCompose("report", "columns")
        data.filter = this.getCompose("report", "filter")
        dispatch(copyReport(data))

        dispatch(packAction(ActionTypes.GET_REPORT_INFO_DONE, this.state.report_dummy))

        return true
    }

    checkReportColumns = () => {
        let arr = findNumberColumns(this.state.report_dummy.info.model, JSON.parse(this.state.report_dummy.report.columns))

        if (arr.length > 0) {
            return true
        }
        return false
    }
    
    checkReportIdColumn() {
        let idColumn = getIdColumn(this.state.report_dummy.info.model.columns)
        return JSON.parse(this.state.report_dummy.report.columns).includes(idColumn)
    }

    promptSetBucket = (bucketId, issues) => {
        this.refs.setBucketDialog.show(issues, bucketId)
    }
    
    render() {
        if (this.props.users && this.props.user && this.state.report_dummy.report.user_id !== -1) {
            return (
                <React.Fragment>
                    <Compose 
                        ref="compose"
                        promptSetBucket={this.promptSetBucket}
                        show={true} 
                        user={this.props.user} 
                        users={this.props.users} 
                        reportdata={this.state.report_dummy}
                        newReport={true} 
                        expand={this.state.expand} 
                        setExpand={(bool) => this.setExpand(bool)} 
                        fixedSlicerColumns={[]}
                        getData={this.getCompose}
                        setData={this.setCompose}
                        save={this.save}
                        setReport={this.setReport}
                        setReportInfo={this.setReportInfo}
                        promptUnselectColumns={cb => cb()}
                        checkReportColumns={this.checkReportColumns}
                    />            
                    <ReportDataIssueDialog ref="setBucketDialog" title={"Remove Insight settings"} accept={b => this.refs.compose.setDataTabBucket(b)}>
                        <p>You are about to change the bucket. Doing so will remove columns that are used elsewhere in the Insight. To continue, the settings using the columns to be removed will also be removed. Below is an overview of which settings have which conflicting columns. Do you want to proceed?</p>
                    </ReportDataIssueDialog>
                </React.Fragment>
            )
        }
        return <span></span>
    }
}
        
NewReport = connect(mapStateToProps)(NewReport)

export default NewReport