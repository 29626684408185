import * as ActionTypes from '../actions/ActionTypes'

const Status = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.GET_CHECKLISTS_STATUS_DONE:
            return Object.assign({}, state, { checklists: action.payload })
        default:
            return state
    }
}

export default Status