import React, { useState, useEffect } from "react"

export default function OrganizationAdministrationEditAccessControl(props) {

    const [deselectedBuckets, setDeselectedBuckets] = useState(!!props.items ? props.items?.buckets?.filter(b => !props.user.bucket_access?.find(ub => ub === b.id)).sort((a,b) => a.name.localeCompare(b.name)) : []);
    const [selectedBuckets, setSelectedBuckets] = useState(!!props.items ? props.items?.buckets?.filter(b => !!props.user.bucket_access?.find(ub => ub === b.id)).sort((a,b) => a.name.localeCompare(b.name)) : []);

    const [filteredDeselectedBuckets, setFilteredDeselectedBuckets] = useState(deselectedBuckets);
    const [filteredSelectedBuckets, setFilteredSelectedBuckets] = useState(selectedBuckets);
    
    const [searchString, setSearchString] = useState("")

    useEffect(()=> {

        if(selectedBuckets && deselectedBuckets){
            props.bucketChangedHandler( {selected: [...selectedBuckets], deselected: [...deselectedBuckets]}) 
        }

    }, [deselectedBuckets, selectedBuckets])

    useEffect(() => {
        setFilteredDeselectedBuckets(deselectedBuckets.filter(b => b.name.toLowerCase().includes(searchString.toLowerCase())))
        setFilteredSelectedBuckets(selectedBuckets.filter(b => b.name.toLowerCase().includes(searchString.toLowerCase())))
    }, [searchString, deselectedBuckets, selectedBuckets])

    function selectBucketHandler(bucket) {
        const index = deselectedBuckets.findIndex((b)=> b.id === bucket.id )
        let deselected = [...deselectedBuckets];
        deselected.splice(index, 1)
        const selected = [...selectedBuckets]
        selected.push(bucket)
        selected.sort((a, b) => a.name.localeCompare(b.name))
        deselected.sort((a, b) => a.name.localeCompare(b.name))
        setDeselectedBuckets(deselected)
        setSelectedBuckets(selected)
    }
      function selectAllBucketsHandler() {
        setDeselectedBuckets([])
        setSelectedBuckets(...[props.items.buckets])
    }
    function deselectAllBucketsHandler() {
            setDeselectedBuckets(...[props.items.buckets])
            setSelectedBuckets([])
    }
    function deselectBucketHandler(bucket) {
        const index = selectedBuckets.findIndex((b)=> b.id === bucket.id )
        let selected = [...selectedBuckets];
        selected.splice(index, 1)
        const deselected = [...deselectedBuckets]
        deselected.push(bucket)
        selected.sort((a, b) => a.name.localeCompare(b.name))
        deselected.sort((a, b) => a.name.localeCompare(b.name))
        setDeselectedBuckets(deselected)
        setSelectedBuckets(selected)
    }

    return (deselectedBuckets && selectedBuckets ?
    <>
    <label htmlFor="org-admin__access-control-search-input" className="org-admin-label d-flex w-50">
        <input id="org-admin__access-control-search-input" className="org-admin-search-field org-admin-text-input " type="text" placeholder="Search..." value={searchString} onChange={e => setSearchString(e.target.value)} />
        <span className="btn org-admin-search-button">
            <i className="fa fa-search"></i>
        </span>
    </label>
    <section className="org-admin-access-control-wrapper d-flex" >
        <section className="org-admin-access-control-column col p-2 position-relative d-flex flex-column">

            <h3 className="h6">User does <strong>not</strong> have access to:</h3>
            <button className="btn btn-outline-secondary btn-sm align-self-center mb-2" disabled={deselectedBuckets.length === 0} onClick={() => selectAllBucketsHandler()}>Select all</button>
            <section className="card org-admin-access-control-list">

            <ul className="m-0 p-0">
                {filteredDeselectedBuckets.map((bucket) => <li key={bucket.id} onClick={() => selectBucketHandler(bucket)} className="org-admin-access-control-list-item d-flex align-items-center justify-content-between">
                    {bucket.name} <span className="d-flex align-items-center">| <i className="ml-2 fa fa-caret-right"></i></span>
                </li>
                )}
            </ul>
            </section>
        </section>
        <section className="org-admin-access-control-column col p-2 position-relative d-flex flex-column">
            <h3 className="h6">User <strong>has</strong> access to:</h3>
            <button className=" btn btn-outline-secondary btn-sm align-self-center mb-2" disabled={selectedBuckets.length === 0} onClick={() => deselectAllBucketsHandler()}>Deselect all</button>
            <section className="card org-admin-access-control-list" style={{ border: selectedBuckets.length === 0 ? "2px solid #d9534f" : "1px solid rgba(0, 0, 0, 0.125)"}}>
    <ul className="m-0 p-0">
        {filteredSelectedBuckets.map((bucket) => <li key={bucket.id} onClick={() => deselectBucketHandler(bucket)} className="org-admin-access-control-list-item d-flex align-items-center justify-content-between" >
            {bucket.name} <span className="d-flex align-items-center"> <i className="ml-2 fa fa-times"></i></span>
        </li>
        )}

    </ul>
            </section>
        </section>
    </section>
       {selectedBuckets.length === 0 ? <p style={{color: "#d9534f", paddingLeft: 10, fontWeight: "bold"}} >User will not have access to anything</p> : null } 
       </>  : <></>         
    ) }