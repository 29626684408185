import React from 'react'
import { packAction } from '../../actions/ActionTypes'
import ShowIf from '../Generic/ShowIf'
import { connect } from 'react-redux'
import * as ActionTypes from '../../actions/ActionTypes'
import RequirePermission from '../RequirePermission'
import { is } from '../../helpers/PermissionHelpers'
import { removeColumns } from '../../actions/ReportActions'

const mapStateToProps = (state, ownProps) => {
	return {
        links: state.Item.links
	}
}

const linkComparator = (a,b) => {
    return a.type < b.type ? -1 : a.type === b.type ? 0 : 1
}

const showLimit = 3

class SingleItemReferences extends React.Component {
    state = {
        bucket_id: null,
        item_id: null,
        limit_reports: true,
        limit_checklists: true,
        selectedIndex: -1
    }

    onClick(link) {
        window.location.hash = link
    }

    fetchReferences(bucket, item) {
        this.props.dispatch(packAction(ActionTypes.GET_ITEM_LINKS, {bucket: bucket, item: item}))

    }

    setItem(bucket, item) {
        if(bucket === null || item === null) return
        this.setState({bucket_id: bucket, item_id: item, limit_checklists: true, limit_reports: true})
    }

    loadReferences() {
        if(this.state.bucket_id === null || this.state.item_id === null) return

        this.fetchReferences(this.state.bucket_id, this.state.item_id)
    }

    getItemKey = () => {
        return this.state.item_id
    }

    fixColumns(rId) {
        const { dispatch } = this.props
        dispatch(removeColumns(rId, { view: "item", bucket: this.state.bucket_id, item: this.state.item_id }))
    }

    render() {
        const reportID = this.props.reportID ? this.props.reportID : -1
        const checklistID = this.props.checklistID ? this.props.checklistID : -1

        let links = this.props.links.get(this.state.bucket_id, this.state.item_id)
        if(!links) return <span>Loading item references...</span>
        links = links.sort(linkComparator)
        let checklists = links.filter(link => link.type === 2 && !link.element.archived).filter(c => c.element_id !== checklistID)
        let reports = links.filter(link => link.type === 1).filter(r => r.element_id !== reportID)
        
        let shownChecklists = this.state.limit_checklists && checklists.length > showLimit+1 ? checklists.slice(0,showLimit) : checklists
        let shownReports = this.state.limit_reports && reports.length > showLimit+1 ? reports.slice(0,showLimit) : reports
        
        return (
            <div>
                <h4>Item also appears in</h4>
                <span className="item-reference-title display-block"><img src="./img/comment-checklist-small.png" className="inline-block" alt=""/> <b>{checklists.length} Action lists</b></span>
                {
                    shownChecklists.map((link,i) => <span key={i} className="underline-hover display-block" onClick={() => this.onClick("/checklist/"+link.element.id)}>{link.name}</span>)
                }
                <ShowIf if={this.state.limit_checklists && checklists.length > showLimit+1}>
                    <span className="abc-clickable underline-hover display-block" onClick={() => this.setState({limit_checklists: false})}><b>...show more (+{checklists.length-showLimit})</b></span>
                </ShowIf>
                <ShowIf if={!this.state.limit_checklists && checklists.length > showLimit+1}>
                    <span className="abc-clickable underline-hover display-block" onClick={() => this.setState({limit_checklists: true})}><b>...show less</b></span>
                </ShowIf>
                <br/>
                <span className="item-reference-title display-block"><img src="./img/comment-report-small.png" className="inline-block" alt=""/> <b>{reports.length} Insights</b></span>
                {
                    shownReports.map((link, i) => {
                        return (
                            <span className="display-block" key={i}>
                                <span key={i} className="underline-hover" onClick={() => this.onClick("/report/"+link.element.report_id)}>
                                    {link.name} 
                                </span>  
                                {
                                    link.missing ? 
                                        <span> - <i onClick={() => this.setState({ selectedIndex: i })} className="underline-hover report-text-warning">Warning</i></span>
                                    : null
                                }
                                {
                                    i === this.state.selectedIndex && link.missing ?
                                        <div className="font-size-12px margin-top-5px margin-bottom-5px">Affected columns:
                                            <ul>
                                                {
                                                    link.missing.map((c, index) => {
                                                        return <li key={index}>{c}</li>
                                                    })
                                                }
                                            </ul>
                                            <span>Contact <a href="https://help.inact.io" rel="noopener noreferrer" target="_blank" >Support</a> to resolve this issue.</span>
                                            <RequirePermission perms={is.dataAdmin}>
                                                <ShowIf if={link.critical && link.critical.length === 0}>
                                                    <div>
                                                        <button className="btn btn-primary btn-xs margin-right-5px" onClick={() => this.fixColumns(link.element.report_id)} title="Remove all affected columns from the Insight">Quick fix</button>
                                                        <button className="btn btn-default btn-xs" onClick={() => this.setState({ selectedIndex: -1 })}>Close</button>
                                                    </div>
                                                </ShowIf>
                                            </RequirePermission>
                                        </div>
                                    : null
                                }
                            </span>
                        ) 
                    }) 
                }
                <ShowIf if={this.state.limit_reports && reports.length > showLimit+1}>
                    <span className="abc-clickable display-block" onClick={() => this.setState({limit_reports: false})}><b>...show more (+{reports.length-showLimit})</b></span>
                </ShowIf>
                <ShowIf if={!this.state.limit_reports && reports.length > showLimit+1}>
                    <span className="abc-clickable display-block" onClick={() => this.setState({limit_reports: true})}><b>...show less</b></span>
                </ShowIf>
                <ShowIf if={checklists.length === 0 && reports.length === 0}>
                    <p>Nothing to show here...</p>
                </ShowIf>
            </div>
        )
    }
}

SingleItemReferences = connect(mapStateToProps, null, null, { forwardRef: true })(SingleItemReferences)

export default SingleItemReferences