import React, { useEffect, useRef, useState } from 'react'

import '../../css/ResponsiveButtons.css'
import { getCanvasFont } from '../../helpers/TextHelpers'
import { getTextWidth } from '../../helpers/TextHelpers'

interface ResponsiveToggleProps {
    children: React.ReactNode,
    label?: string,
    responsiveLabel?: string,
    onChange: (newState: boolean) => void,
    checked: boolean,

    className?: string,
    style?: React.CSSProperties,
    title?: string,
}

const ResponsiveToggle = (props: ResponsiveToggleProps) => {

    const [showResponsive, setShowResponsive] = useState(false)
    let labelRef = useRef<any>(null)
    let showTimeoutRef = useRef<any>(null)
    let [text, setText] = useState("")
    let [width, setWidth] = useState(0)

    useEffect(() => {
        // Cleanup
        return () => {
            setShowResponsive(false)
            clearTimeout(showTimeoutRef.current)
        }
    },[])

    useEffect(() => {
        let text = ""
        if (!props.checked && props.label != null) {
            text = props.label
        } else if (props.checked && showResponsive && props.responsiveLabel != null){
            text = props.responsiveLabel
        }
        setText(text)
        let width = 0
        width = getTextWidth(text, getCanvasFont(labelRef.current))
        setWidth(width)
    }, [props.checked, props.label, props.responsiveLabel])

    let onClick = () => {
        
        if (!props.checked){
            setShowResponsive(true)
            showTimeoutRef.current = setTimeout(() => {
                setShowResponsive(false)
            }, 2000)
        } else {
            clearTimeout(showTimeoutRef.current)
        }
        
        props.onChange(!props.checked)
    }

    let shouldShowText = (props.label != null && !props.checked) || (props.checked && showResponsive && props.responsiveLabel != null)
    return (
        <div onClick={onClick} style={props.style ?? {}} className={`responsive-button-container abc-click bg-white ${props.checked ? 'responsive-button-checked' : ''} ${props.className ?? ''}`}>
            {props.children}
                <span ref={labelRef} className='responsive-button-label' style={{width: shouldShowText ? width : "0px", marginLeft: shouldShowText ? 5 : 0}}>
                    {text}
                </span>
            </div>
    )

}

export default ResponsiveToggle
