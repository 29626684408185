import { packAction } from "./ActionTypes"
import * as ActionTypes from './ActionTypes'

export const getDownloadInfo = () => {
    return packAction(ActionTypes.GET_DOWNLOAD_INFO)
}

export const getDownloadInfoDone = info => {
    return packAction(ActionTypes.GET_DOWNLOAD_INFO_DONE, info)
}

export const requestDownloadFile = (bucket, fileName) => {
    return packAction(ActionTypes.REQUEST_DOWNLOAD_FILE, {bucket, fileName})
}