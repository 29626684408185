import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const login = (email, password) => {
    return packAction(ActionTypes.LOGIN, {email:email,password:password})
}

export const loggedIn = token => {
    return packAction(ActionTypes.LOGIN_DONE, token)
}

export const googleLogin = () => {
    return packAction(ActionTypes.GOOGLE_LOGIN)
}

export const googleLoginDone = (token) => {
    return packAction(ActionTypes.GOOGLE_LOGIN_DONE, token)
}

export const logout = () => {
    return packAction(ActionTypes.LOGOUT)
}

export const loggedOut = () => {
    return packAction(ActionTypes.LOGOUT_DONE)
}

export const loginAnimationPlayed = () => {
    return packAction(ActionTypes.LOGIN_ANIMATION_PLAYED)
}

export const getUserInfo = () => {
    return packAction(ActionTypes.GET_USER_INFO)
}

export const gotUserInfo = info => {
    return packAction(ActionTypes.GET_USER_INFO_DONE, info)
}

export const login2FA = (email, password, passcode) => {
    return packAction(ActionTypes.LOGIN2FA, {email: email, password: password, passcode: passcode})
}

export const initialize2FA = data => {
    return packAction(ActionTypes.INITIALIZE_2FA)
}

export const initialize2FADone = data => {
    return packAction(ActionTypes.INITIALIZE_2FA_DONE, data)
}

export const setup2FA = passcode => {
    return packAction(ActionTypes.SETUP_2FA, {passcode: passcode})
}

export const setup2FADone = res => {
    return packAction(ActionTypes.SETUP_2FA_DONE, res)
}

export const is2FAEnabled = () => {
    return packAction(ActionTypes.IS_2FA_ENABLED)
}

export const is2FAEnabledDone = res => {
    return packAction(ActionTypes.IS_2FA_ENABLED_DONE, res)
}

export const is2FAForced = () => {
    return packAction(ActionTypes.IS_2FA_FORCED)
}

export const is2FAForcedDone = res => {
    return packAction(ActionTypes.IS_2FA_FORCED_DONE, res)
}

export const disable2FA = () => {
    return packAction(ActionTypes.DISABLE_2FA)
}

export const disableUser2FA = (userid) => {
    return packAction(ActionTypes.DISABLE_2FA_USER, {id: userid})
}

export const resetSecret = () => {
    return packAction(ActionTypes.RESET_SECRET)
}

export const saveUserInfo = (firstname, lastname, oldPassword, newPassword1, newPassword2) => {
    return packAction(
        ActionTypes.UPDATE_USER_INFO,
        {
            firstname: firstname,
            lastname: lastname,
            old_password: oldPassword,
            new_password: newPassword1,
            new_password2: newPassword2
        }
    )
}
export const saveUpdatedUser = (userInfo, cb) => {
    return packAction(
        ActionTypes.UPDATE_USER,
        { 
            id: userInfo.id,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            phone_number: userInfo.phone_number,
            job_title: userInfo.job_title,
            department: userInfo.department,
            language: userInfo.language,
            organization_id: userInfo.organization_id,
            deleted: userInfo.deleted,
            location: userInfo.location
        }
    , cb)
}

export const gotUpdatedUser = userInfo => {
    return packAction(ActionTypes.UPDATE_USER_DONE, userInfo)
}

export const getEmailSettings = () => {
    return packAction(ActionTypes.GET_EMAIL_SETTINGS)
}

export const gotEmailSettings = res => {
    return packAction(ActionTypes.GET_EMAIL_SETTINGS_DONE, res)
}

export const saveEmailSettings = settings => {
    return packAction(ActionTypes.SAVE_EMAIL_SETTINGS, settings)
}

export const savePendingUser = (firstname, lastname, email, phonenumber, jobtitle, department, language, createdByEmail) => {
    return packAction(
        ActionTypes.CREATE_PENDING_USER, {
            first_name: firstname,
            last_name: lastname,
            email: email,
            phone_number: phonenumber,
            job_title: jobtitle,
            department: department,
            language: language,
            created_by_email: createdByEmail
        }
    )
}

export const gotPasswordPolicy = policy => {
    return packAction(ActionTypes.GET_PASSWORD_POLICY_DONE, policy)
}

export const getPasswordPolicy = () => {
    return packAction(ActionTypes.GET_PASSWORD_POLICY)
}

export const getPasswordPolicyFromToken = (type, token) => {
    return packAction(ActionTypes.GET_PASSWORD_POLICY_FROM_TOKEN, {type, token})
}

export const getUserNPS = _ => {
    return packAction(ActionTypes.GET_NPS_ANSWER)
}

export const setUserNPS = nps => {
    return packAction(ActionTypes.GET_NPS_ANSWER_DONE, nps)
}

export const postponeNPS = _ => {
    return packAction(ActionTypes.POSTPONE_NPS_ANSWER)
}

export const answerNPS = answer => {
    return packAction(ActionTypes.ANSWER_NPS, answer)
}

export const getFollowings = () => {
    return packAction(ActionTypes.GET_FOLLOWINGS)
}

export const gotFollowings = followings => {
    return packAction(ActionTypes.GET_FOLLOWINGS_DONE, followings)
}

export const followItem = (itemId, itemType, follow) => {
    return packAction(ActionTypes.FOLLOW_ITEM, {item_id: itemId, item_type: itemType, follow})
}
export const updatePermissions = (id, permissions) => {
    return packAction(ActionTypes.UPDATE_PERMISSIONS, {id: id, permissions: permissions})
}
export const updatePermissionsDone = (id, permissions) => {
    return packAction(ActionTypes.UPDATE_PERMISSIONS_DONE, {id:id, permissions:permissions})
}

export const updateMultiUsersPermissions = (ids, permissions, cb) => {
        return packAction(ActionTypes.UPDATE_MULTIPLE_USERS_PERMISSIONS, { user_ids: ids, permissions: permissions }, cb)
}

export const updateMultiUsersPermissionsDone = (users) => {
    return packAction(ActionTypes.UPDATE_MULTIPLE_USERS_PERMISSIONS_DONE, { users: users})
}

export const disableMultipleUsers = (ids, organization_id, disable, cb) => {
    return packAction(ActionTypes.DISABLE_MULTIPLE_USERS, {user_ids: ids, organization_id: organization_id, disable_user: disable}, cb)
}

export const disableMultipleUsersDone = (users) => {
    return packAction(ActionTypes.DISABLE_MULTIPLE_USERS_DONE, users)
}

export const createUser = (user, cb) => {
    return packAction(ActionTypes.CREATE_USER, user, cb)
}

export const  createUserDone = (user) => {
    return packAction(ActionTypes.CREATE_USER_DONE, user)
}

export const sendWelcomeMail = (userID, userMail) => {
    return packAction(ActionTypes.SEND_WELCOME_MAIL, {id: userID, email: userMail})
}

export const sendWelcomeMailDone = (lastMailLog) => {
    return packAction(ActionTypes.SEND_WELCOME_MAIL_DONE, lastMailLog)
}