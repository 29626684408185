import { useParams } from "react-router";
import Overview from "../../components/Report/ReportComponents/Overview";
import React, { useEffect, useState, useMemo } from "react";
import { apiGet, apiPost } from "../../helpers/ApiHelpers";
import { SingleKeyMap } from "../../helpers/Collections";
import { hashNValues } from "../../helpers/GeneralHelpers";
import { getFilter, getIdColumn } from "../../helpers/ReportHelpers";
import { TripleKeyMap } from "../../helpers/Collections";

function MatrixViewSecret(){
    const { secret, rId, orgId, sHash} = useParams()
    const [data, setData] = useState()
    const [aggrData, setAggrData] = useState()
    const viewSettings = useMemo(() => {
        if(!data) return
        return JSON.parse(data.view_settings.settings)
    }, [data])
    const matrixSettings = useMemo(() => {
        if(!viewSettings) return
        return viewSettings.report.children.content.children.overview
    }, [viewSettings])
    
    let getWidthForPlaywright = () => {
        return 1400 //fixed @ 1400
    };

    useEffect(() => {
        apiGet(`singletrendview/${rId}/${orgId}/${secret}/${sHash}`, data => {
            setData(data)
        }, error => console.log(error))
    }, [orgId, rId, sHash, secret])

    useEffect(() => {
        if(!data || !viewSettings || !matrixSettings) return
        const url = `singlereport/aggregate/${orgId}/${data.report_data.info.id}/${secret}`
        const column = matrixSettings.selectedColumn && matrixSettings.selectedColumn !== "" ? matrixSettings.selectedColumn : getIdColumn(data.report_data.info.model.columns)
        const limit = data.report_data.report.limit
        const categorization = matrixSettings.categorizationName ? matrixSettings.categorizationName : data.report_data.info.model.categorization_name
        
        const aggrQuery = {
            aggregate_column: column, 
            group_column : categorization,
            filter: getFilter(data.report_data.report, viewSettings.report.slicers ?? []),
            sort_column: data.report_data.report.sort_column,
            sort_direction: data.report_data.report.sort_direction,
            limit: limit,
            kpis: data.report_data.report.kpis,
        }
        apiPost(url, aggrQuery, result => {
            let agData = new TripleKeyMap()
            agData.set(getKPIKey(), rId, "selectedColumn", result)
            setAggrData(agData)
        }, error => console.log(error))
    }, [data])
    
    if (!secret || !rId || !sHash) return null;
    if (!data || !aggrData || !viewSettings || !matrixSettings) return null;
    
    let historydata = new SingleKeyMap()
    historydata.set(data.report_data.info.id, data.history_data)

    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')
    
    function getKPIKey() {
        let reportdata = data.report_data
        let limit = reportdata.report.limit
        let filter = getFilter(reportdata.report, viewSettings.report.slicers ?? [])
        return hashNValues(limit, filter)
    }

    return <div className="mt-2">
        <style> {/* This is needed for the view to be printed to pdf in landscape, can't be set globally as the dashboards needs to be portrait. */}
            {
                `@media print{
                    @page {
                        size: landscape !important;
                    }
                }`
            }
        </style>
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
                
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                {/* reporting service waits for #dashboardTitle to load before it prints pdf, therefore this is needed */}
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.report_data.report.name}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>
        
        <div className='mb-4' style={{ borderTop: "1px solid rgb(229 231 235)", marginLeft: 0, maxWidth: 1400, maxHeight: 800, overflow: "hidden" }}>
            <Overview
                show={true}
                enabled={true}
                settingsParent={this}
                reportdata={data.report_data}
                addClickSlicer={() => {}}
                slicers={viewSettings.report.slicers ?? []}
                clickReport={null}
                showTotal={() => {}}
                limit={data.report_data.report.limit}
                setData={null}
                editMode={null}
                clickableCategories={data.report_data.info.model.categorization.map(c => c.name)}
                forPrint={true}
                secretAggrData={aggrData}
                printViewSettings={matrixSettings}
            />
        </div>
    </div>
}

export default MatrixViewSecret