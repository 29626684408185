import React from 'react'
import ReactDOM from 'react-dom'

// class Suggestion {
//     constructor(title, description, data) {
//         this.title = title
//         this.description = description
//         this.data = data
//     }
// }

class Suggester extends React.Component {
    state = {
        suggestions: [],
        selected: 0
    }

    //Expects {title: "", description: "", data: ""}
    //The input is matched on the title which is also rendered, the description is descriptive sub-text text for rendering only and data is what is returned when the item is clicked on
    setSuggestions(list) { //Expects a list of Suggestion objects
        this.setState({suggestions: list})
    }

    clear() {
        this.setState({suggestions: [], selected: 0})
    }

    onClick(title, data) {
        this.clear()
        this.props.onClick(title, data)
    }

    getSuggestions() {
        return this.state.suggestions
    }
    
    getSelected() {
        return this.state.selected
    }

    setSelected(_s, scroll = true) {
        let s = _s < 0 ? 0 : _s >= this.state.suggestions.length ? this.state.suggestions.length-1 : _s
        this.setState({selected: s})

        if(scroll && this.refs['outer-div'] && this.refs['suggestion-'+s]) {
            /* Scrolling if selected is outside box */
            let outerNode = ReactDOM.findDOMNode(this.refs['outer-div'])
            let suggestionNode = ReactDOM.findDOMNode(this.refs['suggestion-'+s])
            let outerBounds = outerNode.getBoundingClientRect()
            let suggestionBounds = suggestionNode.getBoundingClientRect()
            if(suggestionBounds.top < outerBounds.top) {
                outerNode.scrollBy(0, suggestionBounds.top-outerBounds.top)
            }
            if(suggestionBounds.bottom > outerBounds.bottom) {
                outerNode.scrollBy(0, suggestionBounds.bottom-outerBounds.bottom)
            }
        }
    }

    render() {
        return (
            <div ref="outer-div" className="list-group suggestion-list-group" onKeyDown={e => this.keyDown(e)}>
                {
                    this.state.suggestions.map((s, i) => 
                        <div key={i} ref={"suggestion-"+i} className={"list-group-item"+(this.state.selected===i ? " selected-suggestion-item" : "")} onClick={() => this.onClick(s.title, s.data)} onMouseEnter={() => this.setSelected(i, false)}>
                            <span className="list-group-item-heading">{s.title}</span>
                            <br/>
                            <div className="list-group-item-text">{s.description}</div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Suggester