import React, { useEffect, useState } from "react"
import GenericDialog from "./GenericDialog"


export interface EditBucketDescriptionProps {
    show: boolean,
    onClose: () => void,
    chosenColumnName: string,
    chosenColumnDescription: string,
    saveDescription: (description: string) => void,
}
let EditBucketDescription = (props: EditBucketDescriptionProps) => {

    const [tmpDescription, setTmpDescription] = useState("")
    useEffect(() => {
        if (!props.show) {
            setTmpDescription("")
            return
        }
        setTmpDescription(props.chosenColumnDescription)
    }, [])
    let saveDescription = () => {
        if (tmpDescription == undefined) return
        props.saveDescription(tmpDescription)
    }
    let cancelSave = () => {
        return tmpDescription == undefined
    }
    return (
        <GenericDialog show={props.show} title={"Edit description for [" + props.chosenColumnName + "]"}  onClose={props.onClose}  style={{maxWidth:"50%"}} getButtons={() => { 
            return <div>
                        <button style={{ marginRight: 7 }} onClick={saveDescription} disabled={cancelSave()} className='btn btn-primary'>Save</button>
                        <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
                    </div>
        }}>
            <div className='dashboard-edit-slicers'>
                <label style={{marginBottom: "0px"}} htmlFor='edit-description-dialog' className='form-label'> Insert Description: </label>
                <textarea id="edit-description-dialog"  placeholder="Description" style={{ marginBottom: 20 }} className="form-control" value={tmpDescription} onChange={(e) => setTmpDescription(e.target.value)} />
        
            </div>
        </GenericDialog>
    )
}

export default EditBucketDescription
