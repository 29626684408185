/* REACT */
import React, { createRef } from 'react'
import ShowIf from '../../Generic/ShowIf'
import ReactTooltip from 'react-tooltip'

class MatrixItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            toolTipRight : 250,
            toolTipBottom : 40,
        }
    }
            
    divRef = createRef();

    componentDidMount() {
         setInterval(() => {

            if (this.divRef.current){
                this.setState({
                    toolTipRight:this.divRef.current.offsetWidth + 25,
                });
            }
          }, 100);
    }

    render() {
        let category = this.props.category
        let informations = category ? category.informations : ['']
        const columnWitdh = this.props.maxX >= 2 ? 3 : this.props.maxX === 1 ? 4 : 6

        if(!this.props.label)
            return <div className={`col-md-${columnWitdh} col-sm-${columnWitdh} col-xs-${columnWitdh}`} > <div className={`abc-overview-box-container`}>&nbsp;</div></div>

        return (
            <div className={`col-md-${columnWitdh} col-sm-${columnWitdh} col-xs-${columnWitdh}`} >
                <div className={`abc-overview-box-container`} data-tip data-for={this.props.id + '_tooltip'} > 
                    <ShowIf if={category && this.props.showInformation && category.is_custom_category}>
                        <div className={`abc-overview-information`}>
                            <ShowIf if={this.props.editMode && this.props.editable}>
                                <div className='abc-overview-box-edit-cirle abc-click' onClick={() => this.props.editDescription(category, informations)}>
                                    <i className="fa fa-pencil"></i>
                                </div>
                            </ShowIf>
                            {
                                informations &&
                                informations.map((info, idx) => {
                                    return (
                                        <span key={this.props.id + '' + idx}>{info}<br /></span>
                                    )
                                })
                            }
                        </div>
                    </ShowIf>
                    <ShowIf if={category && this.props.showInformation && !category.is_custom_category}> 
                        <ShowIf if={category && category.position.y === 0 && informations.length > 1}>
                            <div className={`abc-overview-information`} style={{border:0}}
                                                    dangerouslySetInnerHTML={{ __html: informations[1]}}
                            >
                            </div>
                        </ShowIf>
                        <ShowIf if={category && category.position.y === 0 && informations.length === 1}>
                            <div className={`abc-overview-information`} style={{border:0}}
                                                    dangerouslySetInnerHTML={{ __html: informations[0]}}
                            >
                            </div>
                        </ShowIf>
                    </ShowIf>

                    <div ref={this.divRef} className={`abc-overview-box`}>
                    <ShowIf if={category && category.position.x === 0 && this.props.showInformation && !category.is_custom_category && informations.length > 1}>
                        <div className={`abc-overview-information abc-overview-information-left`} style={{border:0, right: this.state.toolTipRight, bottom: this.state.toolTipBottom}}
                            dangerouslySetInnerHTML={{ __html: informations[0]}}
                        >
                        </div>
                    </ShowIf>
                        <div className={`${!this.props.clickable ? "abc-overview-box-not-clickable" : "abc-click"}`} onClick={() => this.props.addSlicer(this.props.label)}>
                            <div className="abc-overview-box-label">{this.props.label}</div>
                            <div style={this.props.border} className={`abc-category-border`}></div>
                            <div className="abc-overview-box-number">{this.props.number}</div>
                        </div>
                    </div>
                </div>
                <ShowIf if={!this.props.showInformation && informations && category && category.is_custom_category}>
                    <ReactTooltip id={this.props.id + '_tooltip'} type='dark' effect='solid' clickable={false} delayShow={1000}> 
                    {
                        informations.map((info, idx) => {
                            return (
                                <span key={this.props.id + '_tooltip_' + idx}>{info}<br /></span>
                            )
                        })
                    }
                    </ReactTooltip>
                </ShowIf>
            </div>
        )
    }
}

export default MatrixItem
