/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { findNumberColumns, getColumnNames, hasReportDataCosistencyIssues, getReportDataConsistencyIssues, fixReportDataConsistency } from '../../../helpers/ReportHelpers'
import { getAllBuckets, getGroups } from '../../../actions/ReportActions'
import { getBucketInfo, setNewReportBucket, getBucketUsers } from '../../../actions/BucketActions'
import { notifyFailure } from '../../../helpers/NotificationManager'
import { isReportAdmin } from '../../../helpers/PermissionHelpers'
import ShowIf from '../../Generic/ShowIf'
import WarningSaveBar from './WarningSaveBar'

import '../../../css/misc.css'

const mapStateToProps = (state, ownProps) => {
    return {
        buckets: state.Report.buckets,
        bucketDetails: state.Bucket.buckets,
        bucket: state.Bucket.selectedBucket,
        info: state.Info.reportInfo,
        users: state.System.users,
        userInfo: state.User.info,
        groups: state.Report.groups,
        bucketUsers: state.Bucket.bucketUsers,
    }
}

class DataTab extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            buckets: [],
            selectedBucket: "",
            allBucketColumns: [],
            reportColumns: [],
            editSortColumn: false,
            localCol: "",
            localDir: "desc",
            localLim: -1,
            numberColumns: [],
            selectedAvailVals: [],
            selectedInclVals: [],
            fetchedBucketInfos: [],
        }
    }

    componentDidMount() {
        let { dispatch } = this.props
        dispatch(getAllBuckets())
        dispatch(getGroups())
        if(this.props.reportdata.report.bucket !== "" && this.props.reportdata.report.bucket !== undefined && !this.props.bucketUsers.contains(this.props.reportdata.report.bucket))
            dispatch(getBucketUsers(this.props.reportdata.report.bucket))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dispatch } = this.props
        let buckets = this.props.buckets.get("buckets", []) ?? []
        let bucket = {}
        let prevBuckets = prevProps.buckets.get("buckets", []) ?? []

        if(this.props.show){
            let fetchedInfos= this.state.fetchedBucketInfos

            buckets.forEach((b, index) => {
                if(!fetchedInfos.includes(b.id)){
                    if(!this.props.bucketDetails.get(this.state.selectedBucket.id)) {
                        dispatch(getBucketInfo(b.id))
                    }
                    fetchedInfos.push(b.id)
                    this.setState({ fetchedBucketInfos: fetchedInfos})
                }
            })
        }

        if ( ( (prevBuckets.length === 0 && buckets.length > 0 ) || (this.props.show && this.state.selectedBucket === ""))) {
            buckets.forEach((b, index) => {
                if (b.id === this.props.reportdata.info.id) {
                    bucket = b
                } 
            })

            // if it's a new report
            let found = false 
            if (this.props.reportdata.info.id === undefined && !this.props.bucket) {
                buckets.forEach((b, index) => {
                    if (b.has_data && !found) {
                        bucket = b
                        found = true
                    }
                })
                this.setState({ selectedBucket: bucket})
                this.props.setData("report", "bucket", bucket.id)
            } else if (this.props.newReport && this.props.bucket) { // if the user already has been in new report and selected a bucket
                this.setState({ selectedBucket: this.props.bucket})
                this.props.setData("report", "bucket", this.props.bucket.id)
            } else { // should enter here if we are just opening edit mode from a report
                this.setState({ selectedBucket: bucket})
            }
        }

        if (
            this.state.selectedBucket.id && 
            this.props.bucketDetails.get(this.state.selectedBucket.id) && 
            (this.state.selectedBucket.id !== prevState.selectedBucket.id ||
                JSON.stringify(prevProps.bucketDetails.get(prevState.selectedBucket.id, {})) !== JSON.stringify(this.props.bucketDetails.get(this.state.selectedBucket.id)) 
            )
        ) {
            const bucket = this.props.bucketDetails.get(this.state.selectedBucket.id, {})
            this.props.setBucket(bucket)
            let bucketColumns = getColumnNames(bucket.model)
            let detailedColumns = this.props.getReportData("view_settings").detailed_report_columns
            let bucketHolder = []
            let reportHolder = this.convertDetailedColumns(detailedColumns) 

            if (this.state.selectedBucket.id !== this.props.reportdata.info.id) {
                this.props.resetBucketSettings(bucket)
                reportHolder = reportHolder.filter(c => bucketColumns.includes(c.column))
                bucketHolder = bucketColumns.filter(c => reportHolder.findIndex(r => r.column === c) === -1)
            } else {
                bucketColumns.forEach((b, i) => {
                    if(reportHolder.findIndex(c => c.column === b) === -1) {
                        bucketHolder.push(b)
                    }
                })
            }

            bucketHolder.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase())
            })

            let numberColumns = findNumberColumns(bucket.model, reportHolder)
            if (reportHolder.length === 0) {
                this.props.setReportData("columns", JSON.stringify([]))
            } else {
                this.props.setReportData("columns", JSON.stringify(reportHolder.map(c=>c.column)))
            }


            this.setState({ allBucketColumns: bucketHolder, reportColumns: reportHolder, numberColumns: numberColumns })
        }

        if (this.state.reportColumns !== prevState.reportColumns) {
            const bucket = this.props.bucketDetails.data[this.state.selectedBucket.id]
            if (bucket) {
                let numberColumns = findNumberColumns(bucket.model, this.getReportColumns(this.state.reportColumns))
                this.setState({ numberColumns: numberColumns })            
            }
        }

        let drc = this.props.reportdata.report.view_settings.detailed_report_columns
        let prevDrc = prevProps.reportdata.report.view_settings.detailed_report_columns
        if (prevDrc !== drc) {
            let rh = this.convertDetailedColumns(drc)
            this.setState({ reportColumns: rh })
        }

        if (this.props.getData("report", "bucket") !== this.props.getData("info", "id")) {
            let bucketDetails = this.props.bucketDetails.get(this.props.getData("report", "bucket"), false)
            if(bucketDetails) {
                this.props.setReportInfo(bucketDetails)
            }
        }

        const limit = this.props.getReportData("limit") 
        if (typeof limit !== "object" && limit !== -1  && this.state.localLim === -1) {
            const dir = this.props.getReportData("sort_direction")
            this.setState({ localLim: parseInt(limit, 10), localDir: dir })
        }
    }

    convertDetailedColumns(input) {
        let reportHolder = []
        let reportColumns = JSON.parse(this.props.reportdata.report.columns)
        let detailedColumns = input === undefined ? [] : input

        if (detailedColumns !== undefined) {
            if (detailedColumns.length > 0 && !detailedColumns[0].column) { // we need to convert to array of objects if detailedColumns is not in the new array-format
                let shown = []
                let hidden = []
                let columns = JSON.parse(this.props.reportdata.report.columns)
                
                columns.forEach((c, index) => {
                    let bool = false
                    for (let i = 0; i < detailedColumns.length; i++) {
                        const dc = detailedColumns[i];
                        if (c === dc) {
                            bool = true 
                            break;
                        }
                    }
                    if(reportColumns.includes(c)) {
                        if (bool) {
                            shown.push({column: c, shown: true}) 
                        } else {
                            hidden.push({column: c, shown: false})
                        }
                    }
                })
                hidden.sort((a, b) => {
                    return a.column.localeCompare(b.column)
                })
                let res = shown.concat(hidden)
                reportHolder = res
            } else {
                let res = []
                reportColumns.forEach((c, i) => {
                    res.push({column: c, shown: true})
                })
                reportHolder = res
            }
        }
        return reportHolder
    }

    setTopSettings(column, direction, limit, cb) {
        this.props.setReportData("sort_column", column, () => {
            this.props.setReportData("sort_direction", direction, () => {
                this.props.setReportData("limit", limit, cb)
            })
        })
        // localCol: "", localDir: "desc", localLim: -1
        this.setState({ editSortColumn: false })
    }

    handleAvailChange(e) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedAvailVals: value })
    }

    handleInclChange(e) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedInclVals: value })
    }

    resetSelect(setting) {
        setTimeout(() => {
            this.setState({ [setting]: [] })
        }, 300)
    }

    countShown(arr) {
        return arr.reduce((acc, col) => col.shown ? acc+1 : acc, 0)
    }

    selectColumn() {
        let viewSettings = this.props.getReportData("view_settings")
        let reportColumns = Object.assign([], this.state.reportColumns)
        let allBucketColumns = Object.assign([], this.state.allBucketColumns)
        this.state.selectedAvailVals.forEach((c) => {
            let index = -1
            let continues = true
            let shown = false
            if (this.countShown(reportColumns) === 0 || reportColumns.length < 8) { 
                shown = true
            }

            reportColumns.forEach((item, i) => {
                if (!item.shown && continues && this.findIndex(c, reportColumns[i], reportColumns[i+1]) ) {
                    index = i+1
                    continues = false
                }
            })

            if (index !== -1 && reportColumns.length > 0) {
                reportColumns.splice(index, 0, {
                    column: c,
                    shown: shown
                })
            } else {
                reportColumns.push({
                    column: c,
                    shown: shown
                })
            }

            allBucketColumns.splice(allBucketColumns.indexOf(c), 1)
        })
        let columns = this.getReportColumns(reportColumns)
        viewSettings.detailed_report_columns = this.getShownReportColumns(reportColumns, viewSettings.detailed_report_columns)
        this.setState({ reportColumns: reportColumns, allBucketColumns: allBucketColumns, selectedAvailVals: [] })

        this.props.setReportData("columns", JSON.stringify(columns), () => {
            this.props.setReportData("view_settings", JSON.stringify(viewSettings))
        })
    }

    findIndex(item, a, b) {
        if (a && b) {
            if ( item.toLowerCase().localeCompare(a.column.toLowerCase()) === 1 && item.toLowerCase().localeCompare(b.column.toLowerCase() ) === -1) {
                return true
            }
        } else if (!b) {
            return true
        }
    }

    unselectColumn(unselectedColumns) {
        let reportColumns = Object.assign([], this.state.reportColumns)
        let allBucketColumns = Object.assign([], this.state.allBucketColumns)

        unselectedColumns.forEach((item, i) => {
            item = JSON.parse(item)
            allBucketColumns.push(item.column)
            allBucketColumns.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase())
            })
            reportColumns.splice(reportColumns.map((e) => { return e.column }).indexOf(item.column), 1)


        })
        let columns = this.getReportColumns(reportColumns)
        let report = fixReportDataConsistency(this.props.reportdata.report, columns)
        this.props.setReport(report, () => {
            this.setState({ reportColumns: reportColumns, allBucketColumns: allBucketColumns, selectedInclVals: [] })
    
            this.props.setReportData("columns", JSON.stringify(columns))
        })
    }

    selectAllColumns() {
        let viewSettings = this.props.getReportData("view_settings")

        let allBucketColumns = getColumnNames(this.props.getData("info", "model"))
        allBucketColumns.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })

        let columns = this.props.getData("report", "columns")
        let shownColumns = viewSettings.detailed_report_columns ?? []

        columns = allBucketColumns.map((c, i) => c)
        if (shownColumns.length === 0) {
            allBucketColumns.forEach((c, i) => { if(i < 8) shownColumns.push(c) })
        }

        let reportColumns = allBucketColumns.map((c, i) => { return {column: c, shown: shownColumns.includes(c)} })

        viewSettings.detailed_report_columns = shownColumns
        this.setState({ allBucketColumns: [], reportColumns: reportColumns })
        this.props.setReportData("columns", JSON.stringify(columns), () => {
            this.props.setReportData("view_settings", JSON.stringify(viewSettings))
        })
    }

    unselectAllColumns() {
        let allBucketColumns = getColumnNames(this.props.getData("info", "model"))
        allBucketColumns.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })
        
        let report = fixReportDataConsistency(this.props.reportdata.report, [])
        this.props.setReport(report, () => {
                this.setState({ allBucketColumns: allBucketColumns, reportColumns: [] })
                this.props.setReportData("columns", JSON.stringify([]))
        })

    }

    promptUnselectColumn() {
        let reportColumns = Object.assign([], this.state.reportColumns)
        let columns = reportColumns.map((e) => { return e.column })
        this.state.selectedInclVals.forEach((item, i) => {
            item = JSON.parse(item)
            columns.splice(columns.indexOf(item.column), 1)
        })
        
        let issues = getReportDataConsistencyIssues(this.props.reportdata.report, columns)
        let hasIssues = hasReportDataCosistencyIssues(issues)

        if(hasIssues) {
            let selecteColumns = this.state.selectedInclVals.slice();
            this.props.promptUnselectColumns(() => { let columns = selecteColumns; this.unselectColumn(columns) }, issues)
        } else {
            this.unselectColumn(this.state.selectedInclVals)
        }
    }

    promptUnselectAllColumns() {
        let issues = getReportDataConsistencyIssues(this.props.reportdata.report, [])
        let hasIssues = hasReportDataCosistencyIssues(issues)

        if(hasIssues) {
            this.props.promptUnselectColumns(() => { this.unselectAllColumns() }, issues)
        } else {
            this.unselectAllColumns()
        }
    }

    getReportColumns(arr) {
        let res = []
        arr.forEach((c, i) => {
            res.push(c.column)
        })

        return res
    }

    getShownReportColumns(reportColumns, previousShownReportColumns) { 
        //uses the previous configuratio to preserve the original order of the columns
        let res = previousShownReportColumns ? previousShownReportColumns : []
        reportColumns.forEach((c, i) => {
            let prevIndex = res.indexOf(c.column)
            if (c.shown && prevIndex === -1) {
                res.push(c.column)
            } else if(!c.shown && prevIndex > -1) {
                res.splice(prevIndex, 1)
            }
        })
        return res
    }

    promptSetBucket(e) {
        const bucket = JSON.parse(e.target.value)
        let model = this.props.bucketDetails.get(bucket.id).model

        let issues = getReportDataConsistencyIssues(this.props.reportdata.report, model.columns.map(c => c.name))
        let hasIssues = hasReportDataCosistencyIssues(issues)

        if(hasIssues) {
            this.props.promptSetBucket(bucket, issues)
        } else {
            this.setBucket(bucket)
        }
    }

    setBucket(bucket) {
        let bucketDetails = this.props.bucketDetails.get(bucket.id)
        let report = fixReportDataConsistency(this.props.reportdata.report, bucketDetails.model.columns.map(c => c.name))
        report.bucket = bucket.id
        this.props.setReport(report, () => {
            if (this.props.newReport) {
                this.props.dispatch(setNewReportBucket(bucket))
            }
            this.setState({ selectedBucket: bucket, editSortColumn: false, localCol: "", localDir: "desc", localLim: -1 })
            this.props.setData("info", bucketDetails)
        })
    }

    editSortColumn() {
        if(this.state.selectedBucket.id && this.props.checkReportColumns()) {
            this.setState({ editSortColumn: true })
        } else {
            notifyFailure('You need to include at least one number column in order to use this setting!')
        }
    }

    setGroup(e) {
        this.props.setData("report", "group_id", JSON.parse(e.target.value))
    }

    render() {
        let getEdit = () => {
            if (this.state.editSortColumn) {
                return 'single-slicer'
            }
            return 'margin-left-10px'
        }

        let sort_column = this.props.getReportData("sort_column") 
        if(Object.keys(sort_column).length === 0) sort_column = ""

        let sort_direction = this.props.getReportData("sort_direction") ?? ""
        let limit = this.props.getReportData("limit")
        let sort_column_val = this.state.localCol ? this.state.localCol : sort_column
        let sort_dir_val = this.state.localDir && this.state.editSortColumn ? this.state.localDir : sort_direction
        let limit_val = this.state.localLim && this.state.editSortColumn ? this.state.localLim : limit
        
        let oldOwnerId = this.props.info.report.user_id
        let ownerId = this.props.getData("report", "user_id")
        let enableOwnerControls = isReportAdmin(this.props.userInfo.access) || this.props.userInfo.is_consultant_login

        let is_public = this.props.getData("report", "is_public")

        let groups = this.props.groups.get("groups", [])
        groups.sort((a, b) => a.name.localeCompare(b.name))
        let group = this.props.getData("report", "group_id")

        let users = this.props.bucketUsers.get(this.props.reportdata.report.bucket)
        if (!users) {
            users = []
        }
        else {
            if(this.props.userInfo.is_consultant_login && !users.some(u => u.id ===this.props.userInfo.user_id)){
                users.push(this.props.users.find(u => u.id === this.props.userInfo.user_id))
            }
            users.sort((a, b) => {
                let aName = a.firstname + a.lastname
                let bName = b.firstname + b.lastname
                return aName.localeCompare(bName)
            })
        }

        let getColumnText = () => {
            if (sort_column !== "") {
                if (limit === -1) {
                    if (sort_direction === 'desc') {
                        return "All rows descending"
                    } else {
                        return "All rows ascending"
                    }
                }
                
                if (sort_direction === 'desc') {
                    return `Top ${limit} rows`
                } else {
                    return `Bottom ${limit} rows`
                }
            }
        }

        let getText = () => {
            if (sort_column === "") {
                return <i>&nbsp;</i>
            }
            
            return <div className="margin-left-10px inline-block">{sort_column}, <i>{getColumnText()}</i> </div>
        }

        const isConsultant = (id) => this.props.users.find(u => u.id === id).is_consultant;

        let buckets = this.props.buckets.get("buckets", []);

        if (this.props.show) {
            return (
                <div className="col-md-12 no-padding row">
                    <div className="col-md-5">
                        <div className="width-100-p inline-block margin-top-15px margin-bottom-10px">
                            <b className="inline-block">{sort_column === "" ? "Set limit for this Insight:" : "Limit:"}</b>
                            {
                                !this.state.editSortColumn ? (
                                    <div className="inline-block">
                                        {getText()}
                                        <button className="btn btn-xs btn-default margin-left-10px" onClick={() => this.editSortColumn()}>{sort_column === "" ? "Set": "Edit"} limit</button>
                                    </div>
                                ) : null
                            }
                            <div className={`form-group inline-block ${getEdit()} margin-bottom-0`}>
                                {
                                    sort_column !== "" && !this.state.editSortColumn ? (
                                        <div className="inline-block">
                                            <i className="fa fa-times abc-click" onClick={() => this.setTopSettings("", 'desc', -1)}></i>
                                        </div>
                                    ) : null
                                }
                                {
                                    this.state.editSortColumn ? (
                                        <div className="slicer-edit-group">
                                            <select className="form-control form-control-sm inline-block" id="sel1" value={sort_column_val} onChange={(e) => { this.setState({ localCol: e.target.value }) }}>
                                                <option value={""} style={{display: "none"}}>Select column</option>
                                                {
                                                    this.state.numberColumns.map((c, index) => {
                                                        return <option value={c} key={index}>{c}</option>
                                                    })
                                                }
                                            </select>
                                            <select className="form-control margin-left-5px width-100px form-control-sm inline-block" value={sort_dir_val} onChange={(e) => this.setState({ localDir: e.target.value })}>
                                                <option value={"desc"}>Top</option>
                                                <option value={"asc"}>Bottom</option>
                                            </select>

                                            <select className="form-control width-100px form-control-sm margin-left-5px inline-block" value={limit_val} onChange={(e) => this.setState({ localLim: parseInt(e.target.value, 10) })}>
                                                <option value={"-1"}>All rows</option>
                                                <option value={"5"}>5</option>
                                                <option value={"10"}>10</option>
                                                <option value={"25"}>25</option>
                                                <option value={"50"}>50</option>
                                                <option value={"75"}>75</option>
                                                <option value={"100"}>100</option>
                                            </select>
                                            <div className="btn-group margin-left-5px">
                                                <button className="btn btn-sm btn-default inline-block" onClick={() => this.setState({ editSortColumn: false}, () => this.setTopSettings("", 'desc', -1))}
                                                    title="Delete top Insight"><span className="fa fa-times red"></span>
                                                </button>
                                                <button className="btn btn-sm btn-default inline-block" title="Set top Insight" disabled={!sort_column_val} onClick={() => this.setTopSettings(sort_column_val, sort_dir_val, limit_val)}>
                                                    <span className="fa fa-check green"></span>
                                                </button>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                        <div className="width-100-p inline-block">
                            <b id="alignfix" className="inline-block">Data source:</b>
                            <div className="form-group width-150px inline-block margin-left-5px">
                                <select className="form-control form-control-sm margin-left-5px" value={JSON.stringify(this.state.selectedBucket)} onChange={(e) => this.promptSetBucket(e)}>
                                    <option style={{display: "none"}} value="">Select bucket</option>
                                    {
                                        buckets ? buckets.map((b, index) => {
                                            if (b.has_data) {
                                                return <option value={JSON.stringify(b)} key={index}>{b.name}</option>
                                            }
                                            return <option className="no-show" key={index} ></option>
                                        }) : null
                                    }
                                </select>
                            </div>
                        </div>
                    
                        <div className="width-100-p inline-block black-border-top padding-top-15px">
                            <b id="alignfix" className="inline-block">Owner:</b>
                            <div className="form-group width-150px inline-block margin-left-5px">
                                <select disabled={!enableOwnerControls} className="form-control form-control-sm margin-left-3px" id="sel1" value={ownerId} onChange={(e) => this.props.setData("report", "user_id", JSON.parse(e.target.value))}>
                                    {
                                        users.filter(u => !u.is_consultant || (u.id === this.props.userInfo.user_id && this.props.userInfo.is_consultant_login) || u.id === ownerId).map((u, index) => {
                                            if (!u.deleted) {
                                                return <option key={index} value={u.id}>{u.firstname} {u.lastname}</option>
                                            }
                                            return null
                                        })
                                    }
                                </select>
                            </div>
                            <ShowIf if={oldOwnerId !== ownerId && !is_public}>
                                <p className="alert alert-danger">
                                    You are about to change ownership of the Insight. If you save now you will no longer be able to edit this Insight. <button className="btn btn-sm btn-default" onClick={() => this.props.setData("report", "user_id", oldOwnerId)}>Cancel</button>
                                </p>
                            </ShowIf>
                            <ShowIf if={oldOwnerId !== ownerId && is_public}>
                                <p className="alert alert-warning">
                                    You are about to change ownership of the Insight. <b className="abc-click" onClick={() => this.props.setData("report", "user_id", oldOwnerId)}>Cancel</b>
                                </p>
                            </ShowIf>
                        </div>
                        <div className="width-100-p inline-block">
                            <b id="alignfix" className="inline-block">Status:</b>
                            <div className="form-group width-150px inline-block margin-left-5px">
                                <select disabled={!enableOwnerControls} className="form-control form-control-sm margin-left-5px" id="sel2" value={this.props.getData("report", "is_public")} onChange={(e) => this.props.setData("report", "is_public", JSON.parse(e.target.value))}>
                                    <option value={false}>Private</option>
                                    <option value={true}>Public</option>
                                </select>
                            </div>
                            <ShowIf if={is_public && !this.props.info.report.is_public}>
                                <p className="alert alert-warning">
                                    If you save this Insight it will be visible to all Insight users in your organization. <button className="btn btn-sm btn-default" onClick={() => this.props.setData("report","is_public", false)}>Cancel</button>
                                </p>
                            </ShowIf>
                            <ShowIf if={!is_public && this.props.info.report.is_public}>
                                <p className="alert alert-warning">
                                    You are about to make this Insight private. Doing this will remove access for other users in your organization. <button className="btn btn-sm btn-default" onClick={() => this.props.setData("report", "is_public", true)}>Cancel</button>
                                </p>
                            </ShowIf>
                            <ShowIf if={!is_public && isConsultant(ownerId) && !this.props.info.report.is_public}>
                                <p>
                                    <b>NB:</b> This Insight will only be visible to consultants.
                                </p>
                            </ShowIf>
                        </div>
                        <ShowIf if={is_public}>
                            <div className="width-100-p inline-block">
                                <b id="alignfix" className="inline-block">Group:</b>
                                <div className="form-group width-150px inline-block margin-left-5px">
                                    <select disabled={!enableOwnerControls} className="form-control form-control-sm margin-left-5px" id="sel3" value={group} onChange={(e) => this.setGroup(e)}>
                                        {
                                            groups.map((g, index) => {
                                                return <option key={index} value={g.group_id}>{g.name}</option>
                                            })
                                        }
                                        <option value={0}>Unsorted</option>
                                    </select>
                                </div>
                            </div>
                        </ShowIf>
                        {
                            (this.props.newReport || JSON.stringify(this.props.reportdata) !== JSON.stringify(this.props.info)) &&  this.state.selectedBucket.id ?
                                <WarningSaveBar disableSave={this.props.disableSave} save={() => this.props.save()} newReport={this.props.newReport} />
                                : null
                        }

                    </div>
                    <div className="col-md-7 padding-right-0 margin-top-15px margin-bottom-15px black-border-left padding-left-30px">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-5">
                                    <p>Available columns:</p>
                                    <select multiple={true} id="unselectedColumnList" value={this.state.selectedAvailVals} onBlur={() => this.resetSelect("selectedAvailVals")} className="form-control select-background" size="12" 
                                        onChange={(e) => this.handleAvailChange(e)} onDoubleClick={() => this.selectColumn()}>
                                        {
                                            this.state.allBucketColumns.map((c, index) => {
                                                return <option value={c} key={index}>{c}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="col-md-2">
                                    <p>&nbsp;</p>
                                    <button className="btn btn-default btn-xs btn-block" disabled={this.state.selectedAvailVals.length <= 0} onClick={() => this.selectColumn()}
                                        id="btn-compose-viewer-arrow-right">
                                        <i className="fa fa-arrow-right"></i>
                                    </button>
                                    <button className="btn btn-default btn-xs btn-block" disabled={this.state.selectedInclVals.length <= 0} onClick={() => this.promptUnselectColumn()}
                                        id="btn-compose-viewer-arrow-left">
                                        <i className="fa fa-arrow-left"></i>
                                    </button>
                                    <button className="btn btn-default btn-xs btn-block" disabled={this.state.allBucketColumns.length <= 0} onClick={() => this.selectAllColumns()}
                                        id="btn-compose-viewer-arrow-right">
                                        All <i className="fa fa-arrow-right"></i>
                                    </button>
                                    <button className="btn btn-default btn-xs btn-block" disabled={this.state.reportColumns.length <= 0} onClick={() => this.promptUnselectAllColumns()}
                                        id="btn-compose-viewer-arrow-left" >
                                        <i className="fa fa-arrow-left"></i> All
                                    </button>
                                </div>

                                <div className="col-md-5">
                                    <p>Included columns:</p>
                                    <select multiple={true} id="selectedColumnList" value={this.state.selectedInclVals} onChange={(e) => { this.handleInclChange(e) }} className="form-control" size="12" 
                                        onBlur={() => this.resetSelect("selectedInclVals")} onDoubleClick={() => this.promptUnselectColumn()}>
                                        {
                                            this.state.reportColumns.map((c, index) => {
                                                
                                                return <option className={c.shown ? "" : "reportColumnItemGray"} value={JSON.stringify(c)} key={index}>{c.column}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <span></span>
    }
}

DataTab = connect(mapStateToProps, null, null, { forwardRef: true })(DataTab)

export default DataTab
