import React from 'react'

import '../css/MultiSelectBar.css'
import ShowIf from './Generic/ShowIf'

export interface MultiSelectBarProps {
    type: string,
    selected: any[],
    className?: string,
    style?: React.CSSProperties,
    options: MultiSelectOption[],
    onDeselectClick: (selected?: any[]) => void,  
}

export interface MultiSelectOption {
    label: string | JSX.Element,
    onClick: (selected?: any[]) => void,
    type?: MultiSelectButtonType,
    disable?: boolean
}

export enum MultiSelectButtonType {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING"
}


const MultiSelectBar = (props: MultiSelectBarProps) => {
    let getTypeString = () => {
        return props.selected.length == 1 ? props.type : `${props.type}s`
    }    

    let getStyling = (type: MultiSelectButtonType) => {
        switch (type) {
            case MultiSelectButtonType.DEFAULT:
                return 'btn-outline-primary'
            case MultiSelectButtonType.WARNING:
                return 'btn-outline-danger'
        }
    }

    return (
    <ShowIf if={props.selected.length > 0}>
        <div className={`multiselect-bar ${props.className ?? ''}`} style={props.style}>
            <div className='multiselect-left'>
                    You have selected <span style={{ color: "#1e3d58", fontWeight: "bold"}}>{props.selected.length}</span> {getTypeString()}
                    <button className="btn btn-default ml-2" onClick={()=> props.onDeselectClick()}>Deselect</button>
            </div>
            <div className='multiselect-right'>
                {props.options.map((o, i) => {
                    return <button key={i} disabled={o.disable ?? false} className={`btn btn-sm btn-default multiselect-button ${o.type ? getStyling(o.type) : ""}`} onClick={() => o.onClick(props.selected)}>
                        {o.label}
                    </button>
                })}
            </div>
        </div>
    </ShowIf>
    )
}

export default MultiSelectBar