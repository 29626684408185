import React, { Component } from 'react'

const kilo = 1024
const mega = kilo * kilo
const giga = mega * kilo

class DataSize extends Component {
    render() {
        const { size, decimals } = this.props

        let rSize = parseFloat(size)
        let unit = 'bytes'
        let scaledSize = rSize

        if (rSize / giga > 1) {
            unit = 'GB'
            scaledSize = rSize / giga
        } else if (rSize / mega > 1) {
            unit = 'MB'
            scaledSize = rSize / mega
        } else if (rSize / kilo > 1) {
            unit = 'KB'
            scaledSize = rSize / kilo
        }

        scaledSize = parseFloat(scaledSize.toFixed(decimals && unit !== 'bytes' ? 2 : 0)).toLocaleString()

        return (
            <span title={`${rSize.toFixed(0)} bytes`}>{scaledSize} {unit}</span>
        )
    }
}

export default DataSize