
import React, { ReactNode } from 'react'

import '../css/SmallTabs.css'

export interface TabsProps<T> {
    onChange: (value: T) => void,
    value: T,
    options: SmallTabOption<T>[],
    className?: string,
    style?: React.CSSProperties
}

export interface SmallTabOption<T> {
    label: ReactNode,
    value: T,
    disabled?: boolean,
    tooltip?: string,
}

const SmallTabs = <T,>(props: TabsProps<T>) => {

    return (
        <div className={`tabs-sm ${props.className ?? ''}`} style={props.style ?? {}}>
            {
                props.options.map((o, i) => {
                    return (
                        <div className={`tab-sm abc-click ${o.disabled ? 'tab-sm-disabled': ''} ${props.value === o.value ? 'tab-sm-chosen' : ''}`} key={i} onClick={() => { if(!o.disabled){ props.onChange(o.value);}}}>
                            <span title={`${o.tooltip ? o.tooltip : ""}`}>{o.label}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SmallTabs
