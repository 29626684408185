import React from 'react'
import { isChecklistAdmin } from '../../helpers/PermissionHelpers'

class ChecklistDropdown extends React.Component {
    dropdown() {
        let jsx = []
        let checklist = this.props.checklist
        if(checklist.list.archived) {
            jsx.push(
                <li className="abc-clickable" key={"dd-"+checklist.list.id+"-1"}><span id="btn-checklist-dashboard-unarchive" onClick={() => {this.props.onClickRestore()}}>Restore</span></li>,
                <li className="abc-clickable" key={"dd-"+checklist.list.id+"-2"}><span id="btn-checklist-dashboard-delete" onClick={() => {this.props.onClickDelete()}}>Delete (Permanent)</span></li>
            )
        } else {
            jsx.push(
                <li className="abc-clickable" key={"dd-"+checklist.list.id+"-1"}><span id="btn-checklist-dashboard-assign" onClick={() => {this.props.onClickAssign()}}>Assign</span></li>,
            )
            if (checklist?.list?.bucket != "") {
                jsx.push(
                    <li className="abc-clickable" key={"dd-"+checklist.list.id+"-3"}><span id="btn-checklist-dashboard-edit" onClick={() => {this.props.onClickEdit()}}>Edit</span></li>,
                )
            } else {
                jsx.push(
                    <li className="abc-clickable" key={"dd-"+checklist.list.id+"-3"}><span id="btn-checklist-dashboard-edit" onClick={() => {this.props.onClickEditName()}}>Edit name</span></li>,
                )
            }
            jsx.push(
                isChecklistAdmin(this.props.permissions) ? <li className="abc-clickable" key={"dd-"+checklist.list.id+"-4"}><span id="btn-checklist-dashboard-edit"  onClick={() => {this.props.onClickChangeAuthor()}}>Change author</span></li> : null,
                <li className='abc-clickable' key={"dd-"+checklist.list.id+"-8"}><span id="btn-checklist-dashboard-layout" onClick={() => {this.props.onClickLayout()}}>Change layout</span></li>,
                <li key={"dd-"+checklist.list.id+"-5"} role="separator" className="divider"></li>,
                <li className='abc-clickable' key={"dd-"+checklist.list.id+"-6"}><span id="btn-checklist-dashboard-duplicate" onClick={() => {this.props.onClickDuplicate()}}>Copy this Action list</span></li>,
                <li className="abc-clickable" key={"dd-"+checklist.list.id+"-7"}><span id="btn-checklist-dashboard-archive" onClick={() => {this.props.onClickArchive()}}>Delete</span></li>,
            )
        }
        return (<ul className="dropdown-menu dropdown-menu-right">{jsx}</ul>)
    }

    render() {
        return (
            <div className="btn-group width-100-p margin-left-5px" role="group" aria-label="...">
                <div className="btn-group" role="group">
                    <button type="button" className="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </button>
                    {this.dropdown()}
                </div>
            </div>
        )
    }
}

export default ChecklistDropdown