import React from 'react'
import WarningAcceptDialog from './WarningAcceptDialog'

class DeleteChecklistDialog extends React.Component {
    show() {
        this.refs.deleteChecklistDialog.show()
    }

    hide() {
        this.refs.deleteChecklistDialog.hide()
    }

    render() {
        return (
            <WarningAcceptDialog ref="deleteChecklistDialog" title="Delete Action list" acceptText="Delete" accept={() => this.props.accept()} closeHandler={() => {if(this.props.closeHandler) this.props.closeHandler()}}>
                {
                    this.props.all ?
                        <p style={{margin: 0}}>Do you want do delete <b>all</b> Action lists? <br/><br/>This is permanent and cannot be undone.</p>
                    : <p style={{margin: 0}}>Do you want to delete this Action list? <br/><br/>This is permanent and cannot be undone.</p>
                }
            </WarningAcceptDialog>
        )
    }
}

export default DeleteChecklistDialog