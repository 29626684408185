import React from 'react'
import { getNotifications, unreadNotification, readNotification, readAllNotifications } from '../actions/NotificationActions'
import { connect } from 'react-redux'
import NotificationItem from '../components/NotificationItem'
import ShowIf from '../components/Generic/ShowIf'
import { getEmailSettings } from '../actions/UserActions'
import { HashLink as Link } from 'react-router-hash-link'
import { setPageTitle } from '../helpers/DocumentHelpers'

const mapStateToProps = (state, ownProps) => {
	return {
        notifications: state.Notification.notifications,
        unreadNotifications: state.Notification.unreadNotifications,
        emailSettings: state.User.email_settings
	}
}

class Notifications extends React.Component {
    state = {
        offset: 0,
        limit: 25,
        filter: false
    }

    componentDidMount() {
        this.props.dispatch(getNotifications(this.state.limit, this.state.offset))
        this.props.dispatch(getEmailSettings())
        setPageTitle("My notifications")
    }

    showMore = () => {
        this.setState({offset: this.state.offset + this.state.limit}, () => {
            this.props.dispatch(getNotifications(this.state.limit, this.state.offset))
        })
    }

    readNotification = (nId) => {
        this.props.dispatch(readNotification(nId))
    }
    
    unreadNotification = (nId) => {
        this.props.dispatch(unreadNotification(nId))
    }

    readAll = () => {
        this.props.dispatch(readAllNotifications())
    }

    onClose = () => {
        /* NOP */
    }
    
    render() {
        let notifications = this.state.filter ? this.props.unreadNotifications : this.props.notifications.slice(0, this.state.limit + this.state.offset)

        let emailString = ""
        switch(this.props.emailSettings) {
            case "off":
                emailString = "E-mail notifications: We'll never send you e-mails"
                break
            case "on":
                emailString = "E-mail notifications: We'll send you instant notifications when you receive checklists, someone tags you etc. "
                break
            case "daily":
                emailString = "E-mail notifications: We'll send you a daily digest of unread notifications"
                break
            default:
                emailString = ""
        }

        return (
            <div className="container-fluid padding-top-bottom-10px">
                <h1 className="text-align-center">Notifications</h1>
                <div className="col-lg-6 col-md-10 col-sm-12 col-12 offset-lg-3 offset-md-2 offset-sm-0">
                    <div>
                        <div className="notifications-overview-header">
                            <div className="btn-group notifications-overview-filter">
                                <button className={`btn ${this.state.filter ? "btn-default" : "btn-primary"}`} onClick={() => this.setState({filter: false})}>All</button>
                                <button className={`btn ${this.state.filter ? "btn-primary" : "btn-default"}`} onClick={() => this.setState({filter: true})}>Unread</button>
                            </div>
                            <button className="btn btn-default notifications-overview-mark-all-as-read" onClick={this.readAll}>Mark all as read</button>
                        </div>
                    </div>
                    <div>
                        <p>
                            {emailString}
                            &nbsp;
                            <Link to="/user#email-settings">
                                <span className="fa fa-edit email-settings-button" title="Edit email settings"/>
                            </Link>
                        </p>
                    </div>
                    <div>
                        <div className="notifications-overview">
                            {
                                notifications.map(n => 
                                    <NotificationItem key={n.id} notification={n} readNotification={this.readNotification} unreadNotification={this.unreadNotification} onClose={this.onClose} forceFullDescription />
                                )
                            }
                            <ShowIf if={notifications.length === 0}>
                                <i>You have no {this.state.filter ? "unread" : ""} notifications...</i>
                            </ShowIf>
                        </div>
                    </div>
                    <ShowIf if={this.state.offset + this.state.limit === notifications.length && !this.state.filter}>
                        <button className="btn btn-primary notifications-show-more" onClick={this.showMore}>Show more</button>
                    </ShowIf>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Notifications)