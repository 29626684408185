import * as ActionTypes from '../actions/ActionTypes'
import { SingleKeyMap } from '../helpers/Collections'

const defaultState = {
    bucketDetails: new SingleKeyMap(),
    buckets: new SingleKeyMap(),
    bucketUsers: new SingleKeyMap(),
    search: undefined,
    brokenReports: new SingleKeyMap(),
    selectedBucket: undefined,
    downloadToken: new SingleKeyMap(),
    profits: new SingleKeyMap(),
    stockBalance: new SingleKeyMap(),
    stockBalanceData: new SingleKeyMap(),
}

const Bucket = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.SEARCH_DONE:
            return Object.assign({}, state, { search: action.payload })
        case ActionTypes.CLEAR_SEARCH:
            return Object.assign({}, state, { search: undefined })
        case ActionTypes.SET_NEW_REPORT_BUCKET:
            return Object.assign({}, state, { selectedBucket: action.payload })
        case ActionTypes.GET_BUCKET_INFO_DONE:
            let buckets = new SingleKeyMap(state.buckets)
            buckets.set(action.payload.id, action.payload)
            return Object.assign({}, state, { buckets: buckets })
        case ActionTypes.GET_BUCKETS_INFO_DONE:
            let bucketDetails = new SingleKeyMap(state.bucketDetails)
            if (action.payload) {
                action.payload.forEach(b => {
                    bucketDetails.set(b.id, b)
                })
            }
            return Object.assign({}, state, { bucketDetails: bucketDetails })
        case ActionTypes.GET_BUCKET_USERS_DONE:
            let buckerUsers = new SingleKeyMap(state.bucketUsers)
            buckerUsers.set(action.payload.bucket, action.payload.users)
            return Object.assign({}, state, { bucketUsers: buckerUsers })
        case ActionTypes.GET_DOWNLOAD_TOKEN_DONE:
            let downloadToken = new SingleKeyMap(state.downloadToken)
            downloadToken.set("data", action.payload)
            return Object.assign({}, state, { downloadToken: downloadToken })
        case ActionTypes.GET_PROFIT_DATA_DONE:
            let profits = new SingleKeyMap(state.profits)
            profits.set(action.payload.id, action.payload.data)
            return Object.assign({}, state, { profits: profits })
        case ActionTypes.GET_STOCK_BALANCE_DATA_DONE:
            let stockBalance = new SingleKeyMap(state.stockBalance)
            stockBalance.set(action.payload.id, action.payload.data)
            return Object.assign({}, state, { stockBalance: stockBalance })
        case ActionTypes.GET_STOCK_BALANCE_COLUMN_DATA_DONE:
            let stockBalanceData = new SingleKeyMap(state.stockBalanceData)
            stockBalanceData.set("data", action.payload.data)
            return Object.assign({}, state, { stockBalanceData: stockBalanceData })
        default:
            return state
    }
}

export default Bucket