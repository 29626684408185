import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getNotifications = (limit, offset) => {
    return packAction(ActionTypes.GET_NOTIFICATIONS, {limit, offset})
}

export const gotNotifications = (notifications, limit, offset) => {
    return packAction(ActionTypes.GET_NOTIFICATIONS_DONE, {notifications, limit, offset})
}

export const getUnreadNotifications = (copyNewNotifications) => {
    return packAction(ActionTypes.GET_UNREAD_NOTIFICATIONS, copyNewNotifications)
}

export const gotUnreadNotifications = (notifications, copyNewNotifications) => {
    return packAction(ActionTypes.GET_UNREAD_NOTIFICATIONS_DONE, {notifications, copyNewNotifications})
}

export const readNotification = (id) => {
    return packAction(ActionTypes.READ_NOTIFICATION, id)
}

export const didReadNotification = (id) => {
    return packAction(ActionTypes.READ_NOTIFICATION_DONE, id)
}

export const unreadNotification = (id) => {
    return packAction(ActionTypes.UNREAD_NOTIFICATION, id)
}

export const didUnreadNotification = (id) => {
    return packAction(ActionTypes.UNREAD_NOTIFICATION_DONE, id)
}

export const readAllNotifications = () => {
    return packAction(ActionTypes.READ_ALL_NOTIFICATIONS)
}

export const didReadAllNotifications = () => {
    return packAction(ActionTypes.READ_ALL_NOTIFICATIONS_DONE)
}