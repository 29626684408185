import type { BucketInfo, Category, Color, ColumnHistory, ColumnHistoryMonth, ProfitabilitySection, ProfitabilityHeading, AggregationDataContainer } from "../types/transfertypes"
import { DecoratedColumnHistoryMonth } from "../types/types"
import { SingleKeyMap } from "./Collections"
import { deepClone } from "./GeneralHelpers"

export const getABCCode = (bucket: BucketInfo, data: any[]) => {
    let index = bucket.model.columns.findIndex(c => c.name === bucket.model.categorization_name)
    if(index > -1){
        return data[index] ?? "-"
    }
    return "-"
}

export const getABCCodeBgColor = (primaryColor: Color) => {
    let gradientFactor = 0.857
    let r = Math.ceil(primaryColor.r * gradientFactor)
    let g = Math.ceil(primaryColor.g * gradientFactor)
    let b = Math.ceil(primaryColor.b * gradientFactor)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
}

export const getABCCodeBgColor2 = (primaryColor: Color) => {
    let gradientFactor = 0.557
    let r = Math.ceil(primaryColor.r * gradientFactor)
    let g = Math.ceil(primaryColor.g * gradientFactor)
    let b = Math.ceil(primaryColor.b * gradientFactor)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
}

export const getABCCodeFgColor = (primaryColor: Color) => {
    if ((primaryColor.r * 0.299 + primaryColor.g * 0.587 + primaryColor.b * 0.114) > 186) {
        return 'black'
    } else {
        return 'white'
    }
}

export const getABCCodeStyle = (code: string, categorizations: Category[]) => {
    let primaryColor: Color = { r: 221, g: 221, b: 221 }
    for (let i = 0; i < categorizations.length; i++) {
        if (code === categorizations[i].name) {
            primaryColor = categorizations[i].color
        }
    }

    return {
        backgroundColor: getABCCodeBgColor(primaryColor),
        background: "linear-gradient(" + getABCCodeBgColor(primaryColor) + "," + getABCCodeBgColor2(primaryColor) + ")",
        color: getABCCodeFgColor(primaryColor)
    }
}

export const getProfitHistoryData = (historyData: SingleKeyMap<any>, column: string, fromReport: boolean): ColumnHistory => {
    if (fromReport) {
        const hisData: AggregationDataContainer[] = historyData.get(column, null)
        if (hisData === null) return { data: [], column: "", item_id: "" }
        let res: ColumnHistory
        let _data: ColumnHistoryMonth[] = []
        hisData.forEach(d => {
            _data.push({ found: d.found, label: d.label, value: d.aggregation.total?.sum ?? 0 })
        })
        res = { item_id: "", column: column, data: _data }
        return res
    }
    return historyData.get(column, { data: [] })
}

export const getProfitHistoryValue = (data: ColumnHistory, columnIndex: number): number => {
    return data.data[columnIndex] && data.data[columnIndex].found ? data.data[columnIndex].value : NaN
}

export const findLastIndex = (data: ColumnHistoryMonth[]) => {
    for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].found) return i
    }
    return -1
}

export const findLastHistoryDataIndex = (keymap: SingleKeyMap<any>, fromReport: boolean) => {
    const first = getFirstInKeymap(keymap, fromReport)
    if (first === undefined) return -1;
    
    for (let i = first.length - 1; i >= 0; i--) {
        if (first[i].found) return i;
    }
    return -1
}

function getFirstInKeymap(keymap: SingleKeyMap<any>, fromReport: boolean): AggregationDataContainer[] {
    const obj: object = Object.values(keymap)[0]
    const first: AggregationDataContainer[] = fromReport ? Object.values(obj)[0] : Object.values(obj)[Object.values(obj)[0]?.column === "" ? 1 : 0]?.data // for some reason, the keymap is sometimes containing an entry with column = "" and all datapoints are not found
    // so if the first entry in keymap is unusable due to this, take entry at index 1.
    return first
}

export const getTotalProfitData = (sections: ProfitabilitySection[], headings: ProfitabilityHeading[], historyData: SingleKeyMap<any>, selectedIndex: number, fromReport: boolean) => {
    let res: number[][] = [] // an array of number arrays
    sections.forEach((section) => {
        let rowRes: number[] = []
        let values: number[][] = []
        section.rows.forEach((row) => {
            row.columns.forEach((col, i) => {
                const canClick = headings[i].type !== "none"
                const data = getProfitHistoryData(historyData, col, fromReport)
                const val = getProfitHistoryValue(data, canClick ? selectedIndex : findLastIndex(data.data))
                if (!values[i]) values[i] = []
                if (row.subtract && !isNaN(val)) {
                    values[i].push(-Math.abs(val))
                } else {
                    values[i].push(val)
                }
            })
        })

        if (section.total_row.use_columns) {
            section.total_row.columns.forEach(col => {
                const data = getProfitHistoryData(historyData, col, fromReport)
                const val = getProfitHistoryValue(data, selectedIndex)
                rowRes.push(val)
            })
        } else {
            values.forEach((arr) => {
                let rowSum = arr.reduce((acc: number, currentVal: number) => acc + currentVal, 0)
                rowRes.push(rowSum)
            })
        }

        res.push(rowRes)
    })

    return res
}

export const calculateProfitTotal = (totalData: number[][], index: number): number => {
    let arr: number[] = []
    totalData.forEach(totalArr => {
        if (totalArr[index]) arr.push(totalArr[index])
    })
    if (arr.length === 0) return NaN; // if no data

    return arr.reduce((acc, currentVal) => acc + currentVal, 0)
}

export const getProfitMonth = (keymap: SingleKeyMap<any>, index: number, fromReport: boolean) => {
    const values: object = Object.values(keymap)[0] // we want the first (just any) entry in SingleKeyMap, it doesn't matter what the key is
    const data = fromReport ? Object.values(values)[0] : Object.values(values)[0]?.data // the object returned above will be an object with entries of columns as keys that contain data -> we just want whatever is first
    if (data) {
        let months: any[] = []
        data.forEach((d: ColumnHistoryMonth) => {
            months.push(d.label)
        })
        return months[index]
    }

    return "NA"
}

export const getSingleItemHistoryBarData = (historyData: ColumnHistoryMonth[]): DecoratedColumnHistoryMonth[] => {
    return deepClone(historyData).reduce((arr: DecoratedColumnHistoryMonth[], c: ColumnHistoryMonth, i: number) => {
        if (i > 0 && historyData[i - 1].value === c.value) {
            arr[arr.length - 1].width += 1
            arr[arr.length - 1].endLabel = c.label
            return arr
        }
        const d: DecoratedColumnHistoryMonth = {
            width: 1,
            endLabel: c.label,
            label: c.label,
            value: c.value,
            found: c.found
        }
        arr.push(d)
        return arr
    }, [])
}

export const getTotalForPercentage = (sections: ProfitabilitySection[], headings: ProfitabilityHeading[], historyData: SingleKeyMap<any>, fromReport: boolean, index: number, lastIndex: number) => {
    if (!sections[0]) return null;
    const first: ProfitabilitySection = sections[0]
    let tmp: [number[]] = [[]]

    first.rows.forEach(r => {
        if (!r.subtract) {
            r.columns.forEach((c, i) => {
                const data = getProfitHistoryData(historyData, c, fromReport)
                const val = getProfitHistoryValue(data, headings[i].type !== "none" ? index : lastIndex)
                if (!tmp[i]) {
                    tmp[i] = []
                }
                tmp[i].push(Math.round(val))
            })
        }
    })
    return tmp.map(v => v.reduce((acc, cur) => acc + cur, 0))
}
