import * as ActionTypes from "../../actions/ActionTypes"
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { notifySuccess, notifyFailure } from '../../helpers/NotificationManager'
import { apiGet, apiPut, apiPost, apiDelete, apiCall } from '../../helpers/ApiHelpers'
import { batchDeleteMailbundlesDone, createMailBundleDone, deleteMailBundleDone, getAllPlansDone, gotOrgMailBundles, setActiveMultipleMailbundlesDone, toggleMailBundleActiveDone, updateMailBundleDone } from "../../actions/MailBundleActions"

const ReportingApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url;
    let data;
    let mailBundle;
    let ids
    let orgID;

    switch(action.type){
        case ActionTypes.GET_ORG_MAILBUNDLES:
            url = `org/mailbundles/${action.payload.orgID}`
            apiGet(url, (mailBundles) => {
                dispatch(gotOrgMailBundles(mailBundles))
            },
            (e) => {
                notifyApiFailure("getting mail bundles", "GET", url, data, e?.status)
            })
            break;
        case ActionTypes.CREATE_MAILBUNDLE:
            mailBundle = action.payload
            url = `org/mailbundles/${mailBundle.org_id}`
            apiPost(url, mailBundle, res => {
                    dispatch(createMailBundleDone(res))
                    notifySuccess(`${res.name} has been created`)
                },
                _ => notifyFailure("Error creating new mail bundle")
            )
            break;
        case ActionTypes.UPDATE_MAILBUNDLE:
            mailBundle = action.payload
            url = `org/mailbundles/${mailBundle.org_id}`
            apiPut(url, mailBundle, res => {
                    dispatch(updateMailBundleDone(res))
                    notifySuccess(`${res.name} updated successfully`)
                },
                _ => notifyFailure("Error updating mail bundle")
            )
            break;
        case ActionTypes.DELETE_MAILBUNDLE:
            mailBundle = action.payload
            url = `org/mailbundles/${mailBundle.org_id}/${mailBundle.id}`
            apiDelete(url, res => {
                    dispatch(deleteMailBundleDone(mailBundle.id))
                    notifySuccess(`${mailBundle.name} deleted successfully`)
                },
                _ => notifyFailure("Error deleting mail bundle")
            )
            break;
        case ActionTypes.TOGGLE_MAILBUNDLE_ACTIVE:
            mailBundle = action.payload
            url = `org/active/mailbundles/${mailBundle.org_id}/${mailBundle.id}`
            apiPut(url, null, res => {
                    dispatch(toggleMailBundleActiveDone(mailBundle.id, res.active))
                    notifySuccess(`${mailBundle.name} ${res.active ? "activated" : "deactivated"}`)
                },
                err => {
                    console.log(err);
                    notifyFailure("Error toggling mail bundle active state")
                }
            )
            break;
        case ActionTypes.ACTIVATE_MULTIPLE_MAILBUNDLES:
            ids = action.payload.ids
            orgID = action.payload.orgID
            url = `org/batch/activate/mailbundles/${orgID}`
            apiPut(url, {ids: ids}, res => {
                    dispatch(setActiveMultipleMailbundlesDone(ids, true))
                    notifySuccess(`Mailbundles activated`)
                },
                err => {
                    console.log(err);
                    notifyFailure(`Error activating mailbundles`)
                }
            )
            break;
        case ActionTypes.DEACTIVATE_MULTIPLE_MAILBUNDLES:
            ids = action.payload.ids
            orgID = action.payload.orgID
            url = `org/batch/deactivate/mailbundles/${orgID}`
            apiPut(url, {ids: ids}, res => {
                    dispatch(setActiveMultipleMailbundlesDone(ids, false))
                    notifySuccess(`Mailbundles deactivated`)
                },
                err => {
                    console.log(err);
                    notifyFailure(`Error deactivating mailbundles`)
                }
            )
            break;
        case ActionTypes.BATCH_DELETE_MAILBUNDLES:
            ids = action.payload.ids
            orgID = action.payload.orgID
            url = `org/batch/delete/mailbundles/${orgID}`
            apiCall(url, "DELETE", {ids: ids})
                .then(res => {
                    dispatch(batchDeleteMailbundlesDone(ids))
                    notifySuccess(`Mailbundles deleted`)
                })
                .catch(err => {
                    console.log(err);
                    notifyFailure(`Error deleting mailbundles`)
                })
            break;
        case ActionTypes.GET_ALL_REPORTING_PLANS:
            url = `reporting/plans`
            apiGet(url, (plans) => {
                    dispatch(getAllPlansDone(plans))
                }, (e) => {
                    notifyApiFailure("getting reporting plans", "GET", url, data, e?.status)
                }
            )
            break;
        default:
    }

}

export default ReportingApiMiddleware