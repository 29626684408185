export const ABC_SINGLE_SETTINGS_SERVICE = 'ABC_SINGLE_SETTINGS_SERVICE'

const storage = window.sessionStorage

export const getSetting = (key:string, defaultValue?:string):string|undefined => {
    let value = storage.getItem(ABC_SINGLE_SETTINGS_SERVICE+"-"+key)
    if (value) return value
    return defaultValue
}

export const removeSetting = (key:string) => {
    storage.removeItem(ABC_SINGLE_SETTINGS_SERVICE+"-"+key)
}

export const putSetting = (key:string, value:string) => {
    return storage.setItem(ABC_SINGLE_SETTINGS_SERVICE+"-"+key, value)
}

export const getAndRemoveSetting = (key:string, defaultValue:string) => {
    let value = getSetting(key)
    if (value) {
        removeSetting(key)
        return value
    }
    return defaultValue
}