import React from 'react'
import ShowIf from './Generic/ShowIf'
import { connect } from 'react-redux'
/*  */

const mapStateToProps = (state, ownProps) => {
	return {
        userPermissions: state.User.info.access,
        userInfo: state.User.info,
	}
}

class RequirePermission extends React.Component {
    render() {
        let perms = this.props.perms
        let and = this.props.and !== undefined ? this.props.and : true
        let or = this.props.or !== undefined ? this.props.or : false
        let andUser = this.props.andUser !== undefined ? this.props.andUser : this.props.userInfo.user_id
    
        let userPermissions = this.props.userPermissions
        if(!userPermissions || !perms) return null
        let hasAccess = perms.some(p => userPermissions.includes(p)) && and && (this.props.userInfo.user_id === andUser || or)
        // hasAccess = hasAccess || or
        if((hasAccess && !this.props.children) || (!hasAccess && !this.props.alt)) return null
        return (
            <React.Fragment>
                <ShowIf if={hasAccess}>
                    {this.props.children}
                </ShowIf>
                <ShowIf if={!hasAccess}>
                    {this.props.alt}
                </ShowIf>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(RequirePermission)