import * as ActionTypes from '../actions/ActionTypes'

const defaultState = { playLoginAnimation: false, loggedIn: false, info: {}, email_settings: "", force2FA: false, passwordPolicy: null, nps: null, followings: [] }

const User = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.LOGOUT_DONE: //Note: see "index.js". The entire state is reset in there.
            return Object.assign({}, state, {
                info: {},
                loggedIn: false
            })
        case ActionTypes.LOGIN_DONE:
            return Object.assign({}, state, {
                loggedIn: true,
                playLoginAnimation: true,
            })
        case ActionTypes.GET_USER_INFO_DONE:
            let info = action.payload
            if (info && info.access) {
                info.access = JSON.parse(info.access)
            }
            return Object.assign({}, state, {
                info: info
            })
        case ActionTypes.LOGIN_ANIMATION_PLAYED:
            return Object.assign({}, state, {
                playLoginAnimation: false,
            })        
        case ActionTypes.INITIALIZE_2FA_DONE:
            return Object.assign({}, state, {
                secret: action.payload
            })
        case ActionTypes.SETUP_2FA_DONE:
            return Object.assign({}, state, {
                result: action.payload
            })
        case ActionTypes.IS_2FA_ENABLED_DONE:
            return Object.assign({}, state, {
                isEnabled: action.payload.isEnabled
            })
        case ActionTypes.RESET_SECRET:
            return Object.assign({}, state, {
                secret: null
            })
        case ActionTypes.GOOGLE_LOGIN_DONE:
            return Object.assign({}, state, {
                token: action.payload
            })
        case ActionTypes.GET_EMAIL_SETTINGS_DONE:
            return Object.assign({}, state, {
                email_settings: action.payload
            })
        case ActionTypes.IS_2FA_FORCED_DONE:
            return Object.assign({}, state, {
                force2FA: action.payload.force2FA
            })
        case ActionTypes.GET_PASSWORD_POLICY_DONE:
            return Object.assign({}, state, {
                passwordPolicy: action.payload
            })
        case ActionTypes.GET_NPS_ANSWER_DONE:
            return Object.assign({}, state, {
                nps: action.payload.nps
            })
        case ActionTypes.GET_FOLLOWINGS_DONE:
            return Object.assign({}, state, {
                followings: action.payload
            })
        default:
            return state
    }
}

export default User