import React from 'react'
import { connect } from 'react-redux'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { getItemData, getItemCategoryHistoryGraph, getItemDrilldown, getItemCategoryHistory } from '../actions/ItemActions'
import SingleItemCategoryHistory from '../components/SingleItem/SingleItemCategoryHistory'
import SingleItemGraph from '../components/SingleItem/SingleItemGraph'
import SingleItemData from '../components/SingleItem/SingleItemData'
import { getBucketInfo, getProfitData, getStockBalanceData } from '../actions/BucketActions'
import { getABCCode, getABCCodeStyle } from '../helpers/ItemHelpers'
import { withSettingsStorage } from '../helpers/SettingsService'
import { getColumnType, getNumberColumnsObj, getTextColumnsObjWithCat } from '../helpers/ReportHelpers'
import SingleItemTextColumn from '../components/SingleItem/SingleItemTextColumn'
import SingleItemProfitData from '../components/SingleItem/SingleItemProfitData'
import ShowIf from '../components/Generic/ShowIf'
import SingleItemDrilldown from '../components/SingleItem/SingleItemDrilldown'
import WordWrap from '../components/Generic/WordWrap'
import SingleItemStockBalanceData from '../components/SingleItem/SingleItemStockBalanceData'
import SingleItemColumnBoxes from '../components/SingleItem/SingleItemColumnBoxes'
import SingleItemMonthLabels from '../components/SingleItem/SingleItemMonthLabels'
import SingleItemComments from '../components/SingleItem/SingleItemComments'
import { getUsers } from '../actions/SystemActions'
import SingleItemReferences from '../components/SingleItem/SingleItemReferences'
import SingleItemInfoBox from '../components/SingleItem/SingleItemInfoBox'
import { hideDiffViewDialog } from '../helpers/DiffViewManager'
import RequirePermission from '../components/RequirePermission'
import { getUserInfo } from '../actions/UserActions'
import {is} from '../helpers/PermissionHelpers'
import ExitPromptDialog from '../components/Dialogs/ExitPromptDialog'
import { updateBucketSetup } from '../actions/BucketActions'
import LinkDialog from '../components/Dialogs/LinkDialog'



const mapStateToProps = (state, ownProps) => {
    return {
        item: state.Item.item,
        buckets: state.Bucket.buckets,
        data: state.Item.data,
        historyData: state.Item.graphHistory,
        history: state.Item.history,
        profits: state.Bucket.profits,
        drilldown: state.Item.drilldown,
        stock_balance: state.Bucket.stockBalance,
        item_exists: state.Item.itemExists,
        comments: state.Item.comments,
        user: state.User.info,
        users: state.System.Users,
    }
}

class SingleItem extends React.Component {
    unsavedChangesDialog = React.createRef();
    
    state = {
        decimalColumn: null,
        textColumn: null,
        startAtZero: false,
        startAtZeroProfit: true,
        profitColumn: null,
        profitViewPercentage: false,
        graphType: "bar",
        profitGraphType: "bar",
        totalSection: { x: -1, y: -1 },
        hasTotalData: false, // just so we don't fire off 10+ (or way more) requests every time we access profit-view -> only fire once per item
        selectedColumnIndex: 11,
        tab: 0,
        commentsOpen: !!this.props.showComments ? this.props.showComments: false,
        referencesOpen: false,
        showPositivesStockBalance: false,
        showSumStockBalance: false,
        showMoreAllData: false,
        showDecimalsAllData: false,
        setup: null,
        updatedSetup: null,
        editMode: !!this.props.editMode ? this.props.editMode : false,
        showExitPrompt: false,
        drilldownAggregationColumns: [],
        drilldownShowDecimals: false,
        drilldownShownColumns: [],
        drilldownSlicers: [],
        columnsLoadedFromSettings: false,
        zoom: 12,
    }

    componentDidMount() {
        let bucketId = this.getBucketId()
        let itemID = this.getItemId()

        if(!this.props.users) 
            this.props.dispatch(getUsers())
        this.props.dispatch(getBucketInfo(bucketId))
        this.props.dispatch(getProfitData(bucketId))
        this.props.dispatch(getStockBalanceData(bucketId))

        this.props.dispatch(getItemData(bucketId, itemID))
        this.props.dispatch(getItemDrilldown(bucketId, itemID, this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : [], this.state.drilldownSlicers ? this.state.drilldownSlicers : []))

        if (this.state.textColumn) {
            this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.textColumn.name))
        }
        if (this.state.decimalColumn) {
            this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.decimalColumn.name))
        }

        this.props.dispatch(getUserInfo())

        window.$(document).on('click', 'comments-dropdown .dropdown-menu, references-dropdown .dropdown-menu', function (e) {
            e.stopPropagation()
        })
        document.addEventListener('mousedown', this.handleClickOutsideComments)
        document.addEventListener('mousedown', this.handleClickOutsideReferences)
        document.addEventListener("mousedown", this.clickOutsideHandler)
           
        let bucket = this.props.buckets.get(bucketId, null)
        if( bucket) {
            this.setState({setup: {...bucket.setup}, updatedSetup: {...bucket.setup}}, )
        }

        this.prefetchData()
    }

    prefetchData() {
        let bucketId = this.getBucketId()
        let itemID = this.getItemId()
        let bucket = this.props.buckets.get(bucketId, null)

        if(this.props.gridRef && this.props.data){
            let neighbours = this.props.gridRef.getPrefetchableIds(bucketId)
            
            for(let i = 0; i < neighbours.length; i++) {
                
                //prefetching for info tab
                if(neighbours[i] !== itemID && !this.props.data.get(bucketId, neighbours[i])){
                    this.props.dispatch(getItemData(bucketId, neighbours[i]))
                }
              
                 //prefetching for categorization-bar
                if(bucket && !this.props.history.get(bucketId, neighbours[i])) {
                    this.props.dispatch(getItemCategoryHistory(bucketId, neighbours[i], bucket.model.categorization_name))
                }

                /* 
                    Uncommented to minimize server load. The 2 calls above is enough to make the dialog performant.
                    Code kept for future use, if needed.
                */

                //const graphData = this.props.historyData.get2(bucketId, neighbours[i], null)

                // //prefetching for extra top-bar
                // if (this.state.textColumn && !graphData) {
                //     this.props.dispatch(getItemCategoryHistoryGraph(bucketId, neighbours[i], this.state.textColumn.name))
                // }
                // //prefetching for info-tab-graph
                // if (this.state.decimalColumn && !graphData) {
                //     this.props.dispatch(getItemCategoryHistoryGraph(bucketId, neighbours[i], this.state.decimalColumn.name))
                // }

                // //prefetching for info-tab-boxes
                // if(bucket.setup && bucket.setup.column_boxes && bucket.setup.column_boxes.length > 0){
                //     for(let j=0; j < bucket.setup.column_boxes.length; j++){
                //         if(!this.state.decimalColumn || this.state.decimalColumn !== bucket.setup.column_boxes[j])
                //             this.props.dispatch(getItemCategoryHistoryGraph(bucketId, neighbours[i], bucket.setup.column_boxes[j]))
                //     }
                // }
                
                // //prefetch drilldown
                // let drilldownColumns = this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : []
                // if(!this.props.drilldown.get(bucket.id, neighbours[i], drilldownColumns)) {
                //     this.props.dispatch(getItemDrilldown(bucketId, neighbours[i], this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : [], this.state.drilldownSlicers ? this.state.drilldownSlicers : []))
                // }

            }
        }

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideComments)
        document.removeEventListener('mousedown', this.handleClickOutsideReferences)
        document.removeEventListener("mousedown", this.clickOutsideHandler)
    }
    
    clickOutsideHandler = (event) => {
    
        if(this.props.masterDataDialogRef.current && !this.props.masterDataDialogRef.current.contains(event.target)) {
            if (this.state.editMode || this.props.editMode) {
                let bucket = this.props.buckets.get(this.props.bucketId, null)
                const bucketSetup = bucket ? bucket.setup : null;

                if (JSON.stringify(this.getUpdatedSetup()) !== JSON.stringify(bucketSetup)) {
                    this.setState({showExitPrompt: true})}
                    else {
                        this.props.hideMasterData()
                    }
            } else {
                this.props.hideMasterData()
            }
        }
       
    }

    handleClickOutsideComments = (event) => {
        if (this.commentsRef && !this.commentsRef.contains(event.target) && this.state.commentsOpen) {
            this.setState({ commentsOpen: false })
        }
    }

    handleClickOutsideReferences = (event) => {
        if (this.referencesRef && !this.referencesRef.contains(event.target) && this.state.referencesOpen) {
            this.setState({ referencesOpen: false })
        }
    }

    setCommentsRef = (node) => {
        this.commentsRef = node
    }

    setReferencesRef = (node) => {
        this.referencesRef = node
    }

    setLinkDialogRef = (node) => {
        this.linkDialogRef = node
    }

    getItemId() {
        return this.props.itemId
    }

    arraysEquals(first, second) {
        if(first === undefined || second === undefined || first !== second) 
            return false

        if(first.length !== second.length)
            return false
        
        for(let i = 0; i < first.length; i++){
            if(first[i] !== second[i])
                return false
        }
        return true
    }

    componentDidUpdate(prevProps, prevState) {
        let prevBucketId = prevProps.bucketId
        let itemID = this.getItemId()
        let bucketId = this.getBucketId()
        let bucket = this.props.buckets.get(bucketId, null)
        let prevBucket = prevProps.buckets.get(prevBucketId, null)
        let profit = this.props.profits.get(bucketId, null)

        if (bucket){
            if (!this.state.updatedSetup) {
                this.setState({updatedSetup: bucket.setup})
            } 

            if (!this.state.setup) {
                this.setState({setup: bucket.setup})
            } 
        }
        
        if (((JSON.stringify(bucket) !== JSON.stringify(prevBucket) && prevBucket && bucket) || (bucket !== null && this.state.decimalColumn === null))) {
            let column = ""
            if (bucket.setup.default_graph_column !== "") {
                column = { name: bucket.setup.default_graph_column, type: "decimal" }
            } else {
                let numberColumns = getNumberColumnsObj(bucket.model)
                column = numberColumns[0]
            }

            if (column) this.setState({ decimalColumn: column, graphType: bucket.setup.graph_type !== "" ? bucket.setup.graph_type : this.state.graphType, startAtZero: bucket.setup.graph_show_zero })
        }
        if (((JSON.stringify(bucket) !== JSON.stringify(prevBucket) && prevBucket && bucket) || (bucket !== null && this.state.textColumn === null))) {
            let column = { name: bucket.setup.default_item_column, type: "text" }
            this.setState({ textColumn: column })
        }

        if ((bucket !== null && this.state.profitColumn === null && profit)) {
            const sections = JSON.parse(profit.sections)
            const headings = JSON.parse(profit.headings)
            const totalRow = JSON.parse(profit.total_row)

            let column = { name: "N/A", type: "none" }
            let totalSection = { x: -1, y: -1 }

            if (sections.length > 0) {
                for (let i = 0; i < headings.length; i++) {
                    const h = headings[i];
                    if (h.type !== "none") {
                        if (totalRow.use_columns) {
                            column = { name: totalRow.columns[i], type: h.type, useTotal: true, label: totalRow.label}
                        } else {
                            column = { name: `Grand total-${i + 1}`, label: totalRow.label ?? "Grand total", type: h.type, useTotal: true, isLast: true }
                            totalSection = { x: i, y: sections.length }
                        }
                        break
                    }
                }
            }
            this.setState({ profitColumn: column, totalSection: totalSection })
          
        }

        if (this.refs.comments && this.refs.comments.getItemKey() !== itemID) {
            this.refs.comments.setItem(bucketId, itemID)
        }

        if (this.refs.references && this.refs.references.getItemKey() !== itemID) {
            this.refs.references.setItem(bucketId, itemID)
        }

        if (this.state.textColumn !== prevState.textColumn && this.state.textColumn) {
            this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.textColumn.name))
        }
        if (this.state.decimalColumn !== prevState.decimalColumn && this.state.decimalColumn) {
            this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.decimalColumn.name))
        }
        if (this.state.profitColumn !== prevState.profitColumn && this.state.profitColumn !== null && !this.state.profitColumn.useTotal) {
            this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.profitColumn.name))
        }
        if (!this.arraysEquals(this.state.drilldownAggregationColumns, prevState.drilldownAggregationColumns) && this.state.drilldownAggregationColumns !== null) {
            this.props.dispatch(getItemDrilldown(bucketId, itemID, this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : [], this.state.drilldownSlicers ? this.state.drilldownSlicers : []))
        }

        let bucketChanged = prevProps.bucketId !== this.props.bucketId
        if (prevProps.itemId !== this.getItemId() || bucketChanged) {
            let itemID = this.getItemId(this.props)
            // let bucket = this.props.bucketId

            //Change tab if the current one is not enabled
            var tab = this.state.tab

            const profit = this.props.profits.get(bucketId, null)
            const stock_balance = this.props.stock_balance.get(bucketId, null)

            if (tab === 1 && !(profit && profit.enabled)) tab = 0
            // if (tab === 2 && !(drilldown && drilldown.has_drilldown)) tab = 0
            if (tab === 3 && !(stock_balance && stock_balance.enabled)) tab = 0

            this.setState({
                textColumn: bucketChanged ? null : this.state.textColumn,
                decimalColumn: bucketChanged ? null : this.state.decimalColumn,
                tab: tab
            })
            if (bucketChanged) {
                this.props.dispatch(getBucketInfo(bucketId))
                this.props.dispatch(getProfitData(bucketId))
                this.props.dispatch(getStockBalanceData(bucketId))
            }
            this.props.dispatch(getItemData(bucketId, itemID))
            this.props.dispatch(getItemDrilldown(bucketId, itemID, this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : [], this.state.drilldownSlicers ? this.state.drilldownSlicers : []))
            if (this.state.textColumn && !bucketChanged) {
                this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.textColumn.name))
            }
            if (this.state.decimalColumn && !bucketChanged) {
                this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.decimalColumn.name))
            }
            if (this.state.profitColumn && !this.state.profitColumn.useTotal && !bucketChanged) {
                this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, this.state.profitColumn.name))
            }

            this.prefetchData()
        }

        const drilldown = this.props.drilldown.get(bucketId, itemID, this.state.drilldownAggregationColumns, null)
        if (drilldown) {
            if(drilldown.linked_bucket){
                let linked_bucket = this.props.buckets.get(drilldown.linked_bucket, null)
                if(!linked_bucket)
                    this.props.dispatch(getBucketInfo(drilldown.linked_bucket))
            }
            if (this.state.tab === 2 && !(drilldown && drilldown.has_drilldown)) this.setState({ tab: 0 })

            //setup has drilldown_columns
            if(!this.state.columnsLoadedFromSettings && bucket && bucket.setup.drilldown_shown_columns && bucket.setup.drilldown_shown_columns.length > 0){
                let tmpDrillDownShownColumns = []
                for(let i = 0 ; i < bucket.setup.drilldown_shown_columns.length; i++){
                    let col = drilldown.columns.find(x => {return x.name == bucket.setup.drilldown_shown_columns[i]})
                    if(col){
                        tmpDrillDownShownColumns.push(col)
                    }
                }

                //the columns differ
                if(JSON.stringify(this.state.drilldownShownColumns) !== JSON.stringify(tmpDrillDownShownColumns)) {
                    this.setState({drilldownShownColumns : tmpDrillDownShownColumns})
                }
                this.setState({columnsLoadedFromSettings: true})
            }
        }

        if (prevProps.profits.get(bucketId, null) !== this.props.profits.get(bucketId, null)) {
            this.setState({ profitGraphType: profit.graph_type })
        }
    }

    setDecimalColumn(c) {
        this.setState({ decimalColumn: c })
    }

    loadColumn(c) {
        let itemID = this.getItemId()
        let bucketId = this.getBucketId()
        this.props.dispatch(getItemCategoryHistoryGraph(bucketId, itemID, c.name))
    }

    setTextColumn(c) {
        if (this.state.textColumn === c) this.setState({ textColumn: "" })
        else this.setState({ textColumn: c })
    }

    setColumn(c) {
        let bucket = this.props.buckets.get(this.getBucketId(), null)
        let usedCategorization = ""
        if (bucket) usedCategorization = bucket.model.categorization_name

        if (c.type === "decimal") this.setDecimalColumn(c)
        else if (c.type === "text" || (c.type === "categorization" && c.name !== usedCategorization)) this.setTextColumn(c)
    }

    setProfitColumn(c) {
        this.setState({ profitColumn: c, totalSection: { x: -1, y: -1 } })
    }

    enterEditMode() {
        this.setState({ editMode: true })
    }

    exitEditMode(e) {
        this.setState({ editMode: !this.state.editMode})
    }

    promptExitEditMode(closeButton = null) {
        let bucket = this.props.buckets.get(this.props.bucketId, null)
        const bucketSetup = bucket? bucket.setup: null;
        if (!this.state.updatedSetup || !bucket) {
            this.exitEditMode()
            if (!!closeButton) {
                this.props.hideMasterData()
            }
        }
        else {
            if (JSON.stringify(this.getUpdatedSetup()) !== JSON.stringify(bucketSetup)) {
            this.setState({showExitPrompt: true})}
            else {
                this.exitEditMode()
                if (!!closeButton) {
                    this.props.hideMasterData()
            }
            }
        }
    }

    promptChangeTab(newTab){

        if(this.state.editMode){
            let bucket = this.props.buckets.get(this.props.bucketId, null)
            const bucketSetup = bucket ? bucket.setup : null;
        
            if (JSON.stringify(this.getUpdatedSetup()) !== JSON.stringify(bucketSetup)) {
                this.setState({showExitPrompt: true})
            }
            else {
                if(newTab === 1 || newTab === 2 || newTab === 3){
                    this.setState({editMode: false})
                }
                this.setState({ tab: newTab })
            }
        } else {
            this.setState({ tab: newTab })
        }
    }


    goBack() {
        window.history.go(-1)
    }

    getBucketId() {
        return this.props.bucketId
    }

    KeyboardPageNavigation = (props) => (<div>
        <KeyboardEventHandler
            handleKeys={['left', "right"]}
            onKeyEvent={(key, _) => {
                if (this.refs.comments.isTextAreaInFocus()) return;
                if (this.editMode) return;
                if (this.refs.drilldown && this.refs.drilldown.isSlicerOpen()) return 

                if(this.props.gridRef){
                    if (key === "left") {this.props.gridRef.next(false)} else {this.props.gridRef.next(true)}
                }
            }}
            handleFocusableElements
        />
    </div>);

    changeSelectedIndex(dir) {
        const newIndex = dir ? this.state.selectedColumnIndex - 1 : this.state.selectedColumnIndex + 1
        if (newIndex > -1 && newIndex < 12) this.setState({ selectedColumnIndex: newIndex });
    }

    goToDiff() {
        this.props.hideMasterData()
    }

    handleClose() {
        this.props.hideMasterData()
        hideDiffViewDialog(false)
    }

    getShownColumns(modelColumns, hiddenColumns) {
        if(hiddenColumns)
            return modelColumns.filter(c => !hiddenColumns.includes(c.name))
        else
            return modelColumns
    }

    columnBoxesChangedHandler = (bucketID, updatedBoxes) => {
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.column_boxes = updatedBoxes;

        this.setState({updatedSetup: newSetup})
    }

    drilldownShownColumnsChanged = (bucketID, columns) => {
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.drilldown_shown_columns = columns.map(c => c.name);

        this.setState({updatedSetup: newSetup})
        this.setState({drilldownShownColumns: columns})
    }


    getUpdatedSetup() {
        let bucketID = this.getBucketId()
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.graph_type = this.state.graphType;
        newSetup.graph_show_zero = this.state.startAtZero;
        newSetup.default_item_column = this.state.textColumn.name;

        this.setState({updatedSetup: newSetup})
        return newSetup;
    }

    additionalColumnsHandler = (additionalColumns, additionalColumns2, additionalColumns3, additionalColumns4, bucketID) => {
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.additional_columns = additionalColumns;
        newSetup.additional_columns2 = additionalColumns2;
        newSetup.additional_columns3 = additionalColumns3;
        newSetup.additional_columns4 = additionalColumns4;

        this.setState({updatedSetup: newSetup})
    }

    idDescriptionHandler = (idDescriptionColumn) => {
        let bucketID = this.getBucketId()
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.id_description_column = idDescriptionColumn;

        this.setState({updatedSetup: newSetup})
    }

    masterColumnsUpdatedHandler = (masterColumns, bucketID) => {
        let bucket = this.props.buckets.get(bucketID, null)
        let newSetup = null; 
      
        if (bucket && !this.state.updatedSetup) {
            newSetup = bucket.setup;
        } else {
            newSetup = {...this.state.updatedSetup}
        }

        newSetup.shown_master_columns = masterColumns.shown_master_columns;
        newSetup.hidden_master_columns = masterColumns.hidden_master_columns;

        this.setState({updatedSetup: newSetup})
    }

    updateBucketSetupHandled = async (data) => {
        let itemID = this.getItemId()
        const bucketID =  this.getBucketId();

        if (data.success) {
            this.props.dispatch(getBucketInfo(bucketID))
            if (this.state.textColumn) {
                await this.props.dispatch(getItemCategoryHistoryGraph(bucketID, itemID, this.state.textColumn.name))
            }
            if (this.state.decimalColumn) {
                await this.props.dispatch(getItemCategoryHistoryGraph(bucketID, itemID, this.state.decimalColumn.name))
            }
            
            this.setState({showExitPrompt: false, editMode: false, setup: {...this.state.updatedSetup}})
        } else return;
    }

    saveSetupHandler = async (e) => {
        const bucketID =  this.getBucketId();
        await this.props.dispatch(updateBucketSetup(bucketID, this.state.updatedSetup, this.updateBucketSetupHandled))        
    }

    cancelExitPrompt = (e) => {
        this.setState({showExitPrompt: false})
    }

    exitWithoutSavingHandler = () => {
        this.setState({editMode: false, showExitPrompt: false})
    }

    getEditButton = () => {
        return (
            <div className="d-flex flex-row">
                <div className="btn-group inline-block">
                    {
                        this.state.editMode && this.state.tab !== 1 && this.state.tab !== 3 ?
                            <React.Fragment>
                                <button type="button"
                                    disabled={this.state.disableSave}
                                    title=""
                                    className="btn btn-danger btn-sm text-nowrap"
                                    onClick={() => this.promptExitEditMode()} >Edit mode: ON <i className="fa fa-cog"></i>
                                </button>
                            </React.Fragment>
                            : 
                            <React.Fragment>
                                <button type="button"   disabled={this.state.tab === 1 || this.state.tab === 3} className="btn btn-default btn-sm" onClick={() => this.enterEditMode()}><i className="fa fa-cog"></i></button>
                            </React.Fragment>
                              
                    }
                    
                </div>
            </div>
        )
    }

    drillDownSlicersChanged = (newSlicers) =>{
        this.setState({drilldownSlicers: newSlicers})

        let bucketId = this.getBucketId()
        let itemID = this.getItemId()

        this.props.dispatch(getItemDrilldown(bucketId, itemID, this.state.drilldownAggregationColumns ? this.state.drilldownAggregationColumns : [], newSlicers ? newSlicers : []))
    }

    getLink = () => {
        let result = window.location.href
        let querySeparator = "?"
        if (result && result.includes("?")) {
            querySeparator = "&"
        }
        return result + querySeparator +"dialog=masterData&bucketId=" +  this.getBucketId() + "&itemId=" + this.getItemId()
    }

    copyLinkToClipboard = () => {
        this.linkDialogRef.show(this.getLink())
    }

    render() {
        let bucketId = this.getBucketId()
        let itemID = this.getItemId()
        let bucket = this.props.buckets.get(bucketId, null)
        if (!bucket || window.$.isEmptyObject(this.props.data)) return null

        let data = this.props.data.get(bucket.id, itemID)
        if (!this.props.item_exists) return (
            <div className="padding-top-20px margin-left-20px margin-right-20px">
                <p className="alert alert-warning width-400px">
                    <b>This item is no longer present in your data.</b>
                    <button onClick={() => this.props.hideMasterData()} className="btn btn-sm btn-default margin-left-15px">Close</button>
                </p>
            </div>
        )
        if (!data) return null

        const profit = this.props.profits.get(bucketId, null)
        const stock_balance = this.props.stock_balance.get(bucketId, null)
        const drilldown = this.props.drilldown.get(bucket.id, itemID, this.state.drilldownAggregationColumns)

        let ABCCode = getABCCode(bucket, data.data)
        const textColumns = this.getShownColumns(getTextColumnsObjWithCat(bucket.model), bucket.setup.hidden_columns)
        const numberColumns =   this.getShownColumns(getNumberColumnsObj(bucket.model), bucket.setup.hidden_columns)
        const IDDescriptionColumn = bucket?.setup?.id_description_column
        const IDDescriptionColumnIndex = IDDescriptionColumn ? bucket?.model?.columns.findIndex(c => c.name === IDDescriptionColumn) : -1
        const IDDescription = IDDescriptionColumnIndex !== null && IDDescriptionColumnIndex !== -1 ? data.data[IDDescriptionColumnIndex] : null
        const itemComments = this.props.comments.get(bucketId, itemID, [])

        const graphData = this.props.historyData.get2(bucketId, itemID, null)
        return (
            <div>
                <this.KeyboardPageNavigation />
                <ExitPromptDialog id="unsaved-master-data" shown={this.state.showExitPrompt} disableBackdrop="true" title="Unsaved changes" cancel={this.cancelExitPrompt} save={this.saveSetupHandler} accept={this.exitWithoutSavingHandler} acceptText={"Exit without saving"}>
                    <p>You have unsaved changes, are you sure you want to exit?</p>
                </ExitPromptDialog>
                <LinkDialog title="Share this" description="Copy a link to this dialog" ref={this.setLinkDialogRef} />
                <div className="container-fluid no-padding">
                    <div className="col-md-12 no-padding">
                        <div className="single-item-top-row col-12">
                            <header className="single-item-header">
                                <div style={getABCCodeStyle(ABCCode, bucket.model.categorization)} className="single-item-abc-code">{ABCCode}</div>
                                    <h1 className="single-item-id">{itemID}</h1>
                                    {this.state.editMode ? 
                                    <div className="single-item-id-description-wrapper">
                                    <select className="form-control form-control-sm" defaultValue={IDDescriptionColumn} onChange={e => this.idDescriptionHandler(e.target.value)}>
                                        <option disabled className="bold">ID Description:</option>
                                        <option  className="italic">No ID Description</option>
                                        {bucket.model.columns.filter(col => getColumnType(col.name, bucket.model) === "text" && !bucket.setup.hidden_columns.includes(col.name)).map(c => 
                                            <option key={c.name} value={c.name}>{c.name}</option>
                                        )}
                                    </select>
                                    </div>
                                    :
                                    <h2 className="single-item-id-description-wrapper single-item-id-description-wrapper-truncate">
                                        <span title={IDDescription}>
                                        {IDDescription}
                                        </span>
                                    </h2>
                                    }
                                <section className="single-item-action-button-group">
                                    <span className="single-item-close-button fa fa-times close-button" onClick={_ => this.state.editMode ? this.promptExitEditMode("closeButton") : this.handleClose()} />
                                    <div className="single-item-nav-buttons">
                                    {
                                        !this.props.noGrid &&
                                       <button disabled={this.props.nextButtonState === -1} onClick={_ => {if(this.props.gridRef) this.props.gridRef.next(false)}} className="single-item-nav-button btn btn-default"><i className="fa fa-caret-up" aria-hidden="true"></i></button>
                                    }
                                    {
                                        !this.props.noGrid && 
                                        <button disabled={this.props.nextButtonState === 1} onClick={_ => {if(this.props.gridRef) this.props.gridRef.next(true)}} className=" single-item-nav-button btn btn-default "><i className="fa fa-caret-down" aria-hidden="true"></i>
                                        </button>
                                    }
                                    </div>
                                    
                                    <div className="single-item-dropdown-buttons" >
                                        

                                        <div ref={this.setReferencesRef}>
                                            <span className="font-size-20px margin-bottom-10px gray-icon" title="Item also appears in..." onClick={() => {this.setState({ referencesOpen: !this.state.referencesOpen }); if(!this.state.referencesOpen){this.refs.references.loadReferences()}}}><i className="fa fa-file-o abc-click"></i></span>
                                            <div className={`dropdown-menu references-dropdown-menu dropdown-menu-right ${this.state.referencesOpen ? "show" : ""}`} role="menu">
                                                <SingleItemReferences reportID={this.props.reportID} ref="references" />
                                            </div>
                                        </div>
                                        <div ref={this.setCommentsRef}>
                                            <span className="font-size-20px margin-bottom-10px gray-icon" title="Comments" onClick={() => this.setState({ commentsOpen: !this.state.commentsOpen })}><i className="fa fa-comment abc-click"></i></span>
                                            <ShowIf if={itemComments.length > 0}>
                                                <span className="small-comment-number">{itemComments.length}</span>
                                            </ShowIf>
                                            <div className={`dropdown-menu comments-dropdown-menu dropdown-menu-right ${this.state.commentsOpen ? "show" : ""}`} role="menu">
                                                <SingleItemComments ref="comments" context={encodeURIComponent(itemID)} context_type="-1" getContextPath={() => this.props.match.url} />
                                            </div>
                                        </div>
                                    </div>
                                    <div><button title="Share this view" type="button" className="btn btn-default btn-sm mr-2" onClick={() => this.copyLinkToClipboard()}><i className="fa fa-link" /></button></div>
                                    <RequirePermission perms={is.reportAdmin}>
                                        <div className="single-item-edit-mode-btn">
                                            {this.getEditButton()}
                                        </div>
                                    </RequirePermission>
                                </section>
                            </header>
                        </div>
                        <hr className="hr-color margin-bottom-0 margin-top-10px width-100-p" />

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-10 col-lg-11">
                                    <SingleItemMonthLabels item={itemID} bucket={bucket} column={this.state.textColumn} data={data} historyData={this.props.historyData} />
                                    <SingleItemCategoryHistory item={itemID} bucket={bucket} data={data} />
                                    <SingleItemTextColumn item={itemID} bucket={bucket} data={data} column={this.state.textColumn} historyData={this.props.historyData} />
                                </div>
                                <div className="col-2 col-lg-1 padding-left-0px padding-right-0px">
                                    <div className="container-fluid margin-top-39px" >
                                        <div className="row">
                                            <div className="col no-padding" title={bucket?.model?.categorization_name ?? "Categorization"}>
                                                <WordWrap>
                                                    {bucket?.model?.categorization_name ?? "Categorization"}
                                                </WordWrap>
                                            </div>
                                            <div className="col-md-auto padding-left-0px">
                                                {
                                                    this.state.textColumn && this.state.textColumn.name === "" ?
                                                        <i className="fa fa-plus abc-click float-right margin-top-3px" title="Show another ribbon" onClick={() => this.setState({ textColumn: { name: "Select column", type: "text" } })} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 padding-left-0px margin-top-neg-2px">
                                        {
                                            this.state.textColumn && this.state.textColumn.name !== "" ?
                                                <div className="form-inline margin-top-11px">
                                                    <select
                                                        className="form-control width-100-p form-control-sm"
                                                        value={this.state.textColumn ? this.state.textColumn.name : ""}
                                                        onChange={e => this.setColumn({ type: "text", name: e.target.value })}
                                                    >
                                                        <option value={this.state.textColumn} hidden>Select column</option>
                                                        <option
                                                            style={{ fontStyle: "italic" }}
                                                            disabled={this.state.textColumn === ""}
                                                            value={""}>{this.state.textColumn && this.state.textColumn.name !== "" ? 'Hide additional' : "Show additional"}
                                                        </option>
                                                        {
                                                            textColumns.map((v, i) => <option key={i} value={v.name}>{v.name}</option>)
                                                        }
                                                    </select>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="compose-navigation margin-top-10px">
                                <div className={`compose-tab ${this.state.tab === 0 ? "active-compose-tab" : ""}`} onClick={() => this.promptChangeTab(0)}><span>Trend</span></div>
                                <ShowIf if={profit && profit.enabled}>
                                    <div className={`compose-tab ${this.state.tab === 1 ? "active-compose-tab" : ""}`} onClick={() => this.promptChangeTab(1)}><span>Profit</span></div>
                                </ShowIf>
                                <ShowIf if={drilldown && drilldown.has_drilldown}>
                                    <div className={`compose-tab ${this.state.tab === 2 ? "active-compose-tab" : ""}`} onClick={() =>this.promptChangeTab(2)}><span>Transactions</span></div>
                                </ShowIf>
                                <ShowIf if={stock_balance && stock_balance.enabled}>
                                    <div className={`compose-tab ${this.state.tab === 3 ? "active-compose-tab" : ""}`} onClick={() => this.promptChangeTab(3)}><span>Stock balance</span></div>
                                </ShowIf>
                                <div className={`compose-tab ${this.state.tab === 4 ? "active-compose-tab" : ""}`} onClick={() => this.promptChangeTab(4)}><span>Info</span></div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {
                                this.state.tab === 0 ?
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-9">
                                                <SingleItemGraph
                                                    item={itemID}
                                                    bucket={bucket}
                                                    data={data}
                                                    column={this.state.decimalColumn}
                                                    historyData={graphData}
                                                    startAtZero={this.state.startAtZero}
                                                    setZoom={(zoom) => {this.setState({ zoom: zoom });
                                                        if (zoom > 12) {
                                                            this.setState({ graphType: "bar" })
                                                        }
                                                     }}
                                                    zoom={this.state.zoom}
                                                    type={this.state.graphType}
                                                    editMode={this.state.editMode}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <SingleItemColumnBoxes
                                                    startAtZero={this.state.startAtZero}
                                                    type={this.state.graphType}
                                                    setStartAtZero={() => this.setState({ startAtZero: !this.state.startAtZero })}
                                                    setType={(type) => this.setState({ graphType: type })}
                                                    numberColumns={numberColumns}
                                                    decimalColumn={this.state.decimalColumn}
                                                    setColumn={(c) => this.setColumn(c)}
                                                    loadColumn={(c) => this.loadColumn(c)}
                                                    historyData={this.props.historyData}
                                                    zoom={this.state.zoom}
                                                    data={data}
                                                    bucket={bucket}
                                                    item={itemID}
                                                    setup={this.state.setup}
                                                    editMode={this.state.editMode}
                                                    columnBoxesChangedHandler={this.columnBoxesChangedHandler}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <hr className="hr-color margin-top-0" />
                                        </div>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <SingleItemInfoBox
                                                    item={itemID}
                                                    bucket={bucket}
                                                    data={data}
                                                    editMode={this.state.editMode}
                                                    additionalColumnsHandler={this.additionalColumnsHandler}
                                                />
                                            </div>
                                        </div>

                                    </React.Fragment>

                                    : null
                            }
                            {
                                this.state.tab === 1 ?
                                    <React.Fragment>
                                        <ShowIf if={this.state.profitColumn && this.state.profitColumn.type !== "none"}>
                                            <div className="row">
                                                <div className="col-9">
                                                    <SingleItemGraph
                                                        bucket={bucket}
                                                        column={this.state.profitColumn}
                                                        historyData={graphData}
                                                        startAtZero={this.state.startAtZeroProfit}
                                                        setStartAtZero={() => this.setState({ startAtZeroProfit: !this.state.startAtZeroProfit })}
                                                        type={this.state.profitColumn ? this.state.profitColumn.type : "none"}
                                                        setType={(type) => this.setState({ profitGraphType: type })}
                                                        totalSection={this.state.totalSection}
                                                        profit={profit}
                                                        isProfit={true}
                                                        hasTotalData={this.state.hasTotalData}
                                                        selectedColumnIndex={this.state.selectedColumnIndex}
                                                        setColumnIndex={(index) => this.setState({ selectedColumnIndex: index })}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="margin-top-50px">
                                                        <button onClick={() => this.setState({ startAtZeroProfit: !this.state.startAtZeroProfit })} className={`btn btn-default btn-sm ${this.state.startAtZeroProfit ? 'active' : ""}`}>Show 0</button>
                                                        <button disabled={this.state.selectedColumnIndex === 0} onClick={() => this.changeSelectedIndex(true)} className={`btn btn-default btn-sm margin-left-10px`}><i className="fa fa-arrow-left"></i></button>
                                                        <button disabled={this.state.selectedColumnIndex === 11} onClick={() => this.changeSelectedIndex(false)} className={`btn btn-default btn-sm margin-left-5px`}><i className="fa fa-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <hr className="hr-color margin-top-0" />
                                            </div>
                                        </ShowIf>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <SingleItemProfitData
                                                    item={itemID}
                                                    bucket={bucket}
                                                    data={data}
                                                    historyData={this.props.historyData}
                                                    profit={profit}
                                                    profitColumn={this.state.profitColumn}
                                                    setColumn={(c) => this.setProfitColumn(c)}
                                                    setTotalIndex={(indexObj, name) => this.setState({ totalSection: indexObj, profitColumn: name })}
                                                    setHasData={() => this.setState({ hasTotalData: true })}
                                                    hasTotalData={this.state.hasTotalData}
                                                    selectedColumnIndex={this.state.selectedColumnIndex}
                                                    viewPercentage={this.state.profitViewPercentage}
                                                    setViewPercentage={_ => this.setState({ profitViewPercentage: !this.state.profitViewPercentage })}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    : null
                            }

                            {
                                this.state.tab === 2 ?
                                    <div className="col-md-12">
                                        <SingleItemDrilldown
                                            ref="drilldown"
                                            bucket={bucket}
                                            bucketId={bucketId}
                                            itemId={this.props.itemId}
                                            buckets={this.props.buckets}
                                            dispatch={this.props.dispatch}
                                            data={drilldown}
                                            aggregationColumns={this.state.drilldownAggregationColumns}
                                            onChangeColumns={c => this.setState({ drilldownAggregationColumns: c })} 
                                            visibleColumns={this.state.drilldownShownColumns}
                                            onChangeVisibleColumns={c => this.drilldownShownColumnsChanged(bucketId, c)}
                                            showDecimals={this.state.drilldownShowDecimals}
                                            onDecimalsChange={c => this.setState({drilldownShowDecimals: c})}
                                            columnDescriptions={this.props.columnDescriptions}
                                            drilldownSlicers={this.state.drilldownSlicers}
                                            onDrilldownSlicersChange={ c => this.drillDownSlicersChanged(c)}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                this.state.tab === 3 ?
                                    <div className="row">
                                        <div className="col-md-9">
                                            <SingleItemStockBalanceData
                                                bucket={bucket}
                                                itemId={this.props.itemId}
                                                setup={stock_balance}
                                                settingsParent={this}
                                                showPositives={this.state.showSPositivestockBalance}
                                                sum={this.state.showSumStockBalance}
                                                setShowPositives={b => this.setState({ showSPositivestockBalance: b })}
                                                setSum={b => this.setState({ showSumStockBalance: b })}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.tab === 4 ?
                                    <div className="row">
                                        <div className={!this.state.editMode ? "col-md-9" : ""}>
                                            <SingleItemData
                                                item={itemID}
                                                bucket={bucket}
                                                data={data}
                                                textColumn={this.state.textColumn}
                                                decimalColumn={this.state.decimalColumn}
                                                setColumn={(c) => this.setColumn(c)}
                                                showDecimals={this.state.showDecimalsAllData}
                                                showMore={this.state.showMoreAllData}
                                                setShowDecimals={b => this.setState({ showDecimalsAllData: b })}
                                                setShowMore={b => this.setState({ showMoreAllData: b })}
                                                settingsParent={this}
                                                masterColumnsUpdatedHandler={this.masterColumnsUpdatedHandler}
                                                editMode = {this.state.editMode}
                                                setup={this.state.setup ? this.state.setup : (bucket ? bucket.setup : null )}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const shouldComponentUpdateSettings = (prevState, curState) => {
    return (
        JSON.stringify(curState.textColumn) !== JSON.stringify(prevState.textColumn) ||
        JSON.stringify(curState.decimalColumn) !== JSON.stringify(prevState.decimalColumn) ||
        JSON.stringify(curState.startAtZero) !== JSON.stringify(prevState.startAtZero) ||
        JSON.stringify(curState.tab) !== JSON.stringify(prevState.tab) ||
        JSON.stringify(curState.startAtZeroProfit) !== JSON.stringify(prevState.startAtZeroProfit) ||
        JSON.stringify(curState.graphType) !== JSON.stringify(prevState.graphType) ||
        JSON.stringify(curState.profitGraphType) !== JSON.stringify(prevState.profitGraphType) ||
        JSON.stringify(curState.profitColumn) !== JSON.stringify(prevState.profitColumn) ||
        JSON.stringify(curState.totalSection) !== JSON.stringify(prevState.totalSection) ||
        JSON.stringify(curState.selectedColumnIndex) !== JSON.stringify(prevState.selectedColumnIndex) ||
        JSON.stringify(curState.profitViewPercentage) !== JSON.stringify(prevState.profitViewPercentage) ||
        JSON.stringify(curState.drilldownAggregationColumns) !== JSON.stringify(prevState.drilldownAggregationColumns) ||
        JSON.stringify(curState.drilldownShowDecimals) !== JSON.stringify(prevState.drilldownShowDecimals) ||
        JSON.stringify(curState.drilldownShownColumns) !== JSON.stringify(prevState.drilldownShownColumns) ||
        JSON.stringify(curState.drilldownSlicers) !== JSON.stringify(prevState.drilldownSlicers)
    )
}

const getSettingsKeys = (state) => {
    return ["textColumn", "decimalColumn", "startAtZero", "tab", "selectedColumnIndex", "startAtZeroProfit", "graphType", "profitGraphType", "profitColumn", "totalSection", "profitViewPercentage", "drilldownAggregationColumns", "drilldownShowDecimals", "drilldownShownColumns", "drilldownSlicers"]
}

const getSessionStoreKey = _this => {
    return "single-item-" + _this.getBucketId()
}

SingleItem = withSettingsStorage(SingleItem, getSettingsKeys, shouldComponentUpdateSettings, null, null, getSessionStoreKey, null, null, "single-item", { withSessionStore: true })

SingleItem = connect(mapStateToProps)(SingleItem)

export default SingleItem
