import React from "react";

import '../../css/DividerText.css'

const DividerText = (props: {label: string, style?: React.CSSProperties, className?: string}) => {

    return (
        <div style={props.style} className={`divider-separator ${props.className ?? ''}`}>
            {props.label}
        </div>
    )
}

export default DividerText