import React from 'react'
import BigWidget from './BigWidget'
import SmallWidget from './SmallWidget'
import { Link } from 'react-router-dom'

import '../../css/dashboard.css'
import ShowIf from '../Generic/ShowIf'

class DashboardWidgets extends React.Component {


    render() {
        const widgetData = this.props.widgetData ? this.props.widgetData : []

        return (
            <div>
                <div className="text-align-center" >
                    {
                        widgetData.map((item, i) => {
                            return (
                                <div key={i} className={`margin-bottom-8px`}>
                                    {
                                        item.widgets.map((w, j) => {
                                            if (w.shown) {
                                                return (
                                                    <div key={j} className={`inline-block ${item.type === "large" ? 'big-widget' : 'padding-left-right-4px small-widget'}`}>
                                                        <ShowIf if={w.is_report}>
                                                            <Link to={`/report/${w.row_id != '' ? w.row_id : w.report_id}`} className="cancel-a-style">
                                                                {
                                                                    item.type === "large" ?
                                                                        <BigWidget
                                                                            fromHome={this.props.fromHome}
                                                                            fromSecret={this.props.fromSecret}
                                                                            setHoverValues={this.props.setHoverValues}
                                                                            hoverReportId={this.props.hoverReportId}
                                                                            hoverKpiKey={this.props.hoverKpiKey}
                                                                            data={w}
                                                                            indexI={i}
                                                                            indexJ={j}
                                                                        />
                                                                        : <SmallWidget
                                                                            fromHome={this.props.fromHome}
                                                                            fromSecret={this.props.fromSecret}
                                                                            setHoverValues={this.props.setHoverValues}
                                                                            hoverReportId={this.props.hoverReportId}
                                                                            hoverKpiKey={this.props.hoverKpiKey}
                                                                            data={w}
                                                                            indexI={i}
                                                                            indexJ={j}
                                                                        />

                                                                }
                                                            </Link>
                                                        </ShowIf>
                                                        <ShowIf if={!w.is_report}>
                                                            <Link to={`/report/${w.mainrow_id}/overview?dId=${w.dashboard_id}&rowId=${w.row_id}&kpi_key=${w.kpi_key}&table=0${this.props.fromHome ? "&home=1": ""}`} className="cancel-a-style">
                                                            {
                                                                item.type === "large" ?
                                                                    <BigWidget
                                                                        fromHome={this.props.fromHome}
                                                                        fromSecret={this.props.fromSecret}
                                                                        setHoverValues={this.props.setHoverValues}
                                                                        hoverReportId={this.props.hoverReportId}
                                                                        hoverKpiKey={this.props.hoverKpiKey}
                                                                        data={w}
                                                                        indexI={i}
                                                                        indexJ={j}
                                                                    />
                                                                    : <SmallWidget
                                                                        fromHome={this.props.fromHome}
                                                                        fromSecret={this.props.fromSecret}
                                                                        setHoverValues={this.props.setHoverValues}
                                                                        hoverReportId={this.props.hoverReportId}
                                                                        hoverKpiKey={this.props.hoverKpiKey}
                                                                        data={w}
                                                                        indexI={i}
                                                                        indexJ={j}
                                                                    />

                                                            }
                                                            </Link>
                                                        </ShowIf>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default DashboardWidgets
