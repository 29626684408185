import React from 'react'

import BaseDialog from './BaseDialog'

class WarningDescriptionDialog extends BaseDialog {

	closeDialog = () => {
		if(this.props.closeHandler)
			this.props.closeHandler();
		this.hide(); //base class
	}

	getButtons() {
		let okText = this.props.okText ? this.props.okText : 'OK'
		return (
			<div>
				<button onClick={this.closeDialog} type="button" className="btn btn-danger">{okText}</button>
			</div>
		)
	}
}

export default WarningDescriptionDialog