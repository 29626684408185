import React, { useRef, useEffect } from 'react'

import '../../css/ResizingTextarea.css'

interface ResizingTextareaProps {
    value: any,
    onChange: ((event: React.ChangeEvent<HTMLTextAreaElement>) => void) | undefined,
    id: string | undefined,
    className: string | undefined,
    placeholder: string | undefined,
}

const ResizingTextarea = ({className = "", value, ...props}: ResizingTextareaProps) => {
    
    const textAreaRef = useRef<HTMLTextAreaElement>(null)


    const resizeArea = () => {
        if (textAreaRef.current == null) return
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }

    useEffect(resizeArea, [value])

    return <textarea ref={textAreaRef} placeholder={props.placeholder} className={`textarea-resize-area ${className}`} id={props.id} value={value} onChange={props.onChange} rows={1}  />
}

export default ResizingTextarea
