import { ReactNode, useEffect, useRef, useState } from "react";
import { MdClose, MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import React from "react";
import "../../css/multiselect.css"

interface MultiSelectProps{
    onOptionSelect : (option:SelectOption) => void;
    onOptionDeselect : (option:SelectOption) => void;
    options : SelectOption[];
    selectedOptions : SelectOption[];
    placeholder: string;
}

interface SelectOption {
    value: any;
    label: string;
    icon?: ReactNode;
}


export const useOutsideClick = (callback: () => any) => {
    const ref = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
        const handleClick = (event:any) => {
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            }
          };
  
      document.addEventListener('click', handleClick, true);
  
      return () => {
        document.removeEventListener('click', handleClick,true);
      };
    }, [ref]);
  
    return ref;
  };


function MultiSelect(props:MultiSelectProps){
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(props.options.sort((a,b) => a.label.localeCompare(b.label)))
    const [searchText, setSearchText] = useState<string>("");
    const ref = useOutsideClick(() => setShowOptions(false))

    useEffect(() => {
        setFilteredOptions(props.options.filter(o => o.label.toLowerCase().includes(searchText.toLowerCase())).sort((a,b) => a.label.localeCompare(b.label)))
    }, [searchText])

    const isSelected = (o:SelectOption) => {
        return props.selectedOptions.find(s => s.value === o.value) ?? false
    }

    return (
        <div className="position-relative" ref={ref}>
        <div className={`${showOptions ? "border-top border-left border-right rounded-top" : "border rounded cursor-pointer"} d-flex justify-content-between align-items-center text-sm w-100 mt-1 border-secondary shadow-sm h-9`}>
            <input 
                type="text" 
                name="search" 
                placeholder={props.placeholder} 
                className="p-2 m-0 w-100 border-0 rounded focus:outline-none flex justify-content-between h-100" 
                onChange={e => setSearchText(e.target.value)} 
                onFocus={() => setShowOptions(true)}
                value={searchText}
            />
            <div 
                className={`${showOptions ? "rounded-top-right" : "rounded-right"} hover:bg-light p-2 cursor-pointer h-100`}
                onClick={() => setShowOptions(!showOptions)}
            >
                {!showOptions && <MdOutlineExpandMore />}
                {showOptions && <MdOutlineExpandLess />} 
            </div>
        </div>
        {showOptions && (
            <div 
                className="border-left border-right border-bottom border-secondary overflow-auto position-absolute bg-white w-100 max-h-96 rounded-bottom"
                style={{zIndex: 999, userSelect: "none"}}
            >
                {filteredOptions.length === 0 && <div className="font-italic text-secondary text-center p-2">No items found</div>}
                {filteredOptions.map(o => (
                    <div 
                        key={o.value}
                        onClick={() => { isSelected(o) ? props.onOptionDeselect(o) : props.onOptionSelect(o)}}
                        className={`${isSelected(o) ? "bg-secondary text-white" : "bg-white multiselect-option"} p-2 border-top border-light d-flex align-items-center justify-content-between cursor-pointer`}
                    >
                        <span className="d-flex align-items-center gap-1">
                            <span>{o.label}</span>
                            {o.icon}
                        </span>
                        {isSelected(o) && <MdClose className="text-white" />}
                    </div>
                ))}
            </div>
        )}
    </div>
    );
}

export default MultiSelect;