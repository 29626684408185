export const parsePastedDataPreview = (pasteData:string, maxRows:number, maxColumns:number) => {
    let tmp = pasteData
    let rows = tmp.split('\n')
    let rawData:string[][] = []
    let idColumns:({name:string,id:number})[] = []
    let tableData:string[][] = []
    let headers:string[] = []
    let rowCount = rows.length-1
    let columnCount = 0

    //Calculating preview cells
    for (let i = 0; i < rows.length; i++) {
        let rowData = rows[i].split('\t')
        if(rowData.length < 0 || (rowData.length === 1 && rowData[0] === "")) { //Ignores empty rows
            rowCount--
            continue
        }

        if(rowData.length > columnCount) columnCount = rowData.length

        rawData.push(rowData)

        if (rowData.length > maxColumns) {
            rowData = rowData.slice(0, maxColumns)
        }

        if (i === 0) {
            headers = rowData
        } else {
            if (tableData.length < maxRows) {
                tableData.push(rowData)
            }
        }
    }

    //Calculating possible id columns
    for (let i = 0; rawData[0] && i < rawData[0].length; i++) {
        let values = []
        for (let j = 0; j < rawData.length; j++) {
            let found = false
            for (let k = 0; k < values.length; k++) {
                if (values[k] === rawData[j][i]) {
                    found = true
                }
            }
            if (!found) {
                values.push(rawData[j][i])
            }
        }

        if (values.length === rawData.length) {
            idColumns.push({name: rawData[0][i], id: i})
        }
    }

    if(rowCount < 0) rowCount = 0

    return {tableHeaders: headers, data: rawData, tableData: tableData, idColumns: idColumns, rowCount: rowCount, columnCount: columnCount}
}
