import React from 'react';
import { connect } from 'react-redux';
import { withSettingsPropagation } from '../../../helpers/SettingsService';
import ProfitDataContent from '../../SingleItem/ProfitDataContent';
import SingleItemGraph from '../../SingleItem/SingleItemGraph';
import ShowIf from '../../Generic/ShowIf';

const mapStateToProps = (state, ownProps) => {
    return {
        historydataProfit: state.Report.historydataProfit
    }
}

class ProfitReportView extends React.Component {
    state = {
        showDecimals: false,
        profitColumn: null,
        selectedColumnIndex: 11,
        totalSection: { x: -1, y: -1 },
        startAtZeroProfit: true,
        profitGraphType: "bar",
        viewPercentage: false,
    }

    componentDidMount() {
        if(this.props.forPrint){
            this.setState({selectedColumnIndex: this.props.profitViewSettings.selectedColumnIndex})
        }
     }

    componentDidUpdate(prevProps, prevState) {
        const { reportdata, profit } = this.props

        if ((reportdata.info !== null && this.state.profitColumn === null && profit)) {
            const sections = JSON.parse(profit.sections)
            const headings = JSON.parse(profit.headings)
            const totalRow = JSON.parse(profit.total_row)
            let column = { name: "N/A", type: "none" }
            let totalSection = { x: -1, y: -1 }

           if (sections.length > 0) {
                for (let i = 0; i < headings.length; i++) {
                    const h = headings[i];
                    if (h.type !== "none") {
                        if (totalRow.use_columns) {
                            column = { name: totalRow.columns[i], type: h.type, useTotal: true, label: totalRow.label}
                        } else {
                            column = { name: `Grand total-${i + 1}`, label: totalRow.label ?? "Grand total", type: h.type, useTotal: true, isLast: true }
                            totalSection = { x: i, y: sections.length }
                        }
                        break
                    }
                }
            }
            this.setState({ profitColumn: column, totalSection: totalSection })
        }
    }

    changeSelectedIndex(dir) {
        const newIndex = dir ? this.state.selectedColumnIndex - 1 : this.state.selectedColumnIndex + 1
        if (newIndex > -1 && newIndex < 12) this.setState({ selectedColumnIndex: newIndex });
    }

    render() {
        const { show, reportdata, profit, data, useLoading, loading } = this.props
        if (!show) return null;
        if (data === null) return null;

        return (
            <div className="mt-2">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-9">
                            <SingleItemGraph
                                bucket={reportdata.info}
                                column={this.state.profitColumn}
                                historyData={data}
                                startAtZero={this.state.startAtZeroProfit}
                                setStartAtZero={() => this.setState({ startAtZeroProfit: !this.state.startAtZeroProfit })}
                                type={this.state.profitColumn ? this.state.profitColumn.type : "none"}
                                setType={(type) => this.setState({ profitGraphType: type })}
                                totalSection={this.state.totalSection}
                                profit={profit}
                                isProfit={true}
                                selectedColumnIndex={this.state.selectedColumnIndex}
                                setColumnIndex={(index) => this.setState({ selectedColumnIndex: index })}
                                fromReport={true}
                                useLoading={useLoading}
                                loading={loading}
                            />
                        </div>
                        <ShowIf if={!this.props.forPrint}>
                            <div className="col-3">
                                <div className="margin-top-50px">
                                    <button onClick={() => this.setState({ startAtZeroProfit: !this.state.startAtZeroProfit })} className={`btn btn-default btn-sm ${this.state.startAtZeroProfit ? 'active' : ""}`}>Show 0</button>
                                    <button disabled={this.state.selectedColumnIndex === 0} onClick={() => this.changeSelectedIndex(true)} className={`btn btn-default btn-sm margin-left-10px`}><i className="fa fa-arrow-left"></i></button>
                                    <button disabled={this.state.selectedColumnIndex === 11} onClick={() => this.changeSelectedIndex(false)} className={`btn btn-default btn-sm margin-left-5px`}><i className="fa fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </ShowIf>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <ProfitDataContent
                                profit={profit}
                                showDecimals={this.state.showDecimals}
                                bucket={reportdata.info}
                                historyData={data} //
                                selectedColumnIndex={this.state.selectedColumnIndex}
                                profitColumn={this.state.profitColumn}
                                setColumn={c => this.setState({ profitColumn: c, totalSection: { x: -1, y: -1 } })}
                                setTotalIndex={(indexObj, name) => this.setState({ totalSection: indexObj, profitColumn: name })}
                                useSummary={false}
                                fromReport={true}
                                viewPercentage={this.state.viewPercentage}
                                setPercentage={_ => this.setState({ viewPercentage: !this.state.viewPercentage })}
                                useLoading={useLoading}
                                loading={loading}
                            />
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (
        prevState.showDecimals !== curState.showDecimals ||
        prevState.viewPercentage !== curState.viewPercentage ||
        JSON.stringify(curState.profitColumn) !== JSON.stringify(prevState.profitColumn) ||
        JSON.stringify(curState.profitGraphType) !== JSON.stringify(prevState.profitGraphType) ||
        JSON.stringify(curState.totalSection) !== JSON.stringify(prevState.totalSection) ||
        curState.selectedColumnIndex !== prevState.selectedColumnIndex
    )
}

const getSettingsKeys = (state) => {
    let { ...settings } = state
    return Object.keys(settings)
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => { }

ProfitReportView = withSettingsPropagation(ProfitReportView, getSettingsKeys, shouldComponentUpdateSettings, null, settingsDidApply, "profit")

ProfitReportView = connect(mapStateToProps)(ProfitReportView)

export default ProfitReportView