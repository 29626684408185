import React from 'react'
import Suggester from './Suggester'
class TextareaAutocompleter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : "", //The current input value
            token: this.props.token ? this.props.token : " ", //Token to look for when autocompleting. The token wil be the start of a new "word" that is being tried to autocomplete
            suggestions: this.props.suggestions ? this.props.suggestions : [] //Suggestions to try autocomplete
        }
        this.textareaRef = React.createRef()
    }

    getValue() {
        return this.state.value
    }

    caretIndex = -1
    tokenIndex = -1

    //Expects a list of {title: "", description: "", data: ""}
    //The input is matched on the title, the description is text for rendering only and data is what is returned when the item is clicked on
    setSuggestions(list) {
        this.refs.suggester.setSuggestions(list)
    }

    componentDidMount() {
        this.setState({value: this.props.defaultValue})
    }

    componentDidUpdate(prevProps, prevState) {
        if(JSON.stringify(prevProps.suggestions) !== JSON.stringify(this.props.suggestions)) {
            this.setState({suggestions: this.props.suggestions})
        }
    }

    update(event) {
        this.setState({value: event.target.value})
        let suggestions = this.state.suggestions
        this.caretIndex = event.target.selectionStart
        this.tokenIndex = event.target.value.substring(0,this.caretIndex).lastIndexOf(this.state.token) //Last index of token before the caret
        if(this.tokenIndex === -1 || this.tokenIndex > this.caretIndex) {
            this.refs.suggester.clear()
            return //If token is before the caret
        }
        
        let substring = event.target.value.substring(this.tokenIndex+1,this.caretIndex)//Text from last token to caret position
        let matches = [] //Array with suggestions that includes the searchstring
        for(let i = 0; i < suggestions.length; i++) {
            if(
                ((this.state.token+suggestions[i].title).includes(this.state.token+substring)) || 
                (this.props.caseInsensitive && ((this.state.token+suggestions[i].title.toLowerCase()).includes(this.state.token+substring.toLowerCase())))
            ) matches.push(suggestions[i])
        }
        this.refs.suggester.setSuggestions(matches)
        if(this.props.onToken && event.type === "change" && this.tokenIndex+1 === this.caretIndex) this.props.onToken()
    }

    keyDown(event) {
        if(event.keyCode === 27) { //27 is escape key
            this.refs.suggester.clear()
        } 
        if((event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13 || event.keyCode === 9) && this.refs.suggester.getSuggestions().length > 0) { //38 is arrow up, 40 is arrow down, 13 is enter, 9 is tab
            event.preventDefault()
            if(event.keyCode === 13 || event.keyCode === 9) {
                let suggestion = this.refs.suggester.getSuggestions()[this.refs.suggester.getSelected()]
                this.onSuggestionClick(suggestion.title, suggestion.data)
                this.refs.suggester.clear()
            }
            if(event.keyCode === 38) this.refs.suggester.setSelected(this.refs.suggester.getSelected()-1)
            if(event.keyCode === 40) this.refs.suggester.setSelected(this.refs.suggester.getSelected()+1)
        }
    }

    onSuggestionClick(title, data) {
        let value = this.state.value
        let prefix = value.slice(0,this.tokenIndex+1)+title+" " //First part of the comment
        this.setState({value: prefix+value.slice(this.caretIndex)}, () => {
            this.textareaRef.current?.focus()
            // this.refs.textarea.selectionStart = this.refs.textarea.selectionEnd = prefix.length // ? What? 
        })
    }

    isInFocus() {
        return this.textareaRef.current === document.activeElement
    }

    render() {
        return (
            <div>
                <textarea autoFocus ref={this.textareaRef} className={this.props.textareaClassName} value={this.state.value} id={this.props.id} onKeyDown={e => this.keyDown(e)} onChange={e => this.update(e)} onClick={e => this.update(e)}/>
                <Suggester ref="suggester" className={this.props.suggesterClassName} onClick={(title, data) => this.onSuggestionClick(title, data)}/>
            </div>
        )
    }
}

export default TextareaAutocompleter