import * as ActionTypes from '../actions/ActionTypes'
import { DoubleKeyMap, TripleKeyMap } from '../helpers/Collections'

const defaultState = {
    data: new DoubleKeyMap(),
    comments: new DoubleKeyMap(),
    history: new DoubleKeyMap(),
    links: new DoubleKeyMap(),
    graphHistory: new TripleKeyMap(),
    drilldown: new TripleKeyMap(),
    itemExists: true,
}

const Item = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ITEM_COMMENTS_DONE:
            var itemComments = new DoubleKeyMap(state.comments)
            itemComments.set(action.payload.bucket, action.payload.itemID, action.payload.comments)
            return Object.assign({}, state, { comments: itemComments })
        case ActionTypes.GET_ITEM_DATA_DONE:
            let data = new DoubleKeyMap(state.data)
            data.set(action.payload.bucket, action.payload.itemID, action.payload.data)
            return Object.assign({}, state, { data: data })
        case ActionTypes.GET_ITEM_DRILLDOWN_DONE:
            let drilldown = new TripleKeyMap(state.drilldown)
            drilldown.set(action.payload.bucket, action.payload.itemID, action.payload.aggregated_columns, action.payload.data)
            return Object.assign({}, state, { drilldown: drilldown })
        case ActionTypes.GET_ITEM_DATA_NOT_FOUND:
            return Object.assign({}, state, { itemExists: false })
        case ActionTypes.GET_ITEM_DATA:
            return Object.assign({}, state, { itemExists: true })
        case ActionTypes.GET_CHECKLIST_COMMENTS_DONE:
            var comments = new DoubleKeyMap(state.comments)
            if (action.payload) Object.keys(action.payload.comments).forEach(item_id => comments.set(action.payload.bucket, item_id, action.payload.comments[item_id]))
            return Object.assign({}, state, { comments: comments })
        case ActionTypes.GET_ITEM_LINKS_DONE:
            let links = new DoubleKeyMap(state.links)
            links.set(action.payload.bucket, action.payload.item, action.payload.data)
            return Object.assign({}, state, { links: links })
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY_DONE:
            let history = new DoubleKeyMap(state.history)
            history.set(action.payload.bucketID, action.payload.itemID, action.payload.history)
            return Object.assign({}, state, { history: history })
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_DONE:
            let graphHistory = new TripleKeyMap(state.graphHistory)
            graphHistory.set(action.payload.bucketID, action.payload.itemID, action.payload.column, action.payload.history)
            return Object.assign({}, state, { graphHistory: graphHistory })
        case ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL_DONE:
            let graphHistory2 = new TripleKeyMap(state.graphHistory)
            action.payload.data.forEach(d => {
                graphHistory2.set(action.payload.bucketID, d.item_id, d.column, d)
            })
            return Object.assign({}, state, { graphHistory: graphHistory2 })
        case ActionTypes.GET_ITEMS_COMMENTS_DONE:
            var _itemComments = new DoubleKeyMap(state.comments)
            let itemIds = Object.keys(action.payload.data)
            itemIds.forEach(itemId => _itemComments.set(action.payload.bId, itemId, action.payload.data[itemId]))
            return Object.assign({}, state, { comments: _itemComments })
        default:
            return state
    }
}

export default Item