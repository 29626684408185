import React from 'react'
import ShowIf from './Generic/ShowIf'
import ReactMarkdown from 'react-markdown'
import { FullSlicer } from '../types/transfertypes'
import { getCompareTypeSymbol } from '../helpers/ReportHelpers'
import WordWrap from './Generic/WordWrap'
import ReactTooltip from 'react-tooltip'
import { CompareType, isBetweenComparison } from '../types/slicertypes'
import HideIf from './Generic/HideIf'
import { IoReload } from 'react-icons/io5'

export interface ReportDescriptionProps {
    description: string,
    filterDescription: string,
    filter: FullSlicer[],
    showColumnDescription: boolean,
    title: string,
    columns: string[],
    columnDescription: {[column: string]: string},
    appliedSlicers?: FullSlicer[],
    isAutomaticUpdate?: boolean,
    datasourceName?: string,
    mainReportName?: string
}

const ReportDescription = ({appliedSlicers = [], isAutomaticUpdate = false, ...props}: ReportDescriptionProps) => {

    let fixedSlicers = appliedSlicers.filter(f => f.isFixed ?? false)
    let clickSlicers = appliedSlicers.filter(f => f.fromClickReport ?? false)
    let dashboardSlicers = appliedSlicers.filter(f => f.rowId != undefined || f.kpi_key != undefined)
    return (
        <div style={{overflowY: "auto", overflowX: "hidden", width:"fit-content", maxHeight: "calc(100vh - 400px)", overscrollBehavior: "none"}}>
            <ShowIf if={props.description != undefined && props.description.length > 0}>
                <div>
                    <ReactMarkdown children={props.description} />  
                </div>
                <hr className="full-width-hr margin-top-10px margin-bottom-10px" />
            </ShowIf>
            <ShowIf if={isAutomaticUpdate}>
                <div>
                    Action list updates automatically <IoReload />
                    <br/><br/>
                </div>
            </ShowIf>
            <ShowIf if={props.datasourceName != undefined}>
                <div className="mt-2 mb-3">
                    <span><b>Datasource</b></span> 
                    <br/>
                    {props.datasourceName}
                </div>
            </ShowIf>
            <ShowIf if={props.mainReportName != undefined}>
                <div className="mt-2 mb-3">
                    <span><b>Filter row based on Insight: </b></span> 
                    <br/>
                    {props.mainReportName}
                </div>
            </ShowIf>
            <span><b>Filters</b></span>
            <br/>
            <div className="mt-2 mb-2">
                {props.filterDescription}
            </div>
            <ShowIf if={props.filter.length == 0 && fixedSlicers.length == 0 && clickSlicers.length == 0 && dashboardSlicers.length == 0}>
                <ul>
                    <li>All rows are included (no filters have been applied)</li>
                </ul>
            </ShowIf>
            <ShowIf if={props.filter.length > 0}>
                <ul>
                    {
                        props.filter.map((f, i) => (
                            <li key={i}>
                                {f.column}&nbsp;
                                {getCompareTypeSymbol(f.compare_type)}&nbsp;
                                <ShowIf if={isBetweenComparison(f.compare_type)}>
                                    {`${f.target_values[0]} and ${f.target_values[1]}`}
                                </ShowIf>
                                <HideIf if={isBetweenComparison(f.compare_type)}>
                                    {
                                        f.target_values.length == 0 ? "No value"
                                            : (f.compare_type === CompareType.CONTAINS || f.compare_type === CompareType.NCONTAINS ? '"' + f.target_values.join('", "') + '"' : f.target_values.join(", "))
                                    }
                                </HideIf>
                            </li>
                        ))
                    }
                </ul>
            </ShowIf>
            <ShowIf if={fixedSlicers.length > 0}>
                <span><b>From fixed slicers</b></span>
                <ul>
                    {
                        fixedSlicers.map((f, i) => (
                            <li key={i}>
                                {f.column}&nbsp;
                                {getCompareTypeSymbol(f.compare_type)}&nbsp;
                                <ShowIf if={isBetweenComparison(f.compare_type)}>
                                    {`${f.target_values[0]} and ${f.target_values[1]}`}
                                </ShowIf>
                                <HideIf if={isBetweenComparison(f.compare_type)}>
                                    {
                                        f.target_values.length == 0 ? "No value"
                                            : (f.compare_type === CompareType.CONTAINS || f.compare_type === CompareType.NCONTAINS ? '"' + f.target_values.join('", "') + '"' : f.target_values.join(", "))
                                    }
                                </HideIf>
                            </li>
                        ))
                    }
                </ul>
            </ShowIf>
            <ShowIf if={clickSlicers.length > 0}>
                <span><b>Fixed filter</b></span>
                <ul>
                    {
                        clickSlicers.map((f, i) => (
                            <li key={i}>
                                {f.column}&nbsp;
                                {getCompareTypeSymbol(f.compare_type)}&nbsp;
                                <ShowIf if={isBetweenComparison(f.compare_type)}>
                                    {`${f.target_values[0]} and ${f.target_values[1]}`}
                                </ShowIf>
                                <HideIf if={isBetweenComparison(f.compare_type)}>
                                    {
                                        f.target_values.length == 0 ? "No value"
                                            : (f.compare_type === CompareType.CONTAINS || f.compare_type === CompareType.NCONTAINS ? '"' + f.target_values.join('", "') + '"' : f.target_values.join(", "))
                                    }
                                </HideIf>
                            </li>
                        ))
                    }
                </ul>
            </ShowIf>
            <ShowIf if={dashboardSlicers.length > 0}>
                <span><b>From Dashboard</b></span>
                <ul>
                    {
                        dashboardSlicers.map((f, i) => (
                            <li key={i}>
                                {f.column}&nbsp;
                                {getCompareTypeSymbol(f.compare_type)}&nbsp;
                                <ShowIf if={isBetweenComparison(f.compare_type)}>
                                    {`${f.target_values[0]} and ${f.target_values[1]}`}
                                </ShowIf>
                                <HideIf if={isBetweenComparison(f.compare_type)}>
                                    {
                                        f.target_values.length == 0 ? "No value"
                                            : (f.compare_type === CompareType.CONTAINS || f.compare_type === CompareType.NCONTAINS ? '"' + f.target_values.join('", "') + '"' : f.target_values.join(", "))
                                    }
                                </HideIf>
                            </li>
                        ))
                    }
                </ul>
            </ShowIf>
            <ShowIf if={props.showColumnDescription}>
                <hr className="full-width-hr margin-top-20px margin-bottom-20px" />
                <table className="table table-condensed table-hover table-striped table-bordered">
                    <thead>

                        <tr>
                            <th>Column</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props?.columns?.map?.(c => (
                                <tr key={c}>
                                    <td>{c}</td>
                                    <td data-tip data-for={`tooltip-${c}`}>
                                        <WordWrap>
                                            {props.columnDescription[c] ? props.columnDescription[c] : ""}
                                        </WordWrap>
                                        <ShowIf if={props.columnDescription[c] != undefined}>
                                            <ReactTooltip id={`tooltip-${c}`} type='dark' effect='solid' place='right'> <ReactMarkdown children={props.columnDescription[c]}/></ReactTooltip>
                                        </ShowIf>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </ShowIf>
        </div>
    )
}

export default ReportDescription
