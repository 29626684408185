/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import ReportContent from '../components/Report/ReportContent'
import Slicer2 from '../components/Report/Slicer2'
import Compose from '../components/Report/Compose'
import { getReportInfo, toggleFavourite, copyReport, deleteReport, updateReport, updateIsPublic, canDeleteReport, removeJumpToCompose } from '../actions/ReportActions'
import { getDashboardAllData } from '../actions/DashboardActions'
import { getFavouriteReportInfo } from '../actions/InfoActions'
import moment from 'moment'
import LinkDialog from '../components/Dialogs/LinkDialog'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'
import SaveReportDialog from '../components/Dialogs/SaveReportDialog'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { slicerEquals, getIdColumn, findNumberColumns, fixReportDataConsistency, convertColumns, getDataDescriptionDialogDescription } from '../helpers/ReportHelpers'
import { notifySuccess, notifyFailure } from '../helpers/NotificationManager'
import { withSettingsStorage } from '../helpers/SettingsService'
import { withRouter, Prompt } from 'react-router'
import { putSetting, getSetting } from '../helpers/SingleSettingService'
import RequirePermission from '../components/RequirePermission'
import { is, isReportCreateOnly, canUseReports } from '../helpers/PermissionHelpers'
import Scrollbar from '../components/Generic/Scrollbar'
import { followItem, getUserInfo } from '../actions/UserActions'
import { getUsers } from '../actions/SystemActions'
import { getSlicerValues } from '../actions/ReportActions'
import ReportDataIssueDialog from '../components/Dialogs/ReportDataIssueDialog'
import WarningSaveDialog from '../components/Dialogs/WarningSaveDialog'
import { getURLQueryParameter } from '../helpers/PathHelpers'
import { Link } from 'react-router-dom'
import DescriptionDialog from '../components/Dialogs/DescriptionDialog'
import ShowIf from '../components/Generic/ShowIf'
import { getProfitData } from '../actions/BucketActions'
import ReportUnselectColumnsDialog from '../components/Dialogs/ReportUnselectColumnsDialog'
import { getFilter } from '../helpers/ReportHelpers'
import { formatDateTime, ItemTooOld } from '../helpers/TimeHelpers'
import WarningDescriptionDialog from '../components/Dialogs/WarningDescriptionDialog'
import SaveDialog from '../components/Dialogs/SaveDialog'
import ResponsiveToggle from '../components/Generic/ResponsiveToggle'
import HomeIcon from '../components/icons/HomeIcon'
import DataDescriptionDialog2 from '../components/Dialogs/DataDescriptionDialog2'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.reportInfo,
        users: state.System.users,
        altReportInfo: state.Info.altReportInfo,
        favourites: state.Report.favourites,
        toggleFav: state.Report.toggleFav,
        jumpToCompose: state.Report.jumpToCompose,
        userPermissions: state.User.info.access,
        user: state.User.info,
        reportsInDashboards: state.Report.reportsInDashboards,
        reportExists: state.Info.reportExists,
        reportAccessible: state.Info.reportAccessible,
        slicerValues: state.Report.slicerValues,
        reportHasErrors: state.Report.reportHasErrors,
        followings: state.User.followings,
        profits: state.Bucket.profits,
        dashboardData: state.Dashboard.dashboard_data,
    }
}

class Report extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            slicers: [],
            clickReport: false,
            clickedCategory: "",
            clickedColumn: "",
            isFavourite: false,
            clickReportLimit: -1,
            editMode: false,
            settings: null,
            aboutToCopy: false,
            newReportName: "",
            newReportAddToFavorites: false,
            newReportDescription: "",
            deleteWarning: false,
            shownGridColumns: [],
            disableSave: false,
            fixedSlicerColumns: [],
            composeData: {},
            isComposeDataReady: false,
            clickSlicerReturn: "overview",
            idSearchSlicer: {},

            showDataDescription: false,
        }
    }
    reportContentRef = React.createRef()

    componentDidMount() {
        let id = parseInt(this.props.match.params.repId, 10)
        let { dispatch } = this.props
        dispatch(getReportInfo(id))
        if(!this.props.users)
            dispatch(getUsers())
        dispatch(getFavouriteReportInfo())
        dispatch(getUserInfo())

        let queryParams = new URLSearchParams(this.props.location.search)

        let dashboard = queryParams.get("dId")
        if(dashboard)
            dispatch(getDashboardAllData(dashboard))


        window.onbeforeunload = this.handleOnBeforeUnload
        document.addEventListener("onExitEditMode", this.exitEditModeHandler)
    }

    componentWillUnmount() {
        window.onbeforeunload = undefined
    }

    handleOnBeforeUnload = e => {
        if (this.reportChanged()) {
            const message = 'You have unsaved changes, are you sure that you want to reload the page?'
            e.returnValue = message
            return message
        }
    }

    getReportData() {
        if (this.state.editMode && this.state.isComposeDataReady) return this.state.composeData
        else return this.props.info ? this.props.info : {}
    }

    getSection() {
        return this.props.match.params.section ? this.props.match.params.section : 'overview'
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dispatch } = this.props
        let favs = this.props.favourites.get("data", [])
        let prevFavs = prevProps.favourites.get("data", [])
        let reportdata = this.props.info ? this.props.info : null

        let toggle = this.props.toggleFav.get("data", { id: -1 })
        let prevToggle = prevProps.toggleFav.get("data", { id: -1 })
        if (toggle.id !== prevToggle.id) {
            dispatch(getFavouriteReportInfo())
        }

        let repId = this.props.match.params.repId
        if (repId && repId !== prevProps.match.params.repId) { //If report id changes
            dispatch(getReportInfo(parseInt(repId, 10)))
        }

        if (reportdata && reportdata.report && reportdata.report.report_id) {
            if(prevProps.info && prevProps.info.report && prevProps.info.report.report_id){
                if(reportdata.report.report_id !== prevProps.info.report.report_id){
                    this.setState({ slicers: [] }) //reset all slicers
                }
            }
        }


        if (reportdata && reportdata.info) {
            setPageTitle(reportdata.report.name)
        }

        if ((reportdata && JSON.stringify(prevProps.info) !== JSON.stringify(reportdata)) || (reportdata && !this.state.isComposeDataReady)) {
            this.setState({ composeData: reportdata, isComposeDataReady: true })
            if(prevProps?.info?.info?.id != this.props.info.info.id && this.props.info.report.fixed_slicer){
                let fixedSlicerColumns = []
                try {
                    fixedSlicerColumns = JSON.parse(this.props.info.report.fixed_slicer)
                } catch (e) {
                    fixedSlicerColumns = [this.props.info.report.fixed_slicer]
                }
                fixedSlicerColumns = Array.isArray(fixedSlicerColumns) ? fixedSlicerColumns : [fixedSlicerColumns]

                let columns = JSON.parse(reportdata.report.columns)
                for (let i = 0; i < fixedSlicerColumns.length; i++) {
                    if(columns.includes(fixedSlicerColumns[i])){
                        this.props.dispatch(getSlicerValues(this.props.info.info.id, { column: fixedSlicerColumns[i], filter: [] }, getFilter(this.getReportData().report, this.getSlicers(false, fixedSlicerColumns[i]))))
                    }
                }
            }
        
            if(this.props.info.info.id)
                this.props.dispatch(getProfitData(this.props.info.info.id))
        }

        if (reportdata && reportdata.report && reportdata.report.report_id) {
            let viewSettings = JSON.parse(reportdata.report.view_settings)
            let prevViewSettings = prevProps.info ? JSON.parse(prevProps.info.report.view_settings) : {}
            if (this.state.shownGridColumns.length === 0 || (JSON.stringify(viewSettings.detailed_report_columns) !== JSON.stringify(prevViewSettings.detailed_report_columns) && viewSettings.detailed_report_columns && prevViewSettings.detailed_report_columns)) {
                //If there's no column setup or if the column setup in view_Settings changed
                let columns = this.getViewSettingColumns().columns
                if (columns.length === 0) {
                    columns = this.getColumns(reportdata).slice(0, 8)
                    columns = convertColumns(columns, reportdata)
                }
                this.setState({ shownGridColumns: columns })
            }

            let isFav = false
            if (favs !== prevFavs && favs.length > 0) {
                favs.forEach((item, index) => {
                    if (item.item_id === parseInt(repId, 10)) {
                        isFav = true
                    }
                })
                this.setState({ isFavourite: isFav })
            }
        }

        if (prevProps.info !== this.props.info && prevProps.info !== undefined) {
            const settings = JSON.parse(this.props.info.report.view_settings)["settings"]
            const prevSettings = JSON.parse(prevProps.info.report.view_settings)["settings"]
            if (settings && prevSettings && prevSettings.default_view !== settings.default_view) {
                let defView = settings.default_view
                if (settings[defView]) { //view is active
                    this.changeView(settings.default_view, true)
                }
                else { //go to first enabled view
                    let settingKeys =Object.keys(settings)
                    for(let i=0; i < settingKeys.length; i++){
                        if(settings[settingKeys[i]] === true){ //'default_view' is a key.
                            this.changeView(settingKeys[i], true)
                        }
                    }
                }

            }
        }

        if (prevProps.reportsInDashboards !== this.props.reportsInDashboards && this.props.reportsInDashboards.get(repId, []).length > 0) {
            this.refs.warnDeleteReportDialog.show()
        }

        if(this.state.aboutToCopy !== prevState.aboutToCopy && this.state.aboutToCopy) {
            if(this.refs.saveReportDialog !== undefined) {
               this.refs.saveReportDialog.show()
            }
        }
        else if (this.state.aboutToCopy) {
            this.setState({aboutToCopy: false })
        }

        // if ((JSON.stringify(prevState.slicers) != JSON.stringify(this.state.slicers)) && reportdata && (this.props.info.info.id == this.getCompose("report", "bucket"))){
        //     let fixedSlicerColumns = this.getFixedSlicerColumns(true)
        //     let columns = JSON.parse(reportdata.report.columns)
        //     for (let i = 0; i < fixedSlicerColumns.length; i++) {
        //         if(columns.includes(fixedSlicerColumns[i])){
        //             this.props.dispatch(getSlicerValues(this.props.info.info.id, { column: fixedSlicerColumns[i], filter: [] }, getFilter(this.getReportData().report, this.getSlicers(false, fixedSlicerColumns[i]))))
        //         }
        //     } 
        // }
        
        let queryParams = new URLSearchParams(this.props.location.search)
        let dashboard = queryParams.get("dId")
        let rowId = queryParams.get("rowId")
        let kpi_key = queryParams.get("kpi_key")

        let tableRowClicked = null;
        let kpiClicked = null
        
        if(dashboard  && this.props.dashboardData && this.props.dashboardData.get(dashboard)){
            
            if(kpi_key){
                let kpis = JSON.parse(this.props.dashboardData.get(dashboard).dashboard_data.kpis)
                for(let kpi = 0; kpi < kpis.length; kpi++ ){
                    if(kpis[kpi].kpi_key == kpi_key){
                        kpiClicked = kpis[kpi];
                        break;
                    }
                }

                if(kpiClicked){
                    this.removeKpiDashboardSlicer(kpiClicked.kpi_key)
                    this.addKpiDashboardSlicers(kpiClicked.category_filter, kpiClicked.kpi_key)
                }
            } else {
                this.removeKpiDashboardSlicer(kpi_key)
            }

            if(rowId) {
                let rows = JSON.parse(this.props.dashboardData.get(dashboard).dashboard_data.rows)
                for(let row = 0; row < rows.length; row++ ){
                    if(rows[row].row_id == rowId){
                        tableRowClicked = rows[row];
                        break;
                    }
                }
                if(tableRowClicked){
                    this.removeDashboardSlicers(tableRowClicked.row_id)
                    this.addDashBoardSlicer(tableRowClicked.row.filter, tableRowClicked.row_id)
                }
            }
            else {
                this.removeDashboardSlicers(rowId)
            }
        } else {
            this.removeDashboardSlicers(rowId)
            this.removeKpiDashboardSlicer(kpi_key)
        }

    }

    getFixedSlicerColumns() {
        let fixed_slicer = this.getReportData().report.fixed_slicer
        if (fixed_slicer) {
            let parsedColumns
            try {
                parsedColumns = JSON.parse(fixed_slicer)
            } catch (e) {
                return [fixed_slicer]
            }
            return Array.isArray(parsedColumns) ? parsedColumns : [parsedColumns]
        } else {
            return []
        }
    }

    getFilter() {
        let filter = this.getReportData().report.filter
        return JSON.parse(filter)
    }

    addFixedSlicer(column) {
        let temp = this.getFixedSlicerColumns().slice()
        temp.push(column)
        this.setCompose("report", "fixed_slicer", JSON.stringify(temp))
        this.props.dispatch(getSlicerValues(this.getReportData().info.id, { column: column, filter: [] }, getFilter(this.getReportData().report, this.getSlicers(false, column))))
    }
    
    moveFixedSlicer = (oldIndex, newIndex) => {
        let temp = this.getFixedSlicerColumns().slice()
        let slicer = temp[oldIndex]
        temp.splice(oldIndex, 1)
        temp.splice(newIndex, 0, slicer)
        this.setCompose("report", "fixed_slicer", JSON.stringify(temp))
        // this.props.dispatch(getSlicerValues(this.getReportData().info.id, { column: slicer, filter: [] }, getFilter(this.getReportData().report, this.getSlicers(false))))
    }

    removeFixedSlicer(column) {
        this.setCompose("report", "fixed_slicer", JSON.stringify(this.getFixedSlicerColumns().filter(fs => fs !== column)))
    }

    updateFixedSlicers() {
        let reportdata = this.props.info
        let data = {
            name: reportdata.report.name,
            bucket: reportdata.info.id,
            columns: JSON.parse(reportdata.report.columns),
            filter: JSON.parse(reportdata.report.filter),
            sort_column: reportdata.report.sort_column,
            sort_direction: reportdata.report.sort_direction,
            limit: reportdata.report.limit,
            kpis: reportdata.report.kpis,
            fixed_slicer: JSON.stringify(this.state.fixedSlicerColumns),
            view_settings: reportdata.report.view_settings,
            kpi_config: reportdata.report.kpi_config,
            user_id: reportdata.report.user_id,
            group_id: reportdata.report.group_id,
            is_public: reportdata.report.is_public,
            description: reportdata.report.description
        }
        this.props.dispatch(updateReport(reportdata.report.report_id, data))
    }

    getColumns(reportdata) {
        return JSON.parse(reportdata.report.columns)
    }

    toggleFav() {
        let { dispatch } = this.props
        let reportdata = this.props.info ? this.props.info : []

        let data = {
            favourited: !this.state.isFavourite,
            item_type: 'report',
            item_id: reportdata.report.report_id
        }
        dispatch(toggleFavourite(data))
        this.setState({ isFavourite: !this.state.isFavourite })
    }

    setFav = (value) => {
        let { dispatch } = this.props
        let reportdata = this.props.info ? this.props.info : []

        let data = {
            favourited: value,
            item_type: 'report',
            item_id: reportdata.report.report_id
        }
        dispatch(toggleFavourite(data))
        this.setState({ isFavourite: value })
    }

    setShownState(state) {
        this.setState(prevState => ({
            [state]: !prevState[state]
        }))
    }

    changeView = (section, silent = false, skipKPICheck = false) => {

        let queryParams = new URLSearchParams(this.props.location.search)
        let dashboard = queryParams.get("dId")
        let rowId = queryParams.get("rowId")
        let home = queryParams.get("home") ?? 0
        let table = queryParams.get("table")
        let kpi_key = queryParams.get("kpi_key")


        let reportdata = this.getReportData()
        let kpis = !skipKPICheck && reportdata.report ? JSON.parse(reportdata.report.kpis) : []
        if (section === 'trend' && !skipKPICheck && kpis.length === 0 && this.refs.noKPIDialog) {
            this.refs.noKPIDialog.show()
        } else {
            let hash = this.currentSettingsHash
            let uuid = hash ? '/' + hash : ''
            let url = `/report/${this.props.match.params.repId}/${section}${uuid}`
            if (dashboard && rowId && kpi_key){
                url = url + `?dId=${dashboard}&rowId=${rowId}&kpi_key=${kpi_key}&home=${home}&table=${table}`
            }
            else if(dashboard && rowId){
                url = url + `?dId=${dashboard}&rowId=${rowId}&home=${home}&table=${table}`
            }

            if (silent) this.props.history.replace(url)
            else this.props.history.push(url)

        }

    }

    checkDetailedReportColumns(dwc) {
        let arr = []
        if (dwc && dwc.length > 0 && dwc[0].column) { // converting old object used for detailed view columns to array with only names of columns
            dwc.forEach((item, i) => {
                if (item.shown) {
                    arr.push(item.column)
                }
            })
        }

        return arr
    }



    getViewSettingColumns() {
        let reportdata = this.getReportData()
        let viewSettings = JSON.parse(reportdata.report.view_settings)
        if (undefined !== viewSettings.detailed_report_columns) {
            let sc = []
            let drc = viewSettings.detailed_report_columns.slice()
            let checkedColumns = this.checkDetailedReportColumns(drc)
            if (checkedColumns.length > 0) {
                sc = convertColumns(checkedColumns, reportdata)
            } else {
                sc = convertColumns(drc, reportdata)
            }
            return { columns: sc }
        }
        return { columns: [] }
    }

    getReportUpdateData(changes, reportdata) {
        let data = {
            name: reportdata.report.name,
            bucket: reportdata.info.id,
            columns: JSON.parse(reportdata.report.columns),
            filter: JSON.parse(reportdata.report.filter),
            sort_column: reportdata.report.sort_column,
            sort_direction: reportdata.report.sort_direction,
            limit: reportdata.report.limit,
            kpis: reportdata.report.kpis,
            fixed_slicer: reportdata.report.fixed_slicer,
            view_settings: reportdata.report.view_settings,
            kpi_config: reportdata.report.kpi_config,
            user_id: reportdata.report.user_id,
            group_id: reportdata.report.group_id,
            is_public: reportdata.report.is_public,
            description: reportdata.report.description
        }
        return Object.assign(data, changes)
    }

    addSlicer(slicer) {
        let editMode = this.state.editMode

        let slicers = editMode ? this.getFilter() : this.state.slicers.slice()

        let newSlicersArr = [slicer].concat(slicers)

        const idColumn = getIdColumn(this.getCompose("info", "model").columns)

        if (slicer.column === idColumn && this.reportContentRef.current) {
            //Select items from id slicer
            let deselectedItems = this.reportContentRef.current.getSelectedItems().slice()
            if (slicer.compare_type === 1)
                deselectedItems = deselectedItems.filter(i => !slicer.target_values.includes(i))
            if (slicer.compare_type === 6)
                deselectedItems = deselectedItems.filter(i => slicer.target_values.includes(i))
            if (slicer.compare_type === 9)
                deselectedItems = deselectedItems.filter(i => slicer.target_values.findIndex(t => i.includes(t)) === -1)
            if (slicer.compare_type === 12)
                deselectedItems = deselectedItems.filter(i => slicer.target_values.findIndex(t => i.includes(t)) !== -1)
            this.reportContentRef.current.deselectItems(deselectedItems)
        }

        if (editMode) {
            this.setCompose("report", "filter", JSON.stringify(newSlicersArr))
        } else {
            this.setState({ slicers: newSlicersArr })
        }
    }

    removeSlicer(slicer) {
        let reportdata = this.getReportData()
        let editMode = this.state.editMode

        let slicers = editMode ? JSON.parse(reportdata.report.filter) : this.state.slicers.slice()
        let index = slicers.findIndex(s => slicerEquals(s, slicer))
        if (index >= 0) {
            slicers.splice(index, 1)
            if (editMode) {
                this.setCompose("report", "filter", JSON.stringify(slicers))
            } else {
                this.setState({ slicers: slicers })
            }
        }
    }

    updateSlicer(oldSlicer, newSlicer) {
        let reportdata = this.getReportData()
        let editMode = this.state.editMode

        let slicers = editMode ? JSON.parse(reportdata.report.filter) : this.state.slicers.slice()
        let index = slicers.findIndex(s => slicerEquals(s, oldSlicer))
        if (index >= 0) {
            slicers[index] = Object.assign({}, oldSlicer, newSlicer)
        } else {
            slicers.push(newSlicer)
        }

        const idColumn = getIdColumn(this.getCompose("info", "model").columns)

        if (newSlicer.column === idColumn && this.reportContentRef.current) {
            //Select items from id slicer
            let deselectedItems = this.reportContentRef.current.getSelectedItems().slice()
            if (newSlicer.compare_type === 1)
                deselectedItems = deselectedItems.filter(i => !newSlicer.target_values.includes(i))
            if (newSlicer.compare_type === 6)
                deselectedItems = deselectedItems.filter(i => newSlicer.target_values.includes(i))
            if (newSlicer.compare_type === 9)
                deselectedItems = deselectedItems.filter(i => newSlicer.target_values.findIndex(t => i.includes(t)) === -1)
            if (newSlicer.compare_type === 12)
                deselectedItems = deselectedItems.filter(i => newSlicer.target_values.findIndex(t => i.includes(t)) !== -1)
            this.reportContentRef.current.deselectItems(deselectedItems)
        }

        if (editMode) {
            this.setCompose("report", "filter", JSON.stringify(slicers))
        } else {
            this.setState({ slicers: slicers })
        }
    }

    addClickSlicer(slicer, clickReport, limit) {
        // TO DO - Håndter hvis man så fjerner en anden slicer mens man er på en clickreport
        slicer.fromClickReport = true
        let slicers = this.state.slicers.slice() // copy to another array 
        if (slicer === 'Total') {
            this.setState({ clickReport: clickReport, clickedColumn: "", clickedCategory: slicer })
        } else {
            slicers.push(slicer)
            this.setState({ slicers: slicers, clickReport: clickReport, clickedColumn: slicer.column, clickedCategory: slicer.target_values[0], clickReportLimit: limit })
        }
        this.setState({ clickSlicerReturn: this.getSection() })
    }

    removeClickSlicer() {
        let index = this.state.slicers.findIndex(s => s.fromClickReport)
        if (index !== -1) {
            let slicers = this.state.slicers.slice() // copy to another array 
            slicers.splice(index, 1)
            this.setState({ slicers: slicers, clickReport: false, clickReportLimit: -1, clickedColumn: "", clickedCategory: "" })
        }
        else {
            this.setState({ clickReport: false, clickReportLimit: -1, clickedColumn: "", clickedCategory: "" })
        }
    }

    slicerAlreadyAdded(slicer){
        let slicers = this.state.slicers.slice()

        let result = false
        for(let i=0; i < slicers.length && !result; i++){
            let s = slicers[i]
            
            let exactMatch = s.column === slicer.column && 
                             s.compare_type === slicer.compare_type && 
                             s.target_values.length === slicer.target_values.length &&
                             s.target_values.every((val, index) => val === slicer.target_values[index]) &&
                             s.disabled === slicer.disabled;

            result = result || exactMatch
        }
        return result
    }

    removeKpiDashboardSlicer(kpi_key){
        let removed = false
        let slicers = this.state.slicers.slice()
        for(let i=0; i < slicers.length; i++){
            let s = slicers[i]

            if(s.kpi_key && s.kpi_key !== kpi_key){
                this.removeSlicer(s)
                removed = true
            }
        }

        if(removed){
            this.removeClickSlicer()
        }
    }

    addKpiDashboardSlicers(target_values, kpi_key){
        if(target_values.length > 0){
            let reportData = this.getReportData()

            if(reportData && reportData.info && reportData.info.model){
                let model = reportData.info.model
                let categoryColumn = model?.categorization_name

                let slicer = {
                    id: 1,
                    column: categoryColumn,
                    compare_type: 1, //equal
                    target_values: target_values,
                    disabled: true,
                    kpi_key: kpi_key
                }
                    
                if(this.getCompose("info", "model").columns && !this.slicerAlreadyAdded(slicer)){
                    this.addSlicer(slicer)
                }
            }
        }
    }

    removeDashboardSlicers(rowId){
        let removed = false
        let slicers = this.state.slicers.slice()
        for(let i=0; i < slicers.length; i++){
            let s = slicers[i]

            if(s.rowId && s.rowId !== rowId){
                this.removeSlicer(s)
                removed = true
            }
        }

        if(removed){
            this.removeClickSlicer()
        }
    }

    addDashBoardSlicer(criteria, rowId){
        for(let i=0; i< criteria.length; i++){

            const slicer = (criteria[i]);
            slicer.disabled = true;
            slicer.rowId = rowId;

            if(this.getCompose("info", "model").columns && !this.slicerAlreadyAdded(slicer)){
                this.addSlicer(slicer)
                
            }
        }
    }

    getShownState(state) {
        if (state) {
            return "fa-chevron-down"
        } else {
            return "fa-chevron-right"
        }
    }

    setSelected(item) {
        this.setState((prevState) => {
            if (item === prevState.selected) {
                return {
                    selected: null
                }
            } else {
                return {
                    selected: item
                }
            }
        })
    }

    getButtonColor(bool) {
        if (bool) {
            return 'kpi1Selected'
        }
        return ''
    }

    hideMultipleRows = (ids) => {
        let reportdata = this.getReportData()
        let editMode = this.state.editMode
        let idColumn = getIdColumn(reportdata.info.model.columns)
        let slicers = editMode ? JSON.parse(reportdata.report.filter) : JSON.parse(JSON.stringify(this.state.slicers)) // clone array to new array 
        let index = slicers.findIndex(s => !!s.hideRow || (s.column === idColumn && s.compare_type === 6))
        if (index >= 0) {
            slicers[index].target_values = slicers[index].target_values.concat(ids)
        } else {
            slicers.push({
                compare_type: 6,
                target_values: ids,
                column: idColumn,
                hideRow: true
            })
        }

        if (editMode) {
            this.setCompose("report", "filter", JSON.stringify(slicers))
        } else {
            let count = ids.length
            this.setState({ slicers: slicers }, () => {
                if (count === 1) notifySuccess("Row hidden!")
                else notifySuccess(`${count} rows hidden!`)
            })
        }
    }

    searchInputChanged(value, column) {
        this.setState({ idSearchSlicer: { column: column, compare_type: 9, target_values: [value] } })
    }

    getIdSearchSlicer() {
        return this.state.idSearchSlicer
    }

    copyLinkToClipboard() {
        this.getPermanentLink().then(result => this.refs.linkDialog.show(result), error => notifyFailure("Error while copying permanent link to clipboard!"))
    }

    saveSettings(){
        this.getPermanentLink().catch(error => console.log("Error persisting settings: ", error))
    }

    goToTrend() {
        this.removeClickSlicer()
        this.changeView("trend")
    }

    copyReport() {
        const { dispatch, user } = this.props
        const report = this.props.info ? this.props.info.report : []


        if (!this.state.editMode) {
            let data = { //Overvej at bruge this.getReportUpdateData()
                name: this.state.newReportName,
                bucket: report.bucket,
                columns: JSON.parse(report.columns),
                filter: JSON.parse(report.filter),
                sort_column: report.sort_column,
                sort_direction: report.sort_direction,
                limit: report.limit,
                kpis: report.kpis,
                fixed_slicer: report.fixed_slicer,
                view_settings: report.view_settings,
                kpi_config: report.kpi_config,
                user_id: user.user_id,
                group_id: 0,
                is_public: false,
                description: this.state.newReportDescription,
                addToFavorites: this.state.newReportAddToFavorites
            }

            dispatch(copyReport(data))
        } else if (this.state.editMode) {
            this.save(true, this.state.newReportName)
        }
        this.setState({ aboutToCopy: false })
    }

    save = (fromReport, newName, section) => { // these parameters are only used from Report.js line 447 - when the user uses the "Save as"-function while editmode is open - section is used to avoid "not access"-error.
        const { dispatch } = this.props

        let data = Object.assign({}, this.state.composeData.report)


        let viewSettings = this.getCompose("report", "view_settings")


        if (viewSettings.detailed_report_columns && viewSettings.detailed_report_columns.length === 0 && this.getCompose("report", "columns").length > 0) {
            //Always have at least one shown column
            viewSettings.detailed_report_columns.push(this.getCompose("report", "columns")[0])
        }

        if (viewSettings.detailed_report_columns && viewSettings.detailed_report_columns.length > 0 && viewSettings.detailed_report_columns[0].column) { // we need to convert to new array to update in the database
            let res = []
            viewSettings.detailed_report_columns.forEach((drc, index) => {
                this.getCompose("report", "columns").forEach((r, i) => {
                    if (drc.column === r && drc.shown) {
                        res.push(drc.column)
                    }
                })
            })
            viewSettings.detailed_report_columns = res
        }


        if (this.getCompose("report", "bucket") !== this.props.info.info.id && this.getCompose("report", "columns").length === 0) {
            viewSettings.detailed_report_columns = []
        }

        if (!this.checkReportColumns()) {
            notifyFailure("You must include at least one number column!")
            return false
        }

        if (!this.checkReportIdColumn()) {
            let idColumn = getIdColumn(this.getCompose("info", "model").columns)
            notifyFailure("The Insight must include the id column! ('" + idColumn + "')")
            return false
        }

        if (this.getCompose("report", "name") === "" && !this.props.newReport) {
            notifyFailure("You must name your Insight!")
            return false
        }

        let gridColumns = viewSettings.detailed_report_columns.map(c => {
            let reportdata = this.getReportData()
            let d = reportdata.info.model.columns.find((e) => {
                return e.name.toLowerCase() === c.toLowerCase()
            })
            return { name: c, type: d.type, index: JSON.parse(reportdata.report.columns).findIndex(_c => _c === c) }
        })
        this.setState({ shownGridColumns: gridColumns })

        //Remove active fixed slicers, if they are removed
        let slicers = this.state.slicers
        let fixedSlicers = this.getFixedSlicerColumns()
        slicers.filter(s => s.isFixed).forEach((s, i) => {
            if (!fixedSlicers.includes(s.column)) {
                slicers.splice(i, 1)
            }
        })
        this.setState({ slicers })

        if (this.props.newReport) {
            data.columns = this.getCompose("report", "columns")
            data.filter = this.getCompose("report", "filter")
            dispatch(copyReport(data))
        } else {
            let prevViewSettings = JSON.parse(this.props.info.report.view_settings)
            if (prevViewSettings && prevViewSettings.settings && prevViewSettings.settings.default_view !== viewSettings.settings.default_view) {
                this.changeView(viewSettings.settings.default_view)
            }
            if (prevViewSettings && prevViewSettings.settings && prevViewSettings.settings.default_view === viewSettings.settings.default_view && prevViewSettings.settings !== viewSettings.settings) {
                let shouldChange = false
                for (const key in viewSettings.settings) {
                    if (key === section && !viewSettings.settings[key]) {
                        shouldChange = true
                    }
                }
                if (shouldChange) {
                    this.changeView(viewSettings.settings.default_view)
                }
            }
            if (data.bucket !== this.getCompose("info", "id") || this.props.info.report.columns !== this.getCompose("report", "columns", false)) {
                // bucket has changed and we need to check if kpis and kpi_config now has columns that is not in this bucket
                data = fixReportDataConsistency(data, this.getCompose("report", "columns"))
            }
            data.columns = this.getCompose("report", "columns")
            data.filter = this.getCompose("report", "filter")
            if (fromReport) { // if the user uses the "Save as"-function when editmode is open
                data.name = newName
                data.description = ""
                data.addToFavorites = this.state.newReportAddToFavorites
                dispatch(copyReport(data))
            } else {
                dispatch(updateReport(this.getCompose("report", "report_id"), data))
            }
        }
        return true
    }

    checkReportColumns() {
        let arr = findNumberColumns(this.getCompose("info", "model"), this.getCompose("report", "columns"))

        if (arr.length > 0) {
            return true
        }
        return false
    }

    checkReportIdColumn() {
        let idColumn = getIdColumn(this.getCompose("info", "model").columns)
        return this.getCompose("report", "columns").includes(idColumn)
    }

    getCompose = (sectionKey, valueKey, parse = true) => {
        if (!this.state.composeData[sectionKey]) return {}
        let value = this.state.composeData[sectionKey][valueKey]
        if (sectionKey === "report" && parse) {
            switch (valueKey) {
                case "kpis":
                case "columns":
                case "filter":
                case "fixed_slicer":
                case "view_settings":
                case "kpi_config":
                    return value === undefined || value === null ? {} : JSON.parse(value)
                default:
                    break
            }
        }
        return typeof (value) === "object" ? (Array.isArray(value) ? value.slice() : Object.assign({}, value)) : (value === undefined || value === null ? "": value)  
    }

    promptExitEditMode = () => {
        if (JSON.stringify(this.state.composeData) !== JSON.stringify(this.props.info)) {
            this.refs.warnUnsavedChangesDialog.show()
        } else {
            this.exitEditMode()
        }
    }

    exitEditMode = () => {
        this.setState({ editMode: !this.state.editMode, composeData: Object.assign({}, this.props.info) })
        this.refs.compose.hide()
    }

   
    exitEditModeHandler() {
        this.promptExitEditMode()
    }

    enterEditMode = () => {
            this.setState({ editMode: true })
    }

    deleteReport() {
        let { dispatch } = this.props
        let id = parseInt(this.props.match.params.repId, 10)
        dispatch(deleteReport(id))
    }

    getSlicers(includeSearchSlicer = true, excludeColumnSlicer = '') {
        let slicers = []
        if (this.state.editMode) {
            slicers = this.state.slicers.slice().filter(s => s.fromClickReport)
        } else {
            slicers = this.state.slicers.slice()
        }
        if (includeSearchSlicer && this.state.idSearchSlicer.target_values) {
            slicers.push(this.state.idSearchSlicer)
        }

        let columns = JSON.parse(this.getReportData().report.columns)
        
        let newSlicers = []
        for(let i = 0; i < slicers.length; i++){
            if(columns.includes(slicers[i].column)){
                newSlicers.push(slicers[i])
            }
        }

        if(excludeColumnSlicer !== ''){
            newSlicers = []
            for(let i = 0; i < slicers.length; i++){
                if(slicers[i].column !== excludeColumnSlicer){
                    newSlicers.push(slicers[i])
                }
            }
            slicers = newSlicers 
        }
        return slicers
    }

    disableSave(bool) {
        this.setState({ disableSave: bool })
    }

    getDisabledText = () => {
        const kpis = JSON.parse(this.props.info.report.kpis)
        if (kpis.length === 0) {
            return "Insight cannot be saved when 'Default view' is set to Trend and you have no KPIs in the Insight."
        } else {
            return 'Insight cannot be saved when all views has been disabled.'
        }
    }

    getCommentContextPath = async () => {
        try {
            let path = await this.getPermanentLink(true)
            return path
        } catch (e) {
            notifyFailure("Couldn't get context path for comment. If you tagged someone, they will have no link to the comment.")
            return ''
        }
    }

    setComposeData = (sectionKey, section, cb) => {
        let composeData = Object.assign({}, this.state.composeData)
        composeData[sectionKey] = section
        this.setState({ composeData }, cb)
    }

    setCompose = (sectionKey, valueKey, value, cb) => {
        let section = Object.assign({}, this.state.composeData[sectionKey])
        if (section[valueKey] !== value) {
            section[valueKey] = value
            this.setComposeData(sectionKey, section, cb)
        } else if (cb) cb()
    }

    setReportInfo = (info, cb) => this.setComposeData("info", info, cb)

    setReport = (report, cb) => this.setComposeData("report", report, cb)

    promptSetBucket = (bucketId, issues) => {
        this.refs.setBucketDialog.show(issues, bucketId)
    }

    promptUnselectColumns = (cb, issues) => {
        this.refs.unselectColumnsDialog.show(issues, cb)
    }

    setShownGridColumns = (sc) => {
        if (this.state.editMode) {
            let viewSettings = JSON.parse(this.getReportData().report.view_settings)
            viewSettings.detailed_report_columns = sc.map(c => c.name)
            this.setCompose("report", "view_settings", JSON.stringify(viewSettings))
        } else {
            this.setState({ shownGridColumns: sc })
        }
    }

    makeReportPublic = () => {
        this.setCompose("report", "is_public", true, () => {
            const report = this.props.info ? Object.assign({}, this.props.info.report) : {}
            report.columns = JSON.parse(report.columns)
            report.filter = JSON.parse(report.filter)
            this.props.dispatch(updateIsPublic(report.report_id, report))
        })
    }

    reportChanged = () => {
        return JSON.stringify(this.state.composeData) !== JSON.stringify(this.props.info) && this.state.editMode
    }

    getClickableCategories = () => {
        let filter = this.getFilter() //report filter
        let model = this.getReportData().info.model
        let categoryColumn = model?.categorization_name
        let categories = model?.categorization?.map(category => category.name) ?? []

        let categoryEqualityTargetValues = //must be present in all target_value arrays with equality comparison
            filter
                .filter(slicer => slicer.column === categoryColumn && slicer.compare_type === 1) //s.compare_type === 1 is equality
                .map(slicer => slicer.target_values) //use only target_value array

        let categoryInequalityTargetValues =  //must NOT be present in target_value arrays with inequality comparison
            filter
                .filter(slicer => slicer.column === categoryColumn && slicer.compare_type === 6) //s.compare_type === 6 is inequality
                .map(slicer => slicer.target_values) //use only target_value array

        return categories.filter(category =>
            !categoryInequalityTargetValues.some(target_values => target_values.includes(category)) &&
            categoryEqualityTargetValues.every(target_values => target_values.includes(category))
        )
    }

    followReport = follow => () => {
        const reportdata = this.getReportData()
        this.props.dispatch(followItem(reportdata.report.report_id, "report", follow))
    }

    render() {
        if (!this.props.reportExists) return (
            <div className="container-fluid">
                <div className="alert alert-success margin-top-10px fix-link-success">
                    <b>The Insight is no longer available.</b> Most likely someone has deleted it, go to Insights to view all Insights. <Link to="/library"><i>Go to Insights</i></Link>.
                </div>
            </div>
        )
        if (!this.props.reportAccessible) return (
            <div className="container-fluid">
                <div className="alert alert-success margin-top-10px fix-link">
                    <b>Sorry, you do not have permission to access this Insight.</b>
                    {
                        this.props.userInfo && this.props.userInfo.access && canUseReports(this.props.userInfo.access) ? <span>
                            Continue to <Link to="/library"><i>Go to Insights</i></Link> to see alle the Insights you have access to.
                        </span> : null
                    }
                </div>
            </div>
        )
        if (this.props.reportHasErrors) return (
            <div className="container-fluid">
                <div className="alert alert-danger margin-top-10px fix-link-danger">
                    <b>Something went wrong while getting Insight data.</b> If the error persists, please contact <a href="mailto:support@inact.io">support@inact.io</a>. <Link to="/library"><i>Go to Insights</i></Link>.
                </div>
            </div>
        )


        let id = parseInt(this.props.match.params.repId, 10)
        let info = this.props.altReportInfo.get(id, null)
        if (!info) {
            return null
        }

        if (info.missing && info.missing.length > 0) {
            return (
                <div className="container-fluid">
                    <div className="alert alert-danger margin-top-10px fix-link-danger">
                        <b>Something went wrong.</b> This Insight includes columns that are no longer in the selected data! The affected columns are: {info.missing.join(", ")}. <Link to="/library"><i>Go to Insights</i></Link> to perform a quick fix on the Insight.
                    </div>
                </div>
            )
        }

        let queryParams = new URLSearchParams(this.props.location.search)
        let dashboard = queryParams.get("dId")
        let rowId = queryParams.get("rowId")
        let home = queryParams.get("home") ?? 0
        let table = queryParams.get("table")
        let widgetClicked = null;
        let tableRowClicked = null;
        
        if(dashboard && rowId && this.props.dashboardData && this.props.dashboardData.get(dashboard)){
            if(table == 0){
                let widgets = this.props.dashboardData.get(dashboard).widget_data
                for(let row = 0; row < widgets.length; row++ ){
                    widgetClicked = widgets[row].widgets.find(x => x.row_id == rowId)
                }
            }
            let rows = JSON.parse(this.props.dashboardData.get(dashboard).dashboard_data.rows)
            for(let row = 0; row < rows.length; row++ ){
                if(rows[row].row_id == rowId){
                    tableRowClicked = rows[row];
                    break;
                }
            }
        }

        // let favourites = this.props.favourites.get("data", [])
        let reportdata = this.getReportData()
        let report = reportdata.report
        let reportinfo = reportdata.info
        let fromNow = (time) => {
            return moment.unix(time).fromNow();
        }
        let idColumn = getIdColumn(reportinfo.model ? reportinfo.model.columns : [])
        const reportColumns = JSON.parse(report.columns)
        let reportFilters = JSON.parse(report.filter)
        //Put the id column in the end.
        reportFilters.sort((a, b) => (a.column === idColumn) - (b.column === idColumn))

        // const followsReport = !!this.props.followings.find(f => f.item_type === "report" && f.item_id === id)

        let getEditButton = () => {
            if(dashboard && rowId){ //click from dashboard. No thank you to edit-mode!
                return null
            }

            return (
                <div className="d-flex flex-row">
                    <div className="btn-group inline-block">
                        {
                            this.state.editMode ?
                                <React.Fragment>
                                    <button type="button"
                                        disabled={this.state.disableSave}
                                        title={this.getDisabledText()}
                                        className="btn btn-danger btn-sm"
                                        onClick={() => this.promptExitEditMode()} >Edit mode: ON <i className="fa fa-cog"></i>
                                    </button>
                                    <button disabled={this.state.disableSave} type="button" className="btn btn-danger btn-sm dropdown-toggle margin-right-10px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                </React.Fragment>
                                : <React.Fragment>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.enterEditMode()}><i className="fa fa-cog"></i></button>
                                    <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                </React.Fragment>
                        }
                        <ul className="dropdown-menu dropdown-menu-right">
                            {/* <ShowIf if={!followsReport}>
                                <li className="abc-click" onClick={this.followReport(true)}><span>Follow</span></li>
                            </ShowIf>
                            <ShowIf if={followsReport}>
                                <li className="abc-click" onClick={this.followReport(false)}><span>Unfollow</span></li>
                            </ShowIf> */}
                            <li className="abc-click" onClick={() => this.setState({ aboutToCopy: true, newReportAddToFavorites: false, newReportName: report.name + " copy", newReportDescription: report.description })}><span>Copy this Insight</span></li>
                            <li className="abc-click" onClick={() => { if (!this.state.editMode) { this.enterEditMode() } else { this.promptExitEditMode() }; }}><span>Edit mode</span></li>
                            <li className="abc-click" onClick={() => { this.setState({ deleteWarning: true }); this.props.dispatch(canDeleteReport(id)) }}><span>Delete</span></li>
                        </ul>
                    </div>
                    {
                        this.state.deleteWarning ?
                            <div className="d-flex flew-row pl-2">
                                <div className="alert-danger form-control-sm danger-fix" role="alert">Are you sure you want to delete the "{report.name}"?</div>
                                <div><button className="btn btn-sm btn-danger ml-2" onClick={() => this.deleteReport()}>Delete</button></div>
                                <div><button className="btn btn-sm btn-default ml-2" onClick={() => this.setState({ deleteWarning: false })}>Cancel</button></div>
                            </div>
                            : null
                    }
                </div>
            )
        }

        let hasReportChanged = () => {
            return JSON.stringify(reportdata) != JSON.stringify(this.props.info)
        }

        let disableSaveButton = () => {
            return !hasReportChanged() || this.state.disableSave
        }

        let getReportClass = () => {
            if (this.state.editMode) {
                return 'report-settings-compose'
            } else {
                return 'report-settings'
            }
        }

        const isConsultantPrivate = () => {
            if (!this.props.users) {
                return false
            }

            const owner = this.props.users.find(u => u.id === report.user_id);
            if (!owner) {
                return false
            }

            return owner.is_consultant && !report.is_public;
        }
        
        let clickableCategories = this.getClickableCategories()

        return (
            <div>
                <Prompt
                    when={this.reportChanged()}
                    message={
                        //only prompt if destination is outside current report
                        location =>
                            location.pathname.match(new RegExp("/report/" + id)) ? true : "You have unsaved changes! Save before you leave this page."
                    }
                />
                <Compose
                    ref="compose"
                    show={this.state.editMode}
                    users={this.props.users}
                    reportdata={reportdata}
                    changeView={this.changeView}
                    disableSave={(bool) => this.disableSave(bool)}
                    isSaveDisabled={this.state.disableSave}
                    fixedSlicerColumns={this.getFixedSlicerColumns()}
                    setData={this.setCompose}
                    getData={this.getCompose}
                    checkReportColumns={() => this.checkReportColumns()}
                    save={this.save}
                    setReportInfo={this.setReportInfo}
                    promptSetBucket={this.promptSetBucket}
                    promptUnselectColumns={this.promptUnselectColumns}
                    setReport={this.setReport}
                />
                <div className={`${getReportClass()}`}>
                    <div className="inline-block width-100-p margin-bottom-10px">
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <div className='d-flex flex-row align-items-end mb-2'>
                                <div>
                                    <ShowIf if={widgetClicked || tableRowClicked}>
                                        <div className='report-widget-title'>
                                            <ShowIf if={home == 1}>
                                                <Link to="/" className="btn btn-default btn-xs">
                                                    <i className="fa fa-arrow-left"></i> Back
                                                </Link>
                                            </ShowIf>
                                            <ShowIf if={home == 0}>
                                                <Link to={`/dashboard/${dashboard}`} className="btn btn-default btn-xs">
                                                    <i className="fa fa-arrow-left"></i> Back
                                                </Link>
                                            </ShowIf>
                                            {widgetClicked ? (widgetClicked.label == "" ? widgetClicked.column + " - " + widgetClicked.report_name : widgetClicked.label ) : ""}
                                            {tableRowClicked && !widgetClicked ? tableRowClicked.row.label : "" } 
                                            <ShowIf if={isConsultantPrivate()}>
                                                <span className='private-report-label' style={{marginLeft:5}}>
                                                    <img src='img/report-private.svg' alt=""/> 
                                                    <b>Consultant</b> Private
                                                </span>
                                            </ShowIf>
                                            <ShowIf if={!report.is_public && !isConsultantPrivate()}>
                                                <span className='private-report-label' style={{marginLeft:5}}>
                                                    <img src='img/report-private.svg' alt=""/> 
                                                    Private
                                                </span>
                                            </ShowIf>
                                            <ShowIf if={report.is_public}>
                                                <span className='private-report-label' style={{marginLeft:5}}>
                                                <img src='img/report.svg' alt=""/> 
                                                    Public
                                                </span>
                                            </ShowIf>
                                        </div>
                                    </ShowIf>
                                    <ShowIf if={!widgetClicked && !tableRowClicked && isConsultantPrivate()}>
                                        <div style={{position:"relative", height:0}}>
                                            <div style={{position:"absolute", bottom:0}}>
                                                <span className='private-report-label'>
                                                    <img src='img/report-private.svg' alt=""/> 
                                                    <b>Consultant</b> Private
                                                </span>
                                            </div>
                                        </div>
                                    </ShowIf>
                                    <ShowIf if={!widgetClicked && !tableRowClicked && !report.is_public && !isConsultantPrivate()}>
                                        <div style={{position:"relative", height:0}}>
                                            <div style={{position:"absolute", bottom:0}}>
                                                <span className='private-report-label'>
                                                    <img src='img/report-private.svg' alt=""/> 
                                                    Private
                                                </span>
                                            </div>
                                        </div>
                                    </ShowIf>
                                    <ShowIf if={!widgetClicked && !tableRowClicked && report.is_public}>
                                        <div style={{position:"relative", height:0}}>
                                            <div style={{position:"absolute", bottom:0}}>
                                                <span className='private-report-label'>
                                                    <img src='img/report.svg' alt=""/> 
                                                    Public
                                                </span>
                                            </div>
                                        </div>
                                    </ShowIf>
                                    <ShowIf if={this.state.editMode}>
                                        <h1 className='mb-0 abc-click'  onClick={() => this.refs.titleAndDescriptionDialog.show()} >
                                            <span className="white-space-nowrap" title={report.name}>{report.name.length > 60 ? report.name.slice(0,57) + '...' : report.name}</span>
                                            <i className='fa fa-edit padding-left-10px titleEditIcon'></i>
                                        </h1>
                                    </ShowIf>
                                    <ShowIf if={!this.state.editMode}>
                                        <h1 className='mb-0'><span className="white-space-nowrap" title={report.name}>{report.name.length > 60 ? report.name.slice(0,57) + '...' : report.name}</span></h1>
                                    </ShowIf>
                                </div>
                                <div className='d-flex flex-row padding-bottom-3px'>
                                    <div><i className="fa fa-info-circle margin-left-10px glyphicon-info-gray abc-click" onClick={() => this.setState({showDataDescription: true})} ></i></div>
                                    
                                    {/* <div><i onClick={() => this.toggleFav()} className={`${getStarIcon()} abc-click margin-left-10px font-size-135p glyphicon-info-gray`} /></div> */}
                                </div>
                                <div className='padding-bottom-3px ml-2' style={{display: "flex"}}>
                                <ResponsiveToggle style={{marginRight: 5}} onChange={() => {this.toggleFav()}} checked={this.state.isFavourite} label='Add' responsiveLabel='Added to Home' title={this.state.isFavourite ? "Remove from Home" : "Add to Home"}>
                                        <HomeIcon width={10} height={10} add={!this.state.isFavourite}/>
                                </ResponsiveToggle>
                                <ShowIf if={ItemTooOld(reportinfo.last_update)} >
                                    <button className="btn btn-xs btn-danger vertical-align-text-bottom" onClick={() => this.refs.itemTooOldDialog.show()}>
                                        <img className="refresh_data_image" alt="Data" src="./img/data_refresh_white.svg" />
                                            Updated {fromNow(reportinfo.last_update)}
                                    </button>
                                </ShowIf>
                                <ShowIf if={!ItemTooOld(reportinfo.last_update)} >
                                    <button className="btn btn-xs btn-default vertical-align-text-bottom disabled">
                                        <img className="refresh_data_image" alt="Data" src="./img/data_refresh_grey.svg" />
                                            Updated {fromNow(reportinfo.last_update)}
                                    </button>
                                </ShowIf>
                                </div>
                            </div>
                            <div className='d-flex flex-row'>
                                <ShowIf if={this.state.editMode}>
                                    <button disabled={disableSaveButton()} onClick={() => {this.save()}} style={{marginRight: 8}} className="btn btn-sm btn-primary">Save</button>
                                </ShowIf>
                                <div><button title="Share this view" type="button" className="btn btn-default btn-sm mr-2" onClick={() => this.copyLinkToClipboard()}><i className="fa fa-link" /></button></div>
                                <RequirePermission perms={is.reportCreateOnly} and={isReportCreateOnly(this.props.userPermissions)} andUser={report.user_id} >
                                    <div>
                                        {getEditButton()}
                                    </div>
                                </RequirePermission>
                                <RequirePermission perms={is.reportAdmin} or={report.is_public} >
                                    <div>
                                        {getEditButton()}
                                    </div>
                                </RequirePermission>
                            </div>
                        </div>
                        <Slicer2
                            idSearchSlicer={this.state.idSearchSlicer}
                            onUpdateSearchInput={(v, c) => this.searchInputChanged(v, c)}
                            reportData={this.getReportData()}
                            slicers={this.getSlicers(false)}
                            addSlicer={(slicer) => this.addSlicer(slicer)}
                            removeSlicer={(slicer) => this.removeSlicer(slicer)}
                            updateSlicer={(oldSlicer, newSlicer) => this.updateSlicer(oldSlicer, newSlicer)}
                            limit={this.state.clickReportLimit}
                            clickReport={this.state.clickReport}
                            editMode={this.state.editMode}
                            fixedSlicerColumns={this.getFixedSlicerColumns()}
                            removeFixedSlicer={c => this.removeFixedSlicer(c)}
                            addFixedSlicer={c => this.addFixedSlicer(c)}
                            moveFixedSlicer={this.moveFixedSlicer}
                            warnSlicerRemoval={() => this.refs.warnDeleteSlicerDialog.show()}
                            clickableCategories={this.state.editMode ? reportdata.info.model.categorization.map(c => c.name) : clickableCategories}
                        />
                        {  //Overvej at bruge ShowIf komponentet
                            this.props.info ? (
                                <React.Fragment>
                                    <Scrollbar>
                                        <ReportContent
                                            settingsParent={this}
                                            changeView={this.changeView}
                                            section={this.getSection()}
                                            reportdata={reportdata}
                                            slicers={this.getSlicers()}
                                            clickReport={this.state.clickReport}
                                            hideRow={(bId, id) => this.hideMultipleRows([id])}
                                            hideMultipleRows={this.hideMultipleRows}
                                            addClickSlicer={(slicer, clickReport, limit) => this.addClickSlicer(slicer, clickReport, limit)}
                                            removeClickSlicer={() => this.removeClickSlicer()}
                                            clickedCategory={this.state.clickedCategory}
                                            clickedColumn={this.state.clickedColumn}
                                            limit={this.state.clickReportLimit}
                                            editMode={this.state.editMode}
                                            setShownGridColumns={this.setShownGridColumns}
                                            shownGridColumns={this.state.editMode ? this.getViewSettingColumns().columns : this.state.shownGridColumns}
                                            setData={this.setCompose}
                                            getCommentContextPath={this.getCommentContextPath}
                                            makeReportPublic={this.makeReportPublic}
                                            clickableCategories={clickableCategories}
                                            reportID={id}
                                            ref={this.reportContentRef}
                                            fixedSlicerColumns={this.getFixedSlicerColumns()}
                                            columnDescriptions={reportinfo && reportinfo.setup ? reportinfo.setup.descriptions : []}

                                            disableSave={this.state.disableSave}
                                            getDisabledText={this.getDisabledText}
                                            promptExitEditMode={this.promptExitEditMode}
                                            enterEditMode={this.enterEditMode}
                                            getData={this.getCompose}
                                            saveButtonDisabled={disableSaveButton()}
                                            saveSetup={() => {this.save()}}
                                        />
                                    </Scrollbar>
                                </React.Fragment>
                            ) : null}
                        <LinkDialog title="Share this view" description="Copy a link to this view, complete with the current slicing and settings." ref="linkDialog" />
                        <TitleAndDescriptionEditDialog title="Edit title and description" saveSetup={() => {this.save()}} saveButtonDisabled={disableSaveButton()} ref="titleAndDescriptionDialog" setData={this.setCompose} getData={this.getCompose} favorited={this.state.isFavourite} setFav={this.setFav} />

                        <WarningAcceptDialog ref="warnDeleteSlicerDialog" title="Delete filter" accept={() => this.refs.slicers.removeSlicer()} >
                            <p>Are you sure that you want to delete this {this.state.editMode ? "report filter" : "slicer"}?</p>
                        </WarningAcceptDialog>
                        <WarningSaveDialog ref="warnUnsavedChangesDialog" acceptText={"Exit without save"} saveReport={() => { this.save(); this.exitEditMode() }} title="Unsaved changes" accept={() => this.exitEditMode()}>
                            <p>You have unsaved changes, are you sure you want to exit?</p>
                        </WarningSaveDialog>
                        <DataDescriptionDialog2 
                            show={this.state.showDataDescription} 
                            onClose={() => this.setState({showDataDescription: false})} 
                            title="About this Insight" 
                            filter={reportFilters} 
                            appliedSlicers={this.getSlicers(false)}
                            columns={reportColumns} 
                            showColumnDescription={false} 
                            columnDescription={reportinfo.setup.descriptions}
                            description={report.description} 
                            filterDescription={getDataDescriptionDialogDescription("Insight")(report.limit, report.sort_direction, report.sort_column, reportFilters.length)} 
                            datasourceName={this.props.info.info.name}
                        />
                        <DescriptionDialog ref="noKPIDialog" title={"No trends available"}>
                            <p>Contact your admin, or edit the Insight setup to view trends & KPIs.</p>
                        </DescriptionDialog>
                        <ReportDataIssueDialog ref="setBucketDialog" title={"Remove Insight settings"} accept={b => this.refs.compose.setDataTabBucket(b)}>
                            <p>You are about to change the bucket. Doing so will remove columns that are used elsewhere in the Insight. To continue, the settings using the columns to be removed will also be removed. Below is an overview of which settings have which conflicting columns. Do you want to proceed?</p>
                        </ReportDataIssueDialog>
                        <ReportUnselectColumnsDialog ref="unselectColumnsDialog" accept={cb => cb()} />
                        <WarningAcceptDialog ref="warnDeleteReportDialog" title="Problem deleting Insight" accept={() => this.deleteReport()} acceptText={"Delete Insight"} closeHandler={() => this.setState({ deleteWarning: false })}>
                            <p>You are about to delete a Insight that is used in one or more Dashboards. Deleting this Insight will remove it from all affected Dashboards.</p>
                            <p><b>{this.props.reportsInDashboards.get(id, []).length}</b> Dashboard{this.props.reportsInDashboards.get(id, []).length === 1 ? " is" : "s are"}:</p>
                            <ul>
                                {
                                    this.props.reportsInDashboards.get(id, []).map((r, i) => {
                                        return <li key={i}>{r.title}</li>
                                    })
                                }
                            </ul>
                        </WarningAcceptDialog>
                        <WarningDescriptionDialog ref="itemTooOldDialog" title="Data is not up to date" okText="I understand">
                            <p>
                                Be aware that the data, you are browsing has not been updated since
                                <br />
                                {formatDateTime(reportinfo.last_update)} ({fromNow(reportinfo.last_update)})
                            </p>
                            <p> 
                                Contact the responsible person for this or contact Inact for help. 
                                <br />
                                <a href="mailto:support@inact.io">support@inact.io</a>
                            </p>
                        </WarningDescriptionDialog>
                        <SaveReportDialog ref="saveReportDialog" title="Save this Insight as" disableBackdrop={true}
                            closeHandler={() => {console.log("close clicked"); this.setState({ aboutToCopy: false })}} 
                            saveHandler={() => {console.log("save clicked"); this.copyReport()}} 
                            updateNewReportName={(value) => this.setState({ newReportName: value })} 
                            updateNewReportDescription={(value) => this.setState({newReportDescription: value})}
                            updateNewReportAddToFavorites={(value) => this.setState({newReportAddToFavorites: value})}
                            newReportName={this.state.newReportName}
                            newReportDescription={this.state.newReportDescription}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class TitleAndDescriptionEditDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tmpFav: false
        }
    }

    show() {
        this.refs.editDialog.show()
        this.setState({tmpFav: this.props.favorited})
    }

    hide() {
        this.refs.editDialog.hide()
    }

    save = () => {
        if(this.props.favorited != this.state.tmpFav){
            this.props.setFav(this.state.tmpFav); 
        }
        if(!this.props.saveButtonDisabled){
            this.props.saveSetup(); 
        }
        this.hide();
    }
 
    render() {
        let name = this.props.getData("report", "name")
        let description = this.props.getData("report", "description")
     
        return (
            <SaveDialog ref="editDialog" title={this.props.title} closeHandler={() => this.hide()} saveHandler={() => {this.save()}} disableSave={(this.props.saveButtonDisabled && (this.props.favorited == this.state.tmpFav)) || name ===""}>
                <input type="text" placeholder="Add title" className="form-control" value={name} onChange={(e) => this.props.setData("report", "name", e.target.value)} />
                <textarea placeholder="Add description" className="width-100-p height-220px textarea-padding no-resize margin-top-10px textarea-border" value={description} onChange={(e) => this.props.setData("report", "description", e.target.value)} ></textarea>
                <label style={{marginTop: 5}}>
                    <input type="checkbox" checked={this.state.tmpFav} onChange={() => this.setState({tmpFav: !this.state.tmpFav})} style={{marginRight: 5}}/>
                    Add to homepage
                </label>
                
            </SaveDialog>)
    }
}

const UseExactPath = (query) => getURLQueryParameter(query, "exact", false, { parseBooleans: true })

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (JSON.stringify(curState.slicers) !== JSON.stringify(prevState.slicers) ||
        JSON.stringify(curState.fixedSlicerColumns) !== JSON.stringify(prevState.fixedSlicerColumns) ||
        curState.clickReport !== prevState.clickReport ||
        curState.clickedCategory !== prevState.clickedCategory ||
        curState.clickedColumn !== prevState.clickedColumn ||
        curState.clickReportLimit !== prevState.clickReportLimit ||
        (JSON.stringify(prevState.shownGridColumns) !== JSON.stringify(curState.shownGridColumns) && JSON.stringify(prevState.shownGridColumns) !== '[]') ||
        curState.editMode !== prevState.editMode ||
        prevState.clickSlicerReturn !== curState.clickSlicerReturn)
}

const getSettingsKeys = (state) => {
    let { isFavourite, selected, composeData, isComposeDataReady, ...settings } = state
    return Object.keys(settings)
}

const isSilentUpdate = (prevProps, prevState, snapshot, _this) => {
    let viewSettings = _this.props.info ? JSON.parse(_this.props.info.report.view_settings) : {}
    let prevViewSettings = prevProps.info ? JSON.parse(prevProps.info.report.view_settings) : {}

    return _this.state.clickReport !== prevState.clickReport || //if clickreport removed or added
        (_this.state.shownGridColumns.length === 0 ||
            (JSON.stringify(viewSettings.detailed_report_columns) !== JSON.stringify(prevViewSettings.detailed_report_columns) &&
                viewSettings.detailed_report_columns &&
                prevViewSettings.detailed_report_columns)
        ) //If there's no column setup or if the column setup in view_Settings changed
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => {
    if (_this.state.clickReport && !prevSettings.clickReport) {
        _this.changeView("grid", undefined, true)
    }

    if (!_this.state.clickReport && prevSettings.clickReport) {
        if (_this.state.clickSlicerReturn) {
            _this.changeView(_this.state.clickSlicerReturn, true)
        } else {
            _this.changeView('overview', true)
        }
    }

    if (_this.props.jumpToCompose && _this.props.info?.report?.report_id === _this.props.jumpToCompose && _this.currentSettingsHash) {
        _this.props.dispatch(removeJumpToCompose())
        _this.setState({ editMode: true }, () => {
            if (_this?.refs?.compose) {
                _this.refs.compose.show(() => {
                    if (_this?.refs?.slicers) {
                        _this.refs.slicers.show()
                    }
                })
            }
        })
    }

    if(_this.state.composeData && _this.props.info?.report?.report_id){
        let reportdata = _this.props.info
        if(!getSetting(`report-${reportdata.report.report_id}-compose-loaded`)){
            putSetting(`report-${reportdata.report.report_id}-compose-loaded`, true)
        }
    }
}

const getSessionStoreKey = _this => {
    return "report-" + _this.props.match.params.repId
}

const ComponentDidChangeURL = _this => {
    if (_this.props.info) {
        let reportdata = _this.props.info
        if (!getSetting(`has-report-${reportdata.report.report_id}-redirected`)) {
            let viewSettings = JSON.parse(reportdata.report.view_settings)
            if (viewSettings && viewSettings.settings && viewSettings.settings.default_view) {
                putSetting(`has-report-${reportdata.report.report_id}-redirected`, true)
                if (!UseExactPath(_this.props.location.search)) {
                    
                    let defView = viewSettings.settings.default_view
                    if (viewSettings.settings[defView]) { //view is active
                        _this.changeView(viewSettings.settings.default_view, true)
                    }
                    else { //go to first enabled view
                        let settingKeys =Object.keys(viewSettings.settings)
                        for(let i=0; i < settingKeys.length; i++){
                            if(viewSettings.settings[settingKeys[i]] === true){ //'default_view' is a key.
                                _this.changeView(settingKeys[i], true)
                            }
                        }
                    }
                }
            }
        }
    }
}

const ShouldApplySessionSettings = _this => {
    return !UseExactPath(_this.props.location.search)
}

Report = withSettingsStorage(Report, getSettingsKeys, shouldComponentUpdateSettings, isSilentUpdate, settingsDidApply, getSessionStoreKey, ComponentDidChangeURL, ShouldApplySessionSettings, "report", { withURLBacktrack: true, withSessionStore: true })

Report = connect(mapStateToProps)(Report)

Report = withRouter(Report)

export default Report
