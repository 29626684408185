import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getVersion = () => {
    return packAction(ActionTypes.GET_VERSION)
}

export const gotVersion = version => {
    return packAction(ActionTypes.GET_VERSION_DONE, version)
}

export const getUsers = users => {
    return packAction(ActionTypes.GET_ORG_USERS)
}

export const gotUsers = users => {
    return packAction(ActionTypes.GET_ORG_USERS_DONE, users)
}

export const generalApiError = () => {
    return packAction(ActionTypes.GENERAL_API_ERROR)
}

export const reportError = (info, type, notificationId) => {
    return packAction(ActionTypes.REPORT_ERROR, {info, type, notificationId})
}

export const commentReportError = (errorReportId, comment) => {
    return packAction(ActionTypes.COMMENT_ERROR_REPORT, {id: errorReportId, comment: comment})
}