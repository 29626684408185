import React from 'react'

import BaseDialog from './BaseDialog'

class WarningSaveDialog extends BaseDialog {

	closeDialog = () => {
		if(this.props.closeHandler)
			this.props.closeHandler();
		this.hide(); //base class
	}

	saveDialog = () => {
		if(this.props.saveReport)
			this.props.saveReport();
		this.hide(); //base class
	}


	acceptDialog = () => {
		if(this.props.accept)
			this.props.accept();
		this.hide(); //base class
	}


	getButtons() {
		let acceptText = this.props.acceptText ? this.props.acceptText : 'Accept'
		return (
			<div>
				<button onClick={this.closeDialog} type="button" className="btn btn-default float-left margin-right-10px">Cancel</button>
				<button onClick={this.saveDialog} type="button" className="btn btn-primary margin-right-10px">Save</button>                
				<button onClick={this.acceptDialog} type="button" className="btn btn-danger">{acceptText}</button>
			</div>
		)
	}
}

export default WarningSaveDialog