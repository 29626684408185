import React from 'react'
import DataGrid from '../DataGrid'
import { deepClone, copyToClipboard, getTodaysDate } from '../../helpers/GeneralHelpers'
import ShowIf from '../Generic/ShowIf'
import { showMasterDataDialog } from '../../helpers/MasterDataManager'
import InfiniteScrollOnScreen from '../../components/Generic/InfiniteScrollOnScreen'
import { notifySuccess } from '../../helpers/NotificationManager'
import ColumnSelectionDialog from '../Dialogs/ColumnSelectionDialog'
import { downloadTransactionData } from '../../actions/ItemActions'

import { getIdColumn, slicerEquals, isDateColumn } from '../../helpers/ReportHelpers'

import AddSlicerDialog2 from '../Dialogs/AddSlicerDialog2'
import SingleSlicer2 from '../Report/SlicerComponents/SingleSlicer2'
import { CompareType } from '../../types/slicertypes'


class SingleItemDrilldown extends React.Component {
    state = {
        sortColumn: "",
        sortDirection: false,
        offset: 100,
        hasMoreData: true,

        slicerValues: [],

        addSlicerSearchStr: "",
        selectedColumn: null,
        selectedValues: [],
        edittedSlicer: null,
        compare_type: 1,
        textSlicerSearch: '',
        slicerId: 0,
        slicers: [],
        tmpRemoveSlicer: {},
        wrapHeaderText: true,

        slicerToEdit: undefined,
        showEditSlicer: false,
        addingSlicer: false,
    }
    
    dataGrid = React.createRef()
    addSlicerRef = React.createRef()

    componentDidMount() {
        if (this.props !== undefined && this.props.data !== undefined ){
            this.setState({
                offset: 100,
                hasMoreData: 100 < this.props.data.data.length
            })
        }
    }

    arrayContains(value, array){
        for(let i = 0; i < array.length; i++){
            if(array[i] === value)
                return true
        }
        return false
    }

    arraysEquals(first, second) {
        if(first.length !== second.length)
            return false
        for(let i = 0; i < first.length; i++){
            if(first[i] !== second[i])
                return false
        }
        return true
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.data === undefined) {
            //no data - skipping
            return
        }

        if(this.props.visibleColumns.length === 0) { 
            let bucketIdColumnName = getIdColumn(this.props.bucket.model.columns)
            let allVisibleColumns = deepClone(this.props.data.columns.filter(c => c.name !== bucketIdColumnName))
            this.props.onChangeVisibleColumns(allVisibleColumns)
        }
        
        if(this.props.itemId !== prevProps.itemId || 
            this.props.bucketId !== prevProps.bucketId ||
            !this.arraysEquals(this.props.aggregationColumns, prevProps.aggregationColumns)
            ) {

            this.setState({
                offset: 100,
                hasMoreData: 100 < this.props.data.data.length
            })
        }
    }

    setSortColumn = (column, direction) => {
        this.setState({
            sortColumn: column.name,
            sortDirection: direction,
        })
    }

    openClicked = (id, row, linkedBucket, index) => {
        this.props.onChangeColumns([])
        this.setSortColumn("", false)
        showMasterDataDialog(linkedBucket, row[index], true)
    }

    fetchMoreData = () => {
        this.setState({
            offset: this.state.offset + 100,
            hasMoreData: this.state.offset + 100 < this.props.data.data.length
        })
    };


    setColumn = (column, index) => {
        let newcols = deepClone(this.props.aggregationColumns)
        if(column === "") //remove from array
        {
            newcols = []
            for(let i = 0; i <  this.props.aggregationColumns.length; i++){
                if(i !== index)
                    newcols.push(this.props.aggregationColumns[i])
            }
        }
        else{
            if(index >= this.props.aggregationColumns.length){ // add to end
                newcols.push(column)
            }
            else { //replace
                newcols[index] = column
            }
        }
        this.props.onChangeColumns(newcols)
    }

    resetSettings = () => {
        this.props.onChangeColumns([])
        this.props.onDecimalsChange(false)
        
        if(this.props.drilldownSlicers.length > 0)
            this.props.onDrilldownSlicersChange([])
    }

    downloadGridDataExcel = () => {
        this.downloadGridDataCommon('excel')
    }

    downloadGridData = () => {
        this.downloadGridDataCommon('csv')
    }

    downloadGridDataCommon(type) {
        const { dispatch } = this.props

        const shownColumns = this.props.visibleColumns.map(c => c.name)//this.props.data.columns.map(c => c.name)
       
        let sortcolumn = this.state.sortColumn.replace(" (count)", "").replace(" (sum)", "")

        const data = {
            name: "Transactions " + this.props.itemId,
            type: type,
            aggregated_columns: this.props.aggregationColumns,
            sort_column: sortcolumn,
            sort_direction: this.state.sortDirection ? "DESC" : "ASC",
            column_filter: shownColumns,
            drilldown_slicers: this.props.drilldownSlicers
        }

        notifySuccess("Your data is being prepared for download - you will get a notification when it is ready!")
        dispatch(downloadTransactionData(this.props.bucketId, this.props.itemId, data))
    }

    setDecimals(bool) {
        this.props.onDecimalsChange(bool)
    }

    openColumnSelection() {
        let bucketIdColumnName = getIdColumn(this.props.bucket.model.columns)

        this.refs.columnSelect.show(this.props.visibleColumns, 
            deepClone(this.props.data.columns.filter(c => c.name !== bucketIdColumnName))
       )
    }

    saveShownColumns = (sc) => {
        this.props.onChangeVisibleColumns(sc)
    }

    getSlicerColumns = () => {
        let bucketIdColumnName = getIdColumn(this.props.bucket.model.columns)

        let columns =  deepClone(this.props.data.columns.filter(c => c.name !== bucketIdColumnName))
        columns.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
        return columns
    }

    isSlicerOpen = () => {
        return this.state.showEditSlicer
    }

    openSlicer = (slicer) => {
        this.setState({ selectedColumn: slicer.column, compare_type: slicer.compare_type, selectedValues: slicer.target_values, edittedSlicer: Object.assign({}, slicer), addingSlicer: false })
        this.setState({slicerToEdit: slicer, showEditSlicer: true})
    }

    cancelSlicer() {
        let columns = this.getSlicerColumns()
        this.setState({ selectedColumn: columns[0], compare_type: "1", selectedValues: [], edittedSlicer: null, addingSlicer: false })
    }

    columnClick = (column, adding = false) => {
        let slicerColumns = this.getSlicerColumns()

        let mockupBucket =  {
            categorization: [],
            categorization_name: "",
            columns: slicerColumns,
            formula_columns: [],
        }

        let slicers = this.state.slicers
        let target_values = []
        if (isDateColumn(column.name, mockupBucket)) {
            target_values = [getTodaysDate()]
        }
        if(slicers.length > 0 && !adding) {
            let slicer = slicers.find(s => s.column === column.name)

            if (slicer){
                this.openSlicer(slicer)
            } else {
                this.setState({addingSlicer: true, slicerToEdit: {column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ }, showEditSlicer: true})
            }
        } else {
            this.setState({addingSlicer: true, slicerToEdit: {column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ }, showEditSlicer: true})
        }
    }
    


    saveSlicer = (slicer) => {
        const index = this.props.drilldownSlicers.findIndex(s => s.column === slicer.column)
        if (index < 0) {
            this.addSlicer(slicer)
        } else {
            this.updateSlicer(this.props.drilldownSlicers[index], slicer)
        }
        if (this.state.addingSlicer) {
            this.addSlicer(slicer)
        } else {
            if (this.state.slicerToEdit == undefined){
                this.addSlicer(slicer)
            } else {
                const index = this.props.drilldownSlicers.findIndex(s => slicerEquals(s, this.state.slicerToEdit))
                if (index < 0) {
                    this.addSlicer(slicer)
                } else {
                    this.updateSlicer(this.props.drilldownSlicers[index], slicer)
                }
            }
        }
        this.setState({slicerToEdit: undefined, showEditSlicer: false, addingSlicer: false})
    }
    addSlicer(slicer) {
        if (slicer.target_values.length == 0) return
        let slicers = this.props.drilldownSlicers.slice()
        let newSlicersArr = [slicer].concat(slicers)
        this.props.onDrilldownSlicersChange(newSlicersArr)
    }

    removeSlicer(slicer) {
        let slicers = this.props.drilldownSlicers.slice()
        let index = slicers.findIndex(s => slicerEquals(s, slicer))
        if (index >= 0) {
            slicers.splice(index, 1)
            this.props.onDrilldownSlicersChange(slicers)
        }
    }

    updateSlicer(oldSlicer, newSlicer) {
        let slicers = this.props.drilldownSlicers.slice()
        let index = slicers.findIndex(s => oldSlicer.column === s.column)
        if (index >= 0) {
            if (newSlicer.target_values.length > 0) {
                slicers[index] = Object.assign({}, oldSlicer, newSlicer)
            } else {
                this.removeSlicer(oldSlicer)
            }
        } else {
            slicers.push(newSlicer)
        }
        
        this.props.onDrilldownSlicersChange(slicers)
    }

    render() {

        let { data, aggregationColumns } = this.props
        if (!data) return null

        let columns = deepClone(data.columns)

        let rows = data.data
        let aggregationFunctions = data.aggregation_functions

        const linkedBucketExists = this.props.buckets.keys().some(id => id === data.linked_bucket)

        const linkedBucketColumnIndex = columns.findIndex(c => c.name === data.linked_bucket_column)

        //add data index to columns
        columns = columns.map((c, i) => {
            //if the columns has been counted or summed, the post aggregation type is decimal. If counting of a text columns was omitted, then it has its original type (text).
            if (rows[0] && typeof rows[0][i] === "number") c.postAggregationType = "decimal"
            else if (rows[0] && typeof rows[0][i] === "string") c.postAggregationType = "text"
            else c.postAggregationType = c.type
            c.index = i
            c.newIndex = this.props.visibleColumns.findIndex(_c => _c.name === c.name);
            return c
        })

        //find id column from bucket
        let bucketIdColumnName = getIdColumn(this.props.bucket.model.columns)
        let idColumn = columns.filter(c => c.name === bucketIdColumnName)[0]

        //columns for aggregation
        let columns_for_aggregation = deepClone(columns).filter(c => c.name !== bucketIdColumnName && c.type !== "decimal")
        columns_for_aggregation.filter(c => this.props.visibleColumns.findIndex(_c => _c.name === c.name) >= 0 || aggregationColumns.includes(c.name))
        
        //remove columns not selected
        columns = columns.filter(c => this.props.visibleColumns.findIndex(_c => _c.name === c.name) >= 0)


        //add type of aggregation to columns
        if (aggregationColumns.length > 0) {
            columns = columns.map((c, i) => {
                const aggFunc = aggregationFunctions[c.name]
                if (aggFunc != "none" && !this.arrayContains(c.name, aggregationColumns) && c.name !== bucketIdColumnName)
                    c.name += ` (${aggFunc})`
                return c
            })
        }

        //sort data rows
        if (this.state.sortColumn) {
            let column = columns.find(c => c.name === this.state.sortColumn)
            if (column) {
                rows.sort((a, b) => {
                    if (column.postAggregationType === "decimal") {
                        if (this.state.sortDirection) {
                            return b[column.index] - a[column.index]
                        } else {
                            return a[column.index] - b[column.index]
                        }
                    } else {
                        if (this.state.sortDirection) {
                            return a[column.index].localeCompare(b[column.index])
                        } else {
                            return b[column.index].localeCompare(a[column.index])
                        }
                    }
                })
            }
        }


        //remove id from data rows and columns
        columns = columns.filter(c => c.name !== bucketIdColumnName)
        
        //sort by index!
        columns.sort((a,b) => {return a.newIndex < b.newIndex ? -1 : 1 })

        rows = rows.map(r => { return { data: r, id: r[idColumn.index] } })

        //only show a portion of the data
        rows = rows.slice(0, this.state.offset);

        let getButtonColor = (bool) => {
            if (bool) {
                return 'btn-default active'
            } else {
                return 'btn-default'
            }
        }

        let copyReportDataToClipboard = () => {
            copyToClipboard(this.dataGrid.current.getHTML(false, true))
            notifySuccess(rows.length  + ' rows has been copied to the clipboard')
        }

        let slicerColumns = this.getSlicerColumns()

        let mockupBucketModel = {categorization: [],
            categorization_name: "",
            columns: slicerColumns,
            formula_columns: [] }
        return (
            <div className="container-fluid">
                <div className="margin-top-20px"></div>
                <div className="inline-block width-100-p">
                    <ShowIf if={columns_for_aggregation.length > 0}>
                        <div className="float-left margin-right-20">
                            Group by: &nbsp;
                            {aggregationColumns.map((aggregationColumn, index) => {
                                return (
                                <select key={index} className="form-control auto-width margin-right-10px" value={aggregationColumn} onChange={e => this.setColumn(e.target.value, index)}>
                                    <option value="">
                                        Remove column
                                    </option>
                                    {
                                        columns_for_aggregation.filter(c => aggregationColumns[index] === c.name).map((c, i) => <option key={i}>{c.name}</option>)
                                    }
                                </select>)
                                })
                            }

                            <ShowIf if={columns_for_aggregation.filter(c => !aggregationColumns.includes(c.name)).length > 0} >
                                <select className="form-control auto-width" value="" onChange={e => this.setColumn(e.target.value, aggregationColumns.length + 1 )}>
                                    <option hidden value="">
                                        Select column
                                    </option>
                                    {
                                        columns_for_aggregation.filter(c => !aggregationColumns.includes(c.name)).map((c, i) => <option key={i}>{c.name}</option>)
                                    }
                                </select>
                            </ShowIf>
                        </div>
                    </ShowIf>
                    <div className='float-left drilldown-slicer-container'>
                        <div style={{display:"inline-block", position:"relative", bottom:(this.props.drilldownSlicers.length > 0 ? 5: 0)}}>Slicer: &nbsp;</div>
                        <div className="dropdown inline-block" style={{bottom:(this.props.drilldownSlicers.length > 0 ? 5: 0)}}>
                            <button className="btn btn-sm btn-primary" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-plus"></i>
                            </button>
                            <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby="dropdownMenu2" style={{paddingTop:0}}>
                                <div className="text-center px-2 sticky-modal-header" style={{paddingTop:'6px'}}>
                                    <input type="text" value={this.state.addSlicerSearchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => this.setState({ addSlicerSearchStr: e.target.value })} />
                                    <div className="dropdown-divider"></div>
                                </div>
                                {
                                    slicerColumns
                                        .filter(c => this.state.addSlicerSearchStr === '' || c.name.toLowerCase().includes(this.state.addSlicerSearchStr.toLowerCase()))
                                        .map((c, index) => <button className="hover-cursor dropdown-item" type="button" onClick={() => this.columnClick(c, idColumn)} key={index}>
                                            <span>{c.name}</span>
                                        </button>)
                                }
                            </div>
                        </div>
                        {
                            this.props.drilldownSlicers.map((item, index) => {
                                return <SingleSlicer2
                                        slicer={item}
                                        index={index}
                                        key={index}
                                        openSlicer={s => this.openSlicer(s)}
                                        model={mockupBucketModel}
                                        removeSlicer={(slicer) => this.state.addingSlicer ? this.cancelSlicer() : this.removeSlicer(slicer)}
                                        idColumn={idColumn}
                                    />
                            })
                        }
                    </div>


                    <div className="float-right" style={{paddingTop: 2}}>
                        <ShowIf if={aggregationColumns.length > 0 || this.props.showDecimals || this.props.drilldownSlicers.length > 0}>
                            &nbsp;
                                <button className="btn btn-sm btn-primary" onClick={e => this.resetSettings()}>Set default</button>
                        </ShowIf>
                        <button title="Wrap text" className={`btn btn-default btn-sm margin-left-5px ${this.state.wrapHeaderText ? 'active' : ''}`} onClick={() => this.setState({ wrapHeaderText: !this.state.wrapHeaderText })}><i className="fa fa-expand"></i></button>
                        <button title="Show/hide decimals" className={`btn btn-default btn-sm margin-left-5px ${getButtonColor(this.props.showDecimals)}`} onClick={() => this.setDecimals(!this.props.showDecimals)}>0,0</button>
                        <div className="btn-group inline-block margin-left-5px">
                            <button title="Copy to clipboard" className="btn btn-default btn-sm" onClick={() => copyReportDataToClipboard()}><i className="fa fa-copy"></i></button>
                            <button title="Download" type="button" className="btn btn-default btn-sm fa fa-download" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right">
                                <ShowIf if={data.data.length * data.columns.length < 1000000}>
                                    <li className="abc-click" onClick={this.downloadGridDataExcel}><span>Download data (Excel)</span></li>
                                </ShowIf>
                                <ShowIf if={data.data.length * data.columns.length >= 1000000}>
                                    <li><span title="Data set is too big"><i>Download data (Excel)</i></span></li>
                                </ShowIf>
                                <li className="abc-click" onClick={this.downloadGridData}><span>Download data (CSV)</span></li>
                            </ul>
                        </div>
                        <button title="Edit column layout" className="btn btn-default btn-sm margin-left-5px" onClick={() => this.openColumnSelection()} ><i className="fa fa-columns"></i></button>
                    </div>
                </div>
                <span className="float-right">Showing {rows.length} out of {data.data.length}</span>

                <DataGrid
                    ref={this.dataGrid}
                    columns={columns}
                    rows={rows}
                    onSort={this.setSortColumn}
                    defaultSortColumn={this.state.sortColumn}
                    defaultSortDirection={this.state.sortDirection}
                    showDecimals={this.props.showDecimals}
                    sticky={-17}
                    wrapHeaderText={this.state.wrapHeaderText}
                    showOpenButton={(aggregationColumns.length === 0 || this.arrayContains(data.linked_bucket_column, aggregationColumns)) && data.linked_bucket_column && data.linked_bucket && linkedBucketExists}
                    openClicked={(id,row) => this.openClicked(id,row,data.linked_bucket,linkedBucketColumnIndex)}
                    disableRowSelection
                    columnDescriptions={this.props.columnDescriptions}
                />
                <InfiniteScrollOnScreen 
                    next={() => {this.fetchMoreData()}} 
                    hasMore={this.state.hasMoreData} 
                    loadingText={""}/>

                <ColumnSelectionDialog ref="columnSelect" saveHandler={this.saveShownColumns} />

                {
                    this.state.slicerToEdit != null ?
                        <AddSlicerDialog2
                            slicer={this.state.slicerToEdit}
                            slicers={this.props.drilldownSlicers}
                            show={this.state.showEditSlicer}
                            bucket={{id: this.props.data.linked_bucket, model: mockupBucketModel}}
                            textValues={this.props.data.text_values}
                            saveSlicer={(s) => this.saveSlicer(s)}
                            onCancel={() => this.setState({showEditSlicer: false, setSlicerToEdit: undefined, addingSlicer: false})}
                            columns={this.getSlicerColumns().map(e => e.name)}
                        />
                        : <></>
                }
                
            </div>
        )
    }
}

export default SingleItemDrilldown
