/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getShownColumns } from '../../helpers/ReportHelpers'

const mapStateToProps = (state, ownProps) => {
    return {}
}

class SingleItemInfoBox extends React.Component {

    constructor(props) {
        super()
        const { bucket } = props

        this.state = {
            additionalColumns: this.getOrderedColumnInfo(bucket.model.columns, bucket.setup.additional_columns),
            additionalColumns2: this.getOrderedColumnInfo(bucket.model.columns, bucket.setup.additional_columns2),
            additionalColumns3: this.getOrderedColumnInfo(bucket.model.columns, bucket.setup.additional_columns3),
            additionalColumns4: this.getOrderedColumnInfo(bucket.model.columns, bucket.setup.additional_columns4),
            addColumn: "",
        }

    }
   
    addAdditionalColumnHandler(value, e) {
        if(!value) return;
        let additionalColumns = []
        
        for (const col of this.state.additionalColumns) {
            additionalColumns.push(col.name)
         };

        additionalColumns.push(value)

        const additionalColummnsData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns);
        this.props.additionalColumnsHandler(additionalColumns, this.state.additionalColumns2.map(c => c.name), this.state.additionalColumns3.map(c => c.name), this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)

        this.setState(
            {additionalColumns: additionalColummnsData})  

        document.getElementById("addAdditionalColumn").value= ""            
    }

    editAdditionalColumnHandler(e, value, column, i) {
        e.stopPropagation()

        let additionalColumns = []
        
        for (const col of [...this.state.additionalColumns]) {
            additionalColumns.push(col.name)
         };
      
            if(!value) {
                additionalColumns.splice(i, 1)
            } else {
                additionalColumns.splice(i, 1, value)
            }
 
        this.props.additionalColumnsHandler(additionalColumns, this.state.additionalColumns2.map(c => c.name), this.state.additionalColumns3.map(c => c.name), this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)
        const columnData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns)
        this.setState(
            {additionalColumns: [...columnData] })
    }

    addAdditionalColumn2Handler(value, e) {
        if(!value) return;
        let additionalColumns = []
        
        for (const col of this.state.additionalColumns2) {
            additionalColumns.push(col.name)
         };

        additionalColumns.push(value)

        const additionalColummnsData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns);
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name), additionalColumns, this.state.additionalColumns3.map(c => c.name), this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)

        this.setState(
            {additionalColumns2: additionalColummnsData})  

        document.getElementById("addAdditionalColumn").value= ""            
    }

    editAdditionalColumn2Handler(e, value, column, i) {
        e.stopPropagation()

        let additionalColumns = []
        
        for (const col of [...this.state.additionalColumns2]) {
            additionalColumns.push(col.name)
         };
      
            if(!value) {
                additionalColumns.splice(i, 1)
            } else {
                additionalColumns.splice(i, 1, value)
            }
 
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name), additionalColumns, this.state.additionalColumns3.map(c => c.name), this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)
        const columnData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns)
        this.setState(
            {additionalColumns2: [...columnData] })
    }

    addAdditionalColumn3Handler(value, e) {
        if(!value) return;
        let additionalColumns = []
        
        for (const col of this.state.additionalColumns3) {
            additionalColumns.push(col.name)
         };

        additionalColumns.push(value)

        const additionalColummnsData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns);
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name),this.state.additionalColumns2.map(c => c.name), additionalColumns,  this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)

        this.setState(
            {additionalColumns3: additionalColummnsData})  

        document.getElementById("addAdditionalColumn").value= ""            
    }

    editAdditionalColumn3Handler(e, value, column, i) {
        e.stopPropagation()

        let additionalColumns = []
        
        for (const col of [...this.state.additionalColumns3]) {
            additionalColumns.push(col.name)
         };
      
            if(!value) {
                additionalColumns.splice(i, 1)
            } else {
                additionalColumns.splice(i, 1, value)
            }
 
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name),this.state.additionalColumns2.map(c => c.name), additionalColumns,  this.state.additionalColumns4.map(c => c.name), this.props.bucket.id)
        const columnData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns)
        this.setState(
            {additionalColumns3: [...columnData] })
    }

    addAdditionalColumn4Handler(value, e) {
        if(!value) return;
        let additionalColumns = []
        
        for (const col of this.state.additionalColumns4) {
            additionalColumns.push(col.name)
         };

        additionalColumns.push(value)

        const additionalColummnsData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns);
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name),this.state.additionalColumns2.map(c => c.name), this.state.additionalColumns3.map(c => c.name), additionalColumns, this.props.bucket.id)

        this.setState(
            {additionalColumns4: additionalColummnsData})  

        document.getElementById("addAdditionalColumn").value= ""            
    }

    editAdditionalColumn4Handler(e, value, column, i) {
        e.stopPropagation()

        let additionalColumns = []
        
        for (const col of [...this.state.additionalColumns4]) {
            additionalColumns.push(col.name)
         };
      
            if(!value) {
                additionalColumns.splice(i, 1)
            } else {
                additionalColumns.splice(i, 1, value)
            }
 
        this.props.additionalColumnsHandler( this.state.additionalColumns.map(c => c.name),this.state.additionalColumns2.map(c => c.name), this.state.additionalColumns3.map(c => c.name), additionalColumns, this.props.bucket.id)
        const columnData = this.getOrderedColumnInfo(this.props.bucket.model.columns, additionalColumns)
        this.setState(
            {additionalColumns4: [...columnData] })
    }


    getOrderedColumnInfo(modelColumns, additionalColumns) {
        let res = []
        for (const [index, ac] of additionalColumns.entries()) {
            modelColumns.forEach((mc, i) => {
                if (ac === mc.name) {
                    res.push({ name: ac, index: i, type: mc.type, key: index+ac })
                }
            })
        }
        return res
    }

    format(value, column) {
        if (column.type === 'date') {
            return moment(value).format("DD-MM-YYYY")
        } else if (column.type === 'decimal' && value !== null) {
            let decimalCount = false
            let x = parseFloat(value)
            if (isNaN(x)) {
                // console.log(value)
                return '?'
            }
            return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
        }
        return value
    }

    getUnusedColumns(allPotentialColumns){
        let result = []

        for( const potentialColumn of allPotentialColumns){
            
            let used = this.state.additionalColumns.find(x => {return x.name == potentialColumn.name}) 
            let used2 = this.state.additionalColumns2.find(x => {return x.name == potentialColumn.name}) 
            let used3 = this.state.additionalColumns3.find(x => {return x.name == potentialColumn.name}) 
            let used4 = this.state.additionalColumns4.find(x => {return x.name == potentialColumn.name}) 

            if(!used && !used2 && !used3 && !used4) {
            result.push(potentialColumn)
            }
        }
        return result
    }

    render() {
        const { bucket, data } = this.props
        if (!data) return null

        const additionals = [];
        const additionals2 = [];
        const additionals3 = [];
        const additionals4 = [];

        for (const addColumn of this.state.additionalColumns) {
            const index = bucket.model.columns.findIndex(c => c.name === addColumn.name)
            let column = bucket.model.columns[index]
      
          
            additionals.push({
                name: column.name,
                value: !!column ? this.format(data.data[index], column) : null
            })
        }

        for (const addColumn of this.state.additionalColumns2) {
            const index = bucket.model.columns.findIndex(c => c.name === addColumn.name)
            let column = bucket.model.columns[index]
      
            additionals2.push({
                name: column.name,
                value: !!column ? this.format(data.data[index], column) : null
            })
        }
        
        
        for (const addColumn of this.state.additionalColumns3) {
            const index = bucket.model.columns.findIndex(c => c.name === addColumn.name)
            let column = bucket.model.columns[index]
      
            additionals3.push({
                name: column.name,
                value: !!column ? this.format(data.data[index], column) : null
            })
        }
        
        for (const addColumn of this.state.additionalColumns4) {
            const index = bucket.model.columns.findIndex(c => c.name === addColumn.name)
            let column = bucket.model.columns[index]
      
            additionals4.push({
                name: column.name,
                value: !!column ? this.format(data.data[index], column) : null
            })
        }

        return (
            <div className="info-box-table">
             
                <div className='additional-row-container'>
                    <div className='additional-columns-container padding-right-15'>
                    { 
                    this.props.editMode ?
                    <>
                    {
                    this.state.additionalColumns.map((col, index) => 
                        <div key={col.key} className="single-item-info-additional-columns-edit">
                            <select className="form-control" defaultValue={col.name} onChange={(e => this.editAdditionalColumnHandler(e, e.target.value, col, index))}>
                            <option className="padding-4px" value={col.name}>{col.name}</option>
                            {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option key={i} className="padding-4px" value={c.name}>{c.name}</option>
                            )}
                            </select>
                            <section className="column-box-edit-btn-wrapper">
                                <button className="column-box-edit-delete btn " onClick={(e => this.editAdditionalColumnHandler(e, "", col, index))}><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </section>
                        </div>
                        
                        )
                    }
                        <div className="single-item-info-additional-columns-edit">
                            <select id="addAdditionalColumn" className="form-control italic" value="" onChange={(e => this.addAdditionalColumnHandler(e.target.value, e))}>
                                <option className="padding-4px italic" value="" >+ Add Additional Column</option>
                          {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option className="padding-4px font-style-normal" value={c.name} key={c.name}>{c.name}</option>
                            )}
                            </select>
                        </div>
                    </>
                    :
                    <table className="table">
                        <tbody>
                           { additionals.map((c, i) =>
                            <tr key={i}>
                                    <td style={{color: "gray", whiteSpace: "nowrap"}} className="padding-4px">{c.name}</td>
                                    <td className="padding-4px">{c.value}</td>
                            </tr>
                            )
                           }
                        </tbody>
                    </table>
                    }
                    </div>

                    <div className='additional-columns-container padding-right-15 padding-left-15px'>
                    { 
                    this.props.editMode ?
                    <>
                    {
                    this.state.additionalColumns2.map((col, index) => 
                        <div key={col.key} className="single-item-info-additional-columns-edit">
                            <select className="form-control" defaultValue={col.name} onChange={(e => this.editAdditionalColumn2Handler(e, e.target.value, col, index))}>
                            <option className="padding-4px" value={col.name}>{col.name}</option>
                            {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option key={i} className="padding-4px" value={c.name}>{c.name}</option>
                            )}
                            </select>
                            <section className="column-box-edit-btn-wrapper">
                                <button className="column-box-edit-delete btn " onClick={(e => this.editAdditionalColumn2Handler(e, "", col, index))}><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </section>
                        </div>
                        
                        )
                    }
                        <div className="single-item-info-additional-columns-edit">
                            <select id="addAdditionalColumn" className="form-control italic" value="" onChange={(e => this.addAdditionalColumn2Handler(e.target.value, e))}>
                                <option className="padding-4px italic" value="" >+ Add Additional Column</option>
                          {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option className="padding-4px font-style-normal" value={c.name} key={c.name}>{c.name}</option>
                            )}
                            </select>
                        </div>
                    </>
                    :
                    <table className="table">
                        <tbody>
                           { additionals2.map((c, i) =>
                            <tr key={i}>
                                    <td style={{color: "gray", whiteSpace: "nowrap"}} className="padding-4px">{c.name}</td>
                                    <td className="padding-4px">{c.value}</td>
                            </tr>
                            )
                           }
                        </tbody>
                    </table>
                    }
                    </div>

                    <div className='additional-columns-container padding-right-15 padding-left-15px'>
                    { 
                    this.props.editMode ?
                    <>
                    {
                    this.state.additionalColumns3.map((col, index) => 
                        <div key={col.key} className="single-item-info-additional-columns-edit">
                            <select className="form-control" defaultValue={col.name} onChange={(e => this.editAdditionalColumn3Handler(e, e.target.value, col, index))}>
                            <option className="padding-4px" value={col.name}>{col.name}</option>
                            {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option key={i} className="padding-4px" value={c.name}>{c.name}</option>
                            )}
                            </select>
                            <section className="column-box-edit-btn-wrapper">
                                <button className="column-box-edit-delete btn " onClick={(e => this.editAdditionalColumn3Handler(e, "", col, index))}><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </section>
                        </div>
                        
                        )
                    }
                        <div className="single-item-info-additional-columns-edit">
                            <select id="addAdditionalColumn" className="form-control italic" value="" onChange={(e => this.addAdditionalColumn3Handler(e.target.value, e))}>
                                <option className="padding-4px italic" value="" >+ Add Additional Column</option>
                          {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option className="padding-4px font-style-normal" value={c.name} key={c.name}>{c.name}</option>
                            )}
                            </select>
                        </div>
                    </>
                    :
                    <table className="table">
                        <tbody>
                           { additionals3.map((c, i) =>
                            <tr key={i}>
                                    <td style={{color: "gray", whiteSpace: "nowrap"}} className="padding-4px">{c.name}</td>
                                    <td className="padding-4px">{c.value}</td>
                            </tr>
                            )
                           }
                        </tbody>
                    </table>
                    }
                    </div>

                    <div className='additional-columns-container padding-left-15px'>
                    { 
                    this.props.editMode ?
                    <>
                    {
                    this.state.additionalColumns4.map((col, index) => 
                        <div key={col.key} className="single-item-info-additional-columns-edit">
                            <select className="form-control" defaultValue={col.name} onChange={(e => this.editAdditionalColumn4Handler(e, e.target.value, col, index))}>
                            <option className="padding-4px" value={col.name}>{col.name}</option>
                            {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option key={i} className="padding-4px" value={c.name}>{c.name}</option>
                            )}
                            </select>
                            <section className="column-box-edit-btn-wrapper">
                                <button className="column-box-edit-delete btn " onClick={(e => this.editAdditionalColumn4Handler(e, "", col, index))}><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </section>
                        </div>
                        
                        )
                    }
                        <div className="single-item-info-additional-columns-edit">
                            <select id="addAdditionalColumn" className="form-control italic" value="" onChange={(e => this.addAdditionalColumn4Handler(e.target.value, e))}>
                                <option className="padding-4px italic" value="" >+ Add Additional Column</option>
                          {this.getUnusedColumns(getShownColumns(bucket.model.columns, bucket.setup)).map((c, i) =>
                                <option className="padding-4px font-style-normal" value={c.name} key={c.name}>{c.name}</option>
                            )}
                            </select>
                        </div>
                    </>
                    :
                    <table className="table">
                        <tbody>
                           { additionals4.map((c, i) =>
                            <tr key={i}>
                                    <td style={{color: "gray", whiteSpace: "nowrap"}} className="padding-4px">{c.name}</td>
                                    <td className="padding-4px">{c.value}</td>
                            </tr>
                            )
                           }
                        </tbody>
                    </table>
                    }
                    </div>

                </div>
            </div>
        )
    }
}

SingleItemInfoBox = connect(mapStateToProps)(SingleItemInfoBox)

export default SingleItemInfoBox
