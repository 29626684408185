import React from 'react'
import NavigationBarSearch from './NavigationBarSearch'
import posed from 'react-pose'
import { connect } from 'react-redux'
import { packAction } from '../actions/ActionTypes'
import * as ActionTypes from '../actions/ActionTypes'
import { getSearchContext } from '../helpers/PathHelpers'
import RequirePermission from '../components/RequirePermission'
import { is } from '../helpers/PermissionHelpers'
import { withRouter } from 'react-router'
import { showMasterDataDialog } from '../helpers/MasterDataManager'

const mapStateToProps = (state, ownProps) => {
    return {
        userInfo: state.User.info,
        search: state.Bucket.search
    }
}

class SearchMenuItem extends React.Component {
    state = {
        searchOpen: false,
        searchText: ""
    }

    Input = posed.input({
        shown: {
            width: 150,
            paddingLeft: 10,
            paddingRight: 10
        },
        hidden: {
            width: 0,
            paddingLeft: 0,
            paddingRight: 0
        }
    })

    componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutsideSearch)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutsideSearch)
    }

    handleClickOutsideSearch = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) this.cancelSearch()
	}
    
    setWrapperRef = (node) => {
		this.wrapperRef = node
    }
    
    cancelSearch = () => {
        this.setState({searchOpen: false})
        this.setState({searchText: ""})
        this.props.dispatch(packAction(ActionTypes.CLEAR_SEARCH))
        clearTimeout(this.searchTimeout)
    }

    onInputChange = (e) => {
        let input = e.target.value
        clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
            if(input !== '') {
                let contextInfo = getSearchContext()
                this.props.dispatch(packAction(ActionTypes.SEARCH, Object.assign(contextInfo, {input})))
            } else {
                this.props.dispatch(packAction(ActionTypes.CLEAR_SEARCH))
            }
        }, 500)
        this.setState({searchText: input})
    }

    openSearch = () => {
        this.setState({searchOpen: true})
        this.refs.searchInput.focus()
    }

    clickOnSearchIcon = () => this.state.searchOpen ? this.cancelSearch() : this.openSearch()
    
    keyDown(event) {
        if(event.keyCode === 27) { //27 is escape key
            this.cancelSearch()
        } 
        if((event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) && this.state.searchText) { //38 is arrow up, 40 is arrow down, 13 is enter
            event.preventDefault()
            if(event.keyCode === 13) {
                let selectedItem = this.refs.searchResults.getSelected()
                if(selectedItem) {
                    let {bucketId, itemId} = selectedItem
                    showMasterDataDialog(bucketId, itemId, true)
                    this.cancelSearch()
                }
            }
            if(event.keyCode === 38) this.refs.searchResults.setSelectedIndex(this.refs.searchResults.getSelectedIndex()-1)
            if(event.keyCode === 40) this.refs.searchResults.setSelectedIndex(this.refs.searchResults.getSelectedIndex()+1)
        }
    }

    render() {
        return (
            <RequirePermission perms={is.itemAccessor}>
                <li className={`dropdown nav-item search-dropdown`} ref={this.setWrapperRef} title="Search for IDs">
                    <span className="nav-link search-dropdown-toggle inline-block" role="button" aria-expanded="false" onClick={this.clickOnSearchIcon}>
                        <span className="fa fa-search"/>
                    </span>
                    <this.Input 
                        type="text" 
                        ref="searchInput"
                        pose={this.state.searchOpen ? "shown" : "hidden"} 
                        className={`navbar-search-input ${this.state.searchText ? "navbar-search-input-has-text" : ""}`} 
                        value={this.state.searchText} 
                        onChange={this.onInputChange}
                        placeholder="Search id"
                        onKeyDown={e => this.keyDown(e)}
                    />
                    <NavigationBarSearch 
                        ref="searchResults" 
                        onClose={this.cancelSearch} 
                        searchResult={this.props.search} 
                        searchText={this.state.searchText} 
                        searchOpen={this.state.searchOpen}
                    />
                </li>
            </RequirePermission>
        )
    }
}

SearchMenuItem = connect(mapStateToProps)(SearchMenuItem)

SearchMenuItem = withRouter(SearchMenuItem)

export default SearchMenuItem