import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getDashboardAllData = dID => {
    return packAction(ActionTypes.GET_DASHBOARD_DATA, { dID: dID })
}

export const getDashboardAllDataDone = (allData, dID) => {
    return packAction(ActionTypes.GET_DASHBOARD_DATA_DONE, {data: allData, dID: dID})
}

export const dashboardNotAccessible = () => {
    return packAction(ActionTypes.DASHBOARD_NOT_ACCESSIBLE)
}

export const getDashboardOverview = () => {
    return packAction(ActionTypes.GET_DASHBOARD_OVERVIEW)
}

export const gotDashboardOverview = (data) => {
    return packAction(ActionTypes.GET_DASHBOARD_OVERVIEW_DONE, data)
}

export const getHomeDashboard = () => {
    return packAction(ActionTypes.GET_HOME_DASHBOARD)
}

export const setHomeDashboard = (data) => {
    return packAction(ActionTypes.SET_HOME_DASHBOARD, {dashboard_id: data})
}
export const setHomeDashboardForUser = (data) => {
    return packAction(ActionTypes.SET_HOME_DASHBOARD_FOR_USER, {userID: data.userID, dashboardID: data.dashboardID})
}

export const gotHomeDashboard = (data) => {
    return packAction(ActionTypes.GET_HOME_DASHBOARD_DONE, data)
}

export const updateHomeDashboardDone = (data) => {
    return packAction(ActionTypes.UPDATE_HOME_DASHBOARD_DONE, data)
}

export const getDefaultDashboard = () => {
    return packAction(ActionTypes.GET_DEFAULT_DASHBOARD)
}

export const gotDefaultDashboard = (id) => {
    return packAction(ActionTypes.GET_DEFAULT_DASHBOARD_DONE, id)
}

export const createDashboard = (name, report_id, label, cb) => {
    return packAction(ActionTypes.CREATE_DASHBOARD, {name: name, main_report: {report_id, label}}, cb)
}

export const copyDashboard = (title, d_id, org_id, cb) => {
    return packAction(ActionTypes.COPY_DASHBOARD, {title, d_id, org_id}, cb)
}

export const deleteDashboard = (dashboard_id) => {
    return packAction(ActionTypes.DELETE_DASHBOARD, {dashboard_id})
}

export const updateDashboard = (dashboard, cb) => {
    return packAction(ActionTypes.UPDATE_DASHBOARD, dashboard, cb)
}

export const getDashboardReports = () => {
    return packAction(ActionTypes.GET_DASHBOARD_REPORTS)
}

export const gotDashboardReports = (reports) => {
    return packAction(ActionTypes.GET_DASHBOARD_REPORTS_DONE, reports)
}

export const calculateDashboardTable = (dashboard, cb) => {
    return packAction(ActionTypes.CALCULATE_DASHBOARD_TABLE, dashboard, cb)
}

export const calculateTmpDashboard = (dashboard, cb) => {
    return packAction(ActionTypes.CALCULATE_TMP_DASHBOARD, dashboard, cb)
}
