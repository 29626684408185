import React from 'react'
import SaveDialog from './SaveDialog'
import { getUserOptionJSX } from '../../helpers/UserHelpers'

class UserOptionDialog extends React.Component {
    state = {
        id: -1
    }

    show(defaultValue) {
        if(this.props.users.some(x => x.id === defaultValue)){
            this.setState({
                id: defaultValue
            })
        }
        else if (this.props.users.length > 0){
            this.setState({
                id: this.props.users[0].id
            })
        }
        this.refs.userDialog.show()
    }

    hide() {
        this.refs.userDialog.hide()
    }

    render() {
        return (
            <SaveDialog ref="userDialog" title={this.props.title} saveHandler={() => this.props.saveHandler(this.state.id)} closeHandler={() => this.props.closeHandler ? this.props.closeHandler() : null}>
                <select className="form-control" value={this.state.id} onChange={e => this.setState({id: e.target.value})}>
                    {
                        getUserOptionJSX(this.props.users, this.props.showUserPredicate, this.props.sort)
                    }
                </select>
                {
                    this.props.children
                }
            </SaveDialog>
        )
    }
}

export default UserOptionDialog