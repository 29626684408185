import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FaCalculator, FaEnvelope, FaFile, FaTable, FaTh, FaWindowMaximize } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiDashboard2Fill } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import { IoDocumentsOutline, IoDocumentOutline } from "react-icons/io5";
import { OrgItemsDashboard, OrgItemsReport } from "../../types/organizationtypes";
import { LoginSession, User } from "../../types/transfertypes";
import { MailBundleWithRelations, newMailBundle, Plan, ReportingDashboard, InsightView } from "../../types/reportingtypes";
import { createMailBundle, updateMailBundle } from "../../actions/MailBundleActions";
import React from "react";
import MultiSelect from "../Generic/MultiSelect";
import { isReportingServiceUser } from "../../helpers/UserHelpers";
import "../../css/organizationAdministration.css"
import AlertBanner from "../Alert/AlertBanner";

interface MailBundleModalProps {
    open: boolean;
    onClose: () => any;
    dashboards: OrgItemsDashboard[];
    reports: OrgItemsReport[];
    consultantMap: {[key:number]:boolean}
    users: User[];
    orgId: number;
    plans: Plan[];
    selectedMailBundle?: MailBundleWithRelations
    use_access_control: boolean
    location_enabled: boolean
    dispatch: any;
    initialPage: 0 | 1 | 2 | 3;
    user: LoginSession;
    setSubscriptionTypeFilter: Dispatch<SetStateAction<"BUNDLE"|"SUBSCRIPTION">>
}

function MailBundleModal(props:MailBundleModalProps) {
    const [mailBundle, setMailBundle] = useState<MailBundleWithRelations>(props.selectedMailBundle ? props.selectedMailBundle : newMailBundle(props.orgId))
    
    const [selectedRecipientIds, setSelectedRecipientIds] = useState<number[]>([])
    const [selectedReportIds, setSelectedReportIds] = useState<number[]>([])
    const [selectedInsightViews, setSelectedInsightViews] = useState<InsightView[]>([])

    const [selectedPlan, setSelectedPlan] = useState<Plan|null>(props.selectedMailBundle ? props.plans.find(p => p.id === props.selectedMailBundle?.plan_id) ?? null : null)
    const [page, setPage] = useState<number>(props.initialPage)
    
    const [selectedDashboards, setSelectedDashboards] = useState<ReportingDashboard[]>([]) 

    const onCloseLocal = () => {
        setMailBundle(newMailBundle(props.orgId));
        props.onClose();
    }

    const getMailBundleRelations = async (mID:number) => {
        if(!mID || !props.selectedMailBundle) return
        setSelectedRecipientIds(props.selectedMailBundle.user_ids ?? [])

        // Deep cloning so it doesnt remember the include widgets/include tables setting if we change them and then cancel 
        setSelectedDashboards(structuredClone(props.selectedMailBundle.dashboards) ?? [])
        setSelectedReportIds(props.selectedMailBundle.report_ids ?? [])
        setSelectedInsightViews(structuredClone(props.selectedMailBundle.insight_views) ?? [])
    }

    const updateMailBundleState = (key: string, value: any) => {
        setMailBundle({ ...mailBundle, [key]: value })
    }

    useEffect(() => {
        if(selectedPlan){
            setMailBundle({...mailBundle, plan: selectedPlan.interval, plan_id: selectedPlan.id})
        }
    }, [selectedPlan])

    useEffect(() => {
        if(props.selectedMailBundle){
            void getMailBundleRelations(props.selectedMailBundle.id)
        }
    }, [props.selectedMailBundle])
    
    const saveMailBundle = (activate: boolean) => {
        if(!validateMailBundle()) return;
        const mailBundleWithRelations : MailBundleWithRelations = {
            ...mailBundle,
            owner_id: props.user.user_id,
            user_ids: selectedRecipientIds,
            report_ids: selectedReportIds,
            insight_views: selectedInsightViews,
            dashboards: selectedDashboards
        }
        
        if(activate){
            mailBundleWithRelations.active = true
        }
        if(props.selectedMailBundle){
            props.dispatch(updateMailBundle(mailBundleWithRelations))
            onCloseLocal();
        } else {
            props.dispatch(createMailBundle(mailBundleWithRelations))
            props.setSubscriptionTypeFilter("BUNDLE")
            onCloseLocal();
        }
        
    }

    const canGoToPage2 = () => {
        return mailBundle.name && (selectedDashboards.length > 0 || selectedReportIds.length > 0 || selectedInsightViews.length > 0);
    }
        
    const canGoToPage3 = () => {
        return canGoToPage2() && selectedPlan;
    }

    const canGoToPage4 = () => {
        return canGoToPage3() && selectedRecipientIds.length > 0;
    }
            
    const canGoToNextPage = () => {
        return (page === 0 && canGoToPage2()) || (page === 1 && canGoToPage3()) || (page === 2 && canGoToPage4());
    }

    const nextPage = () => {
        if(page < 3){
            setPage(page+1)
        }
    }

    const prevPage = () => {
        if(page > 0){
            setPage(page-1)
        }
    }

    const validateMailBundle = () => {
        const hasName = !!mailBundle.name;
        const hasPlan = !!mailBundle.plan && !!mailBundle.plan_id;
        const hasReceivers = selectedRecipientIds.length > 0;
        const hasContent = selectedDashboards.length > 0 || selectedReportIds.length > 0 || selectedInsightViews.length > 0;
        
        return hasName && hasPlan && hasReceivers && hasContent;
    }
    
    return (<>
        <div 
            className="position-absolute top-0 bottom-0 left-0 right-0 mx-auto mt-5 z-40  rounded-lg shadow-lg border border-placeholder bg-white border-secondary p-4 d-flex flex-column justify-content-between gap-2" 
            style={{ width: '800px', height: '900px', zIndex: 9999}}
        >
            <div className="d-flex flex-column">
                <div className="border-bottom p-2 h4 font-weight-bold d-flex" style={{whiteSpace: "nowrap"}}>
                    {page === 0 && <span>{props.selectedMailBundle ? "Update mail bundle" : "Create mail bundle"}</span>}
                    {page === 1 && <span>Schedule</span>}
                    {page === 2 && <span>Select recipients for</span>}
                    {page === 3 && <span>PDF Settings</span>}
                    <div className="text-secondary ml-2" style={{overflow: "hidden", textOverflow: "ellipsis"}}>{mailBundle.name}</div>
                </div>
                {props.selectedMailBundle && (
                    <div className="w-100 d-flex align-items-start">
                        <div
                            onClick={() => setPage(0)}
                            className={`${page === 0 ? "text-primary border-primary" : "border-secondary text-secondary"} border-bottom cursor-default font-weight-bold border-bottom-2 px-3 pb-2 select-none`}
                        >
                            Setup
                        </div>
                        <div
                            onClick={() => { if (canGoToPage2()) setPage(1) }}
                            className={`${page === 1 ? "text-primary border-primary" : "border-secondary text-secondary"} border-bottom cursor-default font-weight-bold border-bottom-2 px-3 pb-2 select-none`}
                        >
                            Schedule
                        </div>
                        <div
                            onClick={() => { if (canGoToPage3()) setPage(2) }}
                            className={`${page === 2 ? "text-primary border-primary" : "border-secondary text-secondary"} border-bottom cursor-default font-weight-bold border-bottom-2 px-3 pb-2 select-none`}
                        >
                            Recipients
                        </div>
                        <div
                            onClick={() => { if (canGoToPage4()) setPage(3) }}
                            className={`${page === 3 ? "text-primary border-primary" : "border-secondary text-secondary"} border-bottom cursor-default font-weight-bold border-bottom-2 px-3 pb-2 select-none`}
                        >
                            PDF Settings
                        </div>
                    </div>
                )}
                {page === 0 && (
                    <MailBundleSetup
                        mailBundle={mailBundle}
                        updateMailBundleState={updateMailBundleState}
                        cloudDashboards={props.dashboards}
                        cloudReports={props.reports}
                        selectedDashboards={selectedDashboards}
                        setSelectedDashboards={setSelectedDashboards}
                        selectedInsightViews={selectedInsightViews}
                        setSelectedInsightViews={setSelectedInsightViews}
                        selectedReportIds={selectedReportIds}
                        setSelectedReportIds={setSelectedReportIds}
                    />
                )}
                {page === 1 && (
                    <ScheduleSelect
                        mailBundle={mailBundle}
                        plans={props.plans}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                    />
                )}
                {page === 2 && (
                    <RecipientSelect
                        consultantMap={props.consultantMap}
                        mailBundle={mailBundle}
                        users={props.users}
                        selectedBuckets={
                            props.reports
                                .filter(r => [...selectedReportIds, ...selectedInsightViews.map(t => t.report_id)].includes(r.report_id ?? -1))
                                .map(r => r.bucket)
                        }
                        use_access_control={props.use_access_control}
                        selectedRecipientIds={selectedRecipientIds}
                        setSelectedRecipientIds={setSelectedRecipientIds}
                        updateMailBundleState={updateMailBundleState}
                        location_enabled={props.location_enabled}
                    />
                )}
                {page === 3 && (
                    <PdfOptions
                        mailBundle={mailBundle}
                        updateMailBundleState={updateMailBundleState}
                    />
                )}
            </div>
            <div className="d-flex flex-column bg-white border-top" style={{ minHeight: '100px' }}>
                {!props.selectedMailBundle && (
                    <div className="w-100 d-flex align-items-center justify-content-center p-2 mt-2">
                        <div
                            onClick={() => setPage(0)}
                            className={`${page === 0 ? "bg-primary" : "bg-secondary"} cursor-pointer rounded-circle mr-1`}
                            style={{width:15, height: 15}}
                        ></div>
                        <div
                            onClick={() => { if (canGoToPage2()) setPage(1) }}
                            className={`${page === 1 ? "bg-primary" : "bg-secondary"} ${canGoToPage2() ? "cursor-pointer" : ""} rounded-circle mr-1`}
                            style={{width:15, height: 15}}
                        ></div>
                        <div
                            onClick={() => { if (canGoToPage3()) setPage(2) }}
                            className={`${page === 2 ? "bg-primary" : "bg-secondary"} ${canGoToPage3() ? "cursor-pointer" : ""} rounded-circle mr-1`}
                            style={{width:15, height: 15}}
                        ></div>
                        <div
                            onClick={() => { if (canGoToPage4()) setPage(3) }}
                            className={`${page === 3 ? "bg-primary" : "bg-secondary"} ${canGoToPage4() ? "cursor-pointer" : ""} rounded-circle`}
                            style={{width:15, height: 15}}
                        ></div>
                    </div>
                )}
                <div className={`${props.selectedMailBundle ? "mt-4" : ""} d-flex align-items-center justify-content-end`}>
                    <div className={`d-flex justify-content-end align-items-center w-100`}>
                        <button className="btn btn-outline-secondary mr-2" onClick={onCloseLocal}>
                            Close
                        </button>
                        {page > 0 && (
                            <button className="btn btn-outline-primary mr-2" onClick={prevPage}>
                                <span aria-hidden="true">&laquo;</span> Previous
                            </button>
                        )}
                        {page < 3 && (
                            <button className={`${!!props.selectedMailBundle ? "btn-outline-primary" : "btn-primary" } btn mr-2`} onClick={nextPage} disabled={!canGoToNextPage()}>
                                Next <span aria-hidden="true">&raquo;</span>
                            </button>
                        )}
                        {((page === 3 && !props.selectedMailBundle) || (!!props.selectedMailBundle && !props.selectedMailBundle.active)) &&
                            <button 
                                onClick={() => saveMailBundle(true)} 
                                className="btn btn-primary mr-2" 
                                disabled={!validateMailBundle()}
                            >
                                Save & Activate
                            </button>
                        }
                        {(page === 3 || !!props.selectedMailBundle) && 
                            <button 
                                onClick={() => saveMailBundle(false)} 
                                className="btn btn-primary mr-2" 
                                disabled={!validateMailBundle()}
                            >
                                Save
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="fade modal-backdrop show"></div> 
    </>);
}

interface MailBundleSetupProps {
    mailBundle: MailBundleWithRelations;
    updateMailBundleState: (key:string, value:any) => void;
    cloudDashboards: OrgItemsDashboard[];
    cloudReports: OrgItemsReport[];
    selectedDashboards: ReportingDashboard[];
    setSelectedDashboards: Dispatch<SetStateAction<ReportingDashboard[]>>
    selectedReportIds: number[];
    setSelectedReportIds: Dispatch<SetStateAction<number[]>>
    selectedInsightViews: InsightView[];
    setSelectedInsightViews: Dispatch<SetStateAction<InsightView[]>>
}

function MailBundleSetup(props:MailBundleSetupProps){
    const [tmpInsightView, setTmpInsightView] = useState<InsightView>()
    const [insightViewUrl, setInsightViewUrl] = useState<string>("")

    const dashboardsChanged = useMemo(() => {
        if(props.mailBundle.dashboards.length !== props.selectedDashboards.length) return true
        for(const d of props.selectedDashboards){
            const id = d.dashboard_id
            const table = d.include_table
            const widgets = d.include_widgets
            if(!props.mailBundle.dashboards.some(db => db.dashboard_id === id && db.include_table === table && db.include_widgets === widgets)){
                return true
            }
        }
        return false;
    }, [props.mailBundle, props.selectedDashboards])
    
    const reportsChanged = useMemo(() => {
        if(props.mailBundle.report_ids.length !== props.selectedReportIds.length) return true
        return !props.mailBundle.report_ids.every(id => props.selectedReportIds.includes(id))
    }, [props.mailBundle, props.selectedReportIds])
    
    const insightViewsChanged = useMemo(() => {
        if(props.mailBundle.insight_views.length !== props.selectedInsightViews.length) return true
        for(const iv of props.selectedInsightViews){
            const id = iv.report_id
            const hash = iv.hash
            const type = iv.type
            if(!props.mailBundle.insight_views.some(i => i.report_id === id && i.hash === hash && i.type === type)){
                return true
            }
        }
        return false;
    }, [props.mailBundle, props.selectedInsightViews])

    const getInsightViewObjFromURL = (url:string) : InsightView | undefined => {
        const reportIdPattern = /\/report\/(\d+)\//
        const reportIdMatch = url.match(reportIdPattern)
        
        const trendHashPattern = /trend\/([^?]+)/
        const matrixHashPattern = /overview\/([^?]+)/
        const kpiHashPattern = /kpis\/([^?]+)/
        const sumHashPattern = /sum\/([^?]+)/
        const profitHashPattern = /profit\/([^?]+)/
        
        const trendMatch = url.match(trendHashPattern)
        const matrixMatch = url.match(matrixHashPattern)
        const kpiMatch = url.match(kpiHashPattern)
        const sumMatch = url.match(sumHashPattern)
        const profitMatch = url.match(profitHashPattern)
        
        if(!reportIdMatch){
            return
        }
        const reportId = Number(reportIdMatch[1]);
        let type: "trend" | "matrix" | "kpi" | "sum" | "profit"
        let hash: string;
        if(!!trendMatch){   
            hash = trendMatch[1]
            type = "trend"
        }else if(!!matrixMatch){
            hash = matrixMatch[1]
            type = "matrix"
        } else if(!!kpiMatch){
            hash = kpiMatch[1]
            type = "kpi"
        } else if(!!sumMatch){
            hash = sumMatch[1]
            type = "sum"
        } else if(!!profitMatch){
            hash = profitMatch[1]
            type = "profit"
        } else {
            return;
        }
        return {
            report_id: reportId,
            hash,
            type
        }
    }

    useEffect(() => {
        const insightViewObj = getInsightViewObjFromURL(insightViewUrl);
        if(insightViewObj){
            setTmpInsightView(insightViewObj)
        } else {
            setTmpInsightView(undefined)
        }
    }, [insightViewUrl])

    const toggleDashboardSetting = (dID: number, setting: "include_table" | "include_widgets") => {
        const dashboard = props.selectedDashboards.find(d => d.dashboard_id === dID);
        if (!dashboard) return;
        dashboard[setting] = !dashboard[setting]

        if(!dashboard.include_table && !dashboard.include_widgets){
            return
        }

        props.setSelectedDashboards([
            ...props.selectedDashboards.map(d => d.dashboard_id === dID ? dashboard : d)
        ])
    }
    
    const baseUrl = window.location.href.substring(0, window.location.href.indexOf("#"))

    return (
        <>
            <label style={{width: 400}} className="mt-2">
                <span className="text-secondary font-weight-bold ml-1">Bundle Name</span>
                <input 
                    type="text" 
                    className="form-control" 
                    value={props.mailBundle.name} 
                    onChange={e => props.updateMailBundleState("name", e.target.value)} 
                    required 
                />
                <div className="invalid-feedback">Please provide a name</div>
            </label>
            <div className="d-flex">                
            <div className="d-flex flex-column py-3 pr-3 border-right w-100" style={{height: 600}}>
                <div className="mb-5">
                    <div className="text-secondary font-weight-bold ml-1">
                        <RiDashboard2Fill className="h5 mr-1 mb-1 d-inline-block"/>
                        Dashboards
                    </div>
                    <MultiSelect 
                        options={props.cloudDashboards.map(d => {
                            return {
                                label: d.title,
                                value: d.id
                            }
                        })} 
                        selectedOptions={props.selectedDashboards.map(d => {
                            return {
                                label: props.cloudDashboards.find(db => db.id === d.dashboard_id)?.title ?? "",
                                value: d.dashboard_id
                            }
                        })}
                        onOptionSelect={(o) => {
                            if(!props.selectedDashboards.map(d => d.dashboard_id).includes(o.value)) {
                                props.setSelectedDashboards([...props.selectedDashboards, {dashboard_id: o.value, include_table: true, include_widgets: true}])
                            }
                        }}
                        onOptionDeselect={(o) => {
                            props.setSelectedDashboards(props.selectedDashboards.filter(d => d.dashboard_id !== o.value))
                        }}
                        placeholder="Select Dashboards"
                    />
                </div>
                <div className="mb-5">
                    <div className="text-secondary font-weight-bold ml-1">
                        <FaFile className="h6 mr-1 d-inline-block mb-1" />
                        Insights
                    </div>
                    <MultiSelect 
                        options={props.cloudReports
                            .filter(r => r.is_public)
                            .map(r => {
                            return {
                                label: r.name,
                                value: r.report_id
                            }
                        })} 
                        selectedOptions={props.selectedReportIds.map(rId => {
                            return {
                                label: props.cloudReports.find(r => r.report_id === rId)?.name ?? "",
                                value: rId
                            }
                        })}
                        onOptionSelect={(o) => {if(!props.selectedReportIds.includes(o.value)) props.setSelectedReportIds([...props.selectedReportIds, o.value])}}
                        onOptionDeselect={(o) => props.setSelectedReportIds(props.selectedReportIds.filter(id => id !== o.value))}
                        placeholder="Select Insights"
                    />
                </div>
                <div className="mb-5">
                    <span className="text-secondary font-weight-bold ml-1">
                        <GoGraph className="h5 mr-1 d-inline-block mb-1"/>
                        Insight View
                    </span>
                    <label className="d-flex btn-group">
                        <input 
                            type="url" 
                            className="form-control rounded-end-0" 
                            onChange={e => setInsightViewUrl(e.target.value)} 
                            placeholder="Insert Insight View link" 
                            value={insightViewUrl}
                        />
                        <button
                            title={`${!tmpInsightView ? "Invalid url" : "Add insight view"}`}
                            className={`${!tmpInsightView ? "" : ""} btn btn-primary`}
                            disabled={!tmpInsightView}
                            onClick={() => {if(tmpInsightView) props.setSelectedInsightViews((p) => [...p, tmpInsightView]); setInsightViewUrl("")}}
                        >
                            Add
                        </button>
                    </label>
                </div>
            </div>
            <div className="p-3 d-flex flex-column gap-4 w-100 overflow-auto" style={{height: 600}}>
                {(props.selectedDashboards.length > 0 || dashboardsChanged) && <div className="mb-4">
                    <div className="text-secondary p-1 border-bottom d-flex justify-content-between align-items-end">
                        <span>
                            <span className="font-weight-bold mr-1 ">
                                {props.selectedDashboards.length > 1 ? "Selected Dashboards" : "Selected Dashboard"} 
                            </span>
                            {props.selectedDashboards.length > 1 && <span>({props.selectedDashboards.length})</span>}
                        </span>
                        {dashboardsChanged && 
                            <button
                                className="px-2 border btn-default rounded"
                                onClick={() => props.setSelectedDashboards(structuredClone(props.mailBundle.dashboards))}
                            >
                                Reset
                            </button>
                        }
                    </div>
                    {props.selectedDashboards.length > 0 && props.selectedDashboards.map((d, i) => {
                        return (
                            <div 
                                key={i} 
                                className="px-1 border-bottom d-flex justify-content-between align-items-center w-100 mailbundle-selected-item" 
                            >
                                <a href={baseUrl + `#/dashboard/${d.dashboard_id}`} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
                                    <RiDashboard2Fill className="text-primary h4 mr-1 pt-1"/>
                                    {props.cloudDashboards.find(db => db.id === d.dashboard_id)?.title}
                                </a>    
                                <div className="d-flex align-items-center justify-content-center">
                                    {!d.include_table && <span className="text-secondary italic">Widgets only</span>}
                                    {!d.include_widgets && <span className="text-secondary italic">Table only</span>}
                                    <div className="btn-group ml-2 mr-2" role="group">
                                        <button 
                                            className="btn btn-default btn-xs dropdown-toggle show" 
                                            id="dropdownMenu" 
                                            data-toggle="dropdown" 
                                            aria-expanded="false" 
                                            name="selectFilter"
                                        >
                                        </button>
                                        <div className="dropdown-menu p-0 overflow-hidden user-select-none" aria-labelledby="dropdownMenu" >
                                            <button 
                                                className={`${d.include_widgets ? "bg-secondary text-white" : ""} dropdown-item`} 
                                                onClick={() => toggleDashboardSetting(d.dashboard_id, "include_widgets")}
                                            >
                                                Include Widgets
                                                {d.include_widgets && <MdClose />}
                                            </button>
                                            <button 
                                                className={`${d.include_table ? "bg-secondary text-white" : ""} dropdown-item d-flex justify-content-between align-items-center`} 
                                                onClick={() => toggleDashboardSetting(d.dashboard_id, "include_table")}
                                            >
                                                Include Table
                                                {d.include_table && <MdClose />}
                                            </button>
                                        </div>
                                    </div>
                                    <MdClose 
                                        className="cursor-pointer" 
                                        title="Remove dashboard" 
                                        onClick={() => props.setSelectedDashboards(props.selectedDashboards.filter(db => db.dashboard_id !== d.dashboard_id))}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>}
                    {(props.selectedReportIds.length > 0 || reportsChanged) && <div className="mb-4">
                    <div className="text-secondary p-1 border-bottom d-flex justify-content-between align-items-end">
                        <span>
                            <span className="font-weight-bold mr-1 ">
                                {props.selectedReportIds.length > 1 ? "Selected Insights" : "Selected Insight"} 
                            </span>
                            {props.selectedReportIds.length > 1 && <span>({props.selectedReportIds.length})</span>}
                        </span>
                        {reportsChanged && 
                            <button
                                className="px-2 border btn-default rounded"
                                onClick={() => props.setSelectedReportIds([...props.mailBundle.report_ids])}
                            >
                                Reset
                            </button>
                        }
                    </div>
                    {props.selectedReportIds.length > 0 && props.selectedReportIds.map((rId, i) => {
                        return (
                            <div 
                                key={i} 
                                className="p-1 border-bottom d-flex justify-content-between align-items-center cursor-pointer w-100 mailbundle-selected-item" 
                            >
                                <a href={baseUrl + `#/report/${rId}`} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
                                    <FaFile className="text-primary mr-1"/>
                                    {props.cloudReports.find(r => r.report_id === rId)?.name}
                                </a>
                                <MdClose onClick={() => props.setSelectedReportIds(props.selectedReportIds.filter(id => id !== rId))} />
                            </div>
                        )
                    })}
                </div>}
                <div>
                    {(props.selectedInsightViews.length > 0 || insightViewsChanged) && <div className="text-secondary  p-1 border-bottom d-flex justify-content-between align-items-end">
                        <span>
                            <span className="font-weight-bold mr-1 ">
                                {props.selectedInsightViews.length > 1 ? "Selected Insight Views" : "Selected Insight View"} 
                            </span>
                            {props.selectedInsightViews.length > 1 && <span>({props.selectedInsightViews.length})</span>}
                        </span>
                        {insightViewsChanged && 
                            <button
                                className="px-2 border btn-default rounded"
                                onClick={() => props.setSelectedInsightViews(structuredClone(props.mailBundle.insight_views))}
                            >
                                Reset
                            </button>
                        }
                    </div>}
                    {props.selectedInsightViews.length > 0 && props.selectedInsightViews.map((iv, i) => {
                        const ivTypeForUrl = iv.type === "kpi" ? "kpis" : iv.type === "matrix" ? "overview" : iv.type
                        return (<div 
                            key={i} 
                            className="p-1 border-bottom hover:bg-light d-flex justify-content-between align-items-center cursor-pointer w-100 mailbundle-selected-item" 
                        >
                            <a href={baseUrl + `#/report/${iv.report_id}/${ivTypeForUrl}/${iv.hash}?exact=true`} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
                                {iv.type === "trend" && <span className="border-right pr-1 mr-2" style={{width: 60}}> 
                                    <GoGraph className="text-primary mr-1"/> 
                                    <span style={{fontSize: 11}}>Trend</span> 
                                </span>}
                                {iv.type === "matrix" && <span className="border-right pr-1 mr-2" style={{width: 60}}>
                                    <FaTh className="text-primary mr-1"/> 
                                    <span style={{fontSize: 11}}>Matrix</span> 
                                </span>}
                                {iv.type === "kpi" && <span className="border-right pr-1 mr-2" style={{width: 60}}>
                                    <FaWindowMaximize className="text-primary mr-1"/> 
                                    <span style={{fontSize: 11}}>KPI</span> 
                                </span>}
                                {iv.type === "sum" && <span className="border-right pr-1 mr-2" style={{width: 60}}>
                                    <FaTable className="text-primary mr-1"/> 
                                    <span style={{fontSize: 11}}>Sum</span> 
                                </span>}
                                {iv.type === "profit" && <span className="border-right pr-1 mr-2" style={{width: 60}}>
                                    <FaCalculator className="text-primary mr-1"/> 
                                    <span style={{fontSize: 11}}>Profit</span> 
                                </span>}
                                {/* If it can't find the Insight, it is most likely a private Insight */}
                                {props.cloudReports.find(r => r.report_id === iv.report_id)?.name ?? "Private Insight"}
                            </a>
                            <MdClose onClick={() => props.setSelectedInsightViews(props.selectedInsightViews.filter(insightView => insightView.report_id !== iv.report_id || insightView.hash !== iv.hash || insightView.type !== iv.type))} />
                        </div>)
                    })}
                </div>
            </div>
        </div>
        </>
    )
}

interface ScheduleSelectProps {
    mailBundle: MailBundleWithRelations;
    plans: Plan[];
    selectedPlan: Plan|null;
    setSelectedPlan: Dispatch<SetStateAction<Plan|null>>
}

function ScheduleSelect(props:ScheduleSelectProps){
    return <>
        <div className="overflow-auto" style={{ height: '600px' }}>
            <div className="d-flex justify-content-evenly p-2 w-100">
                <div className="d-flex flex-column align-items-center w-100 pr-1">
                    <div className=" w-100 text-center font-weight-bold">Daily</div>
                    {props.plans
                        .filter(p => p.interval.startsWith("D"))
                        .map((p, i) => (
                            <div 
                                key={i} 
                                className={`mailbundle-plan-box p-2 m-1 border rounded w-48 h-14 text-center ${props.selectedPlan?.id === p.id ? "bg-secondary text-white" : "cursor-pointer text-secondary"}`}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
                <div className="d-flex flex-column align-items-center w-100 pr-1">
                    <div className=" w-100 text-center font-weight-bold">Weekly</div>
                    {props.plans
                        .filter(p => p.interval.startsWith("W"))
                        .map((p, i) => (
                            <div 
                                key={i} 
                                className={`mailbundle-plan-box p-2 m-1 border rounded w-48 h-14 text-center ${props.selectedPlan?.id === p.id ? "bg-secondary text-white" : "cursor-pointer text-secondary"}`}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
                <div className="d-flex flex-column gap-2 align-items-center w-100">
                    <div className=" w-100 text-center font-weight-bold">Monthly</div>
                    {props.plans
                        .filter(p => p.interval.startsWith("M"))
                        .map((p, i) => (
                            <div 
                                key={i} 
                                className={`mailbundle-plan-box p-2 m-1 border rounded w-48 h-14 text-center ${props.selectedPlan?.id === p.id ? "bg-secondary text-white" : "cursor-pointer text-secondary"}`}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </>
}

interface RecipientSelectProps{
    mailBundle: MailBundleWithRelations;
    users: User[];
    consultantMap: {[key: number]:boolean}
    selectedRecipientIds: number[];
    setSelectedRecipientIds: Dispatch<SetStateAction<number[]>>;
    updateMailBundleState: (key:string, value:any) => void;
    selectedBuckets: string[];
    use_access_control: boolean;
    location_enabled: boolean;
}

function RecipientSelect(props:RecipientSelectProps){
    const recipients = useMemo(() => {
        let r = props.users.filter(u => !u.deleted && !u.is_consultant).sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
        if(props.use_access_control){
            return r.filter(r => props.selectedBuckets.every(b => r.bucket_access?.includes(b)))
        }
        return r
    }, [props.users, props.selectedBuckets])

    const recipientsChanged = useMemo(() => {
        if(props.mailBundle.user_ids.length !== props.selectedRecipientIds.length) return true
        return !props.mailBundle.user_ids.every(id => props.selectedRecipientIds.includes(id))
    }, [props.mailBundle, props.selectedRecipientIds])

    const [filteredRecipients, setFilteredRecipients] = useState<User[]>(recipients.sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    const [filteredSelectedRecipients, setFilteredSelectedRecipients] = useState<User[]>(props.selectedRecipientIds
        .filter(id => recipients.some(r => r.id === id) && !props.consultantMap[id])
        .map(rId => recipients.find(r => r.id === rId)!)
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    const [searchText, setSearchText] = useState<string>("");
    const [linkDisabled, setLinkDisabled] = useState<boolean>(false);
    const showSubscriptionWarning = useMemo(() => 
        props.mailBundle.self_subscription && (props.selectedRecipientIds.length > 1 || props.selectedRecipientIds.some(id => id !== props.mailBundle.owner_id))
    , [props.selectedRecipientIds])

    useEffect(() => {
        setLinkDisabled(!!props.selectedRecipientIds.find(id => isReportingServiceUser(recipients.find(r => r.id === id))))
        
    }, [props.selectedRecipientIds])

    useEffect(() => {
        if(linkDisabled){
            props.updateMailBundleState("link", false);
        }
    }, [linkDisabled])

    useEffect(() => {
        setFilteredRecipients(recipients
            .filter(r => (r.firstname + " " + r.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase()) || r.location?.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        setFilteredSelectedRecipients(recipients
            .filter(r => props.selectedRecipientIds.includes(r.id ?? -1))
            .filter(r => (r.firstname + " " + r.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase()) || r.location?.toLowerCase().includes(searchText.toLowerCase())
            )
        )
    }, [searchText, recipients])
    
    const isSelected = (r: User) => {
        return props.selectedRecipientIds.find(id => id === r.id) ?? false
    }
    
    return <>
            <label style={{width:400}} className="mt-2">
                <span className="text-secondary font-weight-bold ml-1">Search</span>
                <input 
                    type="search" 
                    className="form-control mt-2" 
                    value={searchText} 
                    onChange={e => setSearchText(e.target.value)} 
                    placeholder={props.location_enabled ? "Name, Location" : "Name"}
                />
            </label>
            <div className="d-flex">
                <div className="p-2 border-right w-100" style={{ height: '600px', display: 'flex', flexDirection: 'column' }}>
                    <div className="text-muted  border-bottom pb-2">
                        <div>
                            <span className="font-weight-bold mr-1 ">
                                Recipients 
                            </span>
                            Showing&nbsp;
                            {filteredRecipients.length + " of " + recipients.length}
                        </div>
                    </div>
                    <div className="overflow-auto" style={{ height: '100%' }}>
                        {filteredRecipients.length === 0 && <div className="text-secondary font-italic text-center pt-1">No recipients found</div>}
                        {filteredRecipients.map((r, i) => {
                            if (!r.id) return null;
                            const rId = r.id;
                            return (
                                <div 
                                    key={i}
                                    onClick={() => { 
                                        !isSelected(r) ? props.setSelectedRecipientIds([...props.selectedRecipientIds, rId]) : props.setSelectedRecipientIds(props.selectedRecipientIds.filter(id => id !== rId))
                                    }}
                                    className={`${isSelected(r) ? "bg-secondary text-white" : ""} mailbundle-selected-item p-1 border-bottom d-flex align-items-center justify-content-between cursor-pointer`}
                                >
                                    <span className="d-flex align-items-center">
                                        <span>{r.firstname + " " + r.lastname}</span>
                                        {isReportingServiceUser(r) ? <FaEnvelope className="text-primary ml-1" /> : null}
                                    </span>
                                    {isSelected(r) && <MdClose className="text-white" />}
                                </div>
                            )
                        })}
                    </div>
                    <div className="mt-2">
                        {linkDisabled && <div className="text-muted  mb-1">Link can not be included for reporting service users</div>}
                        {!linkDisabled && <div className="text-muted  mb-1">Include link</div>}
                        <div className="custom-control custom-switch mb-4">
                            <input 
                                type="checkbox" 
                                className="custom-control-input" 
                                checked={props.mailBundle.link && !linkDisabled} 
                                disabled={linkDisabled} 
                                id="include-link-switch" 
                                onChange={(e) => {if(!linkDisabled) props.updateMailBundleState("link", e.target.checked)}} 
                            />
                            <label className="custom-control-label" htmlFor="include-link-switch"></label>
                        </div>
                    </div>
                </div>
                <div className="p-2 w-100" style={{ height: '600px' }}>
                    {(props.selectedRecipientIds.length > 0 || recipientsChanged) && (
                        <div className="text-muted  pb-2 border-bottom d-flex justify-content-between align-items-center">
                            <span>
                                <span className="font-weight-bold mr-1 ">
                                    Selected Recipients 
                                </span>
                                Showing&nbsp;
                                {filteredSelectedRecipients.length + " of " + props.selectedRecipientIds.filter(id => !props.consultantMap[id]).length}
                            </span>
                            {recipientsChanged && 
                                <button
                                    className="px-2 border btn-default rounded"
                                    onClick={() => props.setSelectedRecipientIds([...props.mailBundle.user_ids])}
                                >
                                    Reset
                                </button>
                            }
                        </div>
                    )}
                    <div className="overflow-auto" style={{ height: '475px' }}>
                        {filteredSelectedRecipients.length === 0 && <div className="text-secondary font-italic text-center pt-1">No recipients found</div>}
                        {props.selectedRecipientIds.length > 0 && filteredSelectedRecipients
                            .map((recipient, i) => {
                                return (
                                    <div 
                                        key={i} 
                                        className="p-1 border-bottom mailbundle-selected-item d-flex justify-content-between align-items-center cursor-pointer w-100" 
                                        onClick={() => props.setSelectedRecipientIds(props.selectedRecipientIds.filter(id => id !== recipient.id))}
                                    >
                                        <span className="d-flex align-items-center">
                                            <span>
                                                {recipient.firstname + " " + recipient.lastname}
                                            </span>
                                            {isReportingServiceUser(recipient) ? <FaEnvelope className="text-primary ml-1" /> : null}
                                        </span>
                                        <MdClose />
                                    </div>
                                )
                            })
                        }
                    </div>
                    {showSubscriptionWarning && 
                        <AlertBanner
                            config={{
                                text: (
                                    <span className="d-flex align-items-end">
                                        Adding or changing recipients for this subscription, will make it a bundle. 
                                        <button className="btn btn-default h-50" onClick={() => props.setSelectedRecipientIds([props.mailBundle.owner_id])}>Reset</button>
                                    </span>),
                                title: "",
                                type: "warning"
                            }}
                        />
                    }
                </div>
            </div>
    </>
}

interface PdfOptionsProps{
    mailBundle: MailBundleWithRelations;
    updateMailBundleState: (key:string, value:any) => void;
}

function PdfOptions(props:PdfOptionsProps){
    return (
        <>
            <div className="d-flex justify-content-center align-items-start mt-5" style={{ minHeight: '500px'}}>
                <div className="font-weight-bold mr-5">
                    Merged PDF
                    <div 
                        className={`${props.mailBundle.combined_pdf ? "bg-secondary text-white" : ""} mailbundle-pdf-button select-none border rounded-lg d-flex justify-content-center align-items-center mt-1`}
                        onClick={() => props.updateMailBundleState("combined_pdf", true)}
                    >
                        <IoDocumentOutline className="display-3"/>
                    </div>
                    <span className={`${props.mailBundle.combined_pdf ? "" : "font-weight-normal"} font-italic mailbundle-pdf-text`}>
                        Recipients will receive a single PDF containing all items
                    </span>
                </div>
                <div className="font-weight-bold">
                    Separate PDFs
                    <div 
                        className={`${!props.mailBundle.combined_pdf ? "bg-secondary text-white" : ""} mailbundle-pdf-button select-none border rounded-lg d-flex justify-content-center align-items-center mt-1`}
                        onClick={() => props.updateMailBundleState("combined_pdf", false)}
                    >
                        <IoDocumentsOutline className="display-3"/>
                    </div>
                    <span className={`${!props.mailBundle.combined_pdf ? "" : "font-weight-normal"} font-italic mailbundle-pdf-text`}>
                        Recipients will receive a PDF for each item
                    </span>
                </div>
            </div>
        </>
    )
}

export default MailBundleModal