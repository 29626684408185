import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet, apiPut, apiPost } from '../../helpers/ApiHelpers'
import { gotSearch, gotBucketInfo, gotBucketsDataInfo, gotDownloadToken, gotProfitData, gotStockBalanceData, gotStockBalanceColumnData, gotBucketUsers, gotUpdatedBucketAccess } from '../../actions/BucketActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { notifySuccess } from '../../helpers/NotificationManager'


const BucketApiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.SEARCH:
            url = `buckets/search/${action.payload.context}/${action.payload.contextId}?q=${action.payload.input}`
            apiGet(url, result => dispatch(gotSearch(result)), e => notifyApiFailure(`searching for '${action.payload.input}'.`, 'GET', url, data, e.status))
            break
        case ActionTypes.GET_BUCKET_INFO:
            url = `bucket/${action.payload}`
            apiGet(url, info => dispatch(gotBucketInfo(info)), e => notifyApiFailure("getting bucket info.", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_BUCKET_USERS:
            url = `bucket/${action.payload}/users`
            apiGet(url, info => dispatch(gotBucketUsers(info, action.payload)), e => notifyApiFailure("getting bucket users.", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_BUCKETS_INFO:
            url = `bucketsinfo`
            apiGet(url, buckets => dispatch(gotBucketsDataInfo(buckets)), e => notifyApiFailure("error getting buckets info", "GET", url, data, e.status))
            break
        case ActionTypes.GET_DOWNLOAD_TOKEN:
            url = `bucket/${action.payload.bId}/download/${action.payload.type}`
            apiGet(url, res => dispatch(gotDownloadToken(res), e => notifyApiFailure("error preparing file for download", 'GET', url, data, e.status)))
            break
        case ActionTypes.GET_PROFIT_DATA:
            url = `profitability/${action.payload.bucket_id}`
            apiGet(url, data => dispatch(gotProfitData(action.payload.bucket_id, data)),
                e => {
                    if (e.status !== 404) {
                        notifyApiFailure("getting profit info.", 'GET', url, data, e.status)
                    }
                }
            )
            break
        case ActionTypes.GET_STOCK_BALANCE_DATA:
            url = `stockbalance/${action.payload.bucket_id}`
            apiGet(url, data => dispatch(gotStockBalanceData(action.payload.bucket_id, data)),
                e => {
                    if (e.status !== 404) {
                        notifyApiFailure("getting stock balance info.", 'GET', url, data, e.status)
                    }
                }
            )
            break
        case ActionTypes.GET_STOCK_BALANCE_COLUMN_DATA:
            url = `stockbalance/${action.payload.bucket_id}/data/${encodeURIComponent(action.payload.item_id)}`
            apiGet(url, data => dispatch(gotStockBalanceColumnData(data)),
                e => {
                    if (e.status !== 404) {
                        notifyApiFailure("getting stock balance data.", 'GET', url, data, e.status)
                    }
                }
            )
            break
        case ActionTypes.UPDATE_BUCKET_SETUP: 
            url = `bucket/${action.payload.bucket_id}/setup`
            apiPut(url, action.payload.data, 
                e => {
                    notifySuccess("Changes to bucket setup saved")
                    action.payload.callback({success: true, status: e.status})
                },
                e => {
                    if (e.status !== 404) {
                        notifyApiFailure("saving bucket setup.", 'PUT', url, data, e.status)
                    }
                    action.payload.callback({success: false, status: e.status})
                }
            )
            break;
        case ActionTypes.UPDATE_BUCKET_ACCESS: 
                url = `users/bucket_access/${action.payload.id}`
                apiPost(url, action.payload.data,
                    (updatedBucketAccess) => {
                        dispatch(gotUpdatedBucketAccess(updatedBucketAccess))
                    }, 
                    e => {
                        notifyApiFailure("saving bucket access.", "Post", url, data, e.status)
                }
            )
            break;
        case ActionTypes.DOWNLOAD_BUCKET_DATA_SPECS:
            console.log(action.payload)
            url = `bucket/${action.payload.id}/download`
            data = action.payload.data
            apiPost(url, data, result => {
                //do nothing, a notification should already have been sent
            }, e => {
                notifyApiFailure(`Downloading Data Specs`, 'POST', url, {id: action.payload.id}, e.status)
            })
            break;
        default:
            break
    }
}

export default BucketApiMiddleware
