import React, { useEffect, useRef, useState } from 'react'
import { DashboardQuery, ParsedDashboardData, QueryCriterion, ReportData, Column } from '../../types/transfertypes'
import { connect } from 'react-redux'
import GenericDialog from './GenericDialog'

import '../../css/EditDashboardFilterDialog.css'
import SingleSlicer2 from '../Report/SlicerComponents/SingleSlicer2'
import { getSlicerValues } from '../../actions/ReportActions'
import { getIdColumn, isDateColumn, isTextColumn, slicerEquals } from '../../helpers/ReportHelpers'
import ActiveCategoryIcon from '../Report/ReportComponents/ActiveCategoryIcon'
import WarningAcceptDialog from './WarningAcceptDialog'
import AddSlicerDialog2 from './AddSlicerDialog2'
import { CompareType } from '../../types/slicertypes'
import { getTodaysDate } from '../../helpers/GeneralHelpers'

export interface EditDashboardFilterDialogProps {
    dispatch?: any,
    slicerValues?: any,
    dashboard: ParsedDashboardData,
    filter: DashboardQuery,
    show: boolean,
    onClose: () => void,
    reportInfo: ReportData,
    saveFilters: (newFilter: DashboardQuery) => void,
    adding: boolean,
}

let mapStateToProps = (state: any, ownProps: EditDashboardFilterDialogProps) => {
    return {
        slicerValues: state.Report.slicerValues,
    }
}

let EditDashboardFilterDialog = (props: EditDashboardFilterDialogProps) => {

    
    const [addSlicerSearchStr, setAddSlicerSearchStr] = useState("")
    const [tmpSlicers, setTmpSlicers] = useState<QueryCriterion[]>([])
    const [tmpRemoveSlicer, setTmpRemoveSlicer] = useState<any>({})
    const [tmpLabel, setTmpLabel] = useState("")

    const warnRemoveSlicerRef = useRef<any>()

    const [slicerToEdit, setSlicerToEdit] = useState<QueryCriterion>()
    const [showEditSlicer, setShowEditSlicer] = useState(false)
    const [addingSlicer, setAddingSlicer] = useState(false)

    useEffect(() => {
        setTmpSlicers(props.filter.filter)
        setTmpLabel(props.filter.label)
    }, [props.dashboard, props.filter])


    if (props.reportInfo == undefined) {
        return <></>
    }

    let getSlicerColumns = () => {
        if (props.reportInfo == undefined) return []
        return props.reportInfo.info.model.columns.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }

    let openSlicer = (slicer: QueryCriterion) => {
        props.dispatch(getSlicerValues(props.reportInfo?.info.id, {column: slicer.column, filter: []}))
        setSlicerToEdit(slicer)
        setShowEditSlicer(true)
    }

    let columnClick = (column: Column, adding = false) => {
        if (props.reportInfo == undefined) return
        let bucket = props.reportInfo?.info
        if (bucket == null) {
            return
        }

        let slicers = props.filter.filter
        let target_values: any[] = []
        if (isDateColumn(column.name, props.reportInfo.info.model)) {
            target_values = [getTodaysDate()]
        }
        if(slicers.length > 0 && !adding) {
            let slicer = slicers.find(s => s.column === column.name)

            if (slicer){
                openSlicer(slicer)
            } else {
                setAddingSlicer(true)
                if(isTextColumn(column.name, props.reportInfo.info.model)){
                    props.dispatch(getSlicerValues(props.reportInfo?.info.id, {column: column.name, filter: []}))
                }
                setSlicerToEdit({column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
            }
        } else {
            setAddingSlicer(true)
            if(isTextColumn(column.name, props.reportInfo.info.model)){
                props.dispatch(getSlicerValues(props.reportInfo?.info.id, {column: column.name, filter: []}))
            }
            setSlicerToEdit({column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
        }
        setShowEditSlicer(true)
    }

    let confirmRemoveSlicer = (slicer: any) => {
        if (slicer.target_values.length > 1) {
            setTmpRemoveSlicer(slicer)
            warnRemoveSlicerRef.current.show()
        } else {
            removeSlicer(slicer)
        }
    }

    let removeSlicer = (slicer: any) => {
        let slicers = tmpSlicers.slice()
        let index = slicers.findIndex((s: any) => slicerEquals(s, slicer))
        if (index >= 0) {
            slicers.splice(index, 1)
            setTmpSlicers(slicers)
        }
    }

    const saveSlicer = (slicer: QueryCriterion) => {
        let slicers: QueryCriterion[] = structuredClone(tmpSlicers)

        if (addingSlicer) {
            if (slicer.target_values.length > 0) {
                slicers.push(slicer)
            }
        } else {
            if (slicerToEdit == undefined){
                if (slicer.target_values.length > 0) {
                    slicers.push(slicer)
                }
            } else {
                const index = slicers.findIndex(s => slicerEquals(s, slicerToEdit))
                if (index < 0) {
                    if (slicer.target_values.length > 0) {
                        slicers.push(slicer)
                    }
                } else {
                    if (slicer.target_values.length > 0) {
                        slicers[index] = slicer
                    } else {
                        slicers.splice(index, 1)
                    }
                }
            }
        }
        setTmpSlicers(slicers)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
        setAddingSlicer(false)
    }

    let saveFilters = () => {
        let ret: DashboardQuery = {label: tmpLabel, filter: tmpSlicers, id: props.filter.id}
        props.saveFilters(ret)
    }

    let disableSave = () => {
        return (tmpLabel == props.filter.label) && tmpSlicers == props.filter.filter
    }

    if (!props.show) {
        return <></>
    }

    let slicerColumns = getSlicerColumns()
    let activeCategoryColumn = props.reportInfo.info.model.categorization_name
    let bucket = props.reportInfo.info
    let idColumn = getIdColumn(bucket.model.columns)

    let getButtons = () => {
        return <div>
            <button style={{marginRight: 7}} onClick={saveFilters} disabled={disableSave() && !props.adding} className='btn btn-primary'>Save</button>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    return (
        <div>
            <GenericDialog show={props.show} title='Edit filter row' onClose={props.onClose} getButtons={getButtons} style={{minWidth: "50%"}}>
                <div className='dashboard-edit-slicers'>
                    <label htmlFor='filter-label'>Label:</label>
                    <input id='filter-label' className="form-control form-control-sm" placeholder="Label" value={tmpLabel} onChange={(e) => { setTmpLabel(e.target.value) } } />
                    <br/>
                    <div>Slicer:</div>
                    <div className="dropdown inline-block vertical-align-top margin-bottom-10px">
                        <button className="btn btn-sm btn-primary" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-plus"></i>
                        </button>
                        <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby="dropdownMenu2" style={{position: "absolute"}}>
                            <li className="text-center px-2">
                                <input type="text" value={addSlicerSearchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => setAddSlicerSearchStr(e.target.value) } />
                            </li>
                            <div className="dropdown-divider"></div>
                            {
                                slicerColumns
                                    .filter((c: any) => addSlicerSearchStr === '' || c.name.toLowerCase().includes(addSlicerSearchStr.toLowerCase()))
                                    .map((c: any, index: any) => <button className="hover-cursor dropdown-item" type="button" onClick={() => columnClick(c, true)} key={index}>
                                        {c.name === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                            <div>{c.name}</div>
                                            <div className='pl-2'><ActiveCategoryIcon /></div>
                                        </div> : <span>{c.name}</span>}
                                    </button>)
                            }
                        </div>
                    </div>
                    {
                        tmpSlicers.map((item: any, index: number) => {
                            return <SingleSlicer2
                                    slicer={item}
                                    index={index}
                                    key={index}
                                    openSlicer={(s: any) => openSlicer(s)}
                                    model={bucket.model}
                                    removeSlicer={(slicer: any) => confirmRemoveSlicer(slicer)}
                                    idColumn={idColumn}
                                />
                        })
                    }

                </div>
                {
                    slicerToEdit != null ?
                    <AddSlicerDialog2
                        slicer={slicerToEdit}
                        slicers={tmpSlicers}
                        show={showEditSlicer}
                        bucket={props.reportInfo.info}
                        saveSlicer={(s) => saveSlicer(s)}
                        onCancel={() => {setShowEditSlicer(false); setSlicerToEdit(undefined); setAddingSlicer(false)}}
                        />
                        : <></>
                }

                <WarningAcceptDialog ref={warnRemoveSlicerRef} title="Delete filter" accept={() => removeSlicer(tmpRemoveSlicer)} >
                            <p>Are you sure that you want to delete this slicer?</p>
                </WarningAcceptDialog>
            </GenericDialog>
        </div>
    )
}

// @ts-ignore
EditDashboardFilterDialog = connect(mapStateToProps)(EditDashboardFilterDialog)

export default EditDashboardFilterDialog
