import React, { useReducer, useEffect, CSSProperties, useState, useMemo, useRef } from 'react'
import { QueryCriterion, BucketInfo, BucketColumnType } from '../../types/transfertypes'
import { getStateReducer, getTodaysDate } from '../../helpers/GeneralHelpers'
import { CompareType, getPossibleCompareTypes, SlicerValues, isBetweenComparison } from '../../types/slicertypes'
import GenericDialog from './GenericDialog'
// @ts-ignore
import Select from 'react-select'
import ActiveCategoryIcon from '../Report/ReportComponents/ActiveCategoryIcon'
import { SelectOption } from '../../helpers/TypeHelpers'
import CategorizationTable from '../Report/TrendComponents/CategorizationTable'
import TextSlicer from '../Report/SlicerComponents/TextSlicer'
import { getIdColumn, isDateColumn, isNumberColumn, isTextColumn, isIDColumn } from '../../helpers/ReportHelpers'
import { gotSlicerValues, getSlicerValues } from '../../actions/ReportActions'
import AlertBanner, { alertTypes } from '../Alert/AlertBanner'
import { connect } from 'react-redux'
import { SingleKeyMap } from '../../helpers/Collections'
import moment from 'moment'
import ShowIf from '../Generic/ShowIf'
import HideIf from '../Generic/HideIf'

interface AddSlicerDialog2Props {
    slicer: QueryCriterion,
    slicers: QueryCriterion[],
    isFixedSlicer?: boolean,
    saveSlicer: (slicer: QueryCriterion) => void,
    onCancel: () => void,
    bucket: BucketInfo
    show: boolean,
    dispatch?: (val: any) => void,
    slicerValues?: SingleKeyMap<SlicerValues>,
    columns?: string[],
    textColumnHasTooManyValues?: (column: string) => boolean,
    slicerValuesTooBig?: SingleKeyMap<boolean>,
    textValues?: {[key: string]: {count: number, values: string[]} }
}

interface AlertConfig {
    type: string,
    title: string,
    text: string,
    dismiss: boolean,
}

const isComparable = (a: BucketColumnType, b: BucketColumnType) => {
    if (a == b) return true
    if (a == BucketColumnType.ID || a == BucketColumnType.Text || a == BucketColumnType.Categorization) {
        if (b == BucketColumnType.ID || b == BucketColumnType.Text || b == BucketColumnType.Categorization) {
            return true
        }
    }
    return false
}

const mapStateToProps = (state: any, ownProps: AddSlicerDialog2Props) => {
    return {
        slicerValues: state.Report.slicerValues,
        slicerValuesTooBig: state.Report.slicerValuesTooBig,
    }
}

let AddSlicerDialog2 = ({slicer, saveSlicer, onCancel, bucket, show, dispatch = () => {}, isFixedSlicer = false, textColumnHasTooManyValues = () => false, ...props}: AddSlicerDialog2Props) => {

    const [tmpSlicer, updateTmpSlicer] = useReducer(getStateReducer<QueryCriterion>(), {column: "", compare_type: CompareType.EQ, target_values: [], is_column: false})
    const [alertConfig, setAlertConfig] = useState<AlertConfig>()
    const [selectedCompareType, setSelectedCompareType] = useState<CompareType>(CompareType.EQ);

    const isDateCol = useMemo(() => isDateColumn(tmpSlicer.column, bucket.model), [tmpSlicer.column, bucket.model])
    const isNumberCol = useMemo(() => isNumberColumn(tmpSlicer.column, bucket.model), [tmpSlicer.column, bucket.model])
    const isTextCol = useMemo(() => isTextColumn(tmpSlicer.column, bucket.model), [tmpSlicer.column, bucket.model])

    const targetColumnOptions: SelectOption<string>[] = useMemo(() => {
        const type = bucket.model.columns.find(x => x.name === tmpSlicer.column)?.type
        if (type == undefined) return []
        let columns: string[] = []
        if (props.columns != null) {
           columns = props.columns.filter(c => {
                if (c == tmpSlicer.column) return false
                let col = bucket.model.columns.find(x => x.name == c)
                return col != undefined && isComparable(col.type, type)
            }) 
        } else {
            columns = bucket.model.columns.filter(x => isComparable(x.type, type) && x.name != tmpSlicer.column).map(x => x.name)
        }

        return columns.map(c => ({label: c, value: c}))
    }, [tmpSlicer.column])

    const idSearchTimeout = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        updateTmpSlicer(structuredClone(slicer))
    }, [slicer])

    useEffect(() => {
        let config: AlertConfig | undefined = undefined
        if (!tmpSlicer.is_column) {
            if (isNumberCol) {
                let inputValid = true
                if (tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN) {
                    inputValid = tmpSlicer.target_values.length == 2 && tmpSlicer.target_values[0].length > 0 && tmpSlicer.target_values[1].length > 0
                } else {
                    inputValid = tmpSlicer.target_values.length > 0 && tmpSlicer.target_values[0].length > 0
                }
                if (!inputValid) {
                    config = {
                        type: alertTypes.WARNING,
                        title: "",
                        text: "Please provide a number",
                        dismiss: false,
                    }
                }
                if(isBetweenComparison(tmpSlicer.compare_type) && inputValid) {
                    if (Number(tmpSlicer.target_values[0]) > Number(tmpSlicer.target_values[1])) {
                        config = {
                            type: alertTypes.DANGER,
                            title: "",
                            text: `${tmpSlicer.target_values[0]} is bigger than ${tmpSlicer.target_values[1]} and will therefore return 0 results`,
                            dismiss: false,
                        }
                    }
                }
            } 
            if (isDateCol) {
                let inputValid = true
                if (tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN) {
                    inputValid = tmpSlicer.target_values.length == 2 && moment(tmpSlicer.target_values[0]).isValid() && moment(tmpSlicer.target_values[1]).isValid()
                } else {
                    inputValid = tmpSlicer.target_values.length > 0 && moment(tmpSlicer.target_values[0]).isValid()
                }
                if(!inputValid){
                    config = {
                        type: alertTypes.WARNING,
                        title: "",
                        text: "Please provide a valid date",
                        dismiss: false,
                    }
                }
                if(isBetweenComparison(tmpSlicer.compare_type) && inputValid) {
                    if (tmpSlicer.target_values[0] > tmpSlicer.target_values[1]) {
                        config = {
                            type: alertTypes.DANGER,
                            title: "",
                            text: `${tmpSlicer.target_values[0]} does not happen before ${tmpSlicer.target_values[1]} and will therefore return 0 results`,
                            dismiss: false,
                        }
                    }
                }
            }
            if (isTextCol || idColumn === tmpSlicer.column) {
                let isContainsComparison = ( tmpSlicer.compare_type === CompareType.NCONTAINS || 
                    tmpSlicer.compare_type === CompareType.CONTAINS ||
                    tmpSlicer.compare_type === CompareType.STARTS ||
                    tmpSlicer.compare_type === CompareType.ENDS ||
                    tmpSlicer.compare_type === CompareType.NSTARTS ||
                    tmpSlicer.compare_type === CompareType.NENDS)

                if(isContainsComparison){
                    let trimmed = tmpSlicer.target_values.every(x => x === x.trim())
                    let doublespaces = tmpSlicer.target_values.some(x => x !== x.replace("  ", " "))

                    if(!trimmed && alertConfig == null) {

                        let example = tmpSlicer.target_values.find(x => x !== x.trim())

                        config = {
                            type: alertTypes.WARNING,
                            title: "",
                            text: "'" + example + "' starts or ends with a space",
                            dismiss: false,
                        }
                    }
                    if(doublespaces && alertConfig == null){
                        let example = tmpSlicer.target_values.find(x => x !== x.replace("  ", " "))

                        config = {
                            type: alertTypes.WARNING,
                            title: "",
                            text: "'" + example + "' contains 2 consecutive spaces",
                            dismiss: false,
                        }
                    }

                }
            }
        } else {
            if (tmpSlicer.target_values.length == 0) {
                config = {
                    type: alertTypes.WARNING,
                    title: "",
                    text: "Please choose a column",
                    dismiss: false,
                }
            } else {
                const col = bucket.model.columns.find(c => c.name == tmpSlicer.target_values[0])
                const chosenColumn = bucket.model.columns.find(c => c.name == tmpSlicer.column)
                if (col == undefined || chosenColumn == undefined || col.type != chosenColumn.type) {
                    config = {
                        type: alertTypes.WARNING,
                        title: "",
                        text: "Not a valid column",
                        dismiss: false,
                    }
                }
            }
        }
        setAlertConfig(config)

    }, [tmpSlicer.target_values])

    useEffect(() => {
        setSelectedCompareType(tmpSlicer.compare_type);
    }, [tmpSlicer.compare_type])

    const save = () => {
        saveSlicer(tmpSlicer)
    }

    let onTextSlicerSearchChange = (searchString: any) => {
        if (bucket == undefined) return
        const column = tmpSlicer.column

        //If id slicer, find slicer values
        if (isIDColumn(column, bucket.model)) {
            if (idSearchTimeout.current != undefined) {
                clearTimeout(idSearchTimeout.current)
            }
            idSearchTimeout.current = setTimeout(() => {
                //Write at least 1 character to start search
                if (searchString.length < 1) {
                    dispatch(gotSlicerValues({ values: [] }, column))
                } else {
                    const query = {
                        column: column,
                        filter: [
                            {
                                column: column,
                                compare_type:  (tmpSlicer.compare_type === CompareType.CONTAINS || tmpSlicer.compare_type === CompareType.NCONTAINS) ? CompareType.CONTAINS /* contains */ :  CompareType.STARTS /* starts with */, 
                                target_values: [searchString]
                            }
                        ]
                    }
                    dispatch(getSlicerValues(bucket.id, query))
                }
            }, 400)
        }
        if(isTextColumn(column, bucket.model)) {
            const query = {
                column: column,
                filter: [
                    {
                        column: column,
                        compare_type:(tmpSlicer.compare_type === CompareType.CONTAINS || tmpSlicer.compare_type === CompareType.NCONTAINS) ? CompareType.CONTAINS /* contains */ :  CompareType.STARTS /* starts with */, 
                        target_values: [searchString]
                    }
                ]
            }
            dispatch(getSlicerValues(bucket.id, query))
        }
    }

    let toggleCategorySlicerColumn = (column: string) => {
        let vals = tmpSlicer.target_values.slice()
        if (vals.includes(column)) {
            const index = vals.indexOf(column)
            vals.splice(index, 1)
        } else {
            vals.push(column)
        }
        updateTmpSlicer({target_values: vals})
    }

    const addValue = (value: string) => {
        let vals = tmpSlicer.target_values.slice()
        vals.push(value)
        updateTmpSlicer({target_values: vals})
    }

    const removeValue = (value: string) => {
        let vals = tmpSlicer.target_values.slice()
        const index = vals.indexOf(value)
        if (index > -1) {
            vals.splice(index, 1)
        }
        updateTmpSlicer({target_values: vals})
    }

    const onDateChange = (value: string, pos = 0) => {
        if (!moment(value).isValid()) return
        let target_values = structuredClone(tmpSlicer.target_values)
        target_values[pos] = value
        updateTmpSlicer({target_values: target_values})
    }

    const onNumberChange = (value: string, pos = 0) => {
        let target_values = structuredClone(tmpSlicer.target_values)
        target_values[pos] = value.replace(/[^0-9.-]/, '')
        updateTmpSlicer({target_values: target_values})
    }

    const saveOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation()
        if (e.key === 'Enter' && isValidInput()) { // if is enter
            setAlertConfig(undefined)
            save()
        }
    }

    const changeCompareType = (t: CompareType) => {
        if ((isDateCol || isNumberCol) && (t == CompareType.BETWEEN || t == CompareType.NBETWEEN) && !(tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN)) {
            updateTmpSlicer({compare_type: t, target_values: [tmpSlicer.target_values[0]]})
        } else {
            updateTmpSlicer({compare_type: t})
        }
    }

    const isValidInput = () => {
        if (!tmpSlicer.is_column) {
            if (isNumberCol) {
                let inputValid = true
                if (tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN) {
                    inputValid = tmpSlicer.target_values.length == 2 && tmpSlicer.target_values[0] != undefined && tmpSlicer.target_values[0].length > 0 && tmpSlicer.target_values[1] != undefined && tmpSlicer.target_values[1].length > 0 
                                 && Number(tmpSlicer.target_values[0]) < Number(tmpSlicer.target_values[1])
                } else {
                    inputValid = tmpSlicer.target_values.length > 0 && tmpSlicer.target_values[0] != undefined && tmpSlicer.target_values[0].length > 0
                }
                return inputValid
            } 
            if (isDateCol) {
                let inputValid = true
                if (tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN) {
                    inputValid = tmpSlicer.target_values.length == 2 && tmpSlicer.target_values[0] != undefined && tmpSlicer.target_values[1] != undefined && moment(tmpSlicer.target_values[0]).isValid() && moment(tmpSlicer.target_values[1]).isValid()
                                 && moment(tmpSlicer.target_values[0]) < moment(tmpSlicer.target_values[1])
                } else {
                    inputValid = tmpSlicer.target_values.length > 0 && tmpSlicer.target_values[0] != undefined && moment(tmpSlicer.target_values[0]).isValid()
                }
                return inputValid
            }
            if (isTextCol || idColumn === tmpSlicer.column) {
                let isContainsComparison = ( tmpSlicer.compare_type === CompareType.NCONTAINS || 
                    tmpSlicer.compare_type === CompareType.CONTAINS ||
                    tmpSlicer.compare_type === CompareType.STARTS ||
                    tmpSlicer.compare_type === CompareType.ENDS ||
                    tmpSlicer.compare_type === CompareType.NSTARTS ||
                    tmpSlicer.compare_type === CompareType.NENDS)

                if(isContainsComparison){
                    let trimmed = tmpSlicer.target_values.every(x => x === x.trim())
                    let doublespaces = tmpSlicer.target_values.some(x => x !== x.replace("  ", " "))

                    return trimmed && !doublespaces
                } else {
                    return true
                }
            }
        } else {
            if (tmpSlicer.target_values.length == 0) {
                return false
            } else {
                const col = bucket.model.columns.find(c => c.name == tmpSlicer.target_values[0])
                const chosenColumn = bucket.model.columns.find(c => c.name == tmpSlicer.column)
                if (col == undefined || chosenColumn == undefined || col.type != chosenColumn.type) {
                    return false
                }
            }

        }

        return true
    }

    const changeColumn = (column: string) => {
        let target_values: any[] = []
        if (isDateColumn(column, bucket.model)) {
            let formattedDate = getTodaysDate();
            target_values = [formattedDate]
        }
        if(isTextColumn(column, bucket.model)) {
            dispatch(getSlicerValues(bucket.id, {column: column, filter: []}))
        }
        updateTmpSlicer({column: column, target_values: target_values, compare_type: selectedCompareType, is_column: false})
    }

    const getButtons = () => {
        return <div>
            <button style={{ marginRight: 7 }} onClick={save} disabled={!isValidInput()} className='btn btn-primary'>Save</button>
            <button onClick={() => onCancel()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const compareOptions = getPossibleCompareTypes(tmpSlicer.column, bucket.model, tmpSlicer.is_column)
    const currentCompareOption = compareOptions.find(x => {
        return x.value === selectedCompareType
    })
    
    const columns: string[] = props.columns !== undefined ? props.columns : bucket.model.columns.map(c => c.name)
    const activeCategoryColumn = bucket.model.categorization_name
    const isActiveCategoryCol = tmpSlicer.column === activeCategoryColumn
    const idColumn = getIdColumn(bucket.model.columns)
    let textValues = new SingleKeyMap(props.slicerValues)
    if (props.textValues != undefined) {
        for (let key in props.textValues) {
            let vals = props.textValues[key]
            textValues.set(key, {column: key, data: {values: vals.values, result_set_too_big: false}})
        }
    }
    return <>
        <GenericDialog show={show} title="Data slicer" onClose={onCancel} getButtons={getButtons} style={{width: 500}}>
            <HideIf if={isFixedSlicer}>
            <div className='row mb-4'>
                <div className='col-md-7 pr-0'>
                    <Select
                        onChange={(v: SelectOption<string>) => {changeColumn(v.value); setAlertConfig(undefined)}}
                        isDisabled={!!isFixedSlicer}
                        options={columns.map(c => ({
                            value: c, label: c === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                <div>{c}</div>
                                <div className='pl-1'><ActiveCategoryIcon /></div>
                            </div> : c
                        }))}
                        value={{value: tmpSlicer.column, label: tmpSlicer.column === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                <div>{tmpSlicer.column}</div>
                                <div className='pl-1'><ActiveCategoryIcon /></div>
                            </div> : tmpSlicer.column}}
                        placeholder={slicer.column === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                            <div>{slicer.column}</div>
                            <div className='pl-1'><ActiveCategoryIcon /></div>
                        </div> : slicer.column}
                        styles={{
                            placeholder: (provided: CSSProperties, state: any) => ({
                                ...provided,
                                color: "#495057"
                            }),
                            menu: (baseStyles: CSSProperties, state: any) => (
                                {...baseStyles,
                                    zIndex: 800,
                                    position: "fixed",
                                    top: "-1",
                                    width: "275px",
                                }),
                            menuList: (baseStyles: CSSProperties, state: any) => ( 
                                {...baseStyles,
                                    maxHeight: "250px",
                                }),
                            control: (styles: CSSProperties) => ({ 
                                ...styles,
                                ':focus-within' : { 
                                    borderColor: "var(--tangerine-hover)", 
                                    boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                }
                            })
                        }}
                    />
                </div>
                <div className='col-md-5'>
                    <Select
                        onChange={(v: SelectOption<CompareType>) => changeCompareType(v.value)}
                        options={compareOptions}
                        isDisabled={isFixedSlicer}
                        value={currentCompareOption ?? compareOptions[0]}
                        placeholder={currentCompareOption ? currentCompareOption.label : "Choose..."}
                        styles={{
                            placeholder: (provided: CSSProperties, state: any) => ({
                                ...provided,
                                color: "#495057"
                            }),
                            menu: (baseStyles: CSSProperties, state: any) => (
                                {...baseStyles,
                                    zIndex: 800,
                                    position: "fixed",
                                    width: "177px",
                                    top: "-1",
                                }),
                            menuList: (baseStyles: CSSProperties, state: any) => ( 
                                {...baseStyles,
                                    maxHeight: "250px",
                                }),
                            control: (styles: CSSProperties) => ({ 
                                ...styles,
                                ':focus-within' : { 
                                    borderColor: "var(--tangerine-hover)", 
                                    boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                }
                            })
                        }}
                    />
                </div>
            </div>
            </HideIf>
            <ShowIf if={!tmpSlicer.is_column}>
                {isActiveCategoryCol && <>
                    <CategorizationTable
                        categorizations={bucket.model.categorization}
                        setSelectedCategory={(c: string) => toggleCategorySlicerColumn(c)}
                        selectedCategories={tmpSlicer.target_values}
                        clickableCategories={bucket.model.categorization.map(c => c.name)}
                    />

                </>}
                {(tmpSlicer.column == idColumn || isTextCol) && !isActiveCategoryCol && <>
                    <TextSlicer 
                        selectedValues={tmpSlicer.target_values}
                        selectedColumn={tmpSlicer.column}
                        textColumnHasTooManyValues={textColumnHasTooManyValues}
                        removeTextSlicerColumn={removeValue}
                        addTextSlicerColumn={addValue}
                        onTextSlicerSearchChange={onTextSlicerSearchChange}
                        slicerValues={textValues}
                        compareType={tmpSlicer.compare_type}
                        onTextChange={(values: any[]) => updateTmpSlicer({target_values: values})}
                        slicerValuesTooBig={props.slicerValuesTooBig}
                    />
                </>}
                {isDateCol && <div>
                    <input className="form-control" type="date" value={tmpSlicer.target_values[0] ?? ""} onChange={(e) => onDateChange(e.target.value)} onKeyDown={saveOnEnter} />
                    <ShowIf if={tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN}>
                        <div className="mt-2 mb-2">and</div>
                        <input className="form-control" type="date" value={tmpSlicer.target_values[1] ?? ""} onChange={(e) => onDateChange(e.target.value, 1)} onKeyDown={saveOnEnter} />
                        <div className="mt-2 mb-2">
                            <i>The results will{tmpSlicer.compare_type == CompareType.NBETWEEN ? " not" : ""} include the provided dates</i>
                        </div>
                    </ShowIf>

                </div>}
                {isNumberCol && <>
                    <input type="text" className="form-control" placeholder="Insert number" pattern='-?\d+(\.\d+)?' value={tmpSlicer.target_values[0] ? tmpSlicer.target_values[0] : ""} onKeyDown={saveOnEnter} onChange={e => onNumberChange(e.target.value)} />
                    <ShowIf if={tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN}>
                        <div className="mt-2 mb-2">and</div>
                        <input type="text" className="form-control" placeholder="Insert number" pattern='-?\d+(\.\d+)?' value={tmpSlicer.target_values[1] ? tmpSlicer.target_values[1] : ""} onKeyDown={saveOnEnter} onChange={e => onNumberChange(e.target.value, 1)} />
                        <div className="mt-2 mb-2">
                            <i>The results will{tmpSlicer.compare_type == CompareType.NBETWEEN ? " not" : ""} include the provided numbers</i>
                        </div>
                    </ShowIf>
                </>}
                <HideIf if={tmpSlicer.compare_type == CompareType.BETWEEN || tmpSlicer.compare_type == CompareType.NBETWEEN}>
                <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <button onClick={() => updateTmpSlicer({is_column: true, compare_type: selectedCompareType, target_values: []})} className="link-btn">Slice on column</button>
                </div>
                </HideIf>
            </ShowIf>
            <ShowIf if={tmpSlicer.is_column}>
                <Select
                    onChange={(v: SelectOption<string>) => updateTmpSlicer({target_values: [v.value]})}
                    options={targetColumnOptions}
                    value={tmpSlicer.target_values.length > 0 ? {label: tmpSlicer.target_values[0], value: tmpSlicer.target_values[0]} : null}
                    placeholder={tmpSlicer.target_values.length > 0 ? tmpSlicer.target_values[0] : "Choose a column"}
                    styles={{
                        placeholder: (provided: CSSProperties, state: any) => ({
                            ...provided,
                            color: "#495057"
                        }),
                        menu: (baseStyles: CSSProperties, state: any) => (
                            {...baseStyles,
                                zIndex: 800,
                                position: "fixed",
                                width: "275px",
                                top: "-1",
                            }),
                        menuList: (baseStyles: CSSProperties, state: any) => ( 
                            {...baseStyles,
                                maxHeight: "250px",
                            }),
                        control: (styles: CSSProperties) => ({ 
                            ...styles,
                            ':focus-within' : { 
                                borderColor: "var(--tangerine-hover)", 
                                boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                            }
                        })
                    }}
                />
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                        <button onClick={() => updateTmpSlicer({is_column: false, compare_type: selectedCompareType, target_values: []})} className="link-btn">Slice on value</button>
                    </div>
            </ShowIf>
            {!!alertConfig && <><br /><AlertBanner config={alertConfig} style={{paddingTop:'10px'}}></AlertBanner></>}
        </GenericDialog>
    </>
}
// @ts-ignore
AddSlicerDialog2 = connect(mapStateToProps)(AddSlicerDialog2)
export default AddSlicerDialog2
