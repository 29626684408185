import { MailBundleWithRelations, Plan } from "../types/reportingtypes"
import { packAction } from "./ActionTypes"
import * as ActionTypes from './ActionTypes'

export const getOrgMailBundles = (orgId:number) => {
    return packAction(ActionTypes.GET_ORG_MAILBUNDLES, { orgID: orgId})
}

export const gotOrgMailBundles = (mailBundles:MailBundleWithRelations[]) => {
    return packAction(ActionTypes.GET_ORG_MAILBUNDLES_DONE, mailBundles)
}

export const createMailBundle = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.CREATE_MAILBUNDLE, mailBundle)
}

export const createMailBundleDone = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.CREATE_MAILBUNDLE_DONE, mailBundle)
}

export const updateMailBundle = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.UPDATE_MAILBUNDLE, mailBundle)
}

export const updateMailBundleDone = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.UPDATE_MAILBUNDLE_DONE, mailBundle)
}

export const deleteMailBundle = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.DELETE_MAILBUNDLE, mailBundle)
}

export const deleteMailBundleDone = (mailBundleId: number) => {
    return packAction(ActionTypes.DELETE_MAILBUNDLE_DONE, {mailBundleId: mailBundleId})
}

export const toggleMailBundleActive = (mailBundle: MailBundleWithRelations) => {
    return packAction(ActionTypes.TOGGLE_MAILBUNDLE_ACTIVE, mailBundle)
}

export const toggleMailBundleActiveDone = (mailBundleId: number, active: boolean) => {
    return packAction(ActionTypes.TOGGLE_MAILBUNDLE_ACTIVE_DONE, {mailBundleId: mailBundleId, active: active})
}

export const setActiveMultipleMailbundles = (ids: number[], activate: boolean, orgID: number) => {
    if (activate){
        return packAction(ActionTypes.ACTIVATE_MULTIPLE_MAILBUNDLES, {ids: ids, orgID})
    }
    return packAction(ActionTypes.DEACTIVATE_MULTIPLE_MAILBUNDLES, {ids:ids, orgID})
}

export const setActiveMultipleMailbundlesDone = (ids: number[], activate: boolean) => {
    if(activate){
        return packAction(ActionTypes.ACTIVATE_MULTIPLE_MAILBUNDLES_DONE, {ids: ids})
    }
    return packAction(ActionTypes.DEACTIVATE_MULTIPLE_MAILBUNDLES_DONE, {ids: ids})
}

export const batchDeleteMailbundles = (ids: number[], orgID: number) => {
    return packAction(ActionTypes.BATCH_DELETE_MAILBUNDLES, {ids: ids, orgID})
}

export const batchDeleteMailbundlesDone = (ids: number[]) => {
    return packAction(ActionTypes.BATCH_DELETE_MAILBUNDLES_DONE, {ids: ids})
}

export const getAllPlans = () => {
    return packAction(ActionTypes.GET_ALL_REPORTING_PLANS)
}
export const getAllPlansDone = (plans: Plan[]) => {
    return packAction(ActionTypes.GET_ALL_REPORTING_PLANS_DONE, plans)
}