import React from 'react'

import '../css/Tabs.css'

export interface TabsProps {
    onChange: (value: any) => void,
    value: any,
    options: TabsOption[],
    className?: string,
    style?: React.CSSProperties
}

export interface TabsOption {
    label: string,
    value: any,
    disabled?: boolean,
    tooltip?: string,
}

const Tabs = (props: TabsProps) => {

    return (
        <div className={`tabs ${props.className ?? ''}`} style={props.style ?? {}}>
            {
                props.options.map((o, i) => {
                    return (
                        <div className={`tab abc-click ${o.disabled ? 'tab-disabled': ''} ${props.value === o.value ? 'tab-chosen' : ''}`} key={i} onClick={() => { if(!o.disabled){ props.onChange(o.value);}}}>
                            <span title={`${o.tooltip && o.tooltip !== "" ? o.tooltip : o.label}`}>{o.label}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Tabs