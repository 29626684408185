import { useRef, useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import ReactTooltip from 'react-tooltip'
import ShowIf from "../../Generic/ShowIf"

const TextSlicer = (props) => {
    const { selectedValues, selectedColumn, textColumnHasTooManyValues, removeTextSlicerColumn,
        addTextSlicerColumn, slicerValues, onTextChange, compareType, slicerValuesTooBig } = props

    const [textSlicerSearch, setTextSlicerSearch] = useState('')
    const selectRef = useRef(null)


    const onTextSlicerSearchChange = (value) => {
        props.onTextSlicerSearchChange(value)
        setTextSlicerSearch(value)
    }
    let filterTextSlicer = () => {
        let res = { data: { values: [] }, tooBig: slicerValuesTooBig.get(selectedColumn, false) }
        let tmpSlicerValues = slicerValues?.get(selectedColumn, { data: { values: [] } }) ?? {data: {values:[]}}
        
        if(!!tmpSlicerValues.data.values){
            tmpSlicerValues.data.values.forEach((item, index) => {
                if (selectedValues.indexOf(item) === -1) {
                    res.data.values.push(item)
                }
            })
        }
        return res
    }
    
    const filteredTextValues = useMemo(() => {
        return filterTextSlicer()
    }, [slicerValues, selectedValues])

    let searchFilter = (value) => {
        if (textSlicerSearch !== '') {
            return value.toLowerCase().indexOf(textSlicerSearch.toLowerCase()) !== -1
        } else {
            return true
        }
    }


    const _filteredTextValues = useMemo(() => {
        if (textColumnHasTooManyValues(selectedColumn) && !selectedValues.includes('')) {
            const arr = filterTextSlicer().data.values.slice()
            arr.push("")
            return arr
        }
        return filterTextSlicer().data.values
    }, [slicerValues, selectedValues])

    useEffect(() => {
        if (ReactDOM.findDOMNode(selectRef.current)) {
            ReactDOM.findDOMNode(selectRef.current).selectedIndex = -1
        }
    }, [_filteredTextValues])

    const _filteredTextValuesTooBig = filteredTextValues.tooBig

    const _comparetype = () => {
        switch (compareType) {
            case '1':
            case 1:
                return `equals`
            case '9':
            case 9:
                return `contains`
            case '6':
            case 6:
                return `doesn't equal`
            case '12':
            case 12:
                return `doesn't contain`
            case '7':
            case 7:
                return `starts with`
            case '10':
            case 10:
                return `doesn't start with`
            case '8':
            case 8:
                return `ends with`
            case '11':
            case 11:
                return `doesn't end with`
            default: return 'not supported'
        }
    }

    let isContainsComparison = (compareType === "12" || compareType === 12 || 
                                 compareType === "9" || compareType === 9 ||
                                 compareType === "7" || compareType === 7 ||
                                 compareType === "8" || compareType === 8 ||
                                 compareType === "10" || compareType === 10 ||
                                 compareType === "11" || compareType === 11)

    return <>
        <div className="form">
        <ShowIf if={!isContainsComparison}>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-search"></i></div>
                    </div>

                    <input id="textSlicerModalFocus" autoFocus className="form-control margin-bottom-5px" placeholder="Search" value={textSlicerSearch} onChange={(e) => onTextSlicerSearchChange(e.target.value)} />
                </div>
                <div className="input-group mb-2" style={{height: "23px"}}>
                    <ShowIf if={ _filteredTextValues.filter(searchFilter).length > 1}>
                        <button className={`btn btn-xs btn-default`} onClick={() => onTextChange([...selectedValues, ..._filteredTextValues.filter(searchFilter)])}>Select all</button>
                    </ShowIf>
                    <ShowIf if={textSlicerSearch.length > 0 &&_filteredTextValues.filter(searchFilter).length == 0  && !_filteredTextValuesTooBig} >
                        <span className="center italic" style={{width: "100%"}}>The search is empty. Please try again...</span>
                    </ShowIf>
                    <ShowIf if={textSlicerSearch.length > 0 && _filteredTextValuesTooBig} >
                        <span className="center italic" style={{width: "100%"}}>The result is too big. Add more characters...</span>
                    </ShowIf>
                </div>
                
                <select ref={selectRef} className="form-control width-100-p" size="6" onChange={(e) => addTextSlicerColumn(e.target.value)}>
                    {
                        _filteredTextValues.filter(searchFilter).map((item, index) => {
                            return (
                                <option className="noselect" key={index} value={item}>{item === "" ? "No value" : item}</option>
                            )
                        })
                    }
                </select>
            </ShowIf>
            <ShowIf if={isContainsComparison}>
                <div className="form">
                    <input type="text" className="form-control inline-block width-90-p" value={selectedValues.join(",")} onChange={e => {
                        let values = e.target.value.split(",")
                        onTextChange(values)
                    } } />   
                    <i data-tip data-for={`tooltip-hell-yeah`} className="fa fa-info-circle margin-left-10px glyphicon-info-gray"></i>
                    <ReactTooltip id={`tooltip-hell-yeah`} type='dark' effect='solid' place='bottom'>
                        Use % (percentage) as a wildcard for one or more characters.<br />
                        Use _ (underscore) as a single wildcard character.<br />
                        Use , (comma) if more matches are needed.
                    </ReactTooltip>
                </div>
            </ShowIf>
            <hr className="margin-bottom-10px margin-top-10px" />
            <div>
            <label><b>{selectedColumn}</b> {_comparetype()} the following values:</label>
                <ShowIf if={ selectedValues.length > 1 && !isContainsComparison}>
                    <button className={`btn btn-xs btn-danger float-right`} onClick={() => onTextChange([])}>Clear all</button>
                </ShowIf>
            </div>

            {selectedValues.length < 1 ? (<p><i>None selected</i></p>) : null}
            <div className="form">
                {
                    selectedValues.map((item, index) => {
                        return (
                            isContainsComparison ? 
                            <span key={index} className="abc-text-filer-value badge badge-secondary textSlicerSelected">{item === "" ? "No value" : item}</span>
                            :
                            <span key={index} className="abc-click abc-text-filer-value badge badge-secondary textSlicerSelected"
                                id="option-compose-viewer-slicerTextValues-remove" onClick={() => removeTextSlicerColumn(item)}>
                                {item === "" ? "No value" : item} <i className="fa fa-times filter-label-remove"></i>
                            </span>
                        )
                    })
                }
            </div>
        </div>
    </>
}

export default TextSlicer
