import { useMemo } from "react"
import { getCompareTypeSymbol, isDateColumn, isTextColumn } from "../../../helpers/ReportHelpers"
import moment from 'moment'
import ShowIf from "../../Generic/ShowIf"
import { CompareType } from "../../../types/slicertypes"
import HideIf from "../../Generic/HideIf"

const SingleSlicer2 = ({ slicer, openSlicer, index, model, removeSlicer, idColumn }) => {
    const isTextCol = useMemo(() => isTextColumn(slicer.column, model), [slicer.column, model])
    const isDateCol = useMemo(() => isDateColumn(slicer.column, model), [slicer.column, model])

    const isDisabled = slicer.disabled ?? false

    let checkedOpenSlicer = (slicer, index) => {
        if (!isDisabled) {
            openSlicer(slicer, index)
        }
    }

    let checkedRemoveSlicer = (slicer) => {
        if (!isDisabled) {
            removeSlicer(slicer)
        }
    }


    return <div className={`single-slicer ml-2 margin-bottom-10px ${isDisabled ? "disabled-slicer" : ""}`}>
        <div className={`btn-group`}>
            <button onClick={() => checkedOpenSlicer(slicer, index)} type="button" className="btn btn-default btn-sm" disabled={isDisabled}>
                {slicer.column} 
                <span className="slicer-column-name-span"  title={ slicer.target_values.length == 0 ? "No value" : (slicer.compare_type === 9 || slicer.compare_type === 12 ? '"' + slicer.target_values.join('", "') + '"' : slicer.target_values.join(", "))}>
                    {
                        !isTextCol && idColumn !== slicer.column ?
                            <>
                                <div style={{marginRight: 5}}>
                                    &nbsp;
                                    {getCompareTypeSymbol(slicer.compare_type)}
                                </div>
                                <ShowIf if={slicer.compare_type == CompareType.BETWEEN || slicer.compare_type == CompareType.NBETWEEN}>

                                    <span>{slicer.target_values[0]} and {slicer.target_values[1]}</span>
                                </ShowIf>
                                <HideIf if={slicer.compare_type == CompareType.BETWEEN || slicer.compare_type == CompareType.NBETWEEN}>
                                    {isDateCol ? moment(slicer.target_values[0]).format("DD-MM-YYYY") : slicer.target_values[0]}
                                </HideIf>
                            </>
                            : <>&nbsp;({slicer.target_values.length}) <i className="fa fa-filter slicer-filter-icon" /></>
                    }
                </span>
            </button>
            <button onClick={_ => checkedRemoveSlicer(slicer)} className="btn btn-sm btn-default" type="button" disabled={isDisabled}>
                <i className="fa fa-times"></i>
            </button>
        </div>

    </div>
}

export default SingleSlicer2
