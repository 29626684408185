/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { deepClone } from '../../../helpers/GeneralHelpers'
import ShowIf from '../../Generic/ShowIf'
import { getIdColumn } from '../../../helpers/ReportHelpers'
import ReactTooltip from "react-tooltip"

/* CSS */
// import '../css/dimensions.css'

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

class GraphControls extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { kpi1, kpi2 } = this.props
        if ((!prevProps.kpi1 && kpi1) || (!prevProps.kpi2 && kpi2)) {
            let selectedKpi1 = kpi1 !== null ? deepClone(kpi1) : null
            let selectedKpi2 = kpi2 !== null ? deepClone(kpi2) : null
            if (selectedKpi1 && JSON.stringify(selectedKpi1.settings) === "{}") selectedKpi1.settings = { type: "bar" }
            if (selectedKpi2 && JSON.stringify(selectedKpi2.settings) === "{}") selectedKpi2.settings = { type: "bar" }

            this.props.setKpisSettings(selectedKpi1, selectedKpi2)
        }
    }

    setSettings(type, num) {
        let selectedKpi1 = this.props.kpi1 !== null ? deepClone(this.props.kpi1) : null
        let selectedKpi2 = this.props.kpi2 !== null ? deepClone(this.props.kpi2) : null
        if (selectedKpi1 !== null && num === 1) selectedKpi1.settings.type = type
        if (selectedKpi2 !== null && num === 2) selectedKpi2.settings.type = type

        this.props.setKpisSettings(selectedKpi1, selectedKpi2)
    }

    setOptions(key, val) {
        let opt = this.props.graphOptions !== null ? deepClone(this.props.graphOptions) : null
        if (opt) opt[key] = val

        if (opt && key == "zoom" && val > 12){
            opt["axisNum"] = 1

            let selectedKpi = this.props.kpi1 !== null ? deepClone(this.props.kpi1) : (this.props.kpi2 !== null ? deepClone(this.props.kpi2) : null)
            if(selectedKpi){
                selectedKpi.settings.type = "bar"
            }
            this.props.setKpisSettings(selectedKpi, null)

        }

        this.props.setGraphOptions(opt)
    }

    goToTrend() {
        let selectedKpi1 = this.props.kpi1 !== null ? deepClone(this.props.kpi1) : null
        let selectedKpi2 = this.props.kpi2 !== null ? deepClone(this.props.kpi2) : null

        let idColumn = getIdColumn(this.props.info.model.columns)

        if(selectedKpi1 && selectedKpi1.kpi.column != idColumn)
            this.props.goToTrend({ kpi: selectedKpi1.kpi }, selectedKpi1.index)
        else if (selectedKpi2 && selectedKpi2.kpi.column != idColumn)
            this.props.goToTrend({ kpi: selectedKpi2.kpi }, selectedKpi2.index)
    }
    
    render() {
        const { kpi1, kpi2, lastAdded, graphOptions } = this.props
        let bothKpi = kpi1 !== null && kpi2 !== null ? true : false
        let kpi1name = kpi1 !== null ? { column: kpi1.kpi.column, color: "kpi1Selected", settings: kpi1.settings, index: 1 } : null
        let kpi2name = kpi2 !== null ? { column: kpi2.kpi.column, color: "kpi2Selected", settings: kpi2.settings, index: 2 } : null
        const startAtZero = graphOptions ? graphOptions.startAtZero : true
        const zoom = graphOptions ? parseInt(graphOptions.zoom) : 12
        const axisNum = graphOptions ? graphOptions.axisNum : 1

        // if both kpi are selected and the last added was kpi1 (the blue one) we swap so the blue goes to the right yaxis
        if (lastAdded === 1 && bothKpi) {
            [kpi1name, kpi2name] = [kpi2name, kpi1name]
        }

        let idColumn = getIdColumn(this.props.info.model.columns)
        let isDiffViewValid = (kpi1name && kpi1name.column != idColumn) || (kpi2name && kpi2name.column != idColumn)

        // if we deselect the blue kpi1 the green kpi2 should go to the left
        // if (kpi1 === null && kpi2 !== null) {
        //     const settings = JSON.stringify(kpi2.settings) === "{}" ? kpi2name.settings : kpi2.settings
        //     kpi1name = { column: kpi2.kpi.column, color: "kpi2Selected", settings: settings }
        //     kpi2name = null
        // }
        let datasetFoundReversed = this.props.datasetFound.reverse()
        let doesDataHave12To36Months = false
        let doesDataHave24To36Months = false
        
        if(this.props.info.trendsets > 12) {
            for(let i=12; i < datasetFoundReversed.length; i++){
                doesDataHave12To36Months = doesDataHave12To36Months || datasetFoundReversed[i]
            }
        }

        if(this.props.info.trendsets > 24) {
            for(let i=24; i < datasetFoundReversed.length; i++){
                doesDataHave24To36Months = doesDataHave24To36Months || datasetFoundReversed[i]
            }
        }

        return (
            <div className="mt-4 mb-4">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className='d-flex flex-row align-items-center' style={{flex: "1 1 0%"}}>
                        {kpi1name && <div className="d-flex flex-row align-items-center">
                            <div className="pr-2">
                                {kpi1name.column}
                            </div>
                            <div className={`${kpi2name ? 'margin-right-20px' : ''}`}>
                                <div className="btn-group">
                                    <ShowIf if={zoom == 12}>
                                    <button type="button" onClick={() => this.setSettings("line", kpi1name.index)} className={`btn btn-sm btn-default ${kpi1name.settings.type === "line" ? kpi1name.color : ''}`}><i className="fa fa-line-chart"></i></button>
                                    </ShowIf>
                                    <button type="button" onClick={() => this.setSettings("bar", kpi1name.index)} className={`btn btn-sm btn-default ${kpi1name.settings.type === "bar" ? kpi1name.color : ''}`}><i className="fa fa-bar-chart"></i></button>
                                </div>
                            </div>
                        </div>}
                        {axisNum === 1 && kpi2name && <div className={`d-flex flex-row align-items-center`}>
                            <div className="pr-2">
                                {kpi2name.column}
                            </div>
                            <div className="pr-4">
                                <div className="btn-group">
                                    <button type="button" onClick={() => this.setSettings("line", kpi2name.index)} className={`btn btn-sm btn-default ${kpi2name.settings.type === "line" ? kpi2name.color : ''}`}><i className="fa fa-line-chart"></i></button>
                                    <button type="button" onClick={() => this.setSettings("bar", kpi2name.index)} className={`btn btn-sm btn-default ${kpi2name.settings.type === "bar" ? kpi2name.color : ''}`}><i className="fa fa-bar-chart"></i></button>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="d-flex flex-row align-items-center" >
                        <div className="d-flex flex-row align-items-center pr-4">
                            <div className="margin-right-10px">Vertical axis</div>
                            <div>
                                <button className={`btn btn-sm btn-default margin-right-10px ${startAtZero ? 'active' : ""}`} onClick={() => this.setOptions("startAtZero", !startAtZero)}>Show 0</button>
                            </div>
                            <ShowIf if={zoom == 12}>
                            <div className="btn-group" role="group" aria-label="...">
                                <button onClick={() => this.setOptions("axisNum", 1)} className={`btn btn-default btn-sm custom-icon-padding ${axisNum === 1 ? 'active' : ''}`}><i className="fa fa-long-arrow-up font-size-16px"></i></button>
                                <button disabled={!bothKpi} onClick={() => this.setOptions("axisNum", 2)} data-for="tooltip info" data-tip className={`btn btn-default btn-sm custom-icon-padding trend-arrow-button ${axisNum === 2 ? 'active' : ''}`}><i  className="fa fa-long-arrow-up font-size-16px" ></i> <i className="fa fa-long-arrow-up font-size-16px" ></i></button>
                            <ShowIf if= {!bothKpi && !(zoom == 24 || zoom == 36)}>
                            <ReactTooltip id="tooltip info" delayShow={750} effect='solid' type='light'>
                                <div style={{maxWidth: 120}}>
                                    <span style={{fontSize:10}}>
                                    Select an additional KPI
                                    </span>
                                </div>
                            </ReactTooltip>
                            </ShowIf>
                            </div>
                            </ShowIf>
                        </div>
                        <div className="pr-4">
                            <span className="margin-right-10px">Zoom</span>
                            <div className="btn-group" role="group" aria-label="...">
                                <button onClick={() => this.setOptions("zoom", 12)} className={`btn btn-default btn-sm ${zoom === 12 ? 'active' : ''}`}>12 months</button>
                                <ShowIf if={this.props.info.trendsets > 12}>
                                    <ShowIf if={doesDataHave12To36Months}>
                                        <button onClick={() => this.setOptions("zoom", 24)} className={`btn btn-default btn-sm ${zoom === 24 ? 'active' : ''}`}>24 months</button>
                                    </ShowIf>
                                    <ShowIf if={!doesDataHave12To36Months}>
                                        <button disabled={true} className={`btn btn-default btn-sm`}>24 months</button>
                                    </ShowIf>
                                </ShowIf>
                                <ShowIf if={this.props.info.trendsets > 24}>
                                    <ShowIf if={doesDataHave24To36Months}>
                                        <button onClick={() => this.setOptions("zoom", 36)} className={`btn btn-default btn-sm ${zoom === 36 ? 'active' : ''}`}>36 months</button>
                                    </ShowIf>
                                    <ShowIf if={!doesDataHave24To36Months}>
                                        <button disabled={true} className={`btn btn-default btn-sm`}>36 months</button>
                                    </ShowIf>
                                </ShowIf>
                            </div>
                        </div>
                        <ShowIf if={isDiffViewValid && !this.props.forPrint}>
                        <div className="pr-4">
                            <button onClick={() => this.goToTrend()} className={`btn btn-default btn-sm`}>Difference View</button>           
                        </div>
                        </ShowIf>
                    </div>
                    <div className='d-flex flex-row-reverse' style={{flex: "1 1 0%"}}>
                        {kpi2name && axisNum === 2 && <div className={`d-flex flex-row align-items-center`}>
                            <div className="pr-2">
                                {kpi2name.column}
                            </div>
                            <div className="">
                                <div className="btn-group">
                                    <button type="button" onClick={() => this.setSettings("line", kpi2name.index)} className={`btn btn-sm btn-default ${kpi2name.settings.type === "line" ? kpi2name.color : ''}`}><i className="fa fa-line-chart"></i></button>
                                    <button type="button" onClick={() => this.setSettings("bar", kpi2name.index)} className={`btn btn-sm btn-default ${kpi2name.settings.type === "bar" ? kpi2name.color : ''}`}><i className="fa fa-bar-chart"></i></button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

GraphControls = connect(mapStateToProps)(GraphControls)

export default GraphControls