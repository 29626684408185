import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Select from '../components/Generic/CustomSelect'
import { User, Checklist, DataBucket, ReportData, BucketSetup} from '../types/transfertypes'
import { downloadBucketDataSpecs, getBucketsDataInfo, updateBucketSetup} from '../actions/BucketActions'
import { getUsers } from '../actions/SystemActions'
import { getUserInfo } from '../actions/UserActions'
import { SingleKeyMap } from '../helpers/Collections'
import ReactTooltip from 'react-tooltip'
import ShowIf from '../components/Generic/ShowIf'
import ReactMarkdown from 'react-markdown'



/* CSS */
import '../css/DataSpecs.css'
import { getReportGridData} from '../actions/ReportActions'
import AddBucketDescription from '../components/Dialogs/AddBucketDescription'
import EditBucketDescription from '../components/Dialogs/EditBucketDescription'
import { is } from '../helpers/PermissionHelpers'
import RequirePermission from '../components/RequirePermission'
import { notifySuccess } from '../helpers/NotificationManager'



export interface DataSpecsProps {
    users: User[],
    user: User,
    checklist: Checklist,
    buckets: SingleKeyMap<DataBucket>,
    bucket: DataBucket,
    dispatch: any,
    bucketId: any,
    itemId: any,
    onClose: () => void,
    downloadToken: any,
    reportInfo: ReportData,
    griddata: any

}
let mapStateToProps = (state: any, ownProps: DataSpecsProps ) => {
    return {
        item: state.Item.item,
        bucket: state.Bucket.buckets,
        buckets: state.Bucket.bucketDetails,
        data: state.Item.data,
        user: state.User.info,
        users: state.System.users,
        download_token: state.Bucket.downloadToken,
        reportInfo: state.Info.reportInfo,
        griddata: state.Report.griddata,

    }
}
let DataSpecs = (props: DataSpecsProps) => {
    type sortOrder = "asc" | "desc" | "default";

    const [search, setSearch] = useState("")
    const [chosenBucket, setChosenBucket] = useState<  DataBucket| undefined>(undefined)
    const [editDescriptions, setEditDescriptions] = useState(false)
    const [filterType, setFilterType] = useState<string[]>([])
    const [modalEdit, setModalEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [chosenColumnName, setChosenColumnName] = useState("")
    const [chosenColumnDescription, setChosenColumnDescription] = useState("")
    const [columns, setColumns] = useState<string[]>([])
    const [bucketChange, setBucketChange] = useState(false)
    const [inactData, setInactData] = useState<string[]>([])
    const [erpData, setErpData] = useState<string[]>([])
    const [sortDirection, setSortDirection] = useState<sortOrder>("default")
    const [columnsForUpdate, setColumnsForUpdate] = useState<string[] >([])
    const [shownCols, setShownCols] = useState<string[]>([])
    const [colsDesc, setColsDesc] = useState<string[]>([])
    const [colsAsc, setColsAsc] = useState<string[]>([])
    const [bucketSetup, setBucketSetup] = useState< BucketSetup | undefined >(undefined)
    const [updatedBucketSetup, setUpdatedBucketSetup] = useState< BucketSetup | undefined >(undefined)
    const [hasSetupChanged, setHasSetupChanged] = useState(false)
    const [checkedInactData, setCheckedInactData] = useState<string[] >([])
    const [exampleMap, setExampleMap] = useState< Map<string, any>>()


    // On Mount
    useEffect(() => {
        let { dispatch } = props
        dispatch(getUsers())
        dispatch(getUserInfo())
        props.dispatch(getBucketsDataInfo())  
    }, [])

    useEffect(() => {
        if (chosenBucket === undefined) {
            let id = Object.values(props.buckets.data)[0]
            setChosenBucket(id)
            setBucketChange(true)

        } 
    }, [props.buckets])

    useEffect(() => {
        if (bucketChange) {
            setBucketChange(false)
            columnOrder()   
            getColumnsToRender()
        }
            createTypeArray()

    }, [bucketChange])

    useEffect(() => {
        getExampleArray()

    }, [props.griddata])
    useEffect(() => {
        if (chosenBucket != undefined) {
            setBucketSetup(chosenBucket.info.setup)
        }
        getGridData()
        setFilterType([])
        
    }, [chosenBucket])
    useEffect(() => {
        if (chosenBucket != undefined) {
            sortColumns()
        } else 
       sortColumns()
        
    }, [sortDirection])

    useEffect(() => {
        getColumnsToRender()
    
    }, [search])
    useEffect(() => {
        setBucketSetup(updatedBucketSetup)
        createTypeArray()

    }, [updatedBucketSetup])
    useEffect(() => {
        getColumnsToRender()    
    }, [editDescriptions])

    useEffect (() => {
        if (columnsForUpdate == undefined) {
            sortColumns()
        }
        else {
        updatSortColumns()
        }
    }, [columnsForUpdate])
    useEffect(() => {
        getColumnsToRender()
    }, [chosenColumnDescription])
    

    let options = new Set<string>()
    props.buckets.forEach((_, i) => {
        options.add(i.id)
    })

    let actualOptions = Array.from(options).map((o) => {
        return {value: o, label: props.buckets.get(o) ? props.buckets.get(o)?.info.name : ""}
    })
    const setBucket = (b: any) => {
        props.buckets.forEach((_, bucket) => {
            if ((b['value']) == bucket.id) {
                setChosenBucket(bucket)
            }
        })
    }
    const updatSortColumns = () => {
        let c: string[] = []  
    
        if (sortDirection === "asc") {
            let c = colsAsc.filter((item:any) => columnsForUpdate.includes(item))
            setShownCols(c)
            return
        }
        else if (sortDirection === "desc") {
            let c = colsDesc.filter((item:any) => columnsForUpdate.includes(item))
            setShownCols(c)
            return

        } else if (sortDirection == "default") {
            chosenBucket?.info.setup.shown_master_columns["left"].forEach((n:any) => {
                if (columnsForUpdate.includes(n))
                    c.push(n)
            })
            chosenBucket?.info.setup.shown_master_columns["right"].forEach((n:any) => {
                if (columnsForUpdate.includes(n)) c.push(n)
            })
            chosenBucket?.info.setup.hidden_master_columns["left"].forEach((n:any) => {
                if (columnsForUpdate.includes(n)) c.push(n)
            })
            chosenBucket?.info.setup.hidden_master_columns["right"].forEach((n:any) => {
                if (columnsForUpdate.includes(n)) c.push(n)
            })
            setShownCols(c)
        }

    }
    const sortColumns = () => {
        if (chosenBucket != undefined ) {
            let c: string[] = []  
            var columns;
            columns = chosenBucket.info.model.columns 

            if (sortDirection === "asc") {
                columns.sort((a:any, b:any) => {
                    const nameA = a.name.toUpperCase(); 
                    const nameB = b.name.toUpperCase(); 
                    if (nameA < nameB) {
                    return -1;
                    }
                    if (nameA > nameB) {
                    return 1;
                    }
                return 0;
                });
                let n: string[] = []
                columns.forEach((i: any) => {
                    n.push(i.name)
                })
                setColsAsc(n)            
                } else if (sortDirection === "desc") {
                columns.sort((a:any, b:any) => {
                    const nameA = a.name.toUpperCase(); 
                    const nameB = b.name.toUpperCase(); 
                    if (nameA > nameB) {
                    return -1;
                    }
                    if (nameA < nameB) {
                    return 1;
                    }
                return 0;
                });
                let n: string[] = []
                columns.forEach((i: any) => {
                    n.push(i.name)
                })
                setColsDesc(n)
            } else if (sortDirection == "default") {
                // const cols: string[] = [];
                chosenBucket?.info.setup.shown_master_columns["left"].forEach((n:any) => {
                    c.push(n)
                })
                chosenBucket?.info.setup.shown_master_columns["right"].forEach((n:any) => {
                    c.push(n)
                })
                chosenBucket?.info.setup.hidden_master_columns["left"].forEach((n:any) => {
                    c.push(n)
                })
                chosenBucket?.info.setup.hidden_master_columns["right"].forEach((n:any) => {
                    c.push(n)
                })
                setColumns(c)
                setShownCols(c)
            }
            if (sortDirection !== "default") {
                columns.forEach((i: any) => {
                    c.push(i.name)
                })
                setColumns(c)
                setShownCols(c)
            }
        }
    }   

    const totalcolumns = chosenBucket != undefined ? chosenBucket.info.model.columns.length : 0

    const getGridData = () => {
        if (chosenBucket != undefined) {

            let bucketCols = chosenBucket.info.model.columns

            let cols = bucketCols.map(x => {return x.name})
            const defaultSortColumn = cols[0]
            let data = {
                sort_column:  defaultSortColumn,
                sort_direction: "desc",
                filter: [],
                column_filter: cols,
                offset: 0,
                limit: 100
            }
  
            props.dispatch(getReportGridData(chosenBucket.id, data))
     
            }   
    }

    const shownColumns = () => {
        var filters = filterType != null ? filterType : ""
        if (filters.includes("inactData") && !filters.includes("ERP") && chosenBucket != null ) {
            if (search !== "") {
                let difference = shownCols.filter((item:any) => inactData.includes(item))
                return difference.length
            } else {
                let difference = chosenBucket.info.model.columns.filter((item:any) => inactData.includes(item.name))
                return difference.length
            }
        }
        if (filters.includes("ERP") && !filters.includes("inactData") && chosenBucket != null) {
            if (search !== "") {
                let difference = shownCols.filter((item:any) => erpData.includes(item))
                return difference.length
            } else {
                let difference = chosenBucket.info.model.columns.filter((item:any) => erpData.includes(item.name))
                return difference.length
            }
        }
        else if (search !== "") {
            return shownCols.length
        }
        
        else return totalcolumns
    }

    const createTypeArray = () => {
        let inactData: string[] = []
        let erpData: string[] = [] 

            let bucket = chosenBucket != undefined ? chosenBucket : props.buckets != undefined ? Object.values(props.buckets.data)[0] : null
            if (bucket == null) return
            else {
                bucket.info.model.columns.forEach((i: any, e: any) => {
                    if ((bucket?.info.setup.inact_now_data).includes(i.name)) {
                        inactData.push(i.name)
                    }
                })
            let difference = bucket.info.model.columns.filter( (item:any) => !inactData.includes(item.name))
            difference.forEach(i => {
                erpData.push(i.name)
            })
            erpData.filter((i: any) => i.name)
        }
        setInactData(inactData)
        setErpData(erpData)
        setCheckedInactData(inactData)
    }
  

    const onSaveInfo = (description: any) => {
        if(chosenBucket == undefined) return
        let tmpBucketSetup;
        if (bucketSetup !== undefined) {
            tmpBucketSetup = bucketSetup
        }
        else {tmpBucketSetup = {...chosenBucket.info.setup} }

        let tmp: DataBucket = structuredClone(chosenBucket)

        tmp.info.setup.descriptions[chosenColumnName] = description
        tmpBucketSetup.descriptions = tmp.info.setup.descriptions
        setUpdatedBucketSetup(tmpBucketSetup)
        setHasSetupChanged(true)
        props.dispatch(updateBucketSetup(chosenBucket.id, tmpBucketSetup, updateBucketSetupHandled ))
    }

    const updateBucketSetupHandled = async (data: any) => {
        if (data.success) {
            props.dispatch(getBucketsDataInfo())  
            onCloseInfo()

        } else return;
    }
    const onCloseInfo = () => {
        setModalAdd(false)
        setModalEdit(false)
    }
  

    const getEditButtons = (n: any) => {
        if (chosenBucket != undefined && bucketSetup != undefined) {
            if (bucketSetup.descriptions[n]){
                    return "edit"
            }
            else return "add"
        }
    }
    const getDescriptions = (n: any) => {
        if (chosenBucket != undefined && bucketSetup != undefined) {
            if (bucketSetup.descriptions[n]){
                    return true
            }
            else return false
        }
    }
 
    
    const columnOrder = () => {
        
        const cols: string[] = [];
        chosenBucket?.info.setup.shown_master_columns["left"].forEach((c:any) => {
            cols.push(c)
        })
        chosenBucket?.info.setup.shown_master_columns["right"].forEach((c:any) => {
            cols.push(c)
        })
        chosenBucket?.info.setup.hidden_master_columns["left"].forEach((c:any) => {
            cols.push(c)
        })
        chosenBucket?.info.setup.hidden_master_columns["right"].forEach((c:any) => {
            cols.push(c)
        })
        setColumns(cols)
        setShownCols(cols)

    }

    const getFilter = (c: any) => {
        if (!filterType.includes(c)) {
                setFilterType([c])           
        } else {
            const newArr = [...filterType];
            newArr.splice(newArr.findIndex(item => item === c), 1)
            setFilterType(newArr)
        }
    }
    let downloadDataSpecs = (type: string) => {
        const {dispatch } = props;
        const colHeaders: string[] = [];
        colHeaders.push("Column")
        colHeaders.push("Descriptions")
        colHeaders.push("Data Type")

        colHeaders.push("Example")
        let idRows = shownCols

        const data = {
            name: chosenBucket?.info.name,
            type: type,
            query: {
                column_filter: colHeaders,
                offset: -1, 
                limit: -1, 
            },
            rows: idRows,
            examples: exampleMap !== undefined ? JSON.stringify(Object.fromEntries(exampleMap)) : null,
            include_comments: true,
        }
        let id = chosenBucket?.id ? chosenBucket.id : Object.values(props.buckets.data)[0].id

        notifySuccess("You will get a notification when your download is ready")

        dispatch(downloadBucketDataSpecs(id, data))
    }
    const getType = (c:string) => {
        if (erpData.includes(c)) {
            return "ERP"
        }
        else if (checkedInactData.includes(c))
            return "inact"        
    }


    const toggleSortOrder = () => {
        setSortDirection(prevOrder => {
            if (prevOrder === "default") return "asc";
            if (prevOrder === "asc") return "desc";
            else return "default";
        });
    }
 
    const getSortIcons = () => {
        let className = "float-right arrow-align abc-sort-icon margin-left-5px fa fa-long-arrow-";
        if (sortDirection == "asc") {className += "up";}
        else if (sortDirection == "default") {className = ""}
        else if (sortDirection == "desc"){className += "down"}
        
        return <span className={className} />

    } 

    const getFilteredColumns = () => {
        if (chosenBucket != undefined){
            return columns.filter((name: any) => {return name.toLowerCase().includes(search.toLowerCase())})        
        }
    }

    const getColumnsToRender = () => {
        let cols = getFilteredColumns()

        if (cols?.length !== columns.length){
            if (cols != undefined)
            setColumnsForUpdate(cols)
        }
        else 
           sortColumns() 
        
    }

    function randomNumber(min:number, max:number) {
        return Math.floor((Math.random() * (max - min + 1) + min) * 100) / 100;
    }
      
    function randomDate(start:Date, end:Date) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    const getExampleArray = () => {
        let res: any
        if (chosenBucket == undefined) return 
        let griddata = props.griddata.get("data", [])
        let index: number
        let bucketCols = chosenBucket.info.model.columns
        let reportCols = griddata.columns

        if (reportCols == undefined) return
        bucketCols.forEach((item: any, type: any) => {
            bucketCols = bucketCols.filter((item: any) => reportCols.includes(item.name))
        })
        var map = new Map()

        bucketCols.forEach((item: any) => {
            let colName = item.name
            index = griddata.columns.indexOf(colName)
            for(let i = 0; i < griddata.data.length && !map.has(colName); i++){
                res = griddata.data[i][index]
                if (res !== 0 && res !== ""){
                    map.set(colName, res)
                }
            }
        })
        chosenBucket.info.model.columns.forEach((item: any) => {
            let colname = item.name
            if (!map.has(colname)){
                switch (item.type) {
                    case ("string"): {
                        res = "word"
                        break;
                    }
                    case ("decimal"): {
                        res = randomNumber(1, 100); //random number between 1 & 100
                        break;
                    }
                    case ("id"): {
                        res = "id"
                        break;
                    }
                    case ("categorization"): {
                        res = "categorization"
                        break;
                    }
                    case ("date"): {
                        res = randomDate(new Date(1970, 1,1), new Date()).toISOString().split('T')[0] //random date in the past
                        break;
                    }
                }
                map.set(colname, res)
            }
        })
        setExampleMap(map)
    }
    const getExample = (col: string) => {
        if (exampleMap === undefined) return
        let example = exampleMap.get(col)
        return example
    }
    const addColToInactNow = (col: string) => {
        if(chosenBucket == undefined) return
        const tmpBucketSetup = {...chosenBucket.info.setup}
        let inactData: string[] = []
        let tmp: DataBucket = structuredClone(chosenBucket)

        if (tmp.info.setup.inact_now_data == undefined) {
            inactData.push(col)
            tmp.info.setup.inact_now_data = inactData
        } else {
            if (tmp.info.setup.inact_now_data.includes(col)) {
                const newArr = [...tmp.info.setup.inact_now_data];
                newArr.splice(newArr.findIndex(item => item === col), 1)
                tmp.info.setup.inact_now_data = newArr
            } else {
                tmp.info.setup.inact_now_data.push(col)
            }
        }
        tmpBucketSetup.inact_now_data = tmp.info.setup.inact_now_data
        setUpdatedBucketSetup(tmpBucketSetup)
        inactData.push(col)
        props.dispatch(updateBucketSetup(chosenBucket.id, tmpBucketSetup, updateBucketSetupHandled ))
    }
    const exitEditDescription = () => {
        if (editDescriptions == false) setEditDescriptions(true)
        else { 
            if (hasSetupChanged) {
                setHasSetupChanged(false)
            }
            else setEditDescriptions(false)
        }
    }
    return (
        <div className="">
            <div className="dataspecs-title">
                <div className="dataspecs-filters ">
                <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"10px", verticalAlign:"middle"}}>
                            <span className="vertical-align-middle" style={{marginRight: 8}}>{`Data Source: `}</span>
                </div>
                    <Select options={Object.values(actualOptions)} placeholder={chosenBucket?.info.name} onChange={(e:any) => {setBucket(e); setBucketChange(true)}} className={"fixed-slicer-select margin-left-10"} classNamePrefix="fixed-slicer-select"   />
                <div style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"20px"}}>
                <ShowIf if={inactData.length >= 1}>    
                        <span className="vertical-align-middle " style={{marginRight: 8, fontSize:"14px"}}>{`Filter by: `}</span>
                        <ShowIf if={filterType.includes("ERP") && !filterType.includes("inactData")}>
                        <button className="h6 border rounded-pill py-1 px-2 mb-0" style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"#eee0c3"}} onClick={(e) => getFilter("ERP")} > ERP </button> 
                        </ShowIf>
                        <ShowIf if={!filterType.includes("ERP") || filterType.includes("inactData")} >
                        <button className="h6 border rounded-pill py-1 px-2 mb-0" style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"white"}} onClick={(e) => getFilter("ERP")} > ERP </button> 
                        </ShowIf>
                        <ShowIf if={filterType.includes("inactData") && !filterType.includes("ERP")}>
                            <button className={`h6 border rounded-pill py-1 px-2 mb-0`} style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"#eee0c3"}} onClick={(e) => {getFilter("inactData")}} ><img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} /> Inact Now </button>
                        </ShowIf>
                        <ShowIf if={!filterType.includes("inactData") || filterType.includes("ERP")}>
                        <button className={"h6 border rounded-pill py-1 px-2 mb-0"} style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"white"}} onClick={(e) => {getFilter("inactData")}} ><img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} /> Inact Now</button>
                        </ShowIf>
                        </ShowIf>
                </div>
            </div>
                <div className='dataspecs-toolbar'>

                    {/* To do  */}
                        {/* <button className="btn btn-default btn-sm" > Upload</button> */}

                    <button className="btn btn-default btn-sm abc-click" style={{ marginLeft:"8px"}} onClick={e => downloadDataSpecs("excel")}><i className="fa fa-download"></i> </button>
                    <RequirePermission perms={is.orgAdmin} >
                    <ShowIf if={!editDescriptions  && chosenBucket !== undefined }>
                        <button  className="btn btn-default btn-sm" style={{marginRight:"8px", marginLeft:"8px"}} onClick={(e) => exitEditDescription()}> Add  <i className="fa fa-info-circle glyphicon-info" style={{marginLeft:"2px"}}></i></button>
                    </ShowIf>
                    <ShowIf if={editDescriptions}>
                        <button  className="btn btn-sm" style={{marginRight:"8px", marginLeft:"8px", background:"#d9534f", color:"white"}} onClick={(e) => exitEditDescription()}> Adding Descriptions <i className="fa fa-info-circle glyphicon-info" style={{marginLeft:"2px"}}></i></button>
                    </ShowIf>
                    </RequirePermission>
                    <div className="dataspecs-search" style={ {marginLeft: chosenBucket == undefined ? "8px" : "0px"}}>
                        <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center"}}>
                            <span className="vertical-align-middle" style={{marginRight: 8}}>{`Showing ${shownColumns() !== null ? shownColumns() : totalcolumns} of ${totalcolumns}`}</span>
                        </div>
                        <div className="input-group margin-top-0" id="searchBarContainer">
                            <input id="searchIdInput" className={`form-control form-control-sm zero-border-right ${search ? 'alert-warning' : ''} `}
                                placeholder={`Search data specs`}  onChange={(e) => setSearch(e.target.value)} value={search} />
                            <span title="Search beginning" className={`input-group-append ${search ? 'alert-warning' : ''} `}>
                                <button className="btn btn-sm btn-default" type="button">
                                    <i className="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </div>  
                </div>
            </div>
            <div  style={{paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", paddingRight:"10px"}}>
            <table className="table table-condensed table-bordered  table-striped margin-top-10px" style={{width:"100%"}}>
                <thead>
                    <tr >
                        <th style={{width:"27%"}} className="abc-click" onClick={() =>{toggleSortOrder()}} > Column 
                        {getSortIcons()}
                        </th>
                        <th style={{width:"75%"}}> Descriptions <span className='fa fa-info-circle vertical-align-middle' ></span></th>
                        <th style={{width:"10%"}}> Example</th>

                        <ShowIf if={editDescriptions && props.user.is_consultant}>
                        <th title={"Add data column to Inact Now Data"} style={{width:"4%"}}> <img src="./img/inact_ia_logo_dark.svg" alt=""/> </th>
                        </ShowIf>
                    </tr>
                    </thead>
                    <tbody>
                    {    
                        shownCols.map((i: any, c:any) => {
                            let example: string = getExample(i)

                            let isDefault: boolean = false
                            let defaultVals = ["number", "categorization", "id","word", "date"]
                            if (defaultVals.includes(example)) {
                                isDefault = true
                            } else {
                                isDefault = false
                            }
                            if (filterType.length !== 0) {
                                if (filterType.includes("ERP") && getType(i) == "ERP") {
                                    let setupDescription = bucketSetup ? bucketSetup.descriptions[i] != undefined ? bucketSetup.descriptions[i] : "" : ""
                                    return (
                                        <tr key={c}>
                                            <td style={{height:"33px"}}>
                                                {i} 
                                                <ShowIf if={getType(i) == "inact"}>
                                                    <div style={{display:"inline-block", float:"right"}}>
                                                        <img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} />    
                                                        </div>
                                                </ShowIf>
                                            </td>
                                            <td style={{width:"200px"}} >  
                                            <span className='wrap' >

                                                <ShowIf if={getDescriptions(i) == true}>
                                                        <span className='overflow' style={{float: "left", width: !editDescriptions ? "97%" : "92%"}}>
                                                        {setupDescription}
                                                        </span>    

                                                    <ShowIf if={setupDescription !== ""}>
                                                    <div data-tip data-for={`tooltip-${i}`} className="fa fa-info-circle vertical-align-middle  " style={{marginLeft:"5px", display:"inline-block"}}/>
                                                <ReactTooltip effect={"solid"} place={"bottom"} className='tooltip' id={`tooltip-${i}`} type='dark'  > 
                                                <ReactMarkdown>{setupDescription}</ReactMarkdown>
                                                </ReactTooltip>
                                                </ShowIf>
                                                </ShowIf>   
                                                <ShowIf if={editDescriptions }>

                                                    <ShowIf if={getEditButtons(i) == "add"}>
                                                    <button className="btn btn-default btn-xs edit-button float-right" onClick={() => {setModalAdd(!modalAdd); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Add +</button>  
                                                    </ShowIf>
                                                    <ShowIf if={getEditButtons(i) == "edit"}>
                                                        <button className="btn btn-default btn-xs edit-button margin-left-5px float-right" onClick={() => {setModalEdit(!modalEdit); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Edit </button>  

                                                    </ShowIf>

                                                </ShowIf>
                                            </span>
                                            </td>
                                            <td>
                                                {getExample(i)}
                                            </td> 
                                            <td >
                                                <ShowIf if={editDescriptions && props.user.is_consultant}>
                                                    <div className='text-align-center'>
                                                        <input 
                                                        className='text-align-center'
                                                        style={{ verticalAlign:"middle"}}  
                                                        type="checkbox" 
                                                        checked={getType(i) == "inact"} 
                                                        onChange={e => {addColToInactNow(i); }}
                                                        />
                                                    </div>
                                                </ShowIf>

                                            </td>
                                        </tr>
                                    )
                                }    
                                else if (filterType.includes("inactData") && getType(i) == "inact") {
                                let setupDescription = bucketSetup ? bucketSetup.descriptions[i] != undefined ? bucketSetup.descriptions[i] : "" : ""

                                return (
                                    <tr key={c}>
                                        <td style={{height:"33px"}}>
                                            {i} 
                                            <ShowIf if={getType(i) == "inact"}>
                                                    <div style={{display:"inline-block", float:"right"}}>
                                                        <img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} />    
                                                        </div>
                                                </ShowIf>
                                        </td>
                                        <td style={{width:"200px"}} >  
                                        <span className='wrap' >

                                            <ShowIf if={getDescriptions(i) == true}>
                                                <span className='overflow' style={{float: "left", width: !editDescriptions ? "97%" : "92%"}}>
                                                {setupDescription}
                                                </span>    

                                                <ShowIf if={setupDescription !== ""}>
                                                <div data-tip data-for={`tooltip-${i}`} className="fa fa-info-circle vertical-align-middle  " style={{marginLeft:"5px", display:"inline-block"}}/>
                                            <ReactTooltip effect={"solid"} place={"bottom"} className='tooltip' id={`tooltip-${i}`} type='dark'  > 
                                            <ReactMarkdown>{setupDescription}</ReactMarkdown>
                                            </ReactTooltip>
                                            </ShowIf>
                                            </ShowIf>   
                                            <ShowIf if={editDescriptions }>

                                                <ShowIf if={getEditButtons(i) == "add"}>
                                                <button className="btn btn-default btn-xs edit-button float-right" onClick={() => {setModalAdd(!modalAdd); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Add +</button>  
                                                </ShowIf>
                                                <ShowIf if={getEditButtons(i) == "edit"}>
                                                    <button className="btn btn-default btn-xs edit-button margin-left-5px float-right" onClick={() => {setModalEdit(!modalEdit); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Edit </button>  

                                                </ShowIf>

                                            </ShowIf>
                                        </span>
                                        </td>
                                        <td >
                                        <ShowIf if={getExample(i) != undefined}>
                                            <span style={{ fontStyle: isDefault==false ? 'normal' : 'italic' }} > {example} </span>
                                        </ShowIf>
                                        </td>
                                        <td >
                                            <ShowIf if={editDescriptions && props.user.is_consultant}>
                                                <div className='text-align-center'>
                                                    <input 
                                                    className='text-align-center'
                                                    style={{ verticalAlign:"middle"}}  
                                                    type="checkbox" 
                                                    checked={getType(i) == "inact"} 
                                                    onChange={e => {addColToInactNow(i); }}
                                                    />
                                                </div>
                                            </ShowIf>

                                        </td>
                                    </tr>
                                )}
                                else {
                                    return null
                                }
                                } else {
                                let setupDescription = bucketSetup ? bucketSetup.descriptions[i] != undefined ? bucketSetup.descriptions[i] : "" : ""

                                return (
                                        <tr key={i}>
                                            
                                            <td style={{height:"33px"}}>
                                               
                                                {i} 
                                                <ShowIf if={getType(i) == "inact"}>
                                                    <div style={{display:"inline-block", float:"right"}}>
                                                        <img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"20px"}} />    
                                                        </div>
                                                </ShowIf>
                                            </td>
                                            
                                            <td style={{width:"200px"}} >  
                                            <span className='wrap' >

                                                <ShowIf if={getDescriptions(i) == true}>
                                                        <span className='overflow' style={{float: "left", width: !editDescriptions ? "97%" : "92%"}}>
                                                        {setupDescription}
                                                        </span>    

                                                    <ShowIf if={setupDescription !== ""}>
                                                    <div data-tip data-for={`tooltip-${i}`} className="fa fa-info-circle vertical-align-middle  " style={{marginLeft:"5px", display:"inline-block"}}/>
                                                <ReactTooltip effect={"solid"} place={"bottom"} className='tooltip' id={`tooltip-${i}`} type='dark'  > 
                                                <ReactMarkdown>{setupDescription}</ReactMarkdown>
                                                </ReactTooltip>
                                                </ShowIf>
                                                </ShowIf>   
                                                <ShowIf if={editDescriptions }>

                                                    <ShowIf if={getEditButtons(i) == "add"}>
                                                    <button className="btn btn-default btn-xs edit-button float-right" onClick={() => {setModalAdd(!modalAdd); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Add +</button>  
                                                    </ShowIf>
                                                    <ShowIf if={getEditButtons(i) == "edit"}>
                                                        <button className="btn btn-default btn-xs edit-button margin-left-5px float-right" onClick={() => {setModalEdit(!modalEdit); setChosenColumnName(i); setChosenColumnDescription(setupDescription)}} > Edit </button>  

                                                    </ShowIf>
                                                    

                                                </ShowIf>
                                                </span>

                                            </td>
                                            <td > 
                                                <ShowIf if={getExample(i) != undefined}>
                                                    <span style={{ fontStyle: isDefault==false ? 'normal' : 'italic' }} > {example} </span>
                                                </ShowIf>
                                            </td> 
                                            <td >
                                                <ShowIf if={editDescriptions && props.user.is_consultant}>
                                                    <div className='text-align-center'>
                                                        <input 
                                                        className='text-align-center'
                                                        style={{ verticalAlign:"middle"}}  
                                                        type="checkbox" 
                                                        checked={getType(i) == "inact"} 
                                                        onChange={e => {addColToInactNow(i); }}
                                                        />
                                                    </div>
                                                </ShowIf>

                                            </td>
                                        </tr>
                                    )
                                } 
                            }
                        )
                    }
                </tbody>
            </table>
            <ShowIf if={modalAdd}>
                    <AddBucketDescription 
                        show={modalAdd}
                        onClose={() => onCloseInfo()}
                        chosenColumnName={chosenColumnName}
                        chosenColumnDescription={chosenColumnDescription}
                        saveDescription={(description) => onSaveInfo(description)}
                    />
            </ShowIf>
            <ShowIf if={modalEdit}>
                    <EditBucketDescription 
                        show={modalEdit}
                        onClose={() => onCloseInfo()}
                        chosenColumnName={chosenColumnName}
                        chosenColumnDescription={chosenColumnDescription}
                        saveDescription={(description) => onSaveInfo(description)}
                    />
            </ShowIf>
            
            </div>
        </div>
    )
}
    
// @ts-ignore
DataSpecs = connect(mapStateToProps)(DataSpecs)

export default DataSpecs

