import React, { useState, CSSProperties, useRef, useEffect } from 'react'
import ShowIf from '../Generic/ShowIf'
import ActiveCategoryIcon from './ReportComponents/ActiveCategoryIcon'
import { ReportData, QueryCriterion, FullSlicer } from '../../types/transfertypes'
import Drawer from '../Animation/Drawer'
import { isTextColumn, getIdColumn, isDateColumn, getFilter, slicerEquals } from '../../helpers/ReportHelpers'
import {
    DndContext,
    DragOverlay,
    pointerWithin,
    DragStartEvent,
    UniqueIdentifier,
    DragEndEvent,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { rectSortingStrategy, SortableContext, useSortable  } from "@dnd-kit/sortable";
import { getTodaysDate } from '../../helpers/GeneralHelpers'
import { CompareType } from '../../types/slicertypes'
import { getSlicerValues, getAggregationData, getTotalBucketData } from '../../actions/ReportActions'
import FixedCategorizationSlicer from './SlicerComponents/FixedCategorizationSlicer'
// @ts-ignore
import Select from 'react-select'
import { SingleKeyMap, TripleKeyMap } from '../../helpers/Collections'
import SingleSlicer2 from './SlicerComponents/SingleSlicer2'
import AddSlicerDialog2 from '../Dialogs/AddSlicerDialog2'
import posed from 'react-pose'
import { connect } from 'react-redux'
import WarningAcceptDialog from '../Dialogs/WarningAcceptDialog'
import '../../css/slicer.css'
interface Slicer2Props {
    editMode: boolean,
    reportData: ReportData,
    slicers: FullSlicer[],
    setShow: (val: boolean) => void,
    fixedSlicerColumns: string[],
    addFixedSlicer: (column: string) => void,
    moveFixedSlicer: (oldIndex: number, newIndex: number) => void,
    removeFixedSlicer: (column: string) => void,
    updateSlicer: (oldSlicer: FullSlicer, newSlicer: FullSlicer) => void,
    dispatch?: (val: any) => void,
    isSlicerValueResultSetTooBig: any,
    slicerValues: SingleKeyMap<{ data: { values: string[] } }>,
    slicerAvailableValues: SingleKeyMap<{ data: { values: string[] } }>,
    warnSlicerRemoval: (slicer: QueryCriterion) => void,
    removeSlicer: (slicer: QueryCriterion) => void,
    clickReport: boolean,
    onUpdateSearchInput: (value: any, idColumn: any) => void,
    aggregationdata: TripleKeyMap<any>
    totaldata: SingleKeyMap<any>,
    addSlicer: (slicer: QueryCriterion) => void,
    idSearchSlicer: QueryCriterion,
    limit: number,
}


const mapStateToProps = (state: any, ownProps: Slicer2Props) => {
    return {
        slicerValues: state.Report.slicerValues,
        slicerAvailableValues: state.Report.slicerAvailableValues,
        slicerValuesTooBig: state.Report.slicerValuesTooBig,
        isSlicerValueResultSetTooBig: state.Report.isSlicerValueResultSetTooBig,
        aggregationdata: state.Report.aggregationdata,
        totaldata: state.Report.totaldata,
    }
}

const Arrow = posed.span({
    visible: { transform: 'rotate(0deg)' },
    hidden: { transform: 'rotate(180deg)' }
})


let Slicer2 = ({dispatch = () => {}, ...props}: Slicer2Props) => {

    const [addSlicerSearchStr, setAddSlicerSearchStr] = useState('')
    const [addFixedSearchStr, setAddFixedSearchStr] = useState('')
    const [activeDrag, setActiveDrag] = useState<UniqueIdentifier>()
    const [show, setShow] = useState(true)

    const [slicerToEdit, setSlicerToEdit] = useState<QueryCriterion>()
    const [showEditSlicer, setShowEditSlicer] = useState(false)
    const [addingSlicer, setAddingSlicer] = useState(false)
    const [isFixedSlicer, setIsFixedSlicer] = useState(false)

    const [fixedSlicerInput, setFixedSlicerInputState] = useState<any>([])

    const [search, setSearch] = useState('')

    const searchTimeout = useRef<ReturnType<typeof setTimeout>>()

    const [slicerToDelete, setSlicerToDelete] = useState<FullSlicer>()
    const deleteSlicerDialog = useRef<any>()


    useEffect(() => {
        const { reportData } = props

        let fixedSlicer = []
        if (reportData) {
            try {
                fixedSlicer = JSON.parse(reportData.report.fixed_slicer)
            } catch (err) {
                fixedSlicer = reportData.report.fixed_slicer === "" ? [] : reportData.report.fixed_slicer !== "" ? [reportData.report.fixed_slicer] : []
            }

            let persistedShow = window.localStorage.getItem(reportData.report.report_id + "_slicer_expanded")

            if(persistedShow != null)
            {
                setShow(persistedShow === "true")
            }
            else {
                setShow(fixedSlicer.length > 0)
            }
        }
        else {
            setShow(false)
        }

        getIdColumnAggregation()
        getTotalData()
        
    }, [])

    useEffect(() => {
        if (props.idSearchSlicer && props.idSearchSlicer.target_values && props.idSearchSlicer.target_values[0] !== search) {
            setSearch(props.idSearchSlicer.target_values[0])
        }
    }, [props.idSearchSlicer])

    useEffect(() => {
        getIdColumnAggregation()
    }, [props.reportData, props.limit, props.slicers, props.idSearchSlicer])

    const getSlicerColumns = () => {
        let columns: string[] = props.reportData.report ? JSON.parse(props.reportData.report.columns) : []
        columns.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })

        return columns
    }

    const saveShow = (value:boolean) => {
        const { reportData } = props

        if (reportData) {
            window.localStorage.setItem(reportData.report.report_id + "_slicer_expanded", value ? "true" : "false");
        }

        setShow(value)
    }

    const getIdColumnAggregation = () => {
        let column = getIdColumn(props.reportData.info.model.columns)
        let limit = props.limit !== -1 ? props.limit : props.reportData.report.limit

        let filters = getFilter(props.reportData.report, props.slicers)
        if(props.idSearchSlicer && props.idSearchSlicer.target_values && props.idSearchSlicer.target_values[0] !== '') {
            filters = filters.concat(props.idSearchSlicer)
        }

        let data = {
            aggregate_column: column,
            group_column: props.reportData.info.model.categorization_name,
            filter: filters,
            sort_column: props.reportData.report.sort_column === '-' ? null : props.reportData.report.sort_column,
            sort_direction: props.reportData.report.sort_direction === '-' ? null : props.reportData.report.sort_direction,
            limit: limit,
            kpis: props.reportData.report.kpis,
        }
        dispatch(getAggregationData(props.reportData.info.id, props.reportData.report.report_id, data, column, 'idColumn'))
    }

    const getTotalData = () => {
        let column = getIdColumn(props.reportData.info.model.columns)
        let data = {
            aggregate_column: column,
            group_column: props.reportData.info.model.categorization_name,
            filter: [],
            sort_column: null,
            sort_direction: null,
            limit: -1,
            kpis: props.reportData.report.kpis,
        }
        dispatch(getTotalBucketData(props.reportData.info.id, props.reportData.report.report_id, data, column, 'idColumn'))
    }



    const getFixedSlicerValues = (column: string) => {
        let found = props.slicers.find(slicer => slicer.isFixed && slicer.column === column)
        if (found) return found.target_values
        return []
    }


    const fixedSlicerColumnClick = (column: string) => {
        if(props.fixedSlicerColumns.findIndex(sc => sc === column) === -1) {
            setAddFixedSearchStr('')
            props.addFixedSlicer(column)
        }
    }

    const handleDragStart = (event: DragStartEvent) => {
        const {active} = event
        setActiveDrag(active.id)

    }

    const handleDragEnd = (e: DragEndEvent) => {
        setActiveDrag(undefined)
        const {active, over} = e
        if (over != null && (active.id != over.id)) {
            const oldIndex = props.fixedSlicerColumns.indexOf(active.id as string)
            const newIndex = props.fixedSlicerColumns.indexOf(over.id as string)
            props.moveFixedSlicer(oldIndex, newIndex)
        }
    }

    let openSlicer = (slicer: QueryCriterion) => {
        dispatch(getSlicerValues(props.reportData?.info.id, {column: slicer.column, filter: []}))
        setSlicerToEdit(slicer)
        setShowEditSlicer(true)
    }

    let columnClick = (column: string, adding = false) => {
        if (props.reportData == undefined) return
        let bucket = props.reportData.info
        if (bucket == null) {
            return
        }

        let slicers = props.slicers
        let target_values: any[] = []
        if (isDateColumn(column, props.reportData.info.model)) {
            target_values = [getTodaysDate()]
        }
        if(slicers.length > 0 && !adding) {
            let slicer = slicers.find(s => s.column === column)

            if (slicer){
                openSlicer(slicer)
            } else {
                setAddingSlicer(true)
                if(isTextColumn(column, props.reportData.info.model)){
                    dispatch(getSlicerValues(props.reportData?.info.id, {column: column, filter: []}))
                }
                setSlicerToEdit({column: column, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
            }
        } else {
            setAddingSlicer(true)
            if(isTextColumn(column, props.reportData.info.model)){
                dispatch(getSlicerValues(props.reportData?.info.id, {column: column, filter: []}))
            }
            setSlicerToEdit({column: column, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
        }
        setShowEditSlicer(true)
    }

    const openCategorizationSlicer = (column: string) => {
        dispatch(getSlicerValues(props.reportData.info.id, { column: column, filter: [] }, getFilter(props.reportData.report, props.slicers)))
        setSlicerToEdit({column: column, target_values: getFixedSlicerValues(column), is_column: false, compare_type: CompareType.EQ})
        setIsFixedSlicer(true)
        setShowEditSlicer(true)
    }

    const removeFixedSlicer = (column: string) => {
        props.removeFixedSlicer(column)
    }

    const setFixedSlicerValues = (column: string, values: any[]) => {
        let oldSlicerValues = getFixedSlicerValues(column)
        let oldSlicer: FullSlicer = {compare_type: CompareType.EQ, target_values: oldSlicerValues, column: column, is_column: false, isFixed: true}
        if (values.length === 0) {
            props.removeSlicer(oldSlicer)
        } else {
            let newSlicer: QueryCriterion & {isFixed: boolean} = {compare_type: CompareType.EQ, target_values: values, column: column, is_column: false, isFixed: true}
            props.updateSlicer(oldSlicer, newSlicer)
        }
    }

    const textColumnHasTooManyValues = (column: string) => {
        return !!props.isSlicerValueResultSetTooBig[column]
    }

    const getValuesFromSlicer = (column: string) => {
        let found = props.slicers.find(slicer => slicer.column === column)
        if (found && !found.isFixed) {
            return found.target_values}
        return []
    }

    const getFixedSlicerInput = (column: string) => {
        return fixedSlicerInput[column]
    }

    const isOptionAvailable = (column: string, option: string) => {
        let availableOptions = props.slicerAvailableValues.get(column, { data: {values: [] } }).data.values ?? []
        return availableOptions.includes(option)
    }

    const getFixedSlicerOptions = (column: string) => {
        let options = props.slicerValues.get(column, { data: { values: [] } }).data.values ?? []
        let input = getFixedSlicerInput(column)
        if (input) {
            input = input.toLowerCase()
            options.sort((a, b) => {
                let aStartsWith = a.toLowerCase().startsWith(input)
                let bStartsWith = b.toLowerCase().startsWith(input)
                //first sort on whether a and b starts with input and then sort alphabetically
                return ((+bStartsWith) - (+aStartsWith)) || a.localeCompare(b)
            })
        } else {
            //if no input, then sort alphabetically
            let available = options.filter(o => {return isOptionAvailable(column, o)}).sort()
            let notAvailable = options.filter(o => {return !isOptionAvailable(column, o)}).sort()
            options = available.concat(notAvailable)
        }
        return options
    }

    const setFixedSlicerInput = (column: string, value: any, {action}: {action: any}) => {
        if (action === 'input-change') {
            setFixedSlicerInputState(Object.assign({}, fixedSlicerInput, { [column]: value} ))
        }
    }

    const checkUpdateSearchInput = (e: any, idColumn: string) => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current)
        }
        
        if (e.key == "Enter") {
            updateSearchInput(search, idColumn)
            return
        }

        searchTimeout.current = setTimeout(() => {
            props.onUpdateSearchInput(search, idColumn)
        }, search != "" ? 5000 : 0)
    }


    const updateSearchInput = (value:string, idColumn: string) => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current)
        }
        
        props.onUpdateSearchInput(value, idColumn)
    }

    const getFixedSlicer = (c: string, activeCategoryColumn: string, key: any, overlay = false) => {
        return (
            <SortableItem key={key} id={c} editMode={props.editMode}>
                {c == activeCategoryColumn ? 
                    <FixedCategorizationSlicer
                        column={c}
                        openSlicer={() => openCategorizationSlicer(c)}
                        removeSlicer={() => removeFixedSlicer(c)}
                        editMode={props.editMode}
                        value={getFixedSlicerValues(c)}
                        setFixedSlicerValue={(v: any) => setFixedSlicerValues(c, v)}
                    />
                    :
                    <div style={overlay ? {opacity: 0.5} : {}} className={props.editMode ? "btn-group" : ""}>
                        {textColumnHasTooManyValues(c) ? 

                            <Select
                                className={`fixed-slicer-select ${props.editMode ? "no-right-border-radius" : ""}`}
                                classNamePrefix="fixed-slicer-select"
                                value={getFixedSlicerValues(c).map(v => { return { value: v, label: (v === '' ? 'No value' : v )} })}
                                isDisabled={props.editMode || getValuesFromSlicer(c).length > 0}
                                options={getFixedSlicerOptions(c).map(v => {
                                    return {
                                        value: v,
                                        label: v === '' ? 'No value' : v, 

                                    }
                                })}
                                menuIsOpen={false}
                                onMenuOpen={() => { columnClick(c) }}
                                placeholder={c === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                    <div>{c}</div>
                                    <div className='pl-1'><ActiveCategoryIcon /></div>
                                </div> : c}
                                isMulti
                                styles={{option: (styles: CSSProperties, {data}: {data: any}) => {
                                    return {...styles, color: data.color}
                                },
                                    control: (styles: CSSProperties) => ({ 
                                        ...styles,
                                        ':focus-within' : { 
                                            borderColor: "var(--tangerine-hover)", 
                                            boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                        }
                                    })
                                }}
                                onChange={(vs: {value: string}[]) => { vs === null ? setFixedSlicerValues(c, []) : setFixedSlicerValues(c, vs.map(v => v.value)) }}

                            />
                            :
                            <Select
                                className={`fixed-slicer-select ${props.editMode ? "no-right-border-radius" : ""}`}
                                isClearable
                                classNamePrefix="fixed-slicer-select"
                                value={getFixedSlicerValues(c).map(v => { return { value: v, label: (v === '' ? 'No value' : v )} })}
                                onChange={(vs: {value: any}[]) => { vs === null ? setFixedSlicerValues(c, []) : setFixedSlicerValues(c, vs.map(v => v.value)) }}
                                isDisabled={props.editMode || getValuesFromSlicer(c).length > 0}
                                options={getFixedSlicerOptions(c).map(v => {
                                    return {
                                        value: v,
                                        label: v === '' ? 'No value' : v, 
                                        color: isOptionAvailable(c, v) ? '#000000' : '#ccc'
                                    }
                                })}
                                placeholder={c === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                    <div>{c}</div>
                                    <div className='pl-1'><ActiveCategoryIcon /></div>
                                </div> : c}
                                isMulti
                                onInputChange={(v: any, a: any) => setFixedSlicerInput(c, v, a)}
                                styles={{option: (styles: CSSProperties, {data}: {data: any}) => {
                                    return {...styles, color: data.color}
                                },
                                    control: (styles: CSSProperties) => ({ 
                                        ...styles,
                                        ':focus-within' : { 
                                            borderColor: "var(--tangerine-hover)", 
                                            boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                        }
                                    })
                                }}
                            />
                        }
                        <ShowIf if={props.editMode}>
                            <button className="btn btn-sm btn-danger" type="button" onClick={() => removeFixedSlicer(c)}><span className="fa fa-times" /></button>
                        </ShowIf>
                    </div>
                }
            </SortableItem>
        )
    }

    const removeSlicer = (slicer: QueryCriterion) => {
        if (slicer.target_values.length > 1) {
            setSlicerToDelete(slicer)
            deleteSlicerDialog.current.show()
        } else {
            props.removeSlicer(slicer)
        }
    }

    const onConfirmDeleteSlicer = () => {
        if (slicerToDelete == undefined) return
        props.removeSlicer(slicerToDelete)
        setSlicerToDelete(undefined)
    }

    const hasActiveSlicer = () => {
        return (props.slicers.length > 0 && !props.clickReport) || (props.slicers.length > 1 && props.clickReport)
    }

    const getExpandBtnColor = () => {
        return hasActiveSlicer() ? 'active-slicer' : ''
    }

    const getExpandBtnTitle = () =>  {
        return hasActiveSlicer() ? 'You have active slicers' : 'No active slicers'
    }



    const getShownSlicers = (): FullSlicer[] => {
        return props.editMode ? JSON.parse(props.reportData.report.filter) : props.slicers
    }


    const checkIsFixedSlicer = (slicer: FullSlicer) => {
        return props.slicers.some(_slicer => _slicer.isFixed && slicerEquals(_slicer, slicer))
    }

    const isClickSlicer = (slicer: FullSlicer) => {
        return !!slicer.fromClickReport
    }

    const saveSlicer = (slicer: QueryCriterion) => {
        if (isFixedSlicer){
            setFixedSlicerValues(slicer.column, slicer.target_values)
        } else if (addingSlicer) {
            if (slicer.target_values.length > 0) {
                props.addSlicer(slicer)
            }
        } else {
            if (slicerToEdit == undefined){
                console.log("No to edit")
                if (slicer.target_values.length > 0) {
                    props.addSlicer(slicer)
                }
            } else {
                const slicers = getShownSlicers()
                const index = slicers.findIndex(s => slicerEquals(s, slicerToEdit))
                if (index < 0) {
                    console.log("Can't find slicer")
                    console.log(slicers)
                    if (slicer.target_values.length > 0) {
                        props.addSlicer(slicer)
                    }
                } else {
                    if (slicer.target_values.length > 0) {
                        console.log("Update")
                        props.updateSlicer(slicers[index], slicer)
                    } else {
                        props.removeSlicer(slicers[index])
                    }
                }
            }
        }
        setIsFixedSlicer(false)
        setShowEditSlicer(false)
        setSlicerToEdit(undefined)
        setAddingSlicer(false)
    }



    let columns: string[] = getSlicerColumns()
    const activeCategoryColumn = props.reportData.info.model.categorization_name

    let idColumn = getIdColumn(props.reportData.info.model.columns)
    let idAggr = props.aggregationdata.get("idColumn", props.reportData.report.report_id, idColumn)
    const totalDataCount = props.totaldata.get(props.reportData.report.report_id, null)

    const fixedSlicers = columns
                            .filter(c => isTextColumn(c, props.reportData.info.model) && !props.fixedSlicerColumns.includes(c))
                            .filter(c => addFixedSearchStr === '' || c.toLowerCase().includes(addFixedSearchStr.toLowerCase()))


    return (
        <div>
            <Drawer id="slicerHolder" className={`slicerHolder`} expand={show || props.editMode}>
                <hr className="full-width-hr hr-color" />
                <div>
                    <div className="padding-top-10px">
                        <div className="active-slicers">
                            <div className="dropdown inline-block vertical-align-top margin-bottom-10px">
                                <button className="btn btn-sm btn-primary" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
                                    <ShowIf if={props.editMode}>
                                        + Insight filter
                                    </ShowIf>
                                    <ShowIf if={!props.editMode}>
                                        <i className="fa fa-plus"></i>
                                    </ShowIf>
                                </button>
                                <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby="dropdownMenu2" style={{paddingTop:0}}>
                                    <div className="text-center px-2 sticky-modal-header" style={{paddingTop:'6px'}}>
                                        <input type="text" value={addSlicerSearchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => setAddSlicerSearchStr(e.target.value)} />
                                        <div className="dropdown-divider"></div>
                                    </div>
                                    {
                                        columns
                                        .filter(c => addSlicerSearchStr === '' || c.toLowerCase().includes(addSlicerSearchStr.toLowerCase()))
                                        .map((c, index) => <button className="hover-cursor dropdown-item" disabled={getFixedSlicerValues(c).length > 0} type="button" onClick={() => columnClick(c, true)} key={index}>
                                            {c === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                                <div>{c}</div>
                                                <div className='pl-2'><ActiveCategoryIcon /></div>
                                            </div> : <span>{c}</span>}
                                        </button>)
                                    }
                                </div>
                            </div>
                            <div className="dropdown inline-block vertical-align-top margin-bottom-10px margin-left-5px">
                                <ShowIf if={props.editMode}>
                                    <button className="btn btn-sm btn-primary" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">+ Fixed slicer</button>
                                </ShowIf>
                                <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby='dropdownMenu3' style={{paddingTop:0}}>
                                    <div className="text-center px-2 sticky-modal-header" style={{paddingTop:'6px'}}>
                                        <input type="text" value={addFixedSearchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => setAddFixedSearchStr(e.target.value)} />
                                        <div className="dropdown-divider"></div>
                                    </div>
                                    {
                                        fixedSlicers.length > 0 ?
                                        fixedSlicers
                                        .map((c, index) => <button className="hover-cursor dropdown-item" onClick={() => fixedSlicerColumnClick(c)} key={index}>
                                            {c === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                                <div>{c}</div>
                                                <div className='pl-2'><ActiveCategoryIcon /></div>
                                            </div> : <span>{c}</span>}
                                        </button>)
                                        : <span className="px-2">No values available</span>
                                    }
                                </div>
                            </div>
                            <DndContext collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                <SortableContext items={props.fixedSlicerColumns} strategy={rectSortingStrategy}>
                                    {
                                        props.fixedSlicerColumns.map((c, i) => getFixedSlicer(c, activeCategoryColumn, i, activeDrag == c))
                                    }
                                </SortableContext>
                                <DragOverlay>
                                    {activeDrag ? getFixedSlicer(activeDrag as string, activeCategoryColumn, "overlay") : null}
                                </DragOverlay>
                            </DndContext>

                            {
                                getShownSlicers().map((item, index) => {
                                    if (checkIsFixedSlicer(item) || isClickSlicer(item)) return null
                                    return (
                                        <SingleSlicer2
                                            slicer={item}
                                            index={index}
                                            key={index}
                                            openSlicer={(s: QueryCriterion) => openSlicer(s)}
                                            model={props.reportData.info.model}
                                            removeSlicer={(slicer: QueryCriterion) => removeSlicer(slicer)}
                                            idColumn={idColumn}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className="slicer-count-label">
                            {
                                props.editMode ? `Showing ${idAggr ? idAggr.total.count : "..."} of ${totalDataCount ? totalDataCount.total.count : ""}` : `Showing ${idAggr ? idAggr.total.count : "..."} of ${props.reportData.count}`
                            }
                        </div>
                        <div className={show ? 'form-group float-right inline-block searchBar-div' : 'form-group searchBar-div'}>
                            <div className="input-group margin-top-0" id="searchBarContainer">
                                <input id="searchIdInput" className={`form-control form-control-sm zero-border-right ${search ? 'alert-warning' : ''}`}
                                    placeholder={`Search ${idColumn}`} onChange={e => setSearch(e.target.value)} onKeyUp={e => checkUpdateSearchInput(e, idColumn)} value={search} />
                                <span title="Search" className={`input-group-append ${search ? 'alert-warning' : ''}`}>
                                    <button className="btn btn-sm btn-default" type="button" onClick={e => updateSearchInput(search, idColumn)}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <hr className="hr-color full-width-hr " />
            <div className="width-100-p text-align-center">
                <button id="slicerHolderToggleButton" onFocus={(e) => e.target.blur()} onClick={() => saveShow(!show)} className={`expand-button btn btn-default btn-xs ${getExpandBtnColor()}`} title={getExpandBtnTitle()}>
                    <Arrow pose={show ? 'visible' : 'hidden'} className={'fa fa-angle-up'} />
                </button>
            </div>

            {
                slicerToEdit != null ?
                    <AddSlicerDialog2
                        slicer={slicerToEdit}
                        slicers={props.slicers}
                        show={showEditSlicer}
                        bucket={props.reportData.info}
                        saveSlicer={(s) => saveSlicer(s)}
                        onCancel={() =>  {setShowEditSlicer(false); setSlicerToEdit(undefined); setAddingSlicer(false); setIsFixedSlicer(false)}}
                        columns={columns}
                        textColumnHasTooManyValues={textColumnHasTooManyValues}
                        isFixedSlicer={isFixedSlicer}
                    />
                    : <></>
            }

            <WarningAcceptDialog title="Delete Slicer" acceptText="Delete" ref={deleteSlicerDialog} accept={onConfirmDeleteSlicer} closeHandler={() => setSlicerToDelete(undefined)} >
                <span>Are you sure you want to delete this slicer?</span>
            </WarningAcceptDialog>
        </div>
    )
}

//@ts-ignore
Slicer2 = connect(mapStateToProps)(Slicer2)

export default Slicer2


function SortableItem(props: any) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style}  className="inline-block margin-bottom-10px vertical-align-middle margin-left-10">
            <div style={{display: "flex"}}>
                {
                    props.editMode ? <button className='fixed-slicer-drag' {...attributes} {...listeners} style={{cursor:'w-resize'}}>⠿</button> : null
                }

                {props.children}
            </div>
        </div>
    );
}

