import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getBucketInfo = bucket => {
    return packAction(ActionTypes.GET_BUCKET_INFO, bucket)
}

export const gotBucketInfo = info => {
    return packAction(ActionTypes.GET_BUCKET_INFO_DONE, info)
}

export const getBucketUsers = bucket => {
    return packAction(ActionTypes.GET_BUCKET_USERS, bucket)
}

export const gotBucketUsers = (users, bucket) => {
    return packAction(ActionTypes.GET_BUCKET_USERS_DONE, { users: users, bucket: bucket })
}

export const gotSearch = result => {
    return packAction(ActionTypes.SEARCH_DONE, result)
}

export const setNewReportBucket = bucket => {
    return packAction(ActionTypes.SET_NEW_REPORT_BUCKET, bucket)
}

export const getBucketsDataInfo = () => {
    return packAction(ActionTypes.GET_BUCKETS_INFO)
}

export const gotBucketsDataInfo = buckets => {
    return packAction(ActionTypes.GET_BUCKETS_INFO_DONE, buckets)
}

export const getDownloadToken = (bId, type) => {
    return packAction(ActionTypes.GET_DOWNLOAD_TOKEN, { bId: bId, type: type })
}

export const gotDownloadToken = (res) => {
    return packAction(ActionTypes.GET_DOWNLOAD_TOKEN_DONE, res)
}

export const getProfitData = bucket => {
    return packAction(ActionTypes.GET_PROFIT_DATA, {bucket_id: bucket})
}

export const gotProfitData = (id, data) => {
    return packAction(ActionTypes.GET_PROFIT_DATA_DONE, {id: id, data: data})
}

export const getStockBalanceData = bucket => {
    return packAction(ActionTypes.GET_STOCK_BALANCE_DATA, {bucket_id: bucket})
}

export const gotStockBalanceData = (id, data) => {
    return packAction(ActionTypes.GET_STOCK_BALANCE_DATA_DONE, {id: id, data: data})
}

export const getStockBalanceColumnData = (bucketId, itemId) => {
    return packAction(ActionTypes.GET_STOCK_BALANCE_COLUMN_DATA, {bucket_id: bucketId, item_id: itemId})
}

export const gotStockBalanceColumnData = data => {
    return packAction(ActionTypes.GET_STOCK_BALANCE_COLUMN_DATA_DONE, {data: data})
}

export const updateBucketSetup = (bId, data, callback) => {
    return packAction(ActionTypes.UPDATE_BUCKET_SETUP, {bucket_id: bId, data: data, callback: callback})
}

export const updateBucketAccess = (organization_id, user_id, bucket_ids) => {
    const data = {
        organization_id: organization_id, 
        buckets: bucket_ids,
    }
    
    return packAction(ActionTypes.UPDATE_BUCKET_ACCESS, {data: data, id: user_id})
}
export const gotUpdatedBucketAccess = (buckets) => {
    return packAction(ActionTypes.UPDATE_BUCKET_ACCESS_DONE, {buckets: buckets})
}

export const downloadBucketDataSpecs = (bid, data) => {
    return packAction(ActionTypes.DOWNLOAD_BUCKET_DATA_SPECS, {id: bid, data: data})
}