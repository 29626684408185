import { RefObject } from 'react'
import DataGrid from '../components/DataGrid'
class MasterDataManager {
    static ref:RefObject<any>|null

    static setInstanceRef(r:RefObject<any/*TODO: use correct type for MasterDataDialog functional component*/>) {
        MasterDataManager.ref = r
    }

    static getInstanceRef() {
        return MasterDataManager.ref
    }
}
MasterDataManager.ref = null

export const setMasterInstanceRef = (ref:RefObject<any>) => MasterDataManager.setInstanceRef(ref)

export const setGridRef = (ref:RefObject<DataGrid>) => MasterDataManager.ref?.current.setGridRef(ref)

export const showMasterDataDialog = (bucketId:string, itemId:string, noGrid:boolean, nextButtonState:number, fromDiff: boolean, columnDescriptions: any, editMode: boolean, showComments: boolean) => MasterDataManager.ref?.current.show(bucketId, itemId, noGrid, nextButtonState, fromDiff, columnDescriptions, editMode, showComments)

export const setMasterDataBucket = (bucketId:string) => MasterDataManager.ref?.current.setBucket(bucketId)

export const setMasterDataItem = (itemId:string) => MasterDataManager.ref?.current.setItem(itemId)

export const setMasterDataNextButtonState = (state:number) => MasterDataManager.ref?.current.setNextButtonState(state)