import * as ActionTypes from '../../actions/ActionTypes'
import { packAction } from '../../actions/ActionTypes'
import { apiGet, apiPost, apiPut } from '../../helpers/ApiHelpers'
import { gotVersion, gotUsers } from '../../actions/SystemActions'
import { notifySuccess } from '../../helpers/NotificationManager'
import { notifyApiFailure, getErrorReport } from '../../helpers/ErrorHelpers'

const SystemApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_VERSION:
            url = 'version'
            apiGet(url, version => dispatch(gotVersion(version.version)), e => notifyApiFailure("getting platform version", 'GET', url, data, e.status))
            break
        case ActionTypes.PING:
            url = 'ping'
            apiPost(url, null, e => notifyApiFailure("pinging server", 'POST', url, data, e.status))
            break
        case ActionTypes.GET_ORG_USERS:
            url = 'users'
            apiGet(url, users => dispatch(gotUsers(users)), e => notifyApiFailure("getting organization users", 'GET', url, data, e.status))
            break
        case ActionTypes.REPORT_ERROR:
            url = 'error'
            data = getErrorReport(action.payload.info, action.payload.type)
            apiPost(url, data, errorReport => {
                dispatch(packAction(ActionTypes.REPORT_ERROR_DONE, {report: errorReport, notificationId: action.payload.notificationId}))
            }, e => notifyApiFailure("reporting error", 'POST', url, data, e.status, false))
            break
        case ActionTypes.COMMENT_ERROR_REPORT:
            url = 'error/'+action.payload.id
            data = {comment:action.payload.comment}
            apiPut(url, data, () => notifySuccess("Thanks for letting us know!"), e => notifyApiFailure("reporting error", 'POST', url, data, e.status, false))
            break
        case 'FORGOT_PASSWORD':
            url = 'password/forgot'
            data = action.payload
			apiPost(url, data, null)
			break
        case 'RESET_PASSWORD':
            url = 'password/reset'
            data = action.payload
			apiPost(url, data, () => {
                window.location.hash = '/login'
                notifySuccess("The password was successfully changed!")
            }, e => {
                window.location.hash = '/forgot'
                notifyApiFailure("resetting password", 'POST', url, data, e.status)
            })
			break
        case 'SELECT_PASSWORD':
            url = 'password/select'
            data = action.payload
			apiPost(url, data, () => {
                window.location.hash = '/login'
                notifySuccess("The password has successfully been set!")
            }, e => {
                window.location.hash = '/forgot'
                notifyApiFailure("setting password", 'POST', url, data, e.status)
            })
			break
        default:
            break
    }
}

export default SystemApiMiddleware