import { notifyFailure } from "./NotificationManager"
import moment from "moment"
import type { ErrorInfo } from "../types/types"
import { ErrorReport } from "../types/transfertypes"

export const getErrorMessage = (actionText:string):string => {
    return "Something went wrong while "+actionText+". If the problem persist, please contact support@inact.io"
}

export const notifyApiFailure = (actionText:string, method:string, url:string, data:{[key:string]:any}, httpStatus:number, sendErrorReport = true):void => {
    notifyFailure(getErrorMessage(actionText),  getApiErrorInfo(method, url, data, httpStatus), sendErrorReport && httpStatus !== 404)
}

export const getApiErrorInfo = (method:string, url:string, data:{[key:string]:any}, status:number):ErrorInfo => { return {method, url, data, status} }

export const getErrorReport = (errorInfo:ErrorInfo, type:number):ErrorReport => {
    return {
        error_type: type,
        url: window.location.href,
        comment: "",
        time: moment().unix(),
        message: errorInfo.message ? errorInfo.message : "",
        http_method: errorInfo.method ? errorInfo.method : "",
        http_code: errorInfo.status ? errorInfo.status : 0,
        endpoint: errorInfo.url ? errorInfo.url : "",
        data: errorInfo.data ? JSON.stringify(errorInfo.data, null, 1)  : "",
        error_name: errorInfo.name ? errorInfo.name : "",
        report_settings: errorInfo.report_settings ? JSON.stringify(errorInfo.report_settings, null, 1) : "",
        stack_trace: errorInfo.stack_trace ? errorInfo.stack_trace : ""
    }
}