import Creatable from 'react-select/creatable'

function CustomCreatable(props){
    const colourStyles = {
        control: (styles) => ({ 
          ...styles,
          ':focus-within' : { 
            borderColor: "var(--tangerine-hover)", 
            boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
          }
        }),
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          color: "black",
          backgroundColor: "white",
        }),
      };

    return <Creatable styles={colourStyles} {...props} />
}

export default CustomCreatable;