import React from 'react'

import '../../css/MultiToggle.css'

export interface MultiToggleOption {
    value: any,
    label: React.ReactNode,
}

interface MultiToggleProps {
    value: any,
    onClick: (value: any) => void,
    options: MultiToggleOption[],
    style?: React.CSSProperties,
    className?: string,
}

const MultiToggle = (props: MultiToggleProps) => {

    return (
    <div className={`multi-toggle-container ${props.className ?? ''}`} style={props.style ?? {}}>
        {props.options.map((o, i) => {
            return <button className={`multi-toggle-option ${props.value == o.value ? 'multi-toggle-selected' : ''}`} onClick={() => props.onClick(o.value)} key={i}>
                {o.label}
            </button>
        })}
    </div>)
}

export default MultiToggle
