import * as ActionTypes from '../../actions/ActionTypes'
import { getDashboardAllDataDone, gotDashboardOverview, getHomeDashboard, gotHomeDashboard, gotDefaultDashboard, dashboardNotAccessible, gotDashboardReports, getDashboardOverview } from '../../actions/DashboardActions'
import { apiGet, apiPost, apiPut, apiDelete } from '../../helpers/ApiHelpers'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { notifySuccess } from '../../helpers/NotificationManager'
import { updateHomeDashboardDone } from '../../actions/DashboardActions'


const DashboardApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)

    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_DASHBOARD_DATA:
            url = `dashboard/${action.payload.dID}`
            apiGet(url, data => dispatch(getDashboardAllDataDone(data, action.payload.dID)), e => {
                if(e.status === 403) {
                    dispatch(dashboardNotAccessible())
                } else {
                    notifyApiFailure("getting dashboard contents", 'GET', url, data, e.status)
                }
            })
            break
        case ActionTypes.GET_DASHBOARD_OVERVIEW:
            url = `dashboard_overview`
            apiGet(url, data => dispatch(gotDashboardOverview(data)), e => notifyApiFailure("getting dashboard overview", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_HOME_DASHBOARD:
            url = `home/dashboard`
            apiGet(url, data => dispatch(gotHomeDashboard(data)), e => notifyApiFailure("getting home dashboard", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_DASHBOARD_REPORTS:
            url = 'dashboardReports'
            apiGet(url, data => dispatch(gotDashboardReports(data), e => notifyApiFailure("getting dashboard Insight", 'GET', url, data)))
            break
        case ActionTypes.GET_DEFAULT_DASHBOARD:
            url = `organization/dashboard`
            apiGet(url, id => dispatch(gotDefaultDashboard(id)), e => notifyApiFailure("getting company dashboard", 'GET', url, data, e.status))
            break
        case ActionTypes.SET_HOME_DASHBOARD:
            url = `home/dashboard`
            data = action.payload
            apiPost(url, data, data => {
                dispatch(getHomeDashboard(data))
                if(action.payload.dashboard_id === 0) {
                    notifySuccess("Widgets removed from home")
                } else {
                    notifySuccess("Widgets added to home")
                }
            }, e => notifyApiFailure("setting home dashboard", 'POST', url, data, e.status))
            break
        case ActionTypes.SET_HOME_DASHBOARD_FOR_USER:
            url = `home/dashboard/${action.payload.userID}`
            data = action.payload
            apiPut(url, {dashboard_id: data.dashboardID}, data => {
                dispatch(updateHomeDashboardDone(data))
            } 
            , e => notifyApiFailure("setting home dashboard", 'PUT', url, data, e.status))
            break
        case ActionTypes.CREATE_DASHBOARD:
            url = 'dashboard'
            data = action.payload
            apiPost(url, data, (res) => {
                action.cb(res)
            }, e => {
                notifyApiFailure("creating new dashboard", 'POST', url, data, e.status)
            })
            break
        case ActionTypes.COPY_DASHBOARD:
            url = "copyDashboard"
            data = action.payload
            apiPost(url, data, res => {
                action.cb(res)
            }, e => {
                notifyApiFailure("copying dashboard", 'POST', url, data, e.status)
            })
            break
        case ActionTypes.DELETE_DASHBOARD:
            url = `dashboard/${action.payload.dashboard_id}`
            apiDelete(url, () => {
                notifySuccess("Successfully deleted dashboard")
                dispatch(getDashboardOverview())
            }, e => {
                notifyApiFailure("deleting dashboard", 'DELETE', url, action.payload, e.status)
            })
            break
        case ActionTypes.UPDATE_DASHBOARD:
            url = 'dashboard'
            data = action.payload
            apiPut(url, data, (res) => {
                notifySuccess("Successfully saved dashboard")
                action.cb(res)
            }, e => {
                notifyApiFailure("updating dashboard", 'PUT', url, data, e.status)
            })
            break
        case ActionTypes.CALCULATE_DASHBOARD_TABLE:
            url = 'dashboardTable'
            data = action.payload
            apiPost(url, data, (res) => {
                action.cb(res)
            }, e => {
                notifyApiFailure("calculating dashboard table", 'POST', data, e.status)
            })
            break
        case ActionTypes.CALCULATE_TMP_DASHBOARD:
            url = 'dashboardTmp'
            data = action.payload
            apiPost(url, data, (res) => {
                action.cb(res)
            }, e => {
                notifyApiFailure("calculating dashboard", 'POST', data, e.status)
            })
            break
        default:
            break
    }
}

export default DashboardApiMiddleware
