import React from 'react'
import ReactSelect from 'react-select'

class CustomSelect extends React.Component{
    render() {
      const colourStyles = {
        control: (styles) => ({ 
          ...styles,
          ':focus-within' : { 
            borderColor: "var(--tangerine-hover)", 
            boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
          }
        }),
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          color: "black",
          backgroundColor: "white",
        }),
      };
      return <ReactSelect styles={colourStyles} classNamePrefix="custom-select" {...this.props} />;
  }

}

export default CustomSelect