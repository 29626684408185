import React, {Component} from 'react'
import { connect } from 'react-redux'
import { getAllBuckets } from '../../actions/ReportActions'
import { getAddress } from '../../helpers/ApiHelpers'
import moment from 'moment'
import ShowIf from '../Generic/ShowIf'
import { setPageTitle } from '../../helpers/DocumentHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        buckets: state.Report.buckets,
		user: state.User.info,
    }
}

class UpdateData extends Component {
    state = {
        selectedBucket: "",
    }

    componentDidMount() {
        const { dispatch } = this.props
        setPageTitle("Update data")
        dispatch(getAllBuckets())
    }

    render() {
        const { user } = this.props 
        const buckets = this.props.buckets.get("buckets", [])

        return (
            <div className="container-fluid">
                <h1 className="pt-3 margin-bottom-5px">Update data</h1>
                <hr className="full-width-hr hr-color margin-top-0"/>
                <h2>Select data to update:</h2>
                <div className="search-input-container">
                    { buckets &&
                        <select className="form-control form-control-sm margin-left-5px" value={JSON.stringify(this.state.selectedBucket)} onChange={(e) => this.setState({selectedBucket: JSON.parse(e.target.value)})}>
                            <option style={{display: "none"}} value="">Select bucket</option>
                            {
                                buckets.map((b, index) => {
                                    return <option value={JSON.stringify(b)} key={index}>{b.name}</option>
                                })
                            }
                        </select> 
                    }
                    <ShowIf if={buckets === undefined || buckets === null}>
                            No buckets to update!
                    </ShowIf>
                </div>

                <ShowIf if={this.state.selectedBucket.id}>

                <h2>Select ABC Analyzer project file:</h2>

                <form method="post" action={`${getAddress('upload')}/${user.organization}/${this.state.selectedBucket.id}`} encType="multipart/form-data">
                                <input type="hidden" name="redirectUrl" value={window.location.href} />
                                <input type="hidden" name="bucket" value={this.state.selectedBucket.id} />

                                <div className="form-group">
                                    <label>Select project file (.ABCX)</label>
                                    <input type="file" accept=".abcx" name="project_file" required={true} />
                                </div>

                                    <input type="hidden" name="usedatadate" value="1" />
                                    <input type="hidden" name="datadate" placeholder="YYYY-MM-DD" value={moment().format('YYYY-MM-DD')}/>

                                <button type="submit" className="btn btn-default">Upload</button>
                            </form>
                </ShowIf>
            </div>
        )
    }
}

UpdateData = connect(mapStateToProps)(UpdateData)

export default UpdateData