/* REACT */
import React from 'react'
import KPIElementBase from '../../KPIElementBase'
import { canKpiColumn } from '../../../helpers/ReportHelpers'

class KPIReportAddElement extends KPIElementBase {

    state = {
        add: false,
        decimals: false,
        selectedKpi: {},
        selectedAgg: "sum",
        aggregations: ['sum', 'max', 'min', 'avg', 'count'],
        percentage: false,
        trend: 0
    }

    componentDidMount() {
        let kpi = this.props.columns[0]
        let agg = "sum"
        let decimals = false
        let trend = false
        if (this.props.kpi) {
            kpi = this.props.kpi.column
            agg = this.props.kpi.aggregation
            decimals = this.props.kpi.showKPIDecimals ? this.props.kpi.showKPIDecimals : this.props.kpi.showDecimals
            trend = this.props.kpi.trend
            if(typeof trend === "boolean" || trend === undefined) trend = 0
        } else {
            let found = false
            this.state.aggregations.forEach((a, i) => {
                if (canKpiColumn(a, kpi, this.props.reportdata.info.model) && !found) {
                    agg = a
                    found = true
                }
            })
            kpi = "Select column"
        }

        this.setState({ selectedAgg: agg, selectedKpi: kpi, decimals: decimals, trend: trend })
    }

    saveKpi() {
        let kpi = { column: this.state.selectedKpi, aggregation: this.state.selectedAgg, showKPIDecimals: this.state.decimals, showPercentage: this.state.percentage, trend: this.state.trend }
        this.props.saveKpi(kpi, this.props.index)
        this.setState({ add: false })
    }

    deleteKpi(e) {
        e.stopPropagation()
        let kpis = JSON.parse(this.props.reportdata.report.kpis)
        if (kpis[this.props.index]) {
            this.props.resetKpi(this.props.index)
        } else {
            this.props.resetKpi()
        }
    }

    stopAdd(e) {
        e.stopPropagation()
        this.props.cancel()
        this.setState({ add: false })
    }

    handleKpiChange(e) {
        const kpi = e.target.value
        let agg = ""
        let found = false
        this.state.aggregations.forEach((a, i) => {
            if (canKpiColumn(a, kpi, this.props.reportdata.info.model) && !found) {
                agg = a
                found = true
            }
        })

        this.setState({ selectedKpi: kpi, selectedAgg: agg })
    }

    handleAggChange(e) {
        this.setState({ selectedAgg: e.target.value })
    }

    extraClass() {
        if (this.state.add) {
            return 'abc-click report-kpi-add-element'
        }

        return 'abc-click report-kpi-element'
    }

    setTrend(trend) {
        if (trend === 1) trend = -1
        else trend ++
        this.setState({ trend: trend })
    }

    content() {
        let getDecColor = (bool) => {
            if (bool) {
                return 'active'
            }
            return ''
        }

        let canShow = (agg) => {
            if (!canKpiColumn(agg, this.state.selectedKpi, this.props.reportdata.info.model)) {
                return 'no-show'
            }
            return ''
        }
        
        return (
            <div className="report-kpi-inner-element" onClick={() => this.setState({ add: true })}>
                <span className="text-align-center">
                    <div className="margin-top-5px">
                        <div className="margin-bottom-5px" id="marginfix">  
                            <div className="form-group inline-block margin-bottom-0px">
                                <select id="marginfix" className="form-control form-control-sm " value={this.state.selectedKpi} onChange={(e) => this.handleKpiChange(e)}>
                                    <option disabled >Select column</option>
                                    {
                                        this.props.columns.map((c, i) => {
                                            return <option key={i} value={c}>{c}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                            
                        <div className="margin-bottom-5px" id="marginfix">
                            <div className="form-group width-100px inline-block margin-bottom-0px">
                                <select className="form-control form-control-sm" id="aggOptions" value={this.state.selectedAgg} onChange={(e) => this.handleAggChange(e)}>
                                    <option value="sum" className={`${canShow('sum')}`}>Sum</option>
                                    <option value="max" className={`${canShow('max')}`}>Maximum</option>
                                    <option value="min" className={`${canShow('min')}`}>Minimum</option>
                                    <option value="avg" className={`${canShow('avg')}`}>Average</option>
                                    <option value="count" className={`${canShow('count')}`}>Count</option>
                                </select>
                            </div>
                            <div className="inline-block">
                                <button id="buttonfix" className={`btn btn-sm margin-left-5px btn-default ${getDecColor(this.state.decimals)}`} onClick={() => this.setState({ decimals: !this.state.decimals })}>
                                    0.0
                                </button>
                            </div>
                            {
                                this.props.fromKpiView ?
                                    <div className="inline-block">
                                        <button id="buttonfix" className={`btn btn-sm margin-left-5px btn-default ${getDecColor(this.state.percentage)}`} onClick={() => this.setState({ percentage: !this.state.percentage })}>%</button>
                                    </div> 
                                : null
                            }
                        </div>
                        
                        <div>
                            <div className="btn-group text-align-left">
                                <button className="btn btn-sm btn-default inline-block" onClick={e => this.deleteKpi(e)}
                                    title="Delete kpi"><span className="fa fa-times red"></span>
                                </button>
                                <button className="btn btn-sm btn-default inline-block" disabled={this.state.selectedKpi === 'Select column'} title="Save kpi" onClick={() => this.saveKpi()}>
                                    <span className="fa fa-check green"></span>
                                </button>
                            </div>
                            <div className="inline-block margin-left-5px">
                                <button className="btn btn-sm btn-default" onClick={(e) => this.stopAdd(e)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        )
    }
}

export default KPIReportAddElement

