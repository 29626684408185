import React, { useEffect, useRef, useState } from "react"
import DiffView2, { DiffViewProps } from "../Report/TrendComponents/DIffView2"

interface DiffViewDialogProps extends DiffViewProps {
    show: boolean,
    setShow: (show: boolean) => void,
    promptCreateChecklist: () => void,
}

const DiffViewDialog2 = (props: DiffViewDialogProps) => {

    const diffRef = useRef<any>()
    const [items, setItems] = useState([])

    useEffect(() => {
        // @ts-ignore
        window.$(diffRef.current).on('hide.bs.modal', (e) => {
            if(e.target.id == "dialogContainer"){
                props.setShow(false)
                setItems([])
            }
        })
    }, [])

    useEffect(() => {
        if(props.show){
            show()
        }
    }, [props.show])

    function setSelectedItems(ids: any) {
        setItems(ids)
        props.setSelectedItems(ids)
    }

    function hide() {
        // @ts-ignore
        window.$(diffRef.current).modal('hide')
        props.setShow(false) 
        setItems([])
    }

    function show() {
        //@ts-ignore
        window.$(diffRef.current).modal('show')
        setItems([])
    }
    function promptCreateChecklist() {
        hide();
        props.promptCreateChecklist()
    }

    // const createChecklist = (type: any, label1: any, label2: any) => {
    //     const status: any = { "All": "All", "1": "Newcomers", "-1": "Gone", "0": "Still in the Insight" }
    //     const name = `${status[type]}, ${label1.substring(0, 3)}-${label2.substring(0, 3)}. ${props.reportdata.report.name}`
    //     hide()
    //     props.createChecklist(name, props.reportdata.report.limit, props.reportdata.report.sort_column, props.reportdata.report.sort_direction)
    // }


    return (<div ref={diffRef} className="modal" tabIndex={-1} role="dialog" id="dialogContainer" style={{overflowY: "auto", }}>
    <div className="modal-dialog modal-dialog-custom" role="document" style={{marginTop: 50}}>
        <div className="modal-content modal-content-custom">
            <div className="modal-body no-padding">
                {props.show && props.trendsetsdata && <DiffView2
                        hideDiffData={(_: any) => hide()}
                        reportdata={props.reportdata}
                        selectedKpi={props.selectedKpi}
                        selectedCategory={props.selectedCategory}
                        slicers={props.slicers}
                        last={props.last}
                        sndLast={props.sndLast}
                        setSelectedItems={setSelectedItems}
                        selectedItems={items}
                        selectedItemsLength={items.length}
                        promptCreateChecklist={promptCreateChecklist}
                        editMode={props.editMode}
                        setData={props.setData}
                        settings={props.settings} 
                        setDiffSettings={props.setDiffSettings} 
                        diffdata={props.diffdata} 
                        trendsetsdata={props.trendsetsdata} 
                        setBothTextColumns={props.setBothTextColumns} 
                        setKpiView={props.setKpiView} 
                        dispatch={props.dispatch}     
                        disableSave={props.disableSave}
                        getDisabledText={props.getDisabledText}
                        promptExitEditMode={props.promptExitEditMode}
                        enterEditMode={props.enterEditMode}
                        />}
            </div>
        </div>
    </div>
</div>)
}

export default DiffViewDialog2
