//This file is purposefully not converted to TypeScript because some features rely on unsafe types in vanilla javascript

import { notifyFailure } from './NotificationManager'
import hash from 'object-hash'
import { apiGet, apiPost } from './ApiHelpers'
import { ABC_SINGLE_SETTINGS_SERVICE } from './SingleSettingService'

const ABC_SETTINGS_SERVICE = 'ABC_SETTINGS_SERVICE'

const storage = window.sessionStorage

const SESSION_SETTINGS = ABC_SETTINGS_SERVICE+"_SESSION_SETTINGS"

function getLocalKey(id) {
    return ABC_SETTINGS_SERVICE+'-'+id
}

function getSessionKey(id) {
    return SESSION_SETTINGS+"-"+id
}

const getFirstKey = () => {
    let keys = Object.keys(storage).sort((a,b) => {
        let aVal = JSON.parse(storage.getItem(a)).time
        let bVal = JSON.parse(storage.getItem(b)).time
        return aVal < bVal ? -1 : bVal > aVal ? 1 : 0
    })
    return keys[0]
}

const isQuotaError = (e) => {
    if (e) {
        if (e.code) {
            switch (e.code) {
                case 22:
                    return true
                case 1014:
                    // Firefox
                    if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                        return true
                    }
                    break
                default:
                    return false
            }
        } else if (e.number === -2147024882) {
            // Internet Explorer 8
            return true
        }
        return false
    }
    return false
}

const getSetting = (shortHash, localOnly, cb, cbErr) => {
    let key = getLocalKey(shortHash)
    let json = storage.getItem(key)
    if(!json) {
        if (localOnly) return cbErr()

        getFromBackend(shortHash, settings => {
            console.log("Setting found in db", settings)
            cb({shortHash: settings.short_hash, hash: settings.hash, time: settings.creation_time, value: JSON.parse(settings.settings)})
        }, () => {
            console.log("Setting not found in db")
            cbErr()
        })
    } else {
        let settings = JSON.parse(json)
        if(!settings) return cbErr()
        cb(settings)
    }
}

export const getSettingsFromPath = (cb, cbErr) => {
    //assuming that the settings hash is the last element in the url path,
    let pathComponents = window.location.hash.split("/")
    let settingsHash = pathComponents[pathComponents.length-1]
    getSetting(settingsHash, true, cb, cbErr)
}

const putSetting = (value, cb, cbErr) => {
    //Generate long hash
    let longHash = hash(value)

    //Try to generate unique short hash. If an identical exist and the long hash does not match, we try again with an additional character.
    let shortHashLen = 10
    let shortHash = longHash.substring(0,shortHashLen)
    let shortHashFree = false
    let getSettingCb = settings => {
        //A settings was found
        if(settings.hash === longHash) {
            shortHashFree = true 
        } else {
            shortHashFree = false
            shortHashLen++
            shortHash = longHash.substring(0,shortHashLen)
        }
    }
    let getSettingCbErr = () => {
        //A setting was not found
        shortHashFree = true
    }
    while (!shortHashFree && shortHashLen < longHash.length) {
        getSetting(shortHash, true, getSettingCb, getSettingCbErr)
    }

    //Save short and long hash
    let storedObject = {hash: longHash, value: value, time: new Date().toISOString(), shortHash: shortHash}
    try {
        storage.setItem(getLocalKey(shortHash), JSON.stringify(storedObject))
        cb(storedObject)
    } catch (e) {
        if (isQuotaError(e)) {
            let quotaError = true
            while(quotaError) {
                try {
                    let rShortHash = getFirstKey() //Alt: storage.keys(0), getting first key sorted by insertion time
                    console.warn("QUOTA ERROR, removing item: "+rShortHash)
                    storage.removeItem(rShortHash)
                    storage.setItem(getLocalKey(shortHash), storedObject)   
                    quotaError = false
                    cb(storedObject)
                    return
                } catch (e) {
                    //Do nothing
                }
            }
        } else  {
            notifyFailure("An error occurred while saving the settings for this page.")
            console.log(e)
            if(cbErr) cbErr()
        }
    }
}

const postToBackend = (value, longHash, cb, cbErr) => {
    apiPost(`report/settings`, {hash: longHash, settings: JSON.stringify(value)}, settings => {
        cb({shortHash: settings.short_hash, hash: settings.hash, time: settings.creation_time, value: JSON.parse(settings.settings)})
    }, () => {
        console.warn("Posting settings to backend failed!!", longHash)
        if(cbErr) cbErr()
    })
}

const getFromBackend = (shortHash, cb, cbErr) => {
    apiGet(`report/settings/${shortHash}`, res => {
        cb(res)
    }, () => {
        if(cbErr) cbErr()
    })
}

export const getSessionSettings = (id) => {
    // console.log('Getting settings...')
    let json = window.sessionStorage.getItem(getSessionKey(id))
    // console.log("Settings:", json ? JSON.parse(json).settings : json)
    return json ? JSON.parse(json) : json
}

export const putSessionSettings = (id, settings, url) => {
    // console.log('Putting settings...')
    // let json = window.sessionStorage.getItem(getSessionKey(id))
    // console.log('Current settings:', json ? JSON.parse(json).settings : json)
    // console.log('New settings:', settings)
    window.sessionStorage.setItem(getSessionKey(id), JSON.stringify({settings: settings, url: url}))
}

export const clearSessionSettings = () => { //removes all entries prefixed by the value in SESSION_SETTINGS
    Object.keys(window.sessionStorage).forEach(k => {
        if(k.startsWith(SESSION_SETTINGS) || k.startsWith(ABC_SINGLE_SETTINGS_SERVICE) || k.startsWith(ABC_SETTINGS_SERVICE)) {
            window.sessionStorage.removeItem(k)
        }
    })
}

const URLBuilder = (path, params, query, settingsHash) => {
    let keys = path.split("/").slice(1)
    let settingsHashMet = false
    let newUrl = ""
    for(let i = 0; i < keys.length; i++) {
        let key = keys[i]
        if(key === ":sHash" && !settingsHashMet) {
            newUrl += "/"+settingsHash
            settingsHashMet = true
        } else if(!key.startsWith(":")) {
            newUrl += "/"+key
        }
        else newUrl += "/"+params[key.substring(1)]
    }
    if (!settingsHashMet) newUrl += "/"+settingsHash
    return newUrl+query
}


/*
props:
    settingsParent: component ref (remember forwardRef: true for connected components and provide wrappedInstance in ref) (optional, but doesn't work as intended without)

parameters:
    C: the class to be wrapped (required)
    getSettingsKeys: function that returns the keys that should be saved as settings, given the state (optional, default: no keys)
    storeSettingsPredicate: function that returns true if the state should be stored in settings. Takes prevState and curState as parameters. (optional, default: always true)
    isSilentUpdate: function that returns true if the url should be replaced (not added to history stack!) or false if the url should be pushed, given prevState and curState (optional, default: always false)
    settingsDidApply: a callback function called immediatly after new settings has been applied to the state (optional)
    id: a string which is used to identify previously saved settings and to save new settings. (optional, default: name of wrapped class (not guarranteed to be unique!))    

notes:
    If you are using connect (from redux) and withRouter (from react router dom), they need to be applied after applying withSettingsPropagation. Following order is recommended: withSettingsPropagation, connect, withRouter
*/
export const withSettingsPropagation = (C, getSettingsKeys, storeSettingsPredicate, isSilentUpdate, settingsDidApply, id) => {
    if(!C) throw new Error("No class provided for wrapping")
    if(!id) id = C.name
    return class SettingsPropagater extends C {
        constructor(props) {
            super(props)
            this.state.applyingSettings = true
            this.state.stopPropagation = false
        }
        subscribers = []
        lastSettings = null

        onReceiveSettingsHandler = s => this.onReceiveSettings(s)

        componentDidMount() {
            if(super.componentDidMount) super.componentDidMount()   

            let settingsParent = this.props.settingsParent            
            if(!this.state.stopPropagation && settingsParent && settingsParent.subscribe) {
                settingsParent.subscribe(this)
            }
            else if(!this.state.stopPropagation) console.warn("Propagation chain broken, no valid parent to subscribe to.", id)
        }

        componentWillUnmount() {
            if(super.componentWillUnmount) super.componentWillUnmount()   
            
            let settingsParent = this.props.settingsParent            
            
            if(!this.state.stopPropagation && settingsParent && settingsParent.unsubscribe) {
                settingsParent.unsubscribe(this)
            }
            else if(!this.state.stopPropagation) console.warn("Something went wrong, could not unsubscribe.", id)            
        }

        subscribe(object) {
            this.subscribers.push(object)
            if(this.lastSettings) {
                let {children} = this.lastSettings
                object.onReceiveSettingsHandler(children)
            }
        }

        unsubscribe(object) {
            let index = this.subscribers.indexOf(object)
            this.subscribers = this.subscribers.splice(index, 0)
        }
        
        onReceiveSettings(settings) {
            //console.log("received ["+id+"]", settings)
            if(settings && settings[id]) {
                let lastSettings = Object.assign({}, this.lastSettings)
                let {children, ...mySettings} = this.lastSettings = settings[id]
                for(let i = 0; i < this.subscribers.length; i++) {
                    this.subscribers[i].onReceiveSettingsHandler(children)
                }
                this.setState(Object.assign({}, this.state, mySettings, {applyingSettings: true}), () => {
                    if (settingsDidApply) settingsDidApply(this, lastSettings, false)
                })
            }
        }
        
        shouldComponentUpdateSettings(prevState) {
            return storeSettingsPredicate(prevState, this.state) && !this.state.applyingSettings
        }

        collectSettings() {
            let children = {}
            for(let i = 0; i < this.subscribers.length; i++) {
                Object.assign(children, this.subscribers[i].collectSettings())
            }
            let settings = {[id]: Object.assign({}, this.getSettings(), {children: children})}
            return settings
        }
        
        onSettingsUpdated(silent) {
            let settingsParent = this.props.settingsParent
            if(!this.state.stopPropagation && settingsParent && settingsParent.onSettingsUpdated) settingsParent.onSettingsUpdated(silent)
        }
        
        componentDidUpdate(prevProps, prevState, snapshot) {
            if(super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState, snapshot)

            if(this.shouldComponentUpdateSettings(prevState)) {
                let silent = isSilentUpdate ? isSilentUpdate(prevProps, prevState, snapshot, this) : false
                this.onSettingsUpdated(silent)
            }
            if(this.state.applyingSettings) this.setState({applyingSettings: false})

        }

        getSettings() {
            let {applyingSettings, stopPropagation, ...state} = this.state
            let keys = getSettingsKeys(state, this.props)
            let settings = {}
            for(let i = 0; i < keys.length; i++) {
                settings[keys[i]] = this.state[keys[i]]
            }
            return settings
        }
    }
    
}

/*
props:
    settingsParent: component ref (remember forwardRef: true for connected components and provide wrappedInstance in ref) (optional, but doesn't work as intended without)

parameters:
    C: the class to be wrapped (required)
    getSettingsKeys: function that returns the keys that should be saved as settings, given the state (optional, default: no keys)
    storeSettingsPredicate: function that returns true if the state should be stored in settings. Takes prevState and curState as parameters. (optional, default: always true)
    isSilentUpdate: function that returns true if the url should be replaced (not added to history stack!) or false if the url should be pushed, given prevProps, prevState, snapshot and this (optional, default: always false)
    settingsDidApply: a callback function called immediatly after new settings has been applied to the state (optional)
    getSessionStoreKey: a function that generates a key for the current state of the component. This is used to find settings at mount, if no settings are provided (required for withSessionStore)
    applySessionSettingsPredicate: a function returning a boolean whether the settings saved in session should be applied. Only used with withSessionStore enabled. (opional, default: always true)
    id: a string which is used to identify previously saved settings and to save new settings. (optional, default: name of wrapped class (not guarranteed to be unique!))    
    options: object
        withURLBacktrack - bool: if the component should use add settings settingsHash to url. Requires with withRouter after this and connect enhancers.
        withSessionStore - bool: if the component should store its latest known state and load it next time it mounts.
        withPropagation - bool: if the component should try to propagate its settings to a parent component, like SettingsPropagater

notes:
    If you are using connect (from redux) and withRouter (from react router dom), they need to be applied after applying withSettingsStorage. Following order is recommended: withSettingsStorage, connect, withRouter.
    If you enable URL backtracking, you must have a succeeding withRouter wrapper.

*/

export const withSettingsStorage = (C, getSettingsKeys, storeSettingsPredicate, isSilentUpdate, settingsDidApply, getSessionStoreKey, componentDidChangeURL, applySessionSettingsPredicate, id, options) => {
    if(!C) throw new Error("No class provided for wrapping")
    if(!id) id = C.name
    C = withSettingsPropagation(C, getSettingsKeys, storeSettingsPredicate, isSilentUpdate, settingsDidApply, id)
    C = class SettingsStorer extends C {
        constructor(props) {
            super(props)
            if(options && options.withURLBacktrack && !props.match) throw new Error("You must use a router (alt. use withRouter()) to use URLBacktracking.")
            this.state.stopPropagation = !(options && options.withPropagation)
        }

        componentDidMount() {
            super.componentDidMount()

            let settingsHash = this.props?.match?.params?.sHash
            if(settingsHash && options && options.withURLBacktrack) {
                getSetting(settingsHash, false, settings => {
                    this.currentSettingsHash = settings.shortHash
                    if (settings) this.onReceiveSettings(settings.value)
                    else if(settingsDidApply) settingsDidApply(this, {}, true)
                }, () => {})
            } else if(!settingsHash && options && options.withSessionStore) {
                let sessionSettingsObject = getSessionSettings(getSessionStoreKey(this))
                
                if(!sessionSettingsObject) {
                    if(settingsDidApply) settingsDidApply(this, {}, true)
                    return
                }

                let {settings, url} = sessionSettingsObject

                if(options?.withURLBacktrack && ((applySessionSettingsPredicate && applySessionSettingsPredicate(this)) || !applySessionSettingsPredicate)) 
                    this.props.history.replace(URLBuilder(url.path, url.params, this.props.history.location.search, options.withURLBacktrack ? settings.shortHash : ""))
                this.currentSettingsHash = settings.shortHash
                this.onReceiveSettings(settings.value)                
            } else if(!settingsHash) {
                this.onSettingsUpdated(true)
            }
        }

        onSettingsUpdated(silent) {
            super.onSettingsUpdated(silent) //propagation

            let collectedSettings = this.collectSettings()

            
            putSetting(collectedSettings, settings => {
                let prevSettingsHash = this.props?.match?.params?.sHash
                let params = this.props?.match?.params
                let path = this.props?.match?.path
                
                if(options && options.withURLBacktrack && settings.shortHash !== prevSettingsHash) {
                    let url = URLBuilder(path, params, this.props.history.location.search, settings.shortHash)
                    if(silent) this.props.history.replace(url)
                    else this.props.history.push(url)
                }
                
                if(options && options.withSessionStore && settings.shortHash !== prevSettingsHash) {
                    putSessionSettings(getSessionStoreKey(this), settings, {path: path, params: params})
                    this.onReceiveSettings(collectedSettings)
                }
            })
        }

        currentSettingsHash

        async getPermanentLink(pathOnly = false) {
            let settingsHash = this.props.match.params.sHash
            return new Promise((resolve, reject) => {
                getSetting(settingsHash, false, settings => {
                    if(!settings) reject(new Error("Settings are empty!"))
                    postToBackend(settings.value, settings.hash, linkObj => {
                        let params = this.props.match.params
                        let path = this.props.match.path
                        let query = this.props.history.location.search
                        if(!query) query = "?exact=true"
                        else if(!query.includes("exact=true")) query += "&exact=true"
                        let url = URLBuilder(path, params, query, linkObj.shortHash)

                        resolve(pathOnly ? url : window.location.protocol+'//'+window.location.host+window.location.pathname+"#"+url)
                    }, () => {
                        reject(new Error("Posting to backend failed!"))
                    })
                }, () => {
                    reject(new Error("Settings not found!"))
                })

            })
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState, snapshot)
            if(options && options.withURLBacktrack) {
                let settingsHash = this.props.match.params.sHash
                let prevSettingsHash = prevProps.match.params.sHash
                if(settingsHash !== prevSettingsHash) { //If hash in url changed
                    getSetting(settingsHash, false, settings => {
                        this.currentSettingsHash = settingsHash
                        if (settings) this.onReceiveSettings(settings.value)
                    }, () => {})
                    if(componentDidChangeURL) componentDidChangeURL(this)
                } else if(!settingsHash && this.currentSettingsHash) { //If there's no hash in the url, but there's one stored; put it in the url
                    let params = this.props.match.params
                    let path = this.props.match.path
                    let url = URLBuilder(path, params, this.props.history.location.search, this.currentSettingsHash)
                    this.props.history.replace(url)
                    if(componentDidChangeURL) componentDidChangeURL(this)
                } else if(!settingsHash) { //If there's no hash in the url, and there's none stored; save settings to generate hash
                    this.onSettingsUpdated(true)
                    if(componentDidChangeURL) componentDidChangeURL(this)
                } else if(this.props.match.url !== prevProps.match.url) { //If the hash did not change, but other parts of the url did
                    getSetting(settingsHash, true, settings => {
                        let params = this.props.match.params
                        let path = this.props.match.path
                        putSessionSettings(getSessionStoreKey(this), settings, {path: path, params: params})
                    }, () => {})
                    if(componentDidChangeURL) componentDidChangeURL(this)
                }
            }
        }
    }
    return C
}