import { forwardRef, useEffect, useState, useMemo, useRef } from "react"
import { useDispatch } from "react-redux"
import BaseDialog from "./BaseDialog"
import ReactDOM from "react-dom"
import { toggleFavourite } from "../../actions/ReportActions"

const HomeReportDialog = forwardRef(({ onSave, favourites, reports }, ref) => {
    const dispatch = useDispatch()
    const selectRef = useRef(null)
    const [filter, setFilter] = useState("")
    const [selectedReports, setSelectedReports] = useState([])
    const filteredReports = useMemo(() => reports.filter(r => selectedReports.find(rs => r.report_id === rs.report_id) == null ).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())), [reports, selectedReports])

    useEffect(() => {
        setSelectedReports(favourites.map(r => r.report))
    }, [favourites])


    useEffect(() => {
        // for making the gray line after selecting disappear
        if (ReactDOM.findDOMNode(selectRef.current)) {
            ReactDOM.findDOMNode(selectRef.current).selectedIndex = -1
        }
    }, [selectedReports])

    const onCloseHandler = () => {
        return (e) => {
            ref.current?.hide()
            setFilter("")
            setSelectedReports([])
        }
    }

    const onSaveHandler = () => {
        const dataArr = []
        const _favourites = favourites.map(f => f.report)
        
        // Add new favourites if not already favourited
        selectedReports.forEach(r => {
            const isFav = _favourites.find(f => f.report_id === r.report_id) !== undefined
            if (!isFav) {
                dataArr.push({
                    favourited: true, item_type: 'report', item_id: r.report_id
                })
            }
        })

        // Remove favourites if not selected anymore
        _favourites.forEach(f => {
            const isNotFavAnymore = selectedReports.find(r => r.report_id === f.report_id) === undefined
            if (isNotFavAnymore) {
                dataArr.push({
                    favourited: false, item_type: 'report', item_id: f.report_id
                })
            }
        })

        // Toggle all favourites
        dataArr.forEach(d => dispatch(toggleFavourite(d)))

        onSave()
        setFilter("")
        ref.current?.hide()
    }

    const onSelectChange = (e) => {
        const rID = parseInt(e.target.value, 10)
        const report = reports.find(r => r.report_id === rID)
        const _selectedReporrts = selectedReports.slice()
        _selectedReporrts.push(report)
        setSelectedReports(_selectedReporrts)
    }

    const removeHandler = (report) => {
        const _selectedReports = selectedReports.filter(r => r.report_id !== report.report_id)
        setSelectedReports(_selectedReports)
    }

    const searchFilter = (value) => {
        if (filter !== '') {
            return value.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        } else {
            return true
        }
    }

    return <Dialog ref={ref} title={"Select Insights"} save={_ => onSaveHandler()} closeHandler={onCloseHandler()} disableBackdrop={true}>
        <div className="form">
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text"><i className="fa fa-search"></i></div>
                </div>

                <input id="textSlicerModalFocus" autoFocus className="form-control margin-bottom-5px" placeholder="Search" value={filter} onChange={(e) => setFilter(e.target.value)} />
            </div>
            <select ref={selectRef} className="form-control width-100-p" size="6" onChange={(e) => onSelectChange(e)}>
                {
                    filteredReports.filter(searchFilter).map((item, index) => {
                        return (
                            <option className="noselect" key={index} value={item.report_id}>{item.name}</option>
                        )
                    })
                }
            </select>
            <hr className="margin-bottom-10px margin-top-10px" />
            <label>Selected Insights:</label>
            {selectedReports.length === 0 && <p><i>None selected</i></p>}
            <div className="form">
                {selectedReports.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((item, index) => <span key={index} className="abc-click abc-text-filer-value badge badge-secondary textSlicerSelected"
                    id="option-compose-viewer-slicerTextValues-remove" onClick={() => removeHandler(item)}>
                    {item.name} <i className="fa fa-times filter-label-remove"></i>
                </span>)}


            </div>
        </div>
    </Dialog>
})
class Dialog extends BaseDialog {
    getButtons() {
        return (
            <div>
                <button onClick={this.props.closeHandler} type="button" className="btn btn-default margin-right-10px">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => this.props.save()}>Save</button>
            </div>
        )
    }
}

export default HomeReportDialog
