import React from 'react'
import { ReactNode } from 'react-markdown'

export interface HideIfProps {
    if: boolean,
    children: ReactNode
}

const HideIf  = (props: HideIfProps) => {

    return <>
        {
            !props.if ?
                props.children
                : null
        }
    </>

}

export default HideIf
