import React from 'react'
import SaveDialog from './SaveDialog'
import SortableList from '../Generic/SortableList'
import ActiveCategoryIcon from '../Report/ReportComponents/ActiveCategoryIcon'
import ShowIf from '../Generic/ShowIf'
import ReactMarkdown from 'react-markdown'
import ReactTooltip from 'react-tooltip'

class ColumnSelectionDialog extends React.Component {
    state = {
        shownColumns: [],
        hiddenColumns: [],
        columns: [],
        columnDescriptions: [],
        search: ''
    }

    saveDialogRef = React.createRef()

    /*
    Format of prevShownColumns and columns:
    [{name: "my col", type: "id", index: 0}, {name: "my other col", type: "decimal", index: 1}, ...]
    */
    show(prevShowncolumns, columns, columnDescriptions) {
        this.saveDialogRef.current.show()
        this.setState({
            shownColumns: prevShowncolumns.slice(),
            hiddenColumns: columns.filter(c => prevShowncolumns.findIndex(_c => _c.name === c.name) < 0),
            columns: columns, 
            columnDescriptions: columnDescriptions
        })
    }

    toggleColumn(column, action) {
        let shown = this.state.shownColumns
        let hidden = this.state.hiddenColumns
        let index = -1

        if (action === "add") {
            shown.push(column)
            index = hidden.findIndex(c => c === column)
            hidden.splice(index, 1)
        } else if (action === "remove") {
            index = shown.findIndex(c => c === column)
            shown.splice(index, 1)
            hidden.push(column)
        }

        this.setState({ shownColumns: shown, hiddenColumns: hidden })
    }

    insertAt(oldIndex, newIndex) {
        let shown = this.state.shownColumns.slice()
        shown.splice(newIndex, 0, shown.splice(oldIndex, 1)[0])
        this.setState({ shownColumns: shown })
    }

    saveHandler = () => {
        this.props.saveHandler(this.state.shownColumns.slice())
    }

    render() {
        const { activeCategoryColumn } = this.props

        let getListItem = (column, action, actionText, actionIcon) => {
            return <>
            <div className="inline-block">
                {activeCategoryColumn === column.name ? <>
                    <div className='inline-block'>{column.name} &nbsp;</div>
                    <div className='inline-block pr-2'><ActiveCategoryIcon style={{ marginTop: -2 }} /></div>
                </> : <>{column.name} &nbsp;</>}

                {this.state.columnDescriptions?.[column.name] ? (
                    <div  className="float-right" style={{display: "inline"}}>
                        <i data-tip data-for={`tooltip-${column.index}`} style={{position: "relative"}} className="fa fa-info-circle margin-left-2px margin-right-10px glyphicon-info-gray"></i>
                        <ShowIf if={this.state.columnDescriptions[column.name]}>
                                <ReactTooltip id={`tooltip-${column.index}`} type='dark' effect='solid' place='bottom'><ReactMarkdown children={this.state.columnDescriptions[column.name]}/></ReactTooltip>
                        </ShowIf>
                    </div>
                ) : ""}

            </div>
            <div title={actionText} className={`fa checklist-column-icon abc-click float-right margin-right-5 ${actionIcon}`} onClick={() => this.toggleColumn(column, action)}></div>
            </>
        }

        let hiddenColumns = this.state.hiddenColumns
            .filter(c => this.state.search === '' || c.name.toLowerCase().includes(this.state.search.toLowerCase()))
            .sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            .map((c, _) => getListItem(c, "add", "Show", "fa-eye"))

        let shownColumns = this.state.shownColumns
            .filter(c => this.state.search === '' || c.name.toLowerCase().includes(this.state.search.toLowerCase()))
            .map((c, _) => getListItem(c, "remove", "Hide", "fa-eye-slash"))

        return (
            <SaveDialog ref={this.saveDialogRef} title="Column layout" closeHandler={this.props.closeHandler} saveHandler={this.saveHandler}>
                <React.Fragment>
                    <div className={'form-group'}>
                        <div className="input-group" id="searchBarContainerModal">
                            <input id="searchIdInput" className={`form-control form-control-sm zero-border-right`}
                                placeholder={`Search columns`} onChange={e => this.setState({ search: e.target.value })} value={this.state.search} />
                            <span className={`input-group-append`}>
                                <button title="Clear search filter" onClick={_ => this.setState({ search: "" })} className="btn btn-sm btn-default" type="button">
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <hr className='hr' />
                    <h4><i className="fa fa-eye margin-right-5 gray-color"></i> <b>Visible columns</b></h4>
                    <SortableList items={shownColumns} onSortEnd={(o, n) => this.insertAt(o, n)} containerClassName="sortable-list-container" />

                    <h4 className="margin-top-50px"><i className="fa fa-eye-slash margin-right-5 gray-color"></i> <b>Available columns</b></h4>
                    <SortableList items={hiddenColumns} disabled={true} containerClassName="sortable-list-container" />
                </React.Fragment>
            </SaveDialog>
        )
    }
}

export default ColumnSelectionDialog