import React from 'react'
import posed from 'react-pose'

class NewLayoutCategorySlider extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            overflow: props.expand
        }
    }

    Div = posed.div({
        expanded: {
            width: 'auto',
            boxShadow: '0px 0px 0px 5px rgb(253, 237, 0)',
            backgroundColor: 'rgb(253, 237, 0)',
            marginRight: 10
        },
        closed: {
            width: 0,
            boxShadow: '0px 0px 0px 0px rgb(255, 255, 255)',
            backgroundColor: 'rgb(255, 255, 255)',
            marginRight: 0
        }
    })

    onAnimationComplete = (currentPose) => {
        if(!this.props.expand) this.setState({overflow: false})
        else this.setState({overflow: true})
    }

    render() {
        return (
            <this.Div 
                pose={this.props.expand ? 'expanded' : 'closed'}
                id={this.props.id} 
                className={(this.state.overflow && this.props.expand ? "overflow-visible " : "overflow-hidden ") + this.props.className} 
                onPoseComplete={this.onAnimationComplete}
            >
                {this.props.children}
            </this.Div>
        )
        
    }
}

export default NewLayoutCategorySlider