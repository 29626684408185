import { useEffect } from 'react'

export default function useOnClickOutside(onClick: () => void, ref: any) {
    useEffect(() => {
        let handleClickOutside = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target)){
                onClick()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }


    }, [ref, onClick])

}
