import React from 'react'
import WordWrap from '../Generic/WordWrap'
import { getSingleItemHistoryBarData } from '../../helpers/ItemHelpers'
import { connect } from 'react-redux'
import { deepClone } from '../../helpers/GeneralHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        history: state.Item.history
    }
}

class SingleItemMonthLabels extends React.Component {
    render() {
        if(!this.props.history || !this.props.history.get(this.props.bucket.id, this.props.item)) return null

        let textColumnHistoryData = this.props.column && this.props.historyData.get(this.props.bucket.id, this.props.item, this.props.column.name) ? this.props.historyData.get(this.props.bucket.id, this.props.item, this.props.column.name).data : []
        let categoryHistoryData = this.props.history.get(this.props.bucket.id, this.props.item).data
        //concatenating text column values and category values in each month to detect changes in either of them without changing function getSingleItemHistoryBarData
        let mergedHistoryData = deepClone(categoryHistoryData).map((v,i) => {v.value = textColumnHistoryData[i] ? v.value + textColumnHistoryData[i].value : v.value; return v}) 
        mergedHistoryData = mergedHistoryData.slice(-12) //only latest 12 months

        let history = getSingleItemHistoryBarData(mergedHistoryData)
        history = history.map(v => { v.label = v.label.substr(0,3); return v })

        return (
            <div className="item-category-history-row gray-color">
                {
                    history.map((value,index) => {
                        return (
                            <div key={index} className={`item-category-history-col text-align-left`} style={{width: `calc(100%/${12.01/value.width})`}}>
                                <WordWrap>
                                    { value.label }
                                </WordWrap>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

SingleItemMonthLabels = connect(mapStateToProps)(SingleItemMonthLabels)

export default SingleItemMonthLabels