import React from 'react'
import { connect } from 'react-redux'
import { getItemCategoryHistoryGraphAll } from '../../actions/ItemActions'
import ProfitDataContent from './ProfitDataContent'
import { SingleKeyMap } from '../../helpers/Collections'

const mapStateToProps = (state, ownProps) => { return {} }

const defaultTotalRow = { use_columns: false, columns: [], label: "Grand total" }

class SingleItemProfitData extends React.Component {
    state = {
        showDecimals: false,
    }

    componentDidMount() {
        if (this.props.profit !== null) {
            const columns = []
            const sections = JSON.parse(this.props.profit.sections)
            const total_row = this.props.profit.total_row ? JSON.parse(this.props.profit.total_row) : defaultTotalRow
            sections.forEach(s => {
                s.rows.forEach(row => {
                    row.columns.forEach(column => {
                        columns.push(column)
                    })
                })
                if (s.total_row.use_columns) {
                    s.total_row.columns.forEach(tc => {
                        if (tc) {
                            columns.push(tc)
                        }
                    })
                }
            })
            const summaryColumns = JSON.parse(this.props.profit.summary_columns)
            summaryColumns.forEach(sc => {
                columns.push(sc.column)
            })
            if (total_row.use_columns) {
                total_row.columns.forEach(c => columns.push(c))
            }
            this.props.dispatch(getItemCategoryHistoryGraphAll(this.props.bucket.id, this.props.item, columns))
            this.props.setHasData() // TODO: call somewhere else maybe
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.profit !== null && !this.props.hasTotalData) {
            const columns = []
            const sections = JSON.parse(this.props.profit.sections)
            const total_row = this.props.profit.total_row ? JSON.parse(this.props.profit.total_row) : defaultTotalRow
            sections.forEach(s => {
                s.rows.forEach(row => {
                    row.columns.forEach(column => {
                        columns.push(column)
                    })
                })
                if (s.total_row.use_columns) {
                    s.total_row.columns.forEach(tc => {
                        if (tc) {
                            columns.push(tc)
                        }
                    })
                }
            })
            const summaryColumns = JSON.parse(this.props.profit.summary_columns)
            summaryColumns.forEach(sc => {
                columns.push(sc.column)
            })
            if (total_row.use_columns) {
                total_row.columns.forEach(c => columns.push(c))
            }
            this.props.dispatch(getItemCategoryHistoryGraphAll(this.props.bucket.id, this.props.item, columns))
            this.props.setHasData() // TODO: call somewhere else maybe
        }
    }

    render() {
        const data = this.props.historyData.get2(this.props.bucket.id, this.props.item, null)
        if (data === null) return null;

        let newData = new SingleKeyMap(data);
        newData.forEach((_key, value) => {
            if(value.data.length > 12){ //only 12 months data supported
                value.data = value.data.slice(-12)
            }
        })

        return (
            <ProfitDataContent 
                profit={this.props.profit}
                showDecimals={this.state.showDecimals}
                profitColumn={this.props.profitColumn}
                historyData={newData}
                bucket={this.props.bucket}
                selectedColumnIndex={this.props.selectedColumnIndex}
                setColumn={this.props.setColumn}
                setTotalIndex={this.props.setTotalIndex}
                useSummary={true}
                viewPercentage={this.props.viewPercentage}
                setPercentage={this.props.setViewPercentage}
            />
        )
    }
}

SingleItemProfitData = connect(mapStateToProps)(SingleItemProfitData)

export default SingleItemProfitData