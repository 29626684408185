import React from 'react'
import BaseDialog from './BaseDialog'

class SaveDialog extends BaseDialog {


	closeDialog = () => {
		if(this.props.closeHandler)
			this.props.closeHandler();
		this.hide(); //base class
	}

	saveDialog = () => {
		if(this.props.saveHandler)
			this.props.saveHandler();
		this.hide(); //base class
	}


	getButtons() {
		return (
			<div>
				<button onClick={this.closeDialog} type="button" className="btn btn-default margin-right-10px" >{ this.props.closeText ? this.props.closeText : 'Close' }</button>
				<button onClick={this.saveDialog} type="button" className="btn btn-primary" disabled={this.props.disableSave}>{ this.props.saveText ? this.props.saveText : 'Save' }</button>
			</div>
		)
	}
}

export default SaveDialog