import React from 'react'
import SaveDialog from './SaveDialog'
import SortableList from '../Generic/SortableList'
import ActiveCategoryIcon from '../Report/ReportComponents/ActiveCategoryIcon'
class DataColumnSelectionDialog extends React.Component {
    state = {
        includedColumns: [],
        missingColumns: [],
        columns: [],
        search: ''
    }

    /*
    Format of prevShownColumns and columns:
    [{name: "my col", type: "id", index: 0}, {name: "my other col", type: "decimal", index: 1}, ...]
    */
    show(prevIncludedColumns, datasetColumns) {
        this.refs.saveDialog.show()
        this.setState({
            includedColumns: prevIncludedColumns.slice(),
            missingColumns: datasetColumns.filter(c => prevIncludedColumns.findIndex(_c => _c.name === c.name) < 0),
            columns: datasetColumns
        })
    }

    toggleColumn(column, action) {
        let included = this.state.includedColumns
        let missing = this.state.missingColumns
        let index = -1

        if (action === "add") {
            included.push(column)
            index = missing.findIndex(c => c === column)
            missing.splice(index, 1)
        } else if (action === "remove") {
            index = included.findIndex(c => c === column)
            included.splice(index, 1)
            missing.push(column)
        }

        this.setState({ includedColumns: included, missingColumns: missing })
    }

    insertAt(oldIndex, newIndex) {
        let shown = this.state.shownColumns.slice()
        shown.splice(newIndex, 0, shown.splice(oldIndex, 1)[0])
        this.setState({ shownColumns: shown })
    }

    saveHandler = () => {
        this.props.saveHandler(this.state.includedColumns.slice())
    }

    render() {
        const { activeCategoryColumn } = this.props

        let getListItem = (column, action, actionText, actionIcon) => {
            return <>
            <div className="inline-block">
                {activeCategoryColumn === column.name ? <>
                    <div className='inline-block'>{column.name} &nbsp;</div>
                    <div className='inline-block pr-2'><ActiveCategoryIcon style={{ marginTop: -2 }} /></div>
                </> : <>{column.name} &nbsp;</>}
            </div>
            <div title={actionText} className={`fa checklist-column-icon abc-click float-right margin-right-5 ${actionIcon}`} onClick={() => this.toggleColumn(column, action)}></div>
            </>
        }

        let includedColumns = this.state.includedColumns
            .filter(c => this.state.search === '' || c.name.toLowerCase().includes(this.state.search.toLowerCase()))
            .map((c, _) => getListItem(c, "remove", "Remove", "fa-minus"))

        let missingColumns = this.state.missingColumns
            .filter(c => this.state.search === '' || c.name.toLowerCase().includes(this.state.search.toLowerCase()))
            .map((c, _) => getListItem(c, "add", "Add", "fa-plus"))


        return (
            <SaveDialog ref="saveDialog" title="Data Setup" closeHandler={this.props.closeHandler} saveHandler={this.saveHandler} large={true}>
                <React.Fragment>
                    <div className={'form-group'}>
                        <div className="input-group" id="searchBarContainerModal">
                            <input id="searchIdInput" className={`form-control form-control-sm zero-border-right`}
                                placeholder={`Search columns`} onChange={e => this.setState({ search: e.target.value })} value={this.state.search} />
                            <span className={`input-group-append`}>
                                <button title="Clear search filter" onClick={_ => this.setState({ search: "" })} className="btn btn-sm btn-default" type="button">
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <hr className='hr' />
                    <div className='flex-space-20'>
                        <div className='width-50-p'>
                            <h4><i className="margin-right-5 gray-color"></i> <b>Available columns</b></h4>
                            <SortableList items={missingColumns} disabled={true} containerClassName="sortable-list-container" />
                        </div>
                        <div className='width-50-p'>
                            <h4><i className="margin-right-5 gray-color"></i> <b>Included columns</b></h4>
                            <SortableList items={includedColumns} disabled={true} containerClassName="sortable-list-container" />
                        </div>
                    </div>
                </React.Fragment>
            </SaveDialog>
        )
    }
}

export default DataColumnSelectionDialog