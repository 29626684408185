import { MailBundleWithRelations, Plan } from '../types/reportingtypes'
import * as ActionTypes from '../actions/ActionTypes'

interface ReportingState {
    orgID: number;
    mailBundles: MailBundleWithRelations[]
    plans: Plan[]
}

// TODO: save plans to cloud database
// Hardcoding for now.
const defaultState:ReportingState = {
    orgID: -1,
    mailBundles: [],
    plans: []
}

interface IAction {
    type: string;
    payload: any;
    cb: (v:any) => any;
    errCb: (e:any) => any;
}

const Reporting = (state = defaultState, action:IAction) => {
    let index = -1;
    let mailBundles;
    
    switch(action.type){
        case ActionTypes.GET_ORG_MAILBUNDLES:
            return Object.assign({}, state, {orgID: action.payload.orgID})
        case ActionTypes.GET_ORG_MAILBUNDLES_DONE:
            state.mailBundles = action.payload
            return Object.assign({}, state, {mailBundles: action.payload})
        case ActionTypes.CREATE_MAILBUNDLE_DONE:
            const mailBundle = action.payload        
            return Object.assign({}, state, {
                mailBundles: [...state.mailBundles, mailBundle]
            })
        case ActionTypes.UPDATE_MAILBUNDLE_DONE:
            const updatedMailBundle = action.payload;
            mailBundles = [...state.mailBundles]
            index = state.mailBundles.findIndex(m => m.id === updatedMailBundle.id)
            mailBundles.splice(index, 1, updatedMailBundle)
            return Object.assign({}, state, {
                mailBundles: mailBundles
            })
        case ActionTypes.DELETE_MAILBUNDLE_DONE:
            const deletedId = action.payload.mailBundleId
            return Object.assign({}, state, {
                mailBundles: state.mailBundles.filter(m => m.id !== deletedId)
            })
        case ActionTypes.TOGGLE_MAILBUNDLE_ACTIVE_DONE:
            mailBundles = [...state.mailBundles]
            const updatedId:number = action.payload.mailBundleId
            const updatedActiveState:boolean = action.payload.active
            return Object.assign({}, state, {
                mailBundles: mailBundles.map(mb => mb.id === updatedId ? {...mb, active: updatedActiveState} : mb )
            })
        case ActionTypes.ACTIVATE_MULTIPLE_MAILBUNDLES_DONE:
            mailBundles = [...state.mailBundles]
            const activatedIds: number[] = action.payload.ids
            return Object.assign({}, state, {
                mailBundles: mailBundles.map(mb => activatedIds.includes(mb.id) ? {...mb, active: true} : mb)
            })
        case ActionTypes.DEACTIVATE_MULTIPLE_MAILBUNDLES_DONE:
            mailBundles = [...state.mailBundles]
            const deactivatedIds: number[] = action.payload.ids
            return Object.assign({}, state, {
                mailBundles: mailBundles.map(mb => deactivatedIds.includes(mb.id) ? {...mb, active: false} : mb)
            })
        case ActionTypes.BATCH_DELETE_MAILBUNDLES_DONE:
            mailBundles = [...state.mailBundles]
            const deletedIds: number[] = action.payload.ids
            return Object.assign({}, state, {
                mailBundles: mailBundles.filter(mb => !deletedIds.includes(mb.id))
            })
        case ActionTypes.GET_ALL_REPORTING_PLANS_DONE:
            return Object.assign({}, state, {
                plans: action.payload
            })
        default:
            return state;
    }
}

export default Reporting;