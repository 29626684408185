import { DashboardMap, MailBundleWithRelations, Plan, ReportMap, UserMap } from "../types/reportingtypes";
import { User } from "../types/transfertypes";
import { comparePermissionsToRoles, mapPermListToCheckboxTitles, mapPermListToPermChecks } from "./PermissionHelpers";
import { formatUnix } from "./TimeHelpers";
import { getLastLogin } from "./UserHelpers";


interface OrgUser extends User{
    location?: string
    job_title: string
    last_login: number
    bucket_access: string[] | undefined
    permissions: string[] | null
}

interface BucketInfo {
    id: string
    name: string
}

const getOrgUserHTML = (users:OrgUser[], includeLocation:boolean, includeAccessControl: boolean, orgBuckets: BucketInfo[]) => {
    const columns = [
        {name: "Name", key: "name"}, 
        {name: "Email", key: "email"}, 
        {name: "Job Title", key: "job_title"}, 
        {name: "Department", key: "department"},
        {name: "Language", key: "language"},
        {name: "Last Login", key: "last_login"},
        {name: "Last Login(date)", key: "last_login_date"},
        {name: "Roles", key: "roles"},
        {name: "Permissions", key: "permissions"}
    ];
    if(includeLocation){
        columns.splice(columns.map(c => c.key).indexOf("language"), 0, {name: "Location", key: "location"});
    }
    if(includeAccessControl){
        columns.splice(columns.map(c => c.key).indexOf("roles"), 0, {name: "Access Control", key: "access_control"})
    }
    const columnHTML = '<tr><th>'  + columns.map(c => c.name).join('</th><th>') + '</th></tr>'
    let rowHTML = '<tr><td>' + users.map(u => {
        return columns.map(c => {
            switch(c.key){
                case "name":
                    return u.firstname + " " + u.lastname
                case "last_login":
                    return getLastLogin(u)
                case "last_login_date":
                    return formatUnix(u.last_login)
                case "access_control":
                    return orgBuckets.filter(b => u.bucket_access?.includes(b.id)).map(b => b.name).join(", ") ?? "-"
                case "roles":
                    return Object.keys(comparePermissionsToRoles(mapPermListToPermChecks(u.permissions)))[0].replace("_", "")
                case "permissions":
                    return mapPermListToCheckboxTitles(u.permissions).filter(p => !!p).join(", ")
                default:
                    return u[c.key as keyof OrgUser] ?? "-"
            }
        }).join('</td><td>')
    }).join('</td></tr><tr><td>') + '</td></tr>' //Makes outer array to HTML

    const style = '<style>th { white-space: nowrap; } td {white-space: nowrap; }</style>'
    
    return style + '<table>' + columnHTML + rowHTML + '</table>'
}

const getUserRoleFormatted = (u:OrgUser) => {
    const role = Object.keys(comparePermissionsToRoles(mapPermListToPermChecks(u.permissions)))[0]
    
    const parts = role.split("_")
    return parts.reduce((prev, cur) => prev + " " + cur.substring(0, 1).toUpperCase() + cur.substring(1).toLowerCase(), "")
}

const getMailBundleStats = (items: MailBundleWithRelations[], plans: Plan[]) => {
    return {
        daily: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "D").length,
        weekly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "W").length,
        monthly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "M").length
    }
}

const getMailBundleHTML = (mailBundles: MailBundleWithRelations[], userMap:UserMap, dashboardMap:DashboardMap, reportMap: ReportMap, plans:Plan[], isSubscription: boolean) => {
    const columns = [
        {name: !isSubscription ? "Bundle Name" : "Subscription Name", key: "name"}, 
        {name: "# of Recipients", key: "recipientCount"},
        {name: "Recipients", key: "recipients"}, 
        {name: "# of Items", key: "itemCount"},
        {name: "Dashboards", key: "dashboards"},
        {name: "Insights", key: "insights"},
        {name: "Trend Views", key: "trend"},
        {name: "Matrix Views", key: "matrix"},
        {name: "Grid Views", key: "grid"},
        {name: "KPI Views", key: "kpi"},
        {name: "Sum Views", key: "sum"},
        {name: "Profit Views", key: "profit"},
        {name: "Plan", key: "plan"},
        {name: "Last sent", key: "last_sent"},
        {name: "Status", key: "active"},
        {name: "Include Link", key: "link"},
    ];
    const columnHTML = '<tr><th>'  + columns.map(c => c.name).join('</th><th>') + '</th></tr>'
    let rowHTML = '<tr><td>' + mailBundles.map(m => {
        return columns.map(c => {
            switch(c.key){
                case "name":
                    return m.name
                case "recipientCount":
                    return m.user_ids.length.toString;
                case "recipients":
                    return m.user_ids.map(id => userMap[id]).map(u => u.firstname + " " + u.lastname).join(", ")
                case "itemCount":
                    return (m.dashboards.length + m.report_ids.length + m.insight_views.length).toString()
                case "dashboards":
                    return m.dashboards.map(d => dashboardMap[d.dashboard_id]).map(d => d.title).join(", ")
                case "insights":
                    return m.report_ids.map(id => reportMap[id]).map(r => r.name).join(", ")
                case "trend":
                case "matrix": 
                case "grid":
                case "kpi": 
                case "sum":
                case "profit":
                    return m.insight_views
                        .filter(iv => iv.type === c.key)
                        .map(tv => reportMap[tv.report_id])
                        .map(r => r.name)
                        .join(", ")
                case "plan":
                    var plan = plans.find(x => x.id === m.plan_id);
                    if(plan !== undefined)
                        return plan.description.substring(4).replace("on the", "")
                    else
                        return "??"
                case "last_sent":
                    return m.last_sent !== 0 ? formatUnix(m.last_sent) : "-"
                case "active":
                    return m.active ? "Activated" : "Deactivated"
                case "link":
                    return m.link ? "Yes" : "No"
                default:
                    return m[c.key as keyof MailBundleWithRelations] ?? "-"
            }
        }).join('</td><td>')
    }).join('</td></tr><tr><td>') + '</td></tr>' //Makes outer array to HTML

    const style = '<style>th { white-space: nowrap; } td {white-space: nowrap; }</style>'
    
    return style + '<table>' + columnHTML + rowHTML + '</table>'
}

export {getOrgUserHTML, getUserRoleFormatted, getMailBundleStats, getMailBundleHTML}