import React from 'react'
import { connect } from 'react-redux'
import { savePendingUser } from '../actions/UserActions'
import {setPageTitle } from '../helpers/DocumentHelpers'


const mapStateToProps = (state, ownProps) => {
	return {
		userInfo: state.User.info
	}
}
class CreateUser extends React.Component {
    state = {
        firstname: '',
        lastname: '',
        email: '',
        department: 'Other',
        jobtitle: '',
        phonenumber: '',
        language: 'Danish',
    }

    componentDidMount() {
        setPageTitle("Create new user")
    }

    canCreate() {
        const { firstname, lastname, email, department, jobtitle, language, confirmCreate } = this.state
        return firstname !== '' &&
            lastname !== '' &&
            email !== '' &&
            department !== '' &&
            jobtitle !== '' &&
            language !== '' &&
            confirmCreate
    }

    create() {
        const { firstname, lastname, email, phonenumber, jobtitle, department, language } = this.state
        const { dispatch } = this.props
        const createdBy = this.props.userInfo.email
        dispatch(savePendingUser(firstname, lastname, email, phonenumber, jobtitle, department, language, createdBy))
        this.props.history.push("/")
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>

                    <div className="col-md-8">

                        <h1>Request new user</h1>
                        <hr className="margin-left-right-20" />
                            <form>
                                <div className="form-group">
                                    <label>First name*</label>
                                    <input value={this.state.firstname} onChange={e => this.setState({firstname: e.target.value})} className="form-control" type="text" />
                                </div>
                                <div className="form-group">
                                    <label>Last name*</label>
                                    <input value={this.state.lastname} onChange={e => this.setState({lastname: e.target.value})} className="form-control" type="text" />
                                </div>
                                <div className="form-group">
                                    <label>E-mail*</label>
                                    <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} className="form-control" type="email" />
                                </div>
                                <div className="form-group">
                                    <label>Phone number</label>
                                    <input value={this.state.phonenumber} onChange={e => this.setState({phonenumber: e.target.value})} className="form-control" type="text" />
                                </div>
                                <div className="form-group">
                                    <label>Job title*</label>
                                    <input value={this.state.jobtitle} onChange={e => this.setState({jobtitle: e.target.value})} className="form-control" type="text" />
                                </div>
                                <div className="form-group">
                                    <label>Department*</label>
                                    <select className="form-control"  value={this.state.department} onChange={e => this.setState({department: e.target.value})}>
                                        <option>Procurement</option>
                                        <option>Logistics/Supply Chain Management</option>
                                        <option>Production</option>
                                        <option>Category Management</option>
                                        <option>Sourcing</option>
                                        <option>Sales</option>
                                        <option>Finance</option>
                                        <option>IT</option>
                                        <option>Management</option>
                                        <option>Other</option>
                                        <option>Marketing</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Preferred language*</label>
                                    <select className="form-control"  value={this.state.language} onChange={e => this.setState({language: e.target.value})}>
                                        <option>Danish</option>
                                        <option>English</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input type="checkbox" id="acceptCreateUserCheck" defaultValue={this.state.confirmCreate} onChange={e => this.setState({confirmCreate: e.target.checked})} style={{'marginRight': '5px'}} />
                                    <label className="form-check-label" htmlFor="acceptCreateUserCheck"><strong>Confirm adding an extra user to your subscription.</strong> For more information, contact <a href="mailto:support@inact.io">support@inact.io</a></label>
                                </div>

                                <button disabled={!this.canCreate()} className="btn btn-primary" onClick={() => this.create()}>Add new user</button>
                            </form>

                    </div>

                    <div className="col-md-2"></div>
                </div>

            </div>
        )
    }
}

CreateUser = connect(mapStateToProps)(CreateUser)

export default CreateUser
