import { ProfitabilitySection } from "../types/transfertypes"
import { GraphDataProps, GraphDataset, historyData, kpi, kpiContainer, TotalSection } from "../types/types"
import { SingleKeyMap, TripleKeyMap } from "./Collections"
import { getProfitHistoryData } from "./ItemHelpers"

export const getGraphData = (props: GraphDataProps): GraphDataset[] => {
    let { kpi1, kpi2, showTotal, historydata, kpis, selectedCategory, graphOptions } = props
    let graphDatasets: GraphDataset[] = []
    let bothKpi = kpi1 !== null && kpi2 !== null ? true : false
    const data: number[][] = historydata ? historydata : []

    let bodyCss = getComputedStyle(document.body);
    let kpi1Color = bodyCss.getPropertyValue("--kpi1GraphColor");
    let kpi1ColorHover = bodyCss.getPropertyValue("--kpi1GraphColorHover");
    
    let kpi2Color = bodyCss.getPropertyValue("--kpi2GraphColor")
    let kpi2ColorHover = bodyCss.getPropertyValue("--kpi2GraphColorHover")

    
    if(graphOptions && graphOptions.zoom > 12 && kpi1 !== null){

        let newData: number[][] = []

        for(let i = 0; i < historydata.length; i++){
            //first:  latest 12 months
            let first = historydata[i].slice(-12)
            //second: one year ago: 12 months 
            let second = historydata[i].slice(historydata[i].length - 24, -12)
        
            let third: number[] = []
            if (graphOptions.zoom > 24){
                //third : 2 years ago: 12 months
                third = historydata[i].slice(0, 12)
            }

            newData[i] = []
            for( let j = 0; j < first.length; j++){
                newData[i].push(NaN) //adding a fictive dataset
                if(graphOptions.zoom > 24)
                    newData[i].push(third[j])

                newData[i].push(second[j])
                newData[i].push(first[j])
            }
            
        }

        let kpi1ExtraColor1 = bodyCss.getPropertyValue("--kpi1GraphExtra1Color");
        let kpi1ExtraColor2 = bodyCss.getPropertyValue("--kpi1GraphExtra2Color");
        const data = getData(kpi1, newData, kpis, selectedCategory, graphOptions.zoom + 12)

        let colorScheme = graphOptions.zoom > 24 ? ["white", kpi1ExtraColor2, kpi1ExtraColor1, kpi1Color] : ["white", kpi1ExtraColor1, kpi1Color]
        let hovercolorScheme = graphOptions.zoom > 24 ? ["white", kpi1ColorHover, kpi1ColorHover, kpi1ColorHover] : ["white", kpi1ColorHover, kpi1ColorHover]

        let colors:string[] = []
        let hovercolors:string[] = []
        for(let i = 0; i < 12; i++){
            colors = colors.concat(colorScheme)
            hovercolors = hovercolors.concat(hovercolorScheme)
        }
        const item2 = getMultipleDataVisuals(data, colors, hovercolors, kpi1Color)
        graphDatasets.push(item2)

        return graphDatasets;
    }


    let width = kpi1 !== null && kpi1.settings.type === "bar" && kpi2 !== null && kpi2.settings.type === "bar" ? 0.95: 0.60

    if (kpi1 !== null) {
        const isBar = kpi1.settings.type === "bar" ? true : false
        if (selectedCategory) {
            const bgColor = isBar ? "white" : "transparent"
            const slicedata = getData(kpi1, data, kpis, selectedCategory, graphOptions.zoom)
            const bw = isBar ? 0 : 1
            const yAxID = bothKpi && graphOptions.axisNum === 2 ? 'Kpi1' : ''
            const item = getDataVisuals("Slice1", kpi1.settings.type, slicedata, kpi1Color, bw, bgColor,kpi1ColorHover,  yAxID, width)
            graphDatasets.push(item)
        }

        if (showTotal) {
            const bgColor = isBar ? kpi1Color : "transparent"
            const totaldata = getData(kpi1, data, kpis, "", graphOptions.zoom)
            const bw = isBar ? 0 : 2.5
            const yAxID = bothKpi && graphOptions.axisNum === 2 ? 'Kpi1' : ''
            const item = getDataVisuals("Total1", kpi1.settings.type, totaldata, kpi1Color, bw, bgColor,kpi1ColorHover, yAxID, width)
            graphDatasets.push(item)
        }
    }

    if (kpi2 !== null) {
        const isBar = kpi2.settings.type === "bar" ? true : false

        if (selectedCategory) {
            const bgColor = kpi2.settings.type === "bar" ? "white" : "transparent"
            const slice = getData(kpi2, data, kpis, selectedCategory, graphOptions.zoom)
            const bw = isBar ? 0 : 1
            const yAxID = bothKpi && graphOptions.axisNum === 2 ? 'Kpi2' : ''
            const item = getDataVisuals("Slice2", kpi2.settings.type, slice, kpi2Color, bw, bgColor, kpi2ColorHover, yAxID, width)
            graphDatasets.push(item)
        }

        if (showTotal) {
            const bgColor = kpi2.settings.type === "bar" ? kpi2Color : "transparent"
            const totals = getData(kpi2, data, kpis, "", graphOptions.zoom)
            const bw = isBar ? 0 : 2.5
            const yAxID = bothKpi && graphOptions.axisNum === 2 ? 'Kpi2' : ''
            const item = getDataVisuals("Total2", kpi2.settings.type, totals, kpi2Color, bw, bgColor, kpi2ColorHover, yAxID, width)
            graphDatasets.push(item)
        }
    }

    // if order for the line graph to be in front of the bar graph we have to make sure the line graph is first in graphDatasets
    if (selectedCategory && graphDatasets.length === 4) {
        if (graphDatasets[0].type === "bar" && graphDatasets[2].type === "line") { [graphDatasets[0], graphDatasets[2]] = [graphDatasets[2], graphDatasets[0]];[graphDatasets[1], graphDatasets[3]] = [graphDatasets[3], graphDatasets[1]]; graphDatasets[0].haveChanged = true }
    } else {
        if (graphDatasets.length === 2 && (graphDatasets[0].type === "bar" && graphDatasets[1].type === "line")) { [graphDatasets[0], graphDatasets[1]] = [graphDatasets[1], graphDatasets[0]]; graphDatasets[0].haveChanged = true }
    }
    return graphDatasets
}

export const getGraphOptions = (props: GraphDataProps, data: GraphDataset[], animate: boolean, labels: string[]) => {
    let { kpi1, kpi2, kpis, lastAdded, showTotal, selectedCategory, graphOptions } = props
    // const zoom = graphOptions ? graphOptions.zoom : 12
    const amount = data.length
    let startAtZero = graphOptions ? graphOptions.startAtZero : true
    const axisNum = graphOptions ? graphOptions.axisNum : 2

    let bothKpi = kpi1 !== null && kpi2 !== null ? true : false
    let [kpi1Index, kpi2Index] = [-1, -1] // FIXME: Might break this func, watch out

    let bodyCss = getComputedStyle(document.body);
    let kpi1Color = bodyCss.getPropertyValue("--kpi1GraphColor");
    
    let kpi2Color = bodyCss.getPropertyValue("--kpi2GraphColor")


    let [kpiColor1, kpiColor2] = [kpi1Color, kpi2Color]
    if (kpi1 === null && kpi2 !== null) [kpiColor1, kpiColor2] = [kpiColor2, kpiColor1]
    if (data[0] && data[0].haveChanged) [kpiColor1, kpiColor2] = [kpiColor2, kpiColor1] // if we swapped items in graphDatasets above, we also have to swap colors here

    if (kpi1 !== null) kpi1Index = getKpiIndex(kpi1, kpis)
    if (kpi2 !== null) kpi2Index = getKpiIndex(kpi2, kpis)

    let [id1, id2] = ["Kpi1", "Kpi2"]
    // if the last added was kpi1 we need to swap the id's so kpi2 (the green one) stays on the left. Otherwise the graph will jump around with the yAxes
    if (lastAdded === 1) {
        [id1, id2] = [id2, id1]
    }
    // we also need to swap the kpiIndexes
    if (lastAdded === 1 && bothKpi) {
        [kpi1Index, kpi2Index] = [kpi2Index, kpi1Index]
    }

    let yAxesOptions = []
    if (bothKpi) {
        let index1 = id1 === "Kpi1" ? 0 : 1
        let index2 = id2 === "Kpi2" ? 1 : 0
        // because of the 'unshift' behaviour in line 29 and 72  (which we have to do due to some chartjs bullshit in order for the lines to show correctly when 4 graphs are displayed) we need to swap indexes here
        if (selectedCategory && selectedCategory !== "" && showTotal) [index1, index2] = [index2, index1]
        else if (data[0] && data[0].haveChanged) [index1, index2] = [index2, index1]
        const tmpData1 = data[index1] && data[index1].data ? data[index1].data : []
        const tmpData2 = data[index2] && data[index2].data ? data[index2].data : []
        const sliceActive = data.length === 4 ? true : false

        // const minMaxVals1 = getMaxAndMin(tmpData1, zoom, vertical)
        // const minMaxVals2 = getMaxAndMin(tmpData2, zoom, vertical)
        const tickSettings1 = findBiggestDiff(tmpData1, amount, sliceActive, startAtZero)
        const tickSettings2 = findBiggestDiff(tmpData2, amount, sliceActive, startAtZero)

        // if (Math.min.apply(null, filterArray(tmpData1)) < 0 || Math.min.apply(null, filterArray(tmpData2)) < 0) startAtZero = false // cannot enable startAtZero if any min value is below 0
        if (axisNum === 1) {
            yAxesOptions.push({
                // maybe someday we run into the problem where two kpis are so close together in data AND the user only wants 1 xAxis -> then we would need to compare tickSettings1 max/min with tickSettings2 max/min and figure out wether we need to use min, max & stepsize
                ticks: {
                    // max: tickSettings1.max, 
                    // min: tickSettings1.min,
                    // stepSize: tickSettings1.stepSize,
                    beginAtZero: startAtZero,
                    // suggestedMax: minMaxVals1.suggestedMax,
                    // suggestedMin: minMaxVals1.suggestedMin,
                    callback: function (value: any) {
                        return formatValue(true, value, (kpis[kpi1Index] && kpis[kpi1Index].showKPIDecimals) ?? false)
                    }
                }
            })
        } else {
            yAxesOptions = [{
                id: id1,
                position: 'left',
                ticks: {
                    max: tickSettings1.max,
                    min: tickSettings1.min,
                    stepSize: tickSettings1.stepSize,
                    beginAtZero: startAtZero,
                    // suggestedMax: minMaxVals1.suggestedMax,
                    // suggestedMin: minMaxVals1.suggestedMin,
                    callback: function (value: any) {
                        return formatValue(true, value, (kpis[kpi1Index] && kpis[kpi1Index].showKPIDecimals) ?? false)
                    }
                }
            },
            {
                id: id2,
                position: 'right',
                gridLines: {
                    display: false
                },
                ticks: {
                    max: tickSettings2.max,
                    min: tickSettings2.min,
                    stepSize: tickSettings2.stepSize,
                    beginAtZero: startAtZero,
                    // suggestedMax: minMaxVals2.suggestedMax,
                    // suggestedMin: minMaxVals2.suggestedMin,
                    callback: function (value: any) {
                        return formatValue(true, value, kpi2Index !== -1 ? (kpis[kpi2Index] && kpis[kpi2Index].showKPIDecimals) ?? false : false)
                    }
                    // maxTicksLimit: 3 -> kan bruges til at minimere hvor mange ticks der bliver tegnet			
                }
            }]
        }
    } else {
        const tmpData = data[0] && data[0].data ? data[0].data : []
        const sliceActive = data.length === 2 ? true : false
        // let minMaxVals = getMaxAndMin(tmpData, zoom, vertical)
        let tickSettings = findBiggestDiff(tmpData, amount, sliceActive, startAtZero)

        yAxesOptions = [{
            ticks: {
                max: tickSettings.max,
                min: tickSettings.min,
                stepSize: tickSettings.stepSize,
                beginAtZero: startAtZero,
                // suggestedMax: minMaxVals.suggestedMax,
                // suggestedMin: minMaxVals.suggestedMin,
                callback: function (value: any) {
                    return formatValue(true, value, kpi1 !== null && kpi1Index !== -1 ? (kpis[kpi1Index] && kpis[kpi1Index].showKPIDecimals) ?? false : kpi2 !== null && kpi2Index !== -1 ? (kpis[kpi2Index] && kpis[kpi2Index].showKPIDecimals) ?? false : false)
                },
            }
        }]
    }
    // if (axisNum === 1 && bothKpi) yAxesOptions.pop()
    // console.log(yAxesOptions)
    // if (axisNum === 1 && startAtZero && !yAxesOptions[0].ticks.min) yAxesOptions[0].ticks.min = 0

    let xAxesOptions =  [{ 
        gridLines: {
            offsetGridLines: false,
            display: true
        },
        ticks: {
            callback: function (value: any, index: number, ticks:any) {
                return ""
            }
        }
        // maxBarThickness: 125,
    }]

    if(graphOptions && graphOptions.zoom > 12) {
        xAxesOptions = [{
            gridLines: {
                offsetGridLines: false,
                display: false,
                drawOnChartArea: false,
            },
            ticks: {
                padding: -5,
                callback: function (value: any) {
                    return value.length > 2 ? value.slice(-3).trim() : ""
                }
            }
        }]
    }

    return {
        spanGaps: true,
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: (_: any, legendItem: any) => {
                if (legendItem.length === 1) {
                    const baseVal = graphOptions.zoom === 6 ? 6 : graphOptions.zoom === 3 ? 9 : 0
                    props.setHighlightedPeriod(baseVal + legendItem[0]._index)
                    return
                } else {
                    props.setHighlightedPeriod(-1)
                    return
                }

            }
        },
        animation: animate ? {
            duration: 1000,
            easing: "easeOutQuart"
        } : false,
        legend: {
            display: false,
        },
        scales: {
            yAxes: yAxesOptions,
            xAxes: xAxesOptions
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    return formatValue(true, value, value < 100)
                },
                labelColor: (tooltipItem: any) => {
                    if (amount < 3 && !bothKpi) {
                        return {
                            backgroundColor: kpiColor1,
                            borderColor: 'transparent',
                            borderWidth: 0
                        }
                    } else if (amount < 3 && bothKpi) {
                        if (showTotal) {
                            if (tooltipItem.datasetIndex === 0) {
                                return {
                                    backgroundColor: kpiColor1,
                                    borderColor: 'transparent',
                                    borderWidth: 0
                                }
                            } else if (tooltipItem.datasetIndex === 1) {
                                return {
                                    backgroundColor: kpiColor2,
                                    borderColor: 'transparent',
                                    borderWidth: 0
                                }
                            }
                        } else {
                            if (tooltipItem.datasetIndex === 0) {
                                return {
                                    backgroundColor: kpiColor1,
                                    borderColor: 'transparent',
                                    borderWidth: 0
                                }
                            } else if (tooltipItem.datasetIndex === 1) {
                                return {
                                    backgroundColor: kpiColor2,
                                    borderColor: 'transparent',
                                    borderWidth: 0
                                }
                            }
                        }
                    } else if (amount > 2) {
                        if (tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 1) {
                            return {
                                backgroundColor: kpiColor1,
                                borderColor: 'transparent',
                                borderWidth: 0
                            }
                        } else if (tooltipItem.datasetIndex === 2 || tooltipItem.datasetIndex === 3) {
                            return {
                                backgroundColor: kpiColor2,
                                borderColor: 'transparent',
                                borderWidth: 0
                            }
                        }
                    }
                }
            }
        }

    }

}

export const getGraphLabelsItem =(data: any, isProfit:boolean, zoom: number) => {
    let labels: string[] = []
    if (data) {
        data.forEach((item: any) => {
            labels.push(item.label);
        })
    }

    if (data && data.length > zoom) {
        labels = labels.slice(data.length - zoom, data.length)
    }

    if(zoom > 12 && !isProfit){
        let newLabels: string[] = []

        let first = labels.slice(-12)
        //second: one year ago: 12 months 
        let second = labels.slice(labels.length - 24, -12)

        let third: string[] = []
        if (zoom > 24){
            //third : 2 years ago: 12 months
            third = labels.slice(0, 12)
        }

        for( let j = 0; j < first.length; j++){
            newLabels.push("") //adding a fictive label to fictive dataset
            if(zoom > 24){
                newLabels.push(third[j])
            }

            newLabels.push(second[j])
            newLabels.push(first[j])
        }
        labels = newLabels
    }

    return labels
}

export const getGraphLabels = (data: any, gOtp: any) => {
    let labels: string[] = []
    const numOfMonths = gOtp ? gOtp.zoom : 12
    if (data) {
        data.forEach((item: any) => {
            labels.push(item.label);
        })
    }

    if (data && data.length > numOfMonths) {
        labels = labels.slice(data.length - numOfMonths, data.length)
    }

    if(numOfMonths > 12){
        let newLabels: string[] = []

        let first = labels.slice(-12)
        //second: one year ago: 12 months 
        let second = labels.slice(labels.length - 24, -12)

        let third: string[] = []
        if (numOfMonths > 24){
            //third : 2 years ago: 12 months
            third = labels.slice(0, 12)
        }

        for( let j = 0; j < first.length; j++){
            newLabels.push("") //adding a fictive label to fictive dataset
            if(numOfMonths > 24){
                newLabels.push(third[j])
            }

            newLabels.push(second[j])
            newLabels.push(first[j])
        }
        labels = newLabels
    } 

    return labels
}

export const getData = (kpi: kpiContainer, data: number[][], kpis: kpi[], selCategory: string, numOfMonths: number) => {
    let totalData: number[] = []
    let kpiIndex = getKpiIndex(kpi, kpis)

    if (!data[kpiIndex]) {
        return totalData
    }

    for (let i = 0; i < data[kpiIndex].length; i++) {
        totalData.push(getSum(kpiIndex, i, data, kpis, selCategory))
    }

    if (data[kpiIndex].length > numOfMonths) {
        totalData = totalData.slice(data[kpiIndex].length - numOfMonths, data[kpiIndex].length)
    }

    return totalData
}

const getSum = (kpiIndex: number, period: number, data: any, kpis: kpi[], selCategory: string) => {
    let sum = NaN
    if (data.length > 0 && kpiIndex !== -1 && kpiIndex < data.length) {
        if (data[kpiIndex][period] && data[kpiIndex][period].found) {
            if (selCategory && selCategory !== "") {
                if (data[kpiIndex][period].aggregation.segments[selCategory]) {
                    sum = data[kpiIndex][period].aggregation.segments[selCategory][kpis[kpiIndex].aggregation]
                } else {
                    sum = 0
                }
            } else {
                sum = data[kpiIndex][period].aggregation.total[kpis[kpiIndex].aggregation]
            }
        }
    }
    return sum
}

export const formatValue = (isNumber: boolean, value: any, showDecimals: boolean) => {
    let decimalCount = showDecimals ? 2 : 0
    if (isNumber && null != value) {
        let x = parseFloat(value)
        if (isNaN(x)) {
            return '?'
        }
        return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount });
    }
    return value
}

export const getKpiIndex = (kpi: kpiContainer, kpis: kpi[]) => {
    let kpiIndex = -1
    for (let i = 0; i < kpis.length; i++) {
        if (kpis[i].column === kpi.kpi.column && kpis[i].aggregation === kpi.kpi.aggregation) {
            kpiIndex = i
        }
    }
    return kpiIndex
}

export const getItemGraphData = (history: any, type: string, subtract: boolean, selectedColumnIndex: number, isProfit: boolean, zoom: number) => {
    let graphDatasets = []
    let data = getSingleItemData(history, subtract)
    if(data.length > zoom){
        data = data.slice(data.length - zoom, data.length)
    }

    const isBar = type === "bar" || zoom > 12 ? true : false
    const lastVal = findLastVal(data)

    let bodyCss = getComputedStyle(document.body);
    let color = bodyCss.getPropertyValue("--kpi1GraphColor");

    let positiveColor = "green"
    let negativeColor = "red"

    if(zoom > 12 && !isProfit){
        //first:  latest 12 months
        let first = data.slice(-12)
        //second: one year ago: 12 months 
        let second = data.slice(data.length - 24, -12)
    
        let third: number[] = []
        if (zoom > 24){
            //third : 2 years ago: 12 months
            third = data.slice(0, 12)
        }

        let newData:number[]  = []
        for( let j = 0; j < first.length; j++){
            newData.push(NaN) //adding a fictive dataset
            if(zoom > 24)
                newData.push(third[j])

            newData.push(second[j])
            newData.push(first[j])
        }

        let kpi1ColorHover = bodyCss.getPropertyValue("--kpi1GraphColorHover");
    
        let kpi1ExtraColor1 = bodyCss.getPropertyValue("--kpi1GraphExtra1Color");
        let kpi1ExtraColor2 = bodyCss.getPropertyValue("--kpi1GraphExtra2Color");

        let colorScheme = zoom > 24 ? ["white", kpi1ExtraColor2, kpi1ExtraColor1, color] : ["white", kpi1ExtraColor1, color]
        let hovercolorScheme = zoom > 24 ? ["white", kpi1ColorHover, kpi1ColorHover, kpi1ColorHover] : ["white", kpi1ColorHover, kpi1ColorHover]

        let colors:string[] = []
        let hovercolors:string[] = []
        for(let i = 0; i < 12; i++){
            colors = colors.concat(colorScheme)
            hovercolors = hovercolors.concat(hovercolorScheme)
        }
        const item2 = getMultipleDataVisuals(newData, colors, hovercolors, color)
        
        graphDatasets.push(item2)
        graphDatasets.push({
            label: 'Total',
            type: "bar",
            data: newData,
            lineTension: 0,
            borderColor: 'transparent',
            borderWidth: 2.5,
            backgroundColor: 'transparent',
            pointRadius: 0,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            pointHitRadius: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: 'transparent',
            barPercentage: 0.70,
            categoryPercentage: 0.90,
        });
        return graphDatasets;
    }

   
    

    for (let i = 0; i < 2; i++) {
        if (i === 0) {
            graphDatasets.push({
                label: 'Total',
                type: type,
                data: data,
                lineTension: 0,
                borderColor: !isBar && isProfit && lastVal >= 0 ? positiveColor : !isBar && isProfit && lastVal < 0 ? negativeColor : color,
                borderWidth: isBar ? 0 : 2.5,
                backgroundColor: getBorderColor(isBar, data, selectedColumnIndex, isProfit),
                hoverBackgroundColor: getHoverColor(isBar, data, isProfit),
                pointRadius: 5,
                pointBackgroundColor: 'rgb(255, 255, 255)',
                pointBorderColor: 'rgb(0, 0, 0)',
                pointHitRadius: 5,
                pointHoverRadius: 6.5,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                barPercentage: 0.70,
                categoryPercentage: 0.90,
            });
        } else if (i === 1) { // we need this for values on both sides of the chart
            graphDatasets.push({
                label: 'Total',
                type: type,
                data: data,
                lineTension: 0,
                borderColor: 'transparent',
                borderWidth: 2.5,
                backgroundColor: 'transparent',
                pointRadius: 0,
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointHitRadius: 0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'transparent',
                barPercentage: 0.70,
                categoryPercentage: 0.90,
            });
        }
    }

    graphDatasets.forEach(function (item: any, index) {
        if (index === 0) {
            item.yAxisID = 'leftValues';
        } 
    })

    return graphDatasets
}

export const getSingleItemData = (history: historyData[], subtract: boolean) => {
    let data: number[] = []
    history.forEach(function (item) {
        if (item.found) {
            if (subtract) data.push(-Math.abs(item.value))
            else data.push(item.value);
        } else {
            data.push(NaN) // same as pushing 0 as data but it wont show up as data point on chartjs
        }
    });
    return data
}

export const getItemGraphOptions = (history: historyData[], startAtZero: boolean, type: string, subtract: boolean, isProfit: boolean, setIndex: Function, columnIndex: number, animate: boolean, labels: string[]) => {
    const data = getSingleItemData(history, subtract)
    let tickSettings = findBiggestDiff(data, 1, false, startAtZero)

    let xAxes = isProfit ? [{
        gridLines: {
            offsetGridLines: false
        },
        stacked: true, // we're actually rendering two bars (because of yAxis ticks on both sides), but this makes them stacked and shown as one
        ticks: { // maybe not needed
            callback: (value: any, index: number) => {
                if (index === columnIndex) return `<${value.slice(0, -3)}>`;
                return value.slice(0, -3)
            }
        }
    }] : [{
        gridLines: {
            offsetGridLines: false
        },
        stacked: true,
        ticks: {
            callback: (value: any) => {
                return value.slice(0,-3)
            }
        }
    }]

    if(!isProfit && labels.length > 12){

        let startIndex = 1
        let step = labels.length / 12
        let monthLabels: string[] = []
        for(let i = startIndex; i < labels.length; i+= step){
            monthLabels.push("")
            monthLabels.push(labels[i].slice(0, -3))
        }
        monthLabels.push("")

        xAxes = [ {
             gridLines: {
                offsetGridLines: false,
                display: false,
                drawOnChartArea: false,
            },
            stacked: true,
            ticks: {
                padding: -5,
                callback: function (value: any) {
                    return value.length > 2 ? value.slice(-3).trim() : ""
                }
            }
        },
        {
            type: 'category',
            labels: monthLabels,
            stacked: true,
            gridLines: {
                offsetGridLines: true,
                display: false,
                drawOnChartArea: false,
            },
            
            ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                padding: -10,
                callback: function (value: any) {
                    return  value;
                }
            }
        }]



    }

    return {
        mainmaintainAspectRatio: false,
        spanGaps: true,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        animation: animate ? {
            duration: 1000,
            easing: "easeOutQuart"
        } : false,
        responsiveAnimationDuration: 0,
        legend: {
            display: false,
        },
        scales: {
            yAxes: [{
                id: 'leftValues',
                position: 'left',
                ticks: {
                    max: tickSettings.max,
                    min: tickSettings.min,
                    beginAtZero: startAtZero,
                    stepSize: tickSettings.stepSize,
                    callback: function (value: any) {
                        return formatValue(true, value, (tickSettings.max - tickSettings.min < 10 ) ? true : false);
                    }
                },
            }],
            xAxes: xAxes
        },
        tooltips: {
            displayColors: false,
            mode: 'single',
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return formatValue(true, value, Math.abs(value) < 100);
                },
                title: (tooltipItem: any) => {
                    // if (isProfit && tooltipItem[0] && tooltipItem[0].index === columnIndex) return tooltipItem[0].xLabel.substring(1, tooltipItem[0].xLabel.length-1); // WHY?????
                    return tooltipItem[0].xLabel
                }
            }
        },
        events: ['mousemove', 'click'],
        onHover: (event: any, chartElement: any) => {
            if (isProfit) {
                event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            }
        },
        onClick: (_: any, chartElement: any) => {
            if (chartElement[0] && isProfit) {
                setIndex(chartElement[0]._index)
            }
        }
    }
}

// func to avoid duplicate yAxis ticks
export const findBiggestDiff = (data: number[], amount: number, sliceActive: boolean, startAtZero: boolean) => {
    let res: any = {
        stepSize: null,
        max: undefined,
        min: undefined,
    }

    if ((amount && amount > 2) || sliceActive) return res

    const arr = filterArray(data)

    const minValue = Math.min.apply(null, arr)
    const maxValue = Math.max.apply(null, arr)
    
    const val = maxValue - minValue

    const actualMin = startAtZero && minValue > 0 ? 0 : (minValue-1);

    if (val < 10) {
        res.stepSize = Math.round((maxValue - actualMin) / 5);
        res.max = Math.round(maxValue + 1);
        res.min = Math.round(actualMin);
    }
    return res
}

const getDataVisuals = (label: string, type: string, data: number[], borderColor: string, borderWidth: number, backgroundColor: string, hoverBackgroundColor:string, yAxID: string, barWidth:number): GraphDataset => {
    return {
        label: label,
        type: type,
        data: data,
        lineTension: 0,
        borderColor: borderColor,
        borderWidth: borderWidth,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        pointRadius: 5,
        pointBackgroundColor: 'rgb(255, 255, 255)',
        pointBorderColor: 'rgb(0, 0, 0)',
        pointHitRadius: 5,
        pointHoverRadius: 6.5,
        pointHoverBackgroundColor: 'rgb(255, 255, 255)',
        yAxisID: yAxID,
        barPercentage: barWidth,
        categoryPercentage: 0.90,
        // allData: data.allData, // this is not a prop for chartjs. But we need it for getGraphOptions()
    }
}

const getMultipleDataVisuals = (data: number[], colors: string[], hovercolors: string[], borderColor: string): GraphDataset => {
    return {
        label: "noname",
        type: "bar",
        data: data,
        lineTension: 0,
        borderColor: borderColor,
        borderWidth: 1,
        backgroundColor: colors,
        hoverBackgroundColor: hovercolors,
        pointRadius: 5,
        pointBackgroundColor: 'rgb(255, 255, 255)',
        pointBorderColor: 'rgb(0, 0, 0)',
        pointHitRadius: 5,
        pointHoverRadius: 6.5,
        pointHoverBackgroundColor: 'rgb(255, 255, 255)',
        yAxisID: '',
        barPercentage: 1,
        categoryPercentage: 0.95,
    }
}

// const getMaxAndMin = (data, zoom, vertical) => {
//     let res = {
//         suggestedMax: undefined,
//         suggestedMin: undefined,
//     }
//     if (zoom === 12 || vertical) return res

//     const zoomData = filterArray(data.data)
//     const allData = filterArray(data.allData)

//     const zoomMax = Math.max.apply(null, zoomData)
//     const zoomMin = Math.min.apply(null, zoomData)

//     const allMax = Math.max.apply(null, allData)
//     const allMin = Math.min.apply(null, allData)
//     if (allMax > zoomMax) res.suggestedMax = allMax
//     if (allMin < zoomMin) res.suggestedMin = allMin

//     return res
// }

export const filterArray = (arr: number[]) => {
    let res: number[] = []

    arr.forEach((item) => {
        if (!isNaN(item)) res.push(item)
    })

    return res
}

// currently unused function ?
export const getHistoryData = (profit: any, totalSection: TotalSection, historyData: TripleKeyMap, bucketId: string, item: any) => {
    const sections = JSON.parse(profit.sections)

    let totalHistoryArr: any[] = []
    for (let i = 0; i < totalSection.y + 1; i++) {
        const section = sections[i];
        if (section.total_row.use_columns) {
            totalHistoryArr.push({
                subtract: false,
                item: historyData.get(bucketId, item, section.total_row.columns[totalSection.x])
            })
        } else {
            section.rows.forEach((row: any) => {
                totalHistoryArr.push({
                    subtract: row.subtract,
                    item: historyData.get(bucketId, item, row.columns[totalSection.x])
                })
            })
        }
    }

    let totalData = []
    for (let i = 0; i < 12; i++) {
        let value = 0
        totalHistoryArr.forEach(point => {
            let tmpVal = point.item && point.item.data[i] ? point.item.data[i].value : 0
            if (point.subtract) {
                value += -Math.abs(tmpVal)
            } else {
                value += tmpVal
            }
        })
        totalData.push(value)
    }

    let dataArr: any[] = []
    totalData.forEach((val, i) => {
        dataArr.push({
            label: totalHistoryArr[0].item ? totalHistoryArr[0].item.data[i].label : "NA",
            value: val,
            found: val !== 0
        })
    })
    // dataArr[dataArr.length - 1].value = Math.abs(dataArr[dataArr.length - 1].value)

    return dataArr
}

export const getHistoryDataIndependent = (sections: ProfitabilitySection[], totalRow: { use_columns: boolean }, totalSection: TotalSection, historyData: SingleKeyMap, last: boolean, fromReport: boolean, dataLength: number) => {
    let totalHistoryArr = []

    if (last) {
        for (let i = 0; i < totalSection.y; i++) {
            const section = sections[i];
            if (section.total_row.use_columns) {
                totalHistoryArr.push({
                    subtract: false,
                    item: getProfitHistoryData(historyData, section.total_row.columns[totalSection.x], fromReport)
                })
            } else {
                section.rows.forEach((row: any) => {
                    totalHistoryArr.push({
                        subtract: row.subtract,
                        item: getProfitHistoryData(historyData, row.columns[totalSection.x], fromReport)
                    })
                })
            }
        }
    } else {
        const section = sections[totalSection.y]
        if (section.total_row.use_columns) {
            totalHistoryArr.push({
                subtract: false,
                item: getProfitHistoryData(historyData, section.total_row.columns[totalSection.x], fromReport)
            })
        } else {
            section.rows.forEach((row: any) => {
                totalHistoryArr.push({
                    subtract: row.subtract,
                    item: getProfitHistoryData(historyData, row.columns[totalSection.x], fromReport)
                })
            })
        }
    }

    let totalData = []
    for (let i = 0; i < dataLength; i++) { 
        let value = 0
        totalHistoryArr.forEach(point => {
            let tmpVal = point.item && point.item.data[i] ? point.item.data[i].value : 0
            if (point.subtract) {
                value += -Math.abs(tmpVal)
            } else {
                value += tmpVal
            }
        })
        totalData.push(value)
    }

    let dataArr: any[] = []
    const _hisData = totalHistoryArr.find(e => e.item !== undefined)
    totalData.forEach((val, i) => {
        dataArr.push({
            // label: _hisData && _hisData.item ? _hisData.item.data[i].label : "NA",
            label: _hisData?.item?.data[i]?.label ?? "NA",
            value: val,
            found: val !== 0
        })
    })
    // dataArr[dataArr.length - 1].value = Math.abs(dataArr[dataArr.length - 1].value) // I dont know why this was here?

    return dataArr
}

export const findLastVal = (data: number[]) => {
    for (let i = data.length - 1; i >= 0; i--) {
        const d = data[i];
        if (!isNaN(d)) return d
    }

    return -1
}

export const getBorderColor = (isBar: boolean, data: any, selectedColumnIndex: number, isProfit: boolean) => {
    let bodyCss = getComputedStyle(document.body);
    let color = bodyCss.getPropertyValue("--kpi1GraphColor");
    let colorActive =  bodyCss.getPropertyValue("--kpi1GraphColorHover");

    let positiveColor = '#b3dba3'
    let positiveColorActive = "#5da540"
    let negativeColor ='#f0bcbc'
    let negativeColorActive =  "#d95959" 

    if (isBar && isProfit) {
        return data.map((val: any, i: number) => {
            if (val >= 0) {
                return i === selectedColumnIndex ? positiveColorActive : positiveColor
            } else if (val < 0) {
                return i === selectedColumnIndex ? negativeColorActive : negativeColor
            } else {
                return color
            }
        })
    } else if (isBar) {
        return data.map((_: any, i: number) => {
            if (i === selectedColumnIndex) {
                return colorActive
            } else {
                return color
            }
        })
    }

    return 'transparent'
}

export const getHoverColor = (isBar: boolean, data: any, isProfit: boolean) => {
    let bodyCss = getComputedStyle(document.body);
    let colorHover = bodyCss.getPropertyValue("--kpi1GraphColorHover");

    let positiveColorHover = "#5da540"
    let negativeColorHover = '#d95959'

    if (isBar && isProfit) {
        return data.map((val: any, i: number) => {
            if (val >= 0) {
                return positiveColorHover
            } else if (val < 0) {
                return negativeColorHover
            } else {
                return colorHover
            }
        })
    } else if (isBar) {
        return data.map((_: any, i: number) => {
            return colorHover
        })
    }

    return 'transparent'
}



export const getHomeData = (canvas: any) => {

    let bodyCss = getComputedStyle(document.body);
    let lineColor = bodyCss.getPropertyValue("--dark-forest");

    // const ctx = canvas.getContext("2d")
    // let _stroke = ctx.stroke;
    // ctx.stroke = function () {
    //     ctx.save()
    //     ctx.shadowColor = '#4096C6'
    //     ctx.shadowBlur = 10
    //     ctx.shadowOffsetX = 0
    //     ctx.shadowOffsetY = 5
    //     _stroke.apply(this, arguments)
    //     ctx.restore()
    // }

    const dataPoints = [6, 3, 7, 4, 9, 2, 1, 3, 2, 4, 5, 10]
    let datasets = []

    let labels: any[] = []
    dataPoints.forEach((item) => {
        labels.push(item + "")
    })

    datasets.push({
        label: "datapoints",
        data: dataPoints,
        fill: false,
        borderColor: lineColor,
        borderWidth: 4,
        pointRadius: 0,
        pointHitRadius: 0,
    })

    return {
        labels: labels,
        datasets: datasets,
    }
}