import React from 'react'

class BgColor extends React.Component {
    static defaultProps = {
        bgClass: ''
    }

    componentDidMount() {
        document.body.classList.toggle(this.props.bgClass, true)
    }

    componentWillUnmount() {
        document.body.classList.remove(this.props.bgClass)
    }

    render() {
        return this.props.children
    }
}

export default BgColor