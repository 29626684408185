import React, { createRef, useReducer, useState, useEffect } from "react";
import { useImperativeHandle } from 'react'
import DiffView from "../Report/TrendComponents/DiffView";

const DiffViewDialog = React.forwardRef((props, ref) => {
    const [trendRef, setTrendRef] = useState(null)
    const [items, setItems] = useState([])
    const diffRef = createRef()
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        show: false,
        reportdata: null,
        selectedKpi: null,
        selectedCategory: null,
        slicers: null,
        last: null,
        sndLast: null,
        hidden: false,
        editMode: false,
        fromMasterData: false,
        setData: () => {}
    })

    useEffect(() => {
        window.$(diffRef.current).on('hide.bs.modal', (e) => {
            if(e.target.id == "dialogContainer"){
            if (!state.toMasterData) {
                setState({ show: false })
            } 
            setItems([])
        }
        })
    }, [])

    function hide(toMasterData) {
        window.$(diffRef.current).modal('hide')
        if (!toMasterData) {
            setState({ show: false })
        } 
        setItems([])
    }

    function show(ref, state, fromMasterData) {
        if (!fromMasterData) {
            setTrendRef(ref)
            setState(state)
        }
        setState({fromMasterData})

        window.$(diffRef.current).modal('show')
        setItems([])
    }

    const createChecklist = (type, label1, label2) => {
        const status = { "All": "All", "1": "Newcomers", "-1": "Gone", "0": "Still in the Insight" }
        const name = `${status[type]}, ${label1.substring(0, 3)}-${label2.substring(0, 3)}. ${state.reportdata.report.name}`
        hide()
        trendRef.props.settingsParent.createChecklist(name, state.reportdata.report.limit, state.reportdata.report.sort_column, state.reportdata.report.sort_direction)
    }

    // functions exposed to outer components
    useImperativeHandle(ref, () => ({
        show: (ref, state, skip) => show(ref, state, skip),
        hide: (toMasterData) => hide(toMasterData),
        setItems: (ids) => setItems(ids)
    }))

    return <div ref={diffRef} className="modal" tabIndex="-1" role="dialog" id="dialogContainer">
        <div className="modal-dialog modal-dialog-custom" role="document">
            <div className="modal-content modal-content-custom">
                <div className="modal-body no-padding">
                    {trendRef /*&& state.show*/ && <DiffView
                        settingsParent={trendRef}
                        show={state.show}
                        hideDiffData={_ => hide()}
                        reportdata={state.reportdata}
                        selectedKpi={state.selectedKpi}
                        selectedCategory={state.selectedCategory}
                        slicers={state.slicers}
                        last={state.last}
                        sndLast={state.sndLast}
                        setSelectedItems={trendRef.props.setSelectedItems}
                        selectedItemsLength={items.length}
                        createChecklist={createChecklist}
                        editMode={state.editMode}
                        setData={state.setData}
                    />}
                </div>
            </div>
        </div>
    </div>
})

export default DiffViewDialog
