import React from 'react'

import WarningAcceptDialog from './WarningAcceptDialog'
import ShowIf from '../Generic/ShowIf'

class ReportDataIssueDialog extends React.Component {
	state = { issues: undefined }

	show(issues, data) {
		this.refs.dialog.show()
		this.setState({issues: issues, data: data})
	}

	hide() {
		this.refs.dialog.hide()
		this.setState({issues: undefined, data: undefined})
	}

	render() {
		let issues = this.state.issues

		return (
			<WarningAcceptDialog ref="dialog" title={this.props.title} accept={() => {if(this.props.accept) this.props.accept(this.state.data);}} closeHandler={this.props.closeHandler} >
				{ !issues ? null :
					<div>
						{this.props.children}
						<ShowIf if={issues.columns && issues.columns.length > 0}>
							<p><b>Included columns in Insight:</b> {issues.columns.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.kpis && issues.kpis.length > 0}>
							<p><b>KPIs:</b> {issues.kpis.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.filter && issues.filter.length > 0}>
							<p><b>Insight filter:</b> {issues.filter.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.fixed_slicer && issues.fixed_slicer.length > 0}>
							<p><b>Fixed slicers:</b> {issues.fixed_slicer.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.sort_column && issues.sort_column.length > 0}>
							<p><b>Top Insight:</b> {issues.sort_column.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.kpi_config && issues.kpi_config.length > 0}>
							<p><b>KPI view configuration:</b> {issues.kpi_config.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.selected_graph_column && issues.selected_graph_column.length > 0}>
							<p><b>Chosen pareto graph column:</b> {issues.selected_graph_column.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.selected_column && issues.selected_column.length > 0}>
							<p><b>Chosen matrix aggreation column:</b> {issues.selected_column.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.selected_grid_column && issues.selected_grid_column.length > 0}>
							<p><b>Chosen grid view sorting column:</b> {issues.selected_grid_column.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.detailed_report_columns && issues.detailed_report_columns.length > 0}>
							<p><b>Shown columns on grid view:</b> {issues.detailed_report_columns.join(", ")}</p>
						</ShowIf>
						<ShowIf if={issues.diff_graph_column && issues.diff_graph_column.length > 0}>
							<p><b>Chosen column on difference graph:</b> {issues.diff_graph_column.join(", ")}</p>
						</ShowIf>
					</div>
				}
			</WarningAcceptDialog>
		)
	}
}

export default ReportDataIssueDialog