import React from 'react'
import { connect } from 'react-redux'
import { getItemCategoryHistory } from '../../actions/ItemActions'
import { getABCCodeStyle, getSingleItemHistoryBarData } from '../../helpers/ItemHelpers'
import WordWrap from '../Generic/WordWrap'

const mapStateToProps = (state, ownProps) => {
    return {
        history: state.Item.history
    }
}

class SingleItemCategoryHistory extends React.Component {
    componentDidMount() {
        this.props.dispatch(getItemCategoryHistory(this.props.bucket.id, this.props.item, this.props.bucket.model.categorization_name))
    }

    render() {
        let { bucket, data } = this.props
        if(!this.props.history || !this.props.history.get(bucket.id, this.props.item)) return null

        let historyData = this.props.history.get(bucket.id, this.props.item).data
        historyData = historyData.slice(-12) //only for latest 12 months

        let history = getSingleItemHistoryBarData(historyData)

        let futureColumn = bucket && bucket.setup ? bucket.setup.future_category_column : null
        let futureColumnIndex = futureColumn ? bucket.model.columns.findIndex(c => c.name === futureColumn) : -1
        let futureColumnValue = futureColumnIndex > -1 ? data.data[futureColumnIndex] : ""
        let futureColumnStyle = getABCCodeStyle(futureColumnValue, bucket.model.categorization)

        return (
            <div className="item-category-history-row margin-top-0">
                {
                    history.map((col,index) => {
                        let borderClass = ""
                        if(index < history.length-1 && history[index+1].value !== col.value) borderClass = " white-border "
                        let style = Object.assign(getABCCodeStyle(col.value, bucket.model.categorization), {
                            width: futureColumn && index === history.length-1 ? `calc(100%/${12.01/col.width} - 100%/18.05)` : `calc(100%/${12.01/col.width})`
                        })
                        return (
                            <div title={`${col.label}${col.width > 1 ? ` - ${col.endLabel}` : ""}${col.found ? ": " + col.value : ""}`} key={index} className={"item-category-history-col" + borderClass} style={style}>
                                <WordWrap>
                                    { col.found ? col.value : "-" }
                                </WordWrap>
                            </div>
                        )
                        }
                    )
                }
                {
                    !futureColumn ? null : 
                    <React.Fragment>
                        <div className="item-category-history-col item-future-column-spacer" style={{width: "calc(100%/36.08)"}}></div>
                        <div className="item-category-history-col" style={Object.assign({width: "calc(100%/36.08)"},futureColumnStyle)} title={"Prediction: "+futureColumnValue}>
                            <WordWrap>
                                {futureColumnValue}
                            </WordWrap>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

SingleItemCategoryHistory = connect(mapStateToProps)(SingleItemCategoryHistory)

export default SingleItemCategoryHistory