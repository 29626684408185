/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import KpiTable from './KpiTable'
import { getHistoryData, startLoading } from '../../../actions/ReportActions'
import GraphItem from './GraphItem'
import { getFilter } from '../../../helpers/ReportHelpers'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import { copyToClipboard } from '../../../helpers/GeneralHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        historydata: state.Report.historydata,
    }
}

class KpiGraphView extends React.Component {
    constructor() {
        super()
        this.state = {
            showTotal: true,
        }
        this.kpiTable =  React.createRef()
    }

    componentDidMount() {
        this.getHistoryData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {    
        if (
            JSON.stringify(prevProps.slicers) !== JSON.stringify(this.props.slicers) || 
            prevProps.reportdata.report.kpis !== this.props.reportdata.report.kpis ||
            prevProps.reportdata.report.filter !== this.props.reportdata.report.filter ||
            prevProps.enabled !== this.props.enabled
        ) {
            this.getHistoryData()
        }
    }

    getHistoryData() {
        if(this.props.historydataForwarded !== undefined || !this.props.enabled){
            return
        }

        this.props.dispatch(startLoading())
        
        let { dispatch } = this.props
        let data = {
            aggregate_column: '',  // group column is found in the backend
            group_column: this.props.reportdata.info.model.categorization_name,
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column === '-' ? null : this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction === '-' ? null : this.props.reportdata.report.sort_direction,
            limit: this.props.reportdata.report.limit,
            kpis: this.props.reportdata.report.kpis,
        }
        if(this.props.show){ //trendview is in focus
            dispatch(getHistoryData(this.props.reportdata.info.id, data))
        } else { //trendview is not in focus - delay this request a little
            setTimeout(_ => {
                dispatch(getHistoryData(this.props.reportdata.info.id, data))
            }, 1000)
        }
    }
    
    kpiClicked(kpi, index){
        //forward to table (where the actual logic is placed)
        this.kpiTable.current.selectKpi(kpi, index)
    }

    setSelectedKpis(kpi1, kpi2, lastAdd, kpiView) {
        this.props.setKpis(kpi1, kpi2, lastAdd, kpiView)

    }

    setSelectedCategory(cat) {
        if(cat === this.props.selectedCategory) {
            this.props.setCategory(undefined)
        } else {
            this.props.setCategory(cat)
        }
    }

    copyToClipboard(format) {
        if(format === "html") {
            copyToClipboard(this.kpiTable.current.getHTML())
        }
    }

    goToTrend(kpi, index) {
        const { historydata, reportdata, historydataForwarded } = this.props

        let data 
        if(historydataForwarded !== undefined){
            data = historydataForwarded.get(reportdata.info.id, [])[index] 
        }
        else{
         data = historydata.get(reportdata.info.id, [])[index] 
        }

        let last, sndLast
        
        for (let i = data.length - 1; i >= 0; i--) {
            const item = data[i]
            if (last === undefined) {
                if (item.found) last = item.aggregation.total.sum
            } else if (sndLast === undefined) {
                if (item.found && item !== last) sndLast = item.aggregation.total.sum
            }
        }
        this.props.goToTrend(kpi, last, sndLast)
    }

    render() {
        const { historydata, reportdata, show, graphOptions, historydataForwarded } = this.props

        let data 
        if(historydataForwarded !== undefined){
            data = historydataForwarded.get(reportdata.info.id, [])
        }
        else{
         data = historydata.get(reportdata.info.id, [])
        }

        if (graphOptions && !graphOptions.zoom)
            graphOptions.zoom = 12

        let tableData = data

        if (data !== null && data.length > 0 && data[0].length > 12){
            let newData = []
            for(let i=0; i < data.length; i++){
                newData[i] = data[i].slice(-12)
            }
            tableData = newData;
        }


        let kpis = JSON.parse(reportdata.report.kpis)
        if (show && kpis.length > 0) {
            return (
                <div className="row">                        
                    <div className="col-md-12 margin-top-20px">
                        <GraphItem
                            reportdata={this.props.reportdata}
                            historydata={data}
                            selectedCategory={this.props.selectedCategory}
                            kpi1={this.props.selectedKpi1}
                            kpi2={this.props.selectedKpi2}
                            showTotal={this.state.showTotal}
                            kpis={kpis}
                            lastAdded={this.props.lastAdded}
                            setHighlightedPeriod={(p) => this.kpiTable.current.setPeriod(p)}
                            setKpisSettings={(kpi1, kpi2) => this.props.setKpisSettings(kpi1, kpi2)}
                            graphOptions={graphOptions}
                            setGraphOptions={this.props.setGraphOptions}
                            forPrint={this.props.forPrint}
                            goToTrend={(kpi, index) => this.goToTrend(kpi, index)}
                        />
                        <KpiTable
                            ref={this.kpiTable}
                            reportdata={this.props.reportdata}
                            kpis={kpis}
                            historydata={tableData}
                            selectedKpi1={this.props.selectedKpi1}
                            selectedKpi2={this.props.selectedKpi2}
                            selectedCategory={this.props.selectedCategory}
                            setSelectedKpis={(kpi1, kpi2, lastAdded, kpiView) => this.setSelectedKpis(kpi1, kpi2, lastAdded, kpiView)}
                            goToTrend={(kpi, index) => this.goToTrend(kpi, index)}
                            lastAdded={this.props.lastAdded}
                            settingsParent={this}
                            forPrint={this.props.forPrint}
                            graphOptions={graphOptions}
                        />
                    </div>

                    {/* <div className="col-md-2 margin-top-20px">
                        <CategorizationTable
                            categorizations={this.props.reportdata.info.model.categorization}
                            setSelectedCategory={cs => this.setSelectedCategory(cs)}
                            selectedCategories={this.props.selectedCategory ? [this.props.selectedCategory] : []}
                            clickableCategories={this.props.clickableCategories}
                        />
                        <TotalSelection
                            showTotal={this.state.showTotal}
                            setTotal={bool => this.setState({ showTotal: bool })}
                        />
                        <div>
                            <button className="btn btn-sm btn-default" onClick={() => this.setSelectedCategory(undefined)}>Clear slicers</button>
                        </div>
                    </div> */}
                </div>
            )
        }

        if(show && kpis.length === 0)
            return <div className="alert alert-warning report-view-alert"><i className="fa fa-exclamation-triangle"/> You need at least one kpi to use this view!</div>

        return <span />
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (prevState.showTotal !== curState.showTotal)
}

const getSettingsKeys = (state) => {
    let { ...settings } = state
    return Object.keys(settings)
}

KpiGraphView = withSettingsPropagation(KpiGraphView, getSettingsKeys, shouldComponentUpdateSettings, null, null, "kpiGraph")

KpiGraphView = connect(mapStateToProps, null, null, {forwardRef: true})(KpiGraphView)

export default KpiGraphView
