import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import '../css/DashboardAdmin.css'
import { getDashboardAllData, getDashboardOverview, getDashboardReports, getDefaultDashboard, getHomeDashboard, updateDashboard, calculateTmpDashboard, deleteDashboard, copyDashboard } from '../actions/DashboardActions'
import { DashboardData, DashboardFull, DashboardKPI, DashboardOverview, DashboardQuery, DashboardReport, DashboardReportRow, DashboardReportShortInfo, DashboardWidget, DashboardWidgetData, DataBucket, ParsedDashboardData, ReportData, User, DashboardRow, RowType, DashboardTargetNew, QueryCriterion, LibraryReport } from '../types/transfertypes'
import { SingleKeyMap } from '../helpers/Collections'
import ShowIf from '../components/Generic/ShowIf'
import Tabs from '../components/Tabs'
import EditDashboardFilterDialog from '../components/Dialogs/EditDashboardFilterDialog'
import AddMultipleDashboardFiltersDialog  from '../components/Dialogs/AddMultipleDashboardFiltersDialog'
import { SelectOption, parseDashboardData, stringifyDashboardData, compareObjects, generateTimestampBasedId } from '../helpers/TypeHelpers'
import { getReportInfo } from '../actions/ReportActions'
import EditDashboardReportDialog from '../components/Dialogs/EditDashboardReportDialog'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'
import { getBucketsDataInfo } from '../actions/BucketActions'
import EditDashboardKpiDialog from '../components/Dialogs/EditDashboardKpiDialog'
import { formatValue } from '../helpers/ReportHelpers'
import { getKpiTotalsNew, isKpiUsed, isRowUsed, removeWidgetsWithKpi, removeWidgetsWithRow } from '../helpers/DashboardHelpers'
import EditWidgetDialog from '../components/Dialogs/EditWidgetDialog'
import WidgetRow, { Direction } from '../components/DashboardAdmin/WidgetRow'
import { isDashboardAdmin } from '../helpers/PermissionHelpers'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'
import { setPageTitle } from '../helpers/DocumentHelpers'
import PopOver from '../components/Generic/PopOver'
import EditTargetDialog, { getDefaultTarget } from '../components/Dialogs/EditTargetDialog'
import GenericDialog from '../components/Dialogs/GenericDialog'
import { notifyFailure } from '../helpers/NotificationManager'
import HideIf from '../components/Generic/HideIf'
import { fromNow } from '../helpers/TimeHelpers'
import { getDataDescriptionDialogDescription } from '../helpers/ReportHelpers'
import ReportDescription from '../components/ReportDescription'
import ReactTooltip from 'react-tooltip'

interface DashboardAdminProps {
    dispatch: any,
    dashboardOverview: DashboardOverview[],
    homeDashboard: any,
    defaultDashboard: any,
    dashboardData: SingleKeyMap<DashboardFull>,
    reportInfo?: ReportData,
    user: User,
    reports: LibraryReport[],
    buckets: SingleKeyMap<DataBucket>,
    match: any,
    history: any
}

let mapStateToProps = (state: any, _ownProps: DashboardAdminProps) => {
    return {
        dashboardOverview: state.Dashboard.overview,
        homeDashboard: state.Dashboard.homeDashboard,
        defaultDashboard: state.Dashboard.defaultDashboard,
        dashboardData: state.Dashboard.dashboard_data,
        reportInfo: state.Info.reportInfo,
        user: state.User.info,
        reports: state.Dashboard.reports,
        buckets: state.Bucket.bucketDetails,
    }
}

let DashboardAdmin = ({dashboardData, ...props}: DashboardAdminProps) => {

    const [dashboard, setDashboard] = useState<DashboardFull | undefined>(undefined)
    const [dID, setDID] = useState<number | undefined>()
    const [chosenDashboard, setChosenDashboard] = useState<SelectOption<DashboardOverview> | undefined>(undefined)
    const [activeTab, setActiveTab] = useState<string>("table")
    const [tmpDashboard, setTmpDashboard] = useState<ParsedDashboardData | undefined>(undefined)
    const [filterToEdit, setFilterToEdit] = useState<DashboardQuery | undefined>(undefined)
    const [reportToEdit, setReportToEdit] = useState<{report: DashboardReport, index: number} | undefined>(undefined)
    const [kpiToEdit, setKpiToEdit] = useState<DashboardKPI | undefined>(undefined)
    const [widgetToEdit, setWidgetToEdit] = useState<DashboardWidget | undefined>(undefined)
    const [addingFilter, setAddingFilter] = useState(false)
    const [showMultipleFiltersDialog, setShowMultipleFiltersDialog] = useState(false)
    const [showEditReport, setShowEditReport] = useState(false)
    const [showEditKpi, setShowEditKpi] = useState(false)
    const [showEditWidget, setShowEditWidget] = useState(false)
    const [showEditTarget, setShowEditTarget] = useState(false)
    const [targetToEdit, setTargetToEdit] = useState<DashboardTargetNew | undefined>(undefined)
    const [targetToEditIndex, setTargetToEditIndex] = useState<number>(-1)
    const [targetToEditKpi, setTargetToEditKpi] = useState<string>("")
    const [rowToEdit, setRowToEdit] = useState<DashboardRow | undefined>(undefined)

    const [tmpRowNum, setTmpRowNum] = useState<number | undefined>(undefined)
    const [tmpWidgetNum, setTmpWidgetNum] = useState<number | undefined>(undefined)

    let dashboardOptions: SelectOption<DashboardOverview>[] = useMemo(() => props.dashboardOverview.map(v => { return { label: v.title, value: v } }).sort((a, b) => a.label.localeCompare(b.label)), [props.dashboardOverview])
    let reportOptions: SelectOption<DashboardReportShortInfo>[] = useMemo(() => props.reports.map(v => { return { label: v.report.name, value: v.report } }).sort((a, b) => a.label.localeCompare(b.label)), [props.reports])

    const [kpiToDelete, setKpiToDelete] = useState<DashboardKPI | undefined>(undefined)
    let deleteKpiDialog = useRef<any>()
    const [widgetToDelete, setWidgetToDelete] = useState<{ rowNum: number, widgetNum: number } | undefined>(undefined)
    let deleteWidgetDialog = useRef<any>()
    const [widgetRowToDelete, setWidgetRowToDelete] = useState<number | undefined>(undefined)
    let deleteWidgetRowDialog = useRef<any>()
    const [rowToDelete, setRowToDelete] = useState<DashboardRow | undefined>(undefined)
    let deleteRowDialog = useRef<any>()
    
    const [showEditInfoDialog, setShowEditInfoDialog] = useState(false)
    const [tmpTitle, setTmpTitle] = useState("")
    const [tmpDescription, setTmpDescription] = useState("")

    let warnUndoDialog = useRef<any>()
    let deleteDashboardDialog = useRef<any>()

    const shownRows = useMemo(() => tmpDashboard?.rows.filter(v => !v.hidden) ?? [], [tmpDashboard])
    const hiddenRows = useMemo(() => tmpDashboard?.rows.filter(v => v.hidden) ?? [], [tmpDashboard])

    const {routerPrompt, setUnsavedChanges} = useUnsavedChangesWarning("You have unsaved changes, are you sure you want to leave?")

    // On mount
    useEffect(() => {
        props.dispatch(getDashboardOverview())
        props.dispatch(getDefaultDashboard())
        props.dispatch(getHomeDashboard())
        props.dispatch(getDashboardReports())
        props.dispatch(getBucketsDataInfo())

        let id = props.match?.params?.dID
        if(id != undefined) {
            let tmpId = Number(id)
            if (!isNaN(tmpId)) {
                props.dispatch(getDashboardAllData(tmpId))
                setDID(tmpId)
            }
        }
        setPageTitle("Dashboard Admin")
    }, [])

    useEffect(() => {
        if (chosenDashboard == undefined && dID == undefined) {
            setDashboard(undefined)
            setTmpDashboard(undefined)
            return
        }
        let id = chosenDashboard?.value?.id ?? dID
        if (id == undefined) return
        let db = dashboardData.get(id.toString())
        if (db == undefined) return
        setDashboard(db)
        setTmpDashboard(parseDashboardData(db.dashboard_data))
        let mainRow: DashboardRow = JSON.parse(db.dashboard_data.main_row)
        let mainReport: DashboardReportRow = mainRow.row as DashboardReportRow
        props.dispatch(getReportInfo(mainReport.report_id))
    }, [dashboardData, chosenDashboard, dID])

    useEffect(() => {
        if (tmpDashboard == undefined) return
        let mainReport = tmpDashboard.main_row.row as DashboardReportRow
        props.dispatch(getReportInfo(mainReport.report_id))
    }, [tmpDashboard?.main_row])
    // Resets adding filter flag
    useEffect(() => {
        if (filterToEdit == undefined) {
            setAddingFilter(false)
        }
    }, [filterToEdit])

    // Whenever changes are made to the dashboard, we calculate the new table and check if the dashboard has changed from the original
    useEffect(() => {
        if (tmpDashboard == undefined) return
        setUnsavedChanges(!disableSave())
    }, [tmpDashboard])

    useEffect(() => {
        if (chosenDashboard == undefined && dID != undefined) {
            let tmp = dashboardOptions.find(v => v.value.id == dID)
            if (tmp != undefined) setChosenDashboard(tmp)
        }
    }, [props.dashboardOverview, dashboardOptions, chosenDashboard, dID])

    const calculateTableData = (cDashboard: ParsedDashboardData) => {
        let db = stringifyDashboardData(cDashboard)
        props.dispatch(calculateTmpDashboard(db, (data: Omit<DashboardFull, "dashboard_data">) => {
            if (dashboard == undefined) return
            let tmp: DashboardFull = structuredClone(dashboard)
            tmp.table_data = data.table_data
            tmp.widget_data = data.widget_data
            setDashboard(tmp)
        }))
    }

    const tabChangeHandler = (value: string) => {
        setActiveTab(value)
    }

    const onCancelMultiple = () => {
        setShowMultipleFiltersDialog(false)
    }

    const addMultipleFilterRows = () => {
        setShowMultipleFiltersDialog(true)
    }

    const createFilters = (columnName:string, columnValues:string[]) => {
        if(columnValues == undefined) return;

        if (columnValues.length > 100){
            notifyFailure("Too many values for filter. Maximum is 100 rows", null)
            return
        }
        
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)

        for(let i = 0; i < columnValues.length; i++){
            
            let identifier = generateTimestampBasedId();

            let rowlabel = columnValues[i]
            
            let criterion:QueryCriterion = {  
                column: columnName,
                compare_type: 1,
                target_values: [rowlabel],
                is_column: false,
            }
            
            let dq:DashboardQuery = {id: identifier,
                label: columnName + " = " + (rowlabel == "" ? "No value" : rowlabel),
                filter: [criterion],
            }

            let row: DashboardRow = {row_id: identifier, row: dq, type: RowType.Filter, hidden: false, targets: {}}
            tmp.rows.push(row)
        }

        calculateTableData(tmp)
        setTmpDashboard(tmp)
        setShowMultipleFiltersDialog(false)
    }

    const addFilterRow = () => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)

        let q: DashboardQuery = {
            label: 'New filter',
            filter: [],
            id: generateTimestampBasedId(),
        }

        let row: DashboardRow = {row_id: q.id, row: q, type: RowType.Filter, targets: {}, hidden: false}

        tmp.rows.push(row)
        setTmpDashboard(tmp)
        setFilterToEdit(q)
        setAddingFilter(true)
    }

    const addReportRow = () => {
        if (tmpDashboard == undefined) return
        setShowEditReport(true)
    }

    const addKpi = () => {
        setShowEditKpi(true)
    }

    const editKpi = (kpi: DashboardKPI) => {
        setKpiToEdit(kpi)
        setShowEditKpi(true)
    }

    const saveKpi = (kpi: DashboardKPI) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let index = tmp.kpis.findIndex(v => v.kpi_key == kpi.kpi_key)
        if (index > -1) {
            tmp.kpis[index] = kpi
        } else {
            tmp.kpis.push(kpi)
        }
        setTmpDashboard(tmp)
        calculateTableData(tmp)
        setShowEditKpi(false)
        setKpiToEdit(undefined)
    }

    const onCancelKpi = () => {
        setShowEditKpi(false)
        setKpiToEdit(undefined)
    }

    const deleteKpi = (kpi: DashboardKPI) => {
        setKpiToDelete(kpi)
        deleteKpiDialog.current.show()
    }

    const confirmDeleteKpi = () => {
        if (tmpDashboard == undefined || kpiToDelete == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let index = tmp.kpis.findIndex(v => v.kpi_key == kpiToDelete.kpi_key)
        if (index > -1) {
            tmp.kpis.splice(index, 1)
        }
        if (isKpiUsed(tmp, kpiToDelete)) {
            tmp = removeWidgetsWithKpi(tmp, kpiToDelete)
        }
        setTmpDashboard(tmp)
        setKpiToDelete(undefined)
    }

    const copyKpi = (kpi: DashboardKPI) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let tmpKpi = kpi
        tmpKpi.label = kpi.label != '' ? kpi.label + " - Copy" : ''
        tmpKpi.kpi_key = generateTimestampBasedId()
        tmp.kpis.push(tmpKpi)
        setTmpDashboard(tmp)
    }

    const editFilter = (filter: DashboardQuery) => {
        setFilterToEdit(filter)
    }

    const saveFilter = (filter: DashboardQuery) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let index = tmp.rows.findIndex(v => v.row_id == filter.id)
        let r = tmp.rows.find(v => v.row_id == filter.id)
        let row: DashboardRow = {row_id: filter.id, row: filter, type: RowType.Filter, hidden: r?.hidden ?? false, targets: r?.targets ?? {}}
        if (index > -1) {
            tmp.rows[index] = row
        } else {
            tmp.rows.push(row)
        }
        calculateTableData(tmp)
        setTmpDashboard(tmp)
        setFilterToEdit(undefined)
    }

    const onCancelFilter = () => {
        if (tmpDashboard == undefined || filterToEdit == undefined) return
        if (addingFilter) {
            let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
            let index = tmp.rows.findIndex(v => v.row_id == filterToEdit.id)
            if (index > -1) {
                tmp.rows.splice(index, 1)
            }
            setTmpDashboard(tmp)
        }
        setFilterToEdit(undefined)
    }

    const copyFilter = (f: DashboardQuery) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let tmpFilter = f
        tmpFilter.id = generateTimestampBasedId()
        tmpFilter.label = f.label + '- Copy'
        let r  = tmp.rows.find(v => v.row_id == f.id)
        let row: DashboardRow = {row_id: tmpFilter.id, row: tmpFilter, type: RowType.Filter, hidden: r?.hidden ?? false, targets: r?.targets ?? {}}
        tmp.rows.push(row)
        setTmpDashboard(tmp)
    }

    const editReport = (report: {report: DashboardReport, index: number}) => {
        setReportToEdit(report)
        setShowEditReport(true)

    }

    const saveReport = (report: DashboardReportRow) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let index = reportToEdit?.index ?? -1
        let oldRow: DashboardRow | undefined = undefined
        const isMainRow = `${reportToEdit?.report.report_id}` === tmp.main_row.row_id && reportToEdit?.index == -1
        if (isMainRow) {
            // If the report is the main report
            oldRow = tmp.main_row
        } else {
            oldRow = tmp.rows.find(v => v.row_id == `${reportToEdit?.report.report_id}`)
        }
        let newRow: DashboardRow = {row_id: `${report.report_id}`, row: report, type: RowType.Report, hidden: oldRow?.hidden ?? false, targets: oldRow?.targets ?? {}}

        if (reportToEdit != undefined) {
            changeWidgetRows(tmp, `${reportToEdit.report.report_id}`, newRow.row_id)
        }
        if (isMainRow) {
            tmp.main_row = newRow
        } else {
            if (index > -1) {
                tmp.rows[index] = newRow
            } else {
                tmp.rows.push(newRow)
            }
        }

        calculateTableData(tmp)
        setTmpDashboard(tmp)
        setReportToEdit(undefined)
        setShowEditReport(false)
    }

    // Modifies the given dashboard's widgets so that every widget that refers to the oldId row now refers to the newId row.
    // This includes changing the targets if they are targeted at the oldId row
    const changeWidgetRows = (db: ParsedDashboardData, oldId: string, newId: string) => {
        for (let i = 0; i < db.widgets.length; i++) {
            for (let j = 0; j < db.widgets[i].widgets.length; j++) {
                if (db.widgets[i].widgets[j].row_id == oldId) {
                    db.widgets[i].widgets[j].row_id = newId
                }
                //const target = db.widgets[i].widgets[j].target
                //if (target.enabled && target.is_kpi_target && target.row_id == oldId) {
                //    db.widgets[i].widgets[j].target.row_id = newId
                //}

            }
        }
    }

    const onCancelReport = () => {
        setReportToEdit(undefined)
        setShowEditReport(false)
    }

    const deleteRow = (r: DashboardRow) => {
        setRowToDelete(r)
        deleteRowDialog.current.show()
    }

    const confirmDeleteRow = () => {
        if (tmpDashboard == undefined || rowToDelete == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let index = tmp.rows.findIndex(v => v.row_id == rowToDelete.row_id)
        if (index > -1) {
            tmp.rows.splice(index, 1)
        }
        if (isRowUsed(tmp, rowToDelete.row_id)) {
            tmp = removeWidgetsWithRow(tmp, rowToDelete.row_id)
        }
        setTmpDashboard(tmp)
        setRowToDelete(undefined)
    }

    let disableSave = () => {
        if (dashboard == undefined || tmpDashboard == undefined) return true
        let orig = parseDashboardData(dashboard.dashboard_data)
        let tmp = parseDashboardData(stringifyDashboardData(tmpDashboard))
        let res = compareObjects(orig, tmp)
        return res
    }

    let saveDashboard = () => {
        if (dashboard == undefined || tmpDashboard == undefined) return
        props.dispatch(updateDashboard(stringifyDashboardData(tmpDashboard), (res: DashboardData) => {
            let tmp = structuredClone(dashboard)
            tmp.dashboard_data = res
            setDashboard(tmp)
            setUnsavedChanges(false)
        }))
    }

    const undo = () => {
        if (dashboard == undefined) return
        const dash = parseDashboardData(dashboard.dashboard_data)
        setTmpDashboard(dash)
        calculateTableData(dash)
    }

    const moveWidget = (rowNum: number, from: number, to: number) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let wr = tmp.widgets[rowNum].widgets[from]
        tmp.widgets[rowNum].widgets.splice(from, 1)
        tmp.widgets[rowNum].widgets.splice(to, 0, wr)
        setTmpDashboard(tmp)
    }

    const editWidget = (widget: DashboardWidget, rowNum: number, widgetNum: number) => {
        setShowEditWidget(true)
        setWidgetToEdit(widget)
        setTmpRowNum(rowNum)
        setTmpWidgetNum(widgetNum)
    }

    //const editWidgetTarget = (widget: DashboardWidget, rowNum: number, widgetNum: number) => {
    //    setShowEditTarget(true)
    //    setTargetToEdit(widget.target)
    //    setWidgetToEdit(widget)
    //    setTmpRowNum(rowNum)
    //    setTmpWidgetNum(widgetNum)
    //}

    const onCancelWidget = () => {
        setShowEditWidget(false)
        setWidgetToEdit(undefined)
        setTmpRowNum(undefined)
        setTmpWidgetNum(undefined)
    }

    const saveRow = (row: DashboardRow) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        if (row.row_id == tmp.main_row.row_id) {
            tmp.main_row = row
        } else {
            const index = tmp.rows.findIndex(v => v.row_id == row.row_id)
            if (index > -1) {
                tmp.rows[index] = row
            } else {
                tmp.rows.push(row)
            }
        }
        setTmpDashboard(tmp)
    }

    const onCancelTarget = () => {
        setShowEditTarget(false)
        setTargetToEdit(undefined)
        setTargetToEditIndex(-1)
        setTargetToEditKpi("")
        setRowToEdit(undefined)
    }

    const onSaveTarget = (target: DashboardTargetNew) => {
            if (rowToEdit != undefined ) {
                if (target.enabled) {
                    let tmpRow: DashboardRow = structuredClone(rowToEdit)
                    let tmpTargets = tmpRow.targets[targetToEditKpi] ?? []
                    if (targetToEditIndex != -1) {
                        tmpTargets[targetToEditIndex] = target
                    }
                    tmpRow.targets[targetToEditKpi] = tmpTargets
                    saveRow(tmpRow)
                } else {
                    let tmpRow: DashboardRow = structuredClone(rowToEdit)
                    let tmpTargets = tmpRow.targets[targetToEditKpi] ?? []
                    if (targetToEditIndex != -1) {
                        tmpTargets.splice(targetToEditIndex, 1)
                    }
                    tmpRow.targets[targetToEditKpi] = tmpTargets
                    saveRow(tmpRow)
                }
            } 
        onCancelTarget()
        onCancelWidget()
    }

    const onEditTarget = (row: DashboardRow, targetIndex: number, kpiKey: string) => {
        setRowToEdit(row)
        let target = row.targets[kpiKey]?.[targetIndex] ?? getDefaultTarget()
        setTargetToEdit(target)
        setTargetToEditIndex(targetIndex)
        setTargetToEditKpi(kpiKey)
        setShowEditTarget(true)
    }

    const saveWidget = (widget: DashboardWidget) => {
        if (tmpDashboard == undefined || tmpRowNum == undefined || tmpWidgetNum == undefined) return
        let tmp = structuredClone(tmpDashboard)
        tmp.widgets[tmpRowNum].widgets[tmpWidgetNum] = widget
        setTmpDashboard(tmp)
        calculateTableData(tmp)
        setShowEditWidget(false)
        setWidgetToEdit(undefined)
        setTmpRowNum(undefined)
        setTmpWidgetNum(undefined)
    }

    const saveWidgetDirect = (widget: DashboardWidget, rowNum: number, widgetNum: number) => {
        if (tmpDashboard == undefined) return
        let tmp = structuredClone(tmpDashboard)
        tmp.widgets[rowNum].widgets[widgetNum] = widget
        setTmpDashboard(tmp)
    }

    const addWidgetRow = (type: "large" | "small" = "large") => {
        if (tmpDashboard == undefined) return
        let tmp = structuredClone(tmpDashboard)
        let tmpRow: DashboardWidgetData = {
            type: type,
            widgets: []
        }
        const amount = type == "large" ? 3 : 6

        for (let i = 0; i < amount; i++) {
            tmpRow.widgets.push({
                kpi_key: '',
                shown: false,
                label: '',
                trend: 0,
                row_id: '',
                id: generateTimestampBasedId(),
            })
        }
        tmp.widgets.push(tmpRow)
        setTmpDashboard(tmp)
    }

    const deleteWidget = (rowNum: number, widgetNum: number) => {
        setWidgetToDelete({ rowNum, widgetNum })
        deleteWidgetDialog.current.show()
    }

    const confirmDeleteWidget = () => {
        if (tmpDashboard == undefined || widgetToDelete == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        tmp.widgets[widgetToDelete.rowNum].widgets[widgetToDelete.widgetNum] = { shown: false, kpi_key: '', label: '', trend: 0, row_id: '', id: tmp.widgets[widgetToDelete.rowNum].widgets[widgetToDelete.widgetNum].id }
        setTmpDashboard(tmp)
        setWidgetToDelete(undefined)
    }

    const moveWidgetRow = (rowNum: number, direction: Direction) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        let wr = tmp.widgets[rowNum]
        let to = direction == Direction.UP ? rowNum - 1 : rowNum + 1
        tmp.widgets.splice(rowNum, 1)
        tmp.widgets.splice(to, 0, wr)
        setTmpDashboard(tmp)
    }

    const deleteWidgetRow = (rowNum: number) => {
        setWidgetRowToDelete(rowNum)
        deleteWidgetRowDialog.current.show()
    }

    const confirmDeleteWidgetRow = () => {
        if (tmpDashboard == undefined || widgetRowToDelete == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        tmp.widgets.splice(widgetRowToDelete, 1)
        setTmpDashboard(tmp)
    }

    /** Moves the row 1 position up or down
     */
    const shiftRow = (index: number, direction: Direction) => {
        if (tmpDashboard == undefined) return
        let newIndex: number
        if ((direction == Direction.DOWN && index == tmpDashboard.rows.length - 1) || (direction == Direction.UP && index == 0)) return
        if (direction == Direction.DOWN) {
            newIndex = index + 1
        } else {
            newIndex = index - 1
        }
        let tmpRows = tmpDashboard.rows.slice()
        let row = tmpRows[index]
        tmpRows.splice(index, 1)
        tmpRows.splice(newIndex, 0, row)

        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        tmp.rows = tmpRows
        setTmpDashboard(tmp)
    }

    const findLastShownRow = (rows: DashboardRow[]) => {
        let index = rows.findIndex(v => v.hidden)
        if (index == -1) return rows.length
        return index
    }

    const toggleHide = (row: DashboardRow) => {
        if (tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        if (row.row_id === tmp.main_row.row_id) {
            tmp.main_row.hidden = !tmp.main_row.hidden
            setTmpDashboard(tmp)
            return
        }
        let index = tmp.rows.findIndex(v => v.row_id == row.row_id)
        if (index == -1) return

        let tmpRows = tmp.rows.slice()
        if (tmpRows[index].hidden) {
            let row = tmpRows[index]
            row.hidden = !row.hidden
            tmpRows.splice(index, 1)
            let newIndex = findLastShownRow(tmpRows)
            tmpRows.splice(newIndex, 0, row)
            tmp.rows = tmpRows
        } else {
            let row = tmpRows[index]
            row.hidden = !row.hidden
            tmpRows.splice(index, 1)
            tmpRows.push(row)
            tmp.rows = tmpRows
        }
        setTmpDashboard(tmp)
    }

    const showEditInfo = () => {
        if(tmpDashboard == undefined) return
        setShowEditInfoDialog(true)
        setTmpTitle(tmpDashboard.title)
        setTmpDescription(tmpDashboard.description)
    }
    
    const onSaveInfo = () => {
        if(tmpDashboard == undefined) return
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        tmp.title = tmpTitle
        tmp.description = tmpDescription
        setTmpDashboard(tmp)
        onCloseInfo()
    }
    
    const onCloseInfo = () => {
        setShowEditInfoDialog(false)
        setTmpTitle("")
        setTmpDescription("")
    }

    const onDelete = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setUnsavedChanges(false)
        deleteDashboardDialog.current.show()

    }

    const onConfirmDelete = () => {
        props.dispatch(deleteDashboard(dID))
        props.history.push(`/dashboard`)
    }

    const onCopy = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if(dashboard) {
            const dash = parseDashboardData(dashboard.dashboard_data)
            props.dispatch(copyDashboard(dash.title + " - Copy", dash.id, dash.org_id, (res: DashboardOverview) => {
                props.history.push("/dashboardadmin/" + res.id)
                window.location.reload();
            }))
        }
    }

    const clearTarget = (row_id: string, kpi_key: string) => {
        return setTarget(row_id, kpi_key, getDefaultTarget())
    }

    const setTarget = (row_id: string, kpi_key: string, target: DashboardTargetNew) => {
        if (tmpDashboard == undefined) return
        let tmp = structuredClone(tmpDashboard)
        let row = tmp.main_row
        if (tmp.main_row.row_id == row_id) {
            tmp.main_row.targets[kpi_key] = target.enabled ? [target] : []
        } else {
            const row_index = tmp.rows.findIndex(r => r.row_id == row_id)
            if (row_index >= 0) {
                tmp.rows[row_index].targets[kpi_key] = target.enabled ? [target] : []
                row = tmp.rows[row_index]
            }
        }
        setTmpDashboard(tmp)
        return row
    }

    const editTarget = (kpi_key: string, row: DashboardRow | undefined) => {
        if (tmpDashboard == undefined || row == undefined) return
        onEditTarget(row, 0, kpi_key)
    }

   
    const setHideTable = (checked: boolean) => {
        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)!
        tmp.hide_table = checked
        setTmpDashboard(tmp)
    }

    const moveKpi = (index: number, direction: Direction.LEFT | Direction.RIGHT) => {
        if (tmpDashboard == undefined) return
        let newIndex: number
        if ((direction == Direction.RIGHT && index == tmpDashboard.kpis.length - 1) || (direction == Direction.LEFT && index == 0)) return
        if (direction == Direction.RIGHT) {
            newIndex = index + 1
        } else {
            newIndex = index - 1
        }
        let tmpKpis = tmpDashboard.kpis.slice()
        let kpi = tmpKpis[index]
        tmpKpis.splice(index, 1)
        tmpKpis.splice(newIndex, 0, kpi)

        let tmp: ParsedDashboardData = structuredClone(tmpDashboard)
        tmp.kpis = tmpKpis
        setTmpDashboard(tmp)
    }

    if (!isDashboardAdmin(props.user.access)) {
        return <div className="d-flex justify-content-center flex-column align-items-center">
            <h2 className="mt-5">You do not have the required permissions to access this page.</h2>
            <h3>If you believe this to be a mistake, please contact support.</h3>
        </div>
    }

    let dashboardtab_disabled = tmpDashboard?.main_row.row_id === '0' && (!tmpDashboard?.rows || tmpDashboard?.rows.length == 0);
    dashboardtab_disabled = dashboardtab_disabled || tmpDashboard?.kpis.length == 0

    return (
        <div className='dashboard-admin'>
            {routerPrompt}
            <div className="d-flex flex-column" style={{borderBottom: "1px solid #d9d9d9"}}>
                <i style={{color: "#9c9c9c"}}>Edit Dashboard</i>
                <div className="d-flex align-items-center">
                    <h1 style={{marginBottom: 0}}>{tmpDashboard?.title}</h1>
                    <button onClick={showEditInfo} className="btn btn-default ml-2 d-flex align-items-center" style={{border: "none", padding: 5}} title="Change name and description"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                        <div style={{ marginLeft: 20, display: "flex", alignItems: "center" }}>
                            <div className='btn-group'>
                                <button onClick={saveDashboard} disabled={disableSave()} className='btn btn-primary btn-sm'>Save</button>
                                <button title='Revert changes' onClick={() => warnUndoDialog.current.show()} disabled={disableSave()} className='btn btn-sm btn-default'><i className="fa fa-undo" aria-hidden="true"></i></button>
                            </div>
                            
                        </div>
                    <div className='btn-group' style={{ marginLeft: "auto", display: "flex", justifyContent: "flex-end"}}>
                        <button onClick={() => { props.history.push(`/dashboard/${dID}`)}} disabled={!disableSave()}  className='btn btn-default btn-sm'>Show Dashboard</button>
                        <button type="button" className="btn btn-sm btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li className="abc-click" onClick={e => onCopy(e)}><span>Copy</span></li>
                            <li className="abc-click" onClick={e => onDelete(e)}><span style={{color: "var(--danger)"}}>Delete</span></li>
                        </ul>

                    </div>
                </div>
            </div> 
                {/* <button onClick={() => console.log(props)} >PROPS</button>
                <button onClick={() => console.log(dashboard)}>DASHBOARD</button>
                <button onClick={() => console.log(tmpDashboard)}>TMP</button>
                <button onClick={() => console.log(shownRows)}>SHOWN</button>
                <button onClick={() => console.log(hiddenRows)}>HIDDEN</button> */}
                
            {dashboard != undefined && tmpDashboard != undefined ?
                <>
                    <div className='dashboard-admin-toolbar'>
                        <Tabs
                            options={[
                                      { label: "Table", value: "table", disabled: false, tooltip:""}, 
                                      { label: "Dashboard", value: "widgets", disabled:dashboardtab_disabled, tooltip:`${dashboardtab_disabled ? "At least one row and column must be included in the table in order to edit Dashboard": ""}` }
                                    ]}
                            onChange={tabChangeHandler}
                            value={activeTab}
                            style={{ width: 300, marginBottom: 10 }}
                        />
                    </div>

                    <ShowIf if={activeTab == "table"}>
                        <div>
                            <div className='kpi-table-wrapper' style={{maxHeight:"100%"}}>
                                <KpiTable
                                    dashboard={dashboard}
                                    parsedDashboard={tmpDashboard}
                                    rows={shownRows}
                                    editFilter={editFilter}
                                    editReport={editReport}
                                    editKpi={editKpi}
                                    user={props.user}
                                    reports={props.reports}
                                    deleteKpi={deleteKpi}
                                    copyFilter={copyFilter}
                                    copyKpi={copyKpi}
                                    addMultipleFilterRows={addMultipleFilterRows}
                                    addFilterRow={addFilterRow}
                                    addReportRow={addReportRow}
                                    addKpi={addKpi}
                                    moveRow={shiftRow}
                                    deleteRow={deleteRow}
                                    toggleHide={toggleHide}
                                    saveRow={saveRow}
                                    editTarget={onEditTarget}
                                    moveKpi={moveKpi}
                                />
                            </div>

                            <ShowIf if={hiddenRows.length > 0 || tmpDashboard.main_row.hidden}>
                                <h4 className="mt-3"><i className="fa fa-eye-slash" style={{color: "#a6a6a6"}}></i> Hidden rows</h4>
                                <div className='kpi-table-wrapper' style={{maxHeight:"100%"}}>
                                    
                                    {/* <button className='dropdown-item' onClick={() => console.log(dashboard)}>DEBUG</button> */}
                                    
                                    <KpiTable
                                        dashboard={dashboard}
                                        parsedDashboard={tmpDashboard}
                                        rows={hiddenRows}
                                        editFilter={editFilter}
                                        editReport={editReport}
                                        editKpi={editKpi}
                                        user={props.user}
                                        reports={props.reports}
                                        deleteKpi={deleteKpi}
                                        copyFilter={copyFilter}
                                        copyKpi={copyKpi}
                                        addMultipleFilterRows={addMultipleFilterRows}
                                        addFilterRow={addFilterRow}
                                        addReportRow={addReportRow}
                                        addKpi={addKpi}
                                        moveRow={shiftRow}
                                        deleteRow={deleteRow}
                                        toggleHide={toggleHide}
                                        hiddenTable={true}
                                        saveRow={saveRow}
                                        editTarget={onEditTarget}
                                        moveKpi={moveKpi}
                                    />
                                </div>
                            </ShowIf>
                        </div>
                    </ShowIf>

                    <ShowIf if={activeTab == "widgets"}>
                        <div>
                            {tmpDashboard.widgets.map((v, i) => {
                                return <WidgetRow 
                                    key={i} 
                                    widgetRow={v} 
                                    rowNum={i} 
                                    deleteWidget={deleteWidget} 
                                    moveWidget={moveWidget} 
                                    dashboard={dashboard} 
                                    parsedDashboard={tmpDashboard} 
                                    editWidget={editWidget} 
                                    deleteRow={deleteWidgetRow} 
                                    moveRow={moveWidgetRow} 
                                    saveWidget={saveWidgetDirect}
                                    reports={props.reports}
                                    clearTarget={clearTarget}
                                    setTarget={setTarget}
                                    editTarget={editTarget}
                                />
                            })}
                            <div className='dropdown d-flex justify-content-center flex-column align-items-center' style={{marginLeft: 10, width: 1220}}>
                                <ShowIf if={tmpDashboard.widgets.length == 0}>
                                    <h2>You have not added any widgets</h2>
                                </ShowIf>
                                <div className="kpi-table-add abc-click" data-toggle="dropdown" style={{width: "fit-content"}}>
                                    <i className="fa fa-plus report-icon fa-fw mr-1" aria-hidden="true"></i>
                                    Add KPI row
                                </div>
                                <div className='dropdown-menu' aria-labelledby='addRowButton'>
                                    <button onClick={() => addWidgetRow()} className='dropdown-item'>Large row</button>
                                    <button onClick={() => addWidgetRow("small")} className='dropdown-item'>Small row</button>
                                </div>
                            </div>
                            <div className="">
                                <h4>Additional settings</h4>
                                <input className="mr-1" type="checkbox" checked={tmpDashboard.hide_table} onChange={() => setHideTable(!tmpDashboard.hide_table)} id="hide-table-check" />
                                <label className="" htmlFor="hide-table-check">
                                    Hide table from Dashboard
                                </label>

                            </div>

                        </div>
                    </ShowIf>
                    {filterToEdit != undefined && props.reportInfo != undefined ?
                        <EditDashboardFilterDialog dashboard={tmpDashboard} filter={filterToEdit} show={filterToEdit != undefined} onClose={onCancelFilter} saveFilters={(f) => saveFilter(f)} reportInfo={props.reportInfo} adding={addingFilter} />
                        : null}
                    {props.reportInfo != undefined ? 
                        <AddMultipleDashboardFiltersDialog show={showMultipleFiltersDialog} onClose={onCancelMultiple} createFilters={(col:string, values:string[]) => createFilters(col, values)} reportInfo={props.reportInfo} />
                        : null}
                    <EditDashboardReportDialog show={showEditReport} reportOptions={reportOptions} saveReport={saveReport} onClose={onCancelReport} report={reportToEdit?.report} isMain={tmpDashboard.main_row.row_id == `${reportToEdit?.report.report_id}`} />
                    {props.reportInfo != undefined ?
                        <EditDashboardKpiDialog show={showEditKpi} reportInfo={props.reportInfo} kpi={kpiToEdit} dashboard={tmpDashboard} onClose={onCancelKpi} saveKpi={(v) => saveKpi(v)} buckets={props.buckets} />
                        : null
                    }

                    <EditWidgetDialog
                        show={showEditWidget}
                        saveWidget={(w) => saveWidget(w)}
                        onClose={onCancelWidget}
                        dashboard={dashboard}
                        parsedDashboard={tmpDashboard}
                        user={props.user}
                        reports={props.reports}
                        widget={widgetToEdit}
                    />

                    <EditTargetDialog 
                        show={showEditTarget}
                        saveTarget={onSaveTarget}
                        onClose={onCancelTarget}
                        dashboard={dashboard}
                        parsedDashboard={tmpDashboard}
                        user={props.user}
                        reports={props.reports}
                        target={targetToEdit}
                    />

                </> : null}

            <GenericDialog show={showEditInfoDialog} style={{minWidth: 200, maxWidth: 400, width: "100%"}} onClose={onCloseInfo} title="Edit dashboard" getButtons={() => {
                return  <div>
                            <button style={{ marginRight: 7 }} onClick={onSaveInfo} disabled={tmpTitle == ""} className='btn btn-primary'>Save</button>
                            <button onClick={onCloseInfo} className='btn btn-default'>Cancel</button>
                        </div>
            }}>
                <div className="form-group">
                    <label htmlFor="title-input">Title:</label>
                    <input type="text" className="form-control" id="title-input" placeholder="Insert title" value={tmpTitle} onChange={(e) => setTmpTitle(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="description-input">Description:</label>
                    <textarea className="form-control" id="description-input" placeholder="Insert description (optional)" value={tmpDescription} onChange={(e) => setTmpDescription(e.target.value)} />
                </div>
            </GenericDialog>

            <WarningAcceptDialog title={"Delete row"} acceptText="Delete" ref={deleteRowDialog} accept={confirmDeleteRow} closeHandler={() => setRowToDelete(undefined)}>
                <span>Are you sure you want to delete this row?</span>
                <ShowIf if={tmpDashboard != undefined && rowToDelete != undefined && isRowUsed(tmpDashboard, rowToDelete.row_id)}>
                    <div className='alert alert-warning mt-2'>
                        This row is used in one or more widgets, deleting this row will remove the widgets.
                    </div>
                </ShowIf>
            </WarningAcceptDialog>

            <WarningAcceptDialog title={"Delete KPI"} acceptText="Delete" ref={deleteKpiDialog} accept={confirmDeleteKpi} closeHandler={() => setKpiToDelete(undefined)}>
                <span>Are you sure you want to delete this KPI?</span>
                <ShowIf if={tmpDashboard != undefined && isKpiUsed(tmpDashboard, kpiToDelete)}>
                    <div className='alert alert-warning mt-2'>
                        This KPI is used in one or more widgets, deleting this KPI will remove the widgets.
                    </div>
                </ShowIf>
            </WarningAcceptDialog>

            <WarningAcceptDialog title="Delete widget" acceptText="Delete" ref={deleteWidgetDialog} accept={confirmDeleteWidget} closeHandler={() => setWidgetToDelete(undefined)}>
                <span>Are you sure you want to delete this widget?</span>
            </WarningAcceptDialog>

            <WarningAcceptDialog title="Delete widget row" acceptText="Delete" ref={deleteWidgetRowDialog} accept={confirmDeleteWidgetRow} closeHandler={() => setWidgetRowToDelete(undefined)}>
                <span>Are you sure you want to delete this widget row?</span>
            </WarningAcceptDialog>

            <WarningAcceptDialog ref={warnUndoDialog} title={"Revert changes"} acceptText="Accept" accept={() => undo()}>
                <span>Are you sure want to revert changes? This will revert <b>ALL</b> changes.</span>
            </WarningAcceptDialog>

            <WarningAcceptDialog title="Delete Dashboard" acceptText="Delete" ref={deleteDashboardDialog} accept={onConfirmDelete} closeHandler={() => setUnsavedChanges(!disableSave()) }>
                <span>Are you sure you want to delete Dashboard? <b>This can not be undone.</b></span>
            </WarningAcceptDialog>
        </div>
    )
}

// @ts-ignore
DashboardAdmin = connect(mapStateToProps)(DashboardAdmin)

export default DashboardAdmin

interface KpiTableProps {
    dashboard: DashboardFull,
    parsedDashboard: ParsedDashboardData,
    rows: DashboardRow[],
    user: User,
    editFilter: (filter: DashboardQuery) => void,
    editReport: (report: {report: DashboardReport, index: number}) => void,
    editKpi: (kpi: DashboardKPI) => void,
    reports: LibraryReport[],
    deleteKpi: (k: DashboardKPI) => void,
    deleteRow: (r: DashboardRow) => void,
    copyFilter: (f: DashboardQuery) => void,
    copyKpi: (kpi: DashboardKPI) => void,
    addMultipleFilterRows: () => void,
    addFilterRow: () => void,
    addReportRow: () => void,
    addKpi: () => void,
    moveRow: (index: number, direction: Direction) => void,
    toggleHide: (row: DashboardRow) => void,
    hiddenTable?: boolean,
    saveRow: (row: DashboardRow) => void,
    editTarget: (row: DashboardRow, targetIndex: number, targetKpi: string) => void,
    moveKpi: (index: number, direction: Direction.LEFT | Direction.RIGHT) => void,
}
let KpiTable = ({hiddenTable = false, ...props}: KpiTableProps) => {

    const total = useMemo(() => getKpiTotalsNew(props.dashboard.table_data, props.parsedDashboard.kpis, props.parsedDashboard.main_row), [props.dashboard, props.parsedDashboard])


    const getDisplayValue = (value: number, kpi: DashboardKPI, kpiNumber: number) => {
        let tmpValue = kpi.percent ? value * 100 / total[kpiNumber] : value
        let tmp = formatValue(true, tmpValue, kpi.decimal)
        if (kpi.percent) {
            return `${tmp}%`
        }
        return tmp
    }

    const moveRow = (e: React.MouseEvent<any>, index: number, direction: Direction) => {
        e.preventDefault()
        e.stopPropagation()

        if (direction == Direction.UP) {
            if (index == 0) return
        } else {
            if (index == props.rows.length - 1) return
        }

        props.moveRow(index, direction)
    }

    let getReportDescription = (report: LibraryReport) => {
        let reportFilters: QueryCriterion[] = JSON.parse(report.report.filter)
        let columns: string[] = JSON.parse(report.report.columns)
        return (
            <div>
                <ReportDescription description={report.report.description} filterDescription={getDataDescriptionDialogDescription("Insight")(report.report.limit, report.report.sort_direction, report.report.sort_column, reportFilters.length)} filter={reportFilters} showColumnDescription={false} title={"About this Insight"} columns={columns} columnDescription={report.info.setup.descriptions} />
            </div>
        )
        
    }

    let getTableRowReport = (report: DashboardReportRow, key: any, row: DashboardRow, index: number, classes?: string) => {
        let tmp = props.reports.find(v => v.report.report_id == report.report_id)
        if (tmp == undefined) return <></>
        let r: DashboardReport = {
            report_id: tmp.report.report_id,
            label: report.label,
            name: tmp.report.name,
            is_public: tmp.report.is_public,
            user_id: tmp.report.user_id,
        }

        let olderThan24Hours = (((new Date()).getTime() / 1000) - tmp.info.last_update) > 86_400

        const last = index == props.rows.length - 1

        return <tr key={key} className={`kpi-tr ${classes ?? ''}`}>
            <td className={`kpi-td abc-click ${last ? 'last' : ''}`} title='Edit Insight' onClick={() => {if(key != "main") {props.editReport({report: r, index})} else {props.editReport({report: r, index})}}}>
                <ShowIf if={r.is_public}>
                    <i className="fa fa-file fa-fw d-report-icon" aria-hidden="true" title='Public Insight'></i>
                </ShowIf>
                <ShowIf if={!r.is_public}>
                    {
                        r.user_id === props.user.user_id ?
                            <i className="fa fa-unlock-alt fa-fw d-report-icon" aria-hidden="true" title='Your private Insight'></i>
                            :
                            <i className="fa fa-lock fa-fw d-report-icon" aria-hidden="true" title='Other persons private Insight'></i>
                    }
                </ShowIf>
                <ShowIf if={key != "main"}>
                    <ShowIf if={!row.hidden}>
                        <i title="Move row up" onClick={(e) => moveRow(e, index, Direction.UP)} className={`fa fa-angle-up fa-lg d-button ${index == 0 ? 'd-button-disabled' : ''}`} aria-hidden="true"></i>
                        <i title="Move row down" onClick={(e) => moveRow(e, index, Direction.DOWN)} className={`fa fa-angle-down fa-lg d-button ${index == props.rows.length - 1 ? 'd-button-disabled' : ''}`} style={{marginRight: 10}} aria-hidden="true"></i>
                    </ShowIf>
                    <ShowIf if={row.hidden}>
                        <i title="Show row" style={{marginRight:"10.4px", marginLeft:"10.4px"}}onClick={(e) => {e.stopPropagation(); props.toggleHide(row)}} className="fa fa-eye fa-fw d-button" ></i>
                    </ShowIf>
                </ShowIf>
                <ShowIf if={key == "main"}>
                    <div style={{display: "inline-flex", width: 35.8}} />
                </ShowIf>
                <div title={report.label != "" ? report.label : r.name} style={{display:"inline-block", verticalAlign:"middle", width:"240px", overflow: "hidden", textOverflow:"ellipsis"}} >{report.label != "" ? report.label : r.name} </div>
                &nbsp;
                        <i data-tip data-for={`tooltip-${key}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                        <ReactTooltip id={`tooltip-${key}`} effect='solid' place='bottom' className='react-tooltip-extra'>
                            <p style={{textDecoration: `underline`, fontWeight: "normal"}} className={`margin-top-0 ${olderThan24Hours ? 'library-item-red' : ''}`}>Data updated:&nbsp; 
                            <span title="">{fromNow(tmp.info.last_update)}</span>
                            </p>
                            <div style={{maxWidth:800, whiteSpace:"normal", fontWeight: "normal"}}>
                            {getReportDescription(tmp)}
                            </div>
                        </ReactTooltip>
                    </td>
                    <td className={`kpi-td ${last ? 'last' : ''}`} >
                        <button className='btn btn-sm btn-default dropdown-toggle dropdown-toggle-split' data-toggle="dropdown" />
                        <div className='dropdown-menu'>
                            <button className='dropdown-item' onClick={() => {
                                if (key == "main") {
                                    props.editReport({report: r, index: -1})
                                } else {
                                    props.editReport({report: r, index: index})
                                }
                            }}>Edit</button>
                            <button className='dropdown-item' onClick={() => props.toggleHide(row)}>{row.hidden ? "Show" : "Hide"} row</button>
                    {/* <ShowIf if={props.user.is_consultant}>
                         <button className='dropdown-item' onClick={() => console.log(row)}>Debug</button>
                    </ShowIf>   */}
                            <HideIf if={key == "main"}>
                                <div className="dropdown-divider"></div>
                                <button className='dropdown-item' style={{color: "var(--danger)"}} onClick={() => props.deleteRow(row)}>Delete</button>
                            </HideIf>
                        </div>
                    </td>
            {
                props.parsedDashboard.kpis.map((kpi, i) => {
                    let data = props.dashboard.table_data[kpi.kpi_key]?.[r.report_id]
                    return (
                        <td className={`kpi-td text-align-right ${last ? 'last' : ''}`} key={`td${i}`}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <EditTargetButton 
                                row={row}
                                kpiKey={kpi.kpi_key}
                                parsedDashboard={props.parsedDashboard}
                                saveRow={props.saveRow}
                                editTarget={props.editTarget}
                            /> 
                            <span>
                            {
                                data != null ?
                                    getDisplayValue(data.last, kpi, i)
                                    :
                                    "N/A"
                            }
                            </span>
                        </div>
                        </td>
                    )
                })
            }

        </tr>
    }

    let getFilterRowDescription = (filters: QueryCriterion[], main_report: LibraryReport) => {
        return <ReportDescription description={""}
        mainReportName={main_report.report.name}
         filter={filters} showColumnDescription={false} title={"About this Filter"} filterDescription='' columns={[""]} columnDescription={{}} />

    }

    let getTableRowFilter = (f: DashboardQuery, key: any, row: DashboardRow, index: number, main_report: DashboardReportRow, classes?: string) => {
        const last = index == props.rows.length - 1

        let tmp = props.reports.find(v => v.report.report_id == main_report.report_id)
        if (tmp == undefined) return <></>
       
        let olderThan24Hours = (((new Date()).getTime() / 1000) - tmp.info.last_update) > 86_400

        return <tr key={key} className={`kpi-tr ${classes ?? ''}`}>
            <td className={`kpi-td abc-click ${last ? 'last' : ''}`} style={{maxWidth:"400px"}}onClick={() => props.editFilter(f)}>
                <i className="fa fa-filter fa-fw d-report-icon" aria-hidden="true" title='Filter'></i>
                <ShowIf if={!row.hidden}>
                    <i title="Move row up" onClick={(e) => moveRow(e, index, Direction.UP)} className={`fa fa-angle-up fa-lg d-button ${index == 0 ? 'd-button-disabled' : ''}`} aria-hidden="true"></i>
                    <i title="Move row down" onClick={(e) => moveRow(e, index, Direction.DOWN)} className={`fa fa-angle-down fa-lg d-button ${index == props.rows.length - 1 ? 'd-button-disabled' : ''}`} style={{marginRight: 10}} aria-hidden="true"></i>
                </ShowIf>
                <ShowIf if={row.hidden}>
                    <i title="Show row" style={{marginLeft:"10.4px", marginRight:"10.4px"}} onClick={(e) => {e.stopPropagation(); props.toggleHide(row)}} className="fa fa-eye fa-fw d-button" ></i>
                </ShowIf>
                <div title={f.label} style={{display:"inline-block", verticalAlign:"middle", width:"240px", overflow: "hidden", textOverflow:"ellipsis"}} >{f.label} </div>
                &nbsp;
                <i data-tip data-for={`tooltip-${key}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                <ReactTooltip id={`tooltip-${key}`} effect='solid' place='bottom' className='react-tooltip-extra'>
                    <p style={{textDecoration: `underline`}} className={`margin-top-0 ${olderThan24Hours ? 'library-item-red' : ''}`}>Data updated:&nbsp; 
                    <span title="">{fromNow(tmp.info.last_update)}</span>
                    </p>
                    <div style={{maxWidth:800, whiteSpace:"normal"}}>
                    {getFilterRowDescription(f.filter, tmp)}
                    </div>
                </ReactTooltip>
            </td>
            <td className={`kpi-td ${last ? 'last' : ''}`}>
                <button className='btn btn-sm btn-default dropdown-toggle dropdown-toggle-split' data-toggle="dropdown" />
                <div className='dropdown-menu'>
                    <button className='dropdown-item' onClick={() => props.editFilter(f)}>Edit</button>
                    <button className='dropdown-item' onClick={() => props.toggleHide(row)}>{row.hidden ? "Show" : "Hide"} row</button>
                    <button className='dropdown-item' onClick={() => props.copyFilter(f)}>Copy</button>
                    {/* <ShowIf if={props.user.is_consultant}>
                        <button className='dropdown-item' onClick={() => console.log(row)}>Debug</button>
                    </ShowIf> */}
                    <div className="dropdown-divider"></div>
                    <button className='dropdown-item' style={{color: "var(--danger)"}} onClick={() => props.deleteRow(row)}>Delete</button>
                </div>
            </td>
            {
                props.parsedDashboard.kpis.map((kpi, i) => {
                    //@ts-ignore
                    let data = props.dashboard.table_data[kpi.kpi_key]?.[f.id]
                    return (
                        <td className={`kpi-td text-align-right ${last ? 'last': ''}`} key={`td${i}`}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <EditTargetButton 
                                row={row}
                                kpiKey={kpi.kpi_key}
                                parsedDashboard={props.parsedDashboard}
                                saveRow={props.saveRow}
                                editTarget={props.editTarget}
                            /> 
                            <span>
                            {
                                data != null ?
                                    getDisplayValue(data.last, kpi, i)
                                    :
                                    "N/A"
                            }
                            </span>

                        </div>
                        </td>
                    )
                })
            }
        </tr>
    }

    return (
        <table className='kpi-table'>
            <thead className='kpi-thead'>
                <tr className='kpi-tr'>
                    <th className='kpi-th' style={{maxWidth:"350px", minWidth:"350px"}}></th>
                    <th className='kpi-th'></th>
                    {
                        props.parsedDashboard.kpis.map((kpi, i) => {
                            return <th className='kpi-th text-align-right' key={i}>
                                    <i title="Move column left" onClick={() => props.moveKpi(i, Direction.LEFT)} className={`fa fa-angle-left fa-lg d-button d-column-button ${i == 0 ? 'd-button-disabled' : 'abc-click'}`} aria-hidden="true"></i>
                                    <i title="Move column right" onClick={() => props.moveKpi(i, Direction.RIGHT)} className={`fa fa-angle-right fa-lg d-button d-column-button ${i == props.parsedDashboard.kpis.length - 1 ? 'd-button-disabled' : 'abc-click'}`} aria-hidden="true"></i>
                                    <button className='btn btn-sm btn-default' style={{border: "none", borderTopRightRadius: 0, borderBottomRightRadius: 0}} title='Edit column' onClick={() => props.editKpi(kpi)}>
                                        {kpi.label != "" ? kpi.label : kpi.column}
                                    </button>
                                    <button className='btn btn-sm btn-default dropdown-toggle dropdown-toggle-split' data-toggle="dropdown" />
                                    <div className='dropdown-menu'>
                                        <button className='dropdown-item' onClick={() => props.editKpi(kpi)}>Edit</button>
                                        <button className='dropdown-item' onClick={() => props.copyKpi(kpi)}>Copy</button>
                                        <button className='dropdown-item' onClick={() => props.deleteKpi(kpi)}>Delete</button>
                                    {/* <ShowIf if={props.user.is_consultant}>
                                        <button className='dropdown-item' onClick={() => console.log(kpi)}>Debug</button>
                                    </ShowIf> */}

                                    </div>
                            </th>
                        })
                    }
                    <ShowIf if={!hiddenTable}>
                        <th >
                            <div onClick={props.addKpi} className="kpi-table-add abc-click">
                                <i className="fa fa-plus report-icon fa-fw" style={{marginRight: 5}} aria-hidden="true"></i>
                                Add column
                            </div>
                        </th>
                    </ShowIf>
                </tr>
            </thead>
            <tbody className='kpi-tbody'>
                
                <ShowIf if={hiddenTable && props.parsedDashboard.main_row.hidden}>
                    {getTableRowReport(props.parsedDashboard.main_row.row as DashboardReportRow, "main", props.parsedDashboard.main_row, -1, "bold")}
                </ShowIf>
                <ShowIf if={!hiddenTable && !props.parsedDashboard.main_row.hidden}>
                    {getTableRowReport(props.parsedDashboard.main_row.row as DashboardReportRow, "main", props.parsedDashboard.main_row, -1, "bold")}
                </ShowIf>


                {props.rows.map((v, i) => {
                    switch (v.type) {
                        case RowType.Report:
                            return getTableRowReport(v.row as DashboardReportRow, hiddenTable ? "H" + i: "" + i, v, i)
                        case RowType.Filter:
                            return getTableRowFilter(v.row as DashboardQuery, hiddenTable ? "H" + i: "" + i, v, i, props.parsedDashboard.main_row.row as DashboardReportRow)
                        default:
                            return null
                    }
                })}

            <ShowIf if={!hiddenTable}>
                <tr style={{backgroundColor: "transparent"}}>
                    <td className="" >
                        <div className='dropdown'>
                            <div className="kpi-table-add abc-click" data-toggle="dropdown">
                                <i className="fa fa-plus report-icon fa-fw" aria-hidden="true"></i>
                                Add row
                            </div>
                            <div className='dropdown-menu' aria-labelledby='addRowButton'>
                                <button onClick={props.addMultipleFilterRows} disabled={props.parsedDashboard.main_row == null} className='dropdown-item'>Multiple filter rows</button>
                                <button onClick={props.addFilterRow} disabled={props.parsedDashboard.main_row == null} className='dropdown-item'>Filter row</button>
                                <button onClick={props.addReportRow} className='dropdown-item'>Insight row</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ShowIf>

            </tbody>
        </table>
    )
}

interface EditTargetButtonProps {
    row: DashboardRow,
    kpiKey: string,
    parsedDashboard: ParsedDashboardData,
    saveRow: (row: DashboardRow) => void,
    editTarget: (row: DashboardRow, targetIndex: number, targetKpi: string) => void,
    className?: string,
}

type TargetTmpValue = string | undefined

// Does support multiple targets, but does not allow the creation of new multiple targets
const EditTargetButton = (props: EditTargetButtonProps) => {
    const [showPopover, setShowPopover] = useState(false)

    const [tmpTargets, setTmpTargets] = useState<DashboardTargetNew[]>([])
    const [tmpValues, setTmpValues] = useState<TargetTmpValue[]>([])

    const valueFieldRef = useRef<any>()

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, targetIndex: number) => {
        const regex = /^-?\d+\.?\d*$/gm;
        if (regex.test(e.target.value) || e.target.value == '') {
            let tmp = tmpValues.slice()
            tmp[targetIndex] = e.target.value
            setTmpValues(tmp)
        }
    }

    useEffect(() => {
        let tmp = props.row.targets[props.kpiKey] ?? []
        if (tmp.length == 0) {
            tmp.push(getDefaultTarget())
        }
        setTmpTargets(tmp)
        let tmpVals = tmp.map(v => {
            if (!v.enabled) return ''
            if (v.is_kpi_target) {
                let kpi = props.parsedDashboard.kpis.find(v => v.kpi_key == props.kpiKey)
                if (kpi == undefined) return 'KPI not found'
                return kpi.label != '' ? kpi.label : kpi.column
            } else {
                return `${v.value}`
            }
        })
        setTmpValues(tmpVals)

    }, [props.row, props.kpiKey])

    const onPopoverClose = () => {
        let newTargets: DashboardTargetNew[] = []
        for (let i = 0; i < tmpTargets.length; i++) {
            let tmp = tmpTargets[i]
            if(tmpValues[i] == undefined || tmpValues[i] == '' ) {
                // If no target has been set we don't add it
                continue
            } else {
                if (!tmp.is_kpi_target) {
                    tmp.enabled = true
                    tmp.value = Number(tmpValues[i])
                }
                newTargets.push(tmp)
            }
        }
        let tmp: DashboardRow = structuredClone(props.row)
        if (newTargets.length > 0) {
            tmp.targets[props.kpiKey] = newTargets
        } else {
            delete tmp.targets[props.kpiKey]
        }
        props.saveRow(tmp)
        setShowPopover(false)
        return tmp
    }

    const onEditTarget = (index: number) => {
        let tmp = onPopoverClose()
        props.editTarget(tmp, index, props.kpiKey)
    }

    const clearTarget = (index: number) => {
        let tmp = tmpTargets.slice()
        let tmpVals = tmpValues.slice()
        tmp.splice(index, 1)
        tmpVals.splice(index, 1)
        if (tmp.length == 0) {
            tmp.push(getDefaultTarget())
            tmpVals.push("")
        }
        setTmpTargets(tmp)
        setTmpValues(tmpVals)
    }


    const showTarget = () => {
        setShowPopover(true)
        
        if (valueFieldRef.current) {
            valueFieldRef.current.focus()
        }
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If the enter key has been pressed, save the target
        if (e.charCode == 13) {
            onPopoverClose()
        }
    }


    const targetStyle = tmpTargets.length > 0 && tmpTargets[0].enabled ? {color: "black"} : {}
    const className = props.className ?? ""

    return <div>
        <i className={`fa fa-dot-circle-o widget-setting widget-delete-lg fa-lg target-transparent ${className}`} style={targetStyle} onClick={showTarget} aria-hidden="true" title='Select target'></i>
        <PopOver style={{textAlign: "left"}} show={showPopover} onClose={onPopoverClose}>
        <ShowIf if={tmpTargets.length > 0}>
            {
                tmpTargets.map((t, i) => {
                    let disablePopoverInput = t.is_kpi_target && t.enabled
                    return <div key={i}>
                    <label htmlFor="widget-value">
                        Set target value: 
                    </label>
                    <div className="d-flex align-items-center">
                        <input id="widget-value" onKeyPress={onKeyPress} type="text" disabled={disablePopoverInput} className="form-control" placeholder="Insert number" pattern='^-?\d+\.?\d*$' value={tmpValues[i] ?? ''} onChange={e => onValueChange(e, i)} />
                        <i style={{marginLeft: 16}} title="Save" className="fa fa-check widget-setting fa-lg" aria-hidden="true" onClick={onPopoverClose}></i>
                        <i style={tmpValues[i] == '' ? {color: "#c4c4c4", pointerEvents: "none", marginLeft: 16} : {marginLeft: 16}} title="Remove target" className="fa fa-times widget-setting fa-lg" aria-hidden="true" onClick={() => clearTarget(i)}></i>
                        <button className='btn btn-sm btn-default ml-2 d-flex justify-content-center align-items-center' style={{width: 35, height: 35, border: "none"}} title='Advanced settings...' onClick={() => onEditTarget(i)}>
                            <img className="" style={{width: 20, height: 20}} src="./img/PopUpIcon.svg" alt="" />
                        </button>
                    </div>
                    </div>
                })
            }
        </ShowIf>
        </PopOver>
    </div>

}
