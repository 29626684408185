import { RefObject } from 'react'
import Notifier from '../components/Notifications/Notifier'


class NotificationManager {
    static ref:RefObject<Notifier>|null

    static setInstanceRef(r:RefObject<Notifier>) {
        NotificationManager.ref = r
    }

    static getInstanceRef() {
        return NotificationManager.ref
    }

    static notify(message:string, type:string, errInfo?:{[key:string]:any}, sendErrorReport = false, notifyOnce = false) {
        let n = {message: message, type: type, info: errInfo, sendErrorReport: sendErrorReport}
        const notificationInStack = !!NotificationManager.ref?.current?.state.notifications.find((nt:any) => nt.message === n.message)
        if(notifyOnce && notificationInStack){
            return;
        }
        return NotificationManager.ref?.current?.addNotification(n)
    }

    static removeNotification(id:number) {
        NotificationManager.ref?.current?.removeNotification(id)
    }
}
NotificationManager.ref = null

export const setInstanceRef = (ref:RefObject<Notifier>) => {
    NotificationManager.setInstanceRef(ref)
}

export const notifySuccess = (msg:string) => {
    NotificationManager.notify(msg, "success")
}

export const notifyFailure = (msg:string, errInfo:any, sendErrorReport = false, notifyOnce = false) => {
    NotificationManager.notify(msg, "failure", errInfo, sendErrorReport, notifyOnce)
}