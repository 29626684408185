import React from 'react'
import ShowIf from './Generic/ShowIf'
import moment from 'moment'
import WordWrap from './Generic/WordWrap'
import { matchPath } from 'react-router'
import { showMasterDataDialog } from '../helpers/MasterDataManager'
import { itemActionPath } from '../helpers/PathHelpers'

class NotificationItem extends React.Component {    
    onClick = (n) => {
        if(!n.read) this.props.readNotification(n.id)
        if(this.hasURL(n) && n.link.includes("download")) {
            this.props.onClose()
        } else if(this.hasAction(n)) {
            this.doAction(n)
        }
    }

    hasURL = n => {
        return n.link && (n.link.startsWith("http://") || n.link.startsWith("https://"))
    }

    hasExternalUrl = n => {
        return n.link && (!n.link.includes(window.location.host) || n.link.includes("download"))
    }

    hasAction = n => {
        return n.link && n.link.includes("abc://")
    }

    doAction = n => {
        var path = n.link.replace("abc://","")
        var itemMatch = matchPath(path, {path: itemActionPath})
        if(itemMatch) {
            showMasterDataDialog(itemMatch.params.bucketId, itemMatch.params.itemId)
        }
    }

    render() {
        let n = this.props.notification
        let element = (
            <div className={`notifications-item ${n.read ? "read" : "unread"}`} onClick={() => this.onClick(n)}>
                <ShowIf if={!n.read}>
                    <i className="fa fa-circle read-button" title="Mark as read" onClick={e => {e.preventDefault(); this.props.readNotification(n.id)}}/>
                </ShowIf>
                <ShowIf if={n.read}>
                    <i className="fa fa-circle-o read-button" title="Mark as unread" onClick={e => {e.preventDefault(); this.props.unreadNotification(n.id)}}/>
                </ShowIf>
                <div className="notifications-item-message">
                    <WordWrap>
                        {n.message}
                    </WordWrap>                    
                </div>
                <div className="notifications-item-description">
                    <ShowIf if={(n.full_description || this.props.forceFullDescription) && n.description}>
                        {n.description}
                    </ShowIf>
                    <ShowIf if={!n.full_description && !this.props.forceFullDescription && n.description}>
                        <WordWrap>
                            {n.description}
                        </WordWrap>
                    </ShowIf>
                </div>
                <div className="notifications-item-time">{moment.unix(n.notify_time).fromNow()}</div>
            </div>
        )
        return this.hasURL(n) ? <a href={n.link} target={this.hasExternalUrl(n) ? "_blank" : "_self" } rel="noreferrer">{element}</a> : element
    }
}

export default NotificationItem
