import React, { useState, useEffect } from "react"

import { sendWelcomeMail, disableUser2FA } from "../../actions/UserActions"
import { alertTypes } from "../Alert/AlertBanner"
import ShowIf from "../Generic/ShowIf"
import Select from '../Generic/CustomSelect'
import { formatDate } from "../../helpers/TimeHelpers"
import { isUnactivatedUser } from "../../helpers/UserHelpers"
import Creatable from "../Generic/CustomCreatable"

export default function OrganizationAdministrationEditInfo(props) {
    const [updatedUser, setUpdatedUser] = useState(props.user)
    const [departmentsValid, setDepartmentsValid] = useState(!!props.user.department ? true : false)

    useEffect(() => {
        props.userInfoChangedHandler(updatedUser)
    }, [updatedUser])

    function formFieldChangeHandler(value, field) {
        if (field === "department" && !value) {
            setDepartmentsValid(false)
        } else if (field === "department" && !!value) {
            setDepartmentsValid(true)
        }

        let user = !!updatedUser ? { ...updatedUser } : { ...props.user }

        user[field] = value
        setUpdatedUser(user)
    }

    return (
        <div>
            <div>
                <form className={`edit-user-information-form ${props.validated ? "was-validated" : "needs-validation"} `} noValidate>
                    {props.mode === "create" && (
                        <label>
                            <span>Email</span>
                            <input type="email" className="org-admin-text-input" defaultValue={props.user.email} onChange={(e) => formFieldChangeHandler(e.target.value, "email")} required />
                            <div className="invalid-feedback">Please provide an email</div>
                        </label>
                    )}
                    <div className="edit-user-information-row">
                        <label>
                            <span>Firstname</span>
                            <input type="text" className="org-admin-text-input" defaultValue={props.user.firstname} onChange={(e) => formFieldChangeHandler(e.target.value, "firstname")} required />
                            <div className="invalid-feedback">Please provide a first name</div>
                        </label>
                        <label>
                            <span>Lastname</span>
                            <input type="text" className="org-admin-text-input" defaultValue={props.user.lastname} onChange={(e) => formFieldChangeHandler(e.target.value, "lastname")} required />
                            <div className="invalid-feedback">Please provide a last name</div>
                        </label>
                    </div>
                    <div className="edit-user-information-row">
                        <label>
                            <span>Phone number</span>
                            <input type="tel" className="org-admin-text-input" pattern="^\+?\d{1,3}[\s\-]?\d{3,14}$" defaultValue={props.user.phone_number} onChange={(e) => formFieldChangeHandler(e.target.value, "phone_number")}/>
                            <div className="invalid-feedback">Please provide a valid phone number</div>
                        </label>
                        <div className="edit-user-information-radios">
                            <span>Language</span>
                            <div className="">
                                <label>
                                    <input type="radio" name="edit-user-language" defaultChecked={props.user.language === "English" ? true : false} onChange={(e) => formFieldChangeHandler("English", "language")} required />
                                    <span>English</span>
                                </label>
                                <label>
                                    <input type="radio" name="edit-user-language" defaultChecked={props.user.language === "Danish" ? true : false} onChange={(e) => formFieldChangeHandler("Danish", "language")} required />
                                    <span>Danish</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="edit-user-information-row">
                        <label>
                            <span>Job Title</span>
                            <input type="text" className="org-admin-text-input" defaultValue={props.user.job_title} onChange={(e) => formFieldChangeHandler(e.target.value, "job_title")} required />
                            <div className="invalid-feedback">Please provide a job title</div>
                        </label>
                    </div>
                    <div className="edit-user-information-row">
                        <label>
                            <span>Department</span>
                            <Select
                                aria-errormessage="departments-error"
                                placeholder="Select department"
                                defaultValue={props.user.department ? { label: props.user.department, value: props.user.department } : null}
                                options={props.departments}
                                required
                                aria-invalid={true}
                                onChange={(e) => {
                                    formFieldChangeHandler(e.value, "department")
                                }}
                            />
                            {props.validated === true && departmentsValid === false && (
                                <small id="departments-error" className="text-danger fs-8" aria-invalid>
                                    Please choose a department
                                </small>
                            )}
                        </label>
                         <ShowIf if={props.locationFieldSettings.location_field_enabled}>
                            <label>
                                <span>Location</span>
                                <Creatable
                                    defaultValue={props.user.location ? {label: props.user.location, value: props.user.location} : null}
                                    options={props.locations}
                                    required={props.locationFieldSettings.location_field_mandatory}
                                    onChange={(e, actionType) => {
                                        if(e != null){
                                            formFieldChangeHandler(e.value, "location")
                                        }
                                        if(actionType.action === "clear"){
                                            formFieldChangeHandler("", "location")
                                        }
                                    }}
                                    isClearable={true}
                                    
                                    backspaceRemovesValue={true}
                                    placeholder="Enter location"
                                />
                                <div className="invalid-feedback">Please provide a Location</div>
                            </label>
                        </ShowIf>   
                    </div>
                </form>
            </div>
            {props.mode === "edit" && (
                <div className="d-flex flex-column justify-content-center align-items-baseline">
                    <button
                        className="btn btn-outline-primary mb-3"
                        onClick={
                            !props.user.last_welcome_mail?.send_time
                                ? () => props.dispatch(sendWelcomeMail(props.user.id, props.user.email))
                                : () =>
                                      props.setAlertConfig({
                                          type: alertTypes.WARNING,
                                          title: "User has already received this mail before on the " + formatDate(props.user.last_welcome_mail?.send_time*1000),
                                          text: "Do you want to send it anyway?",
                                          customButtons: (
                                              <>
                                                  <button className="btn btn-outline-primary mr-2" onClick={() => props.setAlertConfig(null)}>
                                                      Cancel
                                                  </button>
                                                  <button
                                                      className="btn btn-primary"
                                                      onClick={() => {
                                                          props.dispatch(sendWelcomeMail(props.user.id, props.user.email))
                                                          props.setAlertConfig(null)
                                                      }}
                                                  >
                                                      Send anyway
                                                  </button>
                                              </>
                                          ),
                                      })
                        }
                    >
                        Send welcome mail
                    </button>
                    <ShowIf if={props.user.received_welcome_mail && props.user.last_welcome_mail?.send_time} >
                        <span className="mb-3">
                            Last welcome mail received on the {formatDate(props.user.last_welcome_mail?.send_time*1000)}
                        </span>
                    </ShowIf>
                    <ShowIf if={isUnactivatedUser(props.user)} >
                        <span className="mb-3">
                            {props.user.firstname + " " + props.user.lastname + " has not yet received a welcome mail."}
                            &nbsp; <i title={props.user.firstname + " " + props.user.lastname + " has not yet received a welcome mail."} className="fa fa-exclamation-triangle icon-orange" aria-hidden="true"></i>
                        </span>
                    </ShowIf>
                    <button className="btn btn-outline-danger mb-3" onClick={() => props.disableUser(props.user.id, true)}>
                        Disable user
                    </button>
                    <ShowIf if={props.user.token != ""}>
                    <button className="btn btn-outline-primary" onClick={() => props.dispatch(disableUser2FA(props.user.id))}>
                        Reset 2FA
                    </button>
                    </ShowIf>
                </div>
            )}
        </div>
    )
}
