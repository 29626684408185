import React from 'react'
import BaseDialog from './BaseDialog'
import { copyToClipboard } from '../../helpers/GeneralHelpers'
import { notifySuccess } from '../../helpers/NotificationManager'

class LinkDialog extends BaseDialog {
    state = {
        link: ''
    }

    show(link) {
        this.setState({link: link})
        super.show()
    }

    onFocus(e) {
        e.target.select()
    }

    copyToClipboard = () => {
        copyToClipboard(this.state.link)
        notifySuccess("Link copied to clipboard")
        this.hide()
    }

    getButtons() {
        return (
            <div>
                <button type="button" className="btn btn-default margin-right-10px" onClick={this.hide}>Close</button>
				<button type="button" className="btn btn-primary margin-right-10px" onClick={this.copyToClipboard}>Copy to clipboard</button>
                
			</div>
        )
    }

    getContent() {
        return (
            <React.Fragment>
                <p>
                    {this.props.description}
                </p>
                <input type="text"className="form-control" value={this.state.link} onFocus={(e) => this.onFocus(e)} autoFocus readOnly/>
            </React.Fragment>
        )
    }
}

export default LinkDialog