import moment from 'moment'

export const fromNow = (time:number):string => {
    return moment.unix(time).fromNow()
}

export const ItemTooOld = (time:number):boolean => {
    var duration = moment.duration(moment().diff(moment.unix(time)));
    var hours = duration.asHours()
    return hours > 24
}

export const formatDate = (dateString:string):string => {
    let date = moment(dateString)
    return date.format('DD MMM YYYY')
}

export const formatDateTime =(time:number):string => {
    let date = moment.unix(time)

    if(ItemTooOld(time)) {
        return date.format("DD MMM YYYY, HH:mm:ss")
    }
    else {
        return date.format("HH:mm:ss")
    }
}


export const formatUnix = (time:number):string => {
    let date = moment.unix(time)
    return date.format('DD MMM YYYY')
}

export const isToday = (time:number):boolean => {
    return moment.unix(time).isSame(new Date(), "day");
}