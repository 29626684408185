/* REACT */
import React from 'react'
import { connect } from 'react-redux'

import DataTab from './ComposeComponents/DataTab'
import { notifyFailure } from '../../helpers/NotificationManager'
import ShowIf from '../Generic/ShowIf'
import Drawer from '../Animation/Drawer'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.reportInfo,
        jumpToCompose: state.Report.jumpToCompose,
    }
}

class Compose extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            default_options: ['overview', 'grid', 'kpis', 'sum', 'trend'],
            show: false,
        }
    }

    componentDidMount() {
        if (this.props.newReport) {
           this.setState({show: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let viewSettings = this.getReportData("view_settings")
        let settings = viewSettings.settings ? viewSettings.settings : {}
        let defaultView = settings.default_view

        let overview = (settings.overview === undefined || settings.overview)
        let grid = (settings.grid === undefined || settings.grid)
        let kpis = (settings.kpis === undefined || settings.kpis)
        let sum = (settings.sum === undefined || settings.sum)
        let trend = (settings.trend === undefined || settings.trend)
        let profit = (settings.profit === undefined || settings.profit)
        let allViewsDisabled = (!overview && !grid && !kpis && !sum && !trend && !profit)

        if (defaultView === '') {
            const { default_options } = this.state
            const optionsArr = [overview, grid, kpis, trend]
            let dv = ''
            if (!allViewsDisabled) {
                for (let i = 0; i < optionsArr.length; i++) {
                    const element = optionsArr[i];
                    if (element) {
                        dv = default_options[i]
                    }
                }
                if (dv !== '') {
                    settings.default_view = dv
                    viewSettings.settings = settings
                    this.setReportData("view_settings", JSON.stringify(viewSettings))
                }
            }
        }

        if (allViewsDisabled && !this.state.disableSave) {
            this.disableSave(true)
            notifyFailure("You have to enable at least one view")
        }

        const report_kpis = this.getReportData("kpis")
        if (report_kpis.length === 0 && defaultView === 'trend' && !this.state.disableSave && !this.props.newReport) {
            this.disableSave(true)
        } else if (report_kpis.length === 0 && defaultView !== 'trend' && this.state.disableSave && !allViewsDisabled) { // should not be entered if user has disabled all views - otherwise infinite loop
            this.disableSave(false)
        }

        if (report_kpis.length > 0 && this.state.disableSave && !allViewsDisabled) { // should not be entered if user has disabled all views - otherwise infinite loop
            this.disableSave(false)
        }
    }

    getReportData = (valueKey, cb) => {
        return this.props.getData("report", valueKey, cb)
    }

    setReportData = (valueKey, value, cb) => {
        return this.props.setData("report", valueKey, value, cb)
    }

    setReport = (report, cb) => {
        return this.props.setReport(report, cb)
    }

    disableSave(bool) {
        this.setState({disableSave: bool})
    }

    setSetting(setting, value) {
        this.setState({ [setting]: value })
    }

    setSettingSpecific(setting, value, vs) {
        this.setState({ [setting]: value, view_settings: vs })
    }

    setTopSetting(settings, save = true) {
        let { sort, dir, lim } = settings
        this.setState({ sort_column: sort, sort_direction: dir, limit: lim }, () => {
            if (undefined === this.props.newReport && save) {
                this.props.save()
            }
        })
    }

    setColumnsKPIs(columns, kpis, vs) {
        this.setState({ reportColumns: columns, kpisArr: kpis, view_settings: vs })
    }

    resetBucketSettings(bucket) {
        this.setState({ bucket: bucket, sort_column: "", sort_direction: "desc" })
    }

    setBucket(bucket) {
        this.setState({ bucket: bucket })
    }

    setDataTabBucket(bucket) {
        this.refs.datatab.setBucket(bucket)
    }

    clickExpand() {
        this.setState({show: !this.state.show})
    }

    showCallback

    show(cb) {
        if(cb && !this.state.show) {
            this.showCallback = () => {
                cb()
                this.showCallback = undefined
            }
        } else if(cb) cb()
        this.setState({show: true})
    }

    hide(cb) {
        if(cb && this.state.show) {
            this.showCallback = () => {
                cb()
                this.showCallback = undefined
            }
        } else if(cb) cb()
        this.setState({show: false})
    }

    render() {
        return (
            <div>
                <Drawer className="bottom-border-black report-settings-compose" expand={this.state.show} onPoseComplete={this.showCallback} >
                    <div className="width-100-p padding-top-10px"><h2>Setup</h2></div>
                    <div className="width-100-p black-border-top">
                        <DataTab
                            ref="datatab"
                            reportdata={this.props.reportdata}
                            setSetting={(setting, value) => this.setSetting(setting, value)}
                            setTopSetting={(settings, save) => this.setTopSetting(settings, save)}
                            setSettingSpecific={(setting, value, vs) => this.setSettingSpecific(setting, value, vs)}
                            show={this.props.show}
                            resetBucketSettings={(bucket) => this.resetBucketSettings(bucket)}
                            setBucket={(bucket) => this.setBucket(bucket)}
                            setData={this.props.setData}
                            getData={this.props.getData}
                            getReportData={this.getReportData}
                            setReportData={this.setReportData}
                            checkReportColumns={() => this.props.checkReportColumns()}
                            newReport={this.props.newReport}
                            setReportInfo={this.props.setReportInfo}
                            promptSetBucket={this.props.promptSetBucket}
                            setReport={this.setReport}
                            promptUnselectColumns={this.props.promptUnselectColumns}
                            save={() =>  this.props.save()}
                            info={this.props.info}
                            disableSave={this.state.disableSave}
                        />
                    </div>
                </Drawer>
                <ShowIf if={this.props.show}>
                    <div className="width-100-p text-align-center">
                        <button className={`btn btn-default btn-sm setup-button black-border-color width-165px ${!this.state.show ? "margin-top-0" : ""}`} onClick={() => this.clickExpand()} >Setup</button>
                    </div>
                </ShowIf>
            </div>
        )
    }
}

Compose = connect(mapStateToProps, null, null, { forwardRef: true })(Compose)

export default Compose