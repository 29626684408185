import GenericDialog from './GenericDialog';
import React, { useEffect, useState } from 'react'
import { copyToClipboard } from '../../helpers/GeneralHelpers'
import { notifySuccess } from '../../helpers/NotificationManager'


export interface GetDownloadLinkDialogProps {
    staticLink: string,
    show: boolean,
    onClose: () => void,

}

let GetDownloadLinkDialog = (props: GetDownloadLinkDialogProps) => {
    
    const [link, setLink] = useState("")
    useEffect(() => {
        if (!props.show){
            return
        }
        setLink(props.staticLink)
    }, [props.show])

    let getButtons = () => {
        return <div>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    return (
        <div >
            <GenericDialog onClose={props.onClose} show={props.show} title={"Get Embedded Link"} getButtons={getButtons} style={{width: "40%"}}>
            <span > Embed Inact Now data in all of your systems </span>
            <div style={{marginTop:"30px", marginLeft:"10px", marginRight:"20px", verticalAlign:"middle", display:"inline-flex"}} >
            <button className='btn btn-default' style={{width:"200px"}} onClick={() => {copyToClipboard(props.staticLink); notifySuccess(`Link was copied to clipboard`)
            }}> 
            <i className='fa fa-link'/> Copy link </button>
            <span style={{wordBreak:"break-all", marginLeft:"50px", display:"flex", verticalAlign:"middle", color:"#ff5a00"}}  className='text-align-center vertical-align-middle'> {link }</span>
            </div>

            </GenericDialog>
        </div>
    )
}

export default GetDownloadLinkDialog