import { packAction, GET_REPORT_TEMPLATES, GOT_REPORT_TEMPLATES, CREATE_REPORT_FROM_TEMPLATE, GET_ACTION_TEMPLATES, GOT_ACTION_TEMPLATES, CREATE_ACTION_FROM_TEMPLATE } from "./ActionTypes"
import { ActionTemplateGroup, ReportTemplateGroup } from "../types/types"

export const getReportTemplates = () => {
    return packAction(GET_REPORT_TEMPLATES, {})
}

export const gotReportTemplates = (groups: ReportTemplateGroup[]) => {
    return packAction(GOT_REPORT_TEMPLATES, groups)
}

export const getActionTemplates = () => {
    return packAction(GET_ACTION_TEMPLATES, {})
}

export const gotActionTemplates = (groups: ActionTemplateGroup[]) => {
    return packAction(GOT_ACTION_TEMPLATES, groups)
}

export interface newReportFromTemplateData {
    name: string,
    template_id: number,
    bucket_id: string,
    private: boolean,
    owner: number,
    group_id: number,
    createNewGroup: boolean
}

export const createReportFromTemplate = (data: newReportFromTemplateData, cb?: (reportId: number) => void, errCb?: () => void) => {
    return packAction(CREATE_REPORT_FROM_TEMPLATE, data, cb, errCb)
}

export interface newActionFromTemplateData{
    name: string;
    template_id: number;
    bucket_id: string;
    private: boolean;
    owner_id: number;
    assignee_id: number;
    org_id: number;
}

export const createActionFromTemplate = (data:newActionFromTemplateData, cb?: (actionId: number) => void, errCb?: () => void) => {
    return packAction(CREATE_ACTION_FROM_TEMPLATE, data, cb, errCb)
}