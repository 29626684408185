/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getIdColumn, formatValue, getShownDecimals, getDiffColor, getTextColumns, getProgressBarJSX, monthValue } from '../../../helpers/ReportHelpers'
import { getTrendsetsData, getDiffData } from '../../../actions/ReportActions'
import BaseDialog from '../../Dialogs/BaseDialog'
import ShowIf from '../../Generic/ShowIf'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import { copyToClipboard } from '../../../helpers/GeneralHelpers'
import { notifySuccess } from '../../../helpers/NotificationManager'
import DataGrid from '../../DataGrid'
import RequirePermission from '../../RequirePermission'
import { is } from '../../../helpers/PermissionHelpers'
import InfiniteScrollOnScreen from '../../../components/Generic/InfiniteScrollOnScreen'

const mapStateToProps = (state, ownProps) => {
    return {
        trendsetsdata: state.Report.trendsetsdata,
        diffdata: state.Report.diffdata,
        users: state.System.users,
    }
}

class DiffView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            limitParameter: 100,
            showItems: 'All',
            hasMoreData: true,
            selectedTrendset: null,
            sortParameter: 4,
            sortDirection: true,
            chosenSortColumn: 5,
            selectedTextColumn: null,
            selectedSecondaryTextColumn: undefined,
            offset: 0,
            currentData: null,
            label1: "",
            label2: "",
            trendset1: "",
            trendset2: "",
            autoFit: [],
            newReport: true
        }
        this.dataGrid = React.createRef()
    }

    defaultState = {
        limitParameter: 100,
        showItems: 'All',
        hasMoreData: true,
        selectedTrendset: null,
        sortParameter: 4,
        sortDirection: true,
        chosenSortColumn: 5,
        selectedTextColumn: null,
        selectedSecondaryTextColumn: undefined,
        offset: 0,
        currentData: null,
        label1: "",
        label2: "",
        trendset1: "",
        trendset2: "",
        autoFit: [],
        newReport: true
    }

    componentDidMount() {
        let { dispatch } = this.props
        let tmpDir = this.state.sortDirection
        if (this.props.last && this.props.sndLast) {
            if (this.props.last - this.props.sndLast > 0) {
                tmpDir = true;
            } else {
                tmpDir = false
            }
        }
        this.setState({ sortDirection: tmpDir })
        dispatch(getTrendsetsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.currentData != prevState.currentData) {
            console.log("Data updated")
            console.log(this.state.currentData)
            console.log(prevState.currentData)
        }

        console.log("new")
        console.log(prevProps.trendsetsdata)
        console.log(this.props.trendsetsdata)

        if(this.props.trendsetsdata.get(this.props.reportdata.report.report_id, []).length == 0){
            this.props.dispatch(getTrendsetsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id))
            return
        }

        let currentData = this.props.diffdata.get(this.props.reportdata.report.report_id, null)
        if (currentData == null) {
            
        }

        let trendsetsdata = this.props.trendsetsdata.get(this.props.reportdata.report.report_id, [])
        if (trendsetsdata.length > 1 && this.state.trendset1 === "" && currentData === null && prevState.trendset1 != trendsetsdata[trendsetsdata.length - 2].dataset_id) {
            this.setState({
                label1: trendsetsdata[trendsetsdata.length - 2].label,
                label2: trendsetsdata[trendsetsdata.length - 1].label,
                trendset1: trendsetsdata[trendsetsdata.length - 2].dataset_id,
                trendset2: trendsetsdata[trendsetsdata.length - 1].dataset_id,
            })
        }
        // all the possible combinations that should make the getDiffData get called
        let s1 = (currentData === null && this.state.trendset1 !== "" && (prevProps.show !== this.props.show && this.props.show)) // this is first load
        let s2 = (prevProps.selectedKpi !== this.props.selectedKpi && this.state.trendset1 !== "" && this.props.selectedKpi !== null) // if selectedKpi is changed
        let s3 = (prevProps.selectedCategory !== this.props.selectedCategory) // if selectedCategory is changed
        let s4 = (currentData &&
            (prevState.sortParameter !== this.state.sortParameter ||  // if any of the many states are changed
                prevState.sortDirection !== this.state.sortDirection ||
                prevState.offset !== this.state.offset ||
                prevState.showItems !== this.state.showItems ||
                prevState.selectedTrendset !== this.state.selectedTrendset ||
                (prevState.selectedTextColumn !== this.state.selectedTextColumn && this.state.selectedTextColumn !== null) ||
                (prevState.selectedSecondaryTextColumn !== this.state.selectedSecondaryTextColumn))
        )
        let s5 = this.state.trendset1 !== prevState.trendset1 || this.state.trendset2 !== prevState.trendset2 //if trendsets changed
        let s6 = JSON.stringify(this.props.reportdata.report.columns) !== JSON.stringify(prevProps.reportdata.report.columns) //report data changed
        let s7 = this.props.reportdata?.report?.report_id != prevProps.reportdata?.report?.report_id
        let s8 = trendsetsdata.length != prevProps.trendsetsdata.get(this.props.reportdata.report.report_id, []).length

        // need to stringify slicers in order to compare - sometimes '[] !== []' will return true
        if ((s1 || s2 || s3 || s4 || s5 || s6 || s7 || s8 || JSON.stringify(prevProps.slicers) !== JSON.stringify(this.props.slicers)) && this.props.selectedKpi && this.props.show) {
            console.log("Change4")
            this.updateData()
        }

        // setting the current data. We have to make sure that data was fetched first
        let diffdata = this.props.diffdata.get(this.props.reportdata.report.report_id, [])
        let prevDiffdata = prevProps.diffdata.get(this.props.reportdata.report.report_id, [])
        if (diffdata.values === null && diffdata !== prevDiffdata) {
            let data = Object.assign({}, diffdata)
            data.values = []
            this.setState({ currentData: data, hasMoreData: false })
        }

        // resetting currentData when leaving diff-view
        if (prevProps.show && !this.props.show) {
            this.setState({ currentData: null, offset: 0, limitParameter: 100, sortParameter: 4, sortDirection: true, chosenSortColumn: 5}, () => {
                //this.setState(this.defaultState)
            })
        }

        if (JSON.stringify(this.props.reportdata.report.columns) !== JSON.stringify(prevProps.reportdata.report.columns)) {
            let columns = getTextColumns(JSON.parse(this.props.reportdata.report.columns), this.props.reportdata.info.model.columns)
            if (this.state.selectedTextColumn && !columns.includes(this.state.selectedTextColumn)) {
                if (columns.length > 0) {
                    this.setTextColumn(columns[0])
                } else {
                    this.setTextColumn(null)
                }
            }
            if (this.state.selectedSecondaryTextColumn && !columns.includes(this.state.selectedSecondaryTextColumn)) {
                this.setSecondaryTextColumn(null)
            }
        }

    }

    deselectMultiple = ids => {
        if (this.dataGrid.current)
            this.dataGrid.current.deselectMultiple(ids)
    }

    getDirection(bool) {
        return this.state.sortDirection === true ? 0 : -1
    }

    setTrendset(t) {
        this.setState({ selectedTrendset: t })
    }

    setTextColumn(c, cb) {
        this.setState({ selectedTextColumn: c }, () => {
            if (this.props.editMode) {
                let columns = JSON.parse(this.props.reportdata.report.columns)
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                const index = columns.indexOf(c)
                if (!viewSettings.trend) viewSettings.trend = {}
                if (!viewSettings.trend.diff) viewSettings.trend.diff = {}
                viewSettings.trend.diff.graph_extra_column = index
                viewSettings.trend.diff.graph_column = c
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings), () => {
                    if (cb) cb()
                })
            } else if (cb) cb()
        })
    }

    setSecondaryTextColumn(c, cb) {
        this.setState({ selectedSecondaryTextColumn: c }, () => {
            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.trend) viewSettings.trend = {}
                if (!viewSettings.trend.diff) viewSettings.trend.diff = {}
                viewSettings.trend.diff.graph_secondary_column = c
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings), () => {
                    if (cb) cb()
                })
            } else if (cb) cb()
        })
    }

    // Use this instead of chain calling, since chain calling has a chance to overwrite one of the columns
    setBothTextColumns(c1, c2, cb) {
        this.setState({ selectedTextColumn: c1, selectedSecondaryTextColumn: c2 }, () => {
            if (this.props.editMode) {
                let columns = JSON.parse(this.props.reportdata.report.columns)
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                const index = columns.indexOf(c1)
                if (!viewSettings.trend) viewSettings.trend = {}
                if (!viewSettings.trend.diff) viewSettings.trend.diff = {}
                viewSettings.trend.diff.graph_extra_column = index
                viewSettings.trend.diff.graph_column = c1
                viewSettings.trend.diff.graph_secondary_column = c2
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings), () => {
                    if (cb) cb()
                })
            }else if (cb) cb()
        } )
    }

    getTextColumn = () => {
        let reportdata = this.props.reportdata
        let columns = reportdata.report.columns ? JSON.parse(reportdata.report.columns) : []

        let res = ""
        if (this.state.selectedTextColumn !== null) {
            res = this.state.selectedTextColumn
        } else {
            const viewSettings = JSON.parse(reportdata.report.view_settings)
            if (!viewSettings.trend) viewSettings.trend = {}
            const editModeColumn = viewSettings.trend.diff ? viewSettings.trend.diff.graph_column : ""
            const defaultBucketColumn = this.props.reportdata.info.setup.id_description_column
            if (editModeColumn && editModeColumn !== "") {
                res = editModeColumn
            } else if (defaultBucketColumn && defaultBucketColumn !== "") {
                res = defaultBucketColumn
            } else {
                res = getTextColumns(columns, reportdata.info.model.columns)[0]
            }
        }
        const index = columns.indexOf(res)
        if (index === -1) { // if we have a column that is no longer in the report because of bucket change
            res = getTextColumns(columns, reportdata.info.model.columns)[0]
        }
        if (res === undefined) res = "-"

        return res
    }

    getSecondaryColumn = () => {
        let reportdata = this.props.reportdata
        if (this.state.selectedSecondaryTextColumn !== undefined) {
            return this.state.selectedSecondaryTextColumn
        } else {
            const viewSettings = JSON.parse(reportdata.report.view_settings)
            if (!viewSettings.trend) viewSettings.trend = {}
            const editModeColumn = viewSettings.trend.diff ? viewSettings.trend.diff.graph_secondary_column : ""
            if (editModeColumn && editModeColumn !== "") {
                return editModeColumn
            }
        }
        return null
    }

    //This function serves as an adapter from the datagrid way of sorting to the legacy diff way of sorting with sortParameter. 
    setSortColumn(column) {
        const idColumn = getIdColumn(this.props.reportdata.info.model.columns)
        const { label2, selectedTrendset } = this.state
        let { label1, selectedTextColumn } = this.state
        selectedTextColumn = selectedTextColumn ? selectedTextColumn : this.getTextColumn()
        const secondarySelectedTextColumn = this.getSecondaryColumn()
        const statusColumn = "Status"
        const diffColumn = "Changes"

        label1 = selectedTrendset ? selectedTrendset.label : label1

        switch (column.name) {
            case idColumn: this.setSort(0, 0); break
            case selectedTextColumn: this.setSort(1, 1); break
            case secondarySelectedTextColumn: this.setSort(6, 6); break
            case statusColumn: this.setSort(5, 2); break
            case label1: this.setSort(2, 3); break
            case label2: this.setSort(3, 4); break
            case diffColumn: this.setSort(4, 5); break
            default: break
        }
    }

    setSort(parameter, column) {
        const selectedTextColumn = this.state.selectedTextColumn ? this.state.selectedTextColumn : this.getTextColumn()
        if ((selectedTextColumn === "-" || selectedTextColumn === null) && (parameter === 1 && column === 1)) return;
        let direction = this.state.sortDirection
        if (this.state.sortParameter === parameter) {
            direction = !direction
        } else {
            direction = true
        }

        // if the user has used pressed 'Load more data'-button and then starts sorting
        let limit = this.state.limitParameter
        let offset = this.state.offset
        if (this.state.offset > 99) {
            limit += this.state.offset
            offset = 0
        }
        this.setState({ sortParameter: parameter, sortDirection: direction, chosenSortColumn: column, limitParameter: limit, offset: offset })
    }

    fetchMoreData = () => {
        this.setState({
        offset: this.state.offset + 100
      })
    };

    setKpiView(kpi) {
        this.props.setKpiView(kpi)
    }

    hideRow(bId, id) {
        this.props.hideRow(bId, id)
        this.dataGrid.current.deselect(id)
    }

    setAutoFitArr(arr) {
        this.setState({ autoFit: arr })
    }

    itemSelection(ids) {
        this.props.setSelectedItems(ids)
    }

    deselectAll = () => {
        this.dataGrid.current.deselectAll()
    }

    updateData = () => {
        let trendsetsdata = this.props.trendsetsdata.get(this.props.reportdata.report.report_id, [])

        let s1 = (this.state.currentData === null && this.state.trendset1 !== "") // this is first load
        let useMvFilter = this.state.showItems !== 'All' ? true : false
        let mvFilter = parseInt(this.state.showItems, 10)
        let textColumn = this.state.selectedTextColumn !== null ? this.state.selectedTextColumn : this.getTextColumn()
        let secondaryTextColumn = this.getSecondaryColumn()
        let { dispatch } = this.props
        let data = {
            dataset_start: this.state.selectedTrendset !== null ? this.state.selectedTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 2].dataset_id,
            dataset_end: trendsetsdata[trendsetsdata.length - 1].dataset_id,
            filter: getCustomFilter(this.props.reportdata.report, this.props.selectedCategory, this.props.reportdata.info.model.categorization_name, this.props.slicers), // using custom filter method
            sort_column: this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction,
            limit: this.props.reportdata.report.limit,
            diff_column: this.props.selectedKpi,
            description_column: textColumn,
            secondary_description_column: secondaryTextColumn ? secondaryTextColumn : "-",

            sort_parameter: this.state.sortParameter,
            sort_parameter_direction: this.getDirection(s1),
            limit_parameter: this.state.limitParameter,
            offset_parameter: this.state.offset,

            use_movement_filter: useMvFilter,
            movement_filter: mvFilter,

            start_limit: -1,
            end_limit: -1,
        }
        if (this.state.trendset2 !== "") {
            console.log(this.props.reportdata)
            console.log(this.state.selectedTrendset !== null ? this.state.selectedTrendset.dataset_id : this.state.trendset1)
            console.log(this.state.trendset2)
            dispatch(getDiffData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, data))
            dispatch(getTrendsetsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, this.props.reportdata.report.report_id))
        }
    }

    render() {
        let diffdata = this.props.diffdata.get(this.props.reportdata.report.report_id, []);
            if (diffdata.length == 0){
                return (<span>No data found</span>)
            }
        let idColumn = getIdColumn(this.props.reportdata.info.model.columns)
        let bucketId = this.props.reportdata.info.id
        let { label1, label2 } = this.state
        let trendsetsdata = this.props.trendsetsdata.get(this.props.reportdata.report.report_id, [])
        let textColumn = this.getTextColumn()
        let kpis = JSON.parse(this.props.reportdata.report.kpis)

        if (this.state.selectedTrendset && this.state.selectedTrendset.label) {
            label1 = this.state.selectedTrendset.label
        }

        let selKpi = this.props.selectedKpi ? this.props.selectedKpi : null
        //let diffdata = this.state.currentData ? this.state.currentData : { values: [] }
        let showDecimals = selKpi !== null ? getShownDecimals(kpis, selKpi) : false
        let maxDiff = diffdata ? diffdata.max_diff : 0
        let textColumns = getTextColumns(JSON.parse(this.props.reportdata.report.columns), this.props.reportdata.info.model.columns)

        let selectedTextColumn = this.state.selectedTextColumn ? this.state.selectedTextColumn : textColumn
        let selectedSecondaryTextColumn = this.getSecondaryColumn()

        let getStatusBox = (status, statusNum) => {
            if (statusNum === -1) {
                return <div className="oldItem" title={"Is no longer on the list"}>GONE</div>
            } else if (statusNum === 1) {
                return <div className="newItem" title={`Wasn't on the list in ${label1}`}>NEW</div>
            }
            return <span></span>
        }

        let copyReportDataToClipboard = () => {
            copyToClipboard(this.dataGrid.current.getDiffHTML(false, true))
            notifySuccess(diffdata.values.length + ' rows has been copied to the clipboard')
        }

        if (this.props.show && selKpi) {
            let dataGridColumns = [
                { name: idColumn, index: 0, type: "id", headerClassName: "width-200-px" },
                { name: selectedTextColumn, index: 1, type: "text", headerClassName: "width-200-px" },
                { name: "Status", index: 2, type: "text", disableAutoFit: true, colgroupClassName: "width-80px", wrapper: getStatusBox },
                { name: label1, index: 3, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px" },
                { name: label2, index: 4, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px" },
                { name: `Changes`, index: 5, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px", isTotal: true },
                { name: "", index: 5, type: "decimal", mergeHeaderWithPrevious: true, wrapper: getProgressBarJSX(maxDiff) },
            ]

            if (selectedSecondaryTextColumn) {
                dataGridColumns.splice(2, 0, { name: selectedSecondaryTextColumn, index: 6, type: "text", headerClassName: "width-200-px" })
            }

            
            if(!diffdata.values[0].hasOwnProperty('is_total')){
                const totalRow = { is_total: true, item_id: diffdata.id_total, description: diffdata.description_total, secondary_description: diffdata.secondary_description_total, start_value: diffdata.start_total, end_value: diffdata.end_total, status: 3, diff: (diffdata.end_total - diffdata.start_total) }
                diffdata.values.unshift(totalRow)
            }

            let dataGridRows = diffdata.values.map(val => {
                return {
                    data: [
                        val.item_id,
                        val.description,
                        val.status,
                        monthValue("start", val),
                        monthValue("end", val),
                        val.diff,
                        val.secondary_description
                    ],
                    id: val.item_id
                }
            })

            return (
                <div className="">
                <button onClick={() => {
                    console.log(this.props)
                }}>Debug</button>
                <button onClick={() => {
                    console.log(this.state)
                }}>Also Debug</button>
                    <ShowIf if={trendsetsdata.length < 2}>
                        <p className="alert alert-danger">
                            Diffview is disabled! You need to have at least two datasets in order to use Trend properly.
                        </p>
                    </ShowIf>
                    <ShowIf if={kpis.length > 0}>
                        <DiffControls
                            label1={label1}
                            label2={label2}
                            totalStartVal={diffdata.start_total}
                            totalEndVal={diffdata.end_total}
                            showDecimals={showDecimals}
                            setShowType={(type) => this.setState({ showItems: type, offset: 0 })}
                            type={this.state.showItems}
                            trendsets={trendsetsdata}
                            setTrendset={(t) => this.setTrendset(t)}
                            setTextColumns={(t, s) => this.setBothTextColumns(t, s, () => {this.updateData()})}
                            columns={textColumns}
                            selectedTextColumn={selectedTextColumn}
                            selectedSecondaryTextColumn={selectedSecondaryTextColumn}
                            selectedKpi={selKpi}
                            kpis={kpis}
                            setKpiView={(kpi) => this.setKpiView(kpi)}
                            idColumn={idColumn}
                            selectedCategory={this.props.selectedCategory}
                            goBack={() => this.props.goBack()}
                            deselectAll={this.deselectAll}
                            selectedItemsLength={this.props.selectedItemsLength}
                            copyGrid={() => copyReportDataToClipboard()}
                            hide={this.props.hideDiffData}
                            createChecklist={_ => this.props.createChecklist(this.state.showItems, label1, label2)}
                        />

                        <div className='px-3 pb-1'>
                            <DataGrid
                                ref={this.dataGrid}
                                id={0}
                                bucketId={bucketId}
                                columns={dataGridColumns}
                                rows={dataGridRows}
                                onSort={(col, direction) => this.setSortColumn(col)}
                                showDecimals={showDecimals}
                                onSelect={ids => this.itemSelection(ids)}
                                autoFitArr={this.state.autoFit}
                                setAutoFitArr={(arr) => this.setAutoFitArr(arr)}
                                sticky={-2}
                                showOpenButton
                                className="sum-sheet-table"
                                isDiff
                                activeCategoryColumn={this.props.reportdata.info.model.categorization_name}
                            />
                            { dataGridRows.length === 0 ? <div className="alert alert-info">No data to display</div> : null }
                        </div>
                        <InfiniteScrollOnScreen hasMore={this.state.hasMoreData} next={() => this.fetchMoreData()} loadingText={<span>...</span>}/>
                    </ShowIf>
                </div>
            )
        }
        return <span></span>
    }
}


class DiffControls extends React.Component {
    state = {
        selectedTrendset: this.props.trendsets[this.props.trendsets.length - 2],
        selectedTextColumn: this.props.selectedTextColumn,
        selectedSecondaryTextColumn: this.props.selectedSecondaryTextColumn,
        selectedKpi: this.props.selectedKpi,
    }

    render() {
        let diff = this.props.totalEndVal - this.props.totalStartVal
        let setType = (val) => {
            this.props.setShowType(val)
        }

        let setTrendset = (e) => {
            const val = JSON.parse(e.target.value)
            this.setState({ selectedTrendset: val }, () => {
                this.props.setTrendset(val ?? this.props.trendsets[this.props.trendsets.length - 2])
            })
        }

        let saveTrendset = () => {
            this.refs.monthSelect.hide()
            this.props.setTrendset(this.state.selectedTrendset ?? this.props.trendsets[this.props.trendsets.length - 2])
        }

        let setTextColumn = (e) => {
            this.setState({ selectedTextColumn: e.target.value })
        }

        let setSecondaryTextColumn = (c) => {
            this.setState({ selectedSecondaryTextColumn: c })
        }

        let saveTextColumns = () => {
            this.props.setTextColumns(this.state.selectedTextColumn, this.state.selectedSecondaryTextColumn)
            this.refs.textColumnSelect.hide()
        }

        let setKPI = (e) => {
            this.setState({ selectedKpi: JSON.parse(e.target.value) })
        }

        let saveKPI = () => {
            this.refs.KPISelect.hide()
            this.props.setKpiView(this.state.selectedKpi)
        }

//        let category = () => {
//            if (this.props.selectedCategory) {
//                return <span> / {this.props.selectedCategory} </span>
//            }
//        }

        let openDialog = () => {
            this.setState({selectedTextColumn: this.props.selectedTextColumn,
                selectedSecondaryTextColumn: this.props.selectedSecondaryTextColumn},
                () => {
                    this.refs.textColumnSelect.show()
                })
        }

        const styles = { color: "#777", margin: 0 }
        return (
            <div>
                <div className='d-flex flex-column'>
                    <div className='border-bottom p-3'>
                        <div className='d-flex flex-row justify-content-between'>
                            <div><h3 style={styles} className=''>Details</h3></div>
                            <div>
                                <span className="fa fa-times close-button" onClick={_ => this.props.hide()} />
                            </div>
                        </div>
                    </div>
                    <div className='border-bottom p-3'>
                        <div className='d-flex flex-column'>
                            <div className='pb-1'>
                                <h2 style={styles}>{this.props.selectedKpi}</h2>
                            </div>
                            <div style={{ fontWeight: "bold" }} className='font-size-16px'>
                                <span className=''>Since last month: </span>
                                <span className={`${getDiffColor(diff)} pl-3`} >{formatValue(true, diff, this.props.showDecimals)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='p-3 d-flex flex-row justify-content-between align-items-center'>
                        <div className='d-flex flex-row'>
                            <div className='d-flex flex-row align-items-center pr-4'>
                                <div className='pr-2'>Status:</div>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    {[{ name: "All", value: "All" }, { name: "Newcomers", value: "1" }, { name: "Gone", value: "-1" }, { name: "Still in the report", value: "0" }].map((s, i) =>
                                        <button key={i} type="button" onClick={_ => setType(s.value)} className={`${this.props.type === s.value ? "active" : ""} px-3 btn btn-sm btn-default`}>
                                            {s.name}
                                        </button>)}
                                </div>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <div className='pr-2'>Track changes since:</div>
                                <div>
                                    <div className="form-inline">
                                        <select className="form-control form-control-sm width-100-p" value={this.state.selectedTrendset ? JSON.stringify(this.state.selectedTrendset) : JSON.stringify(this.props.trendsets[this.props.trendsets.length - 2])} onChange={setTrendset}>
                                            {
                                                this.props.trendsets.map((t, index) => {
                                                    if (this.props.trendsets.length === index + 1) {
                                                        return <option key={index} className="no-show"></option>
                                                    } else {
                                                        return (
                                                            <option key={index} value={JSON.stringify(t)}>{t.label}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flew-row'>
                            <div>
                                <ShowIf if={this.props.selectedItemsLength > 0}>
                                    <button className="btn btn-default btn-sm margin-right-5px" onClick={this.props.deselectAll}>Deselect items ({this.props.selectedItemsLength})</button>
                                </ShowIf>
                            </div>
                            <div><button className="btn btn-default btn-sm margin-right-5px" onClick={() => this.props.copyGrid()}><i className="fa fa-copy"></i></button></div>
                            <div><button onClick={() => openDialog()} className="btn btn-default btn-sm"><i className="fa fa-cog"></i></button></div>
                            <div>
                                <RequirePermission perms={is.checklistsUser}>
                                    <button title={this.props.selectedItemsLength === 0 ? "Select rows by ctrl+click" : ""} disabled={this.props.selectedItemsLength === 0} onClick={_ => this.props.createChecklist()} className='btn btn-sm btn-primary ml-2'>
                                        <span className="fa fa-check margin-right-5px" /> Create Action list {this.props.selectedItemsLength > 0 && <span>({this.props.selectedItemsLength})</span>}
                                    </button>
                                </RequirePermission>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // TODO: Delete if not used anymore */}
                <SelectionDialog ref="monthSelect" title="Select starting month" save={() => saveTrendset()} >
                    <label>Choose month: </label>
                    <div className="form-inline">
                        <select className="form-control width-100-p" value={this.state.selectedTrendset ? JSON.stringify(this.state.selectedTrendset) : JSON.stringify(this.props.trendsets[this.props.trendsets.length - 2])} onChange={setTrendset}>
                            {
                                this.props.trendsets.map((t, index) => {
                                    if (this.props.trendsets.length === index + 1) {
                                        return <option key={index} className="no-show"></option>
                                    } else {
                                        return (
                                            <option key={index} value={JSON.stringify(t)}>{t.label}</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </SelectionDialog>
                <SelectionDialog ref="textColumnSelect" disableDataDismiss={true} closeHandler={() => this.refs.textColumnSelect.hide()} disabled={this.state.selectedTextColumn === "-"} title="Select description column" save={() => saveTextColumns()} >
                    <label>Choose column: </label>
                    <div className="form-inline">
                        <select className="form-control width-100-p" value={this.state.selectedTextColumn} onChange={setTextColumn}>
                            {
                                this.props.columns.map((c, index) => {
                                    return (
                                        <option key={index} value={c}>{c}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <hr />
                    <label>Choose additional column: </label>
                    <div className="form-inline row">
                        <div className={`${this.state.selectedSecondaryTextColumn ? "col-10" : "col-12"}`}>
                            <select className={`form-control width-100-p`} value={this.state.selectedSecondaryTextColumn ? this.state.selectedSecondaryTextColumn : ""} onChange={e => setSecondaryTextColumn(e.target.value)}>
                                <option value={""} hidden disabled>
                                    Select column
                                </option>
                                {
                                    this.props.columns.map((c, index) => {
                                        return (
                                            <option key={index} value={c}>{c}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <ShowIf if={this.state.selectedSecondaryTextColumn}>
                            <div className="col-2">
                                <button className="btn btn-default margin-left-10px float-right" title="Remove additional column" onClick={() => setSecondaryTextColumn(null)}>
                                    <span className="fa fa-trash" />
                                </button>
                            </div>
                        </ShowIf>
                    </div>
                </SelectionDialog>
                {/* // TODO: Delete if not used anymore */}
                <SelectionDialog ref="KPISelect" title="Select KPI Diff" save={() => saveKPI()}>
                    <label>Choose KPI: </label>
                    <div className="form-inline">
                        <select className="form-control width-100-p" value={JSON.stringify(this.state.selectedKpi)} onChange={setKPI}>
                            {
                                this.props.kpis.map((k, index) => {
                                    if (k.column === this.props.idColumn) {
                                        return <option key={index} className="no-show"></option>
                                    } else {
                                        return (
                                            <option key={index} value={JSON.stringify(k.column)}>{k.column}</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </SelectionDialog>
            </div>
        )
    }
}

class SelectionDialog extends BaseDialog {
    getButtons() {
        return (
            <div>
                <button onClick={this.props.closeHandler} type="button" className="btn btn-default margin-right-10px">Cancel</button>
                <button type="button" className="btn btn-primary" title={this.props.disabled ? "This Insight contains no text columns. Add text columns to select them here." : null} disabled={this.props.disabled} onClick={() => this.props.save()}>Select</button>
            </div>
        )
    }
}

let getCustomFilter = (report, c, mastername, slicers) => {
    let filters = JSON.parse(report.filter)
    if (report.fixed_slicer !== '') {
        // TODO - lav support for værdier der er brugt i den faste slicer
    }
    if (c !== null && c !== undefined) {
        filters.push({ column: mastername, compare_type: 1, target_values: [c] })
    }

    if (slicers) {
        slicers.forEach((item, index) => {
            filters.push(item)
        })
    }

    return filters
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (prevState.showItems !== curState.showItems ||
        prevState.selectedTrendset !== curState.selectedTrendset ||
        // prevState.sortParameter !== curState.sortParameter || 
        // prevState.sortDirection !== curState.sortDirection || 
        // prevState.chosenSortColumn !== curState.chosenSortColumn || 
        prevState.selectedTextColumn !== curState.selectedTextColumn ||
        prevState.selectedSecondaryTextColumn !== curState.selectedSecondaryTextColumn)
}

const getSettingsKeys = (state) => {
    let { currentData, autofit, sortParameter, sortDirection, chosenSortColumn, newReport, ...test } = state
    console.log("Settings")
    console.log(test)
    return Object.keys(test)
}

DiffView = withSettingsPropagation(DiffView, getSettingsKeys, shouldComponentUpdateSettings, null, null, "diff")

DiffView = connect(mapStateToProps, null, null, { forwardRef: true })(DiffView)

export default DiffView
