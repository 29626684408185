import React from 'react'

class KPIElementBase extends React.Component {
    extraClass() {
        return ''
    }

    getHover() {
        if (this.props.editMode && this.props.noHover === undefined) {
            return 'kpiElement'
        }
        return ''
    }

    content() {
        return this.props.children
    }

    clickAction() {
        if(this.props.clickAction !== undefined) this.props.clickAction()
    }

    selected() {
        if(this.props.selected) return 'kpiSelected'
        else return ''
    }

    render() {
        return (
            <div className={`col-md-${this.props.width} col-sm-${this.props.width} inline-block ${this.getHover()} ${this.extraClass()} ${this.selected()}`} onClick={() => this.clickAction()}>
                {this.content()}
            </div>
        )
    }
}

export default KPIElementBase