import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getOrganizationUsers = (orgID, callback) => {
    return packAction(ActionTypes.GET_ORGANIZATION_USERS, { orgID: orgID})
}
export const gotOrganizationUsers = (users) => {
    return packAction(ActionTypes.GET_ORGANIZATION_USERS_DONE, users)
}

export const getOrganizationUser = (orgId, userId) => {
 return packAction(ActionTypes.GET_ORGANIZATION_USER, { orgID: orgId, userId: userId})
}
export const gotOrganizationUser = (user) => {
    return packAction(ActionTypes.GET_ORGANIZATION_USER_DONE, user)
}

export const getOrganizationItems = (orgID) => {
        return packAction(ActionTypes.GET_ORGANIZATION_ITEMS, { orgID: orgID })
}

export const gotOrganizationItems = (items) => {
        return packAction(ActionTypes.GET_ORGANIZATION_ITEMS_DONE, { items: items })
}

export const getOrganization = (orgID) => {
    return packAction(ActionTypes.GET_ORGANIZATION, {orgID: orgID})
}
export const gotOrganization = (organization) => {
    return packAction(ActionTypes.GET_ORGANIZATION_DONE, {organization:organization})
}

export const getOrganizationLocations = (orgID) => {
    return packAction(ActionTypes.GET_ORGANIZATION_LOCATIONS, {orgID: orgID})
}
export const gotOrganizationLocations = (locations) => {
    return packAction(ActionTypes.GET_ORGANIZATION_LOCATIONS_DONE, {locations: locations})
}

export const getOrganizationSubscriptionSettings = (orgID) => {
    return packAction(ActionTypes.GET_ORGANIZATION_SUBSCRIPTION_SETTINGS, {orgID: orgID})
}

export const gotOrganizationSubscriptionSettings = (settings) => {
    return packAction(ActionTypes.GET_ORGANIZATION_SUBSCRIPTION_SETTINGS_DONE, settings)
}
