import * as ActionTypes from "../../actions/ActionTypes"
import { gotOrganizationUsers, gotOrganizationItems, gotOrganization, gotOrganizationUser, gotOrganizationLocations, gotOrganizationSubscriptionSettings } from "../../actions/OrganizationActions"
import { apiGet } from "../../helpers/ApiHelpers"
import { notifyApiFailure } from "../../helpers/ErrorHelpers"

const OrganizationApiMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action)
        let url = undefined
        let data = undefined
        switch (action.type) {
            case ActionTypes.GET_ORGANIZATION_USER:
                url = `organization/administrate/${action.payload.orgID}/${action.payload.userId}`
                apiGet(url, (user) => dispatch(gotOrganizationUser({user: user})),
                (e) => {
                    notifyApiFailure("getting user data", "GET", url, data, e?.status)
                })
                break
            case ActionTypes.GET_ORGANIZATION_USERS:
                url = `organization/administrate/${action.payload.orgID}`
                apiGet(url, (users) => dispatch(gotOrganizationUsers(users)),
                    (e) => {
                        notifyApiFailure("getting organization data", "GET", url, data, e?.status)
                    })
                break
            case ActionTypes.GET_ORGANIZATION_ITEMS:
                url = `organization/administrate/${action.payload.orgID}/items`
                apiGet(url, (items) => dispatch(gotOrganizationItems(items)), (e) => {})
                break
            case ActionTypes.GET_ORGANIZATION:
                url = `organization/${action.payload.orgID}`
                apiGet(url, (data) => dispatch(gotOrganization(data)),
                    (e) => {
                        notifyApiFailure("getting organization data", "GET", url, data, e?.status)
                    })
                break
            case ActionTypes.GET_ORGANIZATION_LOCATIONS:
                url = `organizations/${action.payload.orgID}/locations`
                apiGet(url, (locations) => dispatch(gotOrganizationLocations(locations)), (e) => {
                    notifyApiFailure("getting organization locations", "GET", url, data, e?.status)
                })
                break
            case ActionTypes.GET_ORGANIZATION_SUBSCRIPTION_SETTINGS:
                url = `organizations/${action.payload.orgID}/subscription_settings`
                apiGet(url, settings => dispatch(gotOrganizationSubscriptionSettings(settings)), e => {
                    notifyApiFailure("getting organization subscription settings", "GET", url, data, e?.status)
                })
                break
            default:
                break
        }
    }

export default OrganizationApiMiddleware
