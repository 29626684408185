import React, { useState } from 'react'
import { ReportData } from '../../types/transfertypes'
import GenericDialog from './GenericDialog'
import { connect } from "react-redux";
import Select from '../Generic/CustomSelect'
import ActiveCategoryIcon from '../Report/ReportComponents/ActiveCategoryIcon'
import { getSlicerValues } from "../../actions/ReportActions";
import ShowIf from '../Generic/ShowIf';

export interface AddMultipleDashboardFiltersDialogProps {
    dispatch?: any,
    show: boolean,
    onClose: () => void,
    reportInfo: ReportData,
    createFilters: (column: string, values: string[]) => void,
    slicerValues?: any,
}

let mapStateToProps = (state: any, ownProps: AddMultipleDashboardFiltersDialogProps) => {
    return {
        slicerValues: state.Report.slicerValues
    }
}

let AddMultipleDashboardFiltersDialog = (props: AddMultipleDashboardFiltersDialogProps) => {

    const [selectedColumn, setSelectedColumn] = useState<string>("")
    const [columnValues, setColumnValues] = useState<string[]>([])

    let saveFilters = () => {
        let selectedValues = columnValues.length == 0 ? props.slicerValues.get(selectedColumn, { data: { values: [] } }).data.values : columnValues

        props.createFilters(selectedColumn, selectedValues)
        setSelectedColumn("")
        setColumnValues([])
    }


    let setColumn = (column: string) => {
        if(column != selectedColumn){
            setSelectedColumn(column)
            setColumnValues([])

            let filters = JSON.parse(props.reportInfo.report.filter)
            props.dispatch(getSlicerValues(props.reportInfo.info.id, { column: column, filter: filters }, []))
        }
    }

    let removeValue = (value: string) =>{
        let newColumnValues = []

        let selectedValues = columnValues.length == 0 ? props.slicerValues.get(selectedColumn, { data: { values: [] } }).data.values : columnValues

        for(let i=0; i < selectedValues.length;i++){
            if(value !== selectedValues[i]){
                newColumnValues.push(selectedValues[i])
            }
        }

        if(newColumnValues.length == 0){
            setSelectedColumn("")
            setColumnValues([])
        }
        else{
            setColumnValues(newColumnValues)
        }
    }

    let disableSave = () => {
        return selectedColumn === ""
    }
    
    let getTextAndIDColumns = () => {
        if (props.reportInfo == undefined) return []
        let filteredColumns = props.reportInfo.info.model.columns.filter((c) => (c.type === "text" || c.type =="categorization"));
        return filteredColumns.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }
    
    let activeCategoryColumn = props.reportInfo && props.reportInfo.info && props.reportInfo.info.model ? props.reportInfo.info.model.categorization_name : ""

    let getButtons = () => {
        return <div>
            <button style={{marginRight: 7}} onClick={saveFilters} disabled={disableSave()} className='btn btn-primary'>Save</button>
            <button onClick={() => { setSelectedColumn(""); setColumnValues([]); props.onClose()}} className='btn btn-default'>Cancel</button>
        </div>
    }

    let columns = getTextAndIDColumns()
    let selectedValues = columnValues.length == 0 ? props.slicerValues.get(selectedColumn, { data: { values: [] } }).data.values : columnValues

    return (
        <div>
            <GenericDialog show={props.show} title='Add multiple filter rows' onClose={props.onClose} getButtons={getButtons} style={{maxWidth:"50%"}}>
                <div className='dashboard-edit-slicers'>
                    <label className='select-label'>
                        Select column:
                    </label>
                    <Select
                        onChange={(v:{label: string, value: string}) => {setColumn(v.value)}}
                        options={columns.map(c => ({
                            value: c.name, label: c.name === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                <div>{c.name}</div>
                                <div className='pl-1'><ActiveCategoryIcon /></div>
                            </div> : c.name
                        }))}
                        value={selectedColumn}
                        placeholder={selectedColumn === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                            <div>{selectedColumn}</div>
                            <div className='pl-1'><ActiveCategoryIcon /></div>
                        </div> : selectedColumn}
                        styles={{
                            placeholder: (provided:any, state:any) => ({
                                ...provided,
                                color: "#495057"
                            }),
                            menu: (baseStyles:any, state:any) => (
                                {...baseStyles,
                                zIndex: 800,
                                position: "fixed",
                                    top: "-1",
                                    width: "275px",
                                }),
                        menuList: (baseStyles:any, state:any) => ( 
                            {...baseStyles,
                                maxHeight: "250px",
                            }),
                        control: (styles:any) => ({ 
                            ...styles,
                            ':focus-within' : { 
                                borderColor: "var(--tangerine-hover)", 
                                boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                            }
                        }),
                        option: (provided:any, state:any) => ({
                          ...provided,
                          fontWeight: state.isSelected ? "bold" : "normal",
                          color: "black",
                          backgroundColor: "white",
                        }),
                        }}
                    />
                    <ShowIf if={selectedColumn !== ""}>
                        <hr className="margin-bottom-10px margin-top-10px" />
                        <div>
                            <label>Filter rows will be added for each of the following values:</label>
                        </div>
                        <div className="form" style={{overflowY:"scroll", maxHeight:400}}>
                        {selectedValues ? selectedValues.map(
                            (item: string, index: number) => {

                            return <div key={index}> <span key={index} className="abc-click abc-text-filer-value badge badge-secondary textSlicerSelected"
                                id="option-compose-viewer-slicerTextValues-remove" onClick={() => removeValue(item)}>
                                {item === "" ? "No value" : item} <i className="fa fa-times filter-label-remove"></i>
                                </span><br />
                            </div>

                            }) : null
                        }
                        </div>
                    </ShowIf>

                </div>
               
            </GenericDialog>
        </div>
    )
}
// @ts-ignore
AddMultipleDashboardFiltersDialog = connect(mapStateToProps)(AddMultipleDashboardFiltersDialog)

export default AddMultipleDashboardFiltersDialog