import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

// export const gotItem = item => {
//     return packAction(ActionTypes.GET_ITEM_DONE, item)
// }

export const gotItemComments = (bucket, itemID, comments) => {
    return packAction(ActionTypes.GET_ITEM_COMMENTS_DONE, {
        bucket: bucket,
        itemID: itemID,
        comments: comments,
    })
}

export const getItemData = (bucket, item) => {
    return packAction(ActionTypes.GET_ITEM_DATA, {bucket_id: bucket, item_id: item})
}

export const getItemDataNotFound = () => {
    return packAction(ActionTypes.GET_ITEM_DATA_NOT_FOUND)
}

export const gotItemData = (bucket, item, data) => {
    return packAction(ActionTypes.GET_ITEM_DATA_DONE, {bucket: bucket, itemID: item, data: data})
}

export const getItemDrilldown = (bucket, item, aggregatedColumns, drilldownSlicers) => {
    return packAction(ActionTypes.GET_ITEM_DRILLDOWN, {bucket_id: bucket, item_id: item, aggregated_columns: aggregatedColumns, drilldown_slicers: drilldownSlicers})
}

export const gotItemDrilldown = (bucket, item, aggregatedColumns, data) => {
    return packAction(ActionTypes.GET_ITEM_DRILLDOWN_DONE, {bucket: bucket, itemID: item, data: data, aggregated_columns: aggregatedColumns})
}

export const downloadTransactionData = (bId, item, data) => {
    return packAction(ActionTypes.DOWNLOAD_TRANSACTION_DATA, {bucket_id: bId, item_id: item, data: data})
}

export const addedItemComment = (bucket_id, item_id) => {
    return packAction(ActionTypes.GET_ITEM_COMMENTS, {bucket_id: bucket_id, item_id: item_id})
}

export const updatedItemComment = (dispatch, bucket_id, item_id) => {
    dispatch(packAction(ActionTypes.GET_ITEM_COMMENTS, {bucket_id: bucket_id, item_id: item_id}))
}

export const updatedItemCOmment = (bucket_id, item_id) => {
    return packAction(ActionTypes.GET_ITEM_COMMENTS, {bucket_id: bucket_id, item_id: item_id})
}

export const getItemCategoryHistory = (bucketID, itemID, categoryColumn) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY, {bucketID: bucketID, itemID: itemID, categoryColumn: categoryColumn})
}

export const gotItemCategoryHistory = (bucketID, itemID, history) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY_DONE, {bucketID: bucketID, itemID: itemID, history: history})
}

export const getItemCategoryHistoryGraph = (bucketID, itemID, categoryColumn) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH, {bucketID: bucketID, itemID: itemID, categoryColumn: categoryColumn})
}

export const gotItemCategoryHistoryGraph = (bucketID, itemID, history, column) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_DONE, {bucketID: bucketID, itemID: itemID, history: history, column: column})
}

export const getItemCategoryHistoryGraphAll = (bucketID, itemID, categoryColumns) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL, {bucketID: bucketID, itemID: itemID, categoryColumns: categoryColumns})
}

export const gotItemCategoryHistoryGraphAll = (bucketID, data) => {
    return packAction(ActionTypes.GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL_DONE, {bucketID: bucketID, data: data})
}

export const gotLinks = (bucket, item, result) => {
    return packAction(ActionTypes.GET_ITEM_LINKS_DONE, {bucket: bucket, item: item, data: result})
}

export const getItemsComments = (bId, data) => {
    return packAction(ActionTypes.GET_ITEMS_COMMENTS, {bId: bId, data: data})
}

export const gotItemsComments = (data, bId) => {
    return packAction(ActionTypes.GET_ITEMS_COMMENTS_DONE, {data: data, bId: bId})
}