/* REACT */
import React from 'react'
import { getIdColumn } from '../../../helpers/ReportHelpers'
import { copyToClipboard } from '../../../helpers/GeneralHelpers'
import { withSettingsPropagation } from '../../../helpers/SettingsService'


class KpiTable extends React.Component {
    state = {
        selectedPeriod: -1,
        initialFit: false,
        autoFitKpiName: false,
        autoFitArray: [],
        totalDiffArr: []
    }

    componentDidMount() {
        if (this.props.historydata && this.props.historydata.length > 0 && this.state.totalDiffArr.length === 0) {
            this.setState({
                totalDiffArr: this.getTotalDiffArr(this.props.historydata)
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (
            (this.props.historydata && this.props.historydata.length > 0 && this.state.totalDiffArr.length === 0) ||
            (JSON.stringify(prevProps.historydata) !== JSON.stringify(this.props.historydata))
        ) {
            this.setState({
                totalDiffArr: this.getTotalDiffArr(this.props.historydata)
            })
        }


    }

    getTotalDiffArr(data) {
        return data.map((d, i) => {
            const kpi = this.props.kpis[i]

            if(kpi === undefined)
                return 0;

            let last, sndLast

            for (let idx = d.length; idx--;) {
                const item = d[idx]
                if (last === undefined) {
                    if (item.found) last = item.aggregation.total[kpi.aggregation]
                } else if (sndLast === undefined) {
                    if (item.found && item !== last) sndLast = item.aggregation.total[kpi.aggregation]
                }
            }

            return last - sndLast
        })
    }

    setPeriod(p) {
        this.setState({
            selectedPeriod: p
        })
    }

    selectKpi(kpi, index) {
        let skipRest = false
        let lastAdded = this.props.lastAdded
        let selectedKpiView = this.props.selectedKpiView
        let selectedKpi1 = this.props.selectedKpi1 !== null ? Object.assign({}, this.props.selectedKpi1) : null
        let selectedKpi2 = this.props.selectedKpi2 !== null ? Object.assign({}, this.props.selectedKpi2) : null

        // if we select an already selected kpi
        if (selectedKpi1 !== null && kpi.column === selectedKpi1.kpi.column && selectedKpi1.index === index) {
            selectedKpi1 = null
            selectedKpiView = selectedKpi2 ? selectedKpi2.kpi.column : null
            lastAdded = 2
            skipRest = true
        } else if (selectedKpi2 !== null && kpi.column === selectedKpi2.kpi.column && selectedKpi2.index === index) {
            selectedKpi2 = null
            selectedKpiView = selectedKpi1 ? selectedKpi1.kpi.column : null
            lastAdded = 1
            skipRest = true
        }

        // if we have two kpis selected and we select one that is not already selected
        if (!skipRest) {
            if (selectedKpi1 !== null && selectedKpi2 !== null) {
                if (lastAdded === 1) {
                    selectedKpi2 = null
                } else if (lastAdded === 2) {
                    selectedKpi1 = null
                }
            }
        }


        // if we deselect a kpi in the first if-statement we don't want to enter this if 
        if (!skipRest) {
            let idColumn = getIdColumn(this.props.reportdata.info.model.columns)
            // if there is 0 or 1 kpi selected we just add one
            if (selectedKpi1 === null) {
                selectedKpi1 = { kpi: kpi, color: 'blue', index: index, settings: { type: "bar" } }
                selectedKpiView = kpi.column === idColumn && selectedKpi2 && selectedKpi2.kpi.column !== idColumn ? selectedKpi2.kpi.column : kpi.column
                lastAdded = 1
            } else if (selectedKpi2 === null) {
                selectedKpi2 = { kpi: kpi, color: 'green', index: index, settings: { type: "bar" } }
                selectedKpiView = kpi.column === idColumn && selectedKpi1 && selectedKpi1.kpi.column !== idColumn ? selectedKpi1.kpi.column : kpi.column
                lastAdded = 2
            }
        }

        this.props.setSelectedKpis(selectedKpi1, selectedKpi2, lastAdded, selectedKpiView)
    }

    goToTrend(e, kpi, index) {
        e.stopPropagation()
        this.props.goToTrend({ kpi: kpi }, index)
    }

    formatValue(isNumber, value, showDecimals) {
        let decimalCount = showDecimals ? 2 : 0;
        if (isNumber && null != value) {
            let x = parseFloat(value);
            if (isNaN(x)) {
                return '?';
            }
            return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount });
        }
        return value;
    }

    getHTML() {
        let labels = this.props.historydata[0]
        let columnHTML = '<tr><th><b>KPI</b></th><th><b>' + labels.map(l => l.label).join('</b></th><th><b>') + '</b></th><th><b>Since last month</b></th></tr>'

        let rowHTML = '<tr><td>' + this.props.historydata.map((data, i1) => {
            let shownDataCells = data.map((_, i2) => {
                let sum = this.getSum(i1, i2)
                if (!isNaN(sum)) {
                    return this.formatValue(true, sum, this.props.kpis[i1]?.showKPIDecimals ?? false)
                } else {
                    return '-'
                }
            })
            shownDataCells.push(this.state.totalDiffArr[i1] !== undefined ? this.formatValue(true, this.state.totalDiffArr[i1], this.props.kpis[i1]?.showKPIDecimals ?? false) : '-')
            return "<b>" + this.props.kpis[i1].column + "</b></td><td>" + shownDataCells.join('</td><td>')
        }).join('</td></tr><tr><td>') + '</td></tr>'

        return '<table>' + columnHTML + rowHTML + '</table>'
    }

    getSum = (kpi, period) => {
        let sum = NaN
        let data = this.props.historydata
        let kpis = this.props.kpis

        if (data[kpi][period].found) {
            if (this.props.selectedCategory) {
                if (data[kpi][period].aggregation.segments[this.props.selectedCategory]) {
                    sum = data[kpi][period].aggregation.segments[this.props.selectedCategory][kpis[kpi].aggregation]
                } else {
                    sum = 0
                }
            } else {
                sum = data[kpi][period].aggregation.total[kpis[kpi].aggregation]
            }
        }
        return sum
    }

    copyToClipboard(format) {
        if (format === "html") {
            copyToClipboard(this.getHTML())
        }
    }



    render() {
        let data = this.props.historydata ?? []
        let labels = data !== null && data.length !== 0 ? data[0] : []
        let kpis = this.props.kpis
       
        let getBgColor = (kpi, index) => {
            if(kpi === null){
                return ''
            } else if (this.props.selectedKpi1 !== null && kpi.column === this.props.selectedKpi1.kpi.column && index === this.props.selectedKpi1.index) {
                return 'kpi1Selected'
            } else if (this.props.selectedKpi2 !== null && kpi.column === this.props.selectedKpi2.kpi.column && index === this.props.selectedKpi2.index) {
                return 'kpi2Selected'
            }
            return ''
        }

        let getHighlightColor = (kpi, column, row) => {
            let divisor = this.props.graphOptions ? this.props.graphOptions.zoom / 12 + 1 : 1
            if(this.props.graphOptions && this.props.graphOptions.zoom == 12)
                divisor = 1
            let selectedColumn = Math.floor(this.state.selectedPeriod / divisor)
            
            if (this.props.selectedKpi1 !== null && kpi.column === this.props.selectedKpi1.kpi.column && column === selectedColumn && this.props.selectedKpi1.index === row) {
                return 'kpi1Selected-hover'
            } else if (this.props.selectedKpi2 !== null && kpi.column === this.props.selectedKpi2.kpi.column && column === selectedColumn && this.props.selectedKpi2.index === row) {
                return 'kpi2Selected-hover'
            }
            return ''
        }

        let getFitIcon = (index) => {
            if (this.state.autoFitArray[index] && this.state.autoFitArray[index].isFitted) {
                return ''
            }
            return 'abc-autofit-column-icon-2'
        }

        let autoFitColumn = (index) => {
            let arr = this.state.autoFitArray
            if (arr[index]) {
                arr[index].isFitted = !arr[index].isFitted
            } else {
                arr[index] = { index: index, isFitted: true }
            }

            let longestString = ""
            let columnToResize = window.$('.datacolumn-' + index)

            if (arr[index] && !arr[index].isFitted) {
                if (typeof columnToResize.attr('style') !== 'undefined') {
                    columnToResize.removeAttr('style')
                }
            }
            if (arr[index] && arr[index].isFitted) {
                // TODO - find longest string among all KPI number values for that table column
                data.forEach((item, i) => {
                    if (item[index].found) {
                        if (this.props.selectedCategory) {
                            if (item[index].aggregation.segments[this.props.selectedCategory][kpis[i].aggregation].toFixed(2).toString().length > longestString.length) {
                                longestString = item[index].aggregation.segments[this.props.selectedCategory][kpis[i].aggregation].toFixed(2).toString()
                            }
                        } else {
                            if (item[index].aggregation.total[kpis[i].aggregation].toFixed(2).toString().length > longestString.length) {
                                longestString = item[index].aggregation.total[kpis[i].aggregation].toFixed(2).toString()
                            }
                        }
                    } else {
                        longestString = 'XX'
                    }
                })
                window.$('#for-dynamic-cell-width-measurement').empty();
                window.$('#for-dynamic-cell-width-measurement').show();
                window.$('#for-dynamic-cell-width-measurement').append('<span id="width-result">' + longestString + '</span>');
                let elementWidth = window.$('#width-result').width() + 50;
                window.$('#for-dynamic-cell-width-measurement').hide();

                for (let k = 0; k < columnToResize.length; k++) {
                    columnToResize.attr('style', 'width:' + elementWidth + 'px !important');
                }
            }
            this.setState({ autoFitArray: arr })
        }

        let twoAxis = this.props.graphOptions && this.props.graphOptions.axisNum == 2 && this.props.selectedKpi1 && this.props.selectedKpi2

        return (
            <div className='trend-kpi-table' style={twoAxis ? {paddingRight:90} : {}}>
                <div className='d-flex flex-row'>
                    <div className='flex-grow-1'>
                        <table className="table table-condensed table-bordered col-md-12 table-sm" >
                            <thead>
                                <tr className="all-white-space-normal">
                                    <th className="datacolumn-kpi abc-click" style={{width: 135}}>KPI</th>
                                    {
                                        labels.map((d, index) => {
                                            return (
                                                <th className={`text-align-right abc-click datacolumn-${index} ${getFitIcon(index)}`} key={index} onClick={() => autoFitColumn(index)}>{d.label.substring(0, 3)} '{d.label.substring(d.label.length-2)}
                                                    <i className={`fa fa-arrows-h float-left margin-left-2px abc-click`}></i>
                                                </th>
                                            )
                                        })
                                    }
                                    {/* <th title="Copy table to clipboard" className="width-40px text-align-center abc-click padding-bottom-4px" onClick={() => this.copyToClipboard("html")}><span className="fa fa-copy icon-gray" /></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((d, index1) => {
                                        let kpi = kpis[index1] ? kpis[index1] : null
                                        if (kpi) {
                                            return (
                                                <tr key={index1} onClick={() => this.selectKpi(kpi, index1)} className={`${getBgColor(kpi, index1)}`}>
                                                    <td className="abc-click"><strong><span title={kpi.column}>{kpi.column}</span></strong></td>
                                                    {
                                                        d.map((data, index2) => {
                                                            let sum = this.getSum(index1, index2)
                                                            if (!isNaN(sum)) {
                                                                return (
                                                                    <td key={index2} className={`text-align-right abc-click ${getHighlightColor(kpis[index1], index2, index1)}`}>{this.formatValue(true, sum, kpis[index1].showKPIDecimals)}</td>
                                                                )
                                                            }
                                                            return <td key={index2} className="text-align-right abc-click">-</td>
                                                        })
                                                    }
                                                    {/* {getIcon(kpi.column, index1)} */}
                                                </tr>
                                            )
                                        }
                                        return null
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="for-dynamic-cell-width-measurement"></div>
            </div>
        )

    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (prevState.autoFitKpiName != curState.autoFitKpiName)
}

const getSettingsKeys = (state) => {
    let { autoFitArray, selectedPeriod, ...settings } = state
    return Object.keys(settings)
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => {
   
}


export default withSettingsPropagation(KpiTable, getSettingsKeys, shouldComponentUpdateSettings, null, settingsDidApply, "kpi-table")
