/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { formatValue } from '../../helpers/ReportHelpers'
import { getArrowValues} from '../../helpers/DashboardHelpers'
import {SiTarget} from 'react-icons/si'
import ShowIf from '../Generic/ShowIf'

/* CSS */
// import '../css/dimensions.css'

const mapStateToProps = (state, ownProps) => {
    return {

    }
}
export class SmallWidget extends React.Component {
    state = {}

    componentDidUpdate(prevProps, prevState, snapshot) { }

    getHighlightedColor(id, key) {
        const { hoverReportId, hoverKpiKey } = this.props
        if (id === hoverReportId && hoverKpiKey === key) return 'dashboard-background-small-selected'

        return 'dashboard-background-small'
    }

    render() {
        // const { indexJ } = this.props
        const widget = this.props.data
        if (!widget) return null

        const column = widget.column
        const name = widget.report_name
        const trend = widget.trend
        const noData = widget.data_points === null ? true : false
        const label = widget.label && widget.label !== "" ? widget.label : ""
        const dataPoints = widget.data_points ? widget.data_points : []

        const lastVal = widget.last_value || widget.last_value === 0 ? widget.last_value : -1
        const trendObj = getArrowValues(dataPoints[dataPoints.length - 2], lastVal, trend)

        let formattedVal
        if (widget.percent) {
            formattedVal = lastVal !== -1 ? formatValue(true, widget.percent_value, widget.decimal) + "%" : ""
        } else {
            formattedVal = lastVal !== -1 ? formatValue(true, lastVal, widget.decimal) : ""
        }
        if (noData) formattedVal = "N/A"
   
        let targetcolor = "neutral-target" //neutral
        if (widget.found_target && (widget.last_value || widget.last_value === 0)) {
            if (widget.target_trend === -1 && widget.target >= widget.last_value){ //good
                targetcolor = "green-target-positive"
            } else if (widget.target_trend === -1 && widget.target < widget.last_value){ //bad
                targetcolor = "red-target-negative" 
            } else if (widget.target_trend === 1 && widget.target > widget.last_value) { //bad
                targetcolor = "red-target-negative" 
            } else if (widget.target_trend === 1 && widget.target <= widget.last_value) { //good
                targetcolor = "green-target-positive"
            }
        }

        return (
            <div
                className={`${this.props.fromSecret ? "dashboard-small-secret" : "dashboard-small show-target"} dashboard-background-small inline-block ${widget.is_report ? 'abc-click' : ''} position-relative ${!this.props.fromHome ? this.getHighlightedColor(widget.row_id != '' ? widget.row_id : widget.report_id, widget.kpi_key) : null}`}
                // onMouseEnter={!this.props.fromHome ? () => this.props.setHoverValues(widget.row_id, widget.kpi_key) : null}
                // onMouseLeave={!this.props.fromHome ? () => this.props.setHoverValues("", "") : null}
                title={widget.is_report ? "Go to Insight" : ""}
                // data-tip
                // data-for={`tooltip-${widget.row_id}-${widget.kpi_key}-${indexJ}-${widget.type}`}
            >
                {/* <WidgetToolTip index={indexJ} value={formattedVal} widget={widget} direction={trendObj.direction} differencePrecedingPlus={""} /> */}
                <div className="container">

                    <div style={{display:'flex', width:"100%"}}>
                        <div className="" style={{color:"#455955", marginTop:"4px", fontSize:"10px", height:"10px", textAlign:"left", marginLeft:"0px"}}>{label !== "" ? `${label}` : `${column} - ${name}`}</div>
                    </div>
                    <div className="row text-align-center " style={{ alignItems:"center", height: "90px", justifyContent:"center "}}>
                        <div className=" kpi-wrapper-small" style={{paddingLeft:"1px",  }}>
                            <div style={{marginRight:"5px", minWidth:"84px", float:"right"}}>
                            {/* { */}
                            {
                                !noData ?
                                    <span className="" style={{fontSize: "22px", fontWeight:"bold", float:"right"}} >
                                        {formattedVal}
                                    </span>
                                    : <span className="" style={{fontSize: "14px", fontWeight:"bold", float:"right"}} >N/A</span>
                            }
                            </div>
                            <div className='vertical-line-small-widget'></div>

                            <div className='widget-percentage-info zero-margin ' style={{fontSize:"14px", minWidth:"60px"}}> 
                            <div style={{ display:"flex"}} >
                            <span style={{ marginLeft:"3px", }} >{formatValue(true, widget.difference_percent, true)}%</span>

                            {!noData && widget.trend !== 0 ? 
                                    <img className="" style={{marginLeft:"2px", height:"21px", width:"12px"}} alt="" src={`${trendObj.isPositive ? widget.difference_percent > 0 ? './img/PolygonGreenUp.svg' : widget.difference_percent < 0 ?  './img/PolygonGreenDown.svg' : './img/PolygonNeutral.svg': widget.difference_percent > 0 ? './img/PolygonRedUp.svg' : widget.difference_percent< 0 ?  './img/PolygonRedDown.svg' : './img/PolygonNeutral.svg ' }`}></img>
                                    : !noData && widget.trend === 0 ?
                                    <img className="" style={{marginLeft:"2px", height:"21px", width:"12px"}} alt="" src={`${ widget.difference_percent > 0 ? './img/PolygonNeutralUp.svg' : widget.difference_percent < 0 ? './img/PolygonNeutralDown.svg' : './img/PolygonNeutral.svg' }`}></img>
                                        : null 
                            }
                            </div>
                            <div className="" style={{fontSize:"8px", color:"#304642", marginLeft:"2px", marginTop:"1px"}}> Since last month</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 no-padding stick-to-bottom"  >
                            <div style={{flex:""}}>
                            <div className={`height-12px target-color ${ targetcolor }`} style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center", verticalAlign:"middle"}} > 
                                <ShowIf if={widget.found_target}><SiTarget style={{marginRight:"2px", height:"10px"}} /></ShowIf>
                                {/* <span className={`${widget.found_target ? "fa fa-dot-circle-o" : "" }`}  style={{verticalAlign:"middle", fontSize:"12px", marginRight:"2px", marginTop:"1px"}} > </span>  */}
                                <span  style={{verticalAlign:"middle",fontSize:"10px", marginRight:"5px"}}> {widget.found_target && widget.target_trend === -1 ? "max." : widget.found_target && widget.target_trend === 1 ? "min." : ""} {widget.found_target ? formatValue(true, widget.target, widget.decimal) : ""}{widget.found_target && widget.percent ? "%" : ""}</span>
                            </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}

SmallWidget = connect(mapStateToProps)(SmallWidget)

export default SmallWidget
