import React, {useRef, useState, useEffect} from "react"
import "../css/imageCarousel.css"

export default function ImageCarousel(props) {

    const [activeImageIndex, setActiveImageIndex] = useState(0)

    const carouselInnerRef = useRef()

    function updateImageIndex(newIndex) {
        if(newIndex < 0) {
            newIndex = props.images.length - 1;
        } else if (newIndex >= props.images.length) {
            newIndex = 0;
        }

        setActiveImageIndex(newIndex)
    }

    useEffect(()=> {
        const interval = setInterval(()=> {
            updateImageIndex(activeImageIndex + 1); }, props.interval);
        
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    })

    useEffect(() => {
        carouselInnerRef.current.style.transform = `translateX(-${activeImageIndex * 100}%)`
    }, [activeImageIndex])

    return(
        <section className="image-carousel">
            <div className="image-carousel-inner" ref={carouselInnerRef}>
                {props.images.map(img => 
                    <li className="image-carousel-item" key={img.id}>
                        <img src={img.src} alt=""></img>
                    </li>)}
            </div>
        </section>
    )
}
