import React from 'react'
import LoadingAnimation from '../components/LoadingAnimation'

import '../css/offline.css'


let Offline = (props) => {

    const width = 8
    const height = 6

    let boxes = []

        for (let i = 0; i < width * height; i++) {
            boxes.push(<div key={i} className='offline-grid-div'></div>)
        }

    return (
    <div className='offline-container'>  
        <div className='offline-left'>
            <img className='offline-logo' src='img/abc-logo-med-tagline.png' alt="" />
        </div>
        <div className='offline-center'>
            <h1>The service is currently offline due to maintanence.</h1>
            <h2>We are working on it...</h2>
            <LoadingAnimation />

        </div>
            <div className='offline-grid-decoration'>
                {boxes}

            </div>
    </div>

    )

}


export default Offline
