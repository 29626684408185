import React from 'react'
import posed from 'react-pose'
import ShowIf from './Generic/ShowIf'

const SpringSpan = posed.span({
    shake: {
        applyAtEnd: { rotate: "0deg" },
        applyAtStart: { rotate: "15deg" },
        rotate: "0deg",
        transition: {
            type: "spring",
            stiffness: 1000,
            damping: 10,
            duration: 4
        }
    },

})

const EnlargingSpan = posed.span({
    grow: {
        applyAtEnd: { scale: 1.0 },
        applyAtStart: { scale: 1.1 },
        scale: 1.0,
        transition: {
            type: "spring",
            stiffness: 1000,
            damping: 10,
            duration: 4
        }
    },
    initial: {
        scale: 0.0
    },
    shown: {
        scale: 1.0,
        transition: {
            delay: 750,
        }
    }
})

class NotificationBell extends React.Component {
    state = {
        changes: 0
    }

    componentDidUpdate(prevProps) {
        if(prevProps.notificationsCount < this.props.notificationsCount) this.setState({changes: this.state.changes+1})
    }

    render() {
        return (
            <React.Fragment>
                <SpringSpan className="fa fa-bell" pose={["shake"]} poseKey={this.state.changes}/>
                <ShowIf if={this.props.notificationsCount > 0}>
                    <EnlargingSpan className="notification-number" poseKey={this.state.changes} initialPose="initial" pose="shown">{this.props.notificationsCount}</EnlargingSpan>
                </ShowIf>
            </React.Fragment>
        )
    }
}

export default NotificationBell