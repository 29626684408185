import React from 'react'

export default function ExitPromptDialog(props) {

		return (
			<div id={props.id} className={"modal " + (props.shown ? "display-block" : "")} tabIndex="-1" role="dialog">
				{ !!props.shown && 
                <div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header sticky-modal-header">
							<h4 className="modal-title">{props.title}</h4>
						</div>
						<div className="modal-body">
							{props.children}
						</div>
						<div className="modal-footer sticky-modal-footer">
							{!!props.cancel &&
                            <button onClick={(e) => props.cancel(e)} type="button" className="btn btn-default float-left margin-right-10px" >Cancel</button>
							}
                            { !!props.save &&
							<button onClick={(e) => props.save(e)} type="button" className="btn btn-primary margin-right-10px">Save</button>                
							}
                            { !!props.accept &&
							<button onClick={(e) => props.accept(e)} type="button" className={props.acceptCss ? props.acceptCss : "btn btn-danger"}>{props.acceptText}</button>
							}	
						</div>
					</div>
				</div>
                }
			</div>
		)
}
