import React from 'react'


const MARGIN_TOP = 25
//id must be set in props
class ScrollTopSticker extends React.Component {
    constructor(props) {
        super(props)
        this.props = props
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.scroll())
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.scroll())
    }

    isSticky = false

    scroll(event) {
        if(event) {
            event.stopPropagation()
        }
        let $ = window.$
        if(!this.props.id) return
        let controls = document.getElementById(this.props.id)
        if(!controls) return
        let scrollTopContainer = document.getElementById("scroll-top-container")
        
        let offset = scrollTopContainer.offsetTop;
        
        let navbarHeight = $(".navbar").height()

        if (window.pageYOffset+navbarHeight+MARGIN_TOP > offset) {
            $("#scroll-placeholder").height($("#"+this.props.id).height())
            controls.classList.add("scroll-top-sticker")
            controls.classList.add("padding-top-20px")
            if(!this.isSticky) {
                this.isSticky = true
                if(this.props.onStick) this.props.onStick()
            }
        } else {
            $("#scroll-placeholder").height(0)
            controls.classList.remove("scroll-top-sticker")
            controls.classList.remove("padding-top-20px")
            if(this.isSticky) {
                this.isSticky = false
                if(this.props.onUnstick) this.props.onUnstick()
            }
        }
    }

    render() {
        return (
            <div id="scroll-top-container">
                <div id={this.props.id}>
                    {this.props.children}
                </div>
                <div id="scroll-placeholder" style={{width: "100%"}}></div>
            </div>
        )
    }
}

export default ScrollTopSticker