import React from 'react'
import BaseDialog from './BaseDialog';

class SaveWithWarningDialog extends BaseDialog {
    constructor(props) {
        super(props)
        this.state = {
            shouldConfirm: false
        }
    }

    onClose = () => {
        this.hide()
        this.props.closeHandler()
    }

    show() {
        super.show()
        this.setState({shouldConfirm: false})
    }

    onSave = () => {
        if (this.props.shouldConfirm() && !this.state.shouldConfirm){
            this.setState({shouldConfirm: true})
        } else {
            this.setState({shouldConfirm: false})
            this.props.saveHandler()
            this.hide()
        }
    }

    getButtons(){
        if (this.state.shouldConfirm){
            return <div className='alert alert-danger'>
            {this.props.warningLabel}
            <div style={{marginTop: 10, display: "flex", justifyContent: "end"}}>
                <button onClick={() => {this.setState({shouldConfirm: false})}} type="button" className="btn btn-default margin-right-10px">Cancel</button>
                <button onClick={this.onSave} type="button" className="btn btn-danger" disabled={this.props.disableSave}>Accept</button>
                </div>
            </div>
        } else {
            return <div>
                <button onClick={this.onClose} type="button" className="btn btn-default margin-right-10px" >{ this.props.closeText ? this.props.closeText : 'Close' }</button>
                <button onClick={this.onSave} type="button" className="btn btn-primary" disabled={this.props.disableSave}>{ this.props.saveText ? this.props.saveText : 'Save' }</button>
            </div>
        }
    }
}

export default SaveWithWarningDialog