import React from 'react'
import ShowIf from './Generic/ShowIf'
import { connect } from 'react-redux'
import { readNotification, unreadNotification, readAllNotifications } from '../actions/NotificationActions'
import { Link } from 'react-router-dom'
import NotificationItem from './NotificationItem'

const mapStateToProps = (state, ownProps) => {
	return {
        notifications: state.Notification.notifications,
		unreadNotifications: state.Notification.unreadNotifications
	}
}

class NavigationBarNotifications extends React.PureComponent {
    state = {
        filter: true
    }

    readNotification = (nId) => {
        this.props.dispatch(readNotification(nId))
    }
    
    unreadNotification = (nId) => {
        this.props.dispatch(unreadNotification(nId))
    }

    readAll = () => {
        this.props.dispatch(readAllNotifications())
    }

    render() {
        let notifications = (this.state.filter ? this.props.unreadNotifications : this.props.notifications).slice(0,10)
        return (
            <div className={`dropdown-menu notifications-dropdown-menu dropdown-menu-right ${this.props.open ? "show" : ""}`} role="menu">
                <div className="notifications-dropdown-menu-header">
                    <div>Notifications</div>
                    <div className="btn-group notifications-dropdown-menu-header-filter">
                        <button className={`btn btn-xs ${this.state.filter ? "btn-default" : "btn-primary"}`} onClick={() => this.setState({filter: false})}>All</button>
                        <button className={`btn btn-xs ${this.state.filter ? "btn-primary" : "btn-default"}`} onClick={() => this.setState({filter: true})}>Unread</button>
                    </div>
                    <div className="notifications-dropdown-menu-header-mark-all-as-read">
                        <span className="link" onClick={this.readAll}>Mark all as read</span>
                    </div>
                </div>
                <ShowIf if={notifications.length === 0}>
                    <div className="notifications-dropdown-menu-empty">
                        <i>You have no {this.state.filter ? "unread" : ""} notifications...</i>
                    </div>
                </ShowIf>
                <div className="notification-item-list">
                    {
                        notifications.map(n => 
                            <NotificationItem key={n.id} notification={n} readNotification={this.readNotification} unreadNotification={this.unreadNotification} onClose={this.props.onClose} />
                        )
                    }
                </div>
                <div className="notifications-dropdown-menu-footer">
                    <Link to={"/notifications"} onClick={this.props.onClose}>
                        <span className="link">See all notifications</span>
                    </Link>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(NavigationBarNotifications)