import "isomorphic-fetch"
import cookie from 'react-cookies'
import request, { SuperAgentRequest, Response } from 'superagent'
import { generalApiError } from '../actions/SystemActions'
import { notifyFailure } from "./NotificationManager"

const tokenName = 'abc_cloud_user'
let store:any|null = null
let apiCallCount = 0

export const getApiCallCount = () => {
    return apiCallCount
}

const handleResponse = (cb:(p:any)=>void, cbErr:(p:any)=>void) => (err:any, res:Response) => {
    // @ts-ignore
    if (apiCallCount > 0 && !(res?.req?.url.endsWith("/ping") || res?.req?.url.endsWith("/notifications/unread"))) apiCallCount--
    if (err !== null) {
        if(res === undefined){
            notifyFailure("Network error", "Network error, failed to get a response from server", false, true);
        }
        else if (cbErr) {
            cbErr(err)
        } else {
            store.dispatch(generalApiError())
        }
        console.log(err)
        return
    }
    
    if (cb) {
        cb(res.body)
    }
}

const doRequest = (request:SuperAgentRequest, cb:(p:any)=>void, cbError:(p:any)=>void) => {
    // Ignores pings and notifications
    if(!(request.url.endsWith("/ping") || request.url.endsWith("/notifications/unread"))) apiCallCount++
    
    let tmpToken = getToken()
    request
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${tmpToken}`)
        .end(handleResponse(cb, cbError))
}

export const apiGet = (endpoint:string, cb:(p:any)=>void, cbError:(p:any)=>void) => {
    doRequest(
        request.get(getAddress(endpoint)),
        cb,
        cbError)
}

export const apiPost = (endpoint:string, data:any, cb:(p:any)=>void, cbError:(p:any)=>void) => {
    doRequest(
        request.post(getAddress(endpoint)).send(data),
        cb,
        cbError)
}

export const apiPing = (cb:(p:any)=>void, cbError:(p:any)=>void) => {
    doRequest(
        request.post(getAddress('ping')),
        cb,
        cbError)
}

export const apiPut = (endpoint:string, data:any, cb:(p:any)=>void, cbError:(p:any)=>void) => {
    doRequest(
        request.put(getAddress(endpoint)).send(data),
        cb,
        cbError)
}

export const apiDelete = (endpoint:string, cb:(p:any)=>void, cbError:(p:any)=>void) => {
    doRequest(
        request.delete(getAddress(endpoint)),
        cb,
        cbError)
}

export const getAddress = (endpoint:string) => {
    if (window.location.host.indexOf('localhost') !== -1) {
        return `//localhost:8000/api/${endpoint}`
    } else if (window.location.host.indexOf('127.0.0.1') !== -1) {
        return `//127.0.0.1:8000/api/${endpoint}`
    }

    let port = window.location.port !== '' ? ':'+window.location.port : ''
    return `${window.location.protocol}//${window.location.hostname}${port}/api/${endpoint}`
}

export const isLocalhost = () => {
    return window.location.host.indexOf('localhost') !== -1
}

export const getServer = () => {
	if (window.location.host.indexOf('localhost') !== -1) {
        return `//localhost:8000/`
	}
	
	return `//${window.location.host}/`
}

export const getVilfredoAddress = (endpoint:string) => {
	return `${getServer()}vilfredo/${endpoint}`
}

export const getApiAddress = (endpoint:String) => {
	return `${getServer()}api/${endpoint}`
}

export const getHeaders = () => {
    let tmpToken = getToken()
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tmpToken}`
    }
}

export const clearToken = () => {
    cookie.remove(tokenName, { path: '/' })
}

export const saveToken = (token:string) => {
    cookie.save(tokenName, token, { path: '/' })
}

export const getToken = () => {
    let tmpToken = cookie.load(tokenName)
    if (null != tmpToken) {
        return tmpToken
    }
    return null
}

export const setStore = (s:any) => {
    store = s
}

export const apiCall = (endpoint:string, method:string, data:any) => {
	return fetch(getAddress(endpoint), {
		headers: getHeaders(),
		method: method,
		body: JSON.stringify(data)
	}).then((res) => {
		if (res.ok) {
			return res.json()
		} else {
			throw Error(res.statusText)
		}
	}).catch((e) => {
		throw Error(e.statusText)
	})
}

export const apiCallRaw = (endpoint:string, method:string, data:any, cb:(res:globalThis.Response) => Promise<any>, cbErr: (e:unknown) => void) => {
    return fetch(getAddress(endpoint), {
		headers: getHeaders(),
		method: method,
		body: JSON.stringify(data)
	}).then((res) => {
		return cb(res)
	}).catch((e) => {
		cbErr(e)
	})
}