import React from 'react'
import BaseSideBar from './BaseSideBar'
import ImageCarousel from '../ImageCarousel'
/*CSS*/
import '../../css/sidebar.css'

class SupportSideBar extends BaseSideBar {
    extraClass() {
        return 'support-sidebar'
    }

    supportImages = [
        {
            id: "1",
            src: "./img/troels.png"
        },
        {
            id: "2",
            src: "./img/rasmus.png"
        },
        {
            id: "3",
            src: "./img/lucas.png"
        },
        {
            id: "4",
            src: "./img/hans_christian.png"
        },
        {
            id: "5",
            src: "./img/zofia.png"

        }
    ] //order is randomized further down

    content() {
        return (
            <article className="support-sidebar-container">
                <div className=" support-sidebar-close">
                    <span className="fa fa-times close-button" onClick={() => this.hide()}/>
                </div>
                <div className="support-sidebar-content-wrapper">
                    <header className="support-sidebar-header">
                        <h4 className="margin-bottom-5">We are here <br/> to support you</h4>
                        <div className="support-sidebar-carousel">
                        <ImageCarousel images={this.supportImages.sort(() => Math.random() - 0.5)} interval={5000}/>
                        </div>
                    
                    </header>
                    <section className="support-sidebar-content">
                        <section className="support-sidebar-section">
                            <h5>Contact us</h5>
                            <div className="support-sidebar-contact-info-container support-sidebar-section-container">
                                <address className="support-sidebar-contact-info">
                                    <a className="support-sidebar-contact-info-item support-email-link" href="mailto:support@inact.io">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        support@inact.io
                                    </a>
                                    <span className="support-sidebar-contact-info-item" ><i className="fa fa-phone" aria-hidden="true"></i>+45 72 20 72 61</span>
                                </address>
                                <div className="support-sidebar-opening-hours">
                                    <div className="support-sidebar-opening-hours-container">
                                        <span>Mon - Fri</span>
                                        <span>9.00 - 16.00 CET</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="support-sidebar-section">
                            <h5>Or visit</h5>
                            <div className="support-sidebar-help-center-link-container support-sidebar-section-container">
                                <a className="support-sidebar-help-center-link" href="https://help.inact.io" target="_blank" rel="noreferrer">
                                    <img src={"./img/inact_help.png"} style={{width: "100px"}} alt="Help Center"  />
                                </a>
                            </div>
                        </section> 
                    </section>
                </div>
            </article>
        )
    }
}

export default SupportSideBar
