/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getItemCategoryHistoryGraphAll } from '../../actions/ItemActions'
import SingleItemColumnBoxSingle from './SingleItemColumnBoxSingle'
import * as ActionTypes from "../../actions/ActionTypes"
import { packAction } from "../../actions/ActionTypes"
import ShowIf from '../Generic/ShowIf'

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

class SingleItemColumnBoxes extends React.Component {

   constructor(props) {
        super(props)
        this.state = {
            updatedBoxes: [...props.bucket.setup.column_boxes],
            editing: true,
            loading: false,
        }
   }
   
    componentDidMount() {
        let { dispatch, bucket, item } = this.props
        const infoColumns = bucket.setup.column_boxes
        dispatch(getItemCategoryHistoryGraphAll(bucket.id, item, infoColumns))
    }
    
    componentDidUpdate(prevProps, prevState) {
        
        if(prevProps.historyData !== this.props.historyData) {
        this.setState({
            loading: false,
        })
        }
    }

    findData(data) {
        if (data.data === null) return null
        let res = null
        for (let i = data.data.length - 1; i >= 0; i--) {
            const elem = data.data[i]
            if (elem.found) {
                res = elem;
                break
            } 
        }
        return res
    }

    find2ndLast(data) {
        if (data.data === null) return null
        let res = null
        let foundFirst = false
        for (let i = data.data.length - 1; i >= 0; i--) {
            const elem = data.data[i]
            if(!foundFirst && elem.found) foundFirst = true
            else if (foundFirst && elem.found) {
                res = elem
                break
            }
        }
        return res
    }

    cancelEditHandler(e) {
        e.stopPropagation()
    }
    
    saveEditHandler = (e) => {
        e.stopPropagation();
        e.preventDefault()

        const bucketSetup = {...this.props.bucket.setup}

        bucketSetup.column_boxes = this.state.updatedBoxes;

        this.props.dispatch(packAction(ActionTypes.UPDATE_BUCKET_SETUP, {bucket_id: this.props.bucket.id, data: bucketSetup }))
        this.setState({
            editing: false,
            loading: true,
        })

    }

    editHandler = () => {
        this.setState({
            editing: true,
        })
    }

    columnBoxChangedHandler = (data) => {
        let updated = [];
        updated = [...this.state.updatedBoxes];
        updated[data.index] = data.columnName;
        
        this.setState({updatedBoxes: updated})
        this.props.columnBoxesChangedHandler(this.props.bucket.id, updated)
    }

    render() {
        const { numberColumns, decimalColumn, bucket } = this.props
        if (!this.props.historyData || !this.props.decimalColumn) return null
        const boxes = bucket.setup.column_boxes.map((c, i) => {
            let data = null;
            let val = "";
            let sndLastVal = "";
            if (!c) {
                data = {
                    column: "",
                }
            } else { 
                data = this.props.historyData.get(this.props.bucket.id, this.props.item, c) ? this.props.historyData.get(this.props.bucket.id, this.props.item, c) : { data: null }
                val = this.findData(data)
                sndLastVal = this.find2ndLast(data)
            }
            
            return <SingleItemColumnBoxSingle key={i+c} loading={this.state.loading} columnBoxChanged={this.columnBoxChangedHandler} index={i} bucket={this.props.bucket} column={c} decimalColumn={this.props.decimalColumn} val={val} val2={sndLastVal} loadColumn={this.props.loadColumn} setColumn={this.props.setColumn} editMode={this.props.editMode} editing={this.state.editing} />
        }) 

        return (
                <div className="margin-bottom-25px" style={{marginTop:19}}>
                    <div className="row">
                        <div className="col-6 pl-0">
                            <div className="form-inline">
                                <select
                                    className="form-control custom-select"
                                    value={decimalColumn ? decimalColumn.name : ""}
                                    onChange={e => this.props.setColumn({ type: "decimal", name: e.target.value })}
                                    style={{width: "100%", height: 32}}
                                >
                                    {
                                        numberColumns.map((v, i) => <option key={i} value={v.name}>{v.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6 pl-1">
                            <div className="row">
                                <div className="ml-1">
                                    <div className="btn-group">
                                        <ShowIf if={this.props.zoom == 12}>
                                            <button type="button" onClick={() => this.props.setType("line")} className={`btn btn-sm btn-default ${this.props.type === "line" ? 'active' : ''}`}><i className="fa fa-line-chart"></i></button>
                                        </ShowIf>
                                        <button type="button" onClick={() => this.props.setType("bar")} className={`btn btn-sm btn-default ${this.props.type === "bar" ? 'active' : ''}`}><i className="fa fa-bar-chart"></i></button>
                                    </div>
                                </div>
                                <div className="ml-1 pt-sm-1 pt-md-1 pt-lg-0">
                                    <button onClick={() => this.props.setStartAtZero()} className={`btn btn-default btn-sm ${this.props.startAtZero ? 'active' : ""}`}>Show 0</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row margin-top-5px">
                        {<div className="col-md-12 no-padding row">
                            {boxes}
                        </div>}
                        {
                            (this.props.editMode && !this.state.editing) && (
                                <div className="column-boxes-btn-wrapper">
                                    <button onClick={this.editHandler} className="column-box-edit-btn btn-primary btn btn-default btn-xs">Edit</button>
                                </div>
                            )
                        }
                    </div>
                </div>
        )
    }
}

SingleItemColumnBoxes = connect(mapStateToProps)(SingleItemColumnBoxes)

export default SingleItemColumnBoxes
