import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getFavouriteReportInfo = reports => {
    return packAction(ActionTypes.GET_FAVOURITE_REPORTS_INFO)
}

export const gotFavouriteReportInfo = reports => {
    return packAction(ActionTypes.GET_FAVOURITE_REPORTS_INFO_DONE, reports)
}

export const getHomeInfo = () => {
    return packAction(ActionTypes.GET_HOME_INFO)
}

export const gotHomeInfo = info => {
    return packAction(ActionTypes.GET_HOME_INFO_DONE, info)
}

/**
 * 
 * @param {{maxcount?: number, search?: string, owner_id?: number, bucket?: string} | undefined} [options] query param options for getting library info
 * @returns 
 */
export const getLibraryInfo = options => {
    return packAction(ActionTypes.GET_LIBRARY_INFO, options)
}

export const gotLibraryInfo = info => {
    return packAction(ActionTypes.GET_LIBRARY_INFO_DONE, info)
}

export const tooManyInsights = (count) => {
    return packAction(ActionTypes.TOO_MANY_INSIGHTS, count)
}

export const moveReportsToGroup = (group_id, report_ids) => {
    return packAction(ActionTypes.MOVE_REPORTS_TO_GROUP, {group_id, report_ids})
}

export const assignReportsToOwner = (owner_id, report_ids) => {
    return packAction(ActionTypes.ASSIGN_REPORTS_TO_OWNER, {owner_id, report_ids})
}

export const deleteMultipleReports = (report_ids) => {
    return packAction(ActionTypes.DELETE_MULTIPLE_REPORTS, {report_ids})
}

export const changeMultipleReportStatus = (is_public, group_id, report_ids) => {
    return packAction(ActionTypes.CHANGE_MULTIPLE_REPORT_STATUS, {is_public, group_id, report_ids})
}