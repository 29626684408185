/* REACT */
import React from 'react'
import { getMaxX } from '../../../helpers/ReportHelpers'

class CategorizationTable extends React.Component {
    setSelectedCategory(name) {
        this.props.setSelectedCategory(name)
    }

    render() {
        const { categorizations, clickableCategories, selectedCategories } = this.props
        let ys = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
        const maxX = getMaxX(categorizations)
        let xs = Array(maxX+1).fill(0).map((x,i)=>i) //fills array with 0,1,2,... until maxX is reached.

        let getCategory = (x, y) => {
            if (categorizations !== undefined) {
                for (let i = 0; i < categorizations.length; i++) {
                    const position = categorizations[i].position
                    if (position.x === x && position.y === y) {
                        return categorizations[i]
                    }
                }
            }
        }

        let getSelectedCatColor = (name) => {
            if (selectedCategories.includes(name)) {
                return 'abc-monitor-slicer-selected'
            }
            return ''
        }

        return (
            <div>
                <table className="table table-bordered abc-monitor-graph-slicer-table border-bottom-0px">
                    <tbody>
                        {
                            ys.map((y) => {
                                return (
                                    <tr key={y}>
                                        {
                                            xs.map((x) => {
                                                let pos = getCategory(x, y);
                                                if (pos) {
                                                    let clickable = clickableCategories.includes(pos.name)
                                                    return (
                                                        <td className={`${!clickable ? "grey-striped-background half-opacity" : "abc-click"} unselectable ${getSelectedCatColor(pos.name)} `} key={x} onClick={() => {if(clickable) this.setSelectedCategory(pos.name)}}>
                                                            {pos.name}
                                                        </td>
                                                    )
                                                }
                                                return (
                                                    <td className="no-show-td" key={x}></td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default CategorizationTable