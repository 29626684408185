import React from 'react'
import { connect } from 'react-redux'
import { getUserInfo, saveUserInfo, initialize2FA, setup2FA, is2FAEnabled, disable2FA, resetSecret, getEmailSettings, saveEmailSettings, is2FAForced, getPasswordPolicy } from '../actions/UserActions'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { notifySuccess, notifyFailure } from '../helpers/NotificationManager'
import ShowIf from '../components/Generic/ShowIf'
import { getAddress } from '../helpers/ApiHelpers'
import Cleave from 'cleave.js/react'
import PasswordPolicy from '../components/PasswordPolicy'
import { checkPasswordPolicyViolation } from '../helpers/UserHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.info,
        secret: state.User.secret,
        is_enabled: state.User.isEnabled,
        result: state.User.result,
        emailSettings: state.User.email_settings,
        force2FA: state.User.force2FA,
        passwordPolicy: state.User.passwordPolicy
    }
}

class User extends React.Component {
    constructor() {
        super()
        this.state = {
            firstname: '',
            lastname: '',
            oldPassword: '',
            newPassword1: '',
            newPassword2: '',
            pwdError: false,
            passcode: "",
            emailSettings: null,
        }
    }
    namesLoaded = false

    componentDidMount() {
        setPageTitle("Settings")
        this.props.dispatch(getUserInfo())
        this.props.dispatch(getPasswordPolicy())
        this.props.dispatch(getEmailSettings())
        this.props.dispatch(is2FAEnabled())
        this.props.dispatch(is2FAForced())
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.result && this.props.result === "success") {
            this.props.dispatch(is2FAEnabled())
            this.props.dispatch(resetSecret())
            this.props.dispatch(is2FAForced())
        }

        if (this.props.emailSettings !== prevProps.emailSettings || this.state.emailSettings === null) {
            this.setState({ emailSettings: this.props.emailSettings })
        }

        if(!this.namesLoaded && this.props.user){
            if (this.props.user.firstname) this.setState({ firstname: this.props.user.firstname })
            if (this.props.user.lastname ) this.setState({ lastname: this.props.user.lastname })
           this.namesLoaded = true
        }
    }

    saveName() {
        let data = saveUserInfo(this.state.firstname, this.state.lastname)
        this.props.dispatch(data)
    }

    saveEmailSettings() {
        this.props.dispatch(saveEmailSettings({ settings: this.state.emailSettings }))
    }

    setup2FA() {
        const { dispatch } = this.props
        dispatch(setup2FA({ passcode: this.state.passcode }))
    }

    initialize2FA() {
        const { dispatch } = this.props
        dispatch(initialize2FA())
    }

    savePassword() {
        this.setState({ pwdError: false })
        let savePwd = saveUserInfo(undefined, undefined, this.state.oldPassword, this.state.newPassword1, this.state.newPassword2)
        savePwd.cb = (err, res) => {
            if (err) {
                this.setState({ pwdError: true })
                notifyFailure("Error changing password")
            } else {
                notifySuccess("Password changed")

                this.setState({
                    oldPassword: '',
                    newPassword1: '',
                    newPassword2: '',
                })

                this.refs.oldPassword.value = ''
                this.refs.newPassword1.value = ''
                this.refs.newPassword2.value = ''
            }
        }
        this.props.dispatch(savePwd)
    }

    disable2FA() {
        const { dispatch } = this.props
        dispatch(disable2FA())
        dispatch(is2FAForced())
    }

    render() {
        const policy = this.props.passwordPolicy ?? {options: []}
        const policyViolations = checkPasswordPolicyViolation(policy, this.state.newPassword1)

        let savePwdDisabled = this.state.newPassword1 === '' || this.state.newPassword1 !== this.state.newPassword2 || policyViolations.length > 0
        let pwdControlClass = 'form-control'
        let textClass = 'green'
        if (this.state.newPassword1 !== this.state.newPassword2) {
            pwdControlClass = pwdControlClass + ' red-border'
            textClass = 'red'
        }

        let saveDisabled = this.state.firstname === this.props.user.firstname && this.state.lastname === this.props.user.lastname
        let pwdMessage = <span />
        if (this.state.pwdError) {
            pwdMessage = <div className="alert alert-danger">Password not saved. Old password was incorrect or the new password did not meet the policy!</div>
        }

        let unwrap = (secret) => secret ? secret.secret : null

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>

                    <div className="col-md-8">
                       
                        <ShowIf if={!this.props.force2FA}>
                            <h1 className="mt-2">Edit</h1>
                            <hr className="margin-left-right-20" />
                            <div>
                                <div className="form-group">
                                    <label>First name</label>
                                    <input value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value })} className="form-control" type="text" />
                                </div>
                                <div className="form-group">
                                    <label>Last name</label>
                                    <input value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value })} className="form-control" type="text" />
                                </div>

                                <button disabled={saveDisabled} className="btn btn-primary" onClick={() => this.saveName()}>Save</button>
                            </div>
                            <hr className="margin-left-right-20" />
                            {pwdMessage}
                            <div>
                                <div className="form-group">
                                    <label>Old password</label>
                                    <input ref="oldPassword" onChange={e => this.setState({ oldPassword: e.target.value })} className="form-control" type="password" />
                                </div>
                                <div className="form-group">
                                    <label>New password</label>
                                    <input ref="newPassword1" onChange={e => this.setState({ newPassword1: e.target.value })} className={pwdControlClass} type="password" />
                                </div>
                                <div className="form-group">
                                    <label>Confirm new password</label>
                                    <input ref="newPassword2" onChange={e => this.setState({ newPassword2: e.target.value })} className={pwdControlClass} type="password" />
                                </div>
                                
                                <ShowIf if={this.state.newPassword1.length > 0 || this.state.newPassword2.length > 0}>
                                    <PasswordPolicy policy={this.props.passwordPolicy} password={this.state.newPassword1} />
                                    <span className={textClass}>'New password' and 'Confirm new password' must be identical<br /></span>
                                </ShowIf>
                                <br/>
                                <button className="btn btn-primary margin-top-5" onClick={() => this.savePassword()} disabled={savePwdDisabled}>Confirm password</button>
                            </div>
                            <hr className="margin-left-right-20" />
                            <div className="container">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <form id="email-settings">
                                            <label>Email preferences</label><br />
                                            <input type="radio" checked={this.state.emailSettings === "off"} onChange={e => this.setState({ emailSettings: "off" })} /> Receive no emails<br />
                                            <input type="radio" checked={this.state.emailSettings === "on"} onChange={e => this.setState({ emailSettings: "on" })} /> Receive email notifications <span className="fa fa-info-circle glyphicon-info-gray" title="Receive an email whenever someone tags you, assigns a checklist to you or you miss a deadline" /><br />
                                            <input type="radio" checked={this.state.emailSettings === "daily"} onChange={e => this.setState({ emailSettings: "daily" })} /> Receive a daily digest of unread notifications
                                        </form>
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-primary float-right" onClick={() => this.saveEmailSettings()}>Save preferences</button>
                                    </div>
                                </div>
                            </div>
                            <hr className="margin-left-right-20" />
                        </ShowIf>
                        <div className="container mb-2 margin-top-20">
                            <div className="row">
                                <div className="col-9 pl-0">
                                    <h2>Two-factor authentication</h2>
                                </div>
                                <div className="col-3">
                                    <span className="align-middle">
                                        <h5 className="mb-0">
                                            Status: <i className={`${this.props.is_enabled ? 'green' : 'red'}`}>{this.props.is_enabled ? 'Enabled' : 'Disabled'}</i>
                                        </h5>

                                    </span>
                                </div>
                            </div>
                        </div>
                        <p>Two-factor authentication is an extra layer of security for your account. When 2FA is enabled you will have to use a one-time passcode every time you login to Inact Now.
                        </p>
                        <hr className="margin-left-right-20" />
                        {
                            !this.props.is_enabled ?
                                <React.Fragment>
                                    <div><b>How it works</b></div>
                                    <p>1. Download an authenticator-app on your mobile device. We suggest:
                                    {/* <a href="https://www.microsoft.com/en-us/account/authenticator" rel="noopener noreferrer" target="_blank">Microsoft Authenticator</a> */}
                                    </p>
                                    <div className="container">
                                        <div className="row margin-bottom-10px">
                                            <div className="col-1 pl-0">
                                                <a href="https://www.microsoft.com/en-us/account/authenticator" rel="noopener noreferrer" target="_blank" role="button">
                                                    <img src="./img/ms_authenticator_logo.png" alt="" height="40" width="40" />
                                                </a>
                                            </div>
                                            <div className="col pl-0 padding-top-10px">
                                                <a href="https://www.microsoft.com/en-us/account/authenticator" rel="noopener noreferrer" target="_blank" role="button">
                                                    <b>Microsoft Authenticator</b>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        2. Press "Enable 2FA" and open your authenticator-app.
                                    </p>
                                    <div className="margin-bottom-10px">
                                        {
                                            !this.props.is_enabled && !this.props.secret ?
                                                <button className="btn btn-primary" onClick={() => this.initialize2FA()}>Enable 2FA</button>
                                                : null
                                        }
                                    </div>
                                    <ShowIf if={this.props.secret}>
                                    <p>
                                        <b>In Microsoft Authenticator:</b>
                                    </p>
                                    <p>
                                        3. Press "+" or "Add Account" button within the authenticator-app
                                    </p>
                                    <p>
                                        4. Choose the type of account you wish to add (in this case: "work/school").
                                    </p>
                                    <p>
                                        5. Press "Scan QR Code" and scan the code below:
                                    </p>
                                        <div className="margin-top-20px">
                                            <img src={`${getAddress('2faimage/' + unwrap(this.props.secret))}`} alt="" />
                                        </div>
                                        <div className="margin-top-20px">
                                            <div>
                                                <div className="form-group inline-block">
                                                    <label> 6. Passcode will appear in your authenticator-app (6 digits). Enter these below:</label>
                                                    <Cleave
                                                        options={{ blocks: [3, 3], numericOnly: true }}
                                                        onChange={(e) => this.setState({ passcode: e.target.rawValue })}
                                                        className="form-control input-lg width-110px font-size-20px"
                                                    />
                                                    {/* <input className="form-control width-200" maxLength={6} type="text" onChange={e => this.setState({ passcode: e.target.value })} /> */}
                                                    <button className="btn btn-primary margin-top-20px" onClick={() => this.setup2FA()}>Confirm passcode</button>
                                                    <button className="btn btn-default margin-top-20px margin-left-5px" onClick={() => this.props.dispatch(resetSecret())}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ShowIf>
                                </React.Fragment> :
                                <button className="btn btn-primary" onClick={() => this.disable2FA()}>Disable 2FA</button>
                        }
                    </div>

                    <div className="col-md-2"></div>
                </div>

            </div>
        )
    }
}

User = connect(mapStateToProps)(User)

export default User