import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { apiPost } from '../../../helpers/ApiHelpers'
import { DoubleKeyMap } from '../../../helpers/Collections'
import { getColumnsForProfit, getFilter } from '../../../helpers/ReportHelpers'

import ProfitReportView from "./ProfitReportView"
import { notifyApiFailure } from '../../../helpers/ErrorHelpers'

const ProfitReportViewContainer = (props) => {
    const { reportdata, slicers, profit } = props
    const dispatch = useDispatch()
    const [data, setData] = useState(null)
    const [gettingData, setGettingData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (profit !== null && profit.enabled  && props.enabled && data == null && !gettingData && !props.forPrint) { // we use the data fetched by secret component instead, when used for generating pdf
            setGettingData(true) // stop incoming renders also requesting data when we are already getting data
            fetchData(profit, reportdata)
        }
        if(props.forPrint){ // set data fetched by secret component when used for generating pdf
            setProfitData(props.secretHistoryData, reportdata.info.id, getColumnsForProfit(profit))
        }

    }, [dispatch, profit, reportdata])

    useEffect(() => {
        if (profit && profit.enabled && data && props.enabled && !props.forPrint) { // we use the data fetched by secret component instead, when used for generating pdf
            fetchData(profit, reportdata)
        }
    }, [JSON.stringify(slicers), reportdata.report.filter])


    const fetchData = (_profit, _reportdata) => {
        setIsLoading(true)
        const columns = getColumnsForProfit(_profit)
        const data = getDataObj(columns, _reportdata, slicers)
        const url = `report/data/history/${_reportdata.info.id}`


        if(props.show){ //profit is in focus
            apiPost(url, data,
                res => setProfitData(res, _reportdata.info.id, columns),
                e => notifyApiFailure(`getting KPI trend graph`, 'POST', url, data, e.status)
            )
        } else { //profit is not in focus - delay this request a little
            setTimeout(_ => {
                apiPost(url, data,
                    res => setProfitData(res, _reportdata.info.id, columns),
                    e => notifyApiFailure(`getting KPI trend graph`, 'POST', url, data, e.status)
                )
            }, 1000)
        }
    }

    const setProfitData = (res, id, columns) => {
        const historydataProfit = new DoubleKeyMap()
        res.forEach((d, i) => historydataProfit.set(id, columns[i].column, d))
        setData(historydataProfit.get1(id, null))
        setIsLoading(false)
    }

    const getDataObj = (columns, reportdata, slicers) => {
        return {
            aggregate_column: '', // group column is found in the backend
            group_column: reportdata.info.model.categorization_name,
            filter: getFilter(reportdata.report, slicers),
            sort_column: reportdata.report.sort_column === '-' ? null : reportdata.report.sort_column,
            sort_direction: reportdata.report.sort_direction === '-' ? null : reportdata.report.sort_direction,
            limit: reportdata.report.limit,
            kpis: JSON.stringify(columns),
        }
    }
    
    return <ProfitReportView {...props} data={data} useLoading={true} loading={isLoading} />
}

export default ProfitReportViewContainer
