/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getStockBalanceColumnData } from '../../actions/BucketActions'
import Drawer from '../Animation/Drawer'
import ShowIf from '../Generic/ShowIf'
import ReactMarkdown from 'react-markdown'


const mapStateToProps = (state, ownProps) => {
    return {
        stock_balance_data: state.Bucket.stockBalanceData,
    }
}

class SingleItemStockBalanceData extends React.Component {
    componentDidMount() {
        const { dispatch, bucket, itemId } = this.props
        dispatch(getStockBalanceColumnData(bucket.id, itemId))
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, bucket, itemId } = this.props
        if (prevProps.itemId !== itemId) {
            dispatch(getStockBalanceColumnData(bucket.id, itemId))
        }
    }

    render() {
        const setup = this.props.setup ?? {}
        const data = this.props.stock_balance_data.get("data", null)
        if (!data || !data.buckets) return (
            <div className="alert alert-danger margin-top-20px">Something went wrong and stock balance data was not found.</div>
        )

        const positives = data.buckets.filter(b => data.bucket.value > 0 ? b.value >= 0 : b.value > 0) // if bucket value is positive, then 0 i positive
        const negatives = data.buckets.filter(b => data.bucket.value <= 0 ? b.value <= 0 : b.value < 0) // reverse of this ^

        const left = data.bucket.value <= 0 ? negatives : data.bucket.value > 0 ? positives : []
        const right = data.bucket.value <= 0 ? positives : data.bucket.value > 0 ? negatives : []
        let leftSum = left.slice()
        leftSum.push(data.bucket)

        return (
            <div className="margin-top-5px">
                <div className="row">
                    <div className="col-md-5">
                        <p className="monitor-header">{setup.name}</p>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 padding-right-5px">
                        <div className="float-right padding-top-9px">
                            <button className={`btn btn-default btn-sm ${this.props.sum ? 'active' : ''}`} onClick={() => this.props.setSum(!this.props.sum)}>Sum</button>
                        </div>
                    </div>
                </div>
                {
                    setup.description ?
                        <React.Fragment>
                            <div className="row margin-bottom-20px">
                                <div className="col-md-6">
                                    <ReactMarkdown>{setup.description}</ReactMarkdown>
                                </div>
                            </div>
                        </React.Fragment>
                        : null
                }
                <div className="row">
                    <div className="col-md-5">
                        <div className="row padding-left-15px">
                            <table className="col-md-12 zero-margin table-bordered table-striped table-condensed">
                                <tbody>
                                    {
                                        this.props.sum ?
                                            <tr>
                                                <td><b>Sum</b></td>
                                                <td className="single-item-right-data-column"><b>{leftSum.map(b => b.value).reduce((total, num) => total + num)}</b></td>
                                            </tr>
                                            : null
                                    }
                                    <tr>
                                        <td><b>{data.bucket.name}</b></td>
                                        <td className="single-item-right-data-column">{data.bucket.value}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row padding-left-15px">
                            <Drawer expand={this.props.showPositives} skipFirstUpdate>
                                {
                                    <table className="col-md-12 zero-margin table-bordered table-striped table-condensed">
                                        <tbody>
                                            {
                                                left.map((b, i) =>
                                                    <tr key={i}>
                                                        <td><b>{b.name}</b></td>
                                                        <td className="single-item-right-data-column">{b.value ?? '-'}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                }
                            </Drawer>
                        </div>
                        <div className="row">
                            <ShowIf if={left.length > 0}>
                                <div className="col-md-12 margin-top-10px">
                                    <hr className="hr-color zero-margin" />
                                    <div className="width-100-p text-align-center">
                                        <button onFocus={(e) => e.target.blur()} onClick={() => this.props.setShowPositives(!this.props.showPositives)} className={`btn btn-default btn-xs expand-button`}>
                                            {this.props.showPositives ? "Hide all" : "Show all"}
                                        </button>
                                    </div>
                                </div>
                            </ShowIf>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                        {
                            right.length > 0 ?
                                <table className="col-md-12 zero-margin table-bordered table-striped table-condensed">
                                    <tbody>
                                        {
                                            this.props.sum ?
                                                <tr>
                                                    <td><b>Sum</b></td>
                                                    <td className="single-item-right-data-column"><b>{right.map(b => b.value).reduce((total, num) => total + num)}</b></td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            right.map((b, i) =>
                                                <tr key={i}>
                                                    <td><b>{b.name}</b></td>
                                                    <td className="single-item-right-data-column">{b.value}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                : <i>No data</i>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

SingleItemStockBalanceData = connect(mapStateToProps)(SingleItemStockBalanceData)

export default SingleItemStockBalanceData