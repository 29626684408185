import React from 'react'
import '../../css/sidebar.css'
import '../../css/colors.css'

class BaseSideBar extends React.Component {
    constructor(props){
        super()

        this.state = {
            isVisible: false
        }
    }

    show() {
        this.setState({isVisible: true})
	}

	hide() {
        this.setState({isVisible: false})
    }

    isVisible() {
        return this.state.isVisible
    }

    visible () {
        if (this.state.isVisible) {
            return "abc-menu-report-show"
        } else {
            return "abc-menu-report-close"
        }
    }

    content() {
        return this.props.children
    }

    extraClass() {
        return ''
    }

    render() {
        return (
            <div id="details-menu-report" className={`${this.visible()} sidebar ${this.extraClass()} ${this.props.className ? this.props.className : ''}`} >
                <div>
                    {this.content()}
                </div>
            </div>
        )
    }
}

export default BaseSideBar