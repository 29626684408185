import React from 'react'

class StandardTable extends React.Component {
    extraTableClass() {
        if (this.props.className !== undefined) return this.props.className
        return ''
    }

    render() {
        return (
        <table className={`table table-hover table-striped ${this.extraTableClass()}`}>
            {this.props.children}
        </table>
        )
    }
}

export default StandardTable