import React from 'react'

class DateSpan extends React.Component {
    render() {
        let parsedString = Date.parse(this.props.string)
        let date
        if(this.props.millis !== undefined) date = new Date(this.props.millis)
        else if(this.props.secs !== undefined) date = new Date(this.props.secs*1000)
        else if(this.props.string !== undefined && !isNaN(parsedString)) date = new Date(Date.parse(this.props.string))
        else return <span>-</span>
        
        return <span>{(date.getDate() < 10 ? "0" : "") + date.getDate() + "-" + (date.getMonth()+1 < 10 ? "0" : "") + (date.getMonth()+1) + "-" + date.getFullYear()}</span>
    }
}

export default DateSpan