import React from 'react'
import { getCanvasFont, getTextWidth } from '../../helpers/TextHelpers';
import ShowIf from './ShowIf';

// remember to specify what color you need with the color and type property when using Progress
class Progress extends React.Component {

    constructor(props){
        super(props)
        this.textRef = React.createRef();
        this.state = {
            textWidth: 0,
            progressText: "",
            textPosition: 0,
            enablePropgressNumber: props.enablePropgressNumber ?? false
        }
    }

    updateState(){
        let progress = this.props.max === 0 ? 50 : this.props.current / this.props.max * 100
        let progressRounded = Math.round(progress)

        if(progressRounded == 100 && this.props.current != this.props.max){
            progressRounded = 99
        }

        let progressText = this.props.max != 0 ? `${progressRounded}%` : "Empty"

        if(progressRounded == 100){
            progressText = "Done"
            progress = 50
        }

        let textWidth = getTextWidth(progressText, getCanvasFont(this.textRef.current))
        let textPosition = `calc(${progress + '%'} + ${textWidth / 2}px)`
        this.setState({progressText, textWidth, textPosition})
    }

    componentDidMount() {
        if (this.state.enablePropgressNumber){
           this.updateState()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.enablePropgressNumber && (prevProps.current != this.props.current || this.props.max != prevProps.max)){
            this.updateState()
        }
    }

    render() {
        let width = 0
        if (this.props.max !== 0) {
            width = (((this.props.current) / this.props.max * 100) + '%')
        }
        return (
            <div className={`progress-wrapper ${this.props.type}`} style={{backgroundColor: "transparent !important"}}>
                <ShowIf if={this.state.enablePropgressNumber}>
                        <span ref={this.textRef} style={{width: this.state.textPosition}} className='progress-number progress-number-color'>{this.state.progressText}</span>
                    
                </ShowIf>
                <div className={`progress ${this.props.type} ${this.props.max === 0 && this.state.enablePropgressNumber ? "bg-success" : ""}`} style={this.props.max === 0 && this.state.enablePropgressNumber? {opacity: 0.5} : this.props.reverse ? {flexDirection: "row-reverse"} : {}}>
                    <div className={`progress-bar bg-${this.props.color} no-transitions`} role="progressbar" aria-valuenow={this.props.current} aria-valuemin={this.props.min} aria-valuemax={this.props.max} style={{ width: width }}>
                        {/* <span className="sr-only">{this.props.current / this.props.max * 100}% Complete</span> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Progress