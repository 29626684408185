import React, { useEffect, useState } from 'react'
import { DashboardReport, DashboardReportRow, DashboardReportShortInfo } from '../../types/transfertypes';
import GenericDialog from './GenericDialog';
// @ts-ignore
import Select from '../Generic/CustomSelect'
import ShowIf from '../Generic/ShowIf';

export interface EditDashboardReportDialogProps {
    show: boolean,
    reportOptions: {label: string, value: DashboardReportShortInfo}[],
    report?: DashboardReport,
    saveReport: (report: DashboardReportRow) => void,
    onClose: () => void,
    isMain: boolean,
}

let EditDashboardReportDialog = (props: EditDashboardReportDialogProps) => {

    const [tmpLabel, setTmpLabel] = useState("")
    const [tmpReport, setTmpReport] = useState<{label: string; value: DashboardReportShortInfo} | undefined>(undefined)

    useEffect(() => {
        if (!props.show) {
            setTmpLabel("")
            setTmpReport(undefined)
            return
        }
        if (props.report != undefined){
            setTmpLabel(props.report.label)
        }
        let tmp = props.reportOptions.find(v => v.value.report_id == props.report?.report_id)
        setTmpReport(tmp)
    }, [props.report, props.show])


    let saveReport = () => {
        if (tmpReport == undefined) return
        let tmp: DashboardReportRow = {report_id: tmpReport.value.report_id, label: tmpLabel ?? "", hidden: false} // Hidden field does not matter
        props.saveReport(tmp)
    }

    let disableSave = (): boolean => {
        return tmpReport == undefined
    }

    let getButtons = () => {
        return <div>
            <button style={{marginRight: 7}} onClick={saveReport} disabled={disableSave()} className='btn btn-primary'>Save</button>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const oldReport = props.reportOptions.find(x => props.report != undefined && x.value.report_id == props.report?.report_id)

    return (
        <div>
	        <GenericDialog onClose={props.onClose} title="Edit Insight row" show={props.show} getButtons={getButtons} style={{width: 400}}>
                <label htmlFor='report-label'>Label</label>
                <input id='report-label' type="text" placeholder="Label (optional)" style={{marginBottom: 10}} className="form-control" value={tmpLabel} onChange={(e) => setTmpLabel(e.target.value)} />
                <label>Insight</label>
                <Select 
                    onChange={(v: { label: string; value: DashboardReportShortInfo }) => setTmpReport(v)}
                    options={props.reportOptions}
                    isSearchable
                    value={tmpReport}
                    placeholder="Choose Insight"
                />

                <ShowIf if={props.isMain && tmpReport != undefined && oldReport != undefined && tmpReport.value.bucket != oldReport.value.bucket}>
                    <div style={{color: "red", marginTop: 8}}>
                        <span><b>Attention: </b>The chosen Insight has a different datasource, which may cause issues in the current dashboard.</span>
                    </div>
                </ShowIf>

	        </GenericDialog>

        </div>
    )
}

export default EditDashboardReportDialog
