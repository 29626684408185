import * as ActionTypes from '../actions/ActionTypes'
import { SingleKeyMap, DoubleKeyMap, TripleKeyMap } from '../helpers/Collections'

const defaultState = {
    pastedChecklistComments: new SingleKeyMap(),
    items: new DoubleKeyMap(),
    checklistExists: true,
    checklistAccessible: true,
    itemsList: new TripleKeyMap(),
    totals: new DoubleKeyMap(),
    kpiTotals: new DoubleKeyMap(),
}

const Checklist = (state = defaultState, action) => {
    let itemsList
    switch (action.type) {
        case ActionTypes.GET_CHECKLIST_DONE:
            let checklists = new SingleKeyMap(state.checklists)
            checklists.set(action.payload.id, action.payload.data)
            return Object.assign({}, state, { checklists: checklists })
        case ActionTypes.GET_CHECKLIST_ITEMS_DONE:
            let items = new DoubleKeyMap(state.items)
            items.set1(action.payload.checklistId, new SingleKeyMap())
            action.payload.items.forEach(i => items.set(action.payload.checklistId, i.id, i))
            return Object.assign({}, state, { items: items })
        case ActionTypes.GET_CHECKLIST_CATEGORY_ITEMS_DONE:
            itemsList = new TripleKeyMap(state.itemsList)
            if (action.payload.reset) {
                itemsList.set2(action.payload.checklistId, action.payload.category, new SingleKeyMap())
            }
            let totals = new DoubleKeyMap(state.totals)
            action.payload.items.forEach(i => itemsList.set(action.payload.checklistId, action.payload.category, i.id, i))
            totals.set(action.payload.checklistId, action.payload.category, action.payload.total)
            let kpiTotals = new DoubleKeyMap(state.kpiTotals)
            kpiTotals.set(action.payload.checklistId, action.payload.category, action.payload.kpiTotal)
            return Object.assign({}, state, {itemsList: itemsList, totals: totals, kpiTotals: kpiTotals})
        case ActionTypes.CLEAR_CHECKLISTS_PROPS:
            itemsList = new TripleKeyMap(state.itemsList)
            for (let i = 0; i < action.payload.length; i++) {
                itemsList.set1(action.payload[i], new DoubleKeyMap())
            }
            return Object.assign({}, state, {itemsList: itemsList})
        case ActionTypes.RESET_CHECKLIST_CATEGORY_ITEMS:
            return Object.assign({}, state, {itemsList: new TripleKeyMap(), totals: new DoubleKeyMap()})
        case ActionTypes.GET_LOCAL_CHECKLIST_COMMENTS_DONE:
            let comments = new SingleKeyMap(state.pastedChecklistComments)
            comments.set(action.payload.item_id, action.payload.data)
            return Object.assign({}, state, { pastedChecklistComments: comments })
        case ActionTypes.GET_ALL_LOCAL_CHECKLIST_COMMENTS_DONE:
            let checklistComments = new SingleKeyMap(state.pastedChecklistComments)
            if(action.payload) Object.keys(action.payload).forEach(id => checklistComments.set(id, action.payload[id]))
            return Object.assign({}, state, { pastedChecklistComments: checklistComments })
        case ActionTypes.GET_CHECKLIST_ITEM_DATA_DONE:
            let checklistItems = new SingleKeyMap(state.items)
            checklistItems.set(action.payload.checklist_id, action.payload.item.id, action.payload.item)
            return Object.assign({}, state, { checklistItems: items })
        case ActionTypes.GET_LAYOUTS_DONE:
            return Object.assign({}, state, { layouts: action.payload })
        case ActionTypes.GET_CHECKLIST:
            return Object.assign({}, state, { checklistExists: true, checklistAccessible: true })
        case ActionTypes.GET_CHECKLIST_NOT_FOUND:
            return Object.assign({}, state, { checklistExists: false })
        case ActionTypes.GET_CHECKLIST_NOT_ACCESSIBLE:
            return Object.assign({}, state, { checklistAccessible: false })
        case ActionTypes.SAVE_LAYOUT_DONE:
            return Object.assign({}, state, { newLayout: action.payload })
        case ActionTypes.RESET_CHECKLIST_ITEMS: 
            itemsList = new TripleKeyMap(state.itemsList)
            itemsList.set1(action.payload.id, new DoubleKeyMap())
            let totals2 = new DoubleKeyMap(state.totals)
            totals2.set1(action.payload.id, new SingleKeyMap())
            return Object.assign({}, state, {itemsList: itemsList, totals: totals2})
        case ActionTypes.GOT_ACTION_TEMPLATES:
            return Object.assign({}, state, {actionTemplateGroups: action.payload})
        default:
            return state
    }
}

export default Checklist
