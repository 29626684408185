import React, { useState } from "react";
import { MailBundleWithRelations, Plan } from "../../types/reportingtypes";
import GenericDialog from "../Dialogs/GenericDialog";
import AlertBanner from "../Alert/AlertBanner";
import { Report } from "../../types/transfertypes";
import { GoGraph } from "react-icons/go";
import { FaCalculator, FaTable, FaTh, FaWindowMaximize } from "react-icons/fa";


interface TrendViewMailSignupModalProps {
    show: boolean;
    onClose: () => void;
    onSubscribe: (plan: Plan, type: "matrix" |"trend"|"profit"|"kpi"|"sum") => void;
    onUnsubscribe: (type: "matrix" |"trend"|"profit"|"kpi"|"sum") => void;
    plans: Plan[]
    mailbundle: MailBundleWithRelations | undefined;
    subscription: MailBundleWithRelations | undefined
    subscribed: boolean
    report: Report
    type: "matrix" |"trend"|"profit"|"kpi"|"sum"
}

function InsightViewMailSignupModal(props:TrendViewMailSignupModalProps) {
    const plans = props.plans.slice().sort((a,b) => a.description.localeCompare(b.description))
    const [selectedPlanId, setSelectedPlanId] = useState<number>(plans[0].id)
    
    const getPlan = (plan_id: number): string => {
        var plan = props.plans.find(x => x.id === plan_id);
        if(plan !== undefined){
            let description = plan.description.substring(4).replace("on the", "")
            description = description[0].toLowerCase() + description.slice(1)
            return description
        }
        else return "??"
    }

    const submitHandler = () => {
        if(props.subscribed) {
            props.onUnsubscribe(props.type)
        } else {
            props.onSubscribe(props.plans.find(p => p.id === selectedPlanId) ?? props.plans[0], props.type)
            props.onClose()
        }
    }

    const getTypeTitleString = () => {
        if(props.type === "kpi") return "KPI"
        return props.type[0].toUpperCase() + props.type.substring(1).toLowerCase()
    }

    const getTypeIcon = () => {
        switch(props.type){
            case "matrix":
                return <FaTh className="mb-1 mr-1" />
            case "trend":
                return <GoGraph className="mb-1 mr-1"/> 
            case "profit":
                return <FaCalculator className="mb-1 mr-1"/>
            case "kpi":
                return <FaWindowMaximize className="mb-1 mr-1"/>
            case "sum":
                return <FaTable className="mb-1 mr-1"/>
            default:
                return null
        }
    }

    return (
        <GenericDialog
            show={props.show}
            onClose={props.onClose}
            title={props.subscribed 
                ? <span className="d-flex flex-column">
                    Unsubscribe from {getTypeTitleString()} 
                    <span className="font-weight-bold h6">
                        {getTypeIcon()}
                        {props.report.name}
                    </span>
                    </span> 
                : <span className="d-flex flex-column">Subscribe to {getTypeTitleString()} <span className="font-weight-bold h6">{getTypeIcon()} {props.report.name}</span></span>
            }
            getButtons={() => (
                <div className='d-flex'>
                    <button 
                        onClick={props.onClose} 
                        type="button" 
                        className="btn btn-default margin-right-10px" 
                    >
                        Close
                    </button>
                    <button 
                        onClick={submitHandler} 
                        type="button" 
                        className={`${props.subscribed ? "btn-danger" : "btn-primary"} btn margin-right-10px` }
                    >
                        {props.subscribed ? "Unsubscribe" : "Subscribe"}
                    </button>
                </div>
            )}
            style={{width: 510}}
        >
            {props.mailbundle && 
                <AlertBanner
                    config={{
                        text: <span>
                            You already recieve this {getTypeTitleString()} {getPlan(props.mailbundle.plan_id ?? -1)}.<br/>To <strong>unsubscribe</strong> click the link in the bottom of the mail you recieved, 
                            or contact <a className="" href="mailto:support@inact.io">support@inact.io</a>.
                        </span>,
                        title: "",
                        type: "warning",
                    }}

                />
            }
            {props.subscription && props.subscribed &&
                <AlertBanner
                    config={{
                        text: `You have already subscribed to recieve this ${getTypeTitleString()} ${getPlan(props.subscription.plan_id ?? -1)}`,
                        title: "",
                        type: "warning",
                    }}
                />
            }
            {!props.subscribed && <div className='d-flex flex-column px-2 py-4'>
                <span className='font-weight-bold'>
                    Select schedule
                </span>
                <select onChange={(e) => setSelectedPlanId(Number(e.target.value))} className="p-1">
                    {plans.map((p, i) => (
                        <option key={i} value={p.id}>{p.description}</option>
                    ))}
                </select>
            </div>}
        </GenericDialog>
    )
}

export default InsightViewMailSignupModal