import React from 'react'
import posed from 'react-pose'
import LoadingIcon from './LoadingIcon'

const LabelSpan = posed.span({
    hidden: { opacity: 0.0 },
    shown: { opacity: 1.0 }
})

const SpinnerSpan = posed.span({
    hidden: { opacity: 0.0 },
    shown: { opacity: 1.0 }
})

class ChecklistItemCountLabel extends React.Component {
    render() {
        return (
            <React.Fragment>
                <LabelSpan style={{width:"35px", height:"22px"}} className={`badge home-checklist-count font-size-14px margin-left-8px  ${this.props.count === 0 ? "green-target" : "custom-badge"}`} pose={this.props.loading ? "hidden" : "shown"}>
                    {this.props.count}
                </LabelSpan>
                <SpinnerSpan pose={this.props.loading ? "shown" : "hidden"} initialPose="hidden">
                    <LoadingIcon size="tiny" loading={this.props.loading} className="inline-block vertical-align-middle margin-left-10px" />
                </SpinnerSpan>
            </React.Fragment>
        )
    }
}

export default ChecklistItemCountLabel