/* REACT */
import React from 'react'
import { getGraphData, getGraphOptions, getGraphLabels } from '../../../helpers/GraphHelpers'
import { Bar } from 'react-chartjs-2'
import GraphControls from './GraphControls'
// import { connect } from 'react-redux'

class GraphItem extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.kpis.length !== nextProps.kpis.length) {
            return false
        }
        return true
    }

    render() {
        let labels = getGraphLabels(this.props.historydata ? this.props.historydata[0] : [], this.props.graphOptions)
        let data = getGraphData(this.props)
        let options = getGraphOptions(this.props, data, false, labels)

        let datasetcount = this.props.historydata && this.props.historydata[0] ? this.props.historydata[0].filter(x => x.found).length : 0;

        let datasetfound = this.props.historydata && this.props.historydata[0] ? this.props.historydata[0].map(x => x.found) : []
     

        return (
            <div className="trend-graph-container" >
                {/* <GraphNameItem report={this.props.reportdata.report} kpi1={this.props.kpi1} kpi2={this.props.kpi2} kpis={this.props.kpis} lastAdded={this.props.lastAdded} graphOptions={this.props.graphOptions} setGraphOptions={this.props.setGraphOptions} /> */}
                <div className="graph-controls-container">
                    <GraphControls
                        setKpisSettings={(kpi1, kpi2) => this.props.setKpisSettings(kpi1, kpi2)}
                        report={this.props.reportdata.report}
                        info={this.props.reportdata.info}
                        kpi1={this.props.kpi1}
                        kpi2={this.props.kpi2}
                        kpis={this.props.kpis}
                        lastAdded={this.props.lastAdded}
                        graphOptions={this.props.graphOptions}
                        setGraphOptions={this.props.setGraphOptions}
                        forPrint={this.props.forPrint || datasetcount < 2}
                        datasetFound={datasetfound}
                        goToTrend={(kpi, index) => this.props.goToTrend(kpi, index)}
                    />
                </div>
                <div className="trend-graph" style={this.props.forPrint ? { maxWidth:1470} : {}}>
                    <Bar className="margin-right-10px" redraw={true} height={60} data={{ labels: labels, datasets: data }} options={options} />
                </div>
            </div>
        )
    }
}

// GraphItem = connect(mapStateToProps)(GraphItem)

export default GraphItem
