import React from 'react'
import { connect } from 'react-redux'

import { getIdColumn } from '../../../helpers/ReportHelpers'
import RequirePermission from '../../RequirePermission'
import { is } from '../../../helpers/PermissionHelpers'

const mapStateToProps = (state, ownProps) => {
    return { }
}

class CreateChecklistButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            itemCount: 0,
            idColumn: "",
        }
    }

    componentDidMount() {
        const idColumn = getIdColumn(this.props.columns)
        this.setState({ idColumn: idColumn})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.aggrData !== this.props.aggrData) {
            if (this.props.aggrData) {
                this.setState({ itemCount: this.props.aggrData.total.item_count })
            }
        }
    }

    render() {
        return (
            <button 
                disabled={!this.props.aggrData}
                title={'Create Action list'} 
                className="btn btn-primary btn-sm float-right" 
                onClick={() => this.props.createChecklist()}
            >
                <span className="fa fa-check margin-right-5px" />
                    <RequirePermission perms={is.checklistsCreator} alt={"Update Action list"}>
                        Create Action list 
                    </RequirePermission>
                {
                    this.props.amount > 0 ?
                        <span> ({this.props.amount})</span>
                    : null
                }   
            </button>
        )    
    }
}

CreateChecklistButton = connect(mapStateToProps)(CreateChecklistButton)
        
export default CreateChecklistButton