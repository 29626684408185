import React from 'react'
import SaveWithWarningDialog from './SaveWithWarningDialog'
import ChecklistLayoutEditor from '../ChecklistLayoutEditor'

class EditChecklistLayoutDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = { layout: [], deleted: [], added: [], hasOrderChanged: false, layoutChanged: false }
    }

    show(layout) {
        this.refs.layoutDialog.show()
        layout.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )
        this.setState({ layout: layout, deleted: [], added: [], layoutChanged: false, hasOrderChanged: false })
    }

    hide() {
        this.refs.layoutDialog.hide()
    }

    getLayoutWarningLabel = () => {
        if(!this.state.layout || this.state.layout.length === 0) return null

        let layout = this.state.layout
        return <>All items in the chosen checklists will be moved to category <i>"{layout[0].name}"</i>, do you want to continue?</>
    }

    shouldConfirm = () => {
        return this.state.layoutChanged
    }

    changeLayout = e => {
        const name = e.target.value
        this.props.layouts.forEach(l => {
            if (l.name === name) {
                this.setState({ layout: JSON.parse(l.layout ?? "{}"), layoutChanged: true })
                return
            }
        })
    }

    updateLayoutColor(i, c) {
        let temp = this.state.layout
        temp[i].color = c
        this.setState({ layout: temp })
    }

    updateDoneCount = (i,donecount) => {
        const temp = this.state.layout
        temp[i].donecount = donecount
        temp.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )
        this.setState({layout: temp, hasOrderChanged: true })
    }

    updateLayoutName(i, name) {
        let temp = this.state.layout
        temp[i].name = name
        this.setState({ layout: temp })
    }

    deleteCategory(i) {
        let temp = this.state.layout
        temp.splice(i, 1)
        let deleted = this.state.deleted.slice()
        deleted.push(i)
        this.setState({ tempLayout: temp, deleted: deleted })
    }

    addCategory(c) {
        let temp = this.state.layout
        temp.push(c)
        let added = this.state.added.slice()
        added.push(temp.indexOf(c))
        this.setState({ layout: temp, added: added })
    }

    onSortEnd(oldIndex, newIndex) {
        while(this.props.hideDeleteButtonPredicate(newIndex, this.state.layout)){
            newIndex++
        }
        let tmp = this.state.layout.slice()
        tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0])
        tmp.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )
        this.setState({ layout: tmp, hasOrderChanged: true })
    }

    onSave() {
        this.props.saveHandler(this.state.layout, this.state.deleted, this.state.hasOrderChanged, this.state.layoutChanged)
        this.setState({ hasOrderChanged: false })
    }

    render() {
        if (!this.state.layout) return null
        return (
            <SaveWithWarningDialog ref="layoutDialog" 
                                title="Edit layout" 
                                saveHandler={() => this.onSave()} 
                                closeHandler={() => { if (this.props.closeHandler) { this.props.closeHandler() } }}
                                warningLabel={this.getLayoutWarningLabel()}
                                saveText="Save and update"
                                shouldConfirm={this.shouldConfirm}
            >

                <ChecklistLayoutEditor
                    layout={this.state.layout}
                    onAddCategory={c => this.addCategory(c)}
                    onCategoryColorChange={(i, c) => this.updateLayoutColor(i, c)}
                    onCategoryNameChange={(i, n) => this.updateLayoutName(i, n)}
                    onCategoryDeletion={i => this.deleteCategory(i)}
                    warnCategoryDeletion={i => !this.state.added.includes(i)}
                    hideDeleteButtonPredicate={(i, l) => this.props.hideDeleteButtonPredicate(i, l)}
                    onSortEnd={(o, n) => this.onSortEnd(o, n)}
                    onUpdateDoneCount= {(i,c)=> this.updateDoneCount(i,c)}
                />
                <hr />
                <div>
                    <div className="standard-layout-text">
                        Or use a standard layout
                    </div>
                    <select value="" className="form-control form-control-sm inline-block width-150px float-right" onChange={this.changeLayout}>
                        <option value={""} style={{ display: "none" }}>Select layout</option>
                        {
                            this.props?.layouts?.map(l => (
                                <option key={l.name} value={l.name}>
                                    {l.name}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </SaveWithWarningDialog>
        )
    }
}

export default EditChecklistLayoutDialog