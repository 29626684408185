import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet, apiPost } from '../../helpers/ApiHelpers'
import { SingleKeyMap } from "../../helpers/Collections"
import KPIReportBanner from '../../components/Report/ReportComponents/KPIReportBanner'
import { getFilter, getIdColumn } from '../../helpers/ReportHelpers'
import { TripleKeyMap } from '../../helpers/Collections'
import { hashNValues } from '../../helpers/GeneralHelpers'
import KpiView from '../../components/Report/ReportComponents/KpiView'

function KPIViewSecret(){
    const { secret, rId, orgId, sHash} = useParams()
    const [data, setData] = useState()
    const [aggrData, setAggrData] = useState(new TripleKeyMap())
    const [doneFetching, setDoneFetching] = useState(false)

    const viewSettings = useMemo(() => {
        if(!data) return
        return JSON.parse(data.view_settings.settings)
    }, [data])
    const kpiSettings = useMemo(() => {
        if(!viewSettings) return
        return viewSettings.report.children.content.children.kpi
    }, [viewSettings])

    let getWidthForPlaywright = () => {
        return 1400 //fixed @ 1600
    };
    
    useEffect(() => {
        apiGet(`singletrendview/${rId}/${orgId}/${secret}/${sHash}`, data => {
            setData(data)
        }, error => console.log(error))


    }, [orgId, rId, sHash, secret])

    useEffect(() => {
        if(!data || !viewSettings || !kpiSettings) return
        getAllAggrData();
        getIdColumnAggr();
    }, [data])
    if (!secret || !rId || !sHash) return null;
    if (!data || !aggrData || !viewSettings || !kpiSettings || !aggrData.get2(getKPIKey(), rId).get("idColumn")) return null;
    if (!doneFetching) return null

    let historydata = new SingleKeyMap()
    historydata.set(data.report_data.info.id, data.history_data)

    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')
        
    function getKPIKey() {
        let reportdata = data.report_data
        let limit = reportdata.report.limit
        let filter = getFilter(reportdata.report, viewSettings.report.slicers ?? [])
        return hashNValues(limit, filter)
    }

    function getIdColumnAggr(){
        if(!data) return;
        const url = `singlereport/aggregate/${orgId}/${data.report_data.info.id}/${secret}`
        const column = getIdColumn(data.report_data.info.model.columns)
        const categorization = data.report_data.info.model.categorization_name
        const limit = data.report_data.report.limit
        const aggrQuery = {
            aggregate_column: column, 
            group_column : categorization,
            filter: getFilter(data.report_data.report, viewSettings.report.slicers ?? []),
            sort_column: data.report_data.report.sort_column,
            sort_direction: data.report_data.report.sort_direction,
            limit: limit,
            kpis: data.report_data.report.kpis,
        }
        apiPost(url, aggrQuery, result => {
            let agData = new TripleKeyMap(aggrData)
            agData.set(getKPIKey(), rId, "idColumn", result)
            setAggrData(agData)
        }, error => console.log(error))
    }

    function getAllAggrData(){
        if(!data) return
        setDoneFetching(false);
        const url = `singlereport/aggregate_multiple/${orgId}/${data.report_data.info.id}/${secret}`

        let kpis = JSON.parse(data.report_data.report.kpis)
        const kpiConfig = JSON.parse(data.report_data.report.kpi_config)
        const limit = data.report_data.report.limit
        let filter = getFilter(data.report_data.report, viewSettings.report.slicers ?? [])
        let q = {
            group_column: data.report_data.info.model.categorization_name,
            filter: filter,
            sort_column: data.report_data.report.sort_column === '-' ? null : data.report_data.report.sort_column,
            sort_direction: data.report_data.report.sort_direction === '-' ? null : data.report_data.report.sort_direction,
            limit: limit,
            kpis: data.report_data.report.kpis,
        }

        let queries = []

        kpis.forEach((item) => {
            let _q = Object.assign({}, q, { aggregate_column: item.column })
            queries.push(_q)
        })
        kpiConfig.forEach((item) => {
            if(item.shown){
                let _q = Object.assign({}, q, { aggregate_column: item.column })
                queries.push(_q)
            }
        })
        
        apiPost(url, {queries:queries}, result => {
            let aggrDataMap = new TripleKeyMap(aggrData)
            result.aggregations.forEach(item => {
                aggrDataMap.set(getKPIKey(), rId, "kpiView-" + item.aggregation_column, item.aggregation)
                aggrDataMap.set(getKPIKey(), rId, "" + item.aggregation_column, item.aggregation)
            })
            setAggrData(aggrDataMap)
            setDoneFetching(true);
        }, error => console.log(error))
    }

    return <div className="mt-2">
        <style> {/* This is needed for the view to be printed to pdf in landscape, can't be set globally as the dashboards needs to be portrait. */}
            {
                `@media print{
                    @page {
                        size: landscape !important;
                    }   
                }`
            }
        </style>
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
                
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                {/* reporting service waits for #dashboardTitle to load before it prints pdf, therefore this is needed */}
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.report_data.report.name}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>
        
        <div className='mb-4 ml-5' style={{ borderTop: "1px solid rgb(229 231 235)", marginLeft: 0, marginRight:50, maxWidth: 1600 }}>
            <KPIReportBanner
                reportdata={data.report_data}
                limit={data.report_data.report.limit}
                slicers={viewSettings.report.slicers ?? []}
                setData={null}
                editMode={null}
                clickable={true}
                kpiClicked={() => {}}
                getTrendSettings={() => {}}
                forPrint={true}
                secretAggrData={aggrData}
            />
            <KpiView
                show={true}
                enabled={true}
                settingsParent={this}
                settings={kpiSettings}
                onSettingUpdate={() => {}}
                reportdata={data.report_data}
                slicers={viewSettings.report.slicers ?? []}
                editMode={null}
                limit={data.report_data.report.limit}
                setData={null}
                kpiItems={JSON.parse(data.report_data.report.kpi_config)}
                addClickSlicer={() => {}}
                changeView={null}
                clickableCategories={data.report_data.info.model.categorization.map(c => c.name)}
                forPrint={true}
                secretAggrData={aggrData}
            />
        </div>

    </div>
}

export default KPIViewSecret