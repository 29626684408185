import * as ActionTypes from '../actions/ActionTypes'
import { SingleKeyMap } from '../helpers/Collections'

const defaultState = {
    version: '',
    apiError: false,
    errors: new SingleKeyMap()
}

const System = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_VERSION_DONE:
            return Object.assign({}, state, { version: action.payload })
        case ActionTypes.GET_ORG_USERS_DONE:
            return Object.assign({}, state, { users: action.payload })
        case ActionTypes.GENERAL_API_ERROR:
            return Object.assign({}, state, { apiError: true })
        case ActionTypes.REPORT_ERROR_DONE:
            let errors = new SingleKeyMap(state.errors)
            errors.set(action.payload.notificationId, action.payload.report)
            return Object.assign({}, state, { errors: errors })
        default:
            return state
    }
}

export default System