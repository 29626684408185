import React, { useState } from 'react'
import { getNumberColumnsObj, getShownColumns } from '../../helpers/ReportHelpers'

export default function SingleItemColumnBoxEdit(props) {

    const [column, setColumn] = useState(props.column)
    
    function deleteColumnBoxHandler(e) {
        setColumn("")
        props.columnBoxChanged({index: props.index, columnName: "", prevColumnName: props.column})
    }
    function changeColumnHandler(e) {
        setColumn(e.target.value)
        e.stopPropagation()
        e.preventDefault()
        props.columnBoxChanged({index: props.index, columnName: e.target.value, prevColumnName: props.column})
    }

    const options = props.bucket ? getShownColumns(getNumberColumnsObj(props.bucket.model), props.bucket.setup) : []
    options.unshift("")

    return(
      <>
        <article className="column-box-edit-wrapper">
            <select className="form-control column-box-edit-form-control " defaultValue={column ? column : options[0]} id="columnOptions" onChange={changeColumnHandler} onClick={(e) => e.stopPropagation()}>
                    { options.map((col, index) => 
                    <option key={index} value={col.name}>{col.name}</option>
                    )}
            </select>
                
            <section className="column-box-edit-btn-wrapper">
                <button className="column-box-edit-delete btn " onClick={(e) => deleteColumnBoxHandler(e)}><i className="fa fa-trash" aria-hidden="true"></i></button>
            </section>
        </article>
    </>)
}