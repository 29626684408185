import React, { useState, useRef, useLayoutEffect } from 'react'
import '../../css/PopOver.css'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { ReactNode } from 'react-markdown'

export interface PopOverProps {
    show: boolean,
    onClose?: () => void,
    children: ReactNode,
    style?: React.CSSProperties,
    className?: string
}

const PopOver = (props: PopOverProps) => {

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)

    const container = useRef<any>()

    useOnClickOutside(() => {
        if (props.show) {
            if (props.onClose) props.onClose()
        }
    }, container)

    useLayoutEffect(() => {
        positionSelf()
    }, [])

    useLayoutEffect(() => {
        if (props.show) {
            positionSelf()
        }
    }, [props.show])

    const positionSelf = () => {
        let rect = container?.current?.parentElement?.getBoundingClientRect()
        if (rect == undefined) return
        let containerRect = container.current.getBoundingClientRect()
        setX(Math.max(5, rect.x - containerRect.width + rect.width + window.scrollX))
        setY(Math.max(5, rect.y + rect.height + window.scrollY))
    }

    let customStyle = structuredClone(props.style ?? {})
    let className = props.className ?? ''

    let style = Object.assign(customStyle, {top: y, left: x})

    return (
            props.show ?
            <div ref={container} className={`popover-container ${className}`} style={style}>
                {props.children}
            </div>

            : <></>
           )}

export default PopOver


