import React from 'react'
import BgColor from '../components/Generic/BgColor'
import ShowIf from '../components/Generic/ShowIf'
import { parsePastedDataPreview } from '../helpers/PasteHelpers'
import StandardTable from '../components/Generic/StandardTable'
import { packAction } from '../actions/ActionTypes'
import * as ActionTypes from '../actions/ActionTypes'
import { connect } from 'react-redux'
import { setPageTitle } from '../helpers/DocumentHelpers'

import '../css/dimensions.css'
import '../css/colors.css'
import '../css/dimensions.css'

const MAX_ROWS = 20
const MAX_COLS = 5

class Paste extends React.Component {
    constructor(props) {
        super()
        this.state = {
            pasteData: "",
            displayState: 0,
            previewTableData: {data: [], tableData: [], tableHeaders: [], idColumns: [], rowCount: 0, columnCount: 0},
            idColumn: -1
        }
    }

    componentDidMount() {
        setPageTitle()
    }

    updateInput(event) {
        this.setState({pasteData: event.target.value})
    }

    showPreview() {
        let data = parsePastedDataPreview(this.state.pasteData, MAX_ROWS, MAX_COLS)
        this.setState({displayState: 1, previewTableData: data})
    }

    upload() {
        this.props.dispatch(packAction(ActionTypes.UPLOAD_LOCAL_CHECKLIST, {data: this.state.pasteData, idColumn: this.state.idColumn})) 
    }

    showInput() {
        this.setState({displayState: 0})
    }

    updateIdColumn(event) {
        this.setState({idColumn: event.target.value})
    }

    render() {
        return (
            <BgColor bgClass="background">
                <div className="container-fluid">
                    <ShowIf if={this.state.displayState === 0}>
                        <h1>Paste data</h1>
                        <p>Paste data to create new Action list</p>
                        <textarea className="form-control resize-vertical min-height-400px" value={this.state.pasteData} onChange={event => this.updateInput(event)} autoFocus/>
                        <button className="btn btn-default btn-primary margin-top-20px float-right" onClick={() => this.showPreview()}>Next</button>
                    </ShowIf>
                    <ShowIf if={this.state.displayState === 1}>
                        <h1>Preview</h1>
                        <p>Showing {Math.min(MAX_ROWS, this.state.previewTableData.rowCount)} of {this.state.previewTableData.rowCount} rows - {Math.min(MAX_COLS, this.state.previewTableData.columnCount)} of {this.state.previewTableData.columnCount} columns</p>
                        <StandardTable className="table-bordered table-condensed bg-white">
                            <thead>
                                <tr>
                                    { this.state.previewTableData.tableHeaders.map((cell,i) => <th key={i}>{cell}</th>) }
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.previewTableData.tableData.map((row, i) => <tr key={i}>{row.map((cell,j) => <td key={String(i)+" "+String(j)}>{cell}</td>)}</tr>) }
                            </tbody>
                        </StandardTable>
                        <div className="input-group display-flex">
                            <div className="input-group-addon width-100">
                                <label className="input-group-text">ID Column</label>
                            </div>
                            <select className="custom-select form-control" value={this.state.idColumn} onChange={event => this.updateIdColumn(event)} autoFocus>
                                <option value={-1} disabled>Select column</option>
                                {
                                    this.state.previewTableData.idColumns.map((col,i) => <option value={col.name} key={i}>{col.name}</option>)
                                }
                            </select>
                        </div>
                        <button className="btn btn-default margin-top-20px float-left inline-block" onClick={() => this.showInput()}>Back</button>
                        <button className="btn btn-default btn-primary margin-top-20px float-right inline-block" onClick={() => this.upload()} disabled={this.state.idColumn < 0}>Next</button>
                    </ShowIf>
                </div>
            </BgColor>
        )
    }
}

export default connect()(Paste)