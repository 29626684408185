import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet } from '../../helpers/ApiHelpers'
import { gotChecklistsStatus } from '../../actions/StatusActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'

const StatusApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_CHECKLISTS_STATUS:
            url = 'checklists/status'
            apiGet(url, checklists => dispatch(gotChecklistsStatus(checklists)), e => notifyApiFailure("loading Action list statuses", 'GET', url, data, e.status))
            break
        default:
            break
    }
}

export default StatusApiMiddleware