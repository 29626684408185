import React from 'react'
import BgColor from '../Generic/BgColor'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class NoUser extends React.Component {
    render() {
        const email = this.props.match.params.email ? this.props.match.params.email : ''
        return (
            <BgColor bgClass="loginBody">
                <div className='row centered-row'>
                <div className='col-md-6 login-left-side'>
                    <img src="./img/inact_now_logo.svg" alt="" />
                   
                    <div className='previously'>
                        <p className="login-white-text">Previously ABC Cloud by <a className="white-text" target="_blank" href="https://inact.io" rel="noopener noreferrer">ABC Softwork</a></p>
                    </div>

                </div>
                <div className='col-md-6'>
                <div className="login-box" style={{width:"90%"}}>
                    <h1 className="margin-bottom-20px">User not found</h1>
                    <br />
                    <div>
                        <p>No user with the provided email <i>{email}</i> was found.</p>
                        <p>Contact <a href="https://help.inact.io" target="_blank" rel="noopener noreferrer">Inact</a> for help.</p>
                        <Link to={'/login'} role="button">
                            <button className="btn btn-default">Back</button>
                        </Link>
                    </div>
                </div>
                </div></div>
            </BgColor>
        )
    }
}

NoUser = connect()(NoUser)

export default NoUser