import { RefObject } from 'react'

class DiffViewManager {
    static ref:RefObject<any>|null

    static setInstanceRef(r:RefObject<any>) {
        DiffViewManager.ref = r
    }

    static getInstanceRef() {
        return DiffViewManager.ref
    }
}
DiffViewManager.ref = null

export const setDiffViewRef = (ref:RefObject<any>) => DiffViewManager.setInstanceRef(ref)

export const showDiffViewDialog = (ref: any, state: any, fromMasterData: boolean) => DiffViewManager.ref?.current.show(ref, state, fromMasterData)

export const hideDiffViewDialog = (toMasterData: boolean) => DiffViewManager.ref?.current.hide(toMasterData)

export const setDiffViewSelectedItems = (ids: []) => DiffViewManager.ref?.current.setItems(ids)