import React from 'react'

const HomeIcon = (
    props: {
        add: boolean,
        width?: number,
        height?: number
    }
    ) => {
    
        return (
            <>
            {props.add ? 
                <svg width={props.width ?? 18} height={props.height ?? 19} viewBox="0 0 192 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* <path fillRule="evenodd" clipRule="evenodd" d="M192 183.983V91.533C192.012 89.3252 191.567 87.1389 190.692 85.1116C189.818 83.0842 188.534 81.2598 186.92 79.753L106.92 4.27299C106.881 4.23887 106.844 4.20212 106.81 4.16299C103.865 1.48432 100.026 0 96.045 0C92.0637 0 88.2254 1.48432 85.28 4.16299L85.17 4.27299L5.17 79.753C3.53998 81.2519 2.23884 83.0728 1.34892 85.1005C0.458992 87.1282 -0.000336147 89.3186 1.84569e-07 91.533V183.983C1.84569e-07 188.226 1.68571 192.296 4.68629 195.297C7.68687 198.297 11.7565 199.983 16 199.983H176C180.243 199.983 184.313 198.297 187.314 195.297C190.314 192.296 192 188.226 192 183.983ZM106.714 120.714H162.786C168.954 120.714 174 115.668 174 109.5C174 103.332 168.954 98.2857 162.786 98.2857H106.714V42.2143C106.714 36.0464 101.668 31 95.5 31C89.3321 31 84.2857 36.0464 84.2857 42.2143V98.2857H28.2143C22.0464 98.2857 17 103.332 17 109.5C17 115.668 22.0464 120.714 28.2143 120.714H84.2857V176.786C84.2857 182.954 89.3321 188 95.5 188C101.668 188 106.714 182.954 106.714 176.786V120.714Z" fill="#5F5F5F"/> */}
                    <path fillRule="evenodd" clipRule="evenodd" d="M192 91.533V183.983C192 188.226 190.314 192.296 187.314 195.297C184.313 198.297 180.243 199.983 176 199.983H123.25V144.192H123.221C123.24 143.765 123.25 143.336 123.25 142.904C123.25 127.493 110.882 115 95.625 115C80.3681 115 68 127.493 68 142.904C68 143.336 68.0097 143.765 68.0289 144.192H68V199.983H16C11.7565 199.983 7.68687 198.297 4.68629 195.297C1.68571 192.296 1.84569e-07 188.226 1.84569e-07 183.983V91.533C-0.000336147 89.3186 0.458992 87.1282 1.34892 85.1005C2.23884 83.0728 3.53998 81.2519 5.17 79.753L85.17 4.27299L85.28 4.16299C88.2254 1.48432 92.0637 0 96.045 0C100.026 0 103.865 1.48432 106.81 4.16299C106.844 4.20212 106.881 4.23887 106.92 4.27299L186.92 79.753C188.534 81.2598 189.818 83.0842 190.692 85.1116C191.567 87.1389 192.012 89.3252 192 91.533Z" fill="#5F5F5F"/>
                    
                </svg>
            : 
                <svg width={props.width ?? 18} height={props.height ?? 19} viewBox="0 0 192 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M192 183.983V91.533C192.012 89.3252 191.567 87.1389 190.692 85.1116C189.818 83.0842 188.534 81.2598 186.92 79.753L106.92 4.27299C106.881 4.23887 106.844 4.20212 106.81 4.16299C103.865 1.48432 100.026 0 96.045 0C92.0637 0 88.2254 1.48432 85.28 4.16299L85.17 4.27299L5.17 79.753C3.53998 81.2519 2.23884 83.0728 1.34892 85.1005C0.458992 87.1282 -0.000336147 89.3186 1.84569e-07 91.533V183.983C1.84569e-07 188.226 1.68571 192.296 4.68629 195.297C7.68687 198.297 11.7565 199.983 16 199.983H176C180.243 199.983 184.313 198.297 187.314 195.297C190.314 192.296 192 188.226 192 183.983ZM96.0039 135.433L129.577 169.006C133.27 172.699 139.313 172.699 143.006 169.006C146.699 165.313 146.699 159.27 143.006 155.577L109.433 122.004L143.006 88.4311C146.699 84.7381 146.699 78.695 143.006 75.002C139.313 71.3089 133.27 71.3089 129.577 75.002L96.0039 108.575L62.4311 75.002C58.7381 71.3089 52.695 71.3089 49.002 75.002C45.3089 78.695 45.3089 84.7381 49.002 88.4311L82.5748 122.004L49.0019 155.577C45.3089 159.27 45.3089 165.313 49.002 169.006C52.695 172.699 58.7381 172.699 62.4311 169.006L96.0039 135.433Z" fill="#5F5F5F"/>
                </svg>
            }
            </>
        )
}

export default HomeIcon