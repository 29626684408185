import React from 'react'
import { Redirect } from 'react-router-dom'
import BgColor from '../Generic/BgColor'
import { connect } from 'react-redux'
import { packAction } from '../../actions/ActionTypes'
import * as ActionTypes from '../../actions/ActionTypes'
import { getPasswordPolicyFromToken } from '../../actions/UserActions'
import PasswordPolicy from '../PasswordPolicy'
import { setPageTitle } from '../../helpers/DocumentHelpers'
import { checkPasswordPolicyViolation } from '../../helpers/UserHelpers'
import ShowIf from '../Generic/ShowIf'

const mapStateToProps = (state, ownProps) => {
    return {
        passwordPolicy: state.User.passwordPolicy
    }
}

class ResetPassword extends React.Component {
    state = {
        password_1: '',
        password_2: ''
    }

    componentDidMount() {
        if(this.props.type === 'welcome') {
            setPageTitle("Select password")
            this.props.dispatch(getPasswordPolicyFromToken("select", this.props.match.params.secret))
        } else {
            setPageTitle("Reset password")
            this.props.dispatch(getPasswordPolicyFromToken("reset", this.props.match.params.secret))
        }
    }

    dispatch() {
        if(this.props.type === 'welcome') {
            this.props.dispatch(packAction(ActionTypes.SELECT_PASSWORD, {token: this.props.match.params.secret, password_1: this.state.password_1, password_2: this.state.password_2}))
        } else {
            this.props.dispatch(packAction(ActionTypes.RESET_PASSWORD, {token: this.props.match.params.secret, password_1: this.state.password_1, password_2: this.state.password_2}))
        }
    }

    render() {
        if(!this.props.match.params.secret) return <Redirect to={'/login'}/> 
        const policy = this.props.passwordPolicy ?? {options: []}
        const policyViolations = checkPasswordPolicyViolation(policy, this.state.password_1)
        return (
            <BgColor bgClass="loginBody">
                  <div className='row centered-row'>
                <div className='col-md-6 login-left-side'>
                    <img src="./img/inact_now_logo.svg" alt="" />
                   
                    <div className='previously'>
                        <p className="login-white-text">Previously ABC Cloud by <a className="white-text" target="_blank" href="https://inact.io" rel="noopener noreferrer">ABC Softwork</a></p>
                    </div>

                </div>
                <div className='col-md-6'>
                <div className="login-box" style={{width:"90%"}}>
                    <h1 className='margin-bottom-20px'>{this.props.type === "welcome" ? "Select password" : "Reset password"}</h1>
                    <input type="password" name="password_1" onChange={e => this.setState({password_1: e.target.value})} value={this.state.password_1} className="form-control margin-bottom-10px" placeholder="Password" required autoFocus/>
                    <input type="password" name="password_2" onChange={e => this.setState({password_2: e.target.value})} value={this.state.password_2} className="form-control" placeholder="Repeat password" required/>
                    <br/>
                    <PasswordPolicy policy={this.props.passwordPolicy} password={this.state.password_1} />
                    <ShowIf if={this.state.password_1.length > 0 || this.state.password_2.length > 0}>
                        <span className={this.state.password_1!==this.state.password_2 ? 'red' : 'green'}>'Password' and 'Repeat password' must be identical<br /></span>
                    </ShowIf>

                    <br/>
                    <button className="btn btn-tangerine" onClick={() => this.dispatch()} type="submit" disabled={this.state.password_1!==this.state.password_2 || this.state.password_1.length < 1 || this.state.password_2.length < 1 || policyViolations.length > 0}>{this.props.type === "welcome" ? "Set password" : "Reset password"}</button>
                    <div className="help-text">
                         <p className="font-size-12px"><a className="fix-helper-a tangerine" target="_blank" href="https://help.inact.io" rel="noopener noreferrer">Do you need help?</a></p>
                    </div>
                </div>
                </div></div>
            </BgColor>
        )
    }
}

export default connect(mapStateToProps)(ResetPassword)