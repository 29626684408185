import { packAction } from "../actions/ActionTypes"
import * as ActionTypes from './ActionTypes'
import { getChecklistsStatus } from './StatusActions'

export const gotChecklist = (result, id) => {
    return packAction(ActionTypes.GET_CHECKLIST_DONE, {data: result, id: id})
}

export const gotChecklistItems = (result) => {
    return packAction(ActionTypes.GET_CHECKLIST_ITEMS_DONE, result)
}

export const gotChecklistCategoryItems = (result) => {
    return packAction(ActionTypes.GET_CHECKLIST_CATEGORY_ITEMS_DONE, result)
}

export const resetChecklistCategoryItems = () => {
    return packAction(ActionTypes.RESET_CHECKLIST_CATEGORY_ITEMS, {})
}

export const gotChecklistComments = (result) => {
    return packAction(ActionTypes.GET_CHECKLIST_COMMENTS_DONE, result)
}

export const getChecklist = (id) => {
    return packAction(ActionTypes.GET_CHECKLIST, id)
}

export const getChecklistItems = (id) => {
    return packAction(ActionTypes.GET_CHECKLIST_ITEMS, id)
}

export const getChecklistCategoryItems = (id, offset, limit, category, sortIndex, sortDirection, reset = false) => {
    return packAction(ActionTypes.GET_CHECKLIST_CATEGORY_ITEMS, {id, offset, limit, category, sortIndex, sortDirection, reset})
}

export const editChecklist = (id, name, limit, sort_column, sort_direction, selected_kpi, automatic_update, filter, update_items = false, redirect = false, category_limit = -1, current_category_limit = -1) => {
    return packAction(ActionTypes.EDIT_CHECKLIST, {id: id, name: name, limit: limit, sort_column: sort_column, sort_direction: sort_direction, selected_kpi: selected_kpi, automatic_update: automatic_update, filter, update_items, redirect, current_category_limit, category_limit})
}

export const editChecklistCurrentCategoryLimit = (checklist, newCurrentCategoryLimit) => {
    return editChecklist(checklist.id, checklist.name, checklist.limit, checklist.sort_column, checklist.sort_direction, checklist.selected_kpi, checklist.automatic_update, checklist.filter, false, false, checklist.category_limit, newCurrentCategoryLimit)
}

export const updatedChecklist = (dispatch, checklist_id) => {
    dispatch(getChecklistsStatus())
    dispatch(getChecklist(checklist_id))
}

export const deletedChecklist = () => {
    return getChecklistsStatus()
}

export const gotPastedChecklistComments = (id, result) => {
    return packAction(ActionTypes.GET_LOCAL_CHECKLIST_COMMENTS_DONE, {item_id: id, data: result})
}

export const gotAllPastedChecklistComments = result => {
    return packAction(ActionTypes.GET_ALL_LOCAL_CHECKLIST_COMMENTS_DONE, result)
}

export const getChecklistItemData = (checklist, itemKey) => {
    return packAction(ActionTypes.GET_CHECKLIST_ITEM_DATA, {checklist_id: checklist.id, item_key: itemKey})
}

export const gotChecklistItemData = (result) => {
    return packAction(ActionTypes.GET_CHECKLIST_ITEM_DATA_DONE, result)
}

export const updatedPastedComment = (checklist_id, item_id) => {
    return packAction(ActionTypes.GET_LOCAL_CHECKLIST_COMMENTS, {item_id: item_id, checklist_id: checklist_id})
}

export const checklistNotFound = () => {
    return packAction(ActionTypes.GET_CHECKLIST_NOT_FOUND)
}

export const checklistNotAccessible = () => {
    return packAction(ActionTypes.GET_CHECKLIST_NOT_ACCESSIBLE)
}

export const getLayouts = () => {
    return packAction(ActionTypes.GET_LAYOUTS)
}

export const gotLayouts = (result) => {
    return packAction(ActionTypes.GET_LAYOUTS_DONE, result)
}

export const reactivateChecklist = id => {
    return packAction(ActionTypes.REACTIVATE_CHECKLIST, id)
}

export const updateChecklistItems = (id, redirect = true, cb) => {
    return packAction(ActionTypes.UPDATE_CHECKLIST_ITEMS, {id, redirect}, cb)
}

export const resetChecklistItems = (id) => {
    return packAction(ActionTypes.RESET_CHECKLIST_ITEMS, {id})
}

export const updateChecklistItemsMultiple = ids => {
    return packAction(ActionTypes.UPDATE_CHECKLIST_ITEMS_MULTIPLE, {ids})
}

export const saveLayout = layout => {
    return packAction(ActionTypes.SAVE_LAYOUT, layout)
}

export const deleteLayout = lid => {
    return packAction(ActionTypes.DELETE_LAYOUT, lid)
}

export const newLayout = layout => {
    return packAction(ActionTypes.SAVE_LAYOUT_DONE, layout)
}

export const updateLayout = layout => {
    return packAction(ActionTypes.UPDATE_LAYOUT, layout)
}

export const duplicateChecklist = (cid, redirect = true) => {
    return packAction(ActionTypes.DUPLICATE_CHECKLIST, {id: cid, redirect})
}

export const downloadChecklistData = (cid, data) => {
    return packAction(ActionTypes.DOWNLOAD_CHECKLIST_DATA, {id: cid, data: data})
}

export const changeMultLayout = (layout, checklists) => {
    return packAction(ActionTypes.SAVE_MULT_LAYOUTS, {layout, checklists})
}


// View can be home/status/single
export const removeChecklistColumns = (id, view, update) => {
    return packAction(ActionTypes.REMOVE_CHECKLIST_COLUMNS, {id, view, update})
}

// View can be home/status/single
export const fixChecklist = (id, checklist, view, update) => {
    return packAction(ActionTypes.FIX_CHECKLIST, {id, checklist, view, update})
}