import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet } from '../../helpers/ApiHelpers'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { getDownloadInfoDone } from '../../actions/DownloadActions'


const DownloadApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)

    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_DOWNLOAD_INFO:
            url = `download_info`
            apiGet(url, data => dispatch(getDownloadInfoDone(data)), e => notifyApiFailure("getting download info", 'GET', url, data, e.status))
            break
        case ActionTypes.REQUEST_DOWNLOAD_FILE:
            url = `bucket/${action.payload.bucket}/download/${action.payload.fileName}`
            apiGet(url, token => {}, e => notifyApiFailure("requesting file download", 'GET', url, data, e.status))
            break
        default:
            break
    }
}

export default DownloadApiMiddleware