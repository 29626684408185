import React, { useEffect, useState, useMemo, useRef } from 'react'
import { DashboardOverview, User, DashboardReportShortInfo } from '../types/transfertypes'
import { getDashboardOverview, getDefaultDashboard, getHomeDashboard, createDashboard, copyDashboard, deleteDashboard, getDashboardReports } from '../actions/DashboardActions'
import { isDashboardAdmin, is } from '../helpers/PermissionHelpers'
import RequirePermission from '../components/RequirePermission'
import { connect } from 'react-redux'
import BgColor from '../components/Generic/BgColor'
// @ts-ignore
import { Link } from 'react-router-dom'

import '../css/home.css'
import '../css/sidebar.css'
import '../css/dimensions.css'
import '../css/misc.css'
import '../css/margin.css'
import '../css/library.css'
import ShowIf from '../components/Generic/ShowIf'
import CreateDashboardDialog from '../components/Dialogs/CreateDashboardDialog'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'

export interface DashboardOverviewProps {
    dispatch: any,
    dashboardOverview: DashboardOverview[],
    homeDashboard: number,
    defaultDashboard: number,
    user: User,
    reports: DashboardReportShortInfo[],
    history: any,

}

let mapStateToProps = (state: any, ownProps: DashboardOverviewProps) => {
    return {
        dashboardOverview: state.Dashboard.overview,
        homeDashboard: state.Dashboard.homeDashboard,
        defaultDashboard: state.Dashboard.defaultDashboard,
        user: state.User.Info,
        reports: state.Dashboard.reports,
    }
}

let DashboardOverview2 = (props: DashboardOverviewProps) => {

    const [search, setSearch] = useState('')
    const [showCreateDashboard, setShowCreateDashboard] = useState(false)
    const [dashboardToDelete, setDashboardToDelete] = useState<DashboardOverview | undefined>(undefined)

    const deleteDashboardDialog = useRef<any>()

    useEffect(() => {
        props.dispatch(getDashboardOverview())
        props.dispatch(getDefaultDashboard())
        props.dispatch(getHomeDashboard())
        props.dispatch(getDashboardReports())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.user != undefined && isDashboardAdmin(props.user.access)) {
            props.dispatch(getDashboardReports())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user])


    const overviews = useMemo(() => {
        if (props.dashboardOverview == undefined) return []

        const filterFunc = (a: DashboardOverview) => {
            return a.title.toLowerCase().includes(search.toLowerCase())
        }

        return props.dashboardOverview.filter(filterFunc).sort((a,b) => a.title.localeCompare(b.title))
    }, [props.dashboardOverview, search])

    const onCreate = (name: string, report_id: number, main_label: string) => {
        props.dispatch(createDashboard(name, report_id, main_label, (res: DashboardOverview) => {
            props.history.push("/dashboardadmin/" + res.id)
        }))
        setShowCreateDashboard(false)
    }

    const onCopy = (title: string, d_id: number, org_id: number) => {
        props.dispatch(copyDashboard(title, d_id, org_id, (res: DashboardOverview) => {
            props.history.push("/dashboardadmin/" + res.id)
        }))
        setShowCreateDashboard(false)
    }
    const onCreateCopy = (e: React.MouseEvent, d: DashboardOverview) => {
        e.preventDefault()
        e.stopPropagation()

        onCopy(d.title + " - Copy", d.id, d.organization_id)
    }

    const onEdit = (e: React.MouseEvent, d: DashboardOverview) => {
        e.preventDefault()
        e.stopPropagation()

        props.history.push("/dashboardadmin/" + d.id)
    }

    const onDelete = (e: React.MouseEvent, d: DashboardOverview) => {
        e.preventDefault()
        e.stopPropagation()

        setDashboardToDelete(d)
        deleteDashboardDialog.current.show()

    }

    const onConfirmDelete = () => {
        if (dashboardToDelete == undefined) return
        props.dispatch(deleteDashboard(dashboardToDelete.id))

        setDashboardToDelete(undefined)

    }

    const totalDashboards = props.dashboardOverview != undefined ? props.dashboardOverview.length : 0
    const shownDashboards = overviews.length

    let noAccessToHomeDashboard = !props.homeDashboard || props.dashboardOverview.every(d => d.id !== props.homeDashboard)

    return <BgColor bgClass="library-background">
        <div className="home-settings">
            <div className="library-title">
                <h1 className="pt-3 margin-bottom-5px">Dashboards</h1>
                <RequirePermission perms={is.dashboardAdmin}>
                    <button className="btn btn-primary btn-sm" onClick={() => setShowCreateDashboard(true)} ><i className="fa fa-plus"></i> New Dashboard</button>
                </RequirePermission>
            </div>
            <div className='library-toolbar'>
                <div></div>
                <div className="library-search">
                    <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center"}}>
                        <span className="vertical-align-middle" style={{marginRight: 8}}>{`Showing ${shownDashboards} of ${totalDashboards}`}</span>
                    </div>
                    <div className="input-group margin-top-0" id="searchBarContainer">
                        <input id="searchIdInput" className={`form-control form-control-sm zero-border-right ${search ? 'alert-warning' : ''}`}
                            placeholder={`Search`} onChange={(e) => setSearch(e.target.value)} value={search} />
                        <span title="Search beginning" className={`input-group-append ${search ? 'alert-warning' : ''}`}>
                            <button className="btn btn-sm btn-default" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div className="d-flex justify-content-center p-5">
            
            <div className="dashboard-overview-container" style={{maxWidth: 800, width: "100%", textDecoration: "none"}}>
            {
                overviews && overviews.map((d, i) => {
                    return <Link key={i} to={`dashboard/${d.id}`}>
                        <div className={`dashboard-overview-item ${i < (overviews.length-1) ? `dashboard-overview-item-divider` : `` }`} style={{textDecoration: "none"}} key={i} title={d.description}>
                            <span>{d.title}</span>
                            <div>
                                <ShowIf if={d.id == props.homeDashboard || (d.id === props.defaultDashboard && noAccessToHomeDashboard)}>
                                    <img alt="" src='img/home_add.svg' style={{height: 15, width:14}} />
                                </ShowIf>
                                <RequirePermission perms={is.dashboardAdmin}>
                                    <button className='btn btn-sm btn-default dropdown-toggle dropdown-toggle-split ml-2' data-toggle="dropdown" />
                                    <div className='dropdown-menu'>
                                        <button className="dropdown-item" onClick={e => onEdit(e, d)}>Edit</button>
                                        <button className="dropdown-item" onClick={e => onCreateCopy(e, d)}>Copy</button>
                                        <div className="dropdown-divider" />
                                        <button className="dropdown-item" style={{color: "var(--danger)"}} onClick={e => onDelete(e, d)}>Delete</button>
                                    </div>
                                    
                                </RequirePermission>
                            </div>
                        </div>
                    </Link>

                })
            }
            <ShowIf if={props.dashboardOverview.length === 0}>
                <p>There are no Dashboards available to you. If you believe this to be an error, please contact <a href="mailto:support@inact.io">
                support@inact.io
                </a>.</p>
            </ShowIf>

            </div>

        </div>

    <CreateDashboardDialog 
        show={showCreateDashboard}
        reports={props.reports}
        dashboardOverview={props.dashboardOverview}
        onCopy={onCopy}
        onCreate={onCreate}
        onClose={() => setShowCreateDashboard(false)}
    />

    <WarningAcceptDialog title="Delete Dashboard" acceptText="Delete" ref={deleteDashboardDialog} accept={onConfirmDelete} closeHandler={() => setDashboardToDelete(undefined)}>
        <span>Are you sure you want to delete Dashboard? <b>This can not be undone.</b></span>
    </WarningAcceptDialog>
    </BgColor>

}

// @ts-ignore
DashboardOverview2 = connect(mapStateToProps)(DashboardOverview2)

export default DashboardOverview2
