import React from 'react'
import '../css/loadinganimation.css'

export default function LoadingAnimation(props){

    let width = 400;
    let height = 200;

    return (
        <svg version="1.1" id="Layer_1"  x="0px" y="0px"
	 viewBox="0 0 320 105.66" width={width} height={height}>
<g>
	<g>
		<path fill="#304642" d="M83.66,105.68l0-0.16c0-0.21-0.01-0.41-0.01-0.62c0-57.58,46.85-104.43,104.43-104.43S292.52,47.31,292.52,104.9
			c0,0.2,0,0.4-0.01,0.6l0,0.15l-26-0.11l0.01-0.65c0-43.24-35.19-78.42-78.43-78.42c-43.25,0-78.43,35.19-78.43,78.43l0.01,0.61
			L83.66,105.68z"/>
	</g>
</g>
<g>
	<rect fill="#304642"  x="-7.82" y="40.12" transform="matrix(3.079913e-03 -1 1 3.079913e-03 -8.5448 97.6679)" width="105.06" height="26"/>
</g>
<circle cx={44.5} cy={13.5} r={12.5} className="loading-circ loading-circ-1" />
</svg>
    )
}
