import React from 'react'
import WordWrap from '../Generic/WordWrap'
import { getSingleItemHistoryBarData } from '../../helpers/ItemHelpers'


class SingleItemTextColumn extends React.Component {
    getBackgroundColorNumber(data) {
        let bgs = []
        data.forEach((c,i) => {
            if(i > 0 && c.value !== data[i-1].value) { //if value is changed
                bgs.push((2-bgs[i-1])+1) //use the opposite color
            } else if(i > 0) { //if value is not changed
                bgs.push(bgs[i-1]) //use last color
            } else { //if first value
                if(!c.value) bgs.push(1) //if first value is empty
                else bgs.push(2) //if the first value is not empty
            }
        })
        return bgs
    }

    render() {
        if(!this.props.column || !this.props.column.name) return null
        let placeholders = Array(12).fill("-").map((v,i) => <div key={i} className={`item-category-history-col text-column-bar-color-1`}>{v}</div>)
        if(!this.props.historyData || !this.props.historyData.get(this.props.bucket.id, this.props.item, this.props.column.name)) return <div className="item-category-history-row">{placeholders}</div>
        let bg = this.getBackgroundColorNumber(this.props.historyData.get(this.props.bucket.id, this.props.item, this.props.column.name).data)

        let historyData = this.props.historyData.get(this.props.bucket.id, this.props.item, this.props.column.name).data
        historyData = historyData.slice(-12) //only for latest 12 months
        let history = getSingleItemHistoryBarData(historyData)

        return (
            <div className="item-category-history-row">
                {
                    history.map((value,index) => {
                        let borderClass = ""
                        if(index < history.length-1 && history[index+1].value !== value.value) borderClass = " white-border"
                        return (
                            <div title={`${value.label}${value.width > 1 ? ` - ${value.endLabel}` : ""}${value.found ? ": " + value.value : ""}`} key={index} className={`item-category-history-col text-column-bar-color-${bg[index]} ${borderClass}`} style={{width: `calc(100%/${12.01/value.width})`}}>
                                <WordWrap>
                                    { value.found ? value.value : "-" }
                                </WordWrap>
                            </div>
                        )
                        }
                    )
                }
            </div>
        )
    }
}

export default SingleItemTextColumn