import React from 'react'
import moment from 'moment'
import { Bar } from 'react-chartjs-2';
import { getItemGraphData, getItemGraphOptions, getHistoryDataIndependent, getGraphLabelsItem } from '../../helpers/GraphHelpers'
import { getProfitHistoryData } from '../../helpers/ItemHelpers';
import { copyToClipboard } from '../../helpers/GeneralHelpers';
import { notifySuccess } from '../../helpers/NotificationManager';
import LoadingIcon from '../LoadingIcon';
import ShowIf from '../Generic/ShowIf';

class SingleItemGraph extends React.Component {
    constructor(props) {
        super()
    }


    render() {
        if (!this.props.historyData || !this.props.column) return null
        let column = this.props.column ? this.props.column : { label: "", name: "" }
        const sections = this.props?.profit?.sections ? JSON.parse(this.props.profit.sections) : []
        const totalRow = this.props?.profit?.total_row ? JSON.parse(this.props.profit.total_row) : { use_columns: false }
        const isTotal = this.props.totalSection && this.props.totalSection.x !== -1 && this.props.totalSection.y !== -1
        // checks if y is bigger than the number of totals (e.g. if the settings differ from the setup by referring to a larger number of sections than there is)
        let historyData

        const missingSection = () => this.props.profit ? this.props.totalSection.y > sections.length : this.props.totalSection.y >= sections.length
        if (isTotal && !missingSection()) {
            if (!this.props.profit) return null
            historyData = { data: getHistoryDataIndependent(sections, totalRow ?? { use_columns: false }, this.props.totalSection, this.props.historyData, this.props.totalSection.y === sections.length, this.props.fromReport, this.props.bucket.trendsets) }
        } else {
            historyData = getProfitHistoryData(this.props.historyData, this.props.column.name, this.props.fromReport)
        }


        let zoom = this.props.isProfit ? 12 : this.props.zoom
        let labels = getGraphLabelsItem(historyData.data, this.props.isProfit, zoom)
        let data = getItemGraphData(historyData.data, this.props.type, column.subtract, this.props.selectedColumnIndex, this.props.isProfit, zoom)
        let options = getItemGraphOptions(historyData.data, this.props.startAtZero, this.props.type, column.subtract, this.props.isProfit, this.props.setColumnIndex, this.props.selectedColumnIndex, false, labels)

        let datasetfound = historyData.data ? historyData.data.map(x => x.found).reverse() : []

        let doesDataHave12To36Months = false
        let doesDataHave24To36Months = false
        
        if(this.props.bucket.trendsets > 12) {
            for(let i=12; i < datasetfound.length; i++){
                doesDataHave12To36Months = doesDataHave12To36Months || datasetfound[i]
            }
        }

        if(this.props.bucket.trendsets > 24) {
            for(let i=24; i < datasetfound.length; i++){
                doesDataHave24To36Months = doesDataHave24To36Months || datasetfound[i]
            }
        }

        if (column.type === "none") {
            return null
        }
        let fromNow = (time) => {
            return moment.unix(time).fromNow();
        }

        let copyReportDataToClipboard = () => {
            const filteredData = historyData.data.filter(obj => obj.found)
            const headers = ["Year Month", column.name  ]
            
            const csvContent = [
                "<table><tr><th>" + headers.join('</th><th>') + "</th></tr>",
                ...filteredData.map(obj => "<tr><td>20" + obj["label"].split(" ")[1] + " " + obj["label"].split(" ")[0] + "</td><td>" + obj["value"] + "</td></tr>"),
                "</table>"
            ].join('\n')

            copyToClipboard(csvContent)
            notifySuccess('Data has been copied to the clipboard')
        }

        return (
            <div className="margin-top-20px">
                <div className="d-flex flex-row align-items-center">
                    <div className="pr-2" style={{width: "100%"}}>
                        <div className="monitor-header" style={{float: "left"}} title={`Updated ${fromNow(this.props.bucket.last_update)}`} >{column.label && column.label !== "-" ? column.label : column.name}</div>               
                        <ShowIf if={!this.props.isProfit}>
                            <div className="btn-group" role="group" aria-label="..." style={{float: "right", right: -30}} >
                                <div className="ml-1 pt-sm-1 pt-md-1 pt-lg-0">
                                    <button title="Copy to clipboard" className="btn btn-default btn-sm margin-right-1px" onClick={() => {copyReportDataToClipboard()}}><i className="fa fa-copy"></i></button>                            
                                </div>
                                <div className="ml-1 pt-sm-1 pt-md-1 pt-lg-0">
                                    <button onClick={() => this.props.setZoom(12)} className={`btn btn-default btn-sm ${zoom === 12 ? 'active' : ''}`}>12 months</button>
                                    <ShowIf if={this.props.bucket.trendsets > 12}>
                                        <button disabled={!doesDataHave12To36Months} onClick={() => this.props.setZoom(24)} className={`btn btn-default btn-sm ${zoom === 24 ? 'active' : ''}`}>24 months</button>
                                    </ShowIf>
                                    <ShowIf if={this.props.bucket.trendsets > 24}>
                                        <button disabled={!doesDataHave24To36Months} onClick={() => this.props.setZoom(36)} className={`btn btn-default btn-sm ${zoom === 36 ? 'active' : ''}`}>36 months</button>
                                    </ShowIf>
                                </div>
                            </div>
                        </ShowIf>
                    </div>
                    <div>
                        <LoadingIcon size="small" loading={this.props.loading} />
                    </div>
                </div>
                <div className="single-item-graph-height">
                    <Bar redraw={true} height={85} data={{ labels: labels, datasets: data }} options={options} />
                </div>
            </div>
        )
    }
}

export default SingleItemGraph