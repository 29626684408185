import React from 'react'
import BaseDialog from './BaseDialog'

class DescriptionDialog extends BaseDialog {
    
    closeDialog = () => {
		if(this.props.closeHandler)
			this.props.closeHandler();
		this.hide(); //base class
	}

    getButtons() {
        return (
            <div>
                <button onClick={this.closeDialog} type="button" className="btn btn-default">Close</button>
            </div>
        )
    }
}

export default DescriptionDialog