import React from 'react'
import { connect } from 'react-redux'
import { loginAnimationPlayed } from './actions/UserActions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
	return {
        showAnimation: state.User.playLoginAnimation
	}
}

class SwitchWrapper extends React.Component {
	render() {
        let classes = 'abc-main-content'
        if (this.props.showAnimation) {
			classes = `${classes} loginAnimation`
            setTimeout(() => {
                this.props.dispatch(loginAnimationPlayed())
            }, 1000)
        }
        return <div className={classes}>{this.props.children}</div>
	}
}

SwitchWrapper = connect(mapStateToProps)(SwitchWrapper)

SwitchWrapper = withRouter(SwitchWrapper)

export default SwitchWrapper
