import React from 'react'
import { connect } from 'react-redux'
import { packAction } from '../../actions/ActionTypes'
import { getUsers } from '../../actions/SystemActions'
import { getBucketUsers } from '../../actions/BucketActions'
import * as ActionTypes from '../../actions/ActionTypes'
import { getFullName } from '../../helpers/UserHelpers'
import moment from 'moment'
import ShowIf from '../Generic/ShowIf'
import HideIf from '../Generic/HideIf'
import TextareaAutocompleter from '../Generic/TextareaAutocompleter'

const mapStateToProps = (state, ownProps) => {
	return {
        userInfo: state.User.info,
        info: state.Info.info,
        users: state.System.users,  
        bucketUsers: state.Bucket.bucketUsers,
        comments: state.Item.comments,
        pastedChecklistComments: state.Checklist.pastedChecklistComments,
        itemExists: state.Item.itemExists
	}
}

class SingleItemComments extends React.Component {
    constructor(props) {
        super()
        this.state = {
            bucket_id: null,
            item: {},
            checklist: null,
            addComment: false,
            amount: 3,
            selectedFilter: 'none',
            showLess: false,
            isLocalChecklistComment: false,
            showPrivateReportTagError: false
        }
    }

    setItem(bucket, itemKey, checklist, itemId) {
        if(!bucket || itemKey === undefined || itemKey === null) return
        let item = {key: itemKey}
        if(itemId) item.id = itemId
        this.setState({bucket_id: bucket, item: item, checklist: checklist ? checklist : null, showPrivateReportTagError: false})
        this.fetchComments(bucket, {key: itemKey})
       
        if(!this.props.bucketUsers.contains(bucket))
            this.props.dispatch(getBucketUsers(bucket))

        if(!this.props.users)
            this.props.dispatch(getUsers())
    }

    setChecklist(checklist, item) {
        if(!checklist) return
        this.setState({checklist: checklist, item: item, bucket_id: checklist.bucket, showPrivateReportTagError: false})
        this.fetchComments(checklist.bucket, item, checklist)
        

        if(!this.props.bucketUsers.contains(checklist.bucket))
            this.props.dispatch(getBucketUsers(checklist.bucket))

        if(!this.props.users)
            this.props.dispatch(getUsers())
    }

    useBucket() {
        return this.state.bucket_id && (this.state.item.key || this.state.item.key === "")
    }

    fetchComments(bucket, item, checklist) {
        if(bucket && (item || item === "")) {
            this.props.dispatch(packAction(ActionTypes.GET_ITEM_COMMENTS, {bucket_id: bucket, item_id: item.key}))
        } 

        if(checklist) {
            this.props.dispatch(packAction(ActionTypes.GET_LOCAL_CHECKLIST_COMMENTS, {checklist_id: checklist.id, item_id: item.id}))
        }
    }

    toggleAddComment() {
        this.setState({addComment: !this.state.addComment, commentToBeEditted: undefined, commentToBeDeleted: undefined, isLocalChecklistComment: !this.state.bucket_id, showPrivateReportTagError: false})
    }

    async addComment() {
        if(this.state.isLocalChecklistComment) {
            let contextPath = await this.props.getContextPath()
            this.props.dispatch(packAction(ActionTypes.CREATE_LOCAL_CHECKLIST_COMMENT, {checklist_id: this.state.checklist.id, item_id: this.state.item.id, key: this.state.item.key, comment: this.refs.addComment.getValue(), context_path: contextPath}))
        } else {
            let contextPath = `/element/${this.state.bucket_id}/${this.state.item.key}`
            this.props.dispatch(packAction(ActionTypes.ADD_ITEM_COMMENT, {bucket_id: this.state.bucket_id, item_id: this.state.item.key, comment: this.refs.addComment.getValue(), user_id: this.props.userInfo.user_id, context_type: this.props.context_type, context: this.props.context, context_path: contextPath, disable_tagging: this.props.privateReport}))
        }
        this.toggleAddComment()
    }

    commentComparator(a,b) {
        if (a.comment_time < b.comment_time) return 1
        else if(a.comment_time > b.comment_time) return -1
        else return 0
    }

    toggleEditComment(comment) {
        if(comment !== undefined && (this.state.commentToBeEditted === undefined || this.state.commentToBeEditted.id !== comment.id)) { 
            this.setState({commentToBeEditted: comment, addComment: false})
        } else {
            this.setState({commentToBeEditted: undefined, isLocalChecklistComment: false})
        }
        let isLocalChecklistComment = this.props.pastedChecklistComments.get(this.state.item.id, []).findIndex(c => c.id === comment.id) > -1
        this.setState({commentToBeDeleted: undefined, isLocalChecklistComment: isLocalChecklistComment, showPrivateReportTagError: false})
    }
    
    async editComment(comment) {
        let ref = `edit-comment-${comment.id}-textarea`
        if(this.state.isLocalChecklistComment) {
            let contextPath = await this.props.getContextPath()
            this.props.dispatch(packAction(ActionTypes.UPDATE_LOCAL_CHECKLIST_COMMENT, {id: comment.id, comment: this.refs[ref].getValue(), checklist_id: this.state.checklist.id, item_id: this.state.item.id, key: this.state.item.key, context_path: contextPath }))
        } else {
            let contextPath = `/element/${this.state.bucket_id}/${this.state.item.key}`
            this.props.dispatch(packAction(ActionTypes.UPDATE_ITEM_COMMENT, {bucket_id: this.state.bucket_id, id: comment.id, item_id: this.state.item.key, comment: this.refs[ref].getValue(), context_path: contextPath, disable_tagging: this.props.privateReport }))
        }
        this.setState({commentToBeEditted: undefined, isLocalChecklistComment: false, showPrivateReportTagError: false})
    }
    
    warnDeleteComment(comment) {
        let isLocalChecklistComment = this.props.pastedChecklistComments.get(this.state.item.id, []).findIndex(c => c.id === comment.id) > -1

        if (this.state.commentToBeDeleted === undefined || this.state.commentToBeDeleted.id !== comment.id) this.setState({commentToBeEditted: undefined, commentToBeDeleted: comment, addComment: false, isLocalChecklistComment: isLocalChecklistComment})
        else this.setState({commentToBeEditted: undefined, commentToBeDeleted: undefined, addComment: false, isLocalChecklistComment: false, showPrivateReportTagError: false})
    }
    
    deleteComment() {
        if (this.state.isLocalChecklistComment) {
            this.props.dispatch(packAction(ActionTypes.DELETE_LOCAL_CHECKLIST_COMMENT, {id: this.state.commentToBeDeleted.id, checklist_id: this.state.checklist.id, item_id: this.state.item.id}))
        } else {
            this.props.dispatch(packAction(ActionTypes.DELETE_ITEM_COMMENT, {bucket_id: this.state.bucket_id, id: this.state.commentToBeDeleted.id, item_id: this.state.item.key }))
        }
        this.setState({commentToBeDeleted: undefined, isLocalChecklistComment: false, showPrivateReportTagError: false})
    }
    
    cancelDeleteComment() {
        this.setState({commentToBeDeleted: undefined})
    }

    highlightTags(comment, regex) {
        let cs = comment.split(regex)
        let html = cs.map(c => c.match(regex) ? <mark key={c} className="abc-tag">{c}</mark> : c)
        return html
    }

    getComments() {
        let itemComments = this.props.comments.get(this.state.bucket_id, this.state.item.key, [])
        let checklistComments = this.props.pastedChecklistComments.get(this.state.item.id, [])
        
        let adaptedChecklistComments = checklistComments.map(c => {
            let _c = c
            c.context = this.state.checklist.name
            c.context_type = "2"
            return _c
        })

        return itemComments.concat(adaptedChecklistComments).sort((a,b) => a.comment_time > b.comment_time ? -1 : b.comment_time > a.comment_time ? 1 : 0)
    }

    setFilter(e) {
        this.setState({ selectedFilter: e.target.value })
    }

    filterType(val, filter) {
        switch(filter) {
            case '1':
                return val === '1'
            case '2':
                return val === '2'
            case '3':
                return val === '3' || val === '-1'
            default:
                return true
        }
    }

    showTagError = () => {
        if(this.props.privateReport) this.setState({showPrivateReportTagError: true})
    }

    makeReportPublic = () => {
        this.props.makeReportPublic()
        this.setState({showPrivateReportTagError: false})
    }

    getItemKey = () => {
        return this.state.item.key
    }

    isTextAreaInFocus() {
        if(this.state.commentToBeEditted !== undefined)
            return true
        
        return this.refs?.addComment?.isInFocus() ?? false
    }

    render() {
        if(!this.props.bucketUsers || !this.props.bucketUsers.get(this.state.bucket_id)) return null
        let users = this.props.privateReport ? [] :  this.props.bucketUsers.get(this.state.bucket_id).filter(u => !u.is_consultant).map(u => {
            return {title: u.firstname+" "+u.lastname, description: u.email, data: u}
        })
        let userTags = users.map(u => "@"+u.title)

        let userTagRegex = users.length === 0 ? new RegExp("$^") : new RegExp("("+userTags.join("|").replaceAll("(", "\\(").replaceAll(")", "\\)")+")")
        let comments = this.getComments()
        return (
            <div>
                <HideIf if={this.state.addComment || !this.props.itemExists}>
                    <button className="btn btn-default btn-sm add-comment-button" onClick={() => this.toggleAddComment()}><i className="fa fa-comment margin-right-5px"></i>Add a comment</button>
                </HideIf>
                <ShowIf if={this.state.addComment}>
                    <div className="abc-item-add-comment">
                        <TextareaAutocompleter 
                            ref="addComment" 
                            caseInsensitive={true} 
                            textareaClassName="form-control margin-top-15px resize-vertical" 
                            defaultValue="" 
                            token="@" 
                            suggestions={users} 
                            onToken={this.showTagError}
                        />

                        <div className="row m-0">
                            <div className="float-right margin-top-10px">
                                <button className="btn btn-sm btn-primary margin-right-10px" onClick={() => this.addComment()}>Add</button>
                                <button className="btn btn-sm btn-default margin-right-15px" onClick={() => this.toggleAddComment()}>Cancel</button>
                            </div>
                        </div>
                        <ShowIf if={this.state.checklist}>
                            <div className="local-comment-checkbox-holder">
                                <input type="checkbox" className="local-comment-checkbox" checked={this.state.isLocalChecklistComment} disabled={!this.state.bucket_id} onChange={e => this.setState({isLocalChecklistComment: e.target.checked})}/>
                                <span>Only comment on Action list</span>
                            </div>
                        </ShowIf>

                        <ShowIf if={!this.state.commentToBeEditted && this.state.showPrivateReportTagError}>
                            <div className="alert alert-danger display-block margin-top-10px" role="alert">
                                <span className="fa fa-exclamation-circle margin-right-10px" aria-hidden="true"></span>
                                <span className="sr-only">Warning: </span>
                                Your Insight is private, so nobody else can se it. Make it public to tag others.
                                <br/>
                                <button className="btn btn-xs btn-default margin-right-10px margin-top-5px" type="button" onClick={() => this.setState({showPrivateReportTagError: false})}>Okay, keep as private</button>
                                <button className="btn btn-xs btn-default margin-right-10px margin-top-5px" type="button" onClick={this.makeReportPublic}>Make public</button>
                            </div>
                        </ShowIf>
                    </div> 
                </ShowIf>

                <div className="margin-top-20px">
                {
                    comments
                    .sort(this.commentComparator)      
                    .filter((val, i) => {return i < this.state.amount})
                    .map(comment => 
                        <div key={`comment-${comment.id}`}>
                            <div className="abc-item-comment-holder">
                                <div className="inline-block comment-headline">
                                    <p className="abc-item-comment-user margin-right-10px inline-block">
                                        {this.props.users && getFullName(this.props.users, comment.user_id)}
                                    </p>
                                    <ShowIf if={this.props.userInfo.user_id === comment.user_id}>
                                        <div className="display-inline float-right" >
                                            <div className="btn btn-xs comment-activate-edit-button" type="button" onClick={() => this.warnDeleteComment(comment)} title="Delete comment"><span className="fa fa-trash"></span></div>
                                            <div className="btn btn-xs comment-activate-edit-button" type="button" onClick={() => this.toggleEditComment(comment)} title="Edit comment"><span className="fa fa-edit" ></span></div>
                                            <ShowIf if={comment.checklist_id}>
                                                <div className="btn-xs comment-activate-edit-button" type="button" title="This comment is only visible on this checklist"><span className="fa fa-check"></span></div>
                                            </ShowIf>
                                        </div>
                                    </ShowIf>
                                    <p className="abc-item-comment-timestamp inline-block float-right" title={moment(comment.comment_time).format('LLLL')}>{moment.unix(comment.comment_time).fromNow()}</p>
                                </div>
                                <HideIf if={this.state.commentToBeEditted !== undefined && this.state.commentToBeEditted.id === comment.id}>
                                    <p style={{whiteSpace: "pre-wrap"}}>
                                        {
                                            this.highlightTags(comment.comment, userTagRegex)
                                        }
                                    </p>
                                </HideIf>
                                <ShowIf if={this.state.commentToBeEditted !== undefined && this.state.commentToBeEditted.id === comment.id}>
                                    <div>
                                        <TextareaAutocompleter 
                                            caseInsensitive={true} 
                                            textareaClassName="form-control" 
                                            ref={`edit-comment-${comment.id}-textarea`} 
                                            defaultValue={comment.comment} 
                                            token="@" 
                                            suggestions={users}
                                            onToken={this.showTagError}
                                        />
                                        
                                        <div className="margin-top-5px margin-bottom-5">
                                            <div className="btn btn-default btn-xs" onClick={() => this.editComment(comment)}>Update comment</div> 
                                            <div className="btn btn-default btn-xs margin-left-10px" onClick={() => this.toggleEditComment()}>Cancel</div>
                                        </div>
                                    </div>
                                </ShowIf>
                                
                                <ShowIf if={this.state.commentToBeDeleted !== undefined && this.state.commentToBeDeleted.id === comment.id}>
                                    <div className="alert alert-danger display-block" role="alert">
                                        <span className="fa fa-exclamation-circle margin-right-10px" aria-hidden="true"></span>
                                        <span className="sr-only">Warning: </span>
                                        Please confirm deletion
                                        <button className="btn btn-xs btn-default margin-left-10px" type="button" onClick={() => this.deleteComment()}>Delete</button>
                                        <button className="btn btn-xs btn-default margin-left-10px" type="button" onClick={() => this.cancelDeleteComment()}>Cancel</button>
                                    </div>
                                </ShowIf>
                                <ShowIf if={this.state.commentToBeEditted && this.state.commentToBeEditted.id === comment.id && this.state.showPrivateReportTagError}>
                                    <div className="alert alert-danger display-block" role="alert">
                                        <span className="fa fa-exclamation-circle margin-right-10px" aria-hidden="true"></span>
                                        <span className="sr-only">Warning: </span>
                                        Your report is private, so nobody else can se it. Make it public to tag others.
                                        <br/>
                                        <button className="btn btn-xs btn-default margin-right-10px margin-top-5px" type="button" onClick={() => this.setState({showPrivateReportTagError: false})}>Okay, keep as private</button>
                                        <button className="btn btn-xs btn-default margin-right-10px margin-top-5px" type="button" onClick={this.props.makeReportPublic}>Make public</button>
                                    </div>
                                </ShowIf>
                            </div>
                        </div>
                    )
                }
                {
                    comments.length > this.state.amount ? // TODO: show more skal ikke vises, når man efter filter kun kan se 3 eller færre kommentarer!!
                        <div className="text-align-center width-100p">
                            <button className="btn btn-default btn-sm middle-align" onClick={() => this.setState({ amount: this.getComments().length, showLess: true })}>Show more</button>
                        </div>
                    : null
                }
                {
                    this.state.showLess ?
                        <div className="text-align-center width-100p">
                            <button className="btn btn-default btn-sm middle-align" onClick={() => this.setState({ amount: 3, showLess: false })}>Show less</button>
                        </div>
                    : null
                }
                </div>
            </div>
        )
    }
}

SingleItemComments = connect(mapStateToProps, null, null, { forwardRef: true })(SingleItemComments)

export default SingleItemComments