import * as ActionTypes from '../actions/ActionTypes'
import { SingleKeyMap } from '../helpers/Collections'

const defaultState = {
    dashboard_data: new SingleKeyMap(),
    reports: [],
    overview: [],
    dashboardNotAccessible: false
}

const Dashboard = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_DASHBOARD_DATA_DONE:
            let dashboard_data = new SingleKeyMap(state.dashboard_data)
            dashboard_data.set(action.payload.dID, action.payload.data)
            return Object.assign({}, state, { dashboard_data: dashboard_data })
        case ActionTypes.GET_DASHBOARD_OVERVIEW_DONE:
            let overview = action.payload ? action.payload : []
            overview.sort((a,b) => a.title.localeCompare(b.title))
            return Object.assign({}, state, { overview: overview })
        case ActionTypes.GET_HOME_DASHBOARD_DONE:
            return Object.assign({}, state, { homeDashboard: action.payload })
        case ActionTypes.GET_DEFAULT_DASHBOARD_DONE:
            return Object.assign({}, state, { defaultDashboard: action.payload })
        case ActionTypes.DASHBOARD_NOT_ACCESSIBLE:
            return Object.assign({}, state, { dashboardNotAccessible: true })
        case ActionTypes.GET_DASHBOARD_DATA:
            return Object.assign({}, state, { dashboardNotAccessible: false })
        case ActionTypes.GET_DASHBOARD_REPORTS_DONE:
            return Object.assign({}, state, {reports: action.payload})
        default:
            return state
    }
}

export default Dashboard