import React, { useEffect, useRef, useReducer, useImperativeHandle } from 'react'
import { useLocation } from 'react-router-dom'
import { getURLQueryParameter } from '../../helpers/PathHelpers'
import SingleItem from '../../views/SingleItem'

let gridRef = null

const MasterDataDialog = React.forwardRef((props, ref) => {
    const innerRef = useRef(null)
    const dialogBodyRef = useRef(null)
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        item: null,
        nextButtonState: 0,
        bucket: null,
        noGrid: false,
        fromDiff: false,
        columnDescriptions: {},
        editMode: false,
        showComments: false,
    })

    const location = useLocation()

    function hide() {
        setState({ bucket: null, item: null, noGrid: false, nextButtonState: 0, fromDiff: false, columnDescriptions: {} })
        window.$(innerRef.current).modal('hide')
    }

    function show(bucketId, itemId, noGrid = false, nextButtonState = 0, fromDiff, columnDescriptions = {}, editMode, showComments) {
        window.$(innerRef.current).modal('show')
        setState({ bucket: bucketId, item: itemId, noGrid, nextButtonState, fromDiff, columnDescriptions, editMode: editMode, showComments: showComments})
    }

    useEffect(() => {
        let isMounted = true;
        //Check if url says if master data dialog should open
        if ( isMounted) {
		var dialog = getURLQueryParameter(location.search, "dialog", null)
		if(dialog === "masterData") {
			var bucketId = getURLQueryParameter(location.search, "bucketId", null)
			var itemId = getURLQueryParameter(location.search, "itemId", null)
			if(bucketId && itemId) {
				show(decodeURIComponent(bucketId), decodeURIComponent(itemId))
			}
		}
    }

        return () => isMounted = false;
    }, [location])

    // functions exposed to outer components
    useImperativeHandle(ref, () => ({
        show: (bucketId, itemId, noGrid, nextButtonState, fromDiff, columnDescriptions, editMode, showComments) => show(bucketId, itemId, noGrid, nextButtonState, fromDiff, columnDescriptions, editMode, showComments),
        hide: () => hide(),
        setGridRef(gRef) {
            gridRef = gRef
        },
        setBucket(b) { setState({ bucket: b }) },
        setItem(itemId) { setState({ item: itemId }) },
        setNextButtonState(state) { setState({ nextButtonState: state }) },
    }))

    window.$(innerRef.current).on('hidden.bs.modal', () => {
        setState({ bucket: null, item: null, noGrid: false, nextButtonState: 0 })
    })

    return (
        <div ref={innerRef} className="modal" data-keyboard="false" data-backdrop="static" tabIndex="-1" role="dialog" id="dialogContainer" style={{zIndex: 5000}}>
            <div className="modal-dialog modal-dialog-custom" role="document" style={{marginTop: 100}}>
                <div className="modal-content modal-content-custom">
                    <div className="modal-body" ref={dialogBodyRef}>
                        {
                            state.bucket && state.item ?
                                <SingleItem
                                    noGrid={state.noGrid}
                                    bucketId={state.bucket}
                                    itemId={state.item}
                                    nextButtonState={state.nextButtonState}
                                    gridRef={gridRef}
                                    fromDiff={state.fromDiff}
                                    hideMasterData={_ => hide()}
                                    columnDescriptions={state.columnDescriptions}
                                    masterDataDialogRef = {dialogBodyRef}
                                    editMode={state.editMode}
                                    showComments={state.showComments}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})


export default MasterDataDialog