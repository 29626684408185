/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getLayouts, saveLayout, deleteLayout, updateLayout } from '../actions/ChecklistActions'
import ShowIf from '../components/Generic/ShowIf'
import ChecklistLayoutEditor from '../components/ChecklistLayoutEditor'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { deepClone } from '../helpers/GeneralHelpers'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'

const mapStateToProps = (state, ownProps) => {
    return {
        layouts: state.Checklist.layouts,
        newLayout: state.Checklist.newLayout
    }
}

const layoutTemplate = {
    name: "New layout",
    layout: [
        {name: "TO DO", color: "btn-primary", donecount: false},
        {name: "DONE", color: "btn-success", donecount: true},
    ]
}

class ManageLayouts extends React.Component {
    state = {
        layout: layoutTemplate,
        deleteLayout: {}
    }

    componentDidMount() {
        let { dispatch } = this.props
        dispatch(getLayouts())
        setPageTitle("Manage layouts")
    }

    componentDidUpdate(prevProps) {
        if(this.props.newLayout !== prevProps.newLayout && this.props.newLayout.name === this.state.layout.name) {
            const layout = deepClone(this.state.layout)
            layout.id = this.props.newLayout.id
            this.setState({layout: layout})
        }
    }

    updateLayoutColor = (i,c) => {
        const layout = deepClone(this.state.layout)
        const tempLayout = layout.layout
        tempLayout[i].color = c
        this.setState({layout: layout})
    }
    
    updateDoneCount = (i, donecount) => {
        if(this.hideDeleteButtonPredicate(i, this.state.layout)){
            return; // don't change anything
        }

        const layout = deepClone(this.state.layout)
        const tempLayout = layout.layout
        tempLayout[i].donecount = donecount
        tempLayout.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )

        this.setState({layout: layout})
    }

    updateLayoutName = (i,name) => {
        const layout = deepClone(this.state.layout)
        const tempLayout = layout.layout
        tempLayout[i].name = name
        this.setState({layout: layout})
    }

    deleteCategory = i => {
        const layout = deepClone(this.state.layout)
        const tempLayout = layout.layout
        tempLayout.splice(i,1)
        this.setState({layout: layout})
    }

    addCategory = c => {
        const layout = deepClone(this.state.layout)
        const tempLayout = layout.layout
        tempLayout.push(c)
        this.setState({layout: layout})
    }

    reset = () => {
        this.setState({layout: layoutTemplate})
    }

    onNameChange = e => {
        const layout = deepClone(this.state.layout)
        layout.name = e.target.value
        this.setState({layout: layout})
    }

    hideDeleteButtonPredicate(i, l) {
        let layout = deepClone(this.state.layout).layout
        return l[i] && layout[i] && i === 0
    }

    onSortEnd(oldIndex, newIndex) {
        while(this.hideDeleteButtonPredicate(newIndex, this.state.layout.layout)){
           newIndex++
        }
        let tmplayout = this.state.layout;
        let tmp = tmplayout.layout.slice()
        tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0])
        tmp.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )
        tmplayout.layout = tmp
        this.setState({ layout: tmplayout})
    }

    setLayout = l => {
        const layout = deepClone(l)
        layout.layout = JSON.parse(layout.layout)
        layout.layout.sort((x,y) => x.donecount === y.donecount  ? 0 : (x.donecount && !y.donecount ? 1 : -1) )
        this.setState({layout: layout})
    }

    promptDeleteLayout = (e, layout) => {
        e.stopPropagation()
        this.refs.deleteDialog.show()
        this.setState({deleteLayout: layout})
    }

    cancelDeleteLayout  = () => {
        this.setState({deleteLayout: {}})
    }
    
    deleteLayout = () => {
        this.props.dispatch(deleteLayout(this.state.deleteLayout.id))
        this.refs.deleteDialog.hide()
        if (this.state.layout?.id === this.state.deleteLayout.id) {
            this.setState({deleteLayout: {}}, () => this.reset())
        } else {
            this.setState({deleteLayout: {}})
        }
    }

    saveLayout = () => {
        const layout = deepClone(this.state.layout)
        layout.layout = JSON.stringify(layout.layout)
        if(layout.id) {
            this.props.dispatch(updateLayout(layout))
        } else {
            this.props.dispatch(saveLayout(layout))
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <h1 className="pt-3 margin-bottom-5px">Manage Layouts</h1>
                </div>
                <hr className={"margin-bottom-30px hr-color margin-top-5px"} />
                <div className="container-fluid row">
                    <div className="col-md-6">
                        <h2>Add/edit a layout</h2>
                        <div className="margin-bottom-10px"><b>Name:</b></div>
                        <input className="form-control margin-bottom-20px" placeholder="Layout name" value={this.state.layout.name} onChange={this.onNameChange} />
                        <ChecklistLayoutEditor 
                            layout={this.state.layout?.layout} 
                            onAddCategory={c=>this.addCategory(c)} 
                            onCategoryColorChange={(i,c) => this.updateLayoutColor(i,c)} 
                            onCategoryNameChange={(i,n) => this.updateLayoutName(i,n)} 
                            onCategoryDeletion={i=>this.deleteCategory(i)} 
                            warnCategoryDeletion={()=>false}
                            hideDeleteButtonPredicate={(i, l) => this.hideDeleteButtonPredicate(i, l)}
                            onSortEnd={(o, n) => this.onSortEnd(o, n)}
                            onUpdateDoneCount = {(i, c) => this.updateDoneCount(i, c)}
                        />
                        <div className="float-right">
                            <button className="btn btn-default" onClick={this.reset}>Clear</button>
                            <button className="btn btn-primary margin-left-10px" onClick={this.saveLayout}>Save</button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h2>Edit/delete a layout</h2>
                        <table className="table table-hover table-borderless margin-bottom-0px-i">
                            <tbody>
                                {
                                    this.props?.layouts?.map((l,i) => 
                                        <tr key={i} onClick={() => this.setLayout(l)} className="hover-cursor" >
                                            <td className="width-40" >
                                                <ShowIf if={this.state.layout?.id === l.id}>
                                                    <span className="fa fa-check" />
                                                </ShowIf>
                                            </td>
                                            <td>{l.name}</td>
                                            <td className="width-40"><span className="btn btn-default btn-xs fa fa-trash" onClick={e => this.promptDeleteLayout(e, l)}/></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <WarningAcceptDialog ref="deleteDialog" acceptText="Delete" accept={this.deleteLayout} closeHandler={this.cancelDeleteLayout} title="Delete layout">
                        Are you sure you want to delete the layout "{this.state?.deleteLayout?.name}"?
                    </WarningAcceptDialog>

                </div>
            </>
        )
    }
}
        
ManageLayouts = connect(mapStateToProps)(ManageLayouts)

export default ManageLayouts