import * as ActionTypes from '../actions/ActionTypes'

const defaultState = {
    loadingHistoryData: false,
    loadingHomeInfoData: false,
    loadingLibraryInfoData: false,
    loadingItemLinkData: false,
    loadingGridData: false,
    loadingKPIMultiple: false,
}

const Loading = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_HISTORY_DATA_DONE:
            return Object.assign({}, state, { loadingHistoryData: false })
        case ActionTypes.GET_HISTORY_DATA:
            return Object.assign({}, state, { loadingHistoryData: true })
        case ActionTypes.GET_HOME_INFO:
            return Object.assign({}, state, { loadingHomeInfoData: true })
        case ActionTypes.GET_HOME_INFO_DONE:
            return Object.assign({}, state, { loadingHomeInfoData: false })
        case ActionTypes.GET_LIBRARY_INFO:
            return Object.assign({}, state, { loadingLibraryInfoData: true })
        case ActionTypes.GET_LIBRARY_INFO_DONE:
            return Object.assign({}, state, { loadingLibraryInfoData: false })
        case ActionTypes.GET_ITEM_LINKS:
            return Object.assign({}, state, { loadingItemLinkData: true })
        case ActionTypes.GET_ITEM_LINKS_DONE:
            return Object.assign({}, state, { loadingItemLinkData: false })
        case ActionTypes.GET_REPORT_GRID_DATA:
            return Object.assign({}, state, { loadingGridData: true })
        case ActionTypes.GET_REPORT_GRID_DATA_DONE:
            return Object.assign({}, state, { loadingGridData: false })
        case ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS:
            return Object.assign({}, state, { loadingKPIMultiple: true })
        case ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS_DONE:
            return Object.assign({}, state, { loadingKPIMultiple: false })
        default:
            return state
    }
}

export default Loading