import { useRef } from "react"
import ActiveCategoryIcon from "../ReportComponents/ActiveCategoryIcon"

const FixedCategorizationSlicer = ({ column, openSlicer, editMode, removeSlicer, value, setFixedSlicerValue }) => {
    const buttonRef = useRef(null)

    const removeValue = (v) => {
        return (e) => handleClick(e, value.filter(val => val !== v))
    }

    const clear = () => {
        return (e) => handleClick(e, [])
    }

    const handleClick = (e, value) => {
        e.stopPropagation()
        setFixedSlicerValue(value)
        buttonRef.current?.blur() // otherwise the button is in focus after click and it looks horrible
    }

    const editStyle = {
        width: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        backgroundColor: "hsl(0, 0%, 92%)",
    }

    return <div className="single-slicer">
        <div className={`btn-group`}>
            <button ref={buttonRef} style={editMode ? editStyle : null} disabled={editMode} onClick={() => openSlicer()} type="button" className="btn btn-default btn-sm categorization-slicer-container">
                <div className="d-flex flex-row align-items-center">
                    <span >
                        {column}
                    </span>
                    <div className="px-2">
                        <ActiveCategoryIcon />
                    </div>
                    {value.length > 0 && <div className="mr-1" style={{ height: 18, borderRight: "1px solid #cccccc" }} ></div>}
                    {value.map((c, i) => <div style={{ cursor: "default" }} className="rounded mx-1 categorization-slicer d-flex flex-row" key={i}>
                        <div className="pl-2 pr-1">{c}</div>
                        <div onClick={removeValue(c)} style={{ paddingLeft: 6, paddingRight: 6 }} className="categorization-slicer-single-remove rounded-right abc-click"><i className="fa fa-times"></i></div>
                    </div>)}
                </div>
            </button>
            {editMode && <button onClick={_ => removeSlicer()} className={`btn btn-sm btn-default`} type="button">
                <i className="fa fa-times"></i>
            </button>}
            {!editMode && value.length > 0 && <button onClick={clear()} title="Clear" style={{ color: "#cccccc" }} className="pl-2 btn btn-sm btn-default">
                <i className="fa fa-times"></i>
            </button>}
        </div>

    </div>
}

export default FixedCategorizationSlicer
