/* REACT */
import React from 'react'
// import { connect } from 'react-redux'

class WarningSaveBar extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="alert alert-warning saveBar">
                {
                    this.props.newReport ?
                        <p className="saveBarText">Once you've added columns to your Insight, press continue</p>
                        : <p className="saveBarText">You have unsaved changes.</p>
                }
                <button disabled={this.props.disableSave} id="btn-compose-viewer-save-report-top" className="btn btn-sm btn-primary" onClick={() => this.props.save()}>{this.props.newReport ? "Continue" : 'Save'}</button>
            </div>
        )
    }
}

// WarningSaveBar = connect(mapStateToProps)(WarningSaveBar)

export default WarningSaveBar