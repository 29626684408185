import React from 'react'
import ReportDescription, { ReportDescriptionProps } from '../ReportDescription'
import GenericDialog from './GenericDialog'

interface DataDescriptionDialog2Props extends ReportDescriptionProps  {
    show: boolean,
    onClose: () => void,
    title: string,
}

const DataDescriptionDialog2 = ({...props}: DataDescriptionDialog2Props) => {

    const getButtons = () => {
        return <div>
            <button onClick={props.onClose} type="button" className="btn btn-default margin-right-10px" >Close</button>
        </div>

    }

    return <GenericDialog title={props.title} show={props.show} onClose={props.onClose} getButtons={getButtons} style={{minWidth: 400}}>
        <ReportDescription {...props} />
    </GenericDialog>
}

export default DataDescriptionDialog2
