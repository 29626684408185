import React, { useMemo, useState, useEffect, useRef } from 'react'
import ShowIf from '../Generic/ShowIf'
import { DashboardFull, DashboardKPI, DashboardWidget, DashboardWidgetData, ParsedDashboardData, DashboardWidgetFull, DashboardTargetNew, DashboardRow, LibraryReport } from '../../types/transfertypes'
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, pointerWithin } from '@dnd-kit/core'
import { SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { getKpiTotalsNew, getWidgetDefaultName } from '../../helpers/DashboardHelpers'
import { formatValue } from '../../helpers/ReportHelpers'
import { CSS } from "@dnd-kit/utilities";

import '../../css/DashboardAdmin/WidgetRow.css'
import PopOver from '../Generic/PopOver'
import { getDefaultTarget } from '../Dialogs/EditTargetDialog'

export enum Direction {
    UP,
    DOWN,
    RIGHT,
    LEFT,
}
export interface WidgetRowProps {
    widgetRow: DashboardWidgetData,
    rowNum: number,
    moveWidget: (rowNum: number, from: number, to: number) => void,
    dashboard: DashboardFull,
    parsedDashboard: ParsedDashboardData,
    editWidget: (widget: DashboardWidget, rowNum: number, widgetNum: number) => void,
    deleteWidget: (rowNum: number, widgetNum: number) => void,
    deleteRow: (rowNum: number) => void,
    moveRow: (rowNum: number, direction: Direction) => void,
    saveWidget: (widget: DashboardWidget, rowNum: number, widgetNum: number) => void,
    reports: LibraryReport[],
    clearTarget: (row_id: string, kpi_key: string) => DashboardRow | undefined,
    setTarget: (row_id: string, kpi_key: string, target: DashboardTargetNew) => DashboardRow | undefined
    editTarget: (kpi_key: string, row: DashboardRow | undefined) => void
}

const WidgetRow = (props: WidgetRowProps) => {

    const [activeDrag, setActiveDrag] = useState<string | undefined>()

    const total = useMemo(() => getKpiTotalsNew(props.dashboard.table_data, props.parsedDashboard.kpis, props.parsedDashboard.main_row), [props.dashboard, props.parsedDashboard])

    const handleDragStart = (e: DragStartEvent) => {
        const { active } = e
        setActiveDrag(`${active.id}`)
    }

    const handleDragEnd = (e: DragEndEvent) => {
        setActiveDrag(undefined)
        const { active, over } = e

        if (over != undefined && active.id != over.id) {
            const oldIndex = props.widgetRow.widgets.findIndex(x => x.id == active.id)
            const newIndex = props.widgetRow.widgets.findIndex(x => x.id == over.id)

            props.moveWidget(props.rowNum, oldIndex, newIndex)
        }
    }


    const getDisplayValue = (value: number, kpi: DashboardKPI, kpiNumber: number) => {

        let tmpValue = kpi.percent ? value * 100 / total[kpiNumber] : value
        let tmp = formatValue(true, tmpValue, kpi.decimal)
        if (kpi.percent) {
            return `${tmp}%`
        }
        return tmp
    }

    const editWidget = (widget: DashboardWidget, widgetId: string) => {
        let widgetNum = props.widgetRow.widgets.findIndex(x => x.id == widgetId)
        if (widgetNum == -1) return
        props.editWidget(widget, props.rowNum, widgetNum)
    }

    const deleteWidget = (widgetId: string) => {
        let widgetNum = props.widgetRow.widgets.findIndex(x => x.id == widgetId)
        if (widgetNum == -1) return
        props.deleteWidget(props.rowNum, widgetNum)
    }

    const saveWidget = (widget: DashboardWidget, widgetId: string) => {
        let widgetNum = props.widgetRow.widgets.findIndex(x => x.id == widgetId)
        if (widgetNum == -1) return
        props.saveWidget(widget, props.rowNum, widgetNum)
    }

    const getWidget = (c: DashboardWidget, overlay: boolean = false) => {
        let value = props.dashboard.table_data[c.kpi_key]?.[c.row_id]
        let kpiIndex = props.parsedDashboard.kpis.findIndex(v => v.kpi_key == c.kpi_key)
        let displayValue = value != null ? getDisplayValue(value.last, props.parsedDashboard.kpis[kpiIndex], kpiIndex) : 'N/A'
        let widgetData = props.dashboard.widget_data?.[props.rowNum]?.widgets.find(x => x.row_id == c.row_id && x.kpi_key == c.kpi_key)
        return (
            <SortableWidget 
                key={c.id} 
                id={c.id} 
                widgetData={widgetData} 
                editWidget={editWidget} 
                widget={c} 
                type={props.widgetRow.type} 
                active={activeDrag == c.id && !overlay} 
                displayValue={displayValue} 
                overlay={overlay} 
                deleteWidget={deleteWidget} 
                parsedDashboard={props.parsedDashboard} 
                saveWidget={saveWidget}
                reports={props.reports}
                clearTarget={props.clearTarget}
                setTarget={props.setTarget}
                editTarget={props.editTarget}
            />
        )
    }

    let isTop = props.rowNum == 0
    let isBottom = props.rowNum == props.parsedDashboard.widgets.length - 1

    return (
        <div className='d-flex'>
            <div className={`p-2 mt-2 mb-2 widget-row ${activeDrag != undefined ? 'drag-active' : ''}`}>
                <DndContext
                    collisionDetection={pointerWithin}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={props.widgetRow.widgets} strategy={horizontalListSortingStrategy}>
                        {
                            props.widgetRow.widgets.map((c) => {
                                return (
                                    getWidget(c)
                                )
                            })
                        }
                    </SortableContext>
                    <DragOverlay dropAnimation={null}>
                        {activeDrag ? getWidget(props.widgetRow.widgets.find(x => x.id == activeDrag) ?? props.widgetRow.widgets[0], true) : null}
                    </DragOverlay>
                </DndContext>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center widget-button'>
                <i className={`fa fa-angle-up fa-2x fa-fw abc-click ${isTop ? 'widget-button-disabled' : ''}`} onClick={() => props.moveRow(props.rowNum, Direction.UP)} aria-hidden="true"></i>
                <i className={`fa fa-angle-down fa-2x fa-fw abc-click ${isBottom ? 'widget-button-disabled' : ''}`} onClick={() => props.moveRow(props.rowNum, Direction.DOWN)} aria-hidden="true"></i>
                <i className="fa fa-trash fa-lg fa-fw mt-2 abc-click" onClick={() => props.deleteRow(props.rowNum)} aria-hidden="true"></i>
            </div>
        </div>
    )
}

export default WidgetRow

interface SortableWidgetProps {
    id: string,
    widget: DashboardWidget,
    parsedDashboard: ParsedDashboardData,
    type: string,
    active?: boolean,
    overlay?: boolean,
    displayValue: string,
    editWidget: (widget: DashboardWidget, widgetNum: string) => void,
    deleteWidget: (widgetNum: string) => void,
    saveWidget: (widget: DashboardWidget, widgetNum: string) => void,
    widgetData?: DashboardWidgetFull,
    reports: LibraryReport[],
    clearTarget: (row_id: string, kpi_key: string) => DashboardRow | undefined,
    setTarget: (row_id: string, kpi_key: string, target: DashboardTargetNew) => DashboardRow | undefined
    editTarget: (kpi_key: string, row: DashboardRow | undefined) => void
}

const SortableWidget = (props: SortableWidgetProps) => {
    const [showPopover, setShowPopover] = useState(false)
    const [tmpValue, setTmpValue] = useState<string | undefined>()
    const [disablePopoverInput, setDisablePopoverInput] = useState(false)

    const valueFieldRef = useRef<any>()

    const getTarget = () => {
        let row = getRow()
        const target = row?.targets[props.widget.kpi_key]?.at(0)
        return target
    }

    const getRow = () => {
        let row = undefined
        if (props.parsedDashboard.main_row.row_id == props.widget.row_id) {
            row = props.parsedDashboard.main_row
        } else {
            row = props.parsedDashboard.rows.find(r => r.row_id == props.widget.row_id)
        }
        return row
    }

    useEffect(() => {
        const target = getTarget()
        if (target != undefined){
            if (target.is_kpi_target) {
                let kpi = props.parsedDashboard.kpis.find(v => v.kpi_key == target.kpi_key)
                if (kpi == undefined) return
                setTmpValue(kpi.label != '' ? kpi.label : kpi.column)
                setDisablePopoverInput(true)
            } else {
                setTmpValue(`${target.value}`)
                setDisablePopoverInput(false)
            }
        } else {
            setTmpValue('')
            setDisablePopoverInput(false)
        }
    }, [props.widget, props.parsedDashboard])

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^-?\d+\.?\d*$/gm;
        if (regex.test(e.target.value) || e.target.value == '') {
            setTmpValue(e.target.value)
        }
    }

    const clearTarget = () => {
        props.clearTarget(props.widget.row_id, props.widget.kpi_key)
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: props.overlay ? "grabbing" : "auto"
    }

    const deleteWidget = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        props.deleteWidget(props.id)
    }

    const onPopoverClose = () => {
        let target = undefined
        let row = getRow()
        // If no target set
        if (tmpValue == '' || tmpValue == undefined) {
            props.clearTarget(props.widget.row_id, props.widget.kpi_key)
        } else {
            // If one is set, we only want to update it if it is a value target
            target = getTarget()
            if (target == undefined) {
                target = getDefaultTarget()
                target.enabled = true
                target.value = Number(tmpValue)
                row = props.setTarget(props.widget.row_id, props.widget.kpi_key, target)
            }else if (!target.is_kpi_target) {
                target.value = Number(tmpValue)
                target.enabled = true
                row = props.setTarget(props.widget.row_id, props.widget.kpi_key, target)
            }
        }
        setShowPopover(false)
        return row
    }

    const onEditTarget = () => {
        let tmp = onPopoverClose()

        props.editTarget(props.widget.kpi_key, tmp)
    }

    const showTarget = () => {
        setShowPopover(true)

        if (valueFieldRef.current) {
            valueFieldRef.current.focus()
        }
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If the enter key has been pressed, save the target
        if (e.charCode == 13) {
            onPopoverClose()
        }
    }

    const target = getTarget()
    const dragStyle = props.overlay ? { cursor: "grabbing" } : { cursor: "grab" }
    const large = props.type == "large"
    const topMargin = large ? { marginTop: "2rem" } : !props.widget.shown ? { marginTop: "1.2rem" } : {}
    const targetStyle = target != undefined && target.enabled ? {color: "black"} : {}
    let trendStyle: React.CSSProperties = {}
    if (props.widget.trend == 1) {
        trendStyle = {color: "#0fa359"}
    } else if (props.widget.trend == -1) {
        trendStyle = {color: "#b4182d"}
    }

    const widgetLabel = props.widget.label != "" ? props.widget.label : getWidgetDefaultName(props.widget.row_id, props.widget.kpi_key, props.parsedDashboard, props.reports)

    return (
        <div ref={setNodeRef} style={style} className={`vertical-align-middle widget ${large ? 'widget-lg' : 'widget-sm'} ${props.active ? 'active' : null} ${props.widget.shown ? '' : 'not-shown'}`} >
            <div className="widget-top">
                <div className="d-flex p-2">
                    <ShowIf if={props.widget.shown}>
                        <i className="fa fa-trash widget-setting widget-delete-lg fa-lg" onClick={deleteWidget} aria-hidden="true" title='Delete'></i>
                    </ShowIf>
                </div>
                <div className="d-flex justify-content-center">
                    <img {...attributes} {...listeners} style={dragStyle} className='widget-drag-handle' src="./img/drag_handle.svg" alt=""/>
                </div>
                <div className="d-flex justify-content-end p-2">
                    <ShowIf if={props.widget.shown}>
                        <div>
                            <i className="fa fa-dot-circle-o widget-setting widget-delete-lg fa-lg" style={targetStyle} onClick={showTarget} aria-hidden="true" title='Select target'>
                            </i>
                            <PopOver show={showPopover} onClose={onPopoverClose}>
                                <label htmlFor="widget-value">
                                    Set target value: 
                                </label>
                                <div className="d-flex align-items-center">
                                    <input ref={valueFieldRef} onKeyPress={onKeyPress} id="widget-value" type="text" disabled={disablePopoverInput} className="form-control" placeholder="Insert number" pattern='^-?\d+\.?\d*$' value={tmpValue ?? ''} onChange={onValueChange} />
                                    <i style={{marginLeft: 16}} title="Save" className="fa fa-check widget-setting fa-lg" aria-hidden="true" onClick={onPopoverClose}></i>
                                    <i style={tmpValue == '' ? {color: "#c4c4c4", pointerEvents: "none", marginLeft: 16} : {marginLeft: 16}} title="Remove target" className="fa fa-times widget-setting fa-lg" aria-hidden="true" onClick={clearTarget}></i>
                                    <button className='btn btn-sm btn-default ml-2 d-flex justify-content-center align-items-center' style={{width: 35, height: 35, border: "none"}} title='Advanced settings...' onClick={onEditTarget}>
                                        <img className="" style={{width: 20, height: 20}} src="./img/PopUpIcon.svg" alt="" />
                                    </button>

                                </div>
                            </PopOver>
                        </div>
                    </ShowIf>
                </div>
                
            </div>
            <div style={topMargin} className={`d-flex flex-column align-items-center abc-click widget-text-container`} onClick={() => props.editWidget(props.widget, props.id)}>
                <ShowIf if={props.widget.shown}>
                    {large ?
                        <>
                            <h1 className='widget-text'><i style={trendStyle} className="fa fa-arrow-up mr-1" aria-hidden="true"></i>{props.displayValue}</h1>
                        </> :
                        <>
                            <h3 className='widget-text'><i style={trendStyle} className="fa fa-arrow-up mr-1" aria-hidden="true"></i>{props.displayValue}</h3>
                        </>
                    }
                    <span style={{textAlign: "center", maxWidth: "80%", whiteSpace: "pre-wrap"}} title={widgetLabel} className=" line-clamp-1">{widgetLabel}</span>
                    <span style={large ? { fontSize: 18, color: "#9c9c9c" } : {color: "#9c9c9c"}}>Click to edit</span>
                </ShowIf>
                <ShowIf if={!props.widget.shown}>
                    {large ?
                        <h1 className=''>No KPI</h1> :
                        <h2>No KPI</h2>
                    }
                    <span style={large ? { fontSize: 18 } : {}}>Click to add KPI</span>
                </ShowIf>
            </div>
        </div>
    )
}
