import React from 'react';
import { getProfitHistoryData, getProfitHistoryValue, getTotalProfitData, calculateProfitTotal, findLastIndex, getProfitMonth, getTotalForPercentage, findLastHistoryDataIndex } from '../../helpers/ItemHelpers'
import ShowIf from "../Generic/ShowIf"
import moment from 'moment'
import LoadingIcon from '../LoadingIcon';

const defaultTotalRow = { use_columns: false, columns: [], label: "Grand total" }

const ProfitDataContent = ({ profit, showDecimals, profitColumn, historyData, bucket, selectedColumnIndex, setColumn, setTotalIndex, useSummary, fromReport, viewPercentage, setPercentage, useLoading, loading }) => {
    if (!profit) return null;
    const sections = JSON.parse(profit.sections)
    const headings = JSON.parse(profit.headings)
    const totalRow = profit.total_row ? JSON.parse(profit.total_row) : defaultTotalRow
    const summaryColumns = JSON.parse(profit.summary_columns)
    const summaryColumnsTitle = profit.summary_columns_title

    const lastDataIndex = findLastHistoryDataIndex(historyData, fromReport)

    let columnIndexOffset = 0;
    if(lastDataIndex > 12) 
        columnIndexOffset = 12;
    if(lastDataIndex > 24) 
        columnIndexOffset = 24;

    const month = getProfitMonth(historyData, columnIndexOffset + selectedColumnIndex, fromReport).slice(0, -3)
    const totalData = getTotalProfitData(sections, headings, historyData, columnIndexOffset + selectedColumnIndex, fromReport)
    const totalForPercentage = getTotalForPercentage(sections, headings, historyData, fromReport, columnIndexOffset + selectedColumnIndex, lastDataIndex)

    const getSelectedRow = (name, className) => {
        if (profitColumn && profitColumn.name === name) {
            return className
        }
        return ''
    }

    const format = (value, index) => {
        if (value !== null) {
            if (typeof value === "number") {
                if (viewPercentage && index !== undefined) {
                    if (value === 0) return "0 %"
                    const percentage = (value / totalForPercentage[index]) * 100 // maybe handle case where totalForPercentage[index] is 0
                    if (isNaN(percentage)) return "-"
                    return Math.round(percentage) + " %"
                } else {
                    let decimalCount = showDecimals ? 2 : 0
                    let x = parseFloat(value)
                    if (isNaN(x)) {
                        return '-'
                    }
                    return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
                }
            }
        }
        return value
    }

    const fromNow = (time) => {
        return moment.unix(time).fromNow();
    }

    return (
        <div className="margin-top-5px row ml-0 mr-0">
            <div className={`col-5 ${summaryColumnsTitle === "" ? 'margin-top-39px' : ''}`}>
                {
                    summaryColumns.length > 0 && useSummary ?
                        <div>
                            <ShowIf if={summaryColumnsTitle}>
                                <h4>{summaryColumnsTitle}</h4>
                            </ShowIf>
                            <table className="col-12 zero-margin table-bordered table-striped table-condensed">
                                <tbody>
                                    {
                                        summaryColumns.map((sc, i) => {
                                            const data = getProfitHistoryData(historyData, sc.column, fromReport)
                                            const val = getProfitHistoryValue(data, findLastIndex(data.data))
                                            return (
                                                <tr key={i}>
                                                    <td><b>{sc.label !== "" ? sc.label : sc.column}</b></td>
                                                    <td className={`single-item-right-data-column`}>{format(val)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        : null
                }
            </div>
            <div className="col-1"></div>
            <div className="col-6">
                <div className="margin-bottom-20px">
                    {
                        sections.map((section, i) => {
                            return (
                                <div key={i} className={`${i !== 0 ? 'margin-top-20px' : ''} row`}>
                                    {
                                        <div className="container p-0">
                                            <div className="row">
                                                <div className="col mb-2">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="pr-2">
                                                            {
                                                                section.name != "" ?
                                                                    <h4 className="mb-0" title={`Updated ${fromNow(bucket.last_update)}`}>{section.name}</h4>
                                                                    : null
                                                            }
                                                        </div>
                                                        <ShowIf if={useLoading}>
                                                            <div>
                                                                <LoadingIcon size="small" loading={loading} />
                                                            </div>
                                                        </ShowIf>
                                                    </div>
                                                </div>
                                                {
                                                    i === 0 ?
                                                        <div className="col-2 text-align-right mb-1">
                                                            <button onClick={_ => setPercentage()} className={`btn btn-default profit-percentage-button ${viewPercentage ? "active" : ""}`}>%</button>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    }
                                    <table className={`col-md-12 zero-margin table-bordered table-striped table-condensed ${i === 0 ? 'margin-right-20 margin-left-25px' : ''} `} key={section.name + i.toString()}>
                                        <thead>
                                            {
                                                headings.length > 0 && i === 0 ?
                                                    <tr>
                                                        <td>-</td>
                                                        {
                                                            headings.map((h, j) =>
                                                                <td className="single-item-right-data-column" key={h.name + j.toString()}><b>{h.name} {h.type !== "none" ? month : ""}</b></td>
                                                            )
                                                        }
                                                    </tr>
                                                    : null
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                                section.rows.map((s_item, j) => {
                                                    const label = s_item.label !== "" ? s_item.label : "-"
                                                    return (
                                                        <tr key={label + j.toString()} >
                                                            <td>
                                                                <b title={label}>{label}</b> &ensp;
                                                            </td>
                                                            {
                                                                headings.map((h, k) => {
                                                                    const c = s_item.columns[k]
                                                                    const canClick = headings[k].type !== "none"
                                                                    const data = getProfitHistoryData(historyData, c, fromReport)
                                                                    const val = getProfitHistoryValue(data, canClick ? columnIndexOffset + selectedColumnIndex : findLastIndex(data.data))
                                                                    return (
                                                                        <td key={label + j.toString() + k.toString()} className={`single-item-right-data-column ${canClick ? 'abc-click abc-data-row' : ''} ${canClick ? getSelectedRow(c, val >= 0 ? 'positive-val' : val < 0 ? 'negative-val' : '') : ''} `} onClick={() => { if (!canClick) return; setColumn({ name: c, label: label, subtract: s_item.subtract, type: headings[k].type }); }}>
                                                                            {s_item.subtract && (!isNaN(val) && val !== 0) ? '-' : ''} {format(val, k)}
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td className="section-total"><b>{section.total_row.label === "" ? "Total" : section.total_row.label}</b></td>
                                                {
                                                    headings.map((h, l) => {
                                                        const canClick = headings[l].type !== "none"
                                                        let total
                                                        let column
                                                        if (section.total_row.use_columns) {
                                                            const _data = getProfitHistoryData(historyData, section.total_row.columns[l], fromReport)
                                                            total = section.total_row.columns[l] ? getProfitHistoryValue(_data, canClick ? columnIndexOffset + selectedColumnIndex : findLastIndex(_data.data)) : "-"
                                                            column = section.total_row.columns[l] ? section.total_row.columns[l] : "NA"
                                                        } else {
                                                            total = totalData[i][l]
                                                            column = "-" + i + "-" + l
                                                        }
                                                        total = !!total ? total : "-"

                                                        return (
                                                            <td
                                                                key={column + "-" + i.toString() + "-" + l.toString()}
                                                                onClick={() => { if (!canClick) return; if (section.total_row.use_columns) { setColumn({ name: column, type: headings[l].type, useTotal: true, label: section.total_row.label }) } else { setTotalIndex({ x: l, y: i }, { name: column, useTotal: true, label: section.total_row.label, type: headings[l].type }) } }}
                                                                className={`single-item-right-data-column section-total ${canClick ? 'abc-click abc-data-row' : ''}  ${canClick ? getSelectedRow(column, total >= 0 ? 'positive-val' : total < 0 ? 'negative-val' : '') : ''}`}
                                                            >
                                                                <b>{format(total, l)}</b>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                    <div className="margin-top-20px row">
                        <table className="col-12 zero-margin table-bordered table-striped table-condensed">
                            <tbody>
                                <tr>
                                    <td className="grand-total"><b>{totalRow.label ?? "Grand total"}</b></td>
                                    {
                                        Array.apply(null, Array(headings.length)).map((n, i) => {
                                            const canClick = headings[i].type !== "none"
                                            let total
                                            let column
                                            if (totalRow.use_columns) {
                                                column = totalRow.columns[i]
                                                const _data = getProfitHistoryData(historyData, column, fromReport)
                                                total = column ? getProfitHistoryValue(_data, canClick ? columnIndexOffset + selectedColumnIndex : findLastIndex(_data.data)) : "-"
                                            } else {
                                                total = calculateProfitTotal(totalData, i)
                                                column = `Grand total-${i + 1}`
                                            }
                                            return (
                                                <td
                                                    key={i}
                                                    className={`single-item-right-data-column grand-total ${canClick ? 'abc-click abc-data-row' : ''} ${(total > 0) ? 'green-text' : (total < 0) ? 'red-text' : ''} ${canClick ? getSelectedRow(column, total >= 0 ? 'positive-val' : total < 0 ? 'negative-val' : '') : ''} `}
                                                    onClick={() => { if (!canClick) return; if (totalRow.use_columns) { setColumn({ name: column, type: headings[i].type, useTotal: true, label: totalRow.label }) } else { setTotalIndex({ x: i, y: sections.length }, { name: column, useTotal: true, label: totalRow.label, type: headings[i].type }) } }}
                                                >
                                                    <b>{format(total, i)}</b>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProfitDataContent;