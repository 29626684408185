import React from 'react'

import '../../css/wordWrap.css'

class WordWrap extends React.Component {
    render() {
        const { marginBottom } = this.props
        return (
        <span style={ marginBottom  ? {marginBottom: 1} : {}} className="word-wrapper-outer">
            <span className="word-wrapper-inner">
                {this.props.children}
            </span>
        </span>
        )
    }
}

export default WordWrap