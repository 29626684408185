import React, { useMemo, useState, useEffect } from  'react'
import {DashboardReportShortInfo, DashboardOverview, LibraryReport} from '../../types/transfertypes'
import GenericDialog from './GenericDialog'
import { SelectOption } from '../../helpers/TypeHelpers'
// @ts-ignore
import Select from '../Generic/CustomSelect'
import ShowIf from '../Generic/ShowIf'

import '../../css/CreateDashboardDialog.css'
import InfoTooltip from '../Generic/InfoTooltip'


export interface CreateDashboardDialogProps {
    show: boolean,
    reports: LibraryReport[],
    dashboardOverview: DashboardOverview[],
    onClose: () => void,
    onCreate: (name: string, report_id: number, main_label: string) => void,
    onCopy: (title: string, d_id: number, org_id: number) => void,
}

enum View {
    PickType,
    New,
    Copy
}

const CreateDashboardDialog = (props: CreateDashboardDialogProps) => {
    const [tmpReport, setTmpReport] = useState<SelectOption<DashboardReportShortInfo> | undefined>()
    const [tmpView, setTmpView] = useState<View>(View.PickType)
    const [tmpDashboardName, setTmpDashboardName] = useState('')
    const [tmpDashboardToCopy, setTmpDashboardToCopy] = useState<DashboardOverview | undefined>(undefined)

    let reportOptions: SelectOption<DashboardReportShortInfo>[] = useMemo(() => props.reports ? props.reports.map(v => { return { label: v.report.name, value: v.report } }).sort((a, b) => a.label.localeCompare(b.label)) : [], [props.reports])

    useEffect(() => {
        if (!props.show) {
            setTmpView(View.PickType)
            setTmpReport(undefined)
            setTmpDashboardName('')
            setTmpDashboardToCopy(undefined)
        }

    }, [props.show])

    const disableSave = () => {
        if (tmpView == View.PickType) return true

        if (tmpView == View.New) {
            if (tmpReport == undefined || tmpDashboardName == '') return true
        }

        if (tmpView == View.Copy) {
            if (tmpDashboardName == '' || tmpDashboardToCopy == undefined) return true
        }

        return false
    }

    const onSave = () => {
        if (tmpView == View.New && tmpDashboardName != '' && tmpReport != undefined) {
            props.onCreate(tmpDashboardName, tmpReport.value.report_id, '')
        }

        if (tmpView == View.Copy && tmpDashboardName != '' && tmpDashboardToCopy != undefined) {
            props.onCopy(tmpDashboardName, tmpDashboardToCopy.id, tmpDashboardToCopy.organization_id)
        }

    }

    const onPickNew = () => {
        setTmpDashboardName('')
        setTmpView(View.New)
    }

    const onDashboardPick = (d: DashboardOverview) => {
        setTmpView(View.Copy)
        setTmpDashboardName(`${d.title} - Copy`)
        setTmpDashboardToCopy(d)
    }

    const getButtons = () => {
        return <div>
            <button style={{ marginRight: 7 }} disabled={disableSave()} onClick={onSave} className='btn btn-primary'>Create</button>
            <button onClick={props.onClose} className='btn btn-default'>Cancel</button>
        </div>

    }

    return <div>
        <GenericDialog show={props.show} title="Create Dashboard" getButtons={getButtons} onClose={props.onClose} style={{width: 400}}>

            <ShowIf if={tmpView == View.PickType}>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <button className="btn btn-default" onClick={onPickNew}>
                        Create without template
                    </button>
                    <div className="mt-3 mb-3">or</div>
                    <div>
                        Copy an existing Dashboard
                        <div className="dashboard-picker">
                            {
                                props.dashboardOverview.map((v,i) => {
                                    return <div key={i} onClick={() => onDashboardPick(v)} className="dashboard-picker-item abc-click">
                                        {v.title}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </ShowIf>
            <ShowIf if={tmpView != View.PickType}>
                <div className="mb-3 ">
                    <span className="abc-click dashboard-back" onClick={() => setTmpView(View.PickType)}><i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</span>
                </div>
                <label style={{marginBottom: 0}} htmlFor="dashboard-name">Dashboard name:</label>
                <input id="dashboard-name" type="text" placeholder="Dashboard Name" style={{ marginBottom: 10 }} className="form-control" value={tmpDashboardName} onChange={(e) => setTmpDashboardName(e.target.value)} />
            </ShowIf>
            <ShowIf if={tmpView == View.New}>
                <label style={{marginBottom: 0}}>Master Insight: <InfoTooltip text="The Insight that all filter rows will be based on." /></label>
                <Select
                    onChange={(v: SelectOption<DashboardReportShortInfo>) => setTmpReport(v)}
                    options={reportOptions}
                    isSearchable
                    value={tmpReport}
                    placeholder="Choose Master Insight"
                />
            </ShowIf>
            <ShowIf if={tmpView == View.Copy}>
                <span>The table, widgets and targets will be copied from the <i>{tmpDashboardToCopy?.title}</i> dashboard.</span>
            </ShowIf>

        </GenericDialog>

    </div>
    
}

export default CreateDashboardDialog
