import React, { useState, useEffect } from 'react';
// @ts-ignore
import { Prompt } from 'react-router-dom';


// How to use:
// To set up, insert this where you put your other states:
// const {routerPrompt, setUnsavedChanges} = useUnsavedChangesWarning("You have unsaved changes, are you sure you want to leave?")
// And insert this where you render your html:
// { routerPrompt }
//
// Now you can use setUnsavedChanges to set if the page should warn when the user tries to leave

const useUnsavedChangesWarning = (
  message = 'Wait, You have unsaved changes?'
) => {
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = unsavedChanges
      ? () => unsavedChanges
      : null;

    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [unsavedChanges, message]);

  const routerPrompt = <Prompt when={unsavedChanges} message={message} />;

  return { routerPrompt, setUnsavedChanges };
};

export default useUnsavedChangesWarning;

