import { RefObject } from 'react'

class NpsManager {
    static ref:RefObject<any>|null

    static setInstanceRef(r:RefObject<any>) {
        NpsManager.ref = r
    }

    static getInstanceRef() {
        return NpsManager.ref
    }
}
NpsManager.ref = null

export const setNpsRef = (ref:RefObject<any>) => NpsManager.setInstanceRef(ref)

export const showNpsDialog = () => NpsManager.ref?.current.show()