import React, { useMemo, useState } from "react"
import { Column, LibraryReport, QueryCriterion, Report, ReportViewSettings } from "../../types/transfertypes";
//@ts-ignore
import Select from "react-select"
import ShowIf from "../Generic/ShowIf";
import { mapColumnTypes, SelectOption } from "../../helpers/TypeHelpers";
import { ReportTemplateKPI } from "../../types/types";
import GenericDialog from "./GenericDialog";

interface FixReportDialogProps {
    data: LibraryReport,
    show: boolean,
    onClose: () => void,
    dispatch: (pack: any) => void,
    allColumns: Column[]
    onSave: (report: Report) => void,
}

function FixReportDialog(props:FixReportDialogProps) {
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const [tmpReport, setTmpReport] = useState(structuredClone(props.data.report))
    const [removedMissingColumns, setRemovedMissingColumns] = useState<string[]>([])
    const [replacedMissingColumns, setReplacedMissingColumns] = useState<string[]>([])
    const [selectedReplacementColumnMap, setSelectedReplacementColumnMap] = useState<{[key:string]:Column}>({})

    const shownMissingColumns = useMemo(() => 
        props.data?.missing?.filter(c => !removedMissingColumns.includes(c) && !replacedMissingColumns.includes(c)) ?? [] 
    ,[props.data.missing, removedMissingColumns, replacedMissingColumns])

    const isCinFilter = (c: string) => {
        const filter: QueryCriterion[] = JSON.parse(tmpReport.filter)

        if(tmpReport.sort_column === c) return true;
        if(filter.some(f => f.column === c)) return true;
        if(filter.some(f => f.is_column && f.target_values?.length > 0 && f.target_values[0] === c)) return true;
        return false
    }

    // WARNING: This might be incomplete due to the mysterious nature of report view_settings
    const isCinViewSettings = (c: string) => {
        let viewSettings = JSON.parse(tmpReport.view_settings)
        return !!viewSettings.detailed_report_columns?.includes(c)
    }

    const alreadySelectedReplacements = useMemo(() => {
        return Object.values(selectedReplacementColumnMap).map(c => c.name)
    }, [selectedReplacementColumnMap])
    
    const columns = useMemo(() => {
        const reportColumns:string[] = JSON.parse(props.data.report.columns)
        return props.allColumns.filter(c => !reportColumns.includes(c.name))
    }, [props.allColumns, props.data])

    const handleRemoveColumn = (c: string) => {
        const updated = structuredClone(tmpReport)

        const columns: string[] = JSON.parse(updated.columns)
        updated.columns = JSON.stringify(columns.filter(col => col !== c))

        const kpis: ReportTemplateKPI[] = JSON.parse(updated.kpis);
        updated.kpis = JSON.stringify(kpis.filter(k => k.column !== c))

        const fixedSlicers: string[] = JSON.parse(updated.fixed_slicer);
        updated.fixed_slicer = JSON.stringify(fixedSlicers.filter(fs => fs !== c))

        const viewSettings:ReportViewSettings = JSON.parse(updated.view_settings);
        viewSettings.detailed_report_columns = viewSettings.detailed_report_columns.filter(rc => rc !== c)
        updated.view_settings = JSON.stringify(viewSettings)

        setTmpReport(updated)
        setChangesMade(true)
        setRemovedMissingColumns([...removedMissingColumns, c])
    }

    const handleReplaceColumn = (c: string, replacement: Column | undefined) => {
        if(!replacement) return;
        const updated = structuredClone(tmpReport)

        const columns: string[] = JSON.parse(updated.columns)
        updated.columns = JSON.stringify(columns.map(col => col === c ? replacement.name : col ))
        
        updated.sort_column = updated.sort_column === c ? replacement.name : updated.sort_column
        let filter: QueryCriterion[] = JSON.parse(updated.filter)
        filter = filter.map(crit => {
            let tmpCrit = structuredClone(crit)
            if(crit.column === c){
                tmpCrit.column = replacement.name
            }
            if(crit.is_column && crit.target_values?.length > 0 && crit.target_values[0] === c){
                crit.target_values[0] = replacement.name
            }
            return tmpCrit
        })
        updated.filter = JSON.stringify(filter)

        let kpis: ReportTemplateKPI[] = JSON.parse(updated.kpis);
        kpis = kpis.map(k => k.column === c ? {...k, column: replacement.name} : k);
        updated.kpis = JSON.stringify(kpis)

        let fixedSlicers: string[] = JSON.parse(updated.fixed_slicer);
        fixedSlicers = fixedSlicers.map(fs => fs === c ? replacement.name : fs);
        updated.fixed_slicer = JSON.stringify(fixedSlicers)

        const viewSettings:ReportViewSettings = JSON.parse(updated.view_settings);
        viewSettings.detailed_report_columns = viewSettings.detailed_report_columns.map(col => col === c ? replacement.name : col)
        updated.view_settings = JSON.stringify(viewSettings)

        setTmpReport(updated)
        setChangesMade(true)
        setReplacedMissingColumns([...replacedMissingColumns, c])
    }
    
    const handleReset = () => {
        setTmpReport(structuredClone(props.data.report))
        setSelectedReplacementColumnMap({})
        setRemovedMissingColumns([])
        setReplacedMissingColumns([])
        setChangesMade(false)
    }

    const handleSave = () => {
        if(!changesMade){ // if nothing was changed
            props.onClose();
            return;
        }
        props.onSave(tmpReport)
        props.onClose();
    }

    const getCompareTypeForColumnInFilter = (c:string) => {
        const filter: QueryCriterion[] = JSON.parse(tmpReport.filter)

        const columnCrit = filter.find(f => f.column === c);
        if(!columnCrit) return -1;
        return columnCrit.compare_type;
    }

    
    if(!props.data.missing) return null
    return (
        <GenericDialog 
            show={props.show}
            title={`Fix ${props.data.report.name}`}
            onClose={handleSave}
            getButtons={() => (
                <div>
                    <button onClick={handleSave} className='btn btn-default mr-2'>Close</button>
                </div>
            )}
            style={{width: 800}}
        >
            {shownMissingColumns.length > 0 && <table className="w-100 table table-striped">
                <thead>
                    <tr>
                        <th>Missing Column</th>
                        <th>Replacement Column</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {shownMissingColumns.map((c, i) => {
                        const isColInFilter = isCinFilter(c);
                        const isColInViewSettings = isCinViewSettings(c);
                        return (
                        <tr key={i}>
                            <td  className="align-middle">
                                {c}
                                {isColInFilter && <i className='d-inline-block fa fa-warning text-danger ml-2' aria-hidden="true" title="This column is part of the slicer for this Action"></i>}    
                            </td>
                            <td>
                                <ShowIf if={columns.length > 0 || isColInFilter || isColInViewSettings}>
                                    <Select
                                        className="w-100"
                                        isSearchable
                                        options={ isColInFilter
                                            ? props.allColumns
                                                .filter(col => col.type === mapColumnTypes(getCompareTypeForColumnInFilter(c)))
                                                .map(col => ({label: col.name, value: col}))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                            : columns
                                                .filter(col => !alreadySelectedReplacements.includes(col.name))
                                                .map(col => ({label: col.name, value: col}))
                                                .sort((a, b) => a.label.localeCompare(b.label))}
                                        value={selectedReplacementColumnMap[c] 
                                            ? {label: selectedReplacementColumnMap[c].name, value: selectedReplacementColumnMap[c]}
                                            : null
                                        }
                                        onChange={(o: SelectOption<Column>) => {
                                            const tmpReplacementColumnMap = structuredClone(selectedReplacementColumnMap)
                                            tmpReplacementColumnMap[c] = o.value
                                            setSelectedReplacementColumnMap(tmpReplacementColumnMap)
                                        }}
                                        placeholder={"Select column..."}
                                        />
                                </ShowIf>
                                <ShowIf if={columns.length === 0 && (isColInFilter || isColInViewSettings)}>
                                    <div className="font-italic text-secondary p-2">No columns to replace with</div>
                                </ShowIf>
                            </td>
                            <td className="align-middle text-right">
                                <button 
                                    className="btn-outline-secondary btn px-1 py-0" 
                                    title={"Replace missing column with selected replacement column."} 
                                    disabled={!selectedReplacementColumnMap[c]}
                                    onClick={() => handleReplaceColumn(c, selectedReplacementColumnMap[c])}
                                >
                                    Replace
                                </button>
                            </td>
                            <td className="align-middle text-right">
                                <button 
                                    className="btn-danger btn px-1 py-0" 
                                    title={isColInFilter ? "Column is used in slicer, has to be replaced." : ""} 
                                    disabled={isColInFilter}
                                    onClick={() => handleRemoveColumn(c)}
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>}
            {(replacedMissingColumns.length > 0 || removedMissingColumns.length > 0) && <div className="d-flex justify-content-between border-top pt-2">
                <div>
                    {replacedMissingColumns.length > 0 && <> 
                        <div className="font-weight-bold">Replaced columns</div>
                        {replacedMissingColumns.map((c, i) => (
                            <div key={i} className="font-italic text-secondary">{c} replaced with <b>{selectedReplacementColumnMap[c]?.name}</b></div>
                        ))}
                    </>}
                    {removedMissingColumns.length > 0 && <> 
                        <div className="font-weight-bold">Removed columns</div>
                        {removedMissingColumns.map((c, i) => (
                            <div key={i} className="font-italic text-secondary">{c}</div>
                        ))}
                    </>}
                </div>
                <div>
                    <button 
                        className="btn btn-outline-secondary px-3 py-0 mr-2" 
                        disabled={removedMissingColumns.length === 0 && replacedMissingColumns.length === 0}
                        onClick={handleReset}
                        >
                        Undo
                    </button>
                </div>
            </div>}
        </GenericDialog>
    )
}

export default FixReportDialog