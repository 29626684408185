import React from 'react'
import { connect } from 'react-redux'
import * as ActionTypes from '../actions/ActionTypes'
import { packAction } from '../actions/ActionTypes'
import { getUsers } from '../actions/SystemActions'
import { SingleKeyMap } from '../helpers/Collections'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SaveDeleteDialog from '../components/Dialogs/SaveDeleteDialog'
import DatePicker from 'react-datepicker'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { notifyFailure } from '../helpers/NotificationManager'

/*CSS*/
import '../css/margin.css'
import 'react-datepicker/dist/react-datepicker.css'
import ShowIf from '../components/Generic/ShowIf'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.info,
        userInfo: state.User.info,
        users: state.System.users,
        checklists: state.Checklist.checklists,
        items: state.Checklist.items
    }
}

class SplitChecklist extends React.Component {
    constructor(props) {
        super()
        this.state = {
            checklistID: -1,
            splittedChecklists: new SingleKeyMap(),
            column: "",
            splitByStatus: false
        }
    }
    
    checklist() {
        return this.props.checklists.get(this.state.checklistID)
    }

    componentDidMount() {
        setPageTitle()

        let checklistID = this.props.match.params.checklistID
        
        this.setState({
            checklistID: checklistID,
        })
        
        this.props.dispatch(packAction(ActionTypes.GET_CHECKLIST_ITEMS, checklistID))
        this.props.dispatch(packAction(ActionTypes.GET_CHECKLIST, checklistID))
        if(!this.props.users)
            this.props.dispatch(getUsers())   
    }
    
    evaluateUniqueValues(column) {
        let temp = new SingleKeyMap()
        this.props.items.forEach((cid,id,item) => {
            if(cid !== this.state.checklistID) return
            let data = JSON.parse(item.data)
            let d = data[column]
            if(temp.contains(d)) {
                let i = temp.get(d)
                let itemArr = i.items
                itemArr.push(item)
                temp.set(d,{items: itemArr, assignee: i.assignee, name: this.checklist().name + " + " + d})
            } else {
                let itemArr = []
                itemArr.push(item)
                temp.set(d,{items: itemArr, assignee: this.props.userInfo.user_id, name: this.checklist().name + " + " + d})
            }
        })
        this.setState({splittedChecklists: temp, column})
    }

    
    getUserOptionJSX() {
        if(this.props.users === undefined) return null
        return this.props.users.filter(u => !u.deleted).filter(u => !u.is_consultant).map((u,i) => <option key={i} value={u.id}>{u.firstname+" "+u.lastname}</option>)
    }

    updateAssignee(event, value) {
        let temp = this.state.splittedChecklists
        let newAssignee = event.target.value
        let data = temp.get(value)
        temp.set(value,{items: data.items, assignee: newAssignee})
        this.setState({splittedChecklists: temp})
    }

    updateName(event, value) {
        let temp = this.state.splittedChecklists
        let newName = event.target.value
        let data = temp.get(value)
        temp.set(value, {items: data.items, assignee: data.assignee, name: newName})
        this.setState({splittedChecklist: temp})
    }

    updateMessage(e) {
        this.setState({message: e.target.value})
    }

    cancel() {
        this.setState({return: true})
    }

    getIdColumn() {
        return this.getColumns().find(c => c.ColumnType === 2)
    }

    save() {
        let details = this.state.splittedChecklists.map((value,splitData) => {
            const idcolumn = this.getIdColumn()
            const ids = splitData.items.map(item => item.id)
            const keys = splitData.items.map(item => item.key)
            return {
                split_by_status: this.state.splitByStatus,
                name: !!splitData.name ? splitData.name : this.checklist().name + " + " + value, 
                ids: ids, 
                new_filter: this.state.splitByStatus ? {column: idcolumn.Name, target_values: keys, compare_type: 1} : {column: this.getColumns()[this.state.column].Name, target_values: [value], compare_type: 1},
                assignee: parseInt(splitData.assignee, 10), 
                has_deadline: this.state.deadline ? true : this.props.checklists && this.checklist().has_deadline && this.state.deadline !== null ? true : false, 
                deadline: this.state.deadline ? moment(this.state.deadline).format('YYYY-MM-DD') : this.props.checklists && this.checklist().has_deadline && this.state.deadline !== null ? this.checklist().deadline : ''
            }
        })

        if(details.length > 100){
            notifyFailure("Split is too big. Maximum 100 new checklists are allowed.", null)
            return
        }

        let payload = {details: details, message: this.state.message ? this.state.message : '', id: this.checklist().id}
        this.props.dispatch(packAction(ActionTypes.SPLIT_CHECKLIST, payload))
        window.location.hash = "/status/checklists"
    }

    openDeadlineDialog() {
        this.refs.deadlineDialog.show()
        this.setState({tempDeadline: this.state.deadline})
    }

    closeDeadlineDialog() {
        this.setState({tempDeadline: null})
    }
    
    saveDeadline() {
        this.refs.deadlineDialog.hide()
        this.setState({deadline: this.state.tempDeadline})
    }
    
    removeDeadline() {
        this.setState({tempDeadline: null})
        this.setState({deadline: null})
    }

    updateDeadline(momentObject) {
        this.setState({tempDeadline: momentObject})
    }

    getColumns() {
        return JSON.parse(this.props.checklists.get(this.state.checklistID).data_columns)
    }

    splitbyStatus() {
        let temp = new SingleKeyMap()
        const layoutJson = this.checklist()?.layout
        if(!layoutJson) return
        const layout = JSON.parse(layoutJson)
        this.props.items.forEach((cid,id,item) => {
            if(cid !== this.state.checklistID) return
            const layoutCategoryName = layout[item.done].name
            if(temp.contains(layoutCategoryName)) {
                let i = temp.get(layoutCategoryName)
                let itemArr = i.items
                itemArr.push(item)
                temp.set(layoutCategoryName,{items: itemArr, assignee: i.assignee, name: this.checklist().name + " + " + layoutCategoryName})
            } else {
                let itemArr = []
                itemArr.push(item)
                temp.set(layoutCategoryName,{items: itemArr, assignee: this.props.userInfo.user_id, name: this.checklist().name + " + " + layoutCategoryName})
            }
        })
        this.setState({splittedChecklists: temp})
    }

    changeSplitCriterion(splitByStatus) {
        if(splitByStatus) {
            this.splitbyStatus()
        } else if(!splitByStatus && this.state.column) {
            this.evaluateUniqueValues(this.state.column)
        } else {
            this.setState({splittedChecklists: new SingleKeyMap()})
        }
        this.setState({splitByStatus: splitByStatus})
    }

    render() {
        if(!this.props.checklists || !this.props.users || !this.props.items) return null
        let checklist = this.checklist()
        if(!checklist) return null
        let deadline = this.state.deadline ? moment(this.state.deadline) : this.props.checklists && this.checklist().has_deadline && this.state.deadline !== null ? moment(this.checklist().deadline) : null
        const categories = JSON.parse(checklist.layout)
        return (
            <div className="container-fluid">
                <h1>Split Action list - {checklist.name}</h1>
                <div className="form-group">
                    <b>Split criterion:</b>
                    <div className="margin-left-20px">
                        <input type="radio" checked={!this.state.splitByStatus} onChange={() => this.changeSplitCriterion(false)} /> &nbsp;
                        <label>Split by column:&nbsp;</label> 
                        <select className="form-control form-control-sm inline-block auto-width" defaultValue={0} onChange={e => this.evaluateUniqueValues(e.target.value)} disabled={this.state.splitByStatus}>
                            <option value={0} disabled>Select column</option>
                            {
                                this.getColumns().map((col,i) => col.ColumnType === 1 || col.ColumnType === 16 || col.ColumnType === 64 ? <option key={i} value={i}>{col.Name}</option> : null)
                            }
                        </select>
                    </div>
                    <div className="margin-left-20px">
                        <input type="radio" checked={this.state.splitByStatus} onChange={() => this.changeSplitCriterion(true)} /> &nbsp;
                        <label>Split by category&nbsp;</label> 
                    </div>
                </div>
                <div className='border-bottom mb-3'>Results: ({this.state.splittedChecklists.values().length} {this.state.splittedChecklists.values().length === 1 ? "Action" : "Actions"})</div>
                <div className="col-md-12 margin-bottom-20px">
                    {this.state.splittedChecklists.values().length > 0 && <table className="table table-striped table-responsive">
                        <thead>
                            <tr>
                                <ShowIf if={this.state.splitByStatus}>
                                    <th className='px-4'>Category</th>
                                </ShowIf>
                                <ShowIf if={!this.state.splitByStatus}>
                                    <th className='px-4'>{this.getColumns()[this.state.column]?.Name}</th>
                                </ShowIf>
                                <th className='px-4'>Action Name</th>
                                <th className='px-4'># of Items</th>
                                <th className='px-4'>Owner</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.splittedChecklists.map((value, splitData, i) => {
                                return (
                                    <tr key={i} style={{whiteSpace: "nowrap"}}>
                                        <ShowIf if={this.state.splitByStatus}>
                                            <td className="align-middle px-4" style={{width: "fit-content"}}>
                                                <div className={`${categories.find(c => c.name === value)?.color} rounded p-1`} style={{width: "fit-content"}}>{value}</div>
                                            </td>
                                        </ShowIf>
                                        <ShowIf if={!this.state.splitByStatus}>
                                            <td className="align-middle px-4">
                                                {!!value ? value : <span className="font-italic text-secondary">BLANK</span>}
                                            </td>
                                        </ShowIf>
                                        <td className="align-middle px-4">
                                            <input className='form-control' type="text" value={splitData.name} onChange={(e) => this.updateName(e, value)}/>
                                            {/* This div is hidden, and needed to force input width to be same as value text*/}
                                            <div style={{height: 0, pointerEvents: "none", color: "transparent"}} className='px-3'>{splitData.name}</div>
                                        </td>
                                        <td className="align-middle px-4">
                                            {splitData.items.length + " items"}
                                        </td>
                                        <td className="align-middle px-4">
                                            <select className="form-control form-control-sm" value={splitData.assignee} onChange={event => this.updateAssignee(event, value)} style={{width: 300}}>
                                                {this.getUserOptionJSX()}
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>}
                </div>

                <div className="col-md-5">
                    { deadline ? <span>Deadline: {deadline.format("DD MMM YYYY")}</span> : <span>No deadline set</span> } <button className="btn btn-default btn-xs" onClick={() => this.openDeadlineDialog()}>Edit deadline</button>
                    <textarea className="form-control resize-vertical margin-top-20px" value={this.state.message} onChange={e => this.updateMessage(e)} placeholder="Message (optional)"/>
                    <div className="margin-top-20px" >
                        <Link to={`/checklist/${this.state.checklistID}`}><button type="button" className="btn btn-default margin-right-10px">Cancel</button></Link>
                        <button onClick={() => this.save()} type="button" className="btn btn-primary" disabled={this.state.splittedChecklists.isEmpty()}>Split list</button>
                    </div>
                </div>
                <SaveDeleteDialog ref="deadlineDialog" title="Edit deadline" saveHandler={() => this.saveDeadline()} deleteHandler={() => this.removeDeadline()} deleteText="Remove deadline">
                    <div style={{height: 280}}>
                        <DatePicker className="form-control" selected={this.state.tempDeadline ? this.state.tempDeadline : deadline ? deadline : moment()} dateFormat="DD-MM-YYYY" onChange={(m)=> this.updateDeadline(m)} />
                    </div>
                </SaveDeleteDialog>
            </div>
        )
    }
}

export default connect(mapStateToProps)(SplitChecklist)