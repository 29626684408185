import { forwardRef, useEffect, useState, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardOverview, getHomeDashboard, setHomeDashboard } from "../../actions/DashboardActions"
import BaseDialog from "./BaseDialog"
import ReactDOM from "react-dom"

const HomeDashboardDialog = forwardRef(({ onSave }, ref) => {
    const dispatch = useDispatch()
    const selectRef = useRef(null)
    const [filter, setFilter] = useState("")
    const [selectedDashboard, setSelectedDashboard] = useState("")

    const dashboards = useSelector(state => state.Dashboard.overview)
    const homeDashboard = useSelector(state => state.Dashboard.homeDashboard)
    // const defaultDashboard = useSelector(state => state.Dashboard.defaultDashboard)

    // getting all data needed for the dialog
    useEffect(() => {
        dispatch(getDashboardOverview())
        dispatch(getHomeDashboard())
        // dispatch(getDefaultDashboard())
    }, [])

    useEffect(() => {
        if (dashboards && homeDashboard && homeDashboard !== 0) {
            const d = dashboards.find(d => d.id === homeDashboard)
            setSelectedDashboard(d?.title ?? "")
        }
    }, [dashboards, homeDashboard])


    useEffect(() => {
        // for making the gray line after selecting disappear
        if (ReactDOM.findDOMNode(selectRef.current)) {
            ReactDOM.findDOMNode(selectRef.current).selectedIndex = -1
        }
    }, [selectedDashboard])

    const onCloseHandler = () => {
        return (e) => {
            ref.current?.hide()
            if (homeDashboard !== 0) {
                setSelectedDashboard(dashboards.find(d => d.id === homeDashboard)?.title ?? "")
            } else {
                setSelectedDashboard("")
            }
            setFilter("")
        }
    }

    const onSaveHandler = () => {
        if (selectedDashboard === "") {
            dispatch(setHomeDashboard(0))
        } else if (selectedDashboard !== "") {
            const d = dashboards.find(d => d.title === selectedDashboard)
            dispatch(setHomeDashboard(d.id))
        }
        onSave()
        setFilter("")
        ref.current?.hide()
    }

    const searchFilter = (value) => {
        if (filter !== '') {
            return value.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        } else {
            return true
        }
    }
    const _dashboards = useMemo(() => dashboards, [dashboards, homeDashboard, searchFilter, filter, selectedDashboard])



    return <Dialog ref={ref} title={"Select dashboard"} save={_ => onSaveHandler()} closeHandler={onCloseHandler()} disableBackdrop={false}>
        <div className="form">
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text"><i className="fa fa-search"></i></div>
                </div>

                <input id="textSlicerModalFocus" autoFocus className="form-control margin-bottom-5px" placeholder="Search" value={filter} onChange={(e) => setFilter(e.target.value)} />
            </div>
            <div className="dashboard-selector" role="group">
                {
                    _dashboards.filter(searchFilter).map((item, index) => {
                        return (
                           <div  key={item.id} className={item.title == selectedDashboard ? "width-100-p selected" : "width-100-p"} >
                                <button className="btn" onClick={(e) => {item.title == selectedDashboard ? setSelectedDashboard("") : setSelectedDashboard(item.title);}} value={item.title}>{item.title}</button>
                                {item.title == selectedDashboard ? <div className="float-right hint">Selected dashboard</div> : null }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </Dialog>
})
class Dialog extends BaseDialog {
    getButtons() {
        return (
            <div>
                <button onClick={this.props.closeHandler} type="button" className="btn btn-default margin-right-10px">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => this.props.save()}>Save</button>
            </div>
        )
    }
}

export default HomeDashboardDialog