import React from 'react'

class KPIBannerBase extends React.Component {
    extraClass() {
        return ''
    }

    content() {
        return this.props.children
    }

    render() {
        return (
            <div className={`abc-report-header-holder kpi-style ${this.extraClass()}`} >
                {this.content()}
            </div>
        )
    }
}

export default KPIBannerBase