import { applyMiddleware, compose } from 'redux'
import ChecklistApiMiddleware from './ApiMiddleware/ChecklistApiMiddleware'
import InfoApiMiddleware from './ApiMiddleware/InfoApiMiddleware'
import BucketApiMiddleware from './ApiMiddleware/BucketApiMiddleware'
import SystemApiMiddleware from './ApiMiddleware/SystemApiMiddleware'
import StatusApiMiddleware from './ApiMiddleware/StatusApiMiddleware'
import ReportApiMiddleware from './ApiMiddleware/ReportApiMiddleware'
import UserApiMiddleware from './ApiMiddleware/UserApiMiddleware'
import ItemApiMiddleware from './ApiMiddleware/ItemApiMiddleware'
import DashboardApiMiddleware from './ApiMiddleware/DashboardApiMiddleware'
import NotificationApiMiddleware from './ApiMiddleware/NotificationApiMiddleware'
import DownloadApiMiddleware from './ApiMiddleware/DownloadApiMiddleware'
import OrganizationApiMiddleware from './ApiMiddleware/OrganizationApiMiddleware'
import ReportingApiMiddleware from './ApiMiddleware/ReportingApiMiddleware'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let middleware = applyMiddleware(
    ChecklistApiMiddleware,
    InfoApiMiddleware,
    BucketApiMiddleware,
    SystemApiMiddleware,
    StatusApiMiddleware,
    ReportApiMiddleware,
    UserApiMiddleware,
    ItemApiMiddleware,
    DashboardApiMiddleware,
    NotificationApiMiddleware,
    DownloadApiMiddleware,
    OrganizationApiMiddleware,
    ReportingApiMiddleware
)

let Middleware = composeEnhancers(middleware)

export default Middleware