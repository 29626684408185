import * as ActionTypes from '../actions/ActionTypes'

const Notification = (state = { notifications: [], unreadNotifications: [] }, action) => {
    let notifications
    switch (action.type) {
        case ActionTypes.GET_NOTIFICATIONS_DONE:
            notifications = state.notifications.slice()
            notifications.splice(action.payload.offset, action.payload.limit, ...action.payload.notifications)
            return Object.assign({}, state, {
                notifications: notifications
            })
        case ActionTypes.GET_UNREAD_NOTIFICATIONS_DONE:
            if(action.payload.copyNewNotifications) {
                let newNotifications = action.payload.notifications.filter(n => state.unreadNotifications.findIndex(m => m.id === n.id) === -1)
                return Object.assign({}, state, {
                    unreadNotifications: action.payload.notifications,
                    notifications: newNotifications.concat(state.notifications)
                })
            } else {
                return Object.assign({}, state, {
                    unreadNotifications: action.payload.notifications,
                })
            }
        case ActionTypes.READ_NOTIFICATION_DONE:
            return Object.assign({}, state, {
                unreadNotifications: state.unreadNotifications.filter(n => n.id !== action.payload),
                notifications: state.notifications.map(n => {
                    if(n.id === action.payload) {
                        n.read = true
                    }
                    return n
                })
            })
        case ActionTypes.UNREAD_NOTIFICATION_DONE:
            let unreadNotifications = state.unreadNotifications.slice()
            notifications = state.notifications.slice()
            let notification = state.notifications.find(n => n.id === action.payload)
            let notificationIndex = state.notifications.findIndex(n => n.id === action.payload)
            notification.read = false
            unreadNotifications.push(notification)
            notifications[notificationIndex] = notification
            return Object.assign({}, state, {
                unreadNotifications: unreadNotifications,
                notifications: notifications
            })
        case ActionTypes.READ_ALL_NOTIFICATIONS_DONE:
            return Object.assign({}, state, {
                unreadNotifications: [],
                notifications: state.notifications.map(n => {
                    n.read = true
                    return n
                })
            })
        default:
            return state
    }
}

export default Notification