/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import MatrixItem from './MatrixItem'
import { getCategoryValue, getIdColumn, getHighestPos, getFilter, isNumberColumn, shouldReportInfoChangeUpdateData, getMaxX } from '../../../helpers/ReportHelpers'
import { getAggregationData } from '../../../actions/ReportActions'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import { isTextColumn } from '../../../helpers/ReportHelpers'
import { hashNValues } from '../../../helpers/GeneralHelpers'
import ShowIf from '../../Generic/ShowIf'
import SaveDeleteDialog from '../../../components/Dialogs/SaveDeleteDialog'
import ActiveCategoryIcon from './ActiveCategoryIcon'

import '../../../css/dimensions.css'
import '../../../css/misc.css'
import '../../../css/padding.css'
import '../../../css/margin.css'
import '../../../css/report.css'


const mapStateToProps = (state, ownProps) => {
	return {
        aggregationdata: state.Report.aggregationdata,
        userInfo: state.User.info,
	}
}

class Overview extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            useDecimals: false,
            usePercentage: false,
            showInformation: false,
            selectedAggregation: 'count',
            selectedColumn: null,
            initialLoad: true,
            descriptions: [],
            settingsLoaded: false,
            categorizationName: null,            
        }
    }

    componentDidMount() {
        const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
        if (!this.props.forPrint && viewSettings.actual && viewSettings.actual.overview) {

            this.setState({
                useDecimals: viewSettings.actual.overview.useDecimals,
                usePercentage: viewSettings.actual.overview.usePercentage,
                showInformation: false,
                selectedAggregation: viewSettings.actual.overview.selectedAggregation,
                selectedColumn: viewSettings.actual.overview.selectedColumn,
                descriptions: viewSettings.actual.overview.descriptions,
                settingsLoaded: true,
            })
            
        }

        if(this.props.forPrint && this.props.printViewSettings){ // use the view settings fetched by secret component when used for generating pdf
            const printViewSettings = this.props.printViewSettings
            this.setState({
                selectedAggregation: printViewSettings.selectedAggregation,
                selectedColumn: printViewSettings.selectedColumn,
                descriptions: printViewSettings.descriptions,
                settingsLoaded: true,
                useDecimals: !!printViewSettings.useDecimals,
                usePercentage: !!printViewSettings.usePercentage
            })
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if(!this.state.settingsLoaded){
            const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
   
            if (viewSettings.actual && viewSettings.actual.overview) {

                this.setState({
                    useDecimals: viewSettings.actual.overview.useDecimals,
                    usePercentage: viewSettings.actual.overview.usePercentage,
                    showInformation: false,
                    selectedAggregation: viewSettings.actual.overview.selectedAggregation,
                    selectedColumn: viewSettings.actual.overview.selectedColumn,
                    descriptions: viewSettings.actual.overview.descriptions
                })
            }
            this.setState({settingsLoaded: true})
        }
        else if ((((prevState.selectedColumn !== this.state.selectedColumn || 
            prevState.categorizationName !== this.state.categorizationName ||
            JSON.stringify(prevProps.slicers) !== JSON.stringify(this.props.slicers) || 
            shouldReportInfoChangeUpdateData(prevProps.reportdata, this.props.reportdata) ||
            prevProps.limit !== this.props.limit)) && this.props.show) || (this.props.show && !prevProps.show) || this.state.initialLoad) {
            this.setState({initialLoad: false})
            
            let columns = JSON.parse(this.props.reportdata.report.columns)
            // if the user changes bucket in compose, we need to reset selectedColumn
            if (prevProps.reportdata.info.id !== this.props.reportdata.info.id) {
                this.setState({ selectedColumn: null, selectedAggregation: "count" }, () => {
                    this.getSelectedColumnAggregation()
                })                
                // if the selectedColumn is not in the report columns we need to reset selectedColumn
            } else if (columns.includes(this.state.selectedColumn) === false ) {
                if(columns.length > 0) {
                    let idColumn = getIdColumn(this.props.reportdata.info.model.columns)
                    if(idColumn) {
                        this.setColumn(idColumn)
                    } else {
                        this.setColumn(columns[0])
                    }
                } else {
                    this.setState({ selectedColumn: null, selectedAggregation: "count" }, () => {
                        this.getSelectedColumnAggregation()
                    })
                }
            } else {
                this.getSelectedColumnAggregation()
            }
            this.getIdColumnAggregation()
        }
    }

    getKPIKey() {
        let reportdata = this.props.reportdata
        let limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        let filter = getFilter(reportdata.report, this.props.slicers)
        return hashNValues(limit, filter)
    }

    setColumn(c) {
        let model = this.props.reportdata.info.model
        let idColumn = getIdColumn(model.columns)
        let isTextCol = isTextColumn(c, model)
        let agg = "sum"
        if(isTextCol || c === idColumn) {
            agg = "count"
        }
        this.setState({selectedColumn: c, selectedAggregation: agg}, () => {
            if (this.props.editMode) {
               this.saveSettings(c, agg, this.state.useDecimals, this.state.usePercentage, this.state.descriptions)
            }
        })
    }

    getViewSettings(column, aggregation, decimals, percentage, desc) {
        return {
            selectedColumn: column,
            selectedAggregation: aggregation,
            useDecimals: decimals,
            usePercentage: percentage,
            descriptions: desc
        }
    }
    
    getSelectedColumnAggregation() {
        const { dispatch } = this.props        
        const column = this.state.selectedColumn !== null ? this.state.selectedColumn : getIdColumn(this.props.reportdata.info.model.columns)
        const categorization = this.state.categorizationName ? this.state.categorizationName : this.props.reportdata.info.model.categorization_name
        const limit = this.props.limit !== -1 ? this.props.limit : this.props.reportdata.report.limit
        const data = { 
            aggregate_column: column, 
            group_column : categorization,
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column === '-' ? null : this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction === '-' ? null : this.props.reportdata.report.sort_direction,
            limit: limit,
            kpis: this.props.reportdata.report.kpis,
        }
        if(this.props.enabled && !this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            dispatch(getAggregationData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, data, "selectedColumn", this.getKPIKey()))
        }
    }

    getIdColumnAggregation() {
        const column = getIdColumn(this.props.reportdata.info.model.columns)
        const limit = this.props.limit !== -1 ? this.props.limit : this.props.reportdata.report.limit
        const categorization = this.state.categorizationName ? this.state.categorizationName : this.props.reportdata.info.model.categorization_name
        const data = { 
            aggregate_column: column, 
            group_column : categorization,
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column === '-' ? null : this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction === '-' ? null : this.props.reportdata.report.sort_direction,
            limit: limit,
            kpis: this.props.reportdata.report.kpis,
        }
        if(this.props.enabled && !this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            this.props.dispatch(getAggregationData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, data, "idColumn", this.getKPIKey()))
        }
    }

    addClickSlicer = (column, slicer, limit, clickable) => {
        if(clickable) this.props.addClickSlicer(column, slicer, limit)
    }

    editDescription = (category, informations) => {
        let originalText = informations.map(e => e).join('\n')
        this.refs.descriptionEditDialog.show(originalText, category)
    }


    saveSettings(column, aggregation, decimals, percentage, desc){
        if (this.props.editMode) {
            let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            
            viewSettings.actual.overview = this.getViewSettings(column, aggregation, decimals, percentage, desc)
            this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
        }
    }

    saveDescription = (newDescription, oldDescription, category) => {

        if (newDescription.trim() === oldDescription.trim()){
            return; //no change
        }

        if (this.props.editMode) {

            let position = category.position;
            let descriptionsSlice = this.state.descriptions ? this.state.descriptions.slice(): [];

            let found = false
            for( let i =0; i < descriptionsSlice.length; i++){
                let description = descriptionsSlice[i]

                if(description.position && description.position.x === position.x && description.position.y === position.y){
                    
                    if(newDescription.trim() !== ""){
                        descriptionsSlice[i] = {position: position, text : newDescription.trim().split("\n")}
                    }
                    else {
                        //remove from slice
                        descriptionsSlice = descriptionsSlice.filter((_, idx) => idx !== i)
                    }
                    found = true;
                    break;
                }
            }

            if(!found && newDescription.trim() !== ""){
                descriptionsSlice.push( {position: position, text : newDescription.trim().split("\n")})
                
            }
            this.setState({descriptions: descriptionsSlice})

            this.saveSettings(this.state.selectedColumn, this.state.selectedAggregation, this.state.useDecimals, this.state.usePercentage, descriptionsSlice)
        }
    }
  

    render() {
        if(!this.props.show) return null

        let id = this.props.reportdata.report ? this.props.reportdata.report.report_id : -1
        let aggrData = this.props.aggregationdata.get2(this.getKPIKey(), id)
        if(this.props.forPrint){ // use the data fetched by secret component when used for generating pdf
            aggrData = this.props.secretAggrData.get2(this.getKPIKey(), id)
        }

        let modelColumns = this.props.reportdata.info ? this.props.reportdata.info.model.columns : []
        
        let columns = this.props.reportdata.report ? JSON.parse(this.props.reportdata.report.columns) : []

        columns.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        });
        
        let getDescription = (x,y, defaultIfNotFound) => {
            
            if (this.state.descriptions) {
                let descriptionsSlice = this.state.descriptions.slice();

                for( let i =0; i < descriptionsSlice.length; i++){
                    let description = descriptionsSlice[i]

                    if(description.position && description.position.x === x && description.position.y === y){
                        return description.text
                    }
                }
            }
            return defaultIfNotFound
        }

        let categorizationColumns = this.props.reportdata.info && this.props.reportdata.info.model.categorizations ? this.props.reportdata.info.model.categorizations : []
        let selectedCategorization = this.state.categorizationName ? this.state.categorizationName : this.props.reportdata.info.model.categorization_name

        let categorizations = this.props.reportdata.info ? this.props.reportdata.info.model.categorization : []
        for(const categorization of categorizationColumns){
            if(categorization.name === selectedCategorization){
                categorizations = categorization.categorization
            }
            
            if(this.props.reportdata.info && selectedCategorization === this.props.reportdata.info.model.categorization_name){
                categorizations = this.props.reportdata.info.model.categorization //henter farver fra vilfredo...
            }
        }

        let ys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
        let xs = [0, 1, 2]
        let getCategory = (x, y) => {
            if (categorizations !== undefined) {
                for (let i = 0; i < categorizations.length; i++) {
                    const position = categorizations[i].position
                    if (position.x === x && position.y === y) {
                        let result = categorizations[i]
                        result.informations = getDescription(x,y,result.criteria)
                        return result
                    }
                }
            }
        }

        let getBorderColor = (category) => {
            if (undefined !== category) {
                return { backgroundColor: 'rgb(' + category.color.r + ',' + category.color.g + ',' + category.color.b + ')' }
            }
            return {}
        }

        let isDefaultCategorization = selectedCategorization === this.props.reportdata.info.model.categorization_name
        let clickableCategories =  isDefaultCategorization ? this.props.clickableCategories : categorizations.map(c => {return c.name})

        const maxX = getMaxX(categorizations)
        const matrixWidth = maxX >= 2 ? 8 : maxX === 1 ? 6 : 3
        const matrixOffset = (12 - matrixWidth)/2

        return (
            <div>
                <Controls
                    setDecimals={() => 
                        {
                            let decimals = !this.state.useDecimals
                            this.setState({ useDecimals: decimals }); 
                            this.saveSettings(this.state.selectedColumn, this.state.selectedAggregation, decimals , this.state.usePercentage, this.state.descriptions)
                        }
                    }
                    setPercentage={() => 
                        {
                            let percentage = !this.state.usePercentage
                            this.setState({ usePercentage: percentage });
                            this.saveSettings(this.state.selectedColumn, this.state.selectedAggregation, this.state.useDecimals, percentage, this.state.descriptions)
                        }
                    }
                    useDecimals={this.state.useDecimals}
                    usePercentage={this.state.usePercentage}
                    columns={columns}
                    model={modelColumns}
                    setAggregation={(aggregation) =>  
                        { 
                            this.setState({ selectedAggregation: aggregation });
                            this.saveSettings(this.state.selectedColumn, aggregation, this.state.useDecimals, this.state.usePercentage, this.state.descriptions)
                        }
                    }
                    selectedAggregation={this.state.selectedAggregation}
                    setColumn={(c) => this.setColumn(c)}
                    selectedColumn={this.state.selectedColumn}
                    reportdata={this.props.reportdata}
                    setInformation={() => this.setState(prevState => ({showInformation : !prevState.showInformation }))}
                    showInformation={this.state.showInformation}
                    categorizations={categorizationColumns}
                    selectedCategorization={selectedCategorization}
                    setCategorization={(categorization) => {
                        this.setState({ categorizationName: categorization });
                    }}
                    defaultCategorization={this.props.reportdata.info.model.categorization_name}
                    userInfo={this.props.userInfo}
                />
                <div className={`col-md-${matrixOffset} col-sm-${matrixOffset} col-xs-0`}/>
                <div className={`col-md-${matrixWidth} abc-overview-holder`}>
                    {
                        ys.map((y) => {
                            let bool = getHighestPos(categorizations, y)
                            if (bool) {
                                return (
                                    <div key={y} className="row">
                                        {
                                            xs.map((x) => {
                                                let cat = getCategory(x, y)
                                                if (cat) {
                                                    let border = getBorderColor(cat)
                                                    let val = getCategoryValue(cat.name, this.state.useDecimals, this.state.usePercentage, this.state.selectedAggregation, aggrData.get("selectedColumn", null))
                                                    let _limit = getCategoryValue(cat.name, false, false, "count", aggrData.get("idColumn", null))
                                                    let limit = _limit && _limit !== "-" && _limit !== "..." ? _limit : 0
                                                    let clickable = clickableCategories.includes(cat.name)
                                                    return (
                                                        <MatrixItem id={x + '_' + y} key={x + '_' + y} maxX={maxX} label={cat.name} border={border} number={val} clickable={clickable} addSlicer={(slicer) => this.addClickSlicer(selectedCategorization, slicer, limit,clickable)} editDescription={(category, informations) => this.editDescription(category, informations)} showInformation={this.state.showInformation} category={cat} editMode={this.props.editMode} editable={isDefaultCategorization}/>
                                                    )
                                                }
                                                else if(x < maxX) {
                                                    return <MatrixItem id={x + '_' + y} key={x + '_' + y} maxX={maxX} label={""} clickable={false} /> 
                                                } else {
                                                     return  <span key={x + '_' + y}/>
                                                }
                                            })
                                        }
                                        <MatrixItem id="Total" label={"Total"} border={{ backgroundColor: 'black' }}
                                            maxX={maxX}
                                            number={getCategoryValue('', this.state.useDecimals, this.state.usePercentage, this.state.selectedAggregation, aggrData.get("selectedColumn", null))}
                                            addSlicer={() => this.props.showTotal()}
                                            clickable={true}
                                        />
                                    </div>
                                )
                            }

                            return (
                                <div key={y} className="row">
                                    {
                                        xs.map((x) => {
                                            let cat = getCategory(x, y)
                                            if (cat) {
                                                let border = getBorderColor(cat)
                                                let val = getCategoryValue(cat.name, this.state.useDecimals, this.state.usePercentage, this.state.selectedAggregation, aggrData.get("selectedColumn", null))
                                                let _limit = getCategoryValue(cat.name, false, false, "count", aggrData.get("idColumn", null))
                                                let limit = _limit && _limit !== "-" && _limit !== "..." ? _limit : 0
                                                let clickable = clickableCategories.includes(cat.name)
                                                return (
                                                    <MatrixItem id={x + '_' + y} key={x + '_' + y} maxX={maxX} label={cat.name} border={border} number={val} clickable={clickable} addSlicer={(slicer) => this.addClickSlicer(selectedCategorization, slicer,limit,clickable)} editDescription={(category, informations) => this.editDescription(category, informations)} showInformation={this.state.showInformation} category={cat} editMode={this.props.editMode} editable={isDefaultCategorization}/>
                                                )
                                            } else if(x < maxX) {
                                                return <MatrixItem id={x + '_' + y} key={x + '_' + y} maxX={maxX} label={""} clickable={false} /> 
                                            } else {
                                                 return  <span key={x + '_' + y}/>
                                            }
                                        })
                                    }
                                </div>
                            )
                        })
                    }

                </div>
                <div className={`col-md-${matrixOffset} col-sm-${matrixOffset} col-xs-0`}/>
            
                <DescriptionEditDialog ref="descriptionEditDialog" title="Change description" saveHandler={(text, oldText, category) => this.saveDescription(text, oldText, category)} />
            </div>
        )
    }
}

class DescriptionEditDialog extends React.Component {
    state = {
        textValue: '',
        category: null,
        originalValue: ''
    }

    show(defaultValue, edittedCategory) {
        this.setState({
            textValue: defaultValue,
            originalValue: defaultValue,
            category: edittedCategory
        })
        this.refs.changeDescDialog.show()
    }

    hide() {
        this.refs.changeDescDialog.hide()
    }

    saveHandler() {
        this.props.saveHandler(this.state.textValue, this.state.originalValue, this.state.category)
    }
    
    resetHandler(){
        this.props.saveHandler("", this.state.originalValue, this.state.category)
    }
 
    render() {
        return (
            <SaveDeleteDialog ref="changeDescDialog" title={this.props.title} deleteText="Reset" saveHandler={() => this.saveHandler()} deleteHandler={() => this.resetHandler()} >
                <textarea type="text"className="form-control" value={this.state.textValue} onChange={e => this.setState({textValue: e.target.value})} placeholder={this.props.placeholder}/>
            </SaveDeleteDialog>
        )
    }
}


class Controls extends React.Component {
    state = {
        searchStr: '',
        showCategorization: false,
    }

    render() {
        let getButtonColor = (bool) => {
            if (bool) {
                return 'btn-default active'
            }
            return 'btn-default'
        }

        let model = this.props.reportdata.info.model
        let idColumn = getIdColumn(model.columns)
        let selectedVal = this.props.selectedColumn !== null ? this.props.selectedColumn : idColumn
        let isNumberCol = isNumberColumn(selectedVal, model)
        
        let selectedCategorization = this.props.selectedCategorization
        let defaultCategorization = this.props.defaultCategorization

        let setAggregation = (agg) => {
            this.props.setAggregation(agg)
        }

        let setColumn = (e) =>{
            this.props.setColumn(e.target.value)
        }

        let showCat = this.state.showCategorization || selectedCategorization !== defaultCategorization

        return (
            <div className="margin-top-10px">
                <div className="form-inline">
                    <select className="form-control form-control-sm" value={selectedVal} onChange={setColumn}>
                        <option value='' style={{display: 'none'}}>Select column</option>
                        {
                            this.props.columns.map((c, index) => {
                                return (
                                    <option key={index} value={c}>{c}</option>
                                )
                            })
                        }
                    </select>
                    <select className="form-control form-control-sm margin-left-5px" value={this.props.selectedAggregation} onChange={e => setAggregation(e.target.value)} >
                        <ShowIf if={!isNumberCol || selectedVal === idColumn}>
                            <option value="count">Count</option>
                        </ShowIf>
                        <ShowIf if={isNumberCol && selectedVal !== idColumn}>
                            <option value="sum">Sum</option>
                            <option value="min">Minimum</option>
                            <option value="max">Maximum</option>
                            <option value="avg">Average</option>
                        </ShowIf>
                    </select>
                    <button className={`btn btn-sm width-46 margin-left-5px ${getButtonColor(this.props.useDecimals)}`} onClick={() => this.props.setDecimals()} >0.0</button>
                    <button className={`btn btn-sm width-46 margin-left-5px ${getButtonColor(this.props.usePercentage)}`} onClick={() => this.props.setPercentage()} >%</button>
                    <button className={`btn btn-sm width-46 margin-left-5px ${getButtonColor(this.props.showInformation)}`} onClick={() => {this.props.setInformation()}} > 
                        <span className="fa fa-info gray-icon"></span>
                    </button>

                    <ShowIf if={this.props.userInfo.is_consultant_login && this.props.categorizations && this.props.categorizations.length > 1}>
                        <button className={`btn btn-sm width-46 margin-left-50px ${getButtonColor(showCat)}`} type="button"  onClick={() => {this.props.setCategorization(defaultCategorization); this.setState({showCategorization: !this.state.showCategorization})}} >
                                <span className="fa fa-th gray-icon"></span> 
                        </button>

                        <ShowIf if={showCat} >                    
                            <button className="btn btn-sm btn-default margin-left-5px" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
                                {selectedCategorization === defaultCategorization ? <div className='d-flex flex-row align-items-center'>
                                                <div>{selectedCategorization}</div>
                                                <div className='pl-2'><ActiveCategoryIcon /></div>
                                                <div className="pl-2 fa fa-angle-down"></div>
                                            </div> : <span className=''>{selectedCategorization} <span className="fa fa-angle-down"></span></span>}
                            
                            </button>

                            <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby="dropdownMenu2" style={{paddingTop:0}}>
                                <div className="text-center px-2 sticky-modal-header" style={{paddingTop:'6px'}}>
                                    <input type="text" value={this.state.searchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => this.setState({ searchStr: e.target.value })} />
                                    <div className="dropdown-divider"></div>
                                </div>
                                {
                                    this.props.categorizations
                                        .filter(c => this.state.searchStr === '' || c.toLowerCase().includes(this.state.searchStr.toLowerCase()))
                                        .map(c => {return c.name}).sort()
                                        .map((col, index) => 
                                        <button className="hover-cursor dropdown-item" type="button" onClick={() =>this.props.setCategorization(col)} key={index}>
                                            {col === defaultCategorization ? <div className='d-flex flex-row align-items-center'>
                                                <div>{col}</div>
                                                <div className='pl-2'><ActiveCategoryIcon /></div>
                                            </div> : <span>{col}</span>}
                                        </button>)
                                }
                            </div>
                        </ShowIf>
                    </ShowIf>
                </div>
            </div>
        )
    }
}


const AreDescriptionsDifferent = (prevDesc, desc) => {
    if(prevDesc !== desc || prevDesc === undefined ){
        return true
    }

    if(prevDesc.length !== desc.length){
        return true
    }
    for(let i=0; i< prevDesc.length; i++){
        if(prevDesc[i].position.x !== desc[i].position.x ||
            prevDesc[i].position.y !== desc[i].position.y ||
            prevDesc[i].text.join('') !== desc[i].text.join('')
            ){
                return true
            }
    }

    return false
}

const shouldComponentUpdateSettings = (prevState, curState) => {
   
      let result = (prevState.useDecimals !== curState.useDecimals || 
            prevState.usePercentage !== curState.usePercentage || 
            prevState.selectedAggregation !== curState.selectedAggregation || 
            prevState.selectedColumn !== curState.selectedColumn ||
            AreDescriptionsDifferent(prevState.descriptions, curState.descriptions)
      )

    return result
}

const getSettingsKeys = (state) => {
    let {initialLoad, settingsLoaded, showInformation, ...settings} = state
    return Object.keys(settings)
}

const isSilentUpdate = (prevProps, prevState, snapshot, _this) => {
    return prevState.selectedColumn !== _this.state.selectedColumn && !prevState.selectedColumn
}

Overview = withSettingsPropagation(Overview, getSettingsKeys, shouldComponentUpdateSettings, isSilentUpdate, null, "overview")

Overview = connect(mapStateToProps)(Overview)

export default Overview