/* REACT */
import React, { Component } from 'react'

/* CSS */
import '../css/loadingicon.css'

/*
    This component can be used in different sizes: 
    - 'tiny' 
    - 'small' 
    - 'medium' 
    - 'large'
    - 'button-group'
    
    Use the props notation when using the component, i.e: size={'small'} loading={boolean}
*/

class LoadingIcon extends Component {
    render() {
        const { size, loading, color, center } = this.props

        return <div className={`loader ${size ? size : 'medium' } ${loading ? '' : 'loading-hidden'} ${color ? color : 'normal-color'} ${center ? 'margin-left-right-auto' : ''} ${this.props.className ? this.props.className : ""}`}></div>
    }
}
        

export default LoadingIcon