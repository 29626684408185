import { BucketColumnType, Model } from "./transfertypes"
import { SelectOption } from "../helpers/TypeHelpers"

export enum CompareType {
    EQ = 1,
    NEQ = 6,
    CONTAINS = 9,
    NCONTAINS = 12,
    STARTS = 7,
    ENDS = 8,
    NSTARTS = 10,
    NENDS = 11,

    GT = 4,
    GEQ = 3,
    LT = 5,
    LEQ = 2,

    BETWEEN = 13,
    NBETWEEN = 14,
}

export const getPossibleCompareTypes = (column: string, bucket: Model, isColumn = false): SelectOption<CompareType>[] => {
    const col = bucket.columns.find(x => x.name === column)
    if (col === undefined) return [] as SelectOption<CompareType>[]
    return getCompareTypes(col.type, isColumn)
}

export const getCompareTypes = (type: BucketColumnType, isColumn = false): SelectOption<CompareType>[] => {
    switch(type){
        case BucketColumnType.ID:
        case BucketColumnType.Text:
        case BucketColumnType.Categorization:
            return isColumn ? [
                {value: CompareType.EQ, label: "Equal"},
                {value: CompareType.NEQ, label: "Not equal"}
            ] : [
                    {value: CompareType.EQ, label: "Equal"},
                    {value: CompareType.NEQ, label: "Not equal"},
                    {value: CompareType.CONTAINS, label: "Contains"},
                    {value: CompareType.NCONTAINS, label: "Not contains"},
                    {value: CompareType.STARTS, label: "Starts with"},
                    {value: CompareType.ENDS, label: "Ends with"},
                    {value: CompareType.NSTARTS, label: "Not starts with"},
                    {value: CompareType.NENDS, label: "Not ends with"}
                ]
        case BucketColumnType.Decimal:
            return isColumn ? [
                {value: CompareType.GT, label: "Greater"},
                {value: CompareType.GEQ, label: "Equal or greater"},
                {value: CompareType.LT, label: "Less"},
                {value: CompareType.LEQ, label: "Equal or less"},
                {value: CompareType.EQ, label: "Equal"},
                {value: CompareType.NEQ, label: "Not equal"},
            ] : [
                    {value: CompareType.GT, label: "Greater"},
                    {value: CompareType.GEQ, label: "Equal or greater"},
                    {value: CompareType.LT, label: "Less"},
                    {value: CompareType.LEQ, label: "Equal or less"},
                    {value: CompareType.EQ, label: "Equal"},
                    {value: CompareType.NEQ, label: "Not equal"},
                    {value: CompareType.BETWEEN, label: "Between"},
                    {value: CompareType.NBETWEEN, label: "Not between"}
                ]
        case BucketColumnType.Date:
            return isColumn ? [
                {value: CompareType.EQ, label: "On"},
                {value: CompareType.NEQ, label: "Not on"},
                {value: CompareType.LT, label: "Before"},
                {value: CompareType.LEQ, label: "On or before"},
                {value: CompareType.GT, label: "After"},
                {value: CompareType.GEQ, label: "On or after"},
            ] : [
                {value: CompareType.EQ, label: "On"},
                {value: CompareType.NEQ, label: "Not on"},
                {value: CompareType.LT, label: "Before"},
                {value: CompareType.LEQ, label: "On or before"},
                {value: CompareType.GT, label: "After"},
                {value: CompareType.GEQ, label: "On or after"},
                {value: CompareType.BETWEEN, label: "Between"},
                {value: CompareType.NBETWEEN, label: "Not between"}
            ]
        default:
            return []
    }
}

export const isBetweenComparison = (type: CompareType) => {
    return type == CompareType.BETWEEN || type == CompareType.NBETWEEN
}

export interface SlicerValues {
    column: string,
    data: {
        result_set_too_big: boolean,
        values: string[],
    }
}
