import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {
    DndContext,
    DragOverlay,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors,
    useDroppable,
    pointerWithin,
  } from "@dnd-kit/core";
  import { CSS } from "@dnd-kit/utilities";
    import { rectSortingStrategy, SortableContext, useSortable  } from "@dnd-kit/sortable";
  import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";
  import ExitPromptDialog from "../../components/Dialogs/ExitPromptDialog"

/*CSS*/
import '../../css/singleItem.css'

function Item(item, dragOverlay) {

  const pointer = {
    cursor: dragOverlay ? "grabbing" : "grab",
  };

  return (
  <div  style={pointer} className="d-flex justify-content-between align-itemGroups-center p-2" >   
    <div
     className=" w-100 h-100  align-itemGroups-center p-2">
      <button className="btn btn-default mr-2"><i className="fa fa-bars" aria-hidden="true"></i></button>
      {item.name}
    </div>
    
  </div>
  )
}

function stringToPascalCase(string) {
  return string.replace(/\s+/g, '')
}


function SortableItem  ({ id, item, dragOverlay, ...props }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: id, data: {
      id: item.id,
      name: item.name
    } });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.5 : 1,
    };

    const pointer = {
      cursor: dragOverlay ? "grabbing" : "grab",
    };

    function hideColumnHandler(item) {
      props.hideColumnHandler(item)
    }
  
    return (
      <li
      ref={setNodeRef}
      style={style}
        className="list-group-item list-group-item-action p-0"
      >
        <div style={pointer} className="d-flex justify-content-between align-items-center w-100" >
      
          <div {...attributes}
          {...listeners} className=" d-flex w-100 align-items-center single-item-data-edit-list-item-content">
            <button className="btn mr-2 button-text"><i className="fa fa-bars" aria-hidden="true"></i></button>
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="mr-1 single-item-data-edit-text data-edit-text-border-right ">{item.name}</span>
              <span className="single-item-data-edit-text">{item.value}</span>
            </div> 
          </div>
          {item.group !== "hidden" &&
            <button title="Hide" className="btn h-50 button-text" onClick={() => hideColumnHandler(item)}><i className="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
          }
        </div>
      </li>
    );
  };

function Droppable ({ id, items, classNames, ...props }) {
  const { setNodeRef } = useDroppable({ id });
  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <ul className="single-item-data-edit-column-section " ref={setNodeRef}>
        {items.map((item) => (
          <SortableItem key={item.id} id={item.id} item={item} hideColumnHandler={props.hideColumnHandler}/>
        ))}
      </ul>
    </SortableContext>
  );
};

function SingleItemDataEdit(props) {

  const [itemGroups, setItemGroups] = useState(() => {

    const hColsLeft = createItems(props.setup.hidden_master_columns.left, "hidden")
    const hColsRight = createItems(props.setup.hidden_master_columns.right, "hidden")
    const hCols = hColsLeft.concat(hColsRight)

    return ( {
    shownLeft: createItems(props.setup.shown_master_columns.left, "shownLeft"),
    shownRight: createItems(props.setup.shown_master_columns.right, "shownRight"),
    hidden: hCols,
    })

  })

  const [activeItem, setActiveItem] = useState(null);

  const [warningDialog, setWarningDialog] = useState({
      showWarning: false,
      warningText: "",
      cancelCallback: null,
      acceptCallback: null,
  })
  const sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor),
      useSensor(PointerSensor),
  );

  function createItems(items, group) {
    const newItems = []
    if (!items) return []

    for (const item of items) {
      let column;
     
        const columns = props.hiddenColumnsData[0].concat(props.hiddenColumnsData[1]).concat(props.shownColumnsData[0]).concat(props.shownColumnsData[1])
        const index = columns.findIndex(c => c.name === item)
        column = columns[index]
      
      newItems.push({
        id: stringToPascalCase(item),
        name: item,
        group: group,
        value: !!column ? format(column, props.data.data[column.index]) : null
      })
    }

    return newItems;
  }

  function format(column, value) {
    if (column.type === 'date') {
      return moment(value).format("YYYY-MM-DD")
  } else if (column.type === 'decimal' && value !== null) {
      let decimalCount = 0;
      let x = parseFloat(value)
      if (isNaN(x)) {
          return '?'
      }
      return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
  }
  return value
  }

    function hideColumnHandler(value) {
       const newHiddenCols = Array.from(itemGroups.hidden);
       const newShownCols = itemGroups[value.group].filter(c => c.id !== value.id)
       const hidden = {...value}
       hidden.group = "hidden"
       newHiddenCols.push(hidden)
       newHiddenCols.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

        setItemGroups({
         ...itemGroups, 
         [value.group]: newShownCols,
         hidden: newHiddenCols
        })

    }

    useEffect(()=> {

      const shownLeft = Array.from(itemGroups.shownLeft, x => x.name)
      const shownRight = Array.from(itemGroups.shownRight, x => x.name)
      const hidden = Array.from(itemGroups.hidden, x => x.name)

      const updatedMasterColumns = {
        shown_master_columns: {
          left: shownLeft,
          right: shownRight
        },
        hidden_master_columns: {
          left: hidden,
          right: []
        }
      }

      if(!activeItem){
        props.masterColumnsUpdated(updatedMasterColumns, props.data.bucket.bucketId)
      }

    }, [itemGroups])
  
    const removeAtIndex = (array, index) => {
      return [...array.slice(0, index), ...array.slice(index + 1)];
    };
    
    const insertAtIndex = (array, index, item) => {
      return [...array.slice(0, index), item, ...array.slice(index)];
    };
    
    const arrayMove = (array, oldIndex, newIndex) => {
      return dndKitArrayMove(array, oldIndex, newIndex);
    };

    const handleDragStart = ({ active }) => setActiveItem({
      id: active.id,
      name: active.data.name,
      group: active.data.group,
    });

    const handleDragCancel = () => setActiveItem(null);

    const handleDragOver = ({ active, over }) => {
      const overId = over?.id;
  
      if ( !overId) {
        return;
      }
  
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
  
      if (activeContainer !== overContainer) {
        setItemGroups((itemGroups) => {
          const activeIndex = active.data.current.sortable.index;
          const overIndex =
            over.id in itemGroups
              ? itemGroups[overContainer].length + 1
              : over.data.current.sortable.index;

            return moveBetweenContainers(
              itemGroups,
              activeContainer,
              activeIndex,
              overContainer,
              overIndex,
              itemGroups[activeContainer][activeIndex]
            );
           })
      }
    };

    const handleDragEnd = ({ active, over }) => {
      if (!over) {
        setActiveItem(null);
        return;
      }

      if (active.id !== over.id) {
        const activeContainer = active.data.current.sortable.containerId;
        const overContainer = over.data.current?.sortable.containerId || over.id;
        const activeIndex = active.data.current.sortable.index;
        const overIndex =
          over.id in itemGroups
            ? itemGroups[overContainer].length + 1
            : over.data.current.sortable.index;
        
        setItemGroups((itemGroups) => {
          let newItems;
          if (activeContainer === overContainer) {
            newItems = {
              ...itemGroups,
              [overContainer]: arrayMove(
                itemGroups[overContainer],
                activeIndex,
                overIndex
              ),
            };
          } else {
            newItems = moveBetweenContainers(
              ...itemGroups,
              activeContainer,
              activeIndex,
              overContainer,
              overIndex,
              itemGroups[activeContainer][activeIndex]
            );
          }
  
          return newItems;
        });
      }
  
      setActiveItem(null);
    };
 
    const moveBetweenContainers = (
      items,
      activeContainer,
      activeIndex,
      overContainer,
      overIndex,
      item
    ) => {

      const newItem = {...item}
      newItem.group = overContainer

      return {
        ...items,
        [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
        [overContainer]: insertAtIndex(items[overContainer], overIndex, newItem),
      };
    };

    function sortAndSplitColumns(columnNames) {
      columnNames.sort((a,b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : ((b.toLowerCase() > a.toLowerCase()) ? -1 : 0))
      const half = Math.ceil(columnNames.length / 2)
      const firstHalf = columnNames.slice(0, half)
      const secondHalf = columnNames.slice(half, columnNames.length)
      return { left: firstHalf, right: secondHalf }
  }

    function sortClickHandler() {
      setWarningDialog({
        showWarning: true,
        warningText: "This action will sort the columns alphabetically. Any unsaved changes for Info will be lost.",
        cancelCallback: () => cancelSort(),
        acceptCallback: () => acceptSort(),
        acceptText: "Sort items"
      })
    }

    function resetClickHandler() {
      setWarningDialog({
        showWarning: true,
        warningText: "This action will reset the columns to default view. All unsaved changes for Info will be lost",
        cancelCallback: () => cancelReset(),
        acceptCallback: () => acceptReset(),
        acceptText: "Reset to default"
      })
    }

    function cancelSort() {
      setWarningDialog({
        showWarning: false,
        ...warningDialog
      })
    }

    function cancelReset() {
      setWarningDialog({
        showWarning: false,
        ...warningDialog
      })
    }

    function acceptReset() {
      setWarningDialog({
        showWarning: false,
        ...warningDialog
      })
        const columns = props.data.bucket.model.columns;
        let shown = columns.filter(c => !props.setup.hidden_columns.includes(c.name))
        shown = shown.map((c) => c.name)
        const shown_master_columns = sortAndSplitColumns(shown)

        setItemGroups({
          shownLeft: createItems(shown_master_columns.left, "shownLeft"),
          shownRight: createItems(shown_master_columns.right, "shownRight"),
          hidden: []
        })
    }

    function acceptSort() {
      setWarningDialog({
        showWarning: false,
        ...warningDialog
      })
      let items = {...itemGroups}
      const array = items.shownLeft.concat(items.shownRight)
      const shown = array.map(c => c.name)
      const shown_master_columns = sortAndSplitColumns(shown)
      const hidden = itemGroups.hidden;
      hidden?.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      setItemGroups({
        shownLeft: createItems(shown_master_columns.left, "shownLeft"),
        shownRight: createItems(shown_master_columns.right, "shownRight"),
        hidden: hidden
      })
    }

    return (
        <>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          <ExitPromptDialog title="Unsaved changes will be lost" shown={warningDialog.showWarning} cancel={warningDialog.cancelCallback} accept={warningDialog.acceptCallback} acceptCss="btn btn-primary" acceptText={warningDialog.acceptText} > {warningDialog.warningText}</ExitPromptDialog>
          <div className="d-flex flex-column flex-wrap mw-700 single-item-data-edit-container">
            <section className="align-self-end mt-2 btn-group" role="group">
                <button className="btn btn-outline-primary" title="Sort and divide columns equally" onClick={sortClickHandler}><i className="fa fa-sort-alpha-asc" aria-hidden="true"></i>
                </button>
                <button onClick={resetClickHandler} title="Reset to default" className="btn btn-outline-primary"><i className="fa fa-undo" aria-hidden="true"></i>
                </button>
            </section>
            <div className="single-item-data-edit-wrapper">
              <div>
                <h3>Shown columns</h3>
                <div className="single-item-data-edit-wrapper">
                  <section className=" m-2">
                    <Droppable
                    id="shownLeft"
                    items={itemGroups["shownLeft"]}
                    activeItem={activeItem}
                    key={"shownLeft"}
                    hideColumnHandler={hideColumnHandler}
                    />
                  </section>
          
                  <section className="m-2">
                    <Droppable
                    id="shownRight"
                    items={itemGroups["shownRight"]}
                    activeItem={activeItem}
                    key={"shownRight"}
                    hideColumnHandler={hideColumnHandler}
                    />
                  </section>
                </div>
              </div>
              <div>
                <h3>Hidden columns</h3>
                <section className=" m-1 mt-3 single-item-data-edit-hidden">
                  <Droppable
                  id="hidden"
                  items={itemGroups["hidden"]}
                  activeItem={activeItem}
                  key={"hidden"}
                  />
                </section>
              </div>
            </div>
          </div>
          <DragOverlay>
            {activeItem ? (
            <Item item={activeItem} dragOverlay /> 
          ): null}
          </DragOverlay>
        </DndContext>
      </>
    )
}
 
export default SingleItemDataEdit