import React, { CSSProperties, ReactNode } from "react"
import ShowIf from "../Generic/ShowIf"

export enum alertTypes {
    DANGER = "danger",
    WARNING = "warning",
    SUCCESS = "success",
}

export interface IAlertProps {
    config: {
        type: string,
        title: string,
        text: ReactNode,
        customButtons?: HTMLElement,
    },
    style?: CSSProperties,
}

function AlertBanner({style = {}, ...props}: IAlertProps) {

    return (
        <div style={style} className={"alert alert-" + props.config.type + " d-flex align-items-center justify-content-between w-100"} role="alert">
            <div>
                <ShowIf if={props.config?.title != undefined && props.config.title.length > 0}>
                    <strong className="mr-2">{props.config.title}</strong>
                </ShowIf>
                {props.config.text}
            </div>
            <section>
                {props.config.customButtons}
            </section>
        </div>
    )

}

export default AlertBanner
