import React from 'react'
import { Link } from 'react-router-dom'

export const MenuItemDropdown = ({ link, text, theClass, reports }) => {
    return (
        <li id="reports-dropdown" className={`${theClass} dropdown`} onClick={(e) => e.target.blur()}>
            <Link to={link} className="dropdown-toggle nav-link cancel-a-style" role="button" aria-haspopup="true" aria-expanded="false">{text}</Link>
            <ul className="dropdown-menu margin-top-0">
                {
                    reports.map((r, i) => {
                        return <Link key={i} className="dropdown-link cancel-a-style" to={`/report/${r.report.report_id}`}><li className="dropdown-item">{r.report.name}</li></Link>
                    })
                }
            </ul>
        </li>
    )
}