import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const gotChecklistsStatus = checklists => {
    return packAction(ActionTypes.GET_CHECKLISTS_STATUS_DONE, checklists)
}

export const getChecklistsStatus = () => {
    return packAction(ActionTypes.GET_CHECKLISTS_STATUS)
}

export const updatedChecklists = () => {
    return getChecklistsStatus()
}

export const clearChecklistsProps = (checklists) => {
    return packAction(ActionTypes.CLEAR_CHECKLISTS_PROPS, checklists)
}
