import React from 'react'

class BaseDialog extends React.Component {
	state = { show: false }

	isVisible() {
		return this.state.show;
	}

	show() {
		window.$(this.refs.dialog).on('shown.bs.modal', () => {
			if (this.props.focus) {
				const input = document.getElementById(this.props.focus);
				input.focus();
				input.select();
			}
		})

		this.setState({show: true})
	}

	hide = () => {
		this.setState({show: false})
	}

	getButtons() {
		return (
			<div>
				<button type="button" onClick={this.hide} className="btn btn-default">OK</button>
			</div>
		)
	}

	getContent() {
		return <span />
	}

	render() {
		return (
			<div ref="dialog" className={"modal " + (this.state.show ? "display-block" : "")} data-backdrop={this.props.disableBackdrop ? "static" : "true"} tabIndex="-1" role="dialog">
				<div className={`modal-dialog ${this.props.large ? 'modal-lg' : '' }`} role="document">
					<div className="modal-content">
						<div className="modal-header sticky-modal-header">
							<h4 className="modal-title">{this.props.title}</h4>
						</div>
						<div className="modal-body">
							{this.getContent()}
							{this.props.children}
						</div>
						<div className="modal-footer sticky-modal-footer">
							{this.getButtons()}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default BaseDialog