/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import KpiGraphView from './TrendComponents/KpiGraphView'
import DiffView from './TrendComponents/DiffView'
import { getIdColumn, isNumberColumn } from '../../helpers/ReportHelpers'
import { withRouter } from 'react-router'
import { withSettingsPropagation } from '../../helpers/SettingsService'
import { getSetting } from '../../helpers/SingleSettingService'
import DiffViewDialog2 from '../Dialogs/DiffViewDialog2'
import { getTrendsetsData } from '../../actions/ReportActions'

const mapStateToProps = (state, ownProps) => {
    return {
        trendsetsdata: state.Report.trendsetsdata,
        diffdata: state.Report.diffdata,
        users: state.System.users,
    }
}
class Trend extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedKpi1: null,
            selectedKpi2: null,
            selectedCategory: null,
            selectedKpiView: null,
            lastAdded: -1,
            goToDiff: false,
            graphOptions: null,
            last: null,
            sndLast: null,
            showDiff: false,
            diffSettings: {},
            diffKpi: ""
        }
        this.diff = React.createRef()
        this.graph = React.createRef()
    }

    componentDidMount() {
        const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
        if (viewSettings.trend && viewSettings.trend.kpigraph) {
            let kpis = JSON.parse(this.props.reportdata.report.kpis)
            if (kpis.length > 0 && (!this.state.selectedKpi1 && !this.state.selectedKpi2)) {
                this.setState({
                    selectedKpi1: viewSettings.trend.kpigraph.selectedKpi1,
                    selectedKpi2: viewSettings.trend.kpigraph.selectedKpi2,
                    selectedCategory: viewSettings.trend.kpigraph.selectedCategory,
                    selectedKpiView: viewSettings.trend.kpigraph.selectedKpiView,
                    lastAdded: viewSettings.trend.kpigraph.lastAdded,
                    goToDiff: viewSettings.trend.kpigraph.goToDiff,
                    graphOptions: viewSettings.trend.kpigraph.graphOptions
                })
            }
        }
        if(this.props.viewSettings !== undefined){
            this.setState({
                selectedKpi1: this.props.viewSettings.selectedKpi1,
                selectedKpi2: this.props.viewSettings.selectedKpi2,
                selectedCategory: this.props.viewSettings.selectedCategory,
                selectedKpiView: this.props.viewSettings.selectedKpiView,
                lastAdded: this.props.viewSettings.lastAdded,
                goToDiff: this.props.viewSettings.goToDiff,
                graphOptions: this.props.viewSettings.graphOptions
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reportdata.report.kpis !== this.props.reportdata.report.kpis) {
            const kpis = JSON.parse(this.props.reportdata.report.kpis)
            if (kpis.length > 0 && kpis[0] !== undefined) {
                this.setKpis({ kpi: kpis[0], color: 'blue', index: 0, settings: { type: "bar" } }, null, 1, kpis[0].column)
            } else {
                this.setKpis({ kpi: { aggregation: "sum", column: "null", showKPIDecimals: false, showPercentage: false }, color: 'blue', index: 0, settings: { type: "bar" } }, null, 1, "null")
            }
        }
    }

    deselectMultiple = ids => {
        if (this.diff.current)
            this.diff.current.deselectMultiple(ids)
    }

    canUseDiff() {
        const idColumn = getIdColumn(this.props.reportdata.info.model.columns)
        return this.state.selectedKpiView !== null && this.state.selectedKpiView !== idColumn
    }

    toggleDiff() {
        if (this.canUseDiff()) {
            this.props.history.push(`/report/${this.props.reportdata.report.report_id}/diff`)
        }
    }

    toggleGraph() {
        this.props.history.push(`/report/${this.props.reportdata.report.report_id}/trend`)
    }

    goToTrend(kpi, last, sndLast) {
        this.setState({ last, sndLast, diffKpi: kpi.kpi.column, showDiff: true }, () => {
            this.props.dispatch(getTrendsetsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id))
        })
    }

    kpiClicked(kpi, index){
        //forward to graph
        this.graph.current.kpiClicked(kpi, index)
    }

    setKpis(kpi1, kpi2, lastAdd, kpiView) {
        let opt = this.state.graphOptions
        if (opt === null || !opt.zoom) opt = {zoom: 12}
        

        if(kpi1 && kpi2 && opt.zoom > 12){
            //ONLY 1 KPI, if more than 24 months            
            kpi1 = kpi2;
            kpi1.color = "blue"
            kpi1.settings.type = "bar"
            kpi2 = null;
            lastAdd = 1;
        }

        if(!kpi1 && !kpi2 && opt.zoom > 12){
            let kpis = JSON.parse(this.props.reportdata.report.kpis)

            if(kpis.length > 0){
                kpi1 = { kpi: kpis[0], color: 'blue', index: 0, settings: { type: "bar" } }
            }
            kpi2 = null;
            lastAdd = 1;
        }
        
        if (kpi1 && kpi2) {
            const isNum1 = isNumberColumn(kpi1.kpi.column, this.props.reportdata.info.model)
            const isNum2 = isNumberColumn(kpi2.kpi.column, this.props.reportdata.info.model)
            if (isNum1 && isNum2) {
                opt.axisNum = 1
            } else {
                opt.axisNum = 2
            }
        } else if ((!kpi1 && kpi2) || (kpi1 && !kpi2)) opt.axisNum = 1

        if (opt.targetHistory) {
            if (kpi1) kpi1.settings.type = opt.targetHistory[kpi1.index] ?? "bar"
            if (kpi2) kpi2.settings.type = opt.targetHistory[kpi2.index] ?? "bar"
        }

        if(opt.startAtZero === undefined){ //default must be "Show 0" 
            opt.startAtZero = true;
        }

        this.setState({ selectedKpi1: kpi1, selectedKpi2: kpi2, lastAdded: lastAdd, selectedKpiView: kpiView, graphOptions: opt }, () => {
            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.trend) viewSettings.trend = {}
                viewSettings.trend.kpigraph = this.getTrendSettings()
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
    }

    setGraphOptions(opt) {
        this.setState({ graphOptions: opt }, () => {

            if(opt && opt.zoom > 12){
                if(this.state.selectedKpi1){
                    this.setState({selectedKpi2: null});
                }
                else if(this.state.selectedKpi2){
                    this.setState({selectedKpi1:this.state.selectedKpi2})
                }
            }

            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.trend) viewSettings.trend = {}
                viewSettings.trend.kpigraph = this.getTrendSettings()
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
    }

    getTrendSettings() {
        return {
            selectedKpi1: this.state.selectedKpi1,
            selectedKpi2: this.state.selectedKpi2,
            selectedCategory: this.state.selectedCategory,
            selectedKpiView: this.state.selectedKpiView,
            lastAdded: this.state.lastAdded,
            goToDiff: this.state.goToDiff,
            graphOptions: this.state.graphOptions,
        }
    }

    setKpisSettings(kpi1, kpi2) {
        let opt = Object.assign({}, this.state.graphOptions)
        if (opt.targetHistory === undefined) opt.targetHistory = {}
        opt.targetHistory[kpi1?.index] = kpi1?.settings?.type
        opt.targetHistory[kpi2?.index] = kpi2?.settings?.type

        this.setState({ selectedKpi1: kpi1, selectedKpi2: kpi2, graphOptions: opt }, () => {
            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.trend) viewSettings.trend = {}
                viewSettings.trend.kpigraph = this.getTrendSettings()
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
    }

    setKpiView(kpi) {
        this.setState({ selectedKpiView: kpi })
    }

    setDiffSettings = (settings) => {
        this.setState({diffSettings: settings}, () => {
            this.setBothTextColumns(this.state.diffSettings.selectedTextColumn, this.state.diffSettings.selectedSecondaryTextColumn)
        })
    }

    setBothTextColumns = (c1, c2) => {
            if (this.props.editMode) {
                let columns = JSON.parse(this.props.reportdata.report.columns)
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                const index = columns.indexOf(c1)
                if (!viewSettings.trend) viewSettings.trend = {}
                if (!viewSettings.trend.diff) viewSettings.trend.diff = {}
                viewSettings.trend.diff.graph_extra_column = index
                viewSettings.trend.diff.graph_column = c1
                viewSettings.trend.diff.graph_secondary_column = c2
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings), () => {
                    
                })
            }
    }

    render() {
        let section = this.props.section

        return (
            <div>
                <KpiGraphView
                    ref={this.graph}
                    settingsParent={this}
                    show={this.props.show && section === 'trend'}
                    enabled={this.props.enabled}
                    reportdata={this.props.reportdata}
                    setKpis={(kpi1, kpi2, lastAdd, kpiView) => this.setKpis(kpi1, kpi2, lastAdd, kpiView)}
                    goToTrend={(kpi, last, sndLast) => this.goToTrend(kpi, last, sndLast)}
                    setCategory={(c) => this.setState({ selectedCategory: c })}
                    selectedCategory={this.state.selectedCategory}
                    slicers={this.props.slicers}
                    selectedKpi1={this.state.selectedKpi1}
                    selectedKpi2={this.state.selectedKpi2}
                    selectedKpiView={this.state.diffKpi}
                    lastAdded={this.state.lastAdded}
                    setKpisSettings={(kpi1, kpi2) => this.setKpisSettings(kpi1, kpi2)}
                    graphOptions={this.state.graphOptions}
                    setGraphOptions={(opt) => this.setGraphOptions(opt)}
                    clickableCategories={this.props.clickableCategories}
                    historydataForwarded={this.props.historydata}
                    forPrint={this.props.forPrint}
                />
                {
                    this.props.show && section === "diff" ?
                        <DiffView
                            ref={this.diff}
                            show={this.props.show && section === 'diff'}
                            settingsParent={this}
                            reportdata={this.props.reportdata}
                            selectedKpi1={this.state.selectedKpi1}
                            selectedKpi2={this.state.selectedKpi2}
                            selectedKpi={this.state.selectedKpiView}
                            selectedCategory={this.state.selectedCategory}
                            setKpis={(kpi1, kpi2, lastAdd, kpiView) => this.setKpis(kpi1, kpi2, lastAdd, kpiView)}
                            lastAdded={this.state.lastAdded}
                            slicers={this.props.slicers}
                            editMode={this.props.editMode}
                            setKpiView={(kpi) => this.setKpiView(kpi)}
                            hideRow={(bId, id) => this.props.hideRow(bId, id)}
                            goBack={() => this.toggleGraph()}
                            setData={this.props.setData}
                            last={this.state.last}
                            sndLast={this.state.sndLast}
                            setSelectedItems={this.props.setSelectedItems}
                            selectedItemsLength={this.props.selectedItemsLength}
                        />
                        : null
                }
                <DiffViewDialog2
                    show={this.state.showDiff}
                    setShow={(val) => this.setState({showDiff: val})}
                    reportdata={this.props.reportdata}
                    selectedKpi={this.state.diffKpi}
                    selectedCategory={this.state.selectedCategory}
                    slicers={this.props.slicers}
                    last={this.state.last}
                    sndLast={this.state.sndLast}
                    setSelectedItems={this.props.setSelectedItems}
                    selectedItemsLength={this.props.selectedItemsLength}
                    editMode={this.props.editMode}
                    setData={this.props.setData}
                    trendsetsdata={this.props.trendsetsdata.get(this.props.reportdata.report.report_id, null)}
                    settings={this.state.diffSettings}
                    setDiffSettings={this.setDiffSettings}
                    setBothTextColumns={this.setBothTextColumns}
                    dispatch={this.props.dispatch}
                    diffdata={this.props.diffdata.get(this.props.reportdata.report.report_id, null)}
                    setKpiView={(kpi) => this.setKpiView(kpi)}
                    promptCreateChecklist={this.props.promptCreateChecklist}
                    disableSave={this.props.disableSave}
                    getDisabledText={this.props.getDisabledText}
                    promptExitEditMode={this.props.promptExitEditMode}
                    enterEditMode={this.props.enterEditMode}
                 />
            </div>
        )
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (
        JSON.stringify(prevState.selectedKpi1) !== JSON.stringify(curState.selectedKpi1) ||
        JSON.stringify(prevState.selectedKpi2) !== JSON.stringify(curState.selectedKpi2) ||
        prevState.selectedCategory !== curState.selectedCategory ||
        prevState.selectedKpiView !== curState.selectedKpiView ||
        prevState.goToDiff !== curState.goToDiff ||
        JSON.stringify(prevState.graphOptions) !== JSON.stringify(curState.graphOptions) ||
        JSON.stringify(prevState.diffSettings) !== JSON.stringify(curState.diffSettings)
    )
}

const getSettingsKeys = (state, props) => {
    let keys
    if (props.editMode) {
        keys = []
    } else {
        let { goToDiff, showDiff, ...rest } = state
        keys = rest
    }
    return Object.keys(keys)
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => {
    if (_this.state.goToDiff) {
        _this.setState({ goToDiff: false }, () => {
            _this.props.changeView("diff")
        })
    }

    let kpis = JSON.parse(_this.props.reportdata.report.kpis)
    if (kpis && kpis.length > 0 && !_this.state.selectedKpi1 && !_this.state.selectedKpi2 && _this.props?.reportdata?.report?.view_settings && getSetting(`report-${_this.props.reportdata.report.report_id}-compose-loaded`)) {
        let view_settings = JSON.parse(_this.props.reportdata.report.view_settings)
        if(view_settings && view_settings.trend && view_settings.trend.kpigraph) {
            let trendSettings = view_settings.trend.kpigraph
            _this.setState(trendSettings)
        }
    }
}

const isSilentUpdate = (prevProps, prevState, snapshot, _this) => {
    let kpis = JSON.parse(_this.props.reportdata.report.kpis)
    return kpis && kpis.length > 0 && !prevState.selectedKpi1 && !prevState.selectedKpi2
}

Trend = withSettingsPropagation(Trend, getSettingsKeys, shouldComponentUpdateSettings, isSilentUpdate, settingsDidApply, "trend")

Trend = connect(mapStateToProps, null, null, { forwardRef: true })(Trend)

Trend = withRouter(Trend)

export default Trend
