import React from 'react'
import BgColor from '../Generic/BgColor'
import { connect } from 'react-redux'
import { packAction } from '../../actions/ActionTypes'
import * as ActionTypes from '../../actions/ActionTypes'
import ShowIf from '../Generic/ShowIf'
import HideIf from '../Generic/HideIf'

class ForgotPassword extends React.Component {
    state = {
        email: ''
    }

    dispatch() {
        this.props.dispatch(packAction(ActionTypes.FORGOT_PASSWORD, {email: this.state.email}))
        this.setState({sent: true})
    }

    render() {
        return (
            <BgColor bgClass="loginBody">
                 <div className='row centered-row'>
                <div className='col-md-7 login-left-side'>
                    <img src="./img/inact_now_logo.svg" alt="" />
                   
                    <div className='previously'>
                        <p className="login-white-text">Previously ABC Cloud by <a className="white-text" target="_blank" href="https://inact.io" rel="noopener noreferrer">ABC Softwork</a></p>
                    </div>

                </div>
                <div className='col-md-5'>

                <div className="login-box">
                    <h1 className='margin-bottom-20px'>Reset Password</h1>
                    <ShowIf if={this.state.sent}>
                        <p>An email has been sent to <i>{this.state.email}</i> with further instructions.</p>
                    </ShowIf>
                    <HideIf if={this.state.sent}>
                        <input type="email" name="email" onChange={e => this.setState({email: e.target.value})} value={this.state.email} className="form-control" placeholder="Email address" required autoFocus/>
                        <br/>
                        <button className="btn btn-tangerine" type="submit" disabled={!/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.state.email)} onClick={() => this.dispatch()}>Reset</button>
                    </HideIf>
                    <div className="help-text">
                         <p className="font-size-12px"><a className="fix-helper-a tangerine" target="_blank" href="https://help.inact.io" rel="noopener noreferrer">Do you need help?</a></p>
                    </div>
                </div>
                </div>
                </div>
            </BgColor>
        )
    }
}

export default connect()(ForgotPassword)