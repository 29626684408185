import React, { createRef, useEffect, useState } from "react";
import { useImperativeHandle } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { answerNPS, getUserNPS, postponeNPS } from "../../actions/UserActions";

const NpsDialog = React.forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const nspRef = createRef()
    const loggedIn = useSelector(state => state.User.loggedIn)
    const nps = false; //useSelector(state => state.User.nps)

    const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [point, setPoint] = useState(-1);

    function hide() {
        window.$(nspRef.current).modal('hide')
    }

    function show() {
        window.$(nspRef.current).modal('show')
    }

    // functions exposed to outer components
    useImperativeHandle(ref, () => ({
        show: () => show(),
        hide: () => hide(),
    }))

    useEffect(() => {
        if (loggedIn) {
            dispatch(getUserNPS())
        }
    }, [loggedIn, dispatch])

    useEffect(_ => {
        if (nps) {
            show()
        }
    }, [nps])

    const send = () => {
        dispatch(answerNPS({ point: point }))
        this.hide()
    }

    const postpone = () => {
        dispatch(postponeNPS())
        this.hide()
    }
    
    if (nps === null) return null;
    return (
        <div ref={nspRef} className="modal fade" id="exampleModalCenter" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-align-center margin-top-20px">
                            
                        </div>
                        <div className="margin-top-30px margin-bottom-30px text-align-center">
                            How likely are you to recommend Inact to a friend or colleague?
                            <br />
                            Your response helps us improve our products and services.
                        </div>
                        <div className="container">
                            <div className="row text-align-center mx-4" style={{ height: 50 }}>
                                {
                                    options.map(v =>
                                        <div key={v} className="col pl-1 pr-1">
                                            <div className="d-flex justify-content-center align-items-center h-100" style={{ width: 50 }}>
                                                <div className={`nps-point-circle ${v === point ? 'nps-point-circle-selected' : ""}`} onClick={_ => setPoint(v)}>
                                                    <div className="d-flex justify-content-center align-items-center h-100"><div>{v}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="container">
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="">
                                        Extremely unlikely
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-align-right">
                                        Extremely likely
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={postpone}>Remind me later</button>
                        <button type="button" disabled={point === -1} className="btn btn-primary" onClick={send}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default NpsDialog;