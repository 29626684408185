import React from 'react'

// Not designed to be inherited from. This table should be used if you need both a custon thead and tbody.
// An example can be found in DiffView.js

class BaseGridTable extends React.Component {
    render() {
        return (
            <table id={"dataGridToCopy-"+this.props.id} className={`table table-sm table-striped table-bordered table-condensed table-hover ${this.props.className ? this.props.className : ''}`} >
                {this.props.children}
            </table>
        )
    }
}

export default BaseGridTable