import React from 'react'
import SaveDialog from './SaveDialog'

class TextareaDialog extends React.Component {
    state = {
        textValue: ''
    }

    show(defaultVaue) {
        this.setState({
            textValue: defaultVaue
        })
        this.refs.changeNameDialog.show()
    }

    hide() {
        this.refs.changeNameDialog.hide()
    }

    saveHandler() {
        this.props.saveHandler(this.state.textValue)
    }

    render() {
        return (
            <SaveDialog ref="changeNameDialog" title={this.props.title} saveHandler={() => this.saveHandler()} closeHandler={() => this.props.closeHandler ? this.props.closeHandler() : null}>
                <textarea type="text"className="form-control" value={this.state.textValue} onChange={e => this.setState({textValue: e.target.value})} placeholder={this.props.placeholder}/>
            </SaveDialog>
        )
    }
}

export default TextareaDialog