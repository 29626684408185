import React from 'react'
import ShowIf from '../Generic/ShowIf'
import ReactTooltip from 'react-tooltip'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import Drawer from '../Animation/Drawer'
import WordWrap from '../Generic/WordWrap'
import SingleItemDataEdit from './SingleItemDataEdit'

/*CSS*/
import '../../css/singleItem.css'

class SingleItemData extends React.Component {
    getOrderedColumnInfo(modelColumns, columns, shownModelColumns, isFirst) {
        let res = [[], []]
        if (columns.left && columns.right) {
            let res1 = columns.left.map(co => {
                let column = modelColumns.find(c => c.name === co)
                column = Object.assign({}, column)
                column.index = shownModelColumns.findIndex(c => c.name === co)
                return column
            }).filter(x => x.index >= 0)
            res[0] = res1

            let res2 = columns.right.map(co => {
                let column = modelColumns.find(c => c.name === co)
                column = Object.assign({}, column)
                column.index = shownModelColumns.findIndex(c => c.name === co)
                return column
            }).filter(x => x.index >= 0)
            res[1] = res2
        } else if (columns.left === null && columns.right === null && isFirst) {
            const _shown = shownModelColumns.slice()
            _shown.sort((a, b) => {
                const nameA = a.name.toLowerCase()
                const nameB = b.name.toLowerCase()
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0
            })
            const half = Math.ceil(shownModelColumns.length / 2)

            let res1 = _shown.splice(0, half).map(co => {
                let column = modelColumns.find(c => c.name === co.name)
                column = Object.assign({}, column)
                column.index = shownModelColumns.findIndex(c => c.name === co.name)
                return column
            })
            res[0] = res1

            let res2 = _shown.splice(-half).map(co => {
                let column = modelColumns.find(c => c.name === co.name)
                column = Object.assign({}, column)
                column.index = shownModelColumns.findIndex(c => c.name === co.name)
                return column
            })
            res[1] = res2
        }

        return res
    }

    getShownColumns(modelColumns, hiddenColumns) {
        if(hiddenColumns)
            return modelColumns.filter(c => !hiddenColumns.includes(c.name))
        else
            return modelColumns
    }

    render() {
        let data = this.props.data
        let bucket = this.props.bucket
        if (!data || !this.props.setup) return null

        const shownModelColumns = this.getShownColumns(bucket.model.columns, this.props.setup.hidden_columns)
        let firstRow = this.getOrderedColumnInfo(bucket.model.columns, this.props.setup.shown_master_columns, shownModelColumns, true)
        let secondRow = this.getOrderedColumnInfo(bucket.model.columns, this.props.setup.hidden_master_columns, shownModelColumns)

        let format = (value, column) => {
            if (column.type === 'date') {
                return moment(value).format("YYYY-MM-DD")
            } else if (column.type === 'decimal' && value !== null) {
                let decimalCount = this.props.showDecimals ? 2 : 0
                let x = parseFloat(value)
                if (isNaN(x)) {
                    return '?'
                }
                return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
            }
            return value
        }

        return (
            this.props.editMode ? 
            <>
                <SingleItemDataEdit data={this.props.data} shownColumnsData={firstRow} hiddenColumnsData={secondRow} getShownColumns={this.getShownColumns} getOrderedColumnInfo={this.getOrderedColumnInfo} setup={this.props.setup} masterColumnsUpdated={(data, id) => this.props.masterColumnsUpdatedHandler(data, id)}></SingleItemDataEdit>
            </>
            :
            <div className="margin-top-20px">
                <button className={`btn btn-default ${this.props.showDecimals ? 'active' : ''} btn-sm display-block margin-bottom-10px`} onClick={() => this.props.setShowDecimals(!this.props.showDecimals)}>0.0</button>
                <div className="margin-bottom-20px">
                    <div className="row">
                        {
                            firstRow.map((array, index) =>
                                <div className="col" key={index}>
                                    <table className={`table-bordered table-hover width-100-p table-striped table-condensed`}>
                                        <tbody>
                                            {
                                                array.map((column) =>
                                                    <tr key={column.name + "_" + column.index}>
                                                        <td>
                                                            <div className="word-wrap-with-icon">
                                                                <WordWrap>
                                                                    <b title={column.name}>{column.name}</b> &ensp;
                                                                </WordWrap>
                                                            </div> &ensp;
                                                            <ShowIf if={data.bucket.setup.descriptions[column.name]}>
                                                                <span data-tip data-for={`tooltip-${column.name}`} className="fa fa-info-circle" />
                                                                <ReactTooltip id={`tooltip-${column.name}`} type='dark' effect='solid' place='right'> <ReactMarkdown>{data.bucket.setup.descriptions[column.name]}</ReactMarkdown></ReactTooltip>
                                                            </ShowIf>
                                                        </td>
                                                        <td className="single-item-right-data-column" title={format(data.data[column.index], column)}>{format(data.data[column.index], column)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </div>
                </div>

                <Drawer expand={this.props.showMore}>
                    <div className="row">
                        {
                            secondRow.map((array, index) =>
                                <div className="col" key={index}>
                                    <table className={`table-bordered table-hover width-100-p table-striped table-condensed`}>
                                        <tbody>
                                            {
                                                array.map((column) =>
                                                    <tr key={column.name + "_" + column.index}>
                                                        <td>
                                                            <div className="word-wrap-with-share-icon">
                                                                <WordWrap>
                                                                    <b title={column.name}>{column.name}</b> &ensp;
                                                                </WordWrap>
                                                            </div>
                                                            <ShowIf if={data.bucket.setup.descriptions[column.name]}>
                                                                <span data-tip data-for={`tooltip-${column.name}`} className="fa fa-info-circle float-right" />
                                                                <ReactTooltip id={`tooltip-${column.name}`} type='dark' effect='solid' place='right'> <ReactMarkdown>{data.bucket.setup.descriptions[column.name]}</ReactMarkdown></ReactTooltip>
                                                            </ShowIf>
                                                        </td>
                                                        <td className="single-item-right-data-column" title={format(data.data[column.index], column)}>{format(data.data[column.index], column)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </div>
                </Drawer>
                {
                    secondRow[0].length > 0 || secondRow[1].length > 0 ?
                        <div className="container">
                            <div className="row margin-top-20px margin-bottom-20px">
                                <div className="col" style={{ borderTop: "1px solid", borderColor: "#d9d9d9" }}></div>
                                <div className="">
                                    <button onFocus={(e) => e.target.blur()} onClick={() => this.props.setShowMore(!this.props.showMore)} className={`btn btn-default btn-xs single-item-data-expand-button`}>
                                        {this.props.showMore ? "Hide" : "Show more"}
                                    </button>
                                </div>
                                <div className="col" style={{ borderTop: "1px solid", borderColor: "#d9d9d9" }}></div>
                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default SingleItemData