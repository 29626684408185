import React from 'react'
import { checkPasswordPolicyViolation, getPolicyOptionRequirements } from '../helpers/UserHelpers'
import ShowIf from './Generic/ShowIf'

class PasswordPolicy extends React.Component {
    render() {
        const password = this.props.password
        const policy = this.props.policy ?? {options: []}
        const policyViolations = checkPasswordPolicyViolation(policy, password)

        const lengthViolationClass = password.length > 0 ? policyViolations.includes("length") ? "red" : "green" : ""
        const attributesViolationClass = password.length > 0 ? policyViolations.includes("attributes") ? "red" : "green" : ""

        const policyReqs =  policy.options.map(o => getPolicyOptionRequirements(o))

        return (
            <ShowIf if={policy.length}>
                <span>
                    <b>Your password must:<br /></b>
                    
                        <span className={lengthViolationClass}>Be at least {policy.length} characters long<br /></span>
                        <ShowIf if={policy.options?.length === 1}>
                            <span className={attributesViolationClass}>Contain {policyReqs[0]}</span>
                        </ShowIf>
                        <ShowIf if={policy.options?.length > 1}>
                            <span className={attributesViolationClass}>Contain either&nbsp;
                            {
                                `${policyReqs.slice(0,policyReqs.length-1).join(", ")} or ${policyReqs[policyReqs.length-1]}`
                            }
                            </span>
                        </ShowIf>
                        <br />
                </span>
            </ShowIf>
        )
    }
}

export default PasswordPolicy